import gql from 'graphql-tag'

const SEARCH_TICKETING_TICKETS = gql`
  query searchTicketingTickets($query: SearchTicketingTicketsQuery!) {
    searchTicketingTickets(query: $query) {
      items {
        ticketId
        processDefinitionName
        subject
        state
        startedOn
        finishedOn
        hasUnreadMail
        startUser
        openTasks {
          name
          dueDate
        }
      }
      totalItems
      facets {
        name
        buckets {
          name
          count
        }
      }
    }
  }
`

export { SEARCH_TICKETING_TICKETS }
