const ALL = 'all'

const CLIENT = 'client'
const CARETAKER = 'caretaker'
const CRAFTSMAN = 'craftsman'
const TENANT = 'tenant'

const RENTING_FORM = 'rentingForm'

export const DASHBOARD_CONSTANTS = {
  ALL,
  CLIENT,
  CARETAKER,
  CRAFTSMAN,
  RENTING_FORM,
  TENANT
}