<template>
  <v-container fluid v-if="dataInitialized">
    <v-row dense>
      <v-col cols="12">
        <v-radio-group
          row
          v-model="onOffSchedule"
          required
          :rules="[rules.radioRequired]"
          @change="updatedField"
        >
          <v-list-item-subtitle class="subtitle-1 mb-2">
            Kündigung terminlich oder Ausserterminlich
          </v-list-item-subtitle>
          <v-radio class="pr-5 mr-0" label="Terminlich" value="ON_SCHEDULE" :disabled="formReadOnly" />
          <v-radio class="pr-5 mr-0" label="Ausserterminlich" value="OFF_SCHEDULE" :disabled="formReadOnly" />
          <template #message="{ message }">
            <span v-if="message === 'required'">{{ requiredMessage }}</span>
          </template>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-radio-group
          row
          v-model="tenantLiable"
          required
          :rules="[rules.radioRequired]"
          @change="updatedField"
        >
          <v-list-item-subtitle class="subtitle-1 mb-2">
            Mieter hat Haftung
          </v-list-item-subtitle>
          <v-radio class="pr-5 mr-0" label="Ja" value="YES" :disabled="formReadOnly" />
          <v-radio class="pr-5 mr-0" label="Nein" value="NO" :disabled="formReadOnly" />
          <template #message="{ message }">
            <span v-if="message === 'required'">{{ requiredMessage }}</span>
          </template>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row dense v-if="tenantLiable === 'YES'">
      <v-col cols="12">
        <DatePicker
          ref="tenantLiableUntil"
          v-model="tenantLiableUntil"
          :default-value="tenantLiableUntil ? true : false"
          :default-value-date="tenantLiableUntil"
          label="Haftung bis"
          :disabled="formReadOnly"
          :required="tenantLiable === 'YES'"
          attach-selector
          @input="updatedField"
        />
        <p>Kündigung per: {{ formatDate(terminationBy) }}</p>
      </v-col>
    </v-row>
    <v-row dense v-if="tenantLiable === 'YES'">
      <v-col cols="12">
        <AutocompleteLinks
          ref="tenantLiableReasonAutocomplete"
          v-model="tenantLiableReason"
          label="Begründung"
          name="System_TenantLiableReason_String"
          :default-html="tenantLiableReason"
          :disabled="formReadOnly"
          :additionalLinks="additionalLinks"
          @autocompleteLinksUpdated="onAutocompleteLinksUpdated"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <DatePicker
          ref="earliestDate"
          v-model="earliestDate"
          :default-value="earliestDate ? true : false"
          :default-value-date="earliestDate"
          label="Frühester Bezugstermin"
          :disabled="formReadOnly"
          required
          @input="updatedField"
        />
        <p>Kündigung per: {{ formatDate(terminationBy) }}</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AutocompleteLinks from '@/features/ticketing/components/AutocompleteLinks'

import DatePicker from '@/components/DatePicker'
import * as ticketingHelper from '@/features/ticketing/helpers/ticketingHelper'
import { formatDate } from '@/helpers/formatterHelper'

export default {
  name: 'TerminationDecisionReletting',
  props: {
    processVariables: {
      type: Array,
      default: () => {}
    },
    formReadOnly: {
      type: Boolean,
      default: false
    },
    additionalLinks: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      dataInitialized: false,
      onOffSchedule: null,
      tenantLiable: null,
      tenantLiableUntil: null,
      tenantLiableReason: null,
      earliestDate: null,
      terminationBy: null,
      rules: {
        required: (value) => !!value || this.$t('common.errors.required'),
        checkboxRequired: (value) => (value !== undefined && value !== '' && value !== null && value) || this.requiredMessage,
        radioRequired: (value) => (value !== undefined && value !== '' && value !== null) || this.requiredMessage
      }
    }
  },
  components: {
    DatePicker,
    AutocompleteLinks
  },
  watch: {
    'processVariables': {
      handler: 'initProcessVariables',
      immediate: true
    }
  },
  computed: {
    requiredMessage () {
      return this.$t('common.errors.required')
    }
  },
  methods: {
    formatDate: formatDate,
    initProcessVariables () {
      this.onOffSchedule = this.getVariableValue('System_TerminationOnOffSchedule_Enum')
      this.tenantLiable = this.getVariableValue('System_TenantLiable_Enum')
      this.tenantLiableUntil = this.getVariableValue('System_TenantLiableUntil_Date')
      this.tenantLiableReason = this.getVariableValue('System_TenantLiableReason_String')
      this.earliestDate = this.getVariableValue('System_ReLetting_EarliestStart_Date')
      this.terminationBy = this.getVariableValue('System_TerminationBy_Date')
      this.dataInitialized = true
    },
    onAutocompleteLinksUpdated (autocompleteLinks) {
      this.$emit('autocompleteLinksUpdated', autocompleteLinks)
      this.updatedField()
    },
    updatedField () {
      this.$emit('updateFormValues', { formFields: [
        {
          formFieldId: 'System_TerminationOnOffSchedule_Enum',
          formFieldType: 'enum',
          formFieldDefaultValue: this.onOffSchedule
        },
        {
          formFieldId: 'System_TenantLiable_Enum',
          formFieldType: 'enum',
          formFieldDefaultValue: this.tenantLiable
        },
        {
          formFieldId: 'System_TenantLiableUntil_Date',
          formFieldType: 'date',
          formFieldDefaultValue: this.tenantLiableUntil
        },
        {
          formFieldId: 'System_TenantLiableReason_String',
          formFieldType: 'string',
          formFieldDefaultValue: this.tenantLiableReason
        },
        {
          formFieldId: 'System_ReLetting_EarliestStart_Date',
          formFieldType: 'date',
          formFieldDefaultValue: this.earliestDate
        }
      ]})
    },
    getVariableValue (variableName) {
      return ticketingHelper.getVariableValue(this.processVariables, variableName)
    }
  }
}
</script>

<style scoped lang="postcss">
</style>
