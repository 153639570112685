<template>
  <v-container fluid>
    <v-slide-y-transition mode="out-in">
      <v-card>
        <v-layout class="row pr-4 pl-2 mt-1">
          <v-card-title>{{ $t('navigation.queue') }}</v-card-title>
        </v-layout>

        <v-data-table
          :headers="headers"
          :items="printerQueue"
          :loading="loading"
          :options.sync="options"
          :hide-default-footer="true"
          :footer-props="{itemsPerPageOptions: itemsPerPageOptions}"
          item-key="queueId"
          class="elevation-1"
        >
          <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
          <template v-slot:[`item.createdOn`]="{ item }">
            {{ item.createdOnFormatted }}
          </template>
        </v-data-table>
      </v-card>
    </v-slide-y-transition>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { formatDateAndTime } from '@/helpers/formatterHelper'

export default {
  name: 'PrinterQueue',
  created () {
    this.loadData()
  },
  mounted () {
    setTimeout(function () { this.loadData() }.bind(this), 15000)
  },
  data () {
    return {
      loading: false,
      printerQueue: [],
      itemsPerPageOptions: [-1],
      options: {
        sortBy: ['name']
      }
    }
  },
  computed: {
    headers () {
      return [
        {
          text: this.$t('common.data.name'),
          align: 'left',
          value: 'name',
          sortable: true
        },
        {
          text: this.$t('common.date.title'),
          align: 'left',
          value: 'createdOn',
          sortable: true
        },
        {
          text: this.$t('dossier.printerQueue.state'),
          align: 'left',
          value: 'state'
        },
        {
          text: this.$t('dossier.printerQueue.stateReason'),
          align: 'left',
          value: 'stateReasons'
        }
      ]
    }
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    formatDateAndTimeFunc: formatDateAndTime,
    loadData () {
      this.loading = true
      let that = this
      this.$prestigeDossierPrinter.getPrinterQueue().then(response => {
        that.loading = false
        that.printerQueue = response.data
        if (that.printerQueue.length) {
          for (let queue of that.printerQueue) {
            queue.createdOnFormatted = that.formatDateAndTime(queue.createdOn)
          }
        }
      }).catch(error => {
        that.setErrorSnackbar(error)
      })
    }
  }
}
</script>

<style scoped lang="postcss">
/deep/ tr:nth-child(odd) {
  background: #f8f8f8;
}
/deep/ tr {
  cursor: pointer;
}
</style>
