<template>
  <v-card class="mb-2 pa-0" v-if="links && links.length > 0">
    <v-card-text class="pa-4">
      <v-icon color="primary" @click="toggle" class="float-right">{{ icon }}</v-icon>
      <v-card-subtitle class="pa-0 font-weight-bold subtitle-1">
        {{ $t('navigation.tasks') }}
      </v-card-subtitle>
    </v-card-text>
    <v-divider></v-divider>
    <v-list v-if="isOpen">
      <template v-for="(order, index) in links">
        <OrderLink :key="index" :order-id="order"  />
        <v-divider :key="index + '_divider'"></v-divider>
      </template>
    </v-list>
  </v-card>
</template>
<script>
import OrderLink from './OrderLink'

export default {
  name: 'OrderLinks',
  components: {
    OrderLink
  },
  props: {
    links: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      isOpen: true
    }
  },
  computed: {
    icon () {
      return this.isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
    }
  },
  methods: {
    toggle () {
      this.isOpen = !this.isOpen
    }
  }
}
</script>

<style scoped lang="postcss">
.property-link a {
  text-decoration: none;
}
</style>
