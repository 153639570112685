<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <div>
        <div v-if="taskFinished && taskDetails.dueDate">
          <v-icon color="grey darken-2" :size="22" v-bind="attrs" v-on="on">mdi-calendar-clock</v-icon>
          <span>{{ formatDateAndTimeFunc(taskDetails.dueDate) }}</span>
        </div>
        <div v-if="!taskFinished">
          <v-icon v-if="!show" color="grey darken-2" :size="22" v-bind="attrs" v-on="on">
            mdi-calendar-clock
          </v-icon>
          <DueDateMenu
            :date="taskDetails.dueDate"
            :show="show"
            @updateTaskDueDate="onUpdateTaskDueDate"
            @customDate="onCustomDate"
          />
          <DatePicker
            ref="datePickerDueDate"
            v-if="show"
            :label="$t('ticketingSystem.taskDetails.dueDate')"
            :date-with-time="true"
            :disable-past-dates="true"
            :default-value="true"
            :default-value-date="taskDetails.dueDate"
            :default-time="dueDefaultTime"
            :required="false"
            :attach-selector="attachSelector"
            :menu-open="true"
            @updateFormValues="updateDueDateValue"
            @cancelSetDate="cancelSetDueDate"
          />
        </div>
      </div>
    </template>
    <span>{{ $t('common.date.dueOn') }}</span>
  </v-tooltip>
</template>

<script>
import DueDateMenu from '@/features/ticketing/components/DueDateMenu'
import DatePicker from '@/components/DatePicker'
import { formatDateAndTime } from '@/helpers/formatterHelper'
import { mapActions } from 'vuex'

export default {
  name: 'DueDate',
  components: {
    DueDateMenu,
    DatePicker
  },
  data () {
    return {
      show: false,
      newDueDate: {},
      dueDefaultTime: '17:00:00'
    }
  },
  watch: {
    show (value) {
      if (value) {
        this.$emit('showDatePicker')
      }
    }
  },
  props: {
    taskFinished: {
      type: Boolean,
      default: false
    },
    taskDetails: {
      type: Object,
      default: () => {}
    },
    attachSelector: {
      type: String,
      default: ''
    }
  },
  methods: {
    ...mapActions('notifier', ['setSuccessSnackbar', 'setErrorSnackbar']),
    formatDateAndTimeFunc: formatDateAndTime,
    updateDueDateValue (params) {
      this.newDueDate = params
      if (params) {
        this.onUpdateTaskDueDate(params)
      }
    },
    onUpdateTaskDueDate (newDate, resetDueDate = false) {
      this.$emit('updateDueDate', resetDueDate, newDate, this.taskDetails)
      this.show = false
    },
    onCustomDate () {
      this.show = true
    },
    cancelSetDueDate () {
      this.show = false
    }
  }
}
</script>

<style scoped lang="postcss">
</style>
