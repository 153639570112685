var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"grid-list-md":"","fluid":""}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('feedback.list.feedbackExcel'))+" "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"icon":"","float":""},on:{"click":function($event){return _vm.clearDates()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-calendar-remove")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('feedback.list.clearDates')))])])],1),_c('v-form',{ref:"form"},[_c('v-layout',{staticClass:"row pl-2 pr-3"},[_c('v-flex',{staticClass:"pr-2",attrs:{"xs12":"","sm12":"","md6":"","lg6":"","xl6":""}},[_c('DatePicker',{ref:"datePickerFrom",attrs:{"label":_vm.$t('statistics.date.from'),"from":true,"default-value":true},model:{value:(_vm.form.from),callback:function ($$v) {_vm.$set(_vm.form, "from", $$v)},expression:"form.from"}})],1),_c('v-flex',{staticClass:"pl-2",attrs:{"xs12":"","sm12":"","md6":"","lg6":"","xl6":""}},[_c('DatePicker',{ref:"datePickerTo",attrs:{"label":_vm.$t('statistics.date.to'),"default-value":true},model:{value:(_vm.form.to),callback:function ($$v) {_vm.$set(_vm.form, "to", $$v)},expression:"form.to"}})],1),_c('v-btn',{staticClass:"primary ml-2 mb-5 pl-2 pr-2",attrs:{"loading":_vm.excelLoading},on:{"click":function($event){return _vm.downloadExcel()}}},[_c('v-icon',[_vm._v("file_download")]),_vm._v(" "+_vm._s(_vm.$t('heatingOil.orders.downloadExcel'))+" ")],1)],1)],1)],1),_c('v-card',{staticClass:"mt-4"},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('feedback.list.feedbacks')))]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.feedbacks,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalItems,"footer-props":{itemsPerPageOptions:_vm.itemsPerPageOptions, itemsPerPageText: _vm.itemsPerPageText},"must-sort":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.feedbackRequested",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.feedbackRequestedFormatted)+" ")]}},{key:"item.feedbackFormSubmitted",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.feedbackFormSubmittedFormatted)+" ")]}},{key:"item.feedbackFormOpened",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.feedbackFormOpenedFormatted)+" ")]}},{key:"item.taskDone",fn:function(ref){
var item = ref.item;
return [(item.taskDone)?_c('v-icon',{staticClass:"ml-6",attrs:{"color":"teal","size":"18"}},[_vm._v("mdi-check-outline")]):_c('v-icon',{staticClass:"ml-6",attrs:{"color":"gray","size":"18"}},[_vm._v("mdi-close-outline")])]}},{key:"item.scoreExecution",fn:function(ref){
var item = ref.item;
return [(item.scoreExecution > 0)?_c('v-rating',{attrs:{"readonly":"","small":"","dense":""},model:{value:(item.scoreExecution),callback:function ($$v) {_vm.$set(item, "scoreExecution", $$v)},expression:"item.scoreExecution"}}):_c('span',[_vm._v("n/a")])]}},{key:"item.scoreCraftsman",fn:function(ref){
var item = ref.item;
return [(item.scoreExecution > 0)?_c('v-rating',{attrs:{"readonly":"","small":"","dense":""},model:{value:(item.scoreCraftsman),callback:function ($$v) {_vm.$set(item, "scoreCraftsman", $$v)},expression:"item.scoreCraftsman"}}):_c('span',[_vm._v("n/a")])]}},{key:"item.scorePrestige",fn:function(ref){
var item = ref.item;
return [(item.scorePrestige > 0)?_c('v-rating',{attrs:{"readonly":"","small":"","dense":""},model:{value:(item.scorePrestige),callback:function ($$v) {_vm.$set(item, "scorePrestige", $$v)},expression:"item.scorePrestige"}}):_c('span',[_vm._v("n/a")])]}},{key:"item.comment",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-pre-wrap"},[_vm._v(_vm._s(item.comment))])]}},{key:"item.feedbackReference",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","float":"","disabled":!item.feedbackReference},on:{"click":function($event){return _vm.navigateToTaskDetails(item.feedbackReference)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("link")])],1)]}}],null,true)},[_c('v-progress-linear',{attrs:{"slot":"progress","color":"blue","indeterminate":""},slot:"progress"}),_c('template',{slot:"no-data"},[_c('v-alert',{attrs:{"value":true,"color":"error","icon":"warning"}},[_vm._v(" "+_vm._s(_vm.$t('table.noData'))+" ")])],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }