var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-4"},[(_vm.propertyId)?_c('v-card-text',[_c('v-icon',{staticClass:"float-right",attrs:{"color":"primary"},on:{"click":_vm.toggle}},[_vm._v(_vm._s(_vm.icon))]),_c('v-card-title',{staticClass:"pa-0"},[_vm._v(_vm._s(_vm.$t('navigation.processes')))])],1):_vm._e(),_c('v-divider'),(_vm.isOpen)?_c('div',{staticClass:"pa-2 ml-2 mb-2"},[_c('v-layout',{staticClass:"processes-list mt-1"},[_c('v-flex',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.processes,"loading":_vm.loading,"footer-props":{itemsPerPageOptions: _vm.itemsPerPageOptions, itemsPerPageText: _vm.itemsPerPageText},"server-items-length":_vm.totalItems,"item-class":_vm.rowClass,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.goToProcessDetails},scopedSlots:_vm._u([{key:"item.processSubject",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"truncate-two-lines"},[_vm._v(_vm._s(item.processSubject))])]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('StateIcon',{attrs:{"state":item.state}})]}},{key:"item.startedOn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.startedOnFormatted)+" ")]}},{key:"item.finishedOn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.finishedOnFormatted)+" ")]}},{key:"item.startUserId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.startUser)+" ")]}}],null,true)},[_c('v-progress-linear',{attrs:{"slot":"progress","color":"blue","indeterminate":""},slot:"progress"}),_c('template',{slot:"no-data"},[_c('v-alert',{staticClass:"mt-4",attrs:{"value":true,"color":"error","icon":"warning"}},[_vm._v(" "+_vm._s(_vm.$t('table.noData'))+" ")])],1)],2)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }