var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-title',{staticClass:"pl-0"},[_vm._v(_vm._s(_vm.$t('navigation.heatingOil')))]),(_vm.supplierStats.length > 0)?_c('v-card-title',{staticClass:"subtitle-1 pa-0"},[_vm._v(_vm._s(_vm.$t('statistics.totalAmountOil'))+": "+_vm._s(_vm.totalAmount)+" L")]):_vm._e(),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg6":"","xl6":""}},[_c('v-data-table',{staticClass:"elevation-1 mr-2 ml-2 mt-2",class:_vm.$ticketingEnabled ? 'suppliers-table' : '',attrs:{"headers":_vm.supplierHeaders,"items":_vm.supplierStats,"loading":_vm.heatingOilStatsLoading,"options":_vm.supplierOptions,"footer-props":{ itemsPerPageOptions: _vm.supplierItemsPerPageOptions, itemsPerPageText: _vm.supplierItemsPerPageText }},on:{"update:options":function($event){_vm.supplierOptions=$event},"click:row":_vm.displaySupplierOrders},scopedSlots:_vm._u([{key:"item.supplierAmount",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.supplierAmountFormatted))])]}}],null,true)},[_c('template',{slot:"no-data"},[_c('v-alert',{attrs:{"value":true,"color":"error","icon":"warning"}},[_vm._v(" "+_vm._s(_vm.$t('table.noData'))+" ")])],1)],2)],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg6":"","xl6":""}},[_c('v-data-table',{staticClass:"elevation-1 ml-2 mt-2 mr-2",attrs:{"headers":_vm.fillingLevelHeaders,"items":_vm.fillingLevelStats,"loading":_vm.heatingOilStatsLoading,"hide-default-footer":true,"footer-props":{itemsPerPageOptions: _vm.itemsPerPageOptions}},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.dateFormatted))])]}},{key:"item.fillingLevel",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.volumeInfo))])]}}],null,true)},[_c('template',{slot:"no-data"},[_c('v-alert',{attrs:{"value":true,"color":"error","icon":"warning"}},[_vm._v(" "+_vm._s(_vm.$t('table.noData'))+" ")])],1)],2)],1),(_vm.$ticketingEnabled)?_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-card',{staticClass:"ml-20",attrs:{"light":""}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t('heatingOil.orders.supplierOrders')))]),_c('v-icon',{staticClass:"float-right",attrs:{"color":"primary"},on:{"click":function($event){_vm.overlay = false}}},[_vm._v("close")])],1),_c('v-container',[_c('v-layout',{staticClass:"overlay-content",attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":"","xl12":""}},[_c('v-data-table',{staticClass:"elevation-1 mr-4 ml-4 mb-2 mt-0",attrs:{"headers":_vm.supplierOrdersHeaders,"items":_vm.supplierOrders,"loading":_vm.supplierOrdersLoading,"options":_vm.supplierOrdersOptions},on:{"update:options":function($event){_vm.supplierOrdersOptions=$event}},scopedSlots:_vm._u([{key:"item.amountSum",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.amountInfo))])]}},{key:"item.orderedOn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.orderedOnFormatted)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mx-0",attrs:{"icon":"","title":_vm.$t('heatingOil.orders.downloadExcel'),"disabled":item.excelLoading,"loading":item.excelLoading},on:{"click":function($event){$event.stopPropagation();return _vm.downloadExcel(item)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("file_download")])],1)]}}],null,true)},[_c('template',{slot:"no-data"},[_c('v-alert',{attrs:{"value":true,"color":"error","icon":"warning"}},[_vm._v(" "+_vm._s(_vm.$t('table.noData'))+" ")])],1)],2)],1)],1)],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }