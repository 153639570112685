<template>
  <v-layout class="ma-4 flex-column">
    <ChartsHeaderFilters @updateFilters="onUpdateFilters" />
    <v-container fluid>
      <v-row>
        <v-col>
          <OpenCompletedTasks :filters="filters"></OpenCompletedTasks>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <CompletedTasksByAssignee
            v-if="completedTasksByAssigneeChartData"
            @hideCompletedTasksByAssigneeChartData="hideCompletedTasksByAssigneeChartData" :filters="filters"
          >
          </CompletedTasksByAssignee>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <OpenTasksByAssignee :filters="filters"></OpenTasksByAssignee>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <OpenTasksByCandidateGroup :filters="filters"></OpenTasksByCandidateGroup>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <UserOpenTasksByAssignee v-if="userOpenTasksByAssigneeChartData" @hideUserOpenTasksByAssigneeChart="hideUserOpenTasksByAssigneeChart" :filters="filters"></UserOpenTasksByAssignee>
        </v-col>
      </v-row>
    </v-container>
  </v-layout>
</template>

<script>
import ChartsHeaderFilters from './components/ChartsHeaderFilters'
import OpenCompletedTasks from '@/features/ticketing-statistics/components/OpenCompletedTasks'
import CompletedTasksByAssignee from '@/features/ticketing-statistics/components/CompletedTasksByAssignee'
import OpenTasksByAssignee from '@/features/ticketing-statistics/components/OpenTasksByAssignee'
import OpenTasksByCandidateGroup from '@/features/ticketing-statistics/components/OpenTasksByCandidateGroup'
import UserOpenTasksByAssignee from '@/features/ticketing-statistics/components/UserOpenTasksByAssignee.vue'

export default {
  name: 'TicketingStatistics',
  components: {
    ChartsHeaderFilters,
    CompletedTasksByAssignee,
    OpenCompletedTasks,
    OpenTasksByAssignee,
    OpenTasksByCandidateGroup,
    UserOpenTasksByAssignee
  },
  data () {
    return {
      labels: [],
      filters: null,
      completedTasksByAssigneeChartData: true,
      userOpenTasksByAssigneeChartData: true
    }
  },
  watch: {
    'filters': ['hideUserOpenTasksByAssigneeChart', 'hideCompletedTasksByAssigneeChartData']
  },
  methods: {
    onUpdateFilters (filters) {
      this.filters = filters
    },
    hideUserOpenTasksByAssigneeChart (value) {
      this.userOpenTasksByAssigneeChartData = value
    },
    hideCompletedTasksByAssigneeChartData (value) {
      this.completedTasksByAssigneeChartData = value
    }
  }
}
</script>
