<template>
  <v-flex class="mt-3" :class="moduleName === 'task' ? 'xs6 sm6 md6 lg6 xl6' : 'xs12 sm12 md12 lg12 xl12'">
    <v-card height="100%" class="attachments">
      <v-card-title>
        <v-container fluid grid-list-xs class="pa-0">
          <div v-if="showInfoAlert">
            <v-alert type="info">
              {{ $t('tasks.createTask.fileMessage') }}
            </v-alert>
          </div>
          <div v-else>
            <TicketFileVariables
              ref="ticketFileVariables"
              module-name="fileWithCheckbox"
              :process-instance-id="processInstanceId"
              :process-variables="processVariables"
              @updateAttachmentIds="onUpdateAttachmentIds"
            />
            <v-list two-line subheader>
              <FileDisplay
                v-for="file in files"
                v-bind:file="file"
                :key="file.fileId || file.id"
                :module-name="moduleName"
                @removeFile="onRemoveFile"
                @updateFile="onUpdateFile"
              />
            </v-list>
            <p v-if="moduleName==='task' && files && files.length > 0" class="caption red--text">{{ $t('tasks.attachments.attachmentCheckboxInfo') }}</p>
            <FileUpload v-bind:files="files" :module-name="moduleName" :read-only="readOnly" @addFile="onAddFile" @removeFile="onRemoveFile" />
          </div>
        </v-container>
      </v-card-title>
    </v-card>
  </v-flex>
</template>

<script>
import FileUpload from './FileUpload'
import FileDisplay from './FileDisplay'
import TicketFileVariables from '@/features/ticketing/shared/TicketFileVariables'
import Vue from 'vue'
import _cloneDeep from 'lodash.clonedeep'
import { mapActions } from 'vuex'

export default {
  name: 'FileAttachments',
  components: {
    FileUpload,
    FileDisplay,
    TicketFileVariables
  },
  props: {
    files: {
      type: Array,
      default: () => []
    },
    showInfoAlert: {
      type: Boolean,
      default: false
    },
    moduleName: {
      type: String,
      default: ''
    },
    processInstanceId: {
      type: String,
      default: ''
    },
    processVariables: {
      type: Array,
      default: () => []
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...mapActions('notifier', ['setInfoSnackbar', 'setSuccessSnackbar']),
    onAddFile (file) {
      this.$emit('addFile', file)
    },
    onRemoveFile (removedFile) {
      let index = this.files.indexOf(removedFile)
      if (index !== -1) {
        this.$emit('removeFile', index)
        if (this.moduleName === 'task') {
          this.setInfoSnackbar(this.$t('common.messages.saveChanges'))
        } else if (this.moduleName === 'emailDialog') {
          this.setSuccessSnackbar(this.$t('common.messages.successfullyDeleted'))
        }
      }
    },
    onUpdateFile (file) {
      let index = -1
      if (file.fileId) {
        index = this.files.findIndex(x => x.fileId === file.fileId)
      } else if (file.id) {
        index = this.files.findIndex(x => x.id === file.id)
      }
      if (index !== -1) {
        this.$emit('updateFile', file, index)
      }
    },
    onUpdateAttachmentIds (attachmentId, addInEmail) {
      this.$emit('updateAttachmentIds', attachmentId, addInEmail)
    }
  }
}
</script>
