const employed = 'EMPLOYED'
const selfEmployed = 'SELF_EMPLOYED'
const pensioner = 'PENSIONER'
const searchingForJob = 'SEARCHING_FOR_JOB'

export const employmentStatuses = {
  employed,
  selfEmployed,
  pensioner,
  searchingForJob,
  options: [
    employed,
    selfEmployed,
    pensioner,
    searchingForJob
  ]
}
