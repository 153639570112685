<template>
  <div>
    <v-row dense>
      <v-col cols="12" sm="6">
        <v-checkbox
          :label="$t('ticketingSystem.entry.finishImmediately')"
          dense
          v-model="variables.finishImmediately"
        ></v-checkbox>
      </v-col>
      <v-col cols="12" sm="6">
        <TicketPriority v-model="variables.priority" />
      </v-col>
    </v-row>
    <template v-if="!variables.finishImmediately">
      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="pl-0 pb-0">
            {{ $t('ticketingSystem.note.selectCandidateGroup') }}
          </v-card-text>
          <v-checkbox
            v-model="variables.selectedCandidateGroups"
            v-for="candidateGroup in candidateGroups"
            class="mt-1"
            dense
            hide-details
            multiple
            :key="'group-' + candidateGroup.groupId"
            :value="candidateGroup.groupId"
            :label="getCandidateGroupNameTranslation(candidateGroup.groupId)"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card-text class="pl-0">
            {{ $t('ticketingSystem.note.selectAssignee') }}
          </v-card-text>
          <TaskAssignee
            v-bind:form="assignee"
            :assigned-to-me="assignedToMe"
            :user-has-ticketing-role="true"
            @changedAssignee="onChangedAssignee"
            @hideAssigneeDropdown="showAssigneeDropdown = false"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <DueDate
            :task-finished="false"
            :task-details="variables"
            attach-selector=".datepicker-input"
            @updateDueDate="onUpdateDueDateValue"
            @showDatePicker="$emit('showDatePicker')"
          />
        </v-col>
      </v-row>
    </template>
    <v-row dense>
      <v-col>
        <v-card-text class="pl-0">
          {{ $t('ticketingSystem.note.files') }}
        </v-card-text>
        <TicketFileVariables
          :process-variables="fileUploads"
          :process-instance-id="processInstanceId"
          @addFileVariable="onAddFileVariable"
          @removeFileVariable="onRemoveFileVariable"
          @loadProcessVariables="onLoadProcessVariables"
        />
      </v-col>
    </v-row>
    <ChecklistItemsVariables
      v-if="!variables.finishImmediately"
      :process-variables="fileUploads"
      :process-instance-id="processInstanceId"
      @updateChecklistItemVariables="onUpdateChecklistItemVariables"
    />
  </div>
</template>

<script>
import DueDate from '@/features/ticketing/components/DueDate'
import TicketPriority from '@/features/ticketing/components/TicketPriority'
import TaskAssignee from '../../task-details/TaskAssignee'
import TicketFileVariables from '../TicketFileVariables'
import ChecklistItemsVariables from '../ChecklistItemsVariables'
import { ticketingZ101Constants } from '@/config/options/ticketing_Z1_01_constants'

import { AuthenticationContext } from 'vue-adal'
import { mapState } from 'vuex'
import { getCandidateGroupName } from '@/helpers/candidateGroupsHelper'

export default {
  name: 'TicketStart',
  components: {
    TicketPriority,
    TaskAssignee,
    DueDate,
    TicketFileVariables,
    ChecklistItemsVariables
  },
  props: {
    processInstanceId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      assignee: { value: { userId: 'unassigned' } },
      showAssigneeDropdown: false,
      variables: {
        finishImmediately: false,
        selectedAssignee: '',
        dueDate: null,
        followUpDate: null,
        selectedCandidateGroups: [],
        priority: 2
      },
      fileUploads: [],
      checklistItems: []
    }
  },
  watch: {
    variables: {
      handler () {
        this.onUpdatedField()
      },
      deep: true
    }
  },
  mounted () {
    this.variables.selectedAssignee = null
    this.variables.finishImmediately = false
  },
  computed: {
    ...mapState('ticketingSystem', ['candidateGroups']),
    loggedInUserId () {
      return AuthenticationContext && AuthenticationContext.user && AuthenticationContext.user.profile ? AuthenticationContext.user.profile.employeeid : null
    },
    assignedToMe () {
      return this.variables.selectedAssignee === this.loggedInUserId
    }
  },
  methods: {
    getCandidateGroupNameTranslation (groupId) {
      return getCandidateGroupName(groupId)
    },
    onChangedAssignee () {
      if (this.assignee.value && this.assignee.value.userId === 'assignToMe') {
        this.variables.selectedAssignee = this.loggedInUserId
      } else if (this.assignee.value && this.assignee.value.userId !== 'unassigned') {
        this.variables.selectedAssignee = this.assignee.value.userId
      } else {
        this.variables.selectedAssignee = null
      }
    },
    onUpdateDueDateValue (resetDueDate = false, newDueDate) {
      this.variables.dueDate = !resetDueDate ? newDueDate : null
    },
    onAddFileVariable (file, variableName) {
      if (file) {
        this.fileUploads.push({
          variableName: variableName,
          variableType: file?.variableType || 'azureFile',
          variableValue: file
        })
      }
    },
    onRemoveFileVariable (variableName) {
      let index = this.fileUploads.findIndex(x => x.variableName === variableName)
      if (index !== -1) {
        this.fileUploads.splice(index, 1)
      }
    },
    onLoadProcessVariables (file, variableName) {
      let index = this.fileUploads.findIndex(x => x.variableValue.fileId === file.fileId)
      if (index !== -1) {
        let variableName = this.fileUploads[index].variableName
        this.onRemoveFileVariable(variableName)
        this.onAddFileVariable(file, variableName)
      }
    },
    onUpdateChecklistItemVariables (checklistItems) {
      this.checklistItems = checklistItems
    },
    onUpdatedField () {
      this.$emit('updateFormValues', this.variables)
    },
    setTicketStartProcessDTOVariables () {
      let variables = [
        {
          variableName: ticketingZ101Constants.COMPLETE_IMMEDIATELY,
          variableType: 'boolean',
          variableValue: this.variables.finishImmediately
        },
        {
          variableName: ticketingZ101Constants.PRIORITY,
          variableType: 'long',
          variableValue: this.variables.priority
        }
      ]
      if (this.variables.selectedCandidateGroups && this.variables.selectedCandidateGroups.length > 0) {
        variables.push({
          variableName: ticketingZ101Constants.CANDIDATE_GROUPS,
          variableType: 'string',
          variableValue: this.variables.selectedCandidateGroups
            ? this.variables.selectedCandidateGroups.join(',')
            : ''
        })
      }
      if (!this.variables.finishImmediately) {
        if (this.variables.selectedAssignee !== null) {
          variables.push({
            variableName: ticketingZ101Constants.ASSIGNEE,
            variableType: 'string',
            variableValue: this.variables?.selectedAssignee || null
          })
        }
        if (this.variables.dueDate !== null) {
          variables.push({
            variableName: ticketingZ101Constants.DUE_DATE,
            variableType: 'date',
            variableValue: this.variables.dueDate
          })
        }
        if (this.checklistItems.length > 0) {
          variables.push({
            variableName: ticketingZ101Constants.CHECKLIST_ITEMS,
            variableType: 'json',
            variableValue: this.checklistItems
          })
        }
      }
      this.fileUploads.forEach(fileUpload => variables.push({
        variableName: fileUpload.variableName,
        variableType: fileUpload.variableType,
        variableValue: fileUpload.variableValue.fileId
      }))
      return variables
    }
  }
}
</script>
