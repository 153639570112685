<template>
  <v-container fluid class="grey lighten-5 h-100">
    <v-layout layout wrap class="filters mt-1 mb-1" id="filters">
      <v-flex shrink class="mr-4">
        <v-card-subtitle class="pa-0 mt-1 ml-1 mb-1 font-weight-bold subtitle-1">
          {{ $t('ticketingSystem.list.filter') }}
        </v-card-subtitle>
      </v-flex>
      <v-flex grow class="mr-4">
        <v-autocomplete
          class="ma-0 pa-0"
          :label="$t('ticketingSystem.list.filters.searchUser')"
          :loading="userLoading"
          cache-items
          return-object
          :items="ticketingUsersDisplayItems"
          item-text="displayText"
          item-value="userId"
          @change="changedUserValue"
          v-model="selectedUser"
          clearable
        />
      </v-flex>
      <v-flex grow class="mr-4">
        <v-autocomplete
          class="ma-0 pa-0"
          :label="$t('ticketingSystem.list.filters.searchTicket')"
          :loading="processDefinitionsLoading"
          cache-items
          :items="listProcessDefinitions"
          item-text="processDefinitionName"
          item-value="processDefinitionKey"
          @change="changedProcessDefinitionValue"
          v-model="selectedProcessDefinitionKey"
          clearable
        ></v-autocomplete>
      </v-flex>
      <v-flex grow class="mr-4">
        <v-autocomplete
          class="ma-0 pa-0"
          :label="$t('ticketingSystem.list.filters.searchProperty')"
          :loading="abacusPropertiesLoading"
          cache-items
          return-object
          :items="abacusPropertiesDisplayItems"
          :filter="customPropertyFilter"
          item-text="displayText"
          item-value="propertyId"
          @change="changedPropertyValue"
          v-model="selectedProperty"
          clearable
        />
      </v-flex>
      <v-flex grow class="mr-4">
        <v-autocomplete
          ref="tenantsAutocomplete"
          class="ma-0 pa-0"
          :label="$t('ticketingSystem.list.filters.searchTenant')"
          :loading="tenantsLoading"
          return-object
          :items="tenantDisplayItems"
          item-text="displayText"
          item-value="addressId"
          @change="changedTenantValue"
          @focus="getTenantsForPropertyId"
          :search-input.sync="searchTenants"
          v-model="selectedTenant"
          clearable
        />
      </v-flex>
      <v-flex grow class="mr-4">
        <v-autocomplete
          class="ma-0 pa-0"
          :label="$t('ticketingSystem.list.filters.searchCaretaker')"
          :loading="caretakersLoading"
          return-object
          :items="caretakerDisplayItems"
          item-text="displayText"
          item-value="addressId"
          @change="changedCaretakerValue"
          :search-input.sync="searchCaretakers"
          v-model="selectedCaretaker"
          clearable
        />
      </v-flex>
      <v-flex shrink class="mt-2 mr-2">
        <v-checkbox
          class="ma-0 pa-0 checkbox"
          :label="$t('ticketingSystem.list.filters.displayAssignedTasks')"
            v-model="includeAssigned"
          @change="changedIncludeAssigned(includeAssigned)"
        ></v-checkbox>
      </v-flex>
      <!--
      <v-flex shrink class="mt-2 mr-2">
        <v-checkbox
          class="ma-0 pa-0 checkbox"
          :label="$t('ticketingSystem.list.filters.displayFollowUpTasks')"
          v-model="includeFollowUpInFuture"
          @change="changedIncludeFollowUp(includeFollowUpInFuture)"
        ></v-checkbox>
      </v-flex>
      -->
      <v-flex shrink class="mt-2 mr-2">
        <v-switch
          class="ma-0"
          v-model="selectedPriorities"
          :label="$t('ticketingSystem.priority.title') + ' ' + $t('ticketingSystem.priority.priority1')"
          :value="1"
          @change="changedSelectedPriorities"
        ></v-switch>
      </v-flex>
      <v-flex shrink class="mt-2 mr-2">
        <v-switch
          class="ma-0"
          v-model="selectedPriorities"
          :label="$t('ticketingSystem.priority.title') + ' ' + $t('ticketingSystem.priority.priority2')"
          :value="2"
          @change="changedSelectedPriorities"
        ></v-switch>
      </v-flex>
      <v-flex shrink class="mt-1">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="clear ml-2 mr-1" color="primary" :size="28" @click="clearFilters" v-bind="attrs" v-on="on" />
          </template>
          <span>{{ $t('ticketingSystem.list.clear') }}</span>
        </v-tooltip>
      </v-flex>
    </v-layout>
    <v-layout class="ticketing-list" id="ticketingList">
      <template v-if="dataReady">
        <TicketingColumn
          ref="personalTickets"
          v-if="(detailsView && columnId === 'personal') || !detailsView"
          class="border-right"
          :title="$t('ticketingSystem.list.personally')"
          :column-id="'personal'"
          :hide-assignee="true"
          :ticketing-list-filters="ticketingListFilters"
          :infinite-id-change="infiniteId"
          @openTaskDetailsDialog="openTaskDetailsDialog"
        />
        <TicketingColumn
          ref="generalTickets"
          v-if="(detailsView && columnId === 'general') || !detailsView"
          class="border-right"
          :column-id="'general'"
          :title="$t('ticketingSystem.list.general')"
          :ticketing-list-filters="ticketingListFilters"
          :infinite-id-change="infiniteId"
          @openTaskDetailsDialog="openTaskDetailsDialog"
        />
        <TicketingColumn
          class="border-right"
          v-for="(group, i) in candidateGroups"
          :column-id="group.groupId"
          :title="getCandidateGroupNameTranslation(group.groupId)"
          :group-tasks="true"
          :candidate-groups="candidateGroups"
          :render-column="(detailsView && columnId === group.groupId) || !detailsView"
          :key="i"
          :ticketing-list-filters="ticketingListFilters"
          :infinite-id-change="infiniteId"
          @openTaskDetailsDialog="openTaskDetailsDialog"
        />
        <TaskDetailsDialog
          v-if="showTaskDetailsDialog"
          :show-dialog="showTaskDetailsDialog"
          @closeDialog="closeDialog"
          @openTaskDetailsDialog="openTaskDetailsDialog"
          @reloadTasks="reloadTasks"
        />
      </template>
    </v-layout>
    <SpinnerOverlay :loading="loading" />
  </v-container>
</template>

<script>
import TicketingColumn from './TicketingColumn'
import TaskDetailsDialog from '../dialogs/TaskDetailsDialog'
import SpinnerOverlay from '@/components/SpinnerOverlay'
import * as ticketingHelper from '@/features/ticketing/helpers/ticketingHelper'
import * as globalHelper from '@/helpers/globalHelper'
import { getCandidateGroupName } from '@/helpers/candidateGroupsHelper'

import { mapActions, mapState } from 'vuex'

export default {
  name: 'TicketingList',
  components: {
    TicketingColumn,
    TaskDetailsDialog,
    SpinnerOverlay
  },
  props: {
    taskId: {
      type: String,
      default: ''
    },
    detailsView: {
      type: Boolean,
      default: false
    },
    columnId: {
      type: String,
      default: 'personal'
    }
  },
  data () {
    return {
      dataReady: false,
      spinnerOverlay: false,
      firstSpinner: true,
      filters: {},
      timer: [],
      tenantItems: [],
      tenantDisplayItems: [],
      caretakerItems: [],
      caretakerDisplayItems: [],
      userLoading: false,
      tenantsLoading: false,
      caretakersLoading: false,
      searchCaretakers: null,
      searchTenants: null,
      selectedProcessDefinitionKey: null,
      selectedCaretaker: null,
      selectedProperty: null,
      selectedUser: null,
      selectedTenant: null,
      selectedPriorities: [1, 2],
      includeAssigned: false,
      includeFollowUpInFuture: true,
      infiniteId: 0,
      showTaskDetailsDialog: false
    }
  },
  watch: {
    candidateGroupTasksLoading () {
      if (!this.candidateGroupTasksLoading) {
        this.stopLoading()
      }
    },
    searchCaretakers (val) {
      if (val) {
        this.caretakerItems = []
        this.caretakerDisplayItems = []
        this.caretakersLoading = true
        this.setCaretakerItems(val)
      }
    },
    searchTenants (val) {
      if (val && this.selectedTenant === null) {
        this.tenantItems = []
        this.tenantDisplayItems = []
        this.tenantsLoading = true
        this.$forceUpdate()
        if (this.selectedProperty) {
          this.getTenantsForPropertyId()
        } else {
          this.setTenantsItems(val)
        }
      }
    }
  },
  created () {
    window.addEventListener('resize', this.windowResizeHandler)
    Promise.all([
      this.$store.dispatch('abacus/loadAbacusProperties'),
      this.$store.dispatch('ticketingSystem/loadProcessDefinitions'),
      this.$store.dispatch('ticketingSystem/loadTicketingUsers'),
      this.$store.dispatch('ticketingSystem/loadCandidateGroups')
    ]).then(() => {
      this.dataReady = true
      this.initDefaultFilters()
    })
  },
  destroyed () {
    window.removeEventListener('resize', this.windowResizeHandler)
  },
  computed: {
    ...mapState('abacus', ['abacusProperties', 'abacusPropertiesDisplayItems', 'abacusPropertiesLoading']),
    ...mapState('ticketingSystem', [
      'ticketingUsers', 'ticketingUsersDisplayItems',
      'ticketingListFilters', 'assignedTasksLoading', 'taskEntriesLoading',
      'candidateGroups', 'candidateGroupTasksLoading',
      'processDefinitionsLoading', 'listProcessDefinitions'
    ]),
    loading () {
      return this.spinnerOverlay || !this.dataReady
    }
  },
  mounted () {
    this.windowResizeHandler()
    if (this.$route.query.taskId) {
      this.showTaskDetailsDialog = true
    }
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    getCandidateGroupNameTranslation (groupId) {
      return getCandidateGroupName(groupId)
    },
    windowResizeHandler () {
      this.$nextTick(() => {
        ticketingHelper.setTicketingListHeight()
        ticketingHelper.setTicketingColumnHeight()
      })
    },
    startLoading () {
      this.spinnerOverlay = true
    },
    stopLoading () {
      this.firstSpinner = false
      this.spinnerOverlay = false
    },
    async initDefaultFilters () {
      if (this.ticketingListFilters.property) {
        this.selectedProperty = this.abacusPropertiesDisplayItems.find(x => x.propertyId.toString() === this.ticketingListFilters.property.toString())
      }
      if (this.ticketingListFilters.user) {
        this.selectedUser = this.ticketingUsersDisplayItems.find(x => x.userId.toString() === this.ticketingListFilters.user.toString())
      }
      if (this.ticketingListFilters.processDefinitionKey) {
        this.selectedProcessDefinitionKey = this.ticketingListFilters.processDefinitionKey
      }
      if (this.ticketingListFilters.tenant) {
        let addressDisplayItem = await this.getAddressById(this.ticketingListFilters.tenant)
        this.tenantDisplayItems.push(addressDisplayItem)
        this.selectedTenant = addressDisplayItem
      }
      if (this.ticketingListFilters.caretaker) {
        let addressDisplayItem = await this.getAddressById(this.ticketingListFilters.caretaker)
        this.caretakerDisplayItems.push(addressDisplayItem)
        this.selectedCaretaker = addressDisplayItem
      }
      this.includeAssigned = this.ticketingListFilters.includeAssigned
      if (this.ticketingListFilters.includeFollowUpInFuture) {
        this.includeFollowUpInFuture = this.ticketingListFilters.includeFollowUpInFuture
      }
      if (this.ticketingListFilters.priorities) {
        this.selectedPriorities = this.ticketingListFilters.priorities
      }
    },
    async getAddressById (addressId) {
      let that = this
      return this.$abacus.getAddressById(addressId).then(function (response) {
        let data = response.data
        let displayListItem = {
          addressId: data.id,
          displayText: globalHelper.setDisplayText(data)
        }
        return displayListItem
      }).catch(function (error) {
        that.setErrorSnackbar(error)
      })
    },
    async getTenantsForPropertyId () {
      if (this.selectedProperty && this.selectedProperty.propertyId) {
        this.tenantsLoading = true
        this.tenantDisplayItems = []
        try {
          let response = await this.$abacusProperty.getTenantsForPropertyId(this.selectedProperty.propertyId)
          if (response.data.length) {
            let that = this
            let data = response.data
            this.tenantItems = globalHelper.sortByFirstNameAndLastName(data)
            this.tenantItems.forEach(item => {
              let displayListItem = {
                addressId: item.id,
                displayText: globalHelper.setDisplayText(item)
              }
              that.tenantDisplayItems.push(displayListItem)
            })
            this.tenantsLoading = false
          }
        } catch (error) {
          this.tenantsLoading = false
          this.tenantItems = []
          this.tenantDisplayItems = []
          this.setErrorSnackbar(error)
        }
      }
    },
    setTenantsItems (searchString) {
      this.tenantsLoading = true
      let queryParams = { query: { searchString: searchString, tenant: true } }
      this.tenantDisplayItems = []
      let that = this
      this.$abacus.getAllAddressesV2(undefined, queryParams).then(response => {
        if (response.data.length) {
          that.tenantItems = response.data
          that.tenantItems = globalHelper.sortByFirstNameAndLastName(that.tenantItems)
          that.tenantItems.forEach(item => {
            let displayListItem = {
              addressId: item.id,
              displayText: globalHelper.setDisplayText(item)
            }
            that.tenantDisplayItems.push(displayListItem)
          })
        }
        that.tenantsLoading = false
      }).catch(function (error) {
        that.tenantsLoading = false
        that.tenantItems = []
        that.tenantDisplayItems = []
        that.setErrorSnackbar(error)
      })
    },
    setCaretakerItems (searchString) {
      let that = this
      this.caretakersLoading = true
      let queryParams = { query: { searchString: searchString, caretaker: true } }
      that.caretakerDisplayItems = []
      this.$abacus.getAllAddressesV2(undefined, queryParams).then(response => {
        if (response.data.length) {
          that.caretakerItems = response.data
          that.caretakerItems = globalHelper.sortByFirstNameAndLastName(that.caretakerItems)
          that.caretakerItems.forEach(item => {
            let displayListItem = {
              addressId: item.id,
              displayText: globalHelper.setDisplayText(item)
            }
            that.caretakerDisplayItems.push(displayListItem)
          })
        }
        that.caretakersLoading = false
      }).catch(function (error) {
        that.caretakersLoading = false
        that.caretakerItems = []
        that.caretakerDisplayItems = []
        that.setErrorSnackbar(error)
      })
    },
    changedUserValue () {
      this.filters.user = this.selectedUser && this.selectedUser.userId
      this.updateFilters()
    },
    changedProcessDefinitionValue (value) {
      this.filters.processDefinitionKey = this.selectedProcessDefinitionKey
      this.updateFilters()
    },
    changedPropertyValue () {
      this.filters.property = this.selectedProperty && this.selectedProperty.propertyId
      this.tenantItems = []
      this.tenantDisplayItems = []
      if (this.filters.property) {
        this.$refs.tenantsAutocomplete.cachedItems = []
        this.selectedTenant = null
      }
      this.updateFilters()
    },
    changedTenantValue () {
      this.filters.tenant = this.selectedTenant?.addressId
      this.updateFilters()
    },
    changedCaretakerValue () {
      this.filters.caretaker = this.selectedCaretaker && this.selectedCaretaker.addressId
      this.updateFilters()
    },
    changedIncludeAssigned (value) {
      this.filters.includeAssigned = value
      this.updateFilters()
    },
    changedIncludeFollowUp (value) {
      this.filters.includeFollowUpInFuture = value
      this.updateFilters()
    },
    changedSelectedPriorities (value) {
      this.filters.priorities = this.selectedPriorities
      this.updateFilters()
    },
    updateFilters () {
      let updatedFilters = Object.assign(this.ticketingListFilters, this.filters)
      this.filters = Object.fromEntries(Object.entries(updatedFilters).filter(([_, v]) => v != null))
      this.reloadTasks()
    },
    clearFilters () {
      this.setFiltersNullValues()
    },
    setFiltersNullValues () {
      this.filters = {}
      this.selectedUser = null
      this.selectedProperty = null
      this.selectedTenant = null
      this.selectedCaretaker = null
      this.selectedProcessDefinitionKey = null
      this.includeAssigned = false
      this.includeFollowUpInFuture = true
      this.tenantItems = []
      this.tenantDisplayItems = []
      this.caretakerItems = []
      this.caretakerDisplayItems = []
      this.selectedPriorities = [1, 2]
      this.$store.dispatch('ticketingSystem/clearTicketingListFilters')
      this.reloadTasks()
    },
    customPropertyFilter (item, queryText, itemText) {
      return globalHelper.propertyFilter(queryText, itemText)
    },
    openTaskDetailsDialog (taskEntryId) {
      const query = { ...this.$route.query, taskId: taskEntryId }
      this.$router.replace({ query })
      this.showTaskDetailsDialog = true
    },
    closeDialog () {
      this.showTaskDetailsDialog = false
      if (this.$route.query.taskId) {
        const query = Object.assign({}, this.$route.query)
        delete query.taskId
        this.$router.replace({ query })
        this.reloadTasks()
      }
    },
    reloadTasks () {
      this.infiniteId += 1
    }
  }
}
</script>

<style scoped lang="postcss">
.ticketing-list {
  -webkit-box-shadow: inset 0 0 6px lightgray;
  width: 100%;
  background-color: #fff;
  height: 100%;
  border-radius: 4px;
  border: 2px solid lightgray;
  overflow-x: auto;
  overflow-y: hidden;
}
/deep/ .grow {
  max-width: 320px;
}
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px lightgray;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(99, 99, 99, .7);
  background-color: #fff;
}
.border-right {
  border-right: 2px solid lightgray;
}
.spinner {
  margin-left: 350px;
}
/deep/ .filters .v-input__control {
  margin-bottom: 0px;
  height: 40px;
}
/deep/ .filters .checkbox .v-input__control {
  height: 20px;
}
/deep/ .filters .checkbox .v-input__slot {
  margin-bottom: 0;
}
</style>
