<template>
  <div>
    <div v-if="!isFileWithCheckbox" :class="{'pa-4': processView }">
      <VariableFileUpload
        :required="false"
        :disabled="disabled"
        :process-instance-id="processInstanceId"
        :file-variables="systemTicketFileVariables"
        @addFileVariable="onAddFileVariable"
        @removeFileVariable="onRemoveFileVariable"
        @loadProcessVariables="onLoadProcessVariables"
      />
    </div>
    <v-divider class="mt-2"></v-divider>
    <v-data-iterator
      v-if="allFileVariables.length > 0"
      :items="allFileVariables"
      :sort-by="sortBy.toLowerCase()"
      :sort-desc="sortDesc"
      :multi-sort="true"
      :custom-sort="customSort"
      hide-default-footer
    >
      <template v-slot:header>
        <v-toolbar
          light
          class="mb-1"
        >
          <v-list-item class="ma-0 pa-0">
            <v-list-item-content>
              <v-select
                v-model="sortBy"
                flat
                solo
                shaped
                hide-details
                item-value="value"
                item-text="label"
                :items="keys"
                :prepend-inner-icon="sortDesc ? 'mdi-arrow-down' : 'mdi-arrow-up'"
              ></v-select>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn-toggle
                class="ml-4"
                v-model="sortDesc"
                mandatory
                rounded
              >
                <v-btn
                  small
                  depressed
                  :value="false"
                >
                  <v-icon>mdi-arrow-up</v-icon>
                </v-btn>
                <v-btn
                  small
                  depressed
                  :value="true"
                >
                  <v-icon>mdi-arrow-down</v-icon>
                </v-btn>
              </v-btn-toggle>
            </v-list-item-action>
          </v-list-item>
        </v-toolbar>
      </template>
      <template v-slot:default="props">
        <v-list class="files-list pa-0">
          <template v-for="(item, index) in props.items">
            <v-list-item
                class="pa-0"
                two-line
                :key="item.moduleName + '_' + index"
              >
                <v-list-item-content class="px-4 py-0">
                  <VariableFileUpload
                    :variable-file="item"
                    :icon="item.icon"
                    :module-name="setModuleName(item)"
                    :deletable="isEmailOrRentalFile(item) ? false : !disabled"
                    :disabled="isEmailOrRentalFile(item)"
                    :required="false"
                    :process-instance-id="processInstanceId"
                    :files-with-checkbox="isFileWithCheckbox"
                    :form-field-id="item.variableName"
                    @addFileVariable="onAddFileVariable"
                    @removeFileVariable="onRemoveFileVariable"
                    @updateAttachmentIds="onUpdateAttachmentIds"
                    @loadProcessVariables="onLoadProcessVariables"
                  />
                </v-list-item-content>
            </v-list-item>
            <v-divider :key="item.moduleName + '_divider_' + index"></v-divider>
          </template>
        </v-list>
      </template>
    </v-data-iterator>
  </div>
</template>

<script>
import VariableFileUpload from '@/features/ticketing/components/VariableFileUpload'
import { ascSortFilesByParam } from '@/helpers/sortHelper'
import { TICKETING_HISTORY_EMAIL_ATTACHMENTS } from '../graphql-apollo/ticketingHistory.gql'

import { getVariableValue } from '@/features/ticketing/helpers/ticketingHelper'
import { useRentingForm } from '@/use/useRentingForm'
const { loadRentingForm } = useRentingForm()

import { mapActions } from 'vuex'
const FILE = '_File'

export default {
  name: 'TicketFileVariables',
  components: {
    VariableFileUpload
  },
  props: {
    processView: {
      type: Boolean,
      default: false
    },
    processInstanceId: {
      type: String,
      default: ''
    },
    processVariables: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    moduleName: {
      type: String,
      default: ''
    },
    createdOn: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      rentalFileVariables: [],
      sortDesc: true,
      sortBy: 'createdOn'
    }
  },
  watch: {
    'rentingFormId': 'setRentalFileVariables'
  },
  created () {
    this.setRentalFileVariables()
  },
  mounted () {
    this.setRentalFileVariables()
  },
  computed: {
    keys() {
      return [
        {
          value: 'fileName',
          label: this.$t('ticketingSystem.fileName')
        },
        {
          value: 'createdOn',
          label: this.$t('ticketingSystem.fileCreatedOn')
        }
      ]
    },
    rentingFormId () {
      return getVariableValue(this.processVariables, 'System_RentingFormId_Long')?.toString() || ''
    },
    camundaFileVariables () {
      return this.processVariables.filter(x => x.variableName.endsWith(FILE)  && !x.variableValue?.deleted)
    },
    systemTicketFileVariables () {
      return this.camundaFileVariables.filter(x => x.variableName.includes('System_TicketFile'))
    },
    emailFileVariables () {
      this.ticketingHistory?.items.map(x => {
        x.attachments?.map(y => {
          Object.assign(y, { createdOn:  x.createdOn })
        })
      })
      return this.ticketingHistory?.items.reduce((acc, val) => acc.concat(val.attachments || []), [])
    },
    isFileWithCheckbox () {
      return this.moduleName === 'fileWithCheckbox'
    },
    allFileVariables () {
      let camundaFiles = []
      let emailFiles = []
      let rentalFiles = []
      if (this.camundaFileVariables?.length > 0) {
        camundaFiles = this.camundaFileVariables.map(x => {
          x.fileName = x.variableValue?.fileName
          x.createdOn = x.variableValue?.createdOn
          x.moduleName = 'ticket'
          x.icon = 'mdi-ticket'
          return x
        })
      }
      if (this.isFileWithCheckbox) {
        return camundaFiles
      } else {
        if (this.emailFileVariables?.length > 0) {
          emailFiles = this.emailFileVariables.map(x => {
            x.createdOn = x.createdOn
            x.moduleName = 'email'
            x.icon = 'mdi-email'
            return x
          })
        }
        if (this.rentalFileVariables?.length > 0) {
          rentalFiles = this.rentalFileVariables.map(x => {
            x.createdOn = this.createdOn
            x.moduleName = 'rental'
            x.icon = 'person'
            return x
          })
        }
        return camundaFiles.concat(emailFiles, rentalFiles)
      }
    }
  },
  apollo: {
    ticketingHistory: {
      query: TICKETING_HISTORY_EMAIL_ATTACHMENTS,
      skip () {
        return !this.processInstanceId
      },
      error (error) {
        this.setErrorSnackbar(error.message)
      },
      variables () {
        return {
          ticketId: this.processInstanceId
        }
      }
    }
  },
  methods: {
    ...mapActions('notifier', ['setSuccessSnackbar', 'setErrorSnackbar']),
    customSort () {
      if (this.sortDesc) {
        return ascSortFilesByParam(this.allFileVariables, this.sortBy).reverse()
      } else {
        return ascSortFilesByParam(this.allFileVariables, this.sortBy)
      }
    },
    sortFileByCreatedOn () {
     if (this.sortDesc) {
        return sortFilesASCByFileName(this.allFileVariables).reverse()
      } else {
        return sortFilesASCByFileName(this.allFileVariables)
      }
    },
    onAddFileVariable (file, formFieldId) {
      this.$emit('addFileVariable', file, formFieldId)
    },
    onRemoveFileVariable (formFieldId, file) {
      this.$emit('removeFileVariable', formFieldId, file)
    },
    onLoadProcessVariables (file, formFieldId) {
      this.$emit('loadProcessVariables', file, formFieldId)
    },
    async setRentalFileVariables () {
      let rentingForm = await loadRentingForm(this.rentingFormId)
      if (rentingForm) {
        this.rentalFileVariables = this.getRentalFileVariables(rentingForm)
      }
    },
    getRentalFileVariables (rentingForm) {
      let rentalResidents = rentingForm.content?.residents
      let rentalFileVariables = []
      if (rentalResidents?.length > 0) {
        rentalResidents.forEach(resident => {
          if (resident.validateCertificateFileId) {
            rentalFileVariables.push({
              fileId: resident.validateCertificateFileId,
              fileName: resident.verifyCertificateFile.split('/').pop(),
              fileNameWithPath: resident.verifyCertificateFile,
              rentalForm: true
            })
          }
          if (resident?.fileIds?.length > 0) {
            resident.fileIds.forEach((fileId, index) => {
              rentalFileVariables.push({
                fileId: fileId,
                fileName: resident.savedFiles[index].split('/').pop(),
                fileNameWithPath: resident.savedFiles[index],
                rentalForm: true
              })
            })
          }
        })
      }
      return rentalFileVariables
    },
    onUpdateAttachmentIds (attachmentId, addInEmail) {
      this.$emit('updateAttachmentIds', attachmentId, addInEmail)
    },
    isEmailOrRentalFile (item) {
      return item.moduleName === 'email' || item.moduleName === 'rental'
    },
    setModuleName (item) {
      return this.isFileWithCheckbox ? this.moduleName : item.moduleName
    }
  }
}
</script>

<style scoped lang="postcss">
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px lightgray;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(99, 99, 99, .7);
  background-color: #fff;
}
/deep/ header {
  width: 100%;
}
/deep/ .v-text-field.v-text-field--solo .v-input__control {
  min-height: 28px;
  height: 28px;
}
/deep/ .v-input__icon i {
  font-size: 20px;
}
/deep/ .v-toolbar, /deep/ .v-toolbar__content {
  height: 40px !important;
  width: 100%;
}
/deep/ .v-input__slot {
  padding: 0px !important;
}
</style>
