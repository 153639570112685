<template>
  <v-container fluid fill-height grid-list-lg>
    <v-layout row wrap id="layout">
      <v-flex xs12 sm12 md12 lg12>
        <v-layout row wrap>
          <v-flex xs12 sm12 md6 lg4>
            <v-autocomplete
              id="propertySelect"
              class="ma-0 pa-0"
              :label="$t('common.buttons.search')"
              :loading="loading"
              cache-items
              single-line
              return-object
              :items="abacusPropertiesDisplayItems"
              :filter="customPropertyFilter"
              item-text="displayText"
              item-value="propertyId"
              @change="changedValue"
              clearable
              v-model="select"
            />
          </v-flex>
          <v-flex xs12 sm12 md6 lg8>
            <v-layout class="pl-2">
              <v-checkbox class="mt-3" :label="$t('map.clusterProperties')" v-model="config.clusterProperties" />
              <v-checkbox class="mt-3 ml-3" :label="$t('map.constructionProjects')" v-model="config.displayConstructionProjects" />
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs12 sm12 :class="propertyId ? 'md6 lg6' : 'md12 lg12'">
            <GmapMap
              :center="{lat: 47.3362615, lng: 8.5130041}"
              :zoom="10"
              ref="mapRef"
              class="gmap-dimensions"
              :class="propertyId ? '' : 'gmap-initial'"
            >
              <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen = false">
                {{ address }}
              </gmap-info-window>
              <template v-if="config.clusterProperties">
                <GmapCluster
                  :max-zoom="15"
                  zoomOnClick
                >
                  <GmapMarker
                    :key="property.id"
                    v-for="property in abacusPropertiesFiltered"
                    :position="{lat: parseFloat(property.lat), lng: parseFloat(property.lng)}"
                    :icon="getSiteIcon()"
                    :clickable="true"
                    :draggable="false"
                    @click="displayProperty(property)"></GmapMarker>
                </GmapCluster>
              </template>
              <template v-else>
                <GmapMarker
                  :key="property.id"
                  v-for="property in abacusPropertiesFiltered"
                  :position="{lat: parseFloat(property.lat), lng: parseFloat(property.lng)}"
                  :icon="getSiteIcon()"
                  :clickable="true"
                  :draggable="false"
                  :optimized="false"
                  :zIndex="999"
                  @click="displayProperty(property)"></GmapMarker>
              </template>
              <template v-if="config.displayConstructionProjects">
                <GmapMarker
                  v-for="m in constructionProjects.buildingProjects"
                  :key="m.buildingProjectId"
                  :position="{lat: parseFloat(m.lat), lng: parseFloat(m.lng)}"
                  :icon="getConstructionIcon(m.buildingProjectId)"
                  :clickable="true"
                  :draggable="false"
                  @click="openBuildingProjectInfoDialog(m.buildingProjectId)"
                ></GmapMarker>
              </template>
            </GmapMap>
            <PropertyOverview class="mt-4" :column-title="columnTitle" :property-items="propertyItems" v-if="propertyId" :property-id="propertyId.toString()" />
          </v-flex>
          <v-flex :class="propertyId ? 'md6 lg6' : 'md12 lg12'">
            <ProcessesList v-if="ticketingEnabledAndTicketingRole && propertyId" :property-id="propertyId" />
            <TaskList v-if="propertyId" :property-id="propertyId" />
            <TenantsView class="mt-4" v-if="propertyId" :tenants-items="tenantsItems" :property-id="propertyId.toString()" />
          </v-flex>
          <BuildingProjectInfoDialog :show-dialog="showBuildingProjectDialog" :building-project="buildingProject" @closeDialog="closeBuildingProjectInfoDialog" />
        </v-layout>
      </v-flex>
      <SpinnerOverlay :loading="constructionsLoading" />
    </v-layout>
  </v-container>
</template>
<script>

import Vue from 'vue'
import GmapCluster from 'vue2-google-maps/dist/components/cluster'
import TaskList from './TaskList'
import TenantsView from './TenantsView'
import PropertyOverview from './PropertyOverview'
import ProcessesList from './ProcessesList'
import BuildingProjectInfoDialog from './dialog/BuildingProjectInfoDialog'
import SpinnerOverlay from '@/components/SpinnerOverlay'

import { mapActions, mapGetters, mapState } from 'vuex'
import { AuthenticationContext } from 'vue-adal'
import * as globalHelper from '@/helpers/globalHelper'
import { MAP_PROPERTIES } from '@/config/options/routes/mapRoutes'

Vue.component('GmapCluster', GmapCluster)

export default {
  name: MAP_PROPERTIES,
  components: {
    TaskList,
    TenantsView,
    PropertyOverview,
    ProcessesList,
    BuildingProjectInfoDialog,
    SpinnerOverlay
  },
  watch: {
    '$route': 'routeUpdated',
    'config.displayConstructionProjects': 'loadConstructionProjects',
    propertyId (value) {
      if (value) {
        this.loadProperties()
      }
    }
  },
  data () {
    return {
      config: {
        clusterProperties: false,
        displayConstructionProjects: false
      },
      address: '',
      loading: false,
      select: null,
      infoWindowPos: null,
      infoWinOpen: false,
      propertyId: this.$route.params.propertyId,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      tenantsItems: [],
      propertyItems: [],
      columnTitle: '',
      ticketingRole: 'prestige-tools-ticketing',
      radius: 100,
      displayIgnored: false,
      constructionsLoading: false,
      constructionProjects: [],
      showBuildingProjectDialog: false,
      buildingProject: null
    }
  },
  computed: {
    ...mapGetters('abacus', ['getAbacusProperties']),
    ...mapState('abacus', ['abacusProperties', 'abacusPropertiesDisplayItems']),
    abacusPropertiesFiltered () {
      return this.abacusProperties.filter(property => property.lat && property.lng && property.managementEnd === null)
    },
    ticketingEnabledAndTicketingRole () {
      let userRoles = AuthenticationContext && AuthenticationContext.user && AuthenticationContext.user.profile ? AuthenticationContext.user.profile.roles : null
      let hasPrestigeTicketingGroupRole = userRoles ? userRoles.includes(this.ticketingRole) : false
      return this.$ticketingEnabled && hasPrestigeTicketingGroupRole
    }
  },
  created () {
    Promise.all([this.$store.dispatch('abacus/loadAbacusProperties')]).then(() => {
      this.initDefaultProperty()
      this.routeUpdated()
      if (this.propertyId) {
        this.loadProperties()
      }
    })
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    displayProperty (marker) {
      if (!this.$route.params.propertyId || this.$route.params.propertyId !== marker.propertyId.toString()) {
        this.$router.push({name: MAP_PROPERTIES, params: { propertyId: marker.propertyId.toString() }})
      }
    },
    loadProperties () {
      let that = this
      that.loading = true
      this.$abacusProperty.getPropertyById(this.propertyId).then(function (response) {
        let data = response.data
        if (data) {
          if (data.objects.length) {
            let properties = response.data.objects
            that.tenantsItems = properties
          }
          that.propertyItems = []
          that.propertyItems.push({ name: 'caretaker', obj: data.caretaker })
          that.propertyItems.push({ name: 'client', obj: data.client })
          that.propertyItems.push({ name: 'realEstateManager', obj: data.realEstateManager })
          that.columnTitle = data.street + ', ' + data.zip + ' ' + data.place
        }
        that.loading = false
        return true
      }).catch(function (error) {
        that.setErrorSnackbar(error)
        that.loading = false
      })
    },
    loadConstructionProjects () {
      if (this.config.displayConstructionProjects && this.constructionProjects.length === 0) {
        this.constructionsLoading = true
        let that = this
        this.$prestigeConstructionProjects.getAllBuildingProjectsWithProperties(this.radius, undefined, this.displayIgnored)
          .then(response => {
            that.constructionProjects = response.data
            if (that.constructionProjects.buildingProjects && that.constructionProjects.buildingProjects.length > 0) {
              that.constructionProjects.buildingProjects.sort((a, b) =>
                (a.title + a.address + a.zip > b.title + b.address + b.zip)
                  ? 1 : ((b.title + b.address + b.zip > a.title + a.address + a.zip) ? -1 : 0)
              )
            }
            that.constructionsLoading = false
            return true
          }).catch(error => {
            that.constructionsLoading = false
            that.setErrorSnackbar(that.$t('common.errors.dataNotLoaded') + error)
          })
      }
    },
    updateInfoWindow (property) {
      if (property) {
        let markerPosition = {
          lat: parseFloat(property.lat),
          lng: parseFloat(property.lng)
        }
        this.infoWindowPos = markerPosition
        if (property.locationAddress) {
          this.address = property.locationAddress
        } else {
          this.address = '?'
        }
        this.infoWinOpen = true
        this.$refs.mapRef.$mapPromise.then((map) => {
          map.panTo(markerPosition)
        })
      }
    },
    getSiteIcon () {
      return require('@/assets/images/icons-map/property-green.png')
    },
    getConstructionIcon (buildingProjectId = '') {
      let color = (this.buildingProject === null || this.buildingProject.buildingProjectId === buildingProjectId) ? 'red' : 'grey'
      return require('@/assets/images/icons-map/construction-' + color + '.png')
    },
    changedValue () {
      if (this.select !== null) {
        let propertyId = this.abacusProperties.find(x => x.propertyId === this.select.propertyId).propertyId.toString()
        if (!this.$route.params.propertyId || this.$route.params.propertyId !== propertyId) {
          this.$router.push({ name: MAP_PROPERTIES, params: { propertyId: propertyId } })
        }
      } else {
        this.propertyId = null
        this.closeInfoWindow()
        this.$refs.mapRef.$mapPromise.then((map) => {
          map.panTo({ lat: 47.3362615, lng: 8.5130041 })
        })
        document.getElementById('layout').click()
      }
    },
    initDefaultProperty () {
      if (this.propertyId) {
        this.select = this.abacusPropertiesDisplayItems.find(x => x.propertyId.toString() === this.propertyId.toString())
      }
    },
    routeUpdated () {
      if (this.$route.params && this.$route.params.propertyId) {
        this.propertyId = this.$route.params.propertyId
        let property = this.abacusProperties.find(x => x.propertyId.toString() === this.propertyId.toString())
        this.select = property
        this.updateInfoWindow(property)
      } else {
        this.propertyId = null
        this.infoWinOpen = false
        this.select = ''
      }
    },
    closeInfoWindow () {
      this.$router.push({ name: MAP_PROPERTIES })
    },
    openBuildingProjectInfoDialog (buildingProjectId) {
      let that = this
      this.$prestigeConstructionProjects.getBuildingProjectWithProperties(buildingProjectId, this.radius, undefined, this.displayIgnored)
        .then(response => {
          if (response.data) {
            that.buildingProject = response.data
            that.showBuildingProjectDialog = true
          }
          return true
        }).catch(error => {
          that.buildingProject = null
          that.setErrorSnackbar(that.$t('common.errors.dataNotLoaded') + error)
        })
    },
    closeBuildingProjectInfoDialog () {
      this.showBuildingProjectDialog = false
      this.buildingProject = null
    },
    customPropertyFilter (item, queryText, itemText) {
      return globalHelper.propertyFilter(queryText, itemText)
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="postcss">
.gmap-dimensions {
  width: 100%;
  height: 500px;
}
.gmap-initial {
  height: 1000px;
}
</style>
