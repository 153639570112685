<template>
  <v-card>
    <v-card-title>
      {{ title }}
    </v-card-title>
    <v-card-text>
      <BarChart :labels="labels" :filters="filters" :chart-data="chartData" :chart-options="chartOptions" />
      <LineChart :labels="labels" :filters="filters" :chart-data="chartData" :chart-options="chartOptions" />
      <PieChart :labels="labels" :filters="filters" :chart-data="chartData" :chart-options="chartOptions" />
      <ScatterChart :labels="labels" :filters="filters" :chart-data="chartData" :chart-options="chartOptions" />
    </v-card-text>
  </v-card>
</template>

<script>
import BarChart from '@/components/charts/BarChart'
import LineChart from '@/components/charts/LineChart'
import PieChart from '@/components/charts/PieChart'
import ScatterChart from '@/components/charts/ScatterChart'

export default {
  name: 'CandidateGroupCharts',
  components: {
    BarChart,
    LineChart,
    PieChart,
    ScatterChart
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    labels: {
      type: Array,
      default: () => []
    },
    filters: {
      type: Object,
      default: () => {}
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    chartData () {
      return {
        labels: this.labels,
        datasets: this.datasets
      }
    },
    datasets () {
      if (this.data) {
        let createdDataset = this.data?.created.map(x => {
          return x.numberOfTasks
        })
        let completedDataset = this.data?.completed.map(x => {
          return x.numberOfTasks
        })
        return [
          {
            label: 'Created',
            data: createdDataset
          },
          {
            label: 'Completed',
            data: completedDataset
          }
        ]
      }
      return []
    },
    chartOptions () {
      return {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          mode: 'index',
          intersect: false
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true
          }
        }
      }
    }
  }
}
</script>