<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="pa-2">
      <v-card-subtitle class="pa-0 ma-0 font-weight-bold">
        {{ $t('renting.rentingForms') }}
      </v-card-subtitle>
      <template v-slot:actions>
        <v-icon color="brown lighten-2">mdi-table</v-icon>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="pa-0">
      <v-data-table
        :headers="headers"
        :items="rentalApplications"
        :loading="$apollo.loading"
        :options.sync="options"
        :footer-props="{itemsPerPageOptions:itemsPerPageOptions, itemsPerPageText: itemsPerPageText}"
        item-key="ticketId"
        @click:row="openProcessDetails"
        class="elevation-1"
      >
        <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
        <template v-slot:[`item.processStartedOn`]="{ item }">
          <span>{{ item.processStartedOn }}</span>
        </template>
        <template v-slot:[`item.processDecision`]="{ item }">
          <DecisionIcon :decision="item.processDecision" />
          <span>{{ $t('renting.statuses.' + item.processDecision) }}</span>
        </template>
        <template v-slot:[`item.step`]="{ item }">
          <div v-if="item.openTasks">
            <p v-for="(task, index) in item.openTasks" :key="'open_task' + index" class="open-task pa-1 mb-0">
              {{ task.name }}
            </p>
          </div>
        </template>
      </v-data-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import DecisionIcon from '@/components/DecisionIcon'
import { mapActions } from 'vuex'
import { RENTAL_CASE } from '@/features/abacus/graphql-apollo/rentalCase.gql'
import { formatDateAndTime } from '@/helpers/formatterHelper'
import { getGraphQLVariable } from '@/helpers/graphQLHelper'

export default {
  name: 'ProcessesRentalApplications',
  components: {
    DecisionIcon
  },
  props: {
    rentalContractId: {
      type: Number,
      default: null
    },
    isExpanded: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      options: {
        sortBy: ['startedOn'],
        sortDesc: [true],
        itemsPerPage: 10,
        page: 1
      },
      itemsPerPageOptions: [ 10, 15, 25 ],
      rentingForms: [],
      processInstanceIds: [],
      processesWithVariables: []
    }
  },
  apollo: {
    rentingRentalCase: {
      query: RENTAL_CASE,
      skip () {
        return !this.isExpanded
      },
      update (data) {
        data.rentingRentalCase.rentalForms = data.rentingRentalCase.rentalForms.map(form => {
          return Object.assign(form, {
            name: form.formContent.residents[0].firstName + ' ' + form.formContent.residents[0].lastName,
            openTasks: form.ticket.openTasks,
            processStartedOn: formatDateAndTime(form.ticket.startedOn),
            processDecision: getGraphQLVariable(form.ticket.variables, 'System_InterestedPerson_Decision_Enum', 'stringValue', 'IN_PROGRESS'),
            processDecisionComment: getGraphQLVariable(form.ticket.variables, 'System_InterestedPerson_DecisionComment_String', 'stringValue', '')
          })
        })
        return data.rentingRentalCase
      },
      error (error) {
        this.setErrorSnackbar(error.message)
      },
      variables () {
        return {
          rentalContractId: this.rentalContractId
        }
      }
    }
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    openProcessDetails (item) {
      window.open('/ticketing/processes/' + item.ticketId)
    }
  },
  computed: {
    headers () {
      return [
        {
          text: this.$t('ticketingSystem.processes.reletting.labels.name'),
          align: 'left',
          value: 'name',
          sortable: true
        },
        {
          text: this.$t('ticketingSystem.processes.reletting.labels.received'),
          align: 'left',
          value: 'processStartedOn',
          sortable: true
        },
        {
          text: this.$t('ticketingSystem.processes.reletting.labels.step'),
          value: 'step',
          sortable: false
        },
        {
          text: this.$t('ticketingSystem.processes.reletting.labels.state'),
          align: 'left',
          value: 'processDecision'
        }
      ]
    },
    itemsPerPageText () {
      return this.$t('common.itemsPerPageText')
    },
    rentalApplications () {
      return this.rentingRentalCase && this.rentingRentalCase?.rentalForms
    }
  }
}
</script>
<style scoped lang="postcss">
/deep/ .v-expansion-panel-content__wrap {
  padding: 8px;
}
/deep/ tr :hover {
  cursor: pointer;
}
.open-task {
  overflow: hidden;
  max-width: 24ch;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
