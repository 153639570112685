import UserInfo from '../UserInfo'

export const UserRoutes = [
  {
    path: '/user-info',
    name: 'UserInfo',
    component: UserInfo,
    meta: {
      title: 'userInfo',
      requireAuth: true,
      requireRoles: [
        'prestige-tools-admin'
      ]
    }
  }
]
