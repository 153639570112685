import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
  tankOrders: [],
  tankOrdersLoading: true
}

const getters = {
  getTankOrders: state => state.tankOrders,
  getTankOrdersLoading: state => state.tankOrdersLoading
}

const actions = {
  setTankOrders ({ commit }, tankOrders) {
    commit('SET_TANK_ORDERS', tankOrders)
  },
  setTankOrdersLoading ({ commit }) {
    commit('SET_TANK_ORDERS_LOADING')
  },
  loadTankOrders ({ state, dispatch }) {
    dispatch('setTankOrdersLoading')
    return Vue.prototype.$prestigeHeatingOilOrder.getLatestOrderPerTank().then(function (response) {
      let tankOrders = response.data
      dispatch('setTankOrders', tankOrders)
    }).catch(function (error) {
      dispatch('notifier/setErrorSnackbar', { text: error.message }, { root: true })
      dispatch('setTankOrders', [])
    })
  }
}

const mutations = {
  SET_TANK_ORDERS (state, tankOrders) {
    state.tankOrders = tankOrders
    state.tankOrdersLoading = false
  },
  SET_TANK_ORDERS_LOADING (state) {
    state.tankOrdersLoading = true
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
