<template>
  <div class="process-context mb-2">
    <ProcessUsers :user-links="userLinks" :delete-action="deleteAction" @openNewTicketDialog="onOpenNewTicketDialog" />
    <ProcessTickets :ticket-links="ticketLinks" :delete-action="deleteAction" @openNewTicketDialog="onOpenNewTicketDialog" />
    <ProcessProperties :process-instance-id="processInstanceId" :property-links="propertyLinks" :delete-action="deleteAction" @openNewTicketDialog="onOpenNewTicketDialog" />
    <ProcessPropertyObjects :process-instance-id="processInstanceId" :property-object-links="propertyObjectLinks" :delete-action="deleteAction" @openNewTicketDialog="onOpenNewTicketDialog" />
    <ProcessAddressLinks
      v-if="caretakerLinks.length > 0"
      address-type="caretaker"
      title="caretakers"
      :links="caretakerLinks"
      :delete-action="deleteAction"
      @openNewTicketDialog="onOpenNewTicketDialog"
    />
    <ProcessAddressLinks
      v-if="clientLinks.length > 0"
      address-type="client"
      title="clients"
      :links="clientLinks"
      :delete-action="deleteAction"
      @openNewTicketDialog="onOpenNewTicketDialog"
    />
    <ProcessAddressLinks
      v-if="craftsmanLinks.length > 0"
      address-type="craftsman"
      title="craftsmen"
      :links="craftsmanLinks"
      :delete-action="deleteAction"
      @openNewTicketDialog="onOpenNewTicketDialog"
    />
    <ProcessAddressLinks
      v-if="partnerLinks.length > 0"
      address-type="partner"
      title="partners"
      :links="partnerLinks"
      :delete-action="deleteAction"
      :process-instance-id="processInstanceId"
      @openNewTicketDialog="onOpenNewTicketDialog"
    />
    <ProcessAddressLinks
      v-if="tenantLinks.length > 0"
      address-type="tenant"
      title="tenants"
      :links="tenantLinks"
      :delete-action="deleteAction"
      :process-instance-id="processInstanceId"
      @openNewTicketDialog="onOpenNewTicketDialog"
    />
    <OrderLinks :links="orderLinks" />
    <v-card v-if="!hasLinks">
      <v-card-subtitle class="pa-2 font-weight-bold subtitle-1">
        {{ $t('ticketingSystem.processes.processLinks') }}
      </v-card-subtitle>
      <v-card-text class="pl-2">
        {{ $t('ticketingSystem.messages.noContextInfo') }}
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ProcessUsers from './ProcessUsers'
import ProcessTickets from './ProcessTickets'
import ProcessAddressLinks from './ProcessAddressLinks'
import ProcessProperties from './ProcessProperties'
import ProcessPropertyObjects from './ProcessPropertyObjects'
import OrderLinks from './OrderLinks'
import { getLinkValues } from '@/features/ticketing/helpers/autocompleteHelper'
import { mapActions } from 'vuex'

export default {
  name: 'ProcessContext',
  components: {
    ProcessUsers,
    ProcessTickets,
    ProcessAddressLinks,
    ProcessPropertyObjects,
    ProcessProperties,
    OrderLinks
  },
  props: {
    processInstanceId: {
      type: String,
      default: ''
    },
    processFinished: {
      type: Boolean,
      default: false
    },
    processReferences: {
      type: Object,
      default: () => {}
    },
    processReferencesLinks: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    userLinks () {
      return getLinkValues(this.processReferencesLinks, 'user')
    },
    ticketLinks () {
      return getLinkValues(this.processReferencesLinks, 'ticket')
    },
    tenantLinks () {
      return getLinkValues(this.processReferencesLinks, 'tenant')
    },
    caretakerLinks () {
      return getLinkValues(this.processReferencesLinks, 'caretaker')
    },
    propertyLinks () {
      return getLinkValues(this.processReferencesLinks, 'property')
    },
    propertyObjectLinks () {
      return getLinkValues(this.processReferencesLinks, 'propertyObject')
    },
    clientLinks () {
      return getLinkValues(this.processReferencesLinks, 'client')
    },
    craftsmanLinks () {
      return getLinkValues(this.processReferencesLinks, 'craftsman')
    },
    partnerLinks () {
      return getLinkValues(this.processReferencesLinks, 'partner')
    },
    orderLinks () {
      return getLinkValues(this.processReferencesLinks, 'order')
    },
    hasLinks () {
      return this.processReferencesLinks && this.processReferencesLinks.length > 0
    },
    activeProcess () {
      return this.processInstanceId && !this.processFinished
    },
    activeTask () {
      return this.$route.query.taskId
    },
    onDeleteAction () {
      return this.activeProcess ? this.activeTask ? this.onTicketDetailsDeleteLink : this.onProcessDeleteLink : this.onTicketDeleteLink
    },
    deleteAction () {
      if (this.processFinished) {
        return null
      } else {
        return { title: this.$t('common.buttons.delete'), icon: 'delete_forever', color: 'red', action: this.onDeleteAction }
      }
    }
  },
  methods: {
    ...mapActions('notifier', ['setSuccessSnackbar', 'setErrorSnackbar']),
    onOpenNewTicketDialog (links = [], subject = '', processDefinitionKey = null, variables = [], property = null, object = null) {
      this.$emit('openNewTicketDialog', links, subject, processDefinitionKey, variables, property, object)
    },
    onTicketDetailsDeleteLink (item) {
      let callEndpoint = false
      if (this.processReferences.links.length > 0) {
        let index = this.processReferences.links.findIndex(x => x.linkName.toString() === item.linkName.toString())
        if (index !== -1) {
          let linkValueIndex = this.processReferences.links[index].linkValues.findIndex(x => x.toString() === item.linkValue.toString())
          if (linkValueIndex !== -1) {
            callEndpoint = true
          }
        }
      }
      callEndpoint ? this.onProcessDeleteLink(item) : this.onTicketDeleteLink(item)
    },
    onTicketDeleteLink (item) {
      this.$emit('deleteReferenceLink', { linkName: item.linkName, linkValue: item.linkValue })
    },
    onProcessDeleteLink (item) {
      let that = this
      this.$prestigeTSProcess.deleteLink(this.processInstanceId, { linkName: item.linkName, linkValue: item.linkValue })
        .then(() => {
          that.setSuccessSnackbar(that.$t('common.messages.successfullyDeleted'))
          that.$emit('loadDetails')
          that.$emit('loadProcessReferences')
        }).catch(error => {
          that.setErrorSnackbar(error)
        })
    }
  }
}
</script>

<style scoped lang="postcss">
/deep/ .context {
  max-height: 400px;
}
</style>
