<template>
  <at :at="'@h'" :members="selectableCaretakers" name-key="displayText" :filter-match="propertyFilter" v-model="html" @at="caretakerSearchTermUpdated">
    <template v-slot:item="s">
      <span v-text="s.item.displayText"></span>
    </template>
    <template slot="embeddedItem" slot-scope="s">
      <span v-if="s.current">
        <span class="caretaker-slot" :id="'[' + s.current.addressId  + ']'">
          <a :id="'[' + s.current.addressId  + ']'" class="caretaker" v-text="'@' + s.current.autocompleteValue"></a>
        </span>
      </span>
    </template>
    <at :at="'@m'" :members="selectableTenants" name-key="displayText" :filter-match="propertyFilter" v-model="html" @at="tenantSearchTermUpdated">
      <template v-slot:item="s">
        <span v-text="s.item.displayText"></span>
      </template>
      <template slot="embeddedItem" slot-scope="s">
        <span v-if="s.current">
          <span class="tenant-slot" :id="'[' + s.current.addressId  + ']'">
            <a :id="'[' + s.current.addressId  + ']'" class="tenant" v-text="'@' + s.current.autocompleteValue"></a>
          </span>
        </span>
      </template>
      <at :at="'@i'" :members="abacusProperties" :filter-match="propertyFilter" name-key="displayText" v-model="html">
        <template v-slot:item="s">
          <span v-text="s.item.displayText"></span>
        </template>
        <template slot="embeddedItem" slot-scope="s">
          <span v-if="s.current">
            <span class="property-slot" :id="'[' + s.current.propertyId  + ']'">
              <a :id="'[' + s.current.propertyId  + ']'" class="property" v-text="'@' + s.current.autocompleteValue"></a>
            </span>
          </span>
        </template>
        <at :at="'@u'" :members="ticketingUsersList" :filter-match="propertyFilter" name-key="displayText" v-model="html">
          <template v-slot:item="s">
            <span v-text="s.item.displayText"></span>
          </template>
          <template slot="embeddedItem" slot-scope="s">
            <span v-if="s.current">
              <a :id="'[' + s.current.userId  + ']'" class="ticketing-user" v-text="'@' + s.current.autocompleteValue"></a>
            </span>
          </template>
          <at :at="'@a'" :members="craftsmen" name-key="displayText" :filter-match="craftsmanFilterMatch" v-model="html">
            <template v-slot:item="s">
              <span v-text="s.item.displayText"></span>
            </template>
            <template slot="embeddedItem" slot-scope="s">
            <span v-if="s.current">
              <span class="craftsman-slot" :id="'[' + s.current.addressId  + ']'">
                <a :id="'[' + s.current.addressId  + ']'" class="craftsman" v-text="'@' + s.current.autocompleteValue"></a>
              </span>
            </span>
            </template>
            <at :at="'@e'" :members="selectableClients" name-key="displayText" :filter-match="propertyFilter" v-model="html" @at="clientSearchTermUpdated">
              <template v-slot:item="s">
                <span v-text="s.item.displayText"></span>
              </template>
              <template slot="embeddedItem" slot-scope="s">
                <span v-if="s.current">
                  <span class="client-slot" :id="'[' + s.current.addressId  + ']'">
                    <a :id="'[' + s.current.addressId  + ']'" class="client" v-text="'@' + s.current.autocompleteValue"></a>
                  </span>
                </span>
              </template>
              <at :at="'@o'" :members="abacusPropertiesObjects" :filter-match="propertyFilter" name-key="displayText" v-model="html">
                <template v-slot:item="s">
                  <span v-text="s.item.displayText"></span>
                </template>
                <template slot="embeddedItem" slot-scope="s">
                <span v-if="s.current">
                  <span class="object-slot" :id="'[' + s.current.propertyObjectId  + ']'">
                    <a :id="'[' + s.current.propertyObjectId  + ']'" class="object" v-text="'@' + s.current.autocompleteValue"></a>
                  </span>
                </span>
                </template>
                <at :at="'@p'" :members="partners" name-key="displayText" :filter-match="partnerFilterMatch" v-model="html">
                  <template v-slot:item="s">
                    <span v-text="s.item.displayText"></span>
                  </template>
                  <template slot="embeddedItem" slot-scope="s">
                    <span v-if="s.current">
                      <span class="partner-slot" :id="'[' + s.current.addressId  + ']'">
                        <a :id="'[' + s.current.addressId  + ']'" class="partner" v-text="'@' + s.current.autocompleteValue"></a>
                      </span>
                    </span>
                  </template>
                  <at name-key="displayText" v-model="html">
                    <template v-slot:item="s">
                      <span v-text="s.item.displayText"></span>
                    </template>
                    <template slot="embeddedItem" slot-scope="s">
                      <span v-if="s.current">
                        <span class="ticket-slot" :id="'[' + s.current.ticketId  + ']'">
                          <a :id="'[' + s.current.ticketId  + ']'" class="ticket" v-text="'@' + s.current.autocompleteValue"></a>
                        </span>
                      </span>
                    </template>
                    <v-list-item-subtitle
                      v-if="label"
                      class="subtitle-1 mt-2 mb-1"
                      v-bind:class="[{ 'required': !html && validateClicked && required }, { 'autocomplete-label--is-readonly':  disabled || readOnly }]"
                    >
                      {{ label }}
                    </v-list-item-subtitle>
                    <div
                      ref="editor"
                      class="editor"
                      v-bind:class="[{ 'required-border': !html && validateClicked && required }, { 'autocomplete--is-readonly':  disabled || readOnly }]"
                      :aria-placeholder="placeholder"
                      :aria-required="required"
                      :aria-invalid="ariaInvalid"
                      :contenteditable="!disabled && !readOnly"
                      v-click-outside="onOutsideClick"
                      @paste="onPaste"
                    ></div>
                    <div class="float-right">
                      <v-tooltip max-width="500" bottom :color="color">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on" class="ml-2">
                            <img src="@/assets/icons/question-circle.svg" />
                          </v-btn>
                        </template>
                        <div class="hint-box">
                          <span>{{ $t('autocomplete.hintSearchBy') }}</span>
                          <div class="hint-box-property">{{ $t('autocomplete.hintProperties') }}</div>
                          <div class="hint-box-object">{{ $t('autocomplete.hintObjects') }}</div>
                          <div class="hint-box-ticketing-user">{{ $t('autocomplete.hintUser') }}</div>
                          <div>{{ $t('common.or') }}</div>
                          <div class="hint-box-craftsman">{{ $t('autocomplete.hintCraftsman') }}</div>
                          <div class="hint-box-client">{{ $t('autocomplete.hintClient') }}</div>
                          <div class="hint-box-caretaker">{{ $t('autocomplete.hintCaretaker') }}</div>
                          <div class="hint-box-partner">{{ $t('autocomplete.hintPartner') }}</div>
                          <div class="hint-box-tenant">{{ $t('autocomplete.hintTenant') }}</div>
                          <span>{{ $t('autocomplete.hintChars') }}</span>
                        </div>
                      </v-tooltip>
                    </div>
                  </at>
                </at>
              </at>
            </at>
          </at>
        </at>
      </at>
    </at>
  </at>
</template>

<script>
import _isEqual from 'lodash.isequal'
import _cloneDeep from 'lodash.clonedeep'

import At from 'vue-at'
import { mapActions, mapState } from 'vuex'

import * as globalHelper from '@/helpers/globalHelper'
import * as autocompleteHelper from '../helpers/autocompleteHelper'

import { TASK_TICKETING_TICKET } from '@/features/tasks/graphql-apollo/taskTicketingTicket.gql'
import { checkIfValidUUID } from '@/helpers/validationHelper'

export default {
  name: 'AutocompleteLinks',
  components: {
    At
  },
  data () {
    return {
      caretakers: [{}],
      craftsmen: [{}],
      clients: [{}],
      partners: [{}],
      ticketingUsersList: [],
      tenants: [{}],
      caretakerLastQuery: '',
      craftsmanLastQuery: '',
      clientLastQuery: '',
      partnerLastQuery: '',
      tenantLastQuery: '',
      addedCaretakers: [],
      addedCraftsmen: [],
      addedClients: [],
      addedTenants: [],
      addedPartners: [],
      addedProperties: [],
      addedPropertyObjects: [],
      addedTicketingUsers: [],
      addedTickets: [],
      html: this.defaultHtml || '',
      color: '#E4EFFA',
      prestigeUrlRegex: /(https?:\/\/[^ ]*)/,
      hasTicketLinksChanges: false,
      linkedTicketId: '',
      apolloLoading: false
    }
  },
  apollo: {
    ticketingTicket: {
      query: TASK_TICKETING_TICKET,
      variables () {
        return {
          ticketId: this.linkedTicketId
        }
      },
      skip () {
        return !this.linkedTicketId
      },
      watchLoading (isLoading) {
        this.apolloLoading = isLoading
      }
    }
  },
  created () {
    this.loadData()
  },
  mounted () {
    autocompleteHelper.addAutocompleteClickListeners()
  },
  watch: {
    html: {
      handler () {
        if (this.html === '' && this.addedTickets.length > 0) {
          this.addedTickets = []
          this.hasTicketLinksChanges = true
        }
        autocompleteHelper.addAutocompleteClickListeners()
        this.$emit('input', this.html)
        let hasChanges = this.updateLinkData() || this.hasTicketLinksChanges
        if (hasChanges) {
          let links = this.getLinks()
          this.$emit('autocompleteLinksUpdated', { name: this.name, links: links })
        }
      }
    },
    propertyLinks: {
      async handler (updatedPropertyLinks) {
        await this.loadAllProperties(updatedPropertyLinks)
      },
      deep: true
    },
    apolloLoading (value) {
      if (!value) {
        if (this.ticketingTicket) {
          const linkedHtmlText = `<span data-at-embedded="" contenteditable="false"><span data-v-5ed549ba="" id="[${this.linkedTicketId}]" class="ticket-slot"><a data-v-5ed549ba="" id="[${this.linkedTicketId}]" class="ticket">@[${this.ticketingTicket.processDefinitionName}] ${this.ticketingTicket.subject || ''}</a></span> </span>`
          window.document.execCommand('insertHtml', false, linkedHtmlText)
        }
      }
    }
  },
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    defaultHtml: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    validateClicked: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    item: {
      type: Object,
      default: () => {}
    },
    additionalLinks: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState('abacus', ['abacusProperties', 'propertiesDetails']),
    ...mapState('ticketingSystem', ['ticketingUsers']),
    ariaInvalid () {
      return this.required ? this.html === '' : false
    },
    propertyLinks () {
      if (this.additionalLinks) {
        const propertyLinks = autocompleteHelper.getLinkValues(this.additionalLinks, 'property')
        if (propertyLinks.length > 0) {
          let that = this
          propertyLinks.forEach(x => {
            if (!that.addedProperties.includes(x)) {
              that.addedProperties.push(x)
            }
          })
        }
      }
      return this.addedProperties
    },
    linkedAbacusProperties () {
      return this.propertyLinks && this.propertiesDetails ? this.propertiesDetails.filter((x) => this.propertyLinks.includes(String(x.propertyId))) : []
    },
    abacusPropertiesObjects () {
      return this.linkedAbacusProperties.flatMap(property => property.objects.map(object => {
        return {
          propertyObjectId: property.propertyId + ':' + object.objectId,
          displayText: '[' + property.propertyId + '] ' + object.houseStreet + ', ' + property.zip + ' ' + property.place + ' - [' + object.objectId + '] ' + object.floorText + ' - ' + object.objectText + ' - ' + object.currentTenantText,
          autocompleteValue: '[' + object.objectId + '] ' + object.floorText + ' - ' + object.objectText + ' - ' + object.currentTenantText
        }
      }))
    },
    linkedAbacusPropertyTenants () {
      let tenants = []
      this.linkedAbacusProperties.forEach(property => property.objects.forEach(object => {
        if (object.currentTenant && tenants.findIndex(tenant => tenant.id === object.currentTenant.id) === -1) {
          tenants.push(_cloneDeep(object.currentTenant))
        }
      }))
      return tenants
    },
    selectableTenants () {
      let options = []
      if (this.linkedAbacusPropertyTenants.length > 0) {
        options = this.linkedAbacusPropertyTenants
      } else if (this.tenantLastQuery) {
        options = this.tenants
      }
      this.processAvailableOptions(options)
      return options
    },
    linkedAbacusPropertyClients () {
      let clients = []
      this.linkedAbacusProperties.forEach(property => {
        if (property.client && clients.findIndex(client => client.id === property.client.id) === -1) {
          clients.push(_cloneDeep(property.client))
        }
      })
      return clients
    },
    selectableClients () {
      let options = []
      if (this.linkedAbacusPropertyClients.length > 0) {
        options = this.linkedAbacusPropertyClients
      } else if (this.clientLastQuery) {
        options = this.tenants
      }
      this.processAvailableOptions(options)
      return options
    },
    linkedAbacusPropertyCaretakers () {
      let caretakers = []
      this.linkedAbacusProperties.forEach(property => {
        if (property.caretaker && caretakers.findIndex(caretaker => caretaker.id === property.caretaker.id) === -1) {
          caretakers.push(_cloneDeep(property.caretaker))
        }
      })
      return caretakers
    },
    selectableCaretakers () {
      let options = []
      if (this.linkedAbacusPropertyCaretakers.length > 0) {
        options = this.linkedAbacusPropertyCaretakers
      } else if (this.caretakerLastQuery) {
        options = this.caretakers
      }
      this.processAvailableOptions(options)
      return options
    }
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    ...mapActions('abacus', ['loadAbacusProperties', 'loadPropertyDetails']),
    ...mapActions('ticketingSystem', ['loadTicketingUsers']),
    onPaste (event) {
      if (event.clipboardData && event.clipboardData.getData) {
        event.preventDefault()
        let text = event.clipboardData.getData('text/plain')

        let prestigeUrl = text.match(this.prestigeUrlRegex)
        if (prestigeUrl !== null) {

          const urlSplitted = prestigeUrl[1]?.split('/processes/')
          const splitProcessInstanceId = urlSplitted.length > 1 ? urlSplitted[1]?.split('?') : urlSplitted[0]?.split('?')
          let linkValue = splitProcessInstanceId[0]
          if (linkValue && checkIfValidUUID(linkValue)) {
            let valueExists = this.addedTickets.find(ticketId => ticketId === linkValue)
            this.linkedTicketId = linkValue
            if (!valueExists) {
              this.addedTickets.push(linkValue)
              this.hasTicketLinksChanges = true
            }
          } else {
              const linkedHtml = `<a href="${linkValue}" target=_blank>${linkValue}</a>`
              window.document.execCommand('insertHtml', false, linkedHtml)
          }

          if (this.ticketingTicket && checkIfValidUUID(linkValue)) {
            this.$apollo.queries.ticketingTicket.refresh()
          } else {
            this.$apollo.queries.ticketingTicket.stop()
          }
        } else {
          window.document.execCommand('insertText', false, text)
        }
      }
    },
    loadData () {
      Promise.all([this.loadAbacusProperties(), this.loadTicketingUsers()]).then(() => this.setData())
    },
    async loadAllProperties (updatedPropertyLinks) {
      let allPropertiesLoaded = await Promise.all(updatedPropertyLinks.map(async (propertyId) => {
        let propertyLoadedData = await this.loadPropertyDetails(propertyId)
        return propertyLoadedData
      }))
      return allPropertiesLoaded
    },
    getHtml () {
      return this.html
    },
    searchCaretakers (query) {
      let that = this
      let queryParams = { query: { searchString: query, caretaker: true } }
      this.$abacus.getAllAddressesV2(undefined, queryParams).then(function (response) {
        if (response.data && response.data.length) {
          that.caretakers = response.data
        } else {
          that.caretakers = []
        }
      }).catch(function (error) {
        that.caretakers = []
        that.setErrorSnackbar(error)
      })
    },
    loadCraftsmen (query) {
      let that = this
      let queryParams = { query: { searchString: query, craftsman: true } }
      this.$abacus.getAllAddressesV2(undefined, queryParams).then(function (response) {
        if (response.data?.length) {
          that.craftsmen = response.data
          that.processAvailableOptions(that.craftsmen)
        }
      }).catch(function (error) {
        that.craftsmen = []
        that.setErrorSnackbar(error)
      })
    },
    loadPartners (query) {
      let that = this
      let queryParams = { query: { searchString: query, partner: true } }
      this.$abacus.getAllAddressesV2(undefined, queryParams).then(function (response) {
        if (response.data?.length) {
          that.partners = response.data
          that.processAvailableOptions(that.partners)
        }
      }).catch(function (error) {
        that.partners = []
        that.setErrorSnackbar(error)
      })
    },
    searchTenants (query) {
      let that = this
      let queryParams = { query: { searchString: query, tenant: true } }
      this.$abacus.getAllAddressesV2(undefined, queryParams).then(function (response) {
        if (response.data && response.data.length) {
          that.tenants = response.data
        }
      }).catch(function (error) {
        that.tenants = []
        that.setErrorSnackbar(error)
      })
    },
    searchClients (query) {
      let that = this
      let queryParams = { query: { searchString: query, client: true } }
      this.$abacus.getAllAddressesV2(undefined, queryParams).then(function (response) {
        if (response.data && response.data.length) {
          that.clients = response.data
        } else {
          that.clients = []
        }
      }).catch(function (error) {
        that.clients = []
        that.setErrorSnackbar(error)
      })
    },
    caretakerSearchTermUpdated (chunk) {
      if (this.linkedAbacusPropertyCaretakers.length === 0 && chunk.length >= 3) {
        if (chunk !== this.caretakerLastQuery) {
          this.searchCaretakers(chunk)
          this.caretakerLastQuery = chunk
        }
      } else {
        this.caretakers = []
      }
    },
    tenantSearchTermUpdated (chunk) {
      if (this.linkedAbacusPropertyTenants.length === 0 && chunk.length >= 3) {
        if (chunk !== this.tenantLastQuery) {
          this.searchTenants(chunk)
          this.tenantLastQuery = chunk
        }
      } else {
        this.tenants = []
      }
    },
    clientSearchTermUpdated (chunk) {
      if (this.linkedAbacusPropertyClients.length === 0 && chunk.length >= 3) {
        if (chunk !== this.clientLastQuery) {
          this.searchClients(chunk)
          this.clientLastQuery = chunk
        }
      } else {
        this.clients = []
      }
    },
    propertyFilter (name, chunk) {
      return globalHelper.propertyFilter(chunk, name)
    },
    processAvailableOptions (options) {
      options = globalHelper.sortByFirstNameAndLastName(options)
      options.forEach(item => {
        item.addressId = item.id
        item.displayText = globalHelper.setDisplayText(item)
        item.autocompleteValue = autocompleteHelper.setAutocompleteValue(item)
      })
    },
    craftsmanFilterMatch (name, chunk) {
      if (chunk.length >= 3) {
        if (chunk !== this.craftsmanLastQuery) {
          this.loadCraftsmen(chunk)
          this.craftsmanLastQuery = chunk
        }
        return true
      } else {
        return false
      }
    },
    partnerFilterMatch (name, chunk) {
      if (chunk.length >= 3) {
        if (chunk !== this.partnerLastQuery) {
          this.loadPartners(chunk)
          this.partnerLastQuery = chunk
        }
        return true
      } else {
        return false
      }
    },
    setData () {
      this.setProperties()
      this.setTicketingUsers()
      this.updateLinkData()
    },
    setProperties () {
      if (this.abacusProperties && this.abacusProperties.length > 0) {
        this.abacusProperties.forEach(item => {
          let displayText = '[' + item.propertyId + '] ' + item.locationAddress.trim()
          item.displayText = displayText
          item.autocompleteValue = displayText.trim()
        })
      }
    },
    setTicketingUsers () {
      if (this.ticketingUsers && this.ticketingUsers.length > 0) {
        this.ticketingUsersList = _cloneDeep(this.ticketingUsers)
        this.ticketingUsersList.push({
          userId: 'bot',
          name: 'Bot',
          displayText: '[bot] Bot',
          autocompleteValue: '[bot] Bot'
        })
        this.ticketingUsersList.forEach(item => {
          let displayText = '[' + item.userId + '] ' + item.name
          item.displayText = displayText
          item.autocompleteValue = displayText.trim()
        })
        this.ticketingUsersList.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase())
          ? 1 : (b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0))
      }
    },
    updateLinkData () {
      let addedCaretakers = []
      let addedTenants = []
      let addedProperties = []
      let addedPropertyObjects = []
      let addedTicketingUsers = []
      let addedClients = []
      let addedCraftsmen = []
      let addedPartners = []

      let hasChanges = false

      let spanElementsNewVal = this.html.split('<span data-at-embedded="" contenteditable="false">')
      spanElementsNewVal.forEach(item => {
        if (item !== '' && item.includes('"caretaker"')) {
          let id = item.match(/\[(.*?)\]/)[1]
          if (!addedCaretakers.includes(id)) {
            addedCaretakers.push(id)
          }
        } else if (item !== '' && item.includes('"tenant"')) {
          let id = item.match(/\[(.*?)\]/)[1]
          if (!addedTenants.includes(id)) {
            addedTenants.push(id)
          }
        } else if (item !== '' && item.includes('"property"')) {
          let id = item.match(/\[(.*?)\]/)[1]
          if (!addedProperties.includes(id)) {
            addedProperties.push(id)
          }
        } else if (item !== '' && item.includes('"object"')) {
          let id = item.match(/\[(.*?)\]/)[1]
          if (!addedPropertyObjects.includes(id)) {
            addedPropertyObjects.push(id)
          }
        } else if (item !== '' && item.includes('"ticketing-user"')) {
          let id = item.match(/\[(.*?)\]/)[1]
          if (!addedTicketingUsers.includes(id)) {
            addedTicketingUsers.push(id)
          }
        } else if (item !== '' && item.includes('"client"')) {
          let id = item.match(/\[(.*?)\]/)[1]
          if (!addedClients.includes(id)) {
            addedClients.push(id)
          }
        } else if (item !== '' && item.includes('"craftsman"')) {
          let id = item.match(/\[(.*?)\]/)[1]
          if (!addedCraftsmen.includes(id)) {
            addedCraftsmen.push(id)
          }
        } else if (item !== '' && item.includes('"partner"')) {
          let id = item.match(/\[(.*?)\]/)[1]
          if (!addedPartners.includes(id)) {
            addedPartners.push(id)
          }
        }
      })

      if (!_isEqual(this.addedCaretakers, addedCaretakers)) {
        hasChanges = true
        this.addedCaretakers = addedCaretakers
      }
      if (!_isEqual(this.addedTenants, addedTenants)) {
        hasChanges = true
        this.addedTenants = addedTenants
      }
      if (!_isEqual(this.addedProperties, addedProperties)) {
        hasChanges = true
        this.addedProperties = addedProperties
      }
      if (!_isEqual(this.addedPropertyObjects, addedPropertyObjects)) {
        hasChanges = true
        this.addedPropertyObjects = addedPropertyObjects
      }
      if (!_isEqual(this.addedTicketingUsers, addedTicketingUsers)) {
        hasChanges = true
        this.addedTicketingUsers = addedTicketingUsers
      }
      if (!_isEqual(this.addedClients, addedClients)) {
        hasChanges = true
        this.addedClients = addedClients
      }
      if (!_isEqual(this.addedCraftsmen, addedCraftsmen)) {
        hasChanges = true
        this.addedCraftsmen = addedCraftsmen
      }
      if (!_isEqual(this.addedPartners, addedPartners)) {
        hasChanges = true
        this.addedPartners = addedPartners
      }

      return hasChanges
    },
    getCaretakerLinks () {
      if (this.addedCaretakers.length > 0) {
        return {
          linkName: 'caretaker',
          linkValues: this.addedCaretakers || []
        }
      }
      return null
    },
    getTenantLinks () {
      if (this.addedTenants.length > 0) {
        return {
          linkName: 'tenant',
          linkValues: this.addedTenants || []
        }
      }
      return null
    },
    getPropertyLinks () {
      if (this.addedProperties.length > 0) {
        return {
          linkName: 'property',
          linkValues: this.addedProperties || []
        }
      }
      return null
    },
    getPropertyObjectLinks () {
      if (this.addedPropertyObjects.length > 0) {
        return {
          linkName: 'propertyObject',
          linkValues: this.addedPropertyObjects || []
        }
      }
      return null
    },
    getTicketingUserLinks () {
      if (this.addedTicketingUsers.length > 0) {
        return {
          linkName: 'user',
          linkValues: this.addedTicketingUsers || []
        }
      }
      return null
    },
    getClientLinks () {
      if (this.addedClients.length > 0) {
        return {
          linkName: 'client',
          linkValues: this.addedClients || []
        }
      }
      return null
    },
    getCraftsmanLinks () {
      if (this.addedCraftsmen.length > 0) {
        return {
          linkName: 'craftsman',
          linkValues: this.addedCraftsmen || []
        }
      }
      return null
    },
    getPartnerLinks () {
      if (this.addedPartners.length > 0) {
        return {
          linkName: 'partner',
          linkValues: this.addedPartners || []
        }
      }
      return null
    },
    getTicketLinks () {
      if (this.addedTickets.length > 0) {
        return {
          linkName: 'ticket',
          linkValues: this.addedTickets || []
        }
      }
      return null
    },
    getLinks () {
      const caretakerLinks = this.getCaretakerLinks()
      const tenantLinks = this.getTenantLinks()
      const propertyLinks = this.getPropertyLinks()
      const propertyObjectLinks = this.getPropertyObjectLinks()
      const userLinks = this.getTicketingUserLinks()
      const clientLinks = this.getClientLinks()
      const craftsmanLinks = this.getCraftsmanLinks()
      const partnerLinks = this.getPartnerLinks()
      const ticketLinks = this.getTicketLinks()

      let newLinks = []
      if (caretakerLinks) {
        newLinks.push(caretakerLinks)
      }
      if (tenantLinks) {
        newLinks.push(tenantLinks)
      }
      if (propertyLinks) {
        newLinks.push(propertyLinks)
      }
      if (propertyObjectLinks) {
        newLinks.push(propertyObjectLinks)
      }
      if (userLinks) {
        newLinks.push(userLinks)
      }
      if (clientLinks) {
        newLinks.push(clientLinks)
      }
      if (craftsmanLinks) {
        newLinks.push(craftsmanLinks)
      }
      if (partnerLinks) {
        newLinks.push(partnerLinks)
      }
      if (ticketLinks) {
        newLinks.push(ticketLinks)
      }
      return newLinks
    },
    onOutsideClick () {
      let selectionPanel = document.getElementsByClassName('atwho-panel')
      if (selectionPanel && selectionPanel.length > 0) {
        selectionPanel[0].style.visibility = 'hidden'
      }
    }
  }
}
</script>

<style scoped lang="postcss">
/deep/ .atwho-panel {
  position: absolute;
  z-index: 1;
  left: 3% !important;
  top: 20% !important;
  right: 3% !important;
  width: auto !important;
  height: 100% !important;
  max-height: 300px;
}
/deep/ .atwho-inner {
  height: 100%;
}
/deep/ .atwho-view {
  max-height: 300px;
  height: 100%;
  position: absolute;
  right: 0;
  left: 0;
  min-width: 100%;
  bottom: 0;
}
/deep/ .atwho-ul {
  padding-left: 4px;
  max-height: 300px;
  height: 100%;
}
/deep/ .atwho-li span {
  white-space: pre;
}
/deep/ .editor {
  width: 100%;
  max-width: 100%;
  overflow: auto;
  white-space: pre-wrap;
  border: 1px solid rgba(0,0,0,.3);
  padding: .4em;
}
/deep/ .editor:empty:before {
  color: gray;
  content: attr(aria-placeholder);
  display: block;
}
/deep/ .required, .errorMessage {
  color: #ff5252 !important;
}
/deep/ .required-border {
  border: 1px solid #ff5252;
}
/deep/ .autocomplete-label--is-readonly {
  color: rgba(0,0,0,.38) !important;
  font-size: 12px !important;
}
/deep/ .autocomplete--is-readonly {
  color: rgba(0,0,0,.38);
  border: 1px dashed rgba(0,0,0,.3)
}
/deep/ a.ticketing-user {
  pointer-events: none;
  cursor: unset;
}
/deep/ span.property-slot, /deep/ span.tenant-slot, /deep/ span.client-slot,
/deep/ span.caretaker-slot, /deep/ span.craftsman-slot,
/deep/ span.object-slot {
  cursor: pointer;
}
/deep/ a.tenant::after, /deep/ .hint-box-tenant::after {
  content: url('./../../../assets/icons/tenant.svg');
  vertical-align: sub;
  padding-left: 2px;
}
/deep/ a.caretaker::after, /deep/ .hint-box-caretaker::after {
  content: url('./../../../assets/icons/caretaker.svg');
  vertical-align: sub;
  padding-left: 2px;
}
/deep/ a.ticketing-user::after, /deep/ .hint-box-ticketing-user::after {
  content: url('./../../../assets/icons/user.svg');
  vertical-align: sub;
  padding-left: 2px;
}
/deep/ a.property::after, /deep/ .hint-box-property::after {
  content: url('./../../../assets/icons/property.svg');
  vertical-align: sub;
  padding-left: 4px;
}
/deep/ a.object::after, /deep/ .hint-box-object::after {
  content: url('./../../../assets/icons/sofa.svg');
  vertical-align: sub;
  padding-left: 4px;
}
/deep/ a.client::after, /deep/ .hint-box-client::after {
  content: url('./../../../assets/icons/client.svg');
  vertical-align: sub;
  padding-left: 4px;
}
/deep/ a.craftsman::after, /deep/ .hint-box-craftsman::after {
  content: url('./../../../assets/icons/craftsman.svg');
  vertical-align: sub;
  padding-left: 4px;
}
/deep/ a.partner::after, /deep/ .hint-box-partner::after {
  content: url('./../../../assets/icons/partner.svg');
  vertical-align: sub;
  padding-left: 4px;
}
/deep/ a.ticket::after, /deep/ .hint-box-ticket::after {
  content: url('./../../../assets/icons/ticket.svg');
  vertical-align: sub;
  padding-left: 4px;
}
/deep/ .hint-box {
  color: rgba(0,0,0,.87);
}
</style>
