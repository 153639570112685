import gql from 'graphql-tag'

const TICKETING_TICKETS = gql` 
  query ticketingTickets ($query: TicketingTicketSearchQuery!) {
    ticketingTickets(query: $query) {
      items {
        ticketId
        subject
      }
      totalItems
    }
  }
`

export { TICKETING_TICKETS }
