<template>
  <div v-if="candidateGroupsLoaded" class="task-candidate-groups" :class="candidateGroupeStyleClass">
    <v-card-text v-if="showTitleLabel" class="pa-0 mr-2s">
      {{ $t('ticketingSystem.note.selectCandidateGroup') }}
    </v-card-text>
    <template v-for="candidateGroup in candidateGroupsWithDefaultSelections">
      <v-checkbox
        v-show="finishedLoading"
        class="mt-0 pt-0"
        dense
        hide-details
        :key="'group-' + candidateGroup.groupId"
        :value="candidateGroup.checked"
        :label="getCandidateGroupNameTranslation(candidateGroup.groupId)"
        :disabled="taskFinished"
        v-model="candidateGroup.checked"
        @change="updateCandidateGroup($event, candidateGroup.groupId)"
      ></v-checkbox>
    </template>
  </div>
</template>

<script>
import { getCandidateGroupName } from '@/helpers/candidateGroupsHelper'

import { mapState, mapActions } from 'vuex'
import _cloneDeep from 'lodash.clonedeep'

export default {
  name: 'CandidateGroups',
  props: {
    taskCandidateGroups: {
      type: Array,
      default: () => []
    },
    taskId: {
      type: String
    },
    taskFinished: {
      type: Boolean,
      default: false
    },
    finishedLoading: {
      type: Boolean,
      default: false
    },
    showTitleLabel: {
      type: Boolean,
      default: false
    },
    checkList: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      candidateGroupsWithDefaultSelections: []
    }
  },
  mounted () {
    this.loadCandidateGroups()
  },
  watch: {
    finishedLoading: {
      handler: function (value) {
        if (value) {
          this.setCandidateGroupsWithDefaultSelections()
        }
      },
      immediate: true
    },
    candidateGroupsLoaded: 'setCandidateGroupsWithDefaultSelections'
  },
  computed: {
    ...mapState('ticketingSystem', ['candidateGroups']),
    candidateGroupsLoaded () {
      return this.candidateGroups && this.candidateGroups.length > 0
    },
    candidateGroupeStyleClass () {
      return this.checkList ? 'mt-2' : 'd-flex align-center mt-2 mt-md-0 ml-md-5'
    }
  },
  methods: {
    ...mapActions('ticketingSystem', ['loadCandidateGroups']),
    ...mapActions('notifier', ['setSuccessSnackbar', 'setErrorSnackbar']),
    getCandidateGroupNameTranslation (groupId) {
      return getCandidateGroupName(groupId)
    },
    setCandidateGroupsWithDefaultSelections () {
      this.candidateGroupsWithDefaultSelections = []
      let clonedCandidateGroups = _cloneDeep(this.candidateGroups)
      let that = this
      this.candidateGroupsWithDefaultSelections = clonedCandidateGroups.map(candidateGroup => {
        let checked = false
        if (that.taskCandidateGroups && that.taskCandidateGroups.length > 0) {
          checked = that.taskCandidateGroups.findIndex(groupId => groupId === candidateGroup.groupId || groupId.id === candidateGroup.groupId) !== -1
        }
        return Object.assign(candidateGroup, { checked })
      })
    },
    updateCandidateGroup (checked, groupId) {
      if (checked) {
        this.addCandidateGroup(groupId)
      } else {
        this.deleteCandidateGroup(groupId)
      }
    },
    addCandidateGroup (groupId) {
      let that = this
      this.$prestigeTSTask.addCandidateGroup(this.taskId, { groupId }).then(() => {
        that.setSuccessSnackbar(that.$t('ticketingSystem.messages.successfullyAddedCandidateGroup'))
        return true
      }).catch(error => {
        that.loadTaskDetails()
        that.setErrorSnackbar(error)
      })
    },
    deleteCandidateGroup (groupId) {
      let that = this
      this.$prestigeTSTask.deleteCandidateGroup(this.taskId, { groupId }).then(() => {
        that.setSuccessSnackbar(that.$t('ticketingSystem.messages.successfullyDeletedCandidateGroup'))
        return true
      }).catch(error => {
        that.loadTaskDetails()
        that.setErrorSnackbar(error)
      })
    },
    loadTaskDetails () {
      this.$emit('loadTaskDetails')
    }
  }
}
</script>

<style scoped lang="postcss">
</style>
