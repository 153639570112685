<template>
  <v-flex class="mt-3" xs12 sm12 md6 lg6 xl6 :class="$vuetify.breakpoint.md ? 'pb-6' : ''">
    <v-card height="100%">
      <v-container fluid grid-list-xs>
        <v-form ref="mailForm">
          <v-card-title class="pa-0">{{ $t('tasks.email.title') }}</v-card-title>
          <v-layout row>
            <v-flex xs12 sm12 md12 lg4 xl4>
              <v-layout>
                <v-checkbox :disabled="readOnly" :prepend-icon="tenantIcon" :label="$t('tasks.tenant.title')" v-model="mailConfig.tenant" @change="formValidate($event)"></v-checkbox>
              </v-layout>
            </v-flex>
            <p class="red--text mb-0 mt-5 error-email" v-if="tenantError">{{ $t('tasks.email.selectionMessages.tenant') }}</p>
            <v-flex xs12 sm12 md12 lg8 xl8 v-else-if="!noTenant">
              <v-text-field
                v-if="mutableMailForm.currentTenant && mutableMailForm.tenant === 'CURRENT'"
                v-model="mutableMailForm.currentTenant.email"
                :label="$t('tasks.email.textField')"
                :rules="mailConfig.tenant ? [rules.required, rules.email] : []"
                :disabled="readOnly"
                @input="debounceInput"
              ></v-text-field>
              <v-text-field
                v-if="mutableMailForm.newTenant && mutableMailForm.tenant === 'NEW'"
                v-model="mutableMailForm.newTenant.email"
                :label="$t('tasks.email.textField')"
                :rules="mailConfig.tenant ? [rules.required, rules.email] : []"
                :disabled="readOnly"
                @input="debounceInput"
              ></v-text-field>
              <v-text-field
                v-if="mutableMailForm.manualTenant  && mutableMailForm.tenant === 'MANUAL'"
                v-model="mutableMailForm.manualTenant.email"
                :label="$t('tasks.email.textField')"
                :rules="mailConfig.tenant ? [rules.required, rules.email] : []"
                :disabled="readOnly"
                @input="debounceInput"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-divider v-if="mdAndDown" class="mb-2"></v-divider>
          <v-layout row>
            <v-flex xs12 sm12 md12 lg4 xl4 class="client">
              <v-layout>
                <v-checkbox :disabled="readOnly" :prepend-icon="clientIcon" :label="$t('tasks.email.owner')" v-model="mailConfig.client"  @change="formValidate($event)"></v-checkbox>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm12 md12 lg8 xl8>
              <p class="red--text mb-0 mt-5 error-email" v-if="clientError">{{ $t('tasks.email.selectionMessages.client') }}</p>
              <v-text-field
                v-else-if="mutableMailForm.client"
                v-model="mutableMailForm.client.email"
                :label="$t('tasks.email.textField')"
                :rules="mailConfig.client ? [rules.required, rules.email] : []"
                :disabled="readOnly"
                @input="debounceInput"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-divider v-if="mdAndDown" class="mb-2"></v-divider>
          <v-layout row>
            <v-flex xs12 sm12 md12 lg4 xl4 class="caretaker">
              <v-layout>
                <v-checkbox :disabled="readOnly" :prepend-icon="caretakerIcon" :label="$t('tasks.info.caretaker')" v-model="mailConfig.careTaker" @change="formValidate($event)"></v-checkbox>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm12 md12 lg8 xl8>
              <p class="red--text mb-0 mt-5 error-email" v-if="caretakerError">{{ $t('tasks.email.selectionMessages.tenant') }}</p>
              <v-text-field
                v-else-if="mutableMailForm.caretaker"
                v-model="mutableMailForm.caretaker.email"
                :label="$t('tasks.email.textField')"
                :rules="mailConfig.careTaker ? [rules.required, rules.email] : []"
                :disabled="readOnly"
                @input="debounceInput"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-divider v-if="mdAndDown" class="mb-2"></v-divider>
          <v-layout row>
            <v-flex xs12 sm12 md12 lg4 xl4>
              <v-layout>
                <v-checkbox :disabled="readOnly" :prepend-icon="craftsmanIcon" :label="$t('tasks.craftsmen.title')" v-model="mailConfig.craftsmen" @change="formValidate($event)"></v-checkbox>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm12 md12 lg8 xl8>
              <p class="red--text mb-0 mt-5 error-email" v-if="craftsmanError">{{ $t('tasks.email.selectionMessages.craftsman') }}</p>
              <v-text-field
                v-else-if="mutableMailForm.craftsman"
                v-model="mutableMailForm.craftsman.email"
                :label="$t('tasks.email.textField')"
                :rules="mailConfig.craftsman ? [rules.required, rules.email] : []"
                :disabled="readOnly"
                @input="debounceInput"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-divider v-if="mdAndDown" class="mb-2"></v-divider>
          <v-layout row>
            <v-flex xs12 sm12 md12 lg4 xl4>
              <v-layout>
                <v-checkbox :disabled="readOnly" :prepend-icon="realEstateManagerIcon" :label="$t('tasks.email.realEstateManager')" v-model="realEstateManagerChecked" @change="formValidate($event)"></v-checkbox>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm12 md12 lg4 xl4 class="pr-2">
              <p class="red--text mb-0 mt-5 error-email" v-if="realEstateManagerError">{{ $t('tasks.email.selectionMessages.realEstateManager') }}</p>
              <v-text-field
                v-else-if="propertyRealEstateManager"
                v-model="propertyRealEstateManager.email"
                :label="$t('tasks.email.textField')"
                :rules="realEstateManagerChecked ? [rules.required, rules.email] : []"
                :disabled="readOnly"
              ></v-text-field>
            </v-flex>
            <v-flex v-if="propertyRealEstateManager" xs12 sm12 md12 lg4 xl4>
              <RealEstateManager
                v-bind:form="newRealEstateManager"
                :read-only="readOnly"
              />
            </v-flex>
          </v-layout>
          <v-divider v-if="mdAndDown" class="mb-4"></v-divider>
          <ConfirmationDialog :data="emailDialogData" :show-dialog="showResendDialog" :disabled="disableBtn" @closeDialog="closeResendDialog" @submitDialog="doSendEmail" />
          <v-btn color="primary" @click="sendEmail" :disabled="sendEmailDisabled || readOnly">
            <span>{{ $t('tasks.email.sendMail') }}</span>
            <v-icon>send</v-icon>
          </v-btn>
          <p v-if="disabled" class="red--text pt-2">{{ $t('tasks.email.saveChanges') }}</p>
        </v-form>
      </v-container>
    </v-card>
  </v-flex>
</template>

<script>
import RealEstateManager from './../RealEstateManager.vue'
import ConfirmationDialog from '@/components/ConfirmationDialog'
import { mapActions, mapState } from 'vuex'
import _debounce from 'lodash.debounce'
import * as validationHelper from '@/helpers/validationHelper'

export default {
  name: 'SendTaskMail',
  props: {
    mailForm: {
      type: Object,
      default: () => {}
    },
    mailHistory: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ConfirmationDialog,
    RealEstateManager
  },
  watch: {
    'newRealEstateManager.value' () {
      this.propertyRealEstateManager = this.realEstateManagers.find(x => x.addressId === this.newRealEstateManager.value.addressId)
    },
    disabled () {
      if (!this.disabled) {
        this.mailsFormValid = this.$refs.mailForm.validate()
      }
    }
  },
  data () {
    return {
      propertyRealEstateManager: {email: null},
      newRealEstateManager: { value: null },
      showResendDialog: false,
      disableBtn: false,
      realEstateManagerChecked: false,
      mailConfig: {
        taskId: parseInt(this.$route.params.taskId),
        tenant: true,
        client: false,
        careTaker: false,
        craftsmen: true,
        realEstateManager: null
      },
      mailsFormValid: true,
      rules: {
        required: (value) => (!!value && value !== undefined) || this.requiredMessage,
        email: (value) => {
          let valid = validationHelper.validateEmail(value)
          return valid
        }
      }
    }
  },
  computed: {
    ...mapState('abacus', ['realEstateManagers']),
    requiredMessage () {
      return this.$t('common.errors.required')
    },
    tenantIcon () {
      if (this.mailHistory.filter(mail => mail.success && mail.receiver === 'TENANT').length > 0) {
        return 'email'
      } else if (this.mailHistory.filter(mail => mail.receiver === 'TENANT').length > 0) {
        return 'warning'
      } else {
        return 'remove'
      }
    },
    clientIcon () {
      if (this.mailHistory.filter(mail => mail.success && mail.receiver === 'CLIENT').length > 0) {
        return 'email'
      } else if (this.mailHistory.filter(mail => mail.receiver === 'CLIENT').length > 0) {
        return 'warning'
      } else {
        return 'remove'
      }
    },
    caretakerIcon () {
      if (this.mailHistory.filter(mail => mail.success && mail.receiver === 'CARETAKER').length > 0) {
        return 'email'
      } else if (this.mailHistory.filter(mail => mail.receiver === 'CARETAKER').length > 0) {
        return 'warning'
      } else {
        return 'remove'
      }
    },
    craftsmanIcon () {
      if (this.mailHistory.filter(mail => mail.success && mail.receiver === 'CRAFTSMAN').length > 0) {
        return 'email'
      } else if (this.mailHistory.filter(mail => mail.receiver === 'CRAFTSMAN').length > 0) {
        return 'warning'
      } else {
        return 'remove'
      }
    },
    realEstateManagerIcon: function () {
      if (this.mailHistory.filter(mail => mail.success && mail.receiver === 'REAL_ESTATE_MANAGER').length > 0) {
        return 'email'
      } else if (this.mailHistory.filter(mail => mail.receiver === 'REAL_ESTATE_MANAGER').length > 0) {
        return 'warning'
      } else {
        return 'remove'
      }
    },
    mdAndDown () {
      return this.$vuetify.breakpoint.mdAndDown
    },
    emailDialogData () {
      return {
        title: this.$t('tasks.dialog.email.title'),
        question: this.$t('tasks.dialog.email.question'),
        confirmButton: this.$t('common.buttons.send'),
        action: 'doSendEmail'
      }
    },
    noRecipients () {
      return !this.mailConfig.tenant && !this.mailConfig.client && !this.mailConfig.careTaker && !this.mailConfig.craftsmen && !this.realEstateManagerChecked
    },
    noTenant () {
      return this.mutableMailForm.currentTenant === null && this.mutableMailForm.newTenant === null && this.mutableMailForm.manualTenant === null
    },
    tenantError () {
      return this.mailConfig.tenant && this.noTenant
    },
    clientError () {
      return this.mailConfig.client && this.mutableMailForm.client === null
    },
    caretakerError () {
      return this.mailConfig.careTaker && this.mutableMailForm.caretaker === null
    },
    craftsmanError () {
      return this.mailConfig.craftsmen && this.mutableMailForm.craftsman === null
    },
    realEstateManagerError () {
      return this.realEstateManagerChecked && this.propertyRealEstateManager === null
    },
    checkboxRecipientError () {
      return this.tenantError || this.clientError || this.caretakerError || this.craftsmanError || this.realEstateManagerError
    },
    sendEmailDisabled () {
      return this.disabled || this.disableBtn || this.noRecipients || this.checkboxRecipientError || !this.mailsFormValid
    },
    mutableMailForm () {
      return this.mailForm
    }
  },
  created () {
    Promise.all([this.$store.dispatch('abacus/loadRealEstateManagers')]).then(() => this.loadProperty())
  },
  methods: {
    ...mapActions('notifier', ['setSuccessSnackbar', 'setErrorSnackbar']),
    loadProperty () {
      let that = this
      this.$abacusProperty.getPropertyById(this.mutableMailForm.propertyId).then(function (response) {
        if(response.data.realEstateManager) {
          that.propertyRealEstateManager = response.data.realEstateManager
          that.newRealEstateManager.value = that.propertyRealEstateManager
          that.newRealEstateManager.value.addressId = that.newRealEstateManager.value.id
          delete that.newRealEstateManager.value.id
        }
        return true
      }).catch(function (error) {
        that.$store.dispatch('notifier/setAlert', {
          text: error
        })
        that.propertyRealEstateManager = {}
      })
    },
    sendEmail () {
      if (this.$refs.mailForm.validate()) {
        if (this.mailHistory.filter(mail => mail.success).length === 0) {
          this.doSendEmail()
        } else {
          this.openResendDialog()
        }
      }
    },
    doSendEmail () {
      if (this.realEstateManagerChecked && this.propertyRealEstateManager) {
        this.mailConfig.realEstateManager = Object.assign({}, { email: this.propertyRealEstateManager.email })
      } else {
        this.mailConfig.realEstateManager = null
      }
      let that = this
      this.disableBtn = true
      this.$prestigeTaskEmail.sendMail(this.mailConfig.taskId, this.mailConfig).then(response => {
        that.closeResendDialog()
        that.disableBtn = false
        that.setSuccessSnackbar(that.$t('tasks.email.successfullySent'))
        that.$emit('fetchMailHistory')
        return true
      }).catch(function (error) {
        that.setErrorSnackbar(error)
        that.closeResendDialog()
      })
    },
    debounceInput: _debounce(function (e) {
      this.updateFormValues()
    }, 1000),
    updateFormValues () {
      this.$emit('updateFormValues', {
        ...this.mutableMailForm
      })
    },
    formValidate () {
      this.$nextTick(() => {
        this.mailsFormValid = this.$refs.mailForm.validate()
      })
    },
    openResendDialog () {
      this.showResendDialog = true
    },
    closeResendDialog () {
      this.showResendDialog = false
    }
  }
}
</script>
