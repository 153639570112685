export function initForm () {
  return {
    taskType: 'TASK',
    insuranceCase: null,
    subjectLine1: '',
    subjectLine2: '',
    jobDescription: '',
    comment: '',
    files: null,
    propertyId: '',
    propertyStreet: '',
    propertyZip: '',
    propertyPlace: '',
    houseId: 1,
    houseStreet: '',
    objectId: '',
    objectText: '',
    client: null,
    tenant: '',
    currentTenantText: '',
    newTenantText: '',
    currentTenant: null,
    newTenant: null,
    newTenantContractStart: null,
    manualTenant: null,
    caretaker: null,
    craftsman: null,
    craftsmenComment: '',
    realEstateManager: null,
    competenceSum: null
  }
}

export function createHelperObject (data, isEdit = false) {
  if (data) {
    return {
      objectId: parseInt(data.objectId),
      objectText: data.objectText,
      subjectLine1: isEdit ? data.subjectLine1 : data.objectText + ' / ' + data.floorText,
      subjectLine2: isEdit ? data.subjectLine2 : setSubjectLine2(data),
      houseId: data.houseId,
      houseStreet: data.houseStreet,
      currentTenantText: data.currentTenantText,
      newTenantText: data.newTenantText,
      newTenantContractStart: data.newTenantContractStart,
      currentTenant: data.currentTenant,
      newTenant: data.newTenant ? data.newTenant : (data.newTenantText ? setTenantData(data.newTenantText) : null),
      manualTenant: data.manualTenant
    }
  }
  return {}
}

export function setTenantData (newTenantText) {
  return {
    addressId: -1,
    text: newTenantText
  }
}

export function getTenant (propertyObject) {
  if (propertyObject && propertyObject.currentTenant) {
    return 'CURRENT'
  } else if (propertyObject && propertyObject.newTenant) {
    return 'NEW'
  } else {
    return 'MANUAL'
  }
}

export function removeProp (obj, propToDelete) {
  let objectKeys = ['client', 'manualTenant', 'newTenant', 'currentTenant', 'caretaker', 'craftsman', 'realEstateManager']
  objectKeys.forEach(objName => {
    if (obj[objName] && obj[objName].hasOwnProperty(propToDelete)) {
      delete obj[objName][propToDelete]
    }
  })
}

export function mapAddressId (obj) {
  let objectKeys = ['client', 'manualTenant', 'newTenant', 'currentTenant', 'caretaker', 'craftsman', 'realEstateManager']
  objectKeys.forEach(objName => {
    if (obj[objName] && !obj[objName]['addressId']) {
      obj[objName]['addressId'] = obj[objName]['id']
      delete obj[objName]['id']
    }
  })
}

export function setSubjectLine2 (propertyObject) {
  let subjectLine2 = ''
  if (propertyObject.currentTenant && propertyObject.tenant === 'CURRENT') {
    subjectLine2 = (propertyObject.currentTenantText || setFirstNameAndLastName(propertyObject.currentTenant)) + setPhone(propertyObject.currentTenant)
  } else if (propertyObject.newTenant && propertyObject.tenant === 'NEW') {
    subjectLine2 = (propertyObject.newTenantText || propertyObject.newTenant.text || setFirstNameAndLastName(propertyObject.newTenant)) + setPhone(propertyObject.newTenant)
  } else if (propertyObject.manualTenant && propertyObject.tenant === 'MANUAL') {
    subjectLine2 = setFirstNameAndLastName(propertyObject.manualTenant) + setPhone(propertyObject.manualTenant)
  } else {
    subjectLine2 = propertyObject.currentTenantText
  }
  return subjectLine2
}

export function setFirstNameAndLastName (object) {
  return object ? (object.firstName ? (object.firstName + ' ') : '') + (object.lastName ? object.lastName : '') : ''
}

export function setPhone (obj) {
  let char = ' / '
  if (obj.mobile && obj.mobile !== '') {
    return char + obj.mobile
  } else if (obj.phone1 && obj.phone1 !== '') {
    return char + obj.phone1
  } else if (obj.phone2 && obj.phone2 !== '') {
    return char + obj.phone2
  } else {
    return ''
  }
}
