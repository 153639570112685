<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="pa-2">
      <v-card-subtitle class="pa-0 ma-0 font-weight-bold">
        {{ $t('renting.rentingForms') }}
      </v-card-subtitle>
      <template v-slot:actions>
        <v-icon color="brown lighten-2">mdi-table</v-icon>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="pa-0">
      <v-data-table
        :headers="headers"
        :items="processesWithVariables"
        :options.sync="options"
        :footer-props="{itemsPerPageOptions:itemsPerPageOptions, itemsPerPageText: itemsPerPageText}"
        item-key="objectId"
        class="elevation-1"
      >
        <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
        <template v-slot:[`item.processStartedOnFormatted`]="{ item }">
          <span>{{ item.processStartedOnFormatted }}</span>
        </template>
        <template v-slot:[`item.processDecision`]="{ item }">
          <DecisionIcon :decision="item.processDecision" />
          <span>{{ $t('renting.statuses.' + item.processDecision) }}</span>
        </template>
        <template v-slot:[`item.step`]="{ item }">
          <div v-if="item.processDetails.openTasks">
            <p v-for="(task, index) in item.processDetails.openTasks" :key="'open_task' + index" class="open-task pa-1 mb-0">
              {{ task.name }}
            </p>
          </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <nobr>
            <v-btn x-small color="blue" @click.stop="openProcessDetails(item.processDetails.process.processInstanceId)">
              <span class="white--text">{{ $t('common.buttons.open') }}</span>
              <v-icon small class="ml-2" color="white">mdi-window-open</v-icon>
            </v-btn>
          </nobr>
        </template>
      </v-data-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import DecisionIcon from '@/components/DecisionIcon'
import { mapActions } from 'vuex'
import { formatDateAndTime } from '@/helpers/formatterHelper'
import * as ticketingHelper from '@/features/ticketing/helpers/ticketingHelper'

export default {
  name: 'ProcessRentalFormsTable',
  components: {
    DecisionIcon
  },
  props: {
    rentalCaseId: {
      type: String,
      default: ''
    }
  },
  watch: {
    'rentalCaseId': 'getRentalCaseWithForms'
  },
  data () {
    return {
      options: {
        sortBy: ['startedOn'],
        sortDesc: [true],
        itemsPerPage: 20,
        page: 1
      },
      itemsPerPageOptions: [ 20, 50, 100 ],
      rentingForms: [],
      processInstanceIds: [],
      processesWithVariables: []
    }
  },
  computed: {
    headers () {
      return [
        {
          text: this.$t('ticketingSystem.processes.reletting.labels.name'),
          align: 'left',
          value: 'name',
          sortable: true
        },
        {
          text: this.$t('ticketingSystem.processes.reletting.labels.received'),
          align: 'left',
          value: 'processStartedOnFormatted',
          sortable: true
        },
        {
          text: this.$t('ticketingSystem.processes.reletting.labels.step'),
          value: 'step',
          sortable: false
        },
        {
          text: this.$t('ticketingSystem.processes.reletting.labels.state'),
          align: 'left',
          value: 'processDecision'
        },
        {
          text: this.$t('ticketingSystem.processes.reletting.labels.actions'),
          sortable: false,
          value: 'actions'
        }
      ]
    },
    itemsPerPageText () {
      return this.$t('common.itemsPerPageText')
    }
  },
  created () {
    this.getRentalCaseWithForms()
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    getRentalCaseWithForms () {
      if (this.rentalCaseId) {
        let that = this
        this.$prestigeRenting.getRentalCaseWithForms(this.rentalCaseId).then(response => {
          if (response.data && response.data.rentingForms && response.data.rentingForms.length > 0) {
            that.rentingForms = response.data.rentingForms
            that.processInstanceIds = that.rentingForms.map(x => { return x.processInstanceId })
            that.getProcessesWithVariables()
          }
        }).catch(error => {
          that.setErrorSnackbar(error)
        })
      }
    },
    getProcessesWithVariables () {
      if (this.processInstanceIds && this.processInstanceIds.length > 0) {
        let that = this
        this.$prestigeTSProcess.getProcessesWithVariables(this.processInstanceIds).then(response => {
          if (response.data && response.data.length > 0) {
            that.processesWithVariables = response.data
            that.processesWithVariables.map(x => {
              let form = that.rentingForms.find(form => form.processInstanceId === x.processDetails.process.processInstanceId)
              x.name = form
                ? (form.content.residents && form.content.residents.length > 0)
                  ? (form.content.residents[0].firstName + ' ' + form.content.residents[0].lastName).trim()
                  : ''
                : ''
              x.processStartedOnFormatted = formatDateAndTime(x.processDetails.process.startedOn)
              x.processDecision =
                that.getVariableValue(x.variables, 'System_InterestedPerson_Decision_Enum') || 'IN_PROGRESS'
            })
          }
        }).catch(error => {
          that.setErrorSnackbar(error)
        })
      }
    },
    getVariableValue (processVariables, variableName) {
      return ticketingHelper.getVariableValue(processVariables, variableName)
    },
    openProcessDetails (processInstanceId) {
      window.open('/ticketing/processes/' + processInstanceId)
    }
  }
}
</script>
<style scoped lang="postcss">
/deep/ .v-expansion-panel-content__wrap {
  padding: 8px;
}
.open-task {
  overflow: hidden;
  max-width: 24ch;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
