<template>
  <div v-if="abacusAddress">

    <v-list-item>
      <v-list-item-content>
        <v-card-text class="pa-0">
          <p class="pa-0 ma-0">
            <span v-if="abacusAddress.addressId">[<a @click="goToDashboardAddressDetails(abacusAddress.addressId.toString())">{{ abacusAddress.addressId }}</a>]</span>
            <span class="pa-0 ma-0" v-if="abacusAddress.firstName || abacusAddress.lastName">
              {{ abacusAddress.firstName }} {{ abacusAddress.lastName }}
            </span>
          </p>
          <p class="pa-0 ma-0" v-if="abacusAddress.line1 || abacusAddress.line2">{{ abacusAddress.line1 || abacusAddress.line2 }}</p>
          <p class="pa-0 ma-0" v-if="abacusAddress.zip || abacusAddress.place">{{ abacusAddress.zip }} {{ abacusAddress.place }}</p>
          <p class="pa-0 ma-0" v-if="abacusAddress.phone1">{{ abacusAddress.phone1 }}</p>
          <p class="pa-0 ma-0" v-if="abacusAddress.phone2">{{ abacusAddress.phone2 }}</p>
          <p class="pa-0 ma-0" v-if="abacusAddress.mobile">{{ abacusAddress.mobile }}</p>
          <p class="pa-0 ma-0" v-if="abacusAddress.email">{{ abacusAddress.email }}</p>
        </v-card-text>
      </v-list-item-content>
      <v-list-item-action>
        <ActionsMenu
          :item="getItem(abacusAddress)"
          :actions="actions"
          :delete-action="deleteAction"
        />
      </v-list-item-action>
    </v-list-item>

    <v-expansion-panels
      v-if="hasExpansionPanels"
      :key="abacusAddress.id + '_panel'"
      class="px-3"
    >
      <template v-for="(panel, index) in expansionPanles">
        <v-expansion-panel v-if="panel.show" :key="index">
          <v-expansion-panel-header class="pa-3">
            <v-card-subtitle class="pa-0 ma-0 font-weight-bold">
              {{ panel.title }}
            </v-card-subtitle>
            <template v-slot:actions>
              <v-icon color="brown lighten-2">mdi-view-list</v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <TenantAddressObjects
              v-if="abacusAddress.tenantObjects"
              :tenant-id="abacusAddress.addressId.toString()"
              :tenant-info="getAddressInfo(abacusAddress)"
              :tenant-address-objects="panel.objects"
              :process-instance-id="processInstanceId"
              @openNewTicketDialog="onOpenNewTicketDialog"
              class="pl-3"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </template>
    </v-expansion-panels>
  </div>
</template>

<script>
import ActionsMenu from '@/components/ActionsMenu'
import TenantAddressObjects from '@/features/shared/TenantAddressObjects'

import { useTenantActions } from '@/use/useTenantActions'
import { useAddressActions } from '@/use/useAddressActions'
import { getAddressInfo, getFullName } from '@/helpers/globalHelper'
import { openDashboardAddress } from '@/helpers/routesHelper'

import { ABACUS_ADDRESS_TENANT } from '../../../dashboard/graphql-apollo/abacusAddress.gql'

const { tenantActions } = useTenantActions()
const { addressActions } = useAddressActions()

export default {
  name: 'ProcessAddressTenantLink',
  components: {
    ActionsMenu,
    TenantAddressObjects
  },
  props: {
    addressId: {
      type: String,
      required: true
    },
    addressType: {
      type: String,
      default: ''
    },
    deleteAction: {
      type: Object,
      default: () => {}
    },
    processInstanceId: {
      type: String,
      default: ''
    }
  },
  apollo: {
    abacusAddress: {
      query: ABACUS_ADDRESS_TENANT,
      variables () {
        return {
          addressId: parseInt(this.addressId)
        }
      },
      skip () {
        return !this.addressId
      },
      fetchPolicy: 'cache-first'
    }
  },
  computed: {
    additionalActions () {
      return [
        { title: this.$t('dashboard.actions.newTicket'), icon: 'mdi-ticket', action: this.onNewTicket },
        ...addressActions
      ]
    },
    actions () {
      return [...this.additionalActions, ...tenantActions]
    },
    expansionPanles () {
      return [
        {
          title: this.$t('ticketingSystem.objects.tenantOf'),
          show: this.hasTenantObjects,
          objects: this.abacusAddress.tenantObjects
        },
        {
          title: this.$t('ticketingSystem.objects.subletOf'),
          show: this.hasSubletObjects,
          objects: this.abacusAddress.subletObjects
        }
      ]
    },
    hasTenantObjects () {
      return this.abacusAddress.tenantObjects?.length > 0
    },
    hasSubletObjects () {
      return this.abacusAddress.subletObjects?.length > 0
    },
    hasExpansionPanels () {
      return this.hasTenantObjects || this.hasSubletObjects
    }
  },
  methods: {
    getItem (item) {
      return Object.assign(item, { linkName: this.addressType, linkValue: this.abacusAddress.addressId.toString() })
    },
    onNewTicket (item) {
      let links = [{
        linkName: item.linkName,
        linkValues: item.linkValue ? [item.linkValue] : []
      }]
      let subject = getAddressInfo(item) + ' - ' + getFullName(item)
      this.$emit('openNewTicketDialog', links, subject)
    },
    getAddressInfo (address) {
      return (address.firstName + ' ' + address.lastName).trim()
    },
    goToDashboardAddressDetails (addressId) {
      openDashboardAddress(addressId, this.addressType)
      localStorage.setItem('deepLink', true)
    },
    onOpenNewTicketDialog (links = [], subject, processDefinitionKey = null, variables = [], property = null, object = null) {
      this.$emit('openNewTicketDialog', links, subject, processDefinitionKey, variables, property, object)
    }
  }
}
</script>
