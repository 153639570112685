<template>
  <v-container class="ml-1 pr-1">
    <v-card-title class="pa-0 mb-1">{{ $t('dashboard.taskList.title') }}</v-card-title>
    <v-data-table
      :class="tableClassName"
      :headers="headers"
      :items="tasks"
      :loading="loading"
      :item-class="rowClass"
      :footer-props="{itemsPerPageOptions: itemsPerPageOptions, itemsPerPageText: itemsPerPageText}"
      @click:row="taskSelected"
      class="elevation-1"
    >
      <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
      <template v-slot:[`item.subjectLine1`]="{ item }">
        <p class="truncate-one-line">{{ item.subjectLine1 }}</p><p class="truncate-one-line">{{ item.subjectLine2 }}</p>
      </template>
      <template v-slot:[`item.jobDescription`]="{ item }">
        <p class="truncate-two-lines">{{ item.jobDescription }}</p>
      </template>
      <template v-slot:[`item.createdOn`]="{ item }">
        {{ formatDateAndTimeFunc(item.createdOn) }}
      </template>
      <template slot="no-data">
        <v-alert class="mt-4" :value="true" color="error" icon="warning">
          {{ $t('table.noData') }}
        </v-alert>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { formatDateAndTime } from '@/helpers/formatterHelper'
import { setRowClassByDiff } from '@/helpers/momentHelper'
import { mapActions } from 'vuex'

export default {
  name: 'TaskList',
  data () {
    return {
      tableClassName: 'taskList',
      itemsPerPageOptions: [10, 15, 25],
      tasks: [],
      totalItems: 0,
      loading: false
    }
  },
  props: {
    routeAddressType: {
      type: String,
      default: ''
    },
    routeAddressId: {
      type: String,
      default: ''
    },
    propertyId: {
      type: String,
      default: ''
    }
  },
  watch: {
    routeAddressType (value) {
      if (value && !this.loading) {
        this.getTasks()
      }
    },
    routeAddressId (value) {
      if (value && !this.loading) {
        this.getTasks()
      }
    },
    propertyId (value) {
      if (value && !this.loading) {
        this.getTasks()
      }
    }
  },
  created () {
    if ((this.routeAddressType && this.routeAddressId) || this.propertyId) {
      this.getTasks()
    }
  },
  computed: {
    headers () {
      return [
        {
          text: this.$t('dashboard.taskList.subject'),
          align: 'left',
          value: 'subjectLine1',
          sortable: true
        },
        {
          text: this.$t('dashboard.taskList.description'),
          align: 'left',
          value: 'jobDescription',
          sortable: true
        },
        {
          text: this.$t('dashboard.taskList.createdOn'),
          align: 'left',
          value: 'createdOn',
          sortable: true
        }
      ]
    },
    itemsPerPageText () {
      return this.$t('common.itemsPerPageText')
    }
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    formatDateAndTimeFunc: formatDateAndTime,
    async getTasks () {
      let that = this
      this.loading = true
      this.tasks = []
      let params = {}
      if (this.routeAddressType && this.routeAddressId) {
        params.clientId = this.routeAddressType === 'client' ? parseInt(this.routeAddressId) : null
        params.tenantId = this.routeAddressType === 'tenant' ? parseInt(this.routeAddressId) : null
        params.caretakerId = this.routeAddressType === 'caretaker' ? parseInt(this.routeAddressId) : null
        params.craftsmanId = this.routeAddressType === 'craftsman' ? parseInt(this.routeAddressId) : null
      } else if (this.propertyId) {
        params.propertyId = parseInt(this.propertyId)
      }
      this.$prestigeTask.searchTasksV2(undefined, {query: params}).then(function (response) {
        if (response.data.length) {
          that.tasks = response.data
        }
        that.loading = false
        return true
      }).catch(function (error) {
        that.setErrorSnackbar(error)
        that.loading = false
      })
    },
    taskSelected (row) {
      let routeData = this.$router.resolve(({ name: 'TaskDetails', params: {taskId: row.id} }))
      window.open(routeData.href, '_blank')
    },
    rowClass (item) {
      return setRowClassByDiff(item.createdOn)
    }
  }
}
</script>

<style scoped lang="postcss">
/deep/ tr {
  cursor: pointer;
}
/deep/ tr.v-data-table__selected {
  background: rgb(228, 239, 250) !important;
}
.truncate-one-line {
  max-height: 39px;
  display: -webkit-inline-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  margin: 0 auto;
  line-height: 1.5;
}
.truncate-two-lines {
  max-height: 39px;
  display: -webkit-inline-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  margin: 0 auto;
  line-height: 1.5;
}
</style>
