<template>
  <v-container class="advanced-content-card mt-2 pa-0" fluid grid-list-xs>
    <v-card class="pa-3 mb-2" v-if="ticketingTask">
      <TaskFirstRowInfo
        ref="taskFirstRowInfo"
        :ticketing-task="ticketingTask"
        :logged-in-user-id="loggedInUserId"
        :assigned-to-me="assignedToMe"
        :has-permission-for-task="hasPermissionForTask"
        :finished-loading="finishedLoading"
        @loadTaskDetails="loadTaskDetails"
      />
      <v-card-text v-if="taskDescription" class="pa-1 text-pre-line">
        {{ ticketingTask.description }}
      </v-card-text>
    </v-card>
    <TaskForm
      ref="taskForm"
      v-if="showTaskForm"
      :process-instance-id="processInstanceId"
      :task-id="ticketingTask.taskId"
      :ticketing-task="ticketingTask"
      :form-read-only="formReadOnly"
      :has-parent-task="hasParentTask"
      :additional-links="additionalLinks"
      @loadProcessReferences="loadProcessReferences"
      @closeDialog="closeDialog"
      @autocompleteLinksUpdated="onAutocompleteLinksUpdated"
      @reloadTasks="reloadTasks"
      @saveStarted="$emit('saveStarted')"
      @saveFinished="$emit('saveFinished')"
    />
    <CheckList
      ref="checkList"
      v-if="!hasParentTask && checklistEnabled"
      :ticketing-task="ticketingTask"
      :show-add-btn="!taskFinished"
      :finished-loading="finishedLoading"
      @loadTaskDetails="loadTaskDetails" />
    <v-card class="pa-3" v-if="processInstanceId">
      <TicketingHistory
        ref="ticketingHistory"
        :process-instance-id="processInstanceId"
        :task-id="taskFinished ? null : ticketingTask.taskId"
        :additional-links="additionalLinks"
        :enable-edit-comment="!taskIsFinished"
        @saveComment="saveComment"
        @loadProcessReferences="loadProcessReferences"
      />
    </v-card>
  </v-container>
</template>
<script>
import TaskFirstRowInfo from './TaskFirstRowInfo'
import TicketingHistory from '../shared/messages/TicketingHistory'
import TaskForm from './TaskForm'
import CheckList from './CheckList'
import { mapActions } from 'vuex'
import _isEmpty from 'lodash.isempty'

export default {
  name: 'TaskAdvancedInfo',
  components: {
    TaskFirstRowInfo,
    TicketingHistory,
    TaskForm,
    CheckList
  },
  props: {
    ticketingTask: {
      type: Object,
      default: () => {}
    },
    loggedInUserId: {
      type: String,
      default: ''
    },
    assignedToMe: {
      type: Boolean,
      default: false
    },
    checklistEnabled: {
      type: Boolean,
      default: true
    },
    hasPermissionForTask: {
      type: Boolean,
      default: false
    },
    additionalLinks: {
      type: Array,
      default: () => []
    },
    finishedLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    taskFinished () {
      return !_isEmpty(this.ticketingTask) && this.ticketingTask.finishedOn !== '' && this.ticketingTask.finishedOn !== null
    },
    hasParentTask () {
      return !_isEmpty(this.ticketingTask) && this.ticketingTask.parentTaskId !== '' && this.ticketingTask.parentTaskId !== null
    },
    hasAssignedUser () {
      return !_isEmpty(this.ticketingTask) && this.ticketingTask.assignee !== '' && this.ticketingTask.assignee !== null
    },
    formReadOnly () {
      return (!this.assignedToMe && this.hasAssignedUser) || this.taskFinished
    },
    showTaskForm () {
      return this.ticketingTask?.formKey ||
         (!_isEmpty(this.ticketingTask) && this.processInstanceId && !this.taskFinished && !this.hasParentTask)
    },
    processInstanceId () {
      return this.ticketingTask?.ticket?.ticketId
    },
    taskIsFinished () {
      return this.ticketingTask?.finishedOn !== null
    },
    taskDescription () {
      return this.ticketingTask?.description && this.ticketingTask.description !== '' && this.ticketingTask.description.replace(/\s/g, '').length
    }
  },
  methods: {
    ...mapActions('notifier', ['setSuccessSnackbar', 'setErrorSnackbar']),
    loadTaskDetails () {
      this.$emit('loadTaskDetails')
    },
    saveComment () {
      this.$refs.ticketingHistory.saveComment()
    },
    async saveTaskVariables (processReferencesLinks) {
      this.$refs.taskForm.saveTaskVariables(processReferencesLinks)
    },
    async completeTask (processReferencesLinks) {
      if (this.$refs.taskFirstRowInfo && !this.assignedToMe) {
        await this.$refs.taskFirstRowInfo.changedAssignee(true)
      }
      if (this.$refs.taskForm) {
        this.$refs.taskForm.completeTask(processReferencesLinks)
      }
    },
    loadProcessReferences () {
      this.$emit('loadProcessReferences')
    },
    closeDialog () {
      this.$emit('closeDialog')
    },
    onAutocompleteLinksUpdated (autocompleteLinks) {
      this.$emit('autocompleteLinksUpdated', autocompleteLinks)
    },
    reloadTasks () {
      this.$emit('reloadTasks')
    }
  }
}
</script>

<style scoped lang="postcss">
</style>
