export function openDashboardAddress (addressId, addressType, openInSame = false) {
  let url = '/dashboard/address/' + addressId
  if (addressType) {
    url += '/' + addressType
  }
  window.open(url, openInSame)
}

export function openDashboardProperty (propertyId, openInSame = false) {
  window.open('/dashboard/property/' + propertyId, openInSame)
}

export function openTicket (ticketId, openInSame = false) {
  window.open('/ticketing/processes/' + ticketId, openInSame)
}
