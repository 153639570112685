<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm12 md4 lg4 xl4>
        <v-card class="elevation-12">
          <v-form ref="form" class="create-form">
            <v-toolbar dark :color="appBarBackgroundColor" class="mb-4">
              <v-toolbar-title>
                <v-img height="70px" width="70px" class="mt-10" src="/Logo-Prestige.png"></v-img>
              </v-toolbar-title>
              <v-toolbar-title class="pl-4 font-weight-bold">{{ $t('renting.publicForm.title') }}</v-toolbar-title>
            </v-toolbar>
            <v-container class="px-4">
              <v-layout wrap>
                <v-flex xs12 sm12 md12 lg12 xl12>
                  <p>{{ successfullyProcessed ? $t('renting.interestedPersonDecision.successfulMessage') : $t('renting.interestedPersonDecision.requestCouldNotBeProcessedMessage')  }}</p>
                </v-flex>
              </v-layout>
            </v-container>
            <v-card-actions class="pl-4 pr-4 mt-6">
              <a href="https://www.prestige.ch/impressum/" target="_blank">{{ $t('renting.publicForm.imprint') }}</a>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { appBarBackgroundColor } from '@/config/options/appBar'
import { mapActions } from 'vuex'

export default {
  name: 'InterestedPersonDecision',
  data () {
    return {
      successfullyProcessed: false,
      appBarBackgroundColor: appBarBackgroundColor
    }
  },
  created () {
    this.setInterestedPersonDecision()
  },
  computed: {
    rentalFormId () {
      return this.$route.params.rentalFormId
    },
    rentalFormToken () {
      return this.$route.params.rentalFormToken
    },
    decision () {
      return this.$route.query.decision
    }
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    setInterestedPersonDecision () {
      let that = this
      if (this.rentalFormId && this.rentalFormToken && this.decision) {
        let requestBody = {
          decision: this.decision
        }
        this.$prestigeRenting.setInterestedPersonDecision(this.rentalFormId, this.rentalFormToken, requestBody).then(response => {
          if (response.status === 201) {
            this.successfullyProcessed = true
          } else if (response.status === 400 || response.status === 404) {
            this.successfullyProcessed = false
          }
          return true
        }).catch(error => {
          that.setErrorSnackbar(error)
        })
      }
    }
  }
}
</script>

<style scoped lang="postcss">
</style>
