<template>
  <v-flex class="mt-1 mb-3 mt-4" xs12>
    <v-card>
      <v-card-text>
        <v-layout row wrap class="pl-0">
          <v-flex sm6>
            <v-list class="pa-0">
              <v-list-item-content class="d-block">
                {{ $t('tasks.info.property') }}:
                [<a @click="onPropertyClicked(property.propertyId)">{{ property.propertyId }}</a>]
                {{ property.propertyStreet + ', ' + property.propertyZip + ' ' + property.propertyPlace }}
              </v-list-item-content>
              <v-list-item-content>{{ $t('tasks.info.house') }}: {{ houseInfo }}</v-list-item-content>
              <v-list-item-content>{{ $t('tasks.info.object') }}:  {{ houseObjectInfo }}</v-list-item-content>
            </v-list>
          </v-flex>
          <v-flex sm6>
            <v-list class="pa-0">
              <v-list-item-content>{{ $t('tasks.info.client') }}: {{ clientInfo }}</v-list-item-content>
              <v-list-item-content>{{ $t('tasks.info.caretaker') }}: {{ caretakerInfo }}</v-list-item-content>
              <v-list-item-content>{{ $t('tasks.info.administrativeArea') }}: {{ administrativeAreaInfo }}</v-list-item-content>
            </v-list>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-flex>
</template>

<script>
import { openDashboardProperty } from '@/helpers/routesHelper'

export default {
  name: 'PropertyInfo',
  props: {
    property: {
      type: Object,
      default: () => {}
    },
    propertyObject: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    propertyInfo () {
      return '[' + this.property.propertyId + '] ' + this.property.propertyStreet + ', ' + this.property.propertyZip + ' ' + this.property.propertyPlace
    },
    houseInfo () {
      return this.propertyObject ? '[' + (this.propertyObject.houseId || '') + '] ' + (this.propertyObject.houseStreet || '') : ''
    },
    houseObjectInfo () {
      return this.propertyObject ? '[' + (this.propertyObject.objectId || '') + '] ' + (this.propertyObject.objectText || '') : ''
    },
    clientAddress () {
      return '' + (this.property.client.line1 ? this.property.client.line1 + ', ' : '') + (this.property.client.line2 ? this.property.client.line2 + ', ' : '') + (this.property.client.line3 ? this.property.client.line3 + ', ' : '') + this.property.client.zip + ' ' + this.property.client.place
    },
    clientName () {
      return '' + this.property.client.firstName + ' ' + (this.property.client.lastName ? this.property.client.lastName + ', ' : '')
    },
    clientInfo () {
      return this.property.client ? '[' + this.property.client.id + '] ' + this.clientName + this.clientAddress : '[?]'
    },
    caretakerInfo () {
      return this.property.caretaker ? '[' + this.property.caretaker.id + '] ' + this.property.caretaker.firstName + ' ' + this.property.caretaker.lastName : ''
    },
    administrativeAreaInfo () {
      return '[' + (this.property.administrativeAreaId || '') + '] ' + (this.property.administrativeAreaText || '')
    }
  },
  methods: {
    onPropertyClicked (propertyId) {
      openDashboardProperty(propertyId)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.layout {
  padding: 0 10px 0 10px;
}
.v-list-item__content {
  padding: 0;
}
</style>
