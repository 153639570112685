<template>
  <v-overlay :value="loading">
    <div class="spinner">
      <clip-loader :loading="loading" :color="color" :size="size"></clip-loader>
    </div>
  </v-overlay>
</template>

<script>
import ClipLoader from 'vue-spinner/src/ClipLoader'

export default {
  name: 'SpinnerOverlay',
  components: {
    ClipLoader
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      color: '#1976d2',
      size: '100px'
    }
  }
}
</script>
