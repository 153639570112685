<template>
  <v-dialog v-model="showDeleteDialog" ref="confirmationDialog" max-width="500px" @keydown.esc="closeDialog" persistent>
    <v-card>
      <v-card-title>
        {{ $t('ticketingSystem.deleteProcess.title') }}
        <v-btn icon absolute right>
          <v-icon class="clear" color="primary" :size="28" @click.native="closeDialog"/>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-textarea
            v-model="deleteReason"
            required
            :rules="[rules.required]"
            :label="$t('ticketingSystem.deleteProcess.reason')"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="closeDialog">{{ $t('common.buttons.cancel') }}</v-btn>
        <v-btn color="primary" @click.native="deleteProcess">{{ this.$t('common.buttons.delete') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'ConfirmationDialog',
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    processInstanceId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      deleteReason: '',
      rules: {
        required: (value) => (!!value && value !== undefined) || this.requiredMessage
      }
    }
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar','setSuccessSnackbar']),
    closeDialog () {
      this.$emit('closeDialog')
    },
    submitDialog () {
      this.$emit('submitDialog')
    },
    deleteProcess () {
      if (this.$refs.form.validate()) {
        let that = this
        const deleteReason = {
          deleteReason: this.deleteReason
        }
        this.$prestigeTSProcess.deleteProcess(this.processInstanceId, deleteReason).then(() => {
          that.setSuccessSnackbar(that.$t('common.messages.successfullyDeleted'))
          that.closeDialog()
          setTimeout(() => {
            window.location.reload()
          }, 100)          
        }).catch(error => {
          that.setErrorSnackbar(error)
        })
      }
    }
  },
  computed: {
    requiredMessage () {
      return this.$t('common.errors.required')
    },
    showDeleteDialog () {
      return this.showDialog
    }
  }
}
</script>
