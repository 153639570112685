export function initForm () {
  return {
    residents: [
      {
        lastName: 'Muster',
        firstName: 'Fritz',
        dateOfBirth: '1980-04-01',
        hometown: 'Zürich',
        mobile: '079 123 45 67',
        phone1: '',
        phone2: '',
        email: 'fritz@muster.ch',
        profession: 'Tester',
        income: '6000',
        employmentStatus: 'EMPLOYED',
        employer: 'Prestige Immobilien AG',
        employerName: 'Marco Heusser',
        employerFunction: 'Geschäftsführer',
        employerPhone: '044 316 15 15',
        employerEmail: 'info@prestige.ch',
        employmentDate: '2020-01-01',
        landlordName: 'Prestige, Berinastrasse 29, Zürich',
        landlordFunction: 'Verwaltung',
        landlordPhone: '044 316 15 15',
        landlordEmail: 'info@prestige.ch',
        creditRatingComment: 'Rechnungen werden bei mir immer pünktlich bezahlt',
        debtEnforcement: '',
        receivedDebtEnforcement: false,
        foreigner: false,
        country: 'CH',
        settlementPermit: null,
        foreignerResidencePermitType: null,
        currentApartmentStatus: 'RENTAL_APARTMENT',
        currentApartmentSameAsFirstResident: false,
        currentApartmentStreet: 'Mustergasse',
        currentApartmentStreetNumber: '11',
        currentApartmentZip: '8005',
        currentApartmentPlace: 'Zürich',
        currentApartmentNumberOfRooms: 4,
        currentApartmentRent: 2300,
        currentApartmentStartRentDate: '2005-01-01',
        orderCertificate: false,
        validateCertificate: false,
        debtEnforcementPerMail: false
      }
    ],
    newApartment: {
      apartmentConditionRate: '7',
      reasonForChange: 'Jobwechsel',
      numberOfAdults: 1,
      numberOfChildren: 0,
      hasPets: true,
      pet: 'Hund',
      dogBreed: 'Dalmatiner',
      hasMusicalInstruments: false,
      musicalInstrument: '',
      takeSubtenantsLater: false,
      renovationRequirement: 'Badezimmer sollte renoviert werden.',
      conclusionOfContract: true,
      additionalGarage: true,
      additionalParkingSpot: true,
      additionalMotorcycleParkingSpot: false
    },
    confirmation: true
  }
}
