var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex"},[_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:{ 'is-active': _vm.editor.isActive('bold') },attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();_vm.editor.chain().focus().toggleBold().run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.editor.isActive('bold') ? '#ffffff' : ''}},[_vm._v("mdi-format-bold")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('tipTap.bold')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:{ 'is-active': _vm.editor.isActive('italic') },attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();_vm.editor.chain().focus().toggleItalic().run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.editor.isActive('italic') ? '#ffffff' : ''}},[_vm._v("mdi-format-italic")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('tipTap.italic')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:{ 'is-active': _vm.editor.isActive('underline') },attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();_vm.editor.chain().focus().toggleUnderline().run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.editor.isActive('underline') ? '#ffffff' : ''}},[_vm._v("mdi-format-underline")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('tipTap.underline')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"strike",class:{ 'is-active': _vm.editor.isActive('strike') },attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();_vm.editor.chain().focus().toggleStrike().run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.editor.isActive('strike') ? '#ffffff' : ''}},[_vm._v("mdi-format-strikethrough-variant")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('tipTap.strike')))])])],1),_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":""}}),_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:{ 'is-active': _vm.editor.isActive('bulletList') },attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();_vm.editor.chain().focus().toggleBulletList().run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.editor.isActive('bulletList') ? '#ffffff' : ''}},[_vm._v("mdi-format-list-bulleted")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('tipTap.bulletList')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:{ 'is-active': _vm.editor.isActive('orderedList') },attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();_vm.editor.chain().focus().toggleOrderedList().run()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.editor.isActive('orderedList') ? '#ffffff' : ''}},[_vm._v("mdi-format-list-numbered")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('tipTap.numberedList')))])])],1),_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":""}}),_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":!_vm.editor.can().undo()},on:{"click":function($event){$event.preventDefault();_vm.editor.chain().focus().undo().run()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-undo-variant")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('tipTap.undo')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":!_vm.editor.can().redo()},on:{"click":function($event){$event.preventDefault();_vm.editor.chain().focus().redo().run()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-redo-variant")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('tipTap.redo')))])])],1),_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":""}}),_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:{ 'is-active': _vm.editor.isActive('link') },attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return _vm.setLink.apply(null, arguments)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.editor.isActive('link') ? '#ffffff' : ''}},[_vm._v("mdi-link-plus")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('tipTap.link')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":!_vm.editor.isActive('link')},on:{"click":function($event){$event.preventDefault();_vm.editor.chain().focus().unsetLink().run()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-link-off")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('tipTap.linkOff')))])])],1),_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":""}}),_c('div',[_c('v-btn',{staticClass:"mr-2",class:{ 'is-active': _vm.editor.isActive('textStyle', { color: '#76226c' }) },attrs:{"x-small":"","min-width":"25px","color":"#76226c","dark":""},on:{"click":function($event){_vm.editor.chain().focus().setColor('#76226c').run()}}}),_c('v-btn',{staticClass:"mr-2",class:{ 'is-active': _vm.editor.isActive('textStyle', { color: '#c82613' }) },attrs:{"x-small":"","min-width":"25px","color":"#c82613","dark":""},on:{"click":function($event){_vm.editor.chain().focus().setColor('#c82613').run()}}}),_c('v-btn',{staticClass:"mr-2",class:{ 'is-active': _vm.editor.isActive('textStyle', { color: '#0c882a ' }) },attrs:{"x-small":"","min-width":"25px","color":"#0c882a","dark":""},on:{"click":function($event){_vm.editor.chain().focus().setColor('#0c882a ').run()}}}),_c('v-btn',{class:{ 'is-active': _vm.editor.isActive('textStyle', { color: '#0c64c0' }) },attrs:{"x-small":"","min-width":"25px","color":"#0c64c0","dark":""},on:{"click":function($event){_vm.editor.chain().focus().setColor('#0c64c0').run()}}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.editor.chain().focus().unsetColor().run()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-water-off")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('tipTap.clearColor')))])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }