import _isEmpty from 'lodash.isempty'

export function getBuildingProjectAddress (buildingProject) {
  let address = ''
  if (!_isEmpty(buildingProject)) {
    address = addressLine(buildingProject.addressLine1) +
    addressLine(buildingProject.addressLine2) +
    addressLine(buildingProject.addressLine3) +
    buildingProject.place + ' ' + buildingProject.zip
  }
  return address
}

export function addressLine (line) {
  return line ? line + ', ' : ''
}
