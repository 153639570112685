import { render, staticRenderFns } from "./TerminationConfirmation.vue?vue&type=template&id=9200d42c&scoped=true&"
import script from "./TerminationConfirmation.vue?vue&type=script&lang=js&"
export * from "./TerminationConfirmation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9200d42c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VAlert,VBtn,VSimpleTable})
