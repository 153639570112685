import gql from 'graphql-tag'

const TASK_TICKETING_TICKET = gql`
  query ticketingTicket ($ticketId: ID!) {
    ticketingTicket(ticketId: $ticketId) {
      ticketId
      state
      processDefinitionName
      subject
    }
  }
`

export { TASK_TICKETING_TICKET }
