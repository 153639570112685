
import gql from 'graphql-tag'

const ABACUS_ADDRESS = gql`
  fragment AddressFields on AbacusAddress {
    line1
    line2
    line3
    country
    zip
    place
  }
  query abacusAddress($addressId: Int!) {
    abacusAddress(addressId: $addressId) {
      addressId
      firstName
      lastName
      ...AddressFields
    }
  }
`
const ABACUS_ADDRESSES_REINDEX = gql`
  mutation abacusAddressReindex {
    abacusAddressReindex
  }
`

const ABACUS_PROPERTY = gql`
  query abacusProperty ($propertyId: Int!) {
    abacusProperty(propertyId: $propertyId) {
      propertyPartners {
        propertyId
        validFrom
        validUntil
        partner {
          addressId
          firstName
          lastName
          line1
          line3
          line2
          zip
          place
        }
      }
    }
  }
`
const ABACUS_IMPORTER_LOGS = gql`
  query abacusImporterLogs ($size: Int, $page: Int ) {
    abacusImporterLogs(
      size: $size,
      page: $page
    ){
      items {
        id,
        job,
        status,
        startedOn,
        finishedOn,
        recordsInserted,
        recordsUpdated,
        recordsDeleted,
        errorMessage
      }
      totalItems
    }
  }
`
export { ABACUS_ADDRESS, ABACUS_ADDRESSES_REINDEX, ABACUS_PROPERTY, ABACUS_IMPORTER_LOGS }
