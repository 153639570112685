var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.parent)?_c('v-card',{staticClass:"mb-2 pa-0"},[_c('v-card-text',{staticClass:"pa-4"},[_c('v-icon',{staticClass:"float-right",attrs:{"color":"primary"},on:{"click":_vm.toggle}},[_vm._v(_vm._s(_vm.icon))]),_c('v-card-subtitle',{staticClass:"pa-0 font-weight-bold subtitle-1"},[_vm._v(" "+_vm._s(_vm.$t('ticketingSystem.taskDetails.processReferences.parent'))+" ")])],1),_c('v-divider'),(_vm.isOpen)?_c('v-layout',{staticClass:"pa-0 ma-0",attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":""}},[(_vm.parent)?_c('v-card-text',[(_vm.parent.processDefinitionName)?_c('p',{staticClass:"pa-0 ma-0 truncate-line two-line-clamp"},[_c('StateIcon',{attrs:{"state":_vm.parent.state}}),_vm._v(" ["),_c('router-link',{staticClass:"process-instance-id truncate-line one-line-clamp",attrs:{"to":{ name: _vm.TICKETING_PROCESS_DETAILS, params: { processInstanceId: _vm.parent.processInstanceId }}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"ma-0 truncate-line one-line-clamp process-instance-id"},'span',attrs,false),on),[_vm._v(_vm._s(_vm.parent.processInstanceId))])]}}],null,false,2801221577)},[_c('span',[_vm._v(_vm._s(_vm.$t('ticketingSystem.processes.processDetails')))])])],1),_vm._v("] "),_c('span',[_vm._v(" "+_vm._s(_vm.parent.processDefinitionName)+" "),(_vm.parent.processSubject)?_c('span',[_vm._v(" - "+_vm._s(_vm.parent.processSubject))]):_vm._e()])],1):_vm._e(),(_vm.parent.startedOn)?_c('p',{staticClass:"pt-1 ma-0"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"size":22}},'v-icon',attrs,false),on),[_vm._v("mdi-calendar-start")]),_c('span',[_vm._v(_vm._s(_vm.formatDateAndTimeFunc(_vm.parent.startedOn)))])]}}],null,false,952375643)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('ticketingSystem.taskDetails.processReferences.startedOn'))+" ")])])],1):_vm._e(),(_vm.parent.finishedOn)?_c('p',{staticClass:"pt-1 ma-0"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"size":22}},'v-icon',attrs,false),on),[_vm._v("mdi-calendar-end")]),_c('span',[_vm._v(_vm._s(_vm.formatDateAndTimeFunc(_vm.parent.finishedOn)))])]}}],null,false,433487431)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('ticketingSystem.taskDetails.processReferences.finishedOn'))+" ")])])],1):_vm._e()]):_vm._e()],1)],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }