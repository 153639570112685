<template>
  <v-card>
    <v-card-title>
      {{ $t('ticketingSystem.charts.openCompletedTasksTitle') }}
    </v-card-title>
    <v-card-text v-if="hasProcessDefinitionKeysChecked">
      <v-progress-linear v-if="$apollo.loading" color="blue" indeterminate></v-progress-linear>
      <v-container fluid v-else-if="searchTicketingOpenClosedTasksStatistics">
        <v-row>
          <v-col
            v-for="(chart, index) in charts"
            :key="'chart-' + index"
            md="12"
            :xl="xlCols"
          >
            <LineChart
              :chart-options="chart.options"
              :chart-data="chart.data"
              :height="400"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import LineChart from './../../../components/charts/LineChart'
import { getCandidateGroupName } from '@/helpers/candidateGroupsHelper'
import { getIntervalLabels } from '@/helpers/momentHelper'
import { SEARCH_TICKETING_OPEN_CLOSED_TASKS } from '@/features/ticketing-statistics/graphql-apollo/searchTicketing.gql'

export default {
  name: 'OpenCompletedTasks',
  components: {
    LineChart
  },
  props: {
    filters: {
      type: Object,
      default: () => {}
    }
  },
  apollo: {
    searchTicketingOpenClosedTasksStatistics: {
      query: SEARCH_TICKETING_OPEN_CLOSED_TASKS,
      variables () {
        return {
          from: this.filters.from,
          to: this.filters.to,
          interval: this.filters.interval.toUpperCase(),
          processDefinitionKeys: this.filters?.processDefinitionKeys
        }
      },
      skip () {
        return this.filters === null || this.filters?.processDefinitionKeys?.length === 0
      }
    }
  },
  computed: {
    charts () {
      // TODO: there is probably an easier way :D
      const maxCount = Math.max(...this.searchTicketingOpenClosedTasksStatistics.flatMap(x => [Math.max(x.tasksOpened.reduce((partialSum, a) => partialSum + a.count, 0), x.tasksCompleted.reduce((partialSum, a) => partialSum + a.count, 0))]))

      return this.searchTicketingOpenClosedTasksStatistics
        .filter(candidateGroup =>
          candidateGroup.candidateGroup === '' ||
          candidateGroup.candidateGroup === 'prestige-tools-ticketing-innendienst' ||
          candidateGroup.candidateGroup === 'prestige-tools-ticketing-bewirtschaftung' ||
          candidateGroup.candidateGroup === 'prestige-tools-ticketing-buchhaltung'
        )
        .map(candidateGroup => {
          let tasksOpenedSum = 0, tasksCompletedSum = 0
          return {
            options: {
              responsive: true,
              maintainAspectRatio: false,
              interaction: {
                mode: 'index',
                intersect: false
              },
              layout: {
                padding: {
                  right: 15
                },
              },
              plugins: {
                title: {
                  display: true,
                  text: getCandidateGroupName(candidateGroup.candidateGroup)
                },
                datalabels: {
                  backgroundColor: function(context) {
                    return context.dataset.backgroundColor
                  },
                  formatter: function(value, context) {
                    if (context.dataIndex === 0) {
                      return value
                    } else {
                      const inc = context.dataset.data[context.dataIndex] - context.dataset.data[context.dataIndex - 1]
                      return '+' + inc
                    }
                  },
                  borderRadius: 4,
                  color: 'white',
                  padding: 3
                }
              },
              scales: {
                y: {
                  suggestedMin: 0,
                  suggestedMax: maxCount
                }
              }
            },
            data: {
              labels: this.getLabels(candidateGroup.tasksOpened),
              datasets: [
                {
                  label: this.$t('ticketingSystem.charts.createdLabel'),
                  data: candidateGroup.tasksOpened.map(x => tasksOpenedSum = (tasksOpenedSum || 0) + x.count),
                  backgroundColor: '#E65100',
                  borderColor: '#FFA726'
                },
                {
                  label: this.$t('ticketingSystem.charts.completedLabel'),
                  data: candidateGroup.tasksCompleted.map(x => tasksCompletedSum = (tasksCompletedSum || 0) + x.count),
                  backgroundColor: '#1B5E20',
                  borderColor: '#66BB6A'
                }
              ]
            }
          }
        })
    },
    hasProcessDefinitionKeysChecked () {
      return this.filters && this.filters.processDefinitionKeys.length > 0
    },
    xlCols () {
      const maxDatasets = Math.max(...this.searchTicketingOpenClosedTasksStatistics.flatMap(x => [Math.max(x.tasksOpened.length, x.tasksCompleted.length)]))
      if (maxDatasets > 10) {
        return 12
      } else {
        return 6
      }
    }
  },
  methods: {
    getLabels (data) {
      return getIntervalLabels(data, this.filters?.interval.toUpperCase())
    }
  }
}
</script>
