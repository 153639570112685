import RentingList from '../RentingList'
import InterestedPersonCreate from '../InterestedPersonCreate'
import InterestedPersonDetails from '../InterestedPersonDetails'
import PublicRentalForm from '../PublicRentalForm'
import PublicRentalFormEntry from '../PublicRentalFormEntry'
import InterestedPersonDecision from '../InterestedPersonDecision'
import LandlordReferenceForm from '../LandlordReferenceForm'
import EmployerReferenceForm from '../EmployerReferenceForm'

export const RentingRoutes = [
  {
    path: '/renting/interested-persons/list',
    name: 'RentingList',
    component: RentingList,
    meta: {
      title: 'rentingList',
      requireAuth: true,
      requireRoles: [
        'prestige-tools-reader'
      ]
    }
  },
  {
    path: '/abacus/renting/interested-persons/list/:propertyId?/:propertyObjectId?',
    name: 'AbacusRentingList',
    component: RentingList,
    meta: {
      abacus: true,
      full: true,
      title: 'rentingList',
      requireAuth: true,
      requireRoles: [
        'prestige-tools-reader'
      ],
      newTab: true
    }
  },
  {
    path: '/renting/interested-persons/create/:propertyId?/:propertyObjectId?',
    name: 'InterestedPersonCreate',
    component: InterestedPersonCreate,
    meta: {
      title: 'interestedPersonCreate',
      requireAuth: true,
      requireRoles: [
        'prestige-tools-reader'
      ]
    }
  },
  {
    path: '/abacus/renting/interested-persons/create/:propertyId?/:propertyObjectId?',
    name: 'AbacusInterestedPersonCreate',
    component: InterestedPersonCreate,
    meta: {
      abacus: true,
      full: true,
      title: 'interestedPersonCreate',
      requireAuth: true,
      requireRoles: [
        'prestige-tools-reader'
      ]
    }
  },
  {
    path: '/renting/interested-persons/:interestedPersonId',
    name: 'InterestedPersonDetails',
    component: InterestedPersonDetails,
    meta: {
      title: 'interestedPersonDetails',
      requireAuth: true,
      requireRoles: [
        'prestige-tools-reader'
      ]
    }
  },
  {
    path: '/abacus/renting/interested-persons/:interestedPersonId',
    name: 'AbacusInterestedPersonDetails',
    component: InterestedPersonDetails,
    meta: {
      abacus: true,
      full: true,
      title: 'interestedPersonDetails',
      requireAuth: true,
      requireRoles: [
        'prestige-tools-reader'
      ]
    }
  },
  {
    path: '/renting/form/:token',
    name: 'PublicRentalForm',
    component: PublicRentalForm,
    meta: {
      full: true,
      title: 'interestedPersonPublicForm',
      requireAuth: false
    }
  },
  {
    path: '/renting/form',
    name: 'PublicRentalFormEntry',
    component: PublicRentalFormEntry,
    meta: {
      full: true,
      title: 'interestedPersonPublicForm',
      requireAuth: false
    }
  },
  {
    path: '/renting/form/:rentalFormId/landlord/:landlordToken',
    name: 'LandlordReferenceForm',
    component: LandlordReferenceForm,
    meta: {
      full: true,
      title: 'landlordReferenceForm',
      requireAuth: false
    }
  },
  {
    path: '/renting/form/:rentalFormId/employer/:employerToken',
    name: 'EmployerReferenceForm',
    component: EmployerReferenceForm,
    meta: {
      full: true,
      title: 'employerReferenceForm',
      requireAuth: false
    }
  },
  {
    path: '/renting/form/:rentalFormId/application/:rentalFormToken',
    name: 'InterestedPersonDecision',
    component: InterestedPersonDecision,
    meta: {
      full: true,
      title: 'interestedPersonDecision',
      requireAuth: false
    }
  }
]
