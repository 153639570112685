<template>
  <v-menu offset-y class="shrink">
    <template v-slot:activator="{ on }">
      <a v-on="on">
        <span v-if="date && !show">
          {{ formatDateAndTimeFunc(date) }}
          <v-icon class="delete_forever" color="red" :size="22" @click.stop="$emit('updateTaskDueDate', null, true)" />
        </span>
        <span v-if="!date && !show">
          {{ $t('ticketingSystem.taskDetails.setDueDate') }}
        </span>
      </a>
    </template>
    <v-list>
      <v-list-item v-for="(actionItem, index) in actions" :key="index" @click="actionItem.action($emit)">
        <v-list-item-title>{{ actionItem.title }}</v-list-item-title>
        <v-list-item-icon>
          <v-icon color="primary" :size="20">{{ actionItem.icon }}</v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { formatDateAndTime } from '@/helpers/formatterHelper'
import moment from 'moment-business-days'

const DATE_AND_TIME_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss'

export default {
  name: 'DueDateMenu',
  props: {
    date: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    actions () {
      return [
        { title: this.$t('common.date.today'), icon: 'mdi-calendar-today', action: this.onToday },
        { title: this.$t('common.date.tomorrow'), icon: 'mdi-calendar', action: this.onTomorrow },
        { title: this.$t('common.date.dayAfterTomorrow'), icon: 'mdi-calendar-multiple', action: this.onDayAfterTomorrow },
        { title: this.$t('common.date.customDate'), icon: 'mdi-calendar-text', action: this.onCustomDate }
      ]
    }
  },
  methods: {
    formatDateAndTimeFunc: formatDateAndTime,
    onToday () {
      let today = moment().set({ 'hour': 17, 'minute': 0, 'second': 0 }).format(DATE_AND_TIME_FORMAT)
      this.emitUpdateTaskDueDate(today)
    },
    onTomorrow () {
      this.emitUpdateTaskDueDate(this.addBusinessDays(1))
    },
    onDayAfterTomorrow () {
      this.emitUpdateTaskDueDate(this.addBusinessDays(2))
    },
    onCustomDate () {
      this.$emit('customDate')
    },
    emitUpdateTaskDueDate (date, resetDueDate = false) {
      this.$emit('updateTaskDueDate', date, resetDueDate)
    },
    addBusinessDays (numberOfDays) {
      return moment().businessAdd(numberOfDays).format(DATE_AND_TIME_FORMAT)
    }
  }
}
</script>

<style scoped lang="postcss">
</style>
