<template>
  <v-slide-y-transition mode="out-in">
    <v-card class="pl-2 pr-2 pb-2 mt-3 grey lighten-5">
      <v-card-title class="pl-1 pb-0">
        <v-icon class="pr-2">mdi-database-import</v-icon>{{ $t('navigation.abacusAdapter') }}
      </v-card-title>
      <v-layout wrap class="row pa-2">
        <v-flex class="pl-2 pr-1 mt-2">
            <v-card height="100%">
              <v-container fluid grid-list-xs>
                <div class="inline float-left mb-2">
                  <v-btn
                    class="primary ma-2"
                    :loading="addressesLoading"
                    :disabled="addressesLoading"
                    @click="triggerImportReport(reportNames.ADDRESS_REPORT, 'addressesLoading')"
                  >
                    <v-icon class="pr-2">mdi-database-plus</v-icon> {{ $t('dataImport.abacus.addresses') }}
                  </v-btn>
                </div>
                <div class="inline float-left mb-2">
                  <v-btn
                    class="primary ma-2"
                    :loading="propertiesLoading"
                    :disabled="propertiesLoading"
                    @click="triggerImportReport(reportNames.PROPERTY_REPORT, 'propertiesLoading')"
                  >
                    <v-icon class="pr-2">mdi-database-plus</v-icon> {{ $t('dataImport.abacus.properties') }}
                  </v-btn>
                </div>
                <div class="inline float-left mb-2">
                  <v-btn
                    class="primary ma-2"
                    :loading="blockedCraftsmenLoading"
                    :disabled="blockedCraftsmenLoading"
                    @click="triggerImportReport(reportNames.BLOCKED_CRAFTSMEN_REPORT, 'blockedCraftsmenLoading')"
                  >
                    <v-icon class="pr-2">mdi-database-plus</v-icon> {{ $t('dataImport.abacus.blockedCraftsmen') }}
                  </v-btn>
                </div>
                <div class="inline float-left mb-2">
                  <v-btn
                    class="primary ma-2"
                    :loading="priorityCraftsmenLoading"
                    :disabled="priorityCraftsmenLoading"
                    @click="triggerImportReport(reportNames.PRIORITY_CRAFTSMEN_REPORT, 'priorityCraftsmenLoading')"
                  >
                    <v-icon class="pr-2">mdi-database-plus</v-icon> {{ $t('dataImport.abacus.priorityCraftsmen') }}
                  </v-btn>
                </div>
                <div class="inline float-left mb-2">
                  <v-btn
                    class="primary ma-2"
                    :loading="tanksLoading"
                    :disabled="tanksLoading"
                    @click="triggerImportReport(reportNames.HEATING_OIL_TANK_REPORT, 'tanksLoading')"
                  >
                    <v-icon class="pr-2">mdi-database-plus</v-icon> {{ $t('dataImport.abacus.tanks') }}
                  </v-btn>
                </div>
                <div class="inline float-left mb-2">
                  <v-btn
                    class="primary ma-2"
                    :loading="rentalContractPartnerLoading"
                    :disabled="rentalContractPartnerLoading"
                    @click="triggerImportReport(reportNames.RENTAL_CONTRACT_PARTNERS_REPORT, 'rentalContractPartnerLoading')"
                  >
                    <v-icon class="pr-2">mdi-database-plus</v-icon> {{ $t('dataImport.abacus.rentalContractPartner') }}
                  </v-btn>
                </div>
                <div class="inline float-left mb-2">
                  <v-btn
                    class="primary ma-2"
                    :loading="serviceChargeStatementLoading"
                    :disabled="serviceChargeStatementLoading"
                    @click="triggerImportReport(reportNames.SERVICE_CHARGE_STATEMENT_REPORT, 'serviceChargeStatementLoading')"
                  >
                    <v-icon class="pr-2">mdi-database-plus</v-icon> {{ $t('dataImport.abacus.serviceChargeStatements') }}
                  </v-btn>
                </div>
                <div class="inline float-left mb-2">
                  <v-btn
                    class="primary ma-2"
                    :loading="rentComponentsLoading"
                    :disabled="rentComponentsLoading"
                    @click="triggerImportReport(reportNames.RENT_COMPONENTS_REPORT, 'rentComponentsLoading')"
                  >
                    <v-icon class="pr-2">mdi-database-plus</v-icon> {{ $t('dataImport.abacus.rentComponents') }}
                  </v-btn>
                </div>
                <div class="inline float-left mb-2">
                  <v-btn
                    class="primary ma-2"
                    :loading="rentalContractsLoading"
                    :disabled="rentalContractsLoading"
                    @click="triggerImportReport(reportNames.RENTAL_CONTRACTS_REPORT, 'rentalContractsLoading')"
                  >
                    <v-icon class="pr-2">mdi-database-plus</v-icon> {{ $t('dataImport.abacus.rentalContracts') }}
                  </v-btn>
                </div>
                <div class="inline float-left mb-2">
                  <v-btn
                    class="primary ma-2"
                    :loading="rentalContractsSubletLoading"
                    :disabled="rentalContractsSubletLoading"
                    @click="triggerImportReport(reportNames.RENTAL_CONTRACT_SUBLET_REPORT, 'rentalContractsSubletLoading')"
                  >
                    <v-icon class="pr-2">mdi-database-plus</v-icon> {{ $t('dataImport.abacus.sublets') }}
                  </v-btn>
                </div>
                <div class="inline float-left mb-2">
                  <v-btn
                    class="primary ma-2"
                    :loading="propertyPartnersLoading"
                    :disabled="propertyPartnersLoading"
                    @click="triggerImportReport(reportNames.PROPERTY_PARTNER_REPORT, 'propertyPartnersLoading')"
                  >
                    <v-icon class="pr-2">mdi-database-plus</v-icon> {{ $t('dataImport.abacus.partner') }}
                  </v-btn>
                </div>
                <div class="inline float-left mb-2">
                  <v-btn
                    class="primary ma-2"
                    :loading="propertyClientsLoading"
                    :disabled="propertyClientsLoading"
                    @click="triggerImportReport(reportNames.PROPERTY_CLIENT_REPORT, 'propertyClientsLoading')"
                  >
                    <v-icon class="pr-2">mdi-database-plus</v-icon> {{ $t('dataImport.abacus.clients') }}
                  </v-btn>
                </div>
              </v-container>
            </v-card>
        </v-flex>
        <v-flex class="pl-2 pr-1 mt-2">
          <AbacusImporterLogsTable/>
        </v-flex>
      </v-layout>
    </v-card>
  </v-slide-y-transition>
</template>

<script>
import { mapActions } from 'vuex'
import AbacusImporterLogsTable from './AbacusImporterLogsTable.vue'
import { REPORT_NAMES } from '@/config/options/reportNames'

export default {
  name: 'AbacusImport',
  data () {
    return {
      reportNames: REPORT_NAMES,
      addressesLoading: false,
      propertiesLoading: false,
      blockedCraftsmenLoading: false,
      priorityCraftsmenLoading: false,
      tanksLoading: false,
      rentalContractPartnerLoading: false,
      rentalContractsLoading: false,
      rentalContractsSubletLoading: false,
      propertyPartnersLoading: false,
      propertyClientsLoading: false,
      serviceChargeStatementLoading: false,
      rentComponentsLoading: false
    }
  },
  components: {
    AbacusImporterLogsTable
  },
  methods: {
    ...mapActions('notifier', ['setSuccessSnackbar', 'setErrorSnackbar']),
    triggerImportReport (reportName, loading) {
      let that = this
      that[loading] = true
      this.$abacusImporter.importReport(reportName).then(response => {
        if (that.responseSuccess(response.status)) {
          that[loading] = false
          that.triggerSuccessMessage()
        }
      }).catch(error => {
        that[loading] = false
        that.setErrorSnackbar(error)
      })
    },
    triggerSuccessMessage () {
      return this.setSuccessSnackbar(this.$t('dataImport.success'))
    },
    responseSuccess (status) {
      return status === 200 || status === 204
    }
  }
}
</script>

<style scoped>
</style>
