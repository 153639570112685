<template>
  <v-card class="mb-2">
    <v-card-text v-if="propertyId">
      <v-icon color="primary" @click="toggle" class="float-right">{{ icon }}</v-icon>
      <v-card-title class="pa-0">{{ columnTitle }}</v-card-title>
    </v-card-text>
    <v-divider></v-divider>
    <div v-if="isOpen" class="pa-2 ml-2 mb-2">
      <v-layout row wrap v-if="isOpen" class="mt-1 mb-1">
        <v-flex xs12 sm12 md4 lg4 xl4 v-for="(item, index) in propertyItems" :key="index">
          <v-card-title class="pa-0 mb-1">{{ $t('tasks.info.' + item.name) }}</v-card-title>
          <template v-if="item.obj">
            <v-card-text class="pa-0" v-if="item.obj.firstName || item.obj.lastName">
              {{ item.obj.firstName + ' ' + item.obj.lastName }}
            </v-card-text>
            <v-card-text class="pa-0" v-if="item.obj.line1">{{ item.obj.line1 }}</v-card-text>
            <v-card-text class="pa-0" v-if="item.obj.line2">{{ item.obj.line2 }}</v-card-text>
            <v-card-text class="pa-0" v-if="item.obj.line3">{{ item.obj.line3 }}</v-card-text>
            <v-card-text class="pa-0" v-if="item.obj.zip || item.obj.place">
              {{ item.obj.zip + ' ' + item.obj.place }}
            </v-card-text>
            <v-card-text class="pa-0" v-if="item.obj.phone">{{ item.obj.phone }}</v-card-text>
            <v-card-text class="pa-0" v-if="item.obj.mobile">{{ item.obj.mobile }}</v-card-text>
            <v-card-text class="pa-0" v-if="item.obj.email">{{ item.obj.email }}</v-card-text>
          </template>
        </v-flex>
      </v-layout>
    </div>
  </v-card>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  name: 'PropertyOverview',
  props: {
    propertyId: {
      type: String,
      default: ''
    },
    columnTitle: {
      type: String,
      default: ''
    },
    propertyItems: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      isOpen: true
    }
  },
  computed: {
    icon () {
      return this.isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
    }
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    toggle () {
      this.isOpen = !this.isOpen
    }
  }
}
</script>
<style scoped>
</style>
