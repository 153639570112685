
function initNewApartment () {
  return {
    apartmentConditionRate: '',
    reasonForChange: null,
    numberOfAdults: 1,
    numberOfChildren: 0,
    hasPets: null,
    pet: '',
    dogBreed: '',
    hasMusicalInstruments: null,
    musicalInstrument: '',
    takeSubtenantsLater: null,
    renovationRequirement: '',
    conclusionOfContract: null,
    additionalGarage: null,
    additionalParkingSpot: null,
    additionalMotorcycleParkingSpot: null
  }
}

function initPersonForm () {
  return {
    lastName: '',
    firstName: '',
    dateOfBirth: '',
    hometown: '',
    mobile: '',
    phone1: '',
    phone2: '',
    email: '',
    profession: '',
    income: '',
    employmentStatus: '',
    employer: '',
    employerName: '',
    employerFunction: '',
    employerPhone: '',
    employerEmail: '',
    employmentDate: '',
    landlordName: '',
    landlordFunction: '',
    landlordPhone: '',
    landlordEmail: '',
    creditRatingComment: '',
    debtEnforcement: '',
    foreigner: null,
    country: 'CH',
    settlementPermit: null,
    foreignerResidencePermitType: null,
    currentApartmentStatus: null,
    currentApartmentSameAsFirstResident: false,
    currentApartmentStreet: '',
    currentApartmentStreetNumber: '',
    currentApartmentZip: '',
    currentApartmentPlace: '',
    currentApartmentNumberOfRooms: null,
    currentApartmentRent: null,
    currentApartmentStartRentDate: null,
    orderCertificate: false,
    validateCertificate: false,
    validateCertificateFileId: null,
    debtEnforcementPerMail: false
  }
}

function initForm () {
  return {
    desiredMoveInDate: null,
    residents: [initPersonForm()],
    newApartment: initNewApartment(),
    confirmation: null
  }
}

module.exports = {initForm, initPersonForm}
