<template>
  <v-flex class="mt-3" xs6 sm6 md6 lg6 xl6>
    <v-card height="100%">
      <v-container fluid grid-list-xs>
        <v-card-title class="pa-0">{{ $t('tasks.comment.title') }}</v-card-title>
        <v-textarea
          v-model="form.comment"
          :disabled="readOnly"
          :label="$t('tasks.comment.title')"
          :hint="$t('tasks.comment.hint')"
          persistent-hint
          @input="updateFormValues"
        ></v-textarea>
      </v-container>
    </v-card>
  </v-flex>
</template>

<script>
export default {
  name: 'TicketComment',
  props: {
    comment: {
      type: String,
      default: ''
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      form: {
        comment: this.comment
      }
    }
  },
  methods: {
    updateFormValues () {
      this.$emit('updateFormValues', {
        ...this.form
      })
    }
  }
}
</script>
