import { render, staticRenderFns } from "./MatchingAddresses.vue?vue&type=template&id=97b29362&scoped=true&"
import script from "./MatchingAddresses.vue?vue&type=script&lang=js&"
export * from "./MatchingAddresses.vue?vue&type=script&lang=js&"
import style0 from "./MatchingAddresses.vue?vue&type=style&index=0&id=97b29362&scoped=true&lang=postcss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97b29362",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
installComponents(component, {VAlert,VCardTitle,VDataTable,VIcon,VProgressLinear})
