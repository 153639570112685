import moment from 'moment'
import { formatDate } from './formatterHelper'

const MONTH = 'month'

export function isDateInThePast (date) {
  return moment(date).isBefore(moment())
}

export function isDateInTheFuture (date) {
  return moment(date).isAfter(moment())
}

export function isDateToday (date) {
  return moment(date).isSame(moment(), 'day')
}

export function setRowClassByDiff (date) {
  if (daysDiff(date) <= 7) {
    return 'amber lighten-5'
  } else if (daysDiff(date) <= 30) {
    return 'brown lighten-5'
  } else {
    return 'grey lighten-5'
  }
}

export function daysDiff (date) {
  let today = moment()
  let diff = today.diff(date, 'days')
  return diff
}

export function getTodaysDateISOString () {
  return moment().toISOString()
}

export function getLastDayOfCurrentMonth () {
  return moment().endOf('month').toISOString()
}

export function getMondayMinusNMonths (months) {
  return moment().startOf('isoMonth').subtract(months , 'month').toISOString()
}

export function getMondayMinusNWeeks (weeks) {
  return moment().startOf('isoWeek').subtract(weeks , 'week').toISOString()
}

export function getNextSunday () {
  return moment().endOf('isoWeek').toISOString()
}

export function getDaysBetweenDates (startDate, endDate) {
  let from = startDate.clone(), dates = []

  while (from.isSameOrBefore(endDate)) {
    dates.push(from.format('DD.MM.YYYY'))
    from.add(1, 'days')
  }
  return dates
}

export function getMonthsBetweenDates (startDate, endDate) {
  let startDateInMonth = startDate.get('date')
  let newEndDate = endDate.date(parseInt(startDateInMonth) + 1)
  let dates = []

  while (startDate.isSameOrBefore(newEndDate)) {
    dates.push(moment(startDate.month(), 'M').format('MMMM'))
    startDate.add(1, 'month')
  }
  return dates
}

export function getIntervalLabels (data, interval) {
  // IF WE WANT TO UPDATE THE DISPLAY WE CAN CHANGE IT ONLY HERE
  switch (interval) {
    case MONTH:
      return data.map(x => moment(x.date).format('MMMM YYYY'))
    default:
      return data.map(x => formatDate(x.date))
  }
}
