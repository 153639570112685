<template>
  <v-container fluid>
    <v-slide-y-transition mode="out-in">
      <v-card class="mt-4">
        <v-layout class="row pr-4 pl-2">
          <v-card-title>{{ $t('navigation.recipients') }}</v-card-title>
          <v-spacer></v-spacer>
          <v-text-field
            append-icon="search"
            :label="$t('common.buttons.search')"
            single-line
            hide-details
            v-model="search"
          ></v-text-field>
        </v-layout>

        <v-data-table
          :headers="headers"
          :items="recipients"
          :loading="loading"
          :options.sync="options"
          :footer-props="{ itemsPerPageOptions: itemsPerPageOptions, itemsPerPageText: itemsPerPageText }"
          :search="search"
          @click:row="goToRecipientDetails"
          item-key="recipientId"
          class="elevation-1"
        >
          <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
          <template v-slot:[`item.propertiesString`]="{ item }">
            <ul>
              <li v-for="property in item.properties" :key="property.propertyId">
                {{ property.street }}, {{ property.zip }} {{ property.place }}<br />
              </li>
            </ul>
          </template>
        </v-data-table>
      </v-card>
    </v-slide-y-transition>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { RECIPIENT_DETAIL } from '@/config/options/routes/dossierRoutes'

export default {
  name: 'DossierRecipients',
  created () {
    this.resetSavedFilters()
    this.loadData()
  },
  data () {
    return {
      loading: false,
      search: '',
      recipients: [],
      options: {
        page: 1,
        sortBy: ['name']
      }
    }
  },
  computed: {
    headers () {
      return [
        {
          text: this.$t('common.id'),
          align: 'left',
          value: 'recipientId'
        },
        {
          text: this.$t('common.data.name'),
          align: 'left',
          value: 'name',
          sortable: true
        },
        {
          text: this.$t('common.addressFields.place'),
          align: 'left',
          value: 'placeString'
        },
        {
          text: this.$t('dossier.recipients.properties'),
          align: 'left',
          value: 'propertiesString'
        }
      ]
    },
    itemsPerPageOptions () {
      return [
        5,
        10,
        15,
        {
          'text': this.$t('common.all'),
          'value': -1
        }
      ]
    },
    itemsPerPageText () {
      return this.$t('common.itemsPerPageText')
    }
  },
  watch: {
    search () {
      this.options.page = 1
    }
  },
  methods: {
    ...mapActions('printer', ['resetSavedFilters']),
    ...mapActions('notifier', ['setErrorSnackbar']),
    loadData () {
      this.loading = true
      let that = this
      this.$prestigeDossierRecipient.getAllRecipients().then(response => {
        that.loading = false
        that.recipients = response.data
        that.recipients.forEach(recipient => {
          recipient.name = recipient.lastName + ' ' + recipient.firstName
          recipient.placeString = recipient.zip + ' ' + recipient.place
          recipient.propertiesString = recipient.properties.length > 0 ? recipient.properties.map(property => {
            return property.street + ', ' + property.zip + ' ' + property.place
          }).join(' ') : ''
        })
      }).catch(error => {
        that.loading = false
        that.recipients = []
        that.setErrorSnackbar(error)
      })
    },
    goToRecipientDetails (recipient) {
      this.$router.push({ name: RECIPIENT_DETAIL, params: { recipientId: recipient.recipientId } })
    }
  }
}
</script>

<style scoped lang="postcss">
/deep/ tr:nth-child(odd) {
  background: #f8f8f8;
}
/deep/ tr {
  cursor: pointer;
}
</style>
