<template>
  <div>
    <v-card-title class="pl-0">{{ $t('navigation.heatingOil') }}</v-card-title>
    <v-card-title class="subtitle-1 pa-0" v-if="supplierStats.length > 0">{{ $t('statistics.totalAmountOil') }}: {{ totalAmount }} L</v-card-title>
    <v-layout row wrap>
      <v-flex xs12 sm12 md12 lg6 xl6>
        <v-data-table
          :headers="supplierHeaders"
          :items="supplierStats"
          :loading="heatingOilStatsLoading"
          :options.sync="supplierOptions"
          :footer-props="{ itemsPerPageOptions: supplierItemsPerPageOptions, itemsPerPageText: supplierItemsPerPageText }"
          @click:row="displaySupplierOrders"
          :class="$ticketingEnabled ? 'suppliers-table' : ''"
          class="elevation-1 mr-2 ml-2 mt-2"
        >
          <template v-slot:[`item.supplierAmount`]="{ item }">
            <td>{{ item.supplierAmountFormatted }}</td>
          </template>
          <template slot="no-data">
            <v-alert :value="true" color="error" icon="warning">
              {{ $t('table.noData') }}
            </v-alert>
          </template>
        </v-data-table>
      </v-flex>
      <v-flex xs12 sm12 md12 lg6 xl6>
        <v-data-table
          :headers="fillingLevelHeaders"
          :items="fillingLevelStats"
          :loading="heatingOilStatsLoading"
          :hide-default-footer="true"
          :footer-props="{itemsPerPageOptions}"
          class="elevation-1 ml-2 mt-2 mr-2"
        >
          <template v-slot:[`item.date`]="{ item }">
            <td>{{ item.dateFormatted }}</td>
          </template>
          <template v-slot:[`item.fillingLevel`]="{ item }">
            <td>{{ item.volumeInfo }}</td>
          </template>
          <template slot="no-data">
            <v-alert :value="true" color="error" icon="warning">
              {{ $t('table.noData') }}
            </v-alert>
          </template>
        </v-data-table>
      </v-flex>
      <v-overlay :value="overlay" v-if="$ticketingEnabled">
        <v-card light class="ml-20">
          <v-card-title>
            <span class="headline">{{ $t('heatingOil.orders.supplierOrders') }}</span>
            <v-icon color="primary" @click="overlay = false" class="float-right">close</v-icon>
          </v-card-title>
          <v-container>
            <v-layout row wrap class="overlay-content">
              <v-flex xs12 sm12 md12 lg12 xl12>
                <v-data-table
                  :headers="supplierOrdersHeaders"
                  :items="supplierOrders"
                  :loading="supplierOrdersLoading"
                  :options.sync="supplierOrdersOptions"
                  class="elevation-1 mr-4 ml-4 mb-2 mt-0"
                >
                  <template v-slot:[`item.amountSum`]="{ item }">
                    <td>{{ item.amountInfo }}</td>
                  </template>
                  <template v-slot:[`item.orderedOn`]="{ item }">
                    {{ item.orderedOnFormatted }}
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-btn icon class="mx-0" :title="$t('heatingOil.orders.downloadExcel')" :disabled="item.excelLoading" :loading="item.excelLoading" @click.stop="downloadExcel(item)">
                      <v-icon color="primary">file_download</v-icon>
                    </v-btn>
                  </template>
                  <template slot="no-data">
                    <v-alert :value="true" color="error" icon="warning">
                      {{ $t('table.noData') }}
                    </v-alert>
                  </template>
                </v-data-table>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-overlay>
    </v-layout>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { formatDate } from '@/helpers/formatterHelper'
import * as heatingOilHelper from '@/features/heating-oil/helpers/heatingOilHelper'

export default {
  name: 'HeatingOilStats',
  data () {
    return {
      heatingOilStats: [],
      supplierStats: [],
      fillingLevelStats: [],
      supplierOrders: [],
      heatingOilStatsLoading: true,
      supplierOrdersLoading: false,
      supplierOptions: {
        page: 1,
        sortBy: ['supplierAmount'],
        sortDesc: [true]
      },
      supplierOrdersOptions: {
        sortBy: ['supplierAmount'],
        sortDesc: [true]
      },
      itemsPerPageOptions: [-1],
      totalAmount: 0,
      overlay: false,
      from: '',
      to: ''
    }
  },
  computed: {
    supplierHeaders () {
      return [
        {
          text: this.$t('heatingOil.subHeaders.supplier'),
          align: 'left',
          value: 'supplierInfo'
        },
        {
          text: this.$t('heatingOil.subHeaders.amount'),
          align: 'left',
          value: 'supplierAmount'
        }
      ]
    },
    supplierItemsPerPageOptions () {
      return [
        5,
        10,
        15,
        {
          'text': this.$t('common.all'),
          'value': -1
        }
      ]
    },
    supplierItemsPerPageText () {
      return this.$t('common.itemsPerPageText')
    },
    supplierOrdersHeaders () {
      return [
        {
          text: this.$t('common.id'),
          align: 'left',
          value: 'orderId'
        },
        {
          text: this.$t('heatingOil.subHeaders.supplier'),
          align: 'left',
          value: 'supplierInfo'
        },
        {
          text: this.$t('heatingOil.basket.orderQuantity'),
          align: 'left',
          value: 'amountSum'
        },
        {
          text: this.$t('common.date.title'),
          align: 'left',
          value: 'orderedOn'
        },
        {
          text: this.$t('heatingOil.headers.actions'),
          align: 'left',
          sortable: false,
          value: 'actions'
        }
      ]
    },
    fillingLevelHeaders () {
      return [
        {
          text: this.$t('common.date.title'),
          align: 'left',
          value: 'date'
        },
        {
          text: this.$t('heatingOil.headers.stockVolume'),
          align: 'left',
          value: 'fillingLevel'
        }
      ]
    }
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    formatDateFunc: formatDate,
    loadHeatingOilStats (from, to) {
      this.from = from
      this.to = to
      let that = this
      that.heatingOilStatsLoading = true
      this.$prestigeHeatingOilStats.getOilOrderStatistics(from, to).then(response => {
        that.resetStats()
        that.heatingOilStats = response.data
        if (that.heatingOilStats) {
          if (that.heatingOilStats.supplier) {
            let total = 0
            that.heatingOilStats.supplier.forEach(subItem => {
              subItem.supplierInfo = '[' + subItem.id + '] ' + subItem.label
              total += subItem.value
              subItem.supplierAmount = subItem.value
              subItem.supplierAmountFormatted = heatingOilHelper.formatVolume(subItem.supplierAmount)
            })
            that.totalAmount = heatingOilHelper.formatVolume(total)
            that.supplierStats = that.heatingOilStats.supplier
          }

          if (that.heatingOilStats.fillingLevel) {
            that.heatingOilStats.fillingLevel.forEach(subItem => {
              subItem.dateFormatted = that.formatDateFunc(subItem.date)
              let formattedLevel = subItem.fillingLevel ? heatingOilHelper.formatVolume(subItem.fillingLevel) : '?'
              subItem.volumeInfo = formattedLevel + ' / ' + heatingOilHelper.formatVolume(subItem.volume)
            })
            that.fillingLevelStats = that.heatingOilStats.fillingLevel
          }

          that.heatingOilStatsLoading = false
        }
        return true
      }).catch(error => {
        that.resetStats()
        that.heatingOilStatsLoading = false
        that.setErrorSnackbar(error)
      })
    },
    resetStats () {
      this.supplierStats = []
      this.fillingLevelStats = []
      this.supplierOptions.page = 1
    },
    displaySupplierOrders (item) {
      if (this.$ticketingEnabled) {
        this.supplierOrdersLoading = true
        let that = this
        this.$prestigeHeatingOilOrder.getAllOrders(item.id, this.from, this.to).then(function (response) {
          that.supplierOrders = response.data
          for (let order of that.supplierOrders) {
            order.orderedOnFormatted = that.formatDateFunc(order.orderedOn)
            order.supplierInfo = item.supplierInfo
            let amountSum = 0
            for (let item of order.items) {
              amountSum += item.amount
            }
            order.amountSum = amountSum
            order.amountInfo = heatingOilHelper.formatVolume(amountSum)
          }
          that.supplierOrdersLoading = false
          that.overlay = true
          return true
        }).catch(error => {
          that.supplierOrdersLoading = false
          that.setErrorSnackbar(error)
        })
      }
    },
    downloadExcel (item) {
      this.loadIcon(item, true)
      const config = { responseType: 'blob' }
      let that = this
      this.$prestigeHeatingOilOrder.generateExcelReport(item.orderId, config).then(response => {
        let file = heatingOilHelper.setFileInfo(response)
        heatingOilHelper.downloadFile(file)
        that.loadIcon(item, false)
        return true
      }).catch(error => {
        that.loadIcon(item, false)
        that.setErrorSnackbar(error)
      })
    },
    loadIcon (item, loading) {
      item.excelLoading = loading
      this.$forceUpdate()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="postcss">
/deep/ .ml-20 {
  margin-left: 20%;
  width: 100%;
}
/deep/ .v-card__title {
  display: block;
}
/deep/ .suppliers-table tr {
  cursor: pointer;
}
/deep/ tr:nth-child(odd) {
  background: #f8f8f8;
}
ul {
  padding-left: 0;
  list-style: none;
}
ul li {
  padding: 2px 2px 2px 15px;
  font-size: .875rem;
}
.overlay-content {
  max-height: 600px;
  overflow: auto;
}
</style>
