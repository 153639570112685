<template>
  <v-card class="mb-2 pa-0" v-if="propertyLinks.length > 0">
    <v-card-text class="pa-4">
      <v-icon color="primary" @click="toggle" class="float-right">{{ icon }}</v-icon>
      <v-card-subtitle class="pa-0 font-weight-bold subtitle-1">
        {{ $t('common.properties') }}
      </v-card-subtitle>
    </v-card-text>
    <v-divider></v-divider>
    <v-list v-if="isOpen" class="context overflow-auto">
      <template v-for="(propertyId, index) in propertyLinks">
        <ProcessProperty :key="index" :propertyId="propertyId" @openNewTicketDialog="onNewPropertyTicket" :deleteAction="deleteAction" :processInstanceId="processInstanceId"/>
        <v-divider :key="index + '_divider'"></v-divider>
      </template>
    </v-list>
  </v-card>
</template>
<script>
import ProcessProperty from './ProcessProperty'
import { mapActions } from 'vuex'

export default {
  name: 'ProcessProperties',
  components: {
    ProcessProperty
  },
  props: {
    processInstanceId: {
      type: String,
      default: ''
    },
    propertyLinks: {
      type: Array,
      default: () => []
    },
    deleteAction: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      isOpen: true
    }
  },
  async created () {
    await this.loadProperties()
  },
  watch: {
    propertyLinks () {
      this.loadProperties()
    }
  },
  computed: {
    icon () {
      return this.isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
    }
  },
  methods: {
    ...mapActions('abacus', ['loadPropertyDetails']),
    async loadProperties () {
      if (this.propertyLinks.length > 0) {
        return this.propertyLinks.forEach(async (propertyId) => {
          let propertyLoadedDetails = await this.loadPropertyDetails(propertyId)
          return propertyLoadedDetails
        })
      }
    },
    toggle () {
      this.isOpen = !this.isOpen
    },
    onNewPropertyTicket (item) {
      let links = [{ linkName: item.linkName, linkValues: [item.propertyId.toString()] }]
      let subject = item?.street
      this.$emit('openNewTicketDialog', links, subject)
    }
  }
}
</script>

<style scoped lang="postcss">
.property-link a {
  text-decoration: none;
}
</style>
