<template>
  <v-layout>
    <v-flex v-if="hasProcessVariables" class="pr-2">
      <ProcessFileVariables
        ref="processFileVariables"
        :process-variables="processVariables"
        :process-instance-id="processInstanceId"
        :created-on="createdOn"
        @loadProcessVariables="$emit('loadProcessVariables')"
      />
      <ProcessStructuredVariables
        v-if="hasStructuredVariables"
        :process-variables="camundaStructuredVariables"
      />
      <ProcessSimpleVariables
        v-if="hasSimpleVariables"
        :process-variables="camundaSimpleVariables"
      />
    </v-flex>
    <v-flex v-else>
      <v-card-text class="pt-0">
        {{ $t('table.noData') }}
      </v-card-text>
    </v-flex>
  </v-layout>
</template>

<script>
import ProcessFileVariables from '../shared/ProcessFileVariables'
import ProcessStructuredVariables from './ProcessStructuredVariables'
import ProcessSimpleVariables from './ProcessSimpleVariables'

export default {
  name: 'ProcessVariables',
  components: {
    ProcessFileVariables,
    ProcessStructuredVariables,
    ProcessSimpleVariables
  },
  props: {
    processVariables: {
      type: Array,
      default: () => []
    },
    processInstanceId: {
      type: String,
      default: null
    },
    createdOn: {
      type: String,
      default: ''
    }
  },
  computed: {
    userFiles () {
      return this.processVariables.filter(x => x.variableName === 'employerData')
    },
    emailFiles () {
      return true
    },
    camundaStructuredVariables () {
      return this.processVariables.filter(x => x.variableType === 'json')
    },
    camundaSimpleVariables () {
      return this.processVariables.filter(x =>
        !x.variableName.endsWith('_File') &&
        x.variableType !== 'json' &&
        x.variableValue !== null &&
        x.variableValue !== ''
      )
    },
    hasProcessVariables () {
      return this.processVariables.length > 0
    },
    hasStructuredVariables () {
      return this.camundaStructuredVariables.length > 0
    },
    hasSimpleVariables () {
      return this.camundaSimpleVariables.length > 0
    }
  }
}
</script>

<style scoped lang="postcss">
/deep/ .v-list {
  max-height: 600px;
  overflow-y: auto;
}
</style>
