<template>
  <v-list-item-content>
    <v-dialog v-model="show" ref="editDialog" max-width="500px" @keydown.esc="closeEditEditor" persistent>
      <v-card>
        <v-card-title>
            {{ $t('tasks.info.dialogTitleEditor') }}
          <v-btn icon absolute right>
            <v-icon class="clear" color="primary" :size="28" @click.native="closeEditEditor"/>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <RealEstateManager v-bind:form="select" :editor-user="editorUser" />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click.native="closeEditEditor">{{ $t('common.buttons.cancel') }}</v-btn>
          <v-btn color="primary" @click.native="saveEditEditor">{{ $t('common.buttons.save') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div>
      {{ $t('tasks.info.editor') }}: {{ realEstateManagerInfo }}
      <span v-if="!editDisabled">
        [<a @click.stop="openEditEditor">{{ $t('common.buttons.edit') }}</a>]
        <v-icon v-if="realEstateManager" small color="red lighten-1" @click="removeEditor">delete</v-icon>
      </span>
    </div>
  </v-list-item-content>
</template>

<script>
import RealEstateManager from './RealEstateManager.vue'
import { AuthenticationContext } from 'vue-adal'
import { mapActions, mapState } from 'vuex'
import _isEmpty from 'lodash.isempty'

export default {
  name: 'RealEstateManagerEditor',
  components: {
    RealEstateManager
  },
  watch: {
    search (val) {
      val && this.querySelections(val)
    }
  },
  props: {
    realEstateManager: {
      type: Object,
      default: () => {}
    },
    detailsView: {
      type: Boolean,
      default: false
    },
    editDisabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      select: { value: null },
      show: false,
      form: {
        realEstateManager: null
      },
      realEstateManagerInfo: '[]',
      displayItems: [],
      editorUser: {}
    }
  },
  created () {
    if (this.loggedInUserId && !this.detailsView) {
      this.setDefaultUserAndRealEstateManagerInfo()
    } else {
      this.realEstateManagerInfo = this.realEstateManagerText
    }
  },
  computed: {
    ...mapState('abacus', ['realEstateManagers']),
    loggedInUser () {
      return AuthenticationContext && AuthenticationContext.user && AuthenticationContext.user.profile ? AuthenticationContext.user.profile : null
    },
    loggedInUserId () {
      return this.loggedInUser && this.loggedInUser.employeeid ? this.loggedInUser.employeeid : null
    },
    loggedUserInfo () {
      return '[' + this.loggedInUserId + '] ' + this.loggedInUser.name
    },
    realEstateManagerText () {
      return this.realEstateManager ? '[' + this.realEstateManager.addressId + '] ' + this.realEstateManager.firstName + ' ' + this.realEstateManager.lastName : ''
    }
  },
  methods: {
    ...mapActions('abacus', ['loadAddressDetails']),
    openEditEditor () {
      this.select = { value: null }
      this.show = true
    },
    closeEditEditor () {
      this.show = false
    },
    saveEditEditor () {
      let selectedId = this.select.value.addressId.toString()
      if (!_isEmpty(this.editorUser) && selectedId === this.editorUser.addressId.toString()) {
        this.setDefaultUserAndRealEstateManagerInfo()
      } else if (this.realEstateManagers && this.realEstateManagers.length > 0) {
        this.form.realEstateManager = this.realEstateManagers.find(x => x.addressId.toString() === selectedId)
        this.realEstateManagerInfo = this.form.realEstateManager ? '[' + this.form.realEstateManager.addressId + '] ' + this.form.realEstateManager.firstName + ' ' + this.form.realEstateManager.lastName : ''
        this.$emit('updateFormValues', { ...this.form })
      }
      this.show = false
    },
    removeEditor () {
      this.form.realEstateManager = null
      this.realEstateManagerInfo = ''
      this.$emit('updateFormValues', { ...this.form })
    },
    async setDefaultUserAndRealEstateManagerInfo () {
      if (this.loggedInUser && this.loggedInUserId) {
        let userDetails = await this.loadAddressDetails(this.loggedInUserId)
        userDetails.addressId = userDetails.id
        delete userDetails.id
        this.editorUser = userDetails
      } else {
        this.editorUser = this.realEstateManager
      }
      this.form.realEstateManager = this.editorUser
      this.realEstateManagerInfo = this.loggedUserInfo
      this.$emit('updateFormValues', { ...this.form })
    }
  }
}
</script>

<style scoped>
.v-list-item__content {
  float: left;
}
</style>
