<template>
  <div>
    <v-card-title class="pl-0">{{ $t('navigation.tasks') }}</v-card-title>
    <v-card-title class="subtitle-1 pa-0" v-if="administrativeAreaStats.length > 0">
      {{ $t('statistics.totalNumberTasks') }}: {{ totalNumberTasks }}, {{ $t('statistics.totalNumberOffers') }}: {{ totalNumberOffers }}
    </v-card-title>
    <v-layout row wrap>
      <v-flex xs12 sm12 md12 lg4 xl4>
        <v-data-table
          :headers="admininstrativeAreaHeaders"
          :items="administrativeAreaStats"
          :loading="taskStatsLoading"
          :options.sync="administrativeOptions"
          :footer-props="{itemsPerPageOptions}"
          :hide-default-footer="true"
          @click:row="displayAdministrativeTasks"
          :class="$ticketingEnabled ? 'tasks-overlay' : ''"
          class="elevation-1 mr-2 ml-2 mt-2"
        >
          <template slot="no-data">
            <v-alert :value="true" color="error" icon="warning">
              {{ $t('table.noData') }}
            </v-alert>
          </template>
        </v-data-table>
      </v-flex>
      <v-flex xs12 sm12 md12 lg4 xl4>
        <v-data-table
          :headers="realEstateHeaders"
          :items="realEstateManagerStats"
          :loading="taskStatsLoading"
          :options.sync="editorOptions"
          :hide-default-footer="true"
          :footer-props="{itemsPerPageOptions}"
          @click:row="displayRealEstateManagerTasks"
          :class="$ticketingEnabled ? 'tasks-overlay' : ''"
          class="elevation-1 ml-2 mr-2 mt-2"
        >
          <template v-slot:[`item.date`]="{ item }">
            <td>{{ item.dateFormatted }}</td>
          </template>
          <template slot="no-data">
            <v-alert :value="true" color="error" icon="warning">
              {{ $t('table.noData') }}
            </v-alert>
          </template>
        </v-data-table>
      </v-flex>
      <v-flex xs12 sm12 md12 lg4 xl4>
        <v-data-table
          :headers="craftsmenHeaders"
          :items="craftsmenStats"
          :loading="taskStatsLoading"
          :options.sync="craftsmenOptions"
          :footer-props="{ itemsPerPageOptions: craftsmenItemsPerPageOptions, itemsPerPageText: craftsmenItemsPerPageText }"
          @click:row="displayCraftsmenTasks"
          :class="$ticketingEnabled ? 'tasks-overlay' : ''"
          class="elevation-1 ml-2 mt-2 mr-2"
        >
          <template v-slot:[`item.date`]="{ item }">
            <td>{{ item.dateFormatted }}</td>
          </template>
          <template slot="no-data">
            <v-alert :value="true" color="error" icon="warning">
              {{ $t('table.noData') }}
            </v-alert>
          </template>
        </v-data-table>
      </v-flex>
      <v-overlay :value="overlay" v-if="$ticketingEnabled">
        <v-card light class="ml-20">
          <v-card-title>
            <span class="headline">{{ $t('navigation.tasksList') }}</span>
            <v-icon color="primary" @click="overlay = false" class="float-right">close</v-icon>
          </v-card-title>
          <v-container>
            <v-layout row wrap class="overlay-content">
              <v-flex xs12 sm12 md12 lg12 xl12>
                <v-data-table
                  :headers="taskHeaders"
                  :items="tasksList"
                  :loading="tasksListLoading"
                  :options.sync="tasksListOptions"
                  @click:row="openTaskDetails"
                  class="elevation-1 mr-4 ml-4 mb-2 mt-0 tasks-table"
                >
                  <template v-slot:[`item.taskType`]="{ item }">
                    {{ $t('tasks.types.' + item.taskType) }}
                  </template>
                  <template v-slot:[`item.createdOn`]="{ item }">
                    {{ item.createdOnFormatted }}
                  </template>
                  <template v-slot:[`item.jobDescription`]="{ item }">
                    <p class="mb-0 truncate-two-lines">{{ item.jobDescription }}</p>
                  </template>
                  <template slot="no-data">
                    <v-alert :value="true" color="error" icon="warning">
                      {{ $t('table.noData') }}
                    </v-alert>
                  </template>
                </v-data-table>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-overlay>
    </v-layout>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import * as heatingOilHelper from '@/features/heating-oil/helpers/heatingOilHelper'
import { formatDateAndTime } from '@/helpers/formatterHelper'
import { TASK_DETAILS } from '@/config/options/routes/taskRoutes'

export default {
  name: 'TaskStats',
  data () {
    return {
      taskStats: [],
      administrativeAreaStats: [],
      realEstateManagerStats: [],
      craftsmenStats: [],
      taskStatsLoading: true,
      administrativeOptions: {
        sortBy: ['tasks'],
        sortDesc: [true]
      },
      editorOptions: {
        sortBy: ['value'],
        sortDesc: [true]
      },
      craftsmenOptions: {
        page: 1,
        sortBy: ['value'],
        sortDesc: [true]
      },
      tasksListOptions: {
        sortBy: ['createdOn'],
        sortDesc: [true]
      },
      itemsPerPageOptions: [-1],
      totalNumberTasks: 0,
      totalNumberOffers: 0,
      tasksListLoading: false,
      tasksList: [],
      overlay: false,
      from: '',
      to: ''
    }
  },
  computed: {
    admininstrativeAreaHeaders () {
      return [
        {
          text: this.$t('tasks.info.administrativeArea'),
          align: 'left',
          value: 'administrativeAreaInfo'
        },
        {
          text: this.$t('navigation.tasks'),
          align: 'left',
          value: 'tasks'
        },
        {
          text: this.$t('statistics.offers'),
          align: 'left',
          value: 'offers'
        }
      ]
    },
    realEstateHeaders () {
      return [
        {
          text: this.$t('tasks.info.editor'),
          align: 'left',
          value: 'info'
        },
        {
          text: this.$t('navigation.tasks'),
          align: 'left',
          value: 'value'
        }
      ]
    },
    craftsmenHeaders () {
      return [
        {
          text: this.$t('tasks.craftsmen.title'),
          align: 'left',
          value: 'info'
        },
        {
          text: this.$t('navigation.tasks'),
          align: 'left',
          value: 'value'
        }
      ]
    },
    craftsmenItemsPerPageOptions () {
      return [
        5,
        10,
        15,
        {
          'text': this.$t('common.all'),
          'value': -1
        }
      ]
    },
    craftsmenItemsPerPageText () {
      return this.$t('common.itemsPerPageText')
    },
    taskHeaders () {
      return [
        {
          text: this.$t('common.id'),
          align: 'left',
          value: 'id'
        },
        {
          text: this.$t('tasks.list.type'),
          align: 'left',
          value: 'taskType'
        },
        {
          text: this.$t('tasks.info.property'),
          align: 'left',
          value: 'propertyInfo'
        },
        {
          text: this.$t('tasks.createTask.subject1'),
          align: 'left',
          value: 'subjectInfo'
        },
        {
          text: this.$t('tasks.createTask.jobDescription'),
          align: 'left',
          value: 'jobDescription'
        },
        {
          text: this.$t('tasks.tenant.title'),
          align: 'left',
          value: 'tenantInfo'
        },
        {
          text: this.$t('common.date.creationDate'),
          align: 'left',
          value: 'createdOn'
        }
      ]
    }
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    formatDateAndTimeFunc: formatDateAndTime,
    loadTaskStats (from, to) {
      this.from = from
      this.to = to
      let that = this
      that.taskStatsLoading = true
      this.$prestigeTaskStats.getTaskStatistics(from, to).then(response => {
        that.resetStats()
        that.taskStats = response.data
        if (that.taskStats) {
          if (that.taskStats.administrativeArea) {
            let tasks = 0
            let offers = 0
            that.taskStats.administrativeArea.forEach(subItem => {
              tasks += subItem.tasks
              offers += subItem.offers
              subItem.administrativeAreaInfo = '[' + subItem.administrativeAreaId + '] ' + subItem.administrativeAreaText
            })
            that.administrativeAreaStats = that.taskStats.administrativeArea
            that.totalNumberTasks = heatingOilHelper.formatVolume(tasks)
            that.totalNumberOffers = heatingOilHelper.formatVolume(offers)
          }

          if (that.taskStats.realEstateManager) {
            that.taskStats.realEstateManager.forEach(subItem => {
              subItem.info = '[' + subItem.id + '] ' + subItem.label
            })
            that.realEstateManagerStats = that.taskStats.realEstateManager
          }

          if (that.taskStats.craftsmen) {
            that.taskStats.craftsmen.forEach(subItem => {
              subItem.info = '[' + subItem.id + '] ' + subItem.label
            })
            that.craftsmenStats = that.taskStats.craftsmen
          }

          that.taskStatsLoading = false
        }
        return true
      }).catch(error => {
        that.resetStats()
        that.taskStatsLoading = false
        that.setErrorSnackbar(error)
      })
    },
    resetStats () {
      this.administrativeAreaStats = []
      this.realEstateManagerStats = []
      this.craftsmenStats = []
      this.craftsmenOptions.page = 1
    },
    displayAdministrativeTasks (item) {
      if (this.$ticketingEnabled) {
        this.tasksListLoading = true
        let that = this
        this.$prestigeTask.searchTasks('', '', '', item.administrativeAreaId, '', '', this.from, this.to).then(function (response) {
          that.setTaskList(response)
          return true
        }).catch(error => {
          that.overlay = false
          that.tasksListLoading = false
          that.setErrorSnackbar(error)
        })
      }
    },
    displayRealEstateManagerTasks (item) {
      if (this.$ticketingEnabled) {
        this.tasksListLoading = true
        let that = this
        this.$prestigeTask.searchTasks('', '', '', '', item.id, '', this.from, this.to).then(function (response) {
          that.setTaskList(response)
          return true
        }).catch(error => {
          that.overlay = false
          that.tasksListLoading = false
          that.setErrorSnackbar(error)
        })
      }
    },
    displayCraftsmenTasks (item) {
      if (this.$ticketingEnabled) {
        this.tasksListLoading = true
        let that = this
        this.$prestigeTask.searchTasks('', '', '', '', '', item.id, this.from, this.to).then(function (response) {
          that.setTaskList(response)
          return true
        }).catch(error => {
          that.tasksListLoading = false
          that.setErrorSnackbar(error)
        })
      }
    },
    setTaskList (response) {
      this.tasksList = response.data
      for (let task of this.tasksList) {
        task.createdOnFormatted = this.formatDateAndTimeFunc(task.createdOn)
        task.subjectInfo = task.subjectLine1 || task.subjectLine2
        task.propertyInfo = '[' + task.propertyId + '] ' + task.propertyStreet + ', ' + task.propertyZip + ' ' + task.propertyPlace
        task.tenantInfo = this.tenantInfo(task, task.tenant)
      }
      this.tasksListLoading = false
      this.overlay = true
    },
    openTaskDetails (task) {
      this.$router.push({ name: TASK_DETAILS, params: { taskId: task.id } })
    },
    tenantInfo (task, tenant) {
      switch (tenant) {
        case 'CURRENT':
          if (task.currentTenant) {
            return '[' + task.currentTenant.addressId + ']' + task.currentTenant.firstName + ' ' + task.currentTenant.lastName
          }
          return ''
        case 'MANUAL':
          if (task.manualTenant) {
            return '[' + task.manualTenant.addressId + ']' + task.manualTenant.firstName + ' ' + task.manualTenant.lastName
          }
          return ''
        case 'NEW':
          if (task.newTenant) {
            return '[' + task.newTenant.addressId + ']' + task.newTenant.firstName + ' ' + task.newTenant.lastName
          }
          return ''
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="postcss">
/deep/ .ml-20 {
  margin-left: 20%;
}
/deep/ .v-card__title {
  display: block;
}
/deep/ .tasks-overlay tr, /deep/ .tasks-table tr {
  cursor: pointer;
}
/deep/ tr:nth-child(odd) {
  background: #f8f8f8;
}
ul {
  padding-left: 0;
  list-style: none;
}
ul li {
  padding: 4px 4px 4px 15px;
  font-size: .875rem;
}
.overlay-content {
  max-height: 600px;
  overflow: auto;
}
.truncate-two-lines {
  display: block;
  display: -webkit-box;
  max-width: 250px;
  height: 39px;
  margin: 0 auto;
  line-height: 1.5;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
