import * as routesHelper from '@/helpers/routesHelper'
import _cloneDeep from 'lodash.clonedeep'
import Vue from 'vue'

export function addAutocompleteClickListeners () {
  addPropertyEventListener()
  addObjectEventListener()
  addTicketEventListener()
  addDashboardAddressListener('tenant')
  addDashboardAddressListener('client')
  addDashboardAddressListener('caretaker')
  addDashboardAddressListener('craftsman')
  addDashboardAddressListener('partner')
}

export function addPropertyEventListener () {
  document.querySelectorAll('span.property-slot').forEach(item => {
    item.addEventListener('click', function (e) {
      e.preventDefault()
      if (item.id) {
        let propertyId = item.id.slice(1, -1)
        routesHelper.openDashboardProperty(propertyId, true)
      }
    })
  })
}

export function addObjectEventListener () {
  document.querySelectorAll('span.object-slot').forEach(item => {
    item.addEventListener('click', function (e) {
      e.preventDefault()
      if (item.id) {
        let propertyId = item.id.slice(1, -1).split(':')[0]
        routesHelper.openDashboardProperty(propertyId, true)
      }
    })
  })
}

export function addTicketEventListener () {
  document.querySelectorAll('span.ticket-slot').forEach(item => {
    item.addEventListener('click', function (e) {
      e.preventDefault()
      if (item.id) {
        let ticketId = item.id.slice(1, -1).split(':')[0]
        routesHelper.openTicket(ticketId, true)
      }
    })
  })
}

export function addDashboardAddressListener (addressType) {
  document.querySelectorAll('span.' + addressType + '-slot').forEach(item => {
    item.addEventListener('click', function (e) {
      e.preventDefault()
      if (item.id) {
        let addressId = item.id.slice(1, -1)
        routesHelper.openDashboardAddress(addressId, addressType, true)
        localStorage.setItem('deepLink', true)
      }
    })
  })
}

export function setAutocompleteValue (object) {
  let autocompleteValue = ''
  if (object.addressId) {
    autocompleteValue += '[' + object.addressId + ']'
  }
  if (object.firstName || object.lastName) {
    if (object.firstName) {
      autocompleteValue += ' ' + object.firstName.trim()
    }
    if (object.lastName) {
      autocompleteValue += ' ' + object.lastName.trim()
    }
  }
  return autocompleteValue
}

export function getLinkValues (links, group) {
  let groupLinks = []
  if (links && links.length > 0) {
    groupLinks = links.find(x => x.linkName === group)
  }
  return groupLinks && groupLinks.linkValues ? groupLinks.linkValues : []
}

export function appendLinks (resultLinks, additionalLinks) {
  const index = resultLinks.findIndex(x => x.linkName === additionalLinks.linkName)
  if (index !== -1) {
    if (additionalLinks.linkValues && additionalLinks.linkValues.length > 0) {
      additionalLinks.linkValues.forEach(linkValue => {
        if (!resultLinks[index].linkValues.includes(linkValue)) {
          resultLinks[index].linkValues.push(linkValue)
        }
      })
    }
  } else {
    Vue.set(resultLinks, resultLinks.length, _cloneDeep(additionalLinks))
  }
}

export function deleteLink (links, link) {
  if (links.length > 0) {
    let clonedLinks = _cloneDeep(links)
    let index = clonedLinks.findIndex(x =>
      x.linkName.toString() === link.linkName.toString() &&
      x.linkValues.includes(link.linkValue.toString())
    )
    if (index !== -1) {
      clonedLinks[index].linkValues = clonedLinks[index].linkValues.filter(x => x.toString() !== link.linkValue.toString())
      if (clonedLinks[index].linkValues.length === 0) {
        clonedLinks.splice(index, 1)
      }
    }
    return clonedLinks
  }
  return []
}
