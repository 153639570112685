<template>
  <v-list-item class="ma-0 pa-0 bg-gray">
    <v-list-item-title>
      <v-card-title>
        {{ $t('common.salutationOptions.' + resident.salutation) }} {{ resident.firstName }} {{ resident.lastName }}
      </v-card-title>
    </v-list-item-title>
    <v-list-item-action class="my-0 mx-4">
        {{$t('renting.publicForm.fields.personInfo.dateOfBirth')}}: {{ dateOfBirth }}
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { formatDate } from '@/helpers/formatterHelper'

export default {
  name: 'ResidentInfo',
  props: {
    resident: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    dateOfBirth () {
      return formatDate(this.resident.dateOfBirth)
    }
  }
}
</script>

<style scoped>
.bg-gray {
  background-color: #f5f5f5;
}
</style>
