import { render, staticRenderFns } from "./TicketingStatistics.vue?vue&type=template&id=799b0d44&"
import script from "./TicketingStatistics.vue?vue&type=script&lang=js&"
export * from "./TicketingStatistics.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VContainer,VLayout,VRow})
