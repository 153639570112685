<template>
  <v-card class="py-4">
    <v-card-title primary-title class="py-0">
      <h3>{{ $t('constructionProjects.title') }} ({{ constructionProjects ? constructionProjects.length : 0 }})</h3>
    </v-card-title>
    <template v-if="constructionProjects && constructionProjects.length > 0">
      <v-card-text>
        <v-text-field
          append-icon="search"
          :label="$t('common.buttons.search')"
          single-line
          hide-details
          v-model="search"
        ></v-text-field>
      </v-card-text>
      <v-data-table
        sort-by="sortText"
        :headers="headers"
        :items="constructionProjects"
        :loading="false"
        :item-class="rowClass"
        :search="search"
        searchable
        :footer-props="{ itemsPerPageOptions: propertyItemsPerPageOptions, itemsPerPageText: propertyItemsPerPageText }"
        @click:row="rowClick"
      >
        <template v-slot:[`item.status`]="{ item }">
          <td>
            <v-list-item-avatar v-for="(itemStatus, index) in filterStatusList(item.status)" :key="index" class="pa-0 ma-0">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">
                    {{ getIconByStatus(itemStatus) }}
                  </v-icon>
                </template>
                <span>{{ $t('constructionProjects.status.' + itemStatus) }}</span>
              </v-tooltip>
            </v-list-item-avatar>
          </td>
        </template>
        <template v-slot:[`item.sortText`]="{ item }">
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
            <v-list-item-subtitle>{{ item.addressLine1 }}, {{ item.place }}</v-list-item-subtitle>
          </v-list-item-content>
        </template>
        <template  v-slot:[`item.buildingProjectId`]="{ item }">
          <v-btn icon color="primary" @click="showConstructionProjectInfoWindow(item, item.buildingProjectId)">
            <v-icon>near_me</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </template>
  </v-card>
</template>

<script>
import { CONSTRUCTION_PROJECTS } from '@/config/options/routes/mapRoutes'

export default {
  name: 'ConstructionProjectsTable',
  props: {
    constructionProjects: {
      type: Array,
      default: () => []
    },
    currentConstructionProject: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      search: ''
    }
  },
  computed: {
    headers () {
      return [
        {
          text: this.$t('constructionProjects.status.title'),
          align: 'left',
          value: 'status'
        },
        {
          text: this.$t('constructionProjects.info'),
          align: 'left',
          value: 'sortText'
        },
        {
          text: '',
          align: 'left',
          value: 'buildingProjectId',
          sortable: false
        }
      ]
    },
    propertyItemsPerPageOptions () {
      return [
        5,
        10,
        15,
        {
          'text': this.$t('common.all'),
          'value': -1
        }
      ]
    },
    propertyItemsPerPageText () {
      return this.$t('common.itemsPerPageText')
    }
  },
  methods: {
    showConstructionProjectInfoWindow (constructionProject, buildingProjectId) {
      if (this.$route.params && this.$route.params.buildingProjectId !== buildingProjectId) {
        this.$router.push({ name: CONSTRUCTION_PROJECTS, params: { buildingProjectId: buildingProjectId } })
      } else {
        this.$emit('showConstructionProjectInfoWindow', constructionProject, buildingProjectId)
      }
    },
    filterStatusList (list) {
      let newList = []
      list.forEach(item => {
        if (!newList.includes(item.status)) {
          newList.push(item.status)
        }
      })
      return newList
    },
    getIconByStatus (status) {
      switch (status) {
        case 'UNDEFINED':
          return 'help_outline'
        case 'FLAG':
          return 'grade'
        case 'IGNORE':
          return 'visibility_off'
      }
    },
    rowClass (item) {
      return this.currentConstructionProject && item.buildingProjectId === this.currentConstructionProject.buildingProjectId ? 'blue lighten-5' : ''
    },
    rowClick (item) {
      this.showConstructionProjectInfoWindow(item, item.buildingProjectId)
    }
  }
}
</script>

<style scoped lang="postcss">
/deep/ .v-avatar.v-list-item__avatar {
  min-width: fit-content !important;
}
/deep/ tr:nth-child(odd) {
  background: #f8f8f8;
}
/deep/ tr {
  cursor: pointer;
}
</style>
