export function setDisplayText (object) {
  let displayText = ''
  displayText += setFullNameText(object)
  displayText += setFullAddressText(object)
  return displayText
}

export function setFullNameText (object) {
  let displayText = ''
  if (object.addressId || object.id) {
    displayText += '[' + (object.addressId || object.id) + ']'
  }
  if (object.firstName || object.lastName) {
    if (object.firstName) {
      displayText += ' ' + object.firstName.trim()
    }
    if (object.lastName) {
      if (object.lastName.endsWith(',')) {
        object.lastName = object.lastName.split(',')[0]
      }
      displayText += ' ' + object.lastName.trim()
    }
  }
  return displayText
}

export function setFullAddressText (object) {
  let displayText = (object.firstName || object.lastName) ? (object.line1 || object.line2 ? ' -' : '') : ''
  if (object.line1) {
    displayText += object.line1
  }
  if (object.line2) {
    if (object.line1) {
      displayText += ', '
    } else {
      displayText += ' '
    }
    displayText += object.line2
  }
  displayText += setZipAndPlaceText(object)
  return displayText
}

export function setZipAndPlaceText (object) {
  let displayText = ''
  if (object.zip || object.place) {
    // checks if `,` char already exists in the record from the database
    if (displayText.endsWith(',')) {
      displayText = displayText.substring(0, displayText.length - 1).trim()
    }
    displayText += ','
  }
  if (object.zip) {
    displayText += ' ' + object.zip
  }
  if (object.place) {
    displayText += ' ' + object.place
  }
  return displayText
}

export function setAddressInfo (item) {
  let displayText = item.street
  displayText += setZipAndPlaceText(item)
  return displayText
}

export function setObjectInfo (item) {
  let displayText = item.objectId ? '[' + item.objectId + '] ' : ''
  if (item.floorText) {
    displayText += item.floorText
  }
  if (item.objectText) {
    displayText += (' - ' + item.objectText)
  }
  return displayText
}

export function setPropertyObjectInfo (item) {
  let displayText = item.objectId ? '[' + item.objectId + '] ' : ''
  if (item.houseStreet) {
    displayText += item.houseStreet + ' - '
  }
  if (item.floorText) {
    displayText += item.floorText
  }
  if (item.objectText) {
    displayText += (' ' + item.objectText)
  }
  return displayText
}

export function sortByFirstNameAndLastName (list) {
  return list.sort((a, b) => {
    let aName = a.firstName.toLowerCase() + a.lastName.toLowerCase()
    let bName = b.firstName.toLowerCase() + b.lastName.toLowerCase()
    if (aName > bName) {
      return 1
    } else if (bName > aName) {
      return -1
    }
    return 0
  })
}

export function soryListByObjectParam (list, property) {
  return list.sort((a, b) => (a[property] > b[[property]]) ? 1 : ((b[property] > a[property]) ? -1 : 0))
}

export function propertyFilter (queryText, itemText) {
  return queryText.split(' ').every(term => itemText.toLowerCase().indexOf(term.toLowerCase()) > -1)
}

export function dataTableFilter (search, item) {
  return item != null &&
    search != null && search.toString().split(' ')
    .every(term => Object.values(item).toString().toLowerCase().indexOf(term.toLowerCase()) > -1)
}

export function capitalizeFirstLetter (string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function getNewTicketFullSubject (object) {
  let subject = object?.houseStreet + ' - ' + object.objectText
  if(object.currentTenant) {
    subject += ' - ' + (object.currentTenant.firstName + ' ' + object.currentTenant.lastName).trim()
  } else {
    subject += ' - ' + object.currentTenantText
  }
  return subject
}

export function getNewTicketShortSubject (object) {
  let subject = object?.houseStreet + ' - ' + object.objectTextShort
  if(object.currentTenant) {
    subject += ' - ' + (object.currentTenant.firstName + ' ' + object.currentTenant.lastName).trim()
  } else {
    subject += ' - ' + object.currentTenantText
  }
  return subject
}

export function getAddressInfo (item) {
  return item.line2
}

export function getFullName (item) {
  return (item.firstName + ' ' + item.lastName).trim()
}

export function concatenatePhoneNumbers (address) {
  return [address?.mobile, address?.phone1, address?.phone2]
    .filter(i => i)
    .join(' / ')
}
