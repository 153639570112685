<template>
  <div>
    <v-btn
      color="success"
      class="pa-3 mr-2"
      :x-small="smAndDown"
      :class="smAndDown ? 'mt-2 mb-2': ''"
      :disabled="disableAddAndCompleteBtns"
      @click="saveTaskVariables"
    >
      <span>{{ $t('common.buttons.save') }}</span>
      <v-icon class="pl-1" :x-small="smAndDown">send</v-icon>
    </v-btn>
    <v-btn
      color="primary"
      class="pa-3 cy-task-dialog-complete"
      :x-small="smAndDown"
      :class="smAndDown ? 'mt-2 mb-2' : ''"
      :disabled="disableAddAndCompleteBtns || hasActiveChildTask"
      @click="completeTask"
    >
      <span>{{ $t('ticketingSystem.taskDetails.buttons.complete') }}</span>
      <v-icon class="pl-1" :x-small="smAndDown">done</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'TaskActionButtons',
  props: {
    disableAddAndCompleteBtns: {
      type: Boolean,
      default: false
    },
    hasActiveChildTask: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    smAndDown () {
      return this.$vuetify.breakpoint.smAndDown
    }
  },
  methods: {
    saveTaskVariables () {
      this.$emit('saveTaskVariables')
    },
    completeTask () {
      this.$emit('completeTask')
    }
  }
}
</script>

<style scoped lang="postcss">
.float-right {
  float: right;
}
</style>
