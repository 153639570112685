import Vue from 'vue'
import store from '../store/index'

export function useRentingForm () {
  const config = { responseType: 'blob' }
  const fileDownload = require('js-file-download')

  async function getResidentBlob (fileName) {
    try {
      let response = await Vue.prototype.$prestigeRentingFile.downloadFile(fileName, config)
      return response
    } catch (error) {
      store.dispatch('notifier/setErrorSnackbar', error)
    }
  }

  async function getRentalFormBlob (rentingFormId) {
    try {
      let response = await Vue.prototype.$prestigeRenting.getRentingFormPdf(rentingFormId, config)
      return response
    } catch (error) {
      store.dispatch('notifier/setErrorSnackbar', error)
    }
  }

  async function downloadResidentAttachment (fileName) {
    let response = await getResidentBlob(fileName)
    if (response) {
      fileDownload(response.data, fileName)
    }
  }

  async function downloadRentalFormPDF (rentingFormId) {
    let response = await getRentalFormBlob(rentingFormId)
    if (response) {
      fileDownload(response.data, 'anmeldung-' + rentingFormId + '.pdf')
    }
  }

  async function loadRentingForm (rentingFormId) {
    if (rentingFormId) {
      try {
        let response = await Vue.prototype.$prestigeRenting.getRentingForm(parseInt(rentingFormId))
        return response.data
      } catch(error) {
        store.dispatch('notifier/setErrorSnackbar', error)
      }
    }
    return false
  }

  return {
    getResidentBlob,
    getRentalFormBlob,
    downloadResidentAttachment,
    downloadRentalFormPDF,
    loadRentingForm
  }
}
