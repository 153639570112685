<template>
  <v-container fluid grid-list-md>
    <v-slide-y-transition mode="out-in">
      <v-layout row wrap>
        <v-flex md12>
          <v-card height="100%" class="mt-1">
            <PrintContainer
              :corporations="selectedCorporationMappedIDs"
              v-on:sentToPrinter="sentToPrinter"
              @downloadFile="downloadFile">
            </PrintContainer>
          </v-card>
        </v-flex>
        <v-flex md12 class="mt-2">
          <v-card>
            <v-layout class="row pr-4 pl-2">
              <v-card-title>{{ $t('navigation.corporations') }}</v-card-title>
              <v-spacer></v-spacer>
              <v-text-field
                append-icon="search"
                :label="$t('common.buttons.search')"
                single-line
                hide-details
                v-model="search"
              ></v-text-field>
            </v-layout>
            <v-data-table
              :headers="headers"
              :items="corporations"
              :loading="loading"
              :options.sync="options"
              :hide-default-footer="true"
              :footer-props="{itemsPerPageOptions}"
              :search="search"
              must-sort
              show-select
              v-model="selectedCorporations"
              @click:row="openCorporationDetail"
              item-key="corporationId"
              class="elevation-1"
            >
              <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
              <template v-for="h in headers" v-slot:[`header.${h.value}`]="{ header }">
                <v-layout :key="h.value">
                  <v-checkbox
                    primary
                    hide-details
                    :input-value="header.filterable"
                    :label="header.text"
                    class="header-checkbox"
                    @click.stop="filterable[h.value] = !filterable[h.value]"
                  ></v-checkbox>
                </v-layout>
              </template>
              <template v-slot:items="props">
                <tr :active="props.selected">
                  <td>
                    <v-checkbox
                      primary
                      hide-details
                      :input-value="props.selected"
                      @click="props.selected = !props.selected"
                    ></v-checkbox>
                  </td>
                </tr>
              </template>
              <template v-slot:[`item.recipientsString`]="{ item }">
                <ul>
                  <li v-for="recipient in item.recipients" :key="recipient.recipientId">
                    {{ recipient.firstName }} {{ recipient.lastName }}, {{ recipient.zip }} {{ recipient.place }}<br />
                  </li>
                </ul>
              </template>
              <template v-slot:[`item.propertiesString`]="{ item }">
                <ul>
                  <li v-for="property in item.properties" :key="property.propertyId">
                    {{ property.street }}, {{ property.zip }} {{ property.place }}<br />
                  </li>
                </ul>
              </template>
              <template v-slot:[`item.dossierPrintHistoryString`]="{ item }">
                <ul>
                  <li v-for="printHistory in item.dossierPrintHistory" :key="printHistory.dossierPrintHistoryId">
                    <nobr>{{ printHistory.year }}: {{ printHistoryDateFormatted(printHistory) }}</nobr>
                  </li>
                </ul>
              </template>
              <template slot="no-data">
                <v-alert :value="true" color="error" icon="warning">
                  {{ $t('table.noData') }}
                </v-alert>
              </template>
            </v-data-table>
          </v-card>
        </v-flex>
      </v-layout>
    </v-slide-y-transition>
  </v-container>
</template>

<script>
import PrintContainer from './PrintContainer.vue'
import { formatDateAndTime } from '@/helpers/formatterHelper'
import { mapActions, mapGetters, mapState } from 'vuex'
import { CORPORATIONS, CORPORATION_DETAIL } from '@/config/options/routes/dossierRoutes'

export default {
  name: CORPORATIONS,
  components: {
    PrintContainer
  },
  data () {
    return {
      loading: false,
      options: {
        sortBy: ['corporationId']
      },
      selectedCorporations: [],
      search: '',
      itemsPerPageOptions: [
        {
          'text': this.$t('common.all'),
          'value': -1
        }
      ],
      corporations: [],
      filterable: {
        'corporationId': true,
        'name': true,
        'recipientsString': true,
        'propertiesString': true,
        'dossierPrintHistoryString': true
      }
    }
  },
  computed: {
    ...mapGetters('printer', ['getYear', 'getSavedFilters']),
    ...mapState('printer', ['year', 'savedFilters']),
    headers () {
      return [
        {
          text: this.$t('common.id'),
          align: 'left',
          value: 'corporationId',
          filterable: this.filterable['corporationId']
        },
        {
          text: this.$t('common.data.name'),
          align: 'left',
          value: 'name',
          filterable: this.filterable['name']
        },
        {
          text: this.$t('dossier.corporations.recipient'),
          align: 'left',
          value: 'recipientsString',
          sortable: false,
          filterable: this.filterable['recipientsString']
        },
        {
          text: this.$t('dossier.corporations.realEstate'),
          align: 'left',
          value: 'propertiesString',
          sortable: false,
          filterable: this.filterable['propertiesString']
        },
        {
          text: this.$t('dossier.corporations.printouts'),
          align: 'left',
          value: 'dossierPrintHistoryString',
          sortable: false,
          filterable: this.filterable['dossierPrintHistoryString']
        }
      ]
    },
    selectedCorporationMappedIDs () {
      return this.selectedCorporations.length > 0 ? this.selectedCorporations.map((obj) => {
        return {
          corporationId: obj.corporationId,
          properties: obj.properties.map(obj => obj.propertyId),
          recipients: obj.recipients.map(obj => obj.recipientId)
        }
      }) : []
    }
  },
  watch: {
    year () {
      this.loadData()
    }
  },
  created () {
    if (this.savedFilters) {
      this.search = this.savedFilters.search
      this.filterable = Object.assign({}, this.savedFilters.filterable)
      this.selectedCorporations = this.savedFilters.selectedCorporations
    }
    this.loadData()
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    ...mapActions('printer', ['setSavedFilters']),
    formatDateAndTimeFunc: formatDateAndTime,
    loadData () {
      this.loading = true
      let that = this
      this.$prestigeDossierCorporation.getAllCorporations(this.year).then(response => {
        that.loading = false
        that.corporations = response.data
        that.corporations.forEach(corporation => {
          corporation.recipientsString = corporation.recipients.length > 0 ? corporation.recipients.map(recipient => {
            return recipient.recipientId + ' ' + recipient.firstName + ' ' + recipient.lastName + ' ' + recipient.zip + ' ' + recipient.place
          }).join(' ') : ''
          corporation.propertiesString = corporation.properties.length > 0 ? corporation.properties.map(property => {
            return property.propertyId + ' ' + property.street + ' ' + property.zip + ' ' + property.place
          }).join(' ') : ''
          corporation.dossierPrintHistoryString = corporation.dossierPrintHistory ? corporation.dossierPrintHistory.map(history => {
            return history.year + ' ' + history.createdOn
          }).join(' ') : ''
        })
      }).catch(error => {
        that.setErrorSnackbar(error)
      })
    },
    printHistoryDateFormatted (printHistory) {
      return this.formatDateAndTimeFunc(printHistory.createdOn)
    },
    downloadFile (file) {
      let fileDownload = require('js-file-download')
      fileDownload(file.data, file.name)
    },
    sentToPrinter () {
      this.loadData()
    },
    openCorporationDetail (corporation) {
      let savedFilters = {
        search: this.search,
        filterable: this.filterable,
        selectedCorporations: this.selectedCorporations
      }
      this.setSavedFilters(savedFilters)
      this.$router.push({ name: CORPORATION_DETAIL, params: { corporationId: corporation.corporationId } })
    }
  }
}
</script>

<style scoped lang="postcss">
/deep/ tr:nth-child(odd) {
  background: #f8f8f8;
}

/deep/ tr {
  cursor: pointer;
}

/deep/ .header-checkbox label {
  font-size: .75rem;
  color: rgba(0,0,0,.6);
}
</style>
