import i18n from '../i18n/i18n'
import moment from 'moment'

const ABACUS_HREF = 'https://abacus.prestige.ch:40001/abastart?mandant=10&program='

export function usePropertyActions () {
  const propertyActions = [
    { title: i18n.t('dashboard.actions.property.title'), icon: 'abacus-logo', action: onProperty },
    { title: i18n.t('dashboard.actions.property.tenantList'), icon: 'abacus-logo', action: onTenantList },
    { title: i18n.t('dashboard.actions.property.sharepoint'), icon: 'mdi-microsoft-sharepoint', action: onSharepoint }
  ]

  function onProperty (item) {
    let url = ABACUS_HREF + 'immo.11&RealEstateNumber=' + item.propertyId
    window.open(url, '_blank')
  }

  function onTenantList (item) {
    let managementEnd = moment().format('YYYY-MM-DD')
    let url = ABACUS_HREF + 'immo.321&Date.Value=' + managementEnd +
    '&RealEstateSelection.SelectionValue=' + item.propertyId + '&Action=Preview'
    window.open(url, '_blank')
  }

  function onSharepoint (item) {
    let url = 'https://prestige89.sharepoint.com/sites/' + item.propertyId + '/Freigegebene%20Dokumente/Forms/AllItems.aspx'
    window.open(url, '_blank')
  }

  return {
    propertyActions,
    onProperty,
    onTenantList,
    onSharepoint
  }
}
