import ConstructionProjects from '../../construction-projects/ConstructionProjects'

export const ConstructionRoutes = [
  {
    path: '/construction-projects/:buildingProjectId?',
    name: 'ConstructionProjects',
    component: ConstructionProjects,
    meta: {
      title: 'constructionProjects',
      requireAuth: true,
      requireRoles: [
        'prestige-tools-reader'
      ]
    }
  }
]
