<template>
  <v-container fluid class="grey lighten-5 pa-2">
    <v-layout class="row pt-0 pl-0 pr-0">
      <v-card-title>{{ $t('navigation.orders') }}</v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
        append-icon="search"
        :label="$t('common.buttons.search')"
        single-line
        hide-details
        v-model="search"
      ></v-text-field>
    </v-layout>
    <v-data-table
      :headers="headers"
      :items="orders"
      :loading="ordersLoading"
      :search="search"
      :single-expand="true"
      :expanded="expandedOrders"
      item-key="orderId"
      must-sort
      :options.sync="options"
      :footer-props="{ itemsPerPageOptions: itemsPerPageOptions, itemsPerPageText: itemsPerPageText }"
      @current-items="closeAllExpanded"
      class="elevation-1"
    >
      <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
      <template v-slot:[`item.amountSum`]="{ item }">
        {{ item.amountInfo }}
      </template>
      <template v-slot:[`item.orderedOn`]="{ item }">
        {{ item.orderedOnFormatted }}
      </template>
      <template v-slot:[`item.emailSentOn`]="{ item }">
        {{ item.emailSentOnFormatted }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon class="mx-0" :disabled="item.emailLoading" :loading="item.emailLoading" :title="$t('heatingOil.orders.sendEmail')" @click.stop="sendEmail(item)">
          <v-icon color="primary">mdi-send</v-icon>
        </v-btn>
        <v-btn icon class="mx-0" :title="$t('heatingOil.orders.downloadExcel')" :disabled="item.excelLoading" :loading="item.excelLoading" @click.stop="downloadExcel(item)">
          <v-icon color="primary">file_download</v-icon>
        </v-btn>
        <v-btn icon class="mx-0" :title="$t('heatingOil.orders.details')" @click.stop="toggleExpander(item)">
          <v-icon v-if="item.expanded" color="primary">remove_circle_outline</v-icon>
          <v-icon v-else color="blue lighten-3">add_circle_outline</v-icon>
        </v-btn>
      </template>
      <template v-slot:expanded-item="{ item }">
        <td colspan="10" class="pl-2 pr-2 bg-white">
          <v-container fluid grid-list-lg>
          <v-layout row>
            <v-flex xs12>
              <v-card-title class="pl-0">{{ $t('heatingOil.orders.positions') }}</v-card-title>
              <v-data-table
                :headers="subHeaders"
                :items="item.expandedOrders"
                item-key="tankId"
                must-sort
                :hide-default-footer="true"
                :loading="expandedLoading"
                :options.sync="subOptions"
                class="elevation-1"
              >
                <template v-slot:[`item.amount`]="{ item }">
                  {{ item.amountFormatted }}
                </template>
                <template slot="no-data">
                  <v-alert :value="true" color="error" icon="warning">
                    {{ $t('table.noData') }}
                  </v-alert>
                </template>
                <template v-slot:[`item.propertyInfo`]="{ item }">
                  [<a href="" @click="openMapProperty(item.propertyId)">{{item.propertyId}}</a>] <span>{{ item.propertyInfo }}</span>
                </template>
              </v-data-table>
            </v-flex>
          </v-layout>
          </v-container>
        </td>
      </template>
      <template slot="no-data">
        <v-alert :value="true" color="error" icon="warning">
          {{ $t('table.noData') }}
        </v-alert>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import * as heatingOilHelper from './helpers/heatingOilHelper'
import { formatDateAndTime } from '@/helpers/formatterHelper'
import { mapActions, mapGetters } from 'vuex'
import { MAP_PROPERTIES } from '@/config/options/routes/mapRoutes'

export default {
  name: 'HeatingOilOrders',
  data () {
    return {
      search: '',
      orders: [],
      ordersLoading: true,
      expandedOrders: [],
      expandedLoading: false,
      tankLevels: [],
      itemsPerPageOptions: [ 10, 25, 50, 100 ],
      options: {
        page: 1,
        sortBy: ['orderedOn'],
        sortDesc: [true]
      },
      subOptions: {
        sortBy: ['orderedOn'],
        sortDesc: [true]
      }
    }
  },
  created () {
    this.loadData()
  },
  computed: {
    ...mapGetters('abacus', ['getTanks', 'getHeatingOilSuppliers']),
    ...mapGetters('heatingOil', ['getTankOrders']),
    headers () {
      return [
        {
          text: this.$t('common.id'),
          align: 'left',
          value: 'orderId'
        },
        {
          text: this.$t('heatingOil.subHeaders.supplier'),
          align: 'left',
          value: 'supplierInfo'
        },
        {
          text: this.$t('heatingOil.basket.orderQuantity'),
          align: 'left',
          value: 'amountSum'
        },
        {
          text: this.$t('common.date.title'),
          align: 'left',
          value: 'orderedOn'
        },
        {
          text: this.$t('common.data.email'),
          align: 'left',
          value: 'emailSentOn'
        },
        {
          text: this.$t('heatingOil.headers.actions'),
          align: 'left',
          sortable: false,
          value: 'actions'
        }
      ]
    },
    subHeaders () {
      return [
        {
          text: this.$t('common.id'),
          align: 'left',
          value: 'orderId'
        },
        {
          text: this.$t('tasks.info.property'),
          align: 'left',
          value: 'propertyInfo'
        },
        {
          text: this.$t('heatingOil.headers.house'),
          align: 'left',
          value: 'houseInfo'
        },
        {
          text: this.$t('heatingOil.basket.tank'),
          align: 'left',
          value: 'tankIdInfo'
        },
        {
          text: this.$t('heatingOil.basket.orderQuantity'),
          align: 'left',
          value: 'amount'
        }
      ]
    },
    itemsPerPageText () {
      return this.$t('common.itemsPerPageText')
    }
  },
  watch: {
    search () {
      this.options.page = 1
    }
  },
  methods: {
    ...mapActions('notifier', ['setSuccessSnackbar', 'setErrorSnackbar']),
    formatDateAndTimeFunc: formatDateAndTime,
    loadData () {
      Promise.all([this.$store.dispatch('abacus/loadTanks'), this.$store.dispatch('heatingOil/loadTankOrders'), this.$store.dispatch('abacus/loadHeatingOilSuppliers')]).then(() => this.loadOrders())
    },
    async loadOrders () {
      try {
        let response = await this.$prestigeHeatingOilOrder.getAllOrders().then(response => { return response })
        this.orders = response.data
        if (this.orders.length) {
          let suppliers = await this.getHeatingOilSuppliers
          for (let order of this.orders) {
            order.orderedOnFormatted = this.formatDateAndTimeFunc(order.orderedOn)
            order.emailSentOnFormatted = this.formatDateAndTimeFunc(order.emailSentOn)
            let supplier = suppliers.find(x => x.addressId.toString() === order.supplierId.toString())
            if (supplier) {
              order.supplierInfo = (supplier.firstName ? supplier.firstName + ' ' : '') + supplier.lastName
            }
            let amountSum = 0
            for (let item of order.items) {
              amountSum += item.amount
            }
            order.amountSum = amountSum
            order.amountInfo = heatingOilHelper.formatVolume(amountSum)
          }
        }
        this.$forceUpdate()
        this.ordersLoading = false
      } catch (error) {
        this.orders = []
        this.ordersLoading = false
        this.setErrorSnackbar(error)
      }
    },
    async toggleExpander (item) {
      try {
        this.resetToggleIcons(item)
        this.expandedOrders = []
        item.expanded = !item.expanded
        if (item.expanded) {
          if (typeof item.expandedOrders === 'undefined') {
            this.expandedLoading = true
            let allTanks = await this.getTanks
            for (let expandedItem of item.items) {
              expandedItem.orderId = item.orderId
              expandedItem.tankIdInfo = '[' + expandedItem.tankId + ']'
              expandedItem.amountFormatted = heatingOilHelper.formatVolume(expandedItem.amount)
              let currentTank = allTanks.find(x => x.id.toString() === expandedItem.tankId.toString())
              if (currentTank) {
                expandedItem.propertyId = currentTank.propertyId
                expandedItem.propertyInfo = currentTank.propertyStreet + ', ' + currentTank.propertyZip + ' ' + currentTank.propertyPlace
                expandedItem.houseInfo = currentTank.houseInfo
              }
              this.expandedOrders.push(expandedItem)
            }
            item.expandedOrders = this.expandedOrders
            this.expandedLoading = false
          } else {
            this.expandedOrders = item.expandedOrders
          }
        } else {
          this.expandedOrders = []
        }
      } catch (error) {
        this.expandedOrders = []
        this.setErrorSnackbar(error)
      }
    },
    resetToggleIcons (item) {
      this.orders.map(resetItem => {
        if (resetItem.orderId !== item.orderId) {
          resetItem.expanded = false
        }
      })
    },
    downloadExcel (item) {
      this.loadIcon(item, 'excelLoading', true)
      const config = { responseType: 'blob' }
      let that = this
      this.$prestigeHeatingOilOrder.generateExcelReport(item.orderId, config).then(response => {
        let file = heatingOilHelper.setFileInfo(response)
        heatingOilHelper.downloadFile(file)
        that.loadIcon(item, 'excelLoading', false)
        return true
      }).catch(error => {
        that.loadIcon(item, 'excelLoading', false)
        that.setErrorSnackbar(error)
      })
    },
    sendEmail (item) {
      this.loadIcon(item, 'emailLoading', true)
      let that = this
      this.$prestigeHeatingOilOrder.sendEmailToSupplier(item.orderId).then(function (response) {
        if (response.status === 201) {
          if (that.expandedOrders.length > 0) {
            that.toggleExpander(item)
          }
          that.loadData()
          that.setSuccessSnackbar(that.$t('tasks.email.successfullySent'))
          that.loadIcon(item, 'emailLoading', false)
        }
      }).catch(error => {
        that.loadIcon(item, 'emailLoading', false)
        that.setErrorSnackbar(error)
      })
    },
    loadIcon (item, icon, loading) {
      item[icon] = loading
      this.$forceUpdate()
    },
    closeAllExpanded () {
      if (this.expandedOrders.length > 0) {
        this.orders.map(resetItem => {
          if (resetItem.expanded) {
            resetItem.expanded = false
          }
        })
        this.expandedOrders = []
      }
    },
    openMapProperty (propertyId) {
      this.$router.push({ name: MAP_PROPERTIES, params: { propertyId: propertyId.toString() } })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="postcss">
/deep/ tr:nth-child(odd) {
  background: #f8f8f8;
}
.bg-white {
  background: white;
}
</style>
