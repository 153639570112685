<template>
  <v-combobox
    v-model="chips"
    :label="label"
    :items="sortedItems"
    :search-input.sync="searchTerm"
    item-text="displayText"
    item-value="displayText"
    :delimiters="[',', ' ', ';']"
    :color="color"
    hide-selected
    multiple
    persistent-hint
    small-chips
    clearable
    @change="delimit"
  >
    <template v-slot:item="{ item }">
      <v-list-item
        :class="matchesSearchTerm(item) ? 'highlighted' : ''"
        @click.stop="select(item)"
      >
        <v-list-item-content>
          <span v-if="item.name" v-html="highlightPhrase(item.name)"></span>
          <span v-html="highlightPhrase(item[type])"></span>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-slot:selection="{ attrs, item }">
      <v-chip
        v-bind="attrs"
        :input-value="selectedValue"
        close
        :color="getColor(item)"
        @click:close="remove(item)"
      >
        <strong>
          <span v-if="item.name">{{ item.name }} (</span><span>{{ item[type] }}</span><span v-if="item.name">)</span>
          <span v-if="!item.email">{{ item }}</span>
        </strong>&nbsp;
      </v-chip>
    </template>
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t('combobox.noResultsMatching') }}<span v-if="searchTerm"> "<strong>{{ searchTerm }}</strong>"</span>. {{ $t('combobox.press') }} <kbd>enter</kbd> {{ $t('combobox.addNew') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-combobox>
</template>

<script>
import { validateEmail } from '@/helpers/validationHelper'
import { soryListByObjectParam } from '@/helpers/globalHelper'
import _cloneDeep from 'lodash.clonedeep'

export default {
  name: 'ComboboxChips',
  props: {
    label: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => []
    },
    selected: {
      type: Array,
      default: () => []
    },
    required: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'email'
    }
  },
  data () {
    return {
      chips: this.selected || [],
      searchTerm: null,
      selectedValue: null
    }
  },
  watch: {
    chips (value) {
      let newMails = []
      if (value && value.length > 0) {
        newMails = value.map(x => x.email || x)
      }
      this.$emit('input', newMails)
    }
  },
  computed: {
    color () {
      return this.required ? 'red' : ''
    },
    sortedItems () {
      return soryListByObjectParam(_cloneDeep(this.items), 'email')
    }
  },
  methods: {
    getColor (value) {
      if (this.type === 'email') {
        let email = value.hasOwnProperty('email') ? value.email : value
        let invalidEmail = email === '' || validateEmail(email) === this.$t('common.errors.invalidEmail')
        if (invalidEmail) {
          return 'red lighten-2'
        }
      }
      return ''
    },
    select (item) {
      this.selectedValue = item
      this.chips.push({ ...item })
      this.searchTerm = ''
    },
    remove (item) {
      this.chips.splice(this.chips.indexOf(item), 1)
      this.chips = [...this.chips]
    },
    matchesSearchTerm (item) {
      return this.searchTerm ? item.displayText.includes(this.searchTerm) : false
    },
    highlightPhrase (text) {
      if (!this.searchTerm) return text
      return text.replace(this.searchTerm, `<strong>${this.searchTerm}</strong>`)
    },
    delimit (value) {
      const emails = value.filter(x => typeof x === 'string')
      if (emails && emails[emails.length - 1]?.includes(';')) {
        const objectEmails = value.filter(x => typeof x === 'object' && x !== null).map(x => x.email)
        const reducer = (a, e) => [...a, ...e.split(/[; ]+/)]
        value.pop()
        let pastedEmails = [...new Set(emails.reduce(reducer, []))]
        value.push(...new Set(pastedEmails.filter(x => !objectEmails.includes(x))))
        this.chips = [...new Set(value)]
      }
    }
  }
}
</script>

<style scoped lang="postcss">
/deep/ .highlighted {
  background: rgba(0, 0, 0, .12);
}
/deep/ .v-select__selections {
  max-height: 150px;
  overflow-y: auto;
  height: 100%;
}
</style>
