<template>
  <p class="ma-0">
    <span class="font-weight-bold">{{ label }}:</span>
    <span v-for="(item, index) in mailsList" :key="type + index">
      {{ item }}<span v-if="index + 1 < mailsList.length">, </span>
    </span>
  </p>
</template>

<script>
export default {
  name: 'MailClientList',
  props: {
    type: {
      type: String,
      default: ''
    },
    mailsList: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    label () {
      return this.$t('ticketingSystem.messages.email.' + this.type)
    }
  }
}
</script>

<style scoped lang="postcss">
</style>
