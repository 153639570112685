import { render, staticRenderFns } from "./ProcessOpenTasks.vue?vue&type=template&id=1f719872&scoped=true&"
import script from "./ProcessOpenTasks.vue?vue&type=script&lang=js&"
export * from "./ProcessOpenTasks.vue?vue&type=script&lang=js&"
import style0 from "./ProcessOpenTasks.vue?vue&type=style&index=0&id=1f719872&scoped=true&lang=postcss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f719872",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {VCard,VCardSubtitle,VCardText,VContainer,VDataTable,VMain})
