<template>
  <div>
    <v-progress-linear indeterminate v-if="loading" color="blue"></v-progress-linear>
    <v-alert type="info" v-else-if="specialities" class="text-pre-wrap">{{ specialities }}</v-alert>
    <v-alert type="success" v-else>
      Keine Spezialitäten definiert
    </v-alert>
  </div>
</template>

<script>
import * as ticketingHelper from '@/features/ticketing/helpers/ticketingHelper'
import { mapActions } from 'vuex'

export default {
  name: 'TerminationSpecialities',
  props: {
    processVariables: {
      type: Array,
      default: () => {}
    }
  },
  data () {
    return {
      loading: true,
      specialities: ''
    }
  },
  watch: {
    'processVariables': {
      handler: 'initProcessVariables',
      immediate: true
    }
  },
  methods: {
    ...mapActions('notifier', ['setSuccessSnackbar', 'setErrorSnackbar']),
    getVariableValue (variableName) {
      return ticketingHelper.getVariableValue(this.processVariables, variableName)
    },
    initProcessVariables () {
      let that = this
      let propertyId = that.getVariableValue('System_Termination_Property')
      if (propertyId) {
        that.loading = true
        this.$abacusProperty.getSpecialitiesForPropertyId(propertyId).then(response => {
          if (response.data) {
            that.specialities = response.data.specialities
            that.loading = false
          }
        }).catch(error => {
          that.loading = false
          that.setErrorSnackbar(error)
        })
      }
    }
  }
}
</script>

<style scoped lang="postcss">
</style>
