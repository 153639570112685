import Vue from 'vue'
import Vuex from 'vuex'
import * as globalHelper from '@/helpers/globalHelper'

Vue.use(Vuex)

const state = {
  realEstateManagers: [],
  realEstateManagerLoading: false,
  realEstateManagerDisplayItems: [],
  tanks: [],
  tanksLoading: true,
  heatingOilSuppliers: [],
  heatingOilSuppliersLoading: false,
  abacusProperties: [],
  propertiesDetails: [],
  addressesDetails: [],
  addressesDetailsExtended: [],
  abacusPropertiesDisplayItems: [],
  abacusPropertiesLoading: false
}

const getters = {
  getRealEstateManagers: state => state.realEstateManagers,
  getRealEstateManagerLoading: state => state.realEstateManagerLoading,
  getRealEstateManagerDisplayItems: state =>
    state.realEstateManagerDisplayItems,
  getTanks: state => state.tanks,
  getTanksLoading: state => state.tanksLoading,
  getHeatingOilSuppliers: state => state.heatingOilSuppliers,
  getHeatingOilSuppliersLoading: state => state.heatingOilSuppliersLoading,
  getAbacusProperties: state => state.abacusProperties,
  getAbacusPropertiesDisplayItems: state => state.abacusPropertiesDisplayItems,
  getPropertiesDetails: state => state.propertiesDetails,
  getAddressesDetails: state => state.addressesDetails,
  getAddressesDetailsExtended: state => state.addressesDetailsExtended,
  getAbacusPropertiesLoading: state => state.abacusPropertiesLoading
}

const actions = {
  setRealEstateManagers ({ commit }, realEstateManagers) {
    commit('SET_REALESTATEMANAGERS', realEstateManagers)
  },
  setRealEstateManagerLoading ({ commit }) {
    commit('SET_REALESTATEMANAGER_LOADING')
  },
  setRealEstateManagerDisplayItems ({ commit }, realEstateManagerDisplayItems) {
    commit('SET_REALESTATEMANAGER_DISPLAY_ITEMS', realEstateManagerDisplayItems)
  },
  setTanks ({ commit }, tanks) {
    commit('SET_TANKS', tanks)
  },
  setTanksLoading ({ commit }) {
    commit('SET_TANKS_LOADING')
  },
  setHeatingOilSuppliers ({ commit }, heatingOilSuppliers) {
    commit('SET_HEATINGOIL_SUPPLIERS', heatingOilSuppliers)
  },
  setHeatingOilSuppliersLoading ({ commit }) {
    commit('SET_HEATINGOIL_SUPPLIERS_LOADING')
  },
  setAbacusProperties ({ commit }, abacusProperties) {
    commit('SET_ABACUS_PROPERTIES', abacusProperties)
  },
  setAbacusPropertiesDisplayItems ({ commit }, displayItems) {
    commit('SET_ABACUS_PROPERTIES_DISPLAY_ITEMS', displayItems)
  },
  setAbacusPropertiesLoading ({ commit }, loading) {
    commit('SET_ABACUS_PROPERTIES_LOADING', loading)
  },
  loadRealEstateManagers ({ state, dispatch }) {
    if (state.realEstateManagers && state.realEstateManagers.length === 0) {
      dispatch('setRealEstateManagerLoading')
      let queryParams = { query: { realEstateManager: true } }
      return Vue.prototype.$abacus.getAllAddressesV2(undefined, queryParams).then(function (response) {
        let realEstateManagers = response.data
        realEstateManagers = globalHelper.sortByFirstNameAndLastName(realEstateManagers)
        let realEstateManagerDisplayItems = []
        realEstateManagers.forEach(item => {
          item.addressId = item.id
          delete item.id
          let displayText = globalHelper.setDisplayText(item)
          let displayListItem = {
            addressId: item.addressId,
            displayText: displayText
          }
          realEstateManagerDisplayItems.push(displayListItem)
        })
        dispatch('setRealEstateManagers', realEstateManagers)
        dispatch('setRealEstateManagerDisplayItems', realEstateManagerDisplayItems)
        return realEstateManagers
      }).catch(error => {
        dispatch('notifier/setErrorSnackbar', { text: error.message }, { root: true })
        dispatch('setRealEstateManagers', [])
        dispatch('setRealEstateManagerDisplayItems', [])
      })
    }
  },
  loadTanks ({ state, dispatch }) {
    if (state.tanks && state.tanks.length === 0) {
      dispatch('setTanksLoading')
      return Vue.prototype.$abacusHeatingOil.getHeatingOilTanks().then(response => {
        let tanks = response.data
        tanks.forEach(tank => {
          tank.propertyInfo = '[' + tank.propertyId + ']' + ' ' + tank.propertyStreet + ', ' + tank.propertyZip + ' ' + tank.propertyPlace
          tank.houseInfo = tank.houseStreet ? '[' + tank.houseId + ']' + ' ' + tank.houseStreet : ''
        })
        dispatch('setTanks', tanks)
      }).catch(error => {
        dispatch('notifier/setErrorSnackbar', { text: error.message }, { root: true })
        dispatch('setTanks', [])
      })
    }
  },
  loadHeatingOilSuppliers ({ state, dispatch }) {
    if (state.heatingOilSuppliers && state.heatingOilSuppliers.length === 0) {
      dispatch('setHeatingOilSuppliersLoading')
      let queryParams = { query: { heatingOilSupplier: true } }
      Vue.prototype.$abacus.getAllAddressesV2(undefined, queryParams).then(function (response) {
        let heatingOilSuppliers = response.data
        heatingOilSuppliers.forEach(item => {
          item.addressId = item.id
          item.displayText = item.firstName + ' ' + item.lastName
          delete item.id
        })
        dispatch('setHeatingOilSuppliers', heatingOilSuppliers)
      }).catch(error => {
        dispatch('notifier/setErrorSnackbar', { text: error.message }, { root: true })
        dispatch('setHeatingOilSuppliers', [])
      })
    }
  },
  loadAbacusProperties ({ state, dispatch }) {
    if (state.abacusProperties && state.abacusProperties.length === 0) {
      dispatch('setAbacusPropertiesLoading', true)
      return Vue.prototype.$abacusProperty.getPropertiesUnderManagement().then(function (response) {
        let displayItems = []
        let properties = response.data
        if (properties && properties.length > 0) {
          properties.sort((a, b) => (a.locationAddress.toLowerCase() > b.locationAddress.toLowerCase())
            ? 1 : (b.locationAddress.toLowerCase() > a.locationAddress.toLowerCase() ? -1 : 0))
          properties.forEach(item => {
            let displayListItem = {
              propertyId: item.propertyId,
              displayText: '[' + item.propertyId + '] ' + item.locationAddress.trim()
            }
            displayItems.push(displayListItem)
          })
        }
        dispatch('setAbacusProperties', properties)
        dispatch('setAbacusPropertiesDisplayItems', displayItems)
        dispatch('setAbacusPropertiesLoading', false)
        return properties
      }).catch(error => {
        dispatch('setAbacusProperties', [])
        dispatch('setAbacusPropertiesDisplayItems', [])
        dispatch('setAbacusPropertiesLoading', false)
        dispatch('notifier/setErrorSnackbar', { text: error.message }, { root: true })
      })
    }
  },
  loadPropertyDetails ({ state, dispatch }, propertyId) {
    if (propertyId) {
      let existingPropertyDetails = state.propertiesDetails.find(x => x.propertyId?.toString() === propertyId?.toString())
      if (!existingPropertyDetails) {
        return Vue.prototype.$abacusProperty.getPropertyById(propertyId).then(response => {
          let propertyDetails = response.data
          if (propertyDetails) {
            dispatch('pushPropertyDetails', propertyDetails)
          }
          return propertyDetails
        }).catch(error => {
          dispatch('notifier/setErrorSnackbar', {text: error.message}, {root: true})
        })
      }
      return existingPropertyDetails
    }
  },
  loadAddressDetails ({ state, dispatch }, addressId) {
    if (addressId) {
      let existingAddressDetails = state.addressesDetails?.find(x => x.id?.toString() === addressId?.toString())
      if (!existingAddressDetails) {
        return Vue.prototype.$abacus.getAddressById(addressId).then(response => {
          let addressDetails = response.data
          if (addressDetails) {
            dispatch('pushAddressDetails', addressDetails)
          }
          return addressDetails
        }).catch(error => {
          dispatch('notifier/setErrorSnackbar', { text: error.message }, { root: true })
        })
      }
      return existingAddressDetails
    }
  },
  loadAddressDetailsExtended ({ state, dispatch }, addressId) {
    if (addressId) {
      let address = state.addressesDetailsExtended.find(x => x.id?.toString() === addressId?.toString())
      if (!address) {
        return Vue.prototype.$abacus.getAddressDetails(addressId).then(response => {
          let addressesDetailsExtended = response.data
          if (addressesDetailsExtended) {
            dispatch('pushAddressDetailsExtended', addressesDetailsExtended)
          }
          return addressesDetailsExtended
        }).catch(error => {
          dispatch('notifier/setErrorSnackbar', { text: error.message }, { root: true })
        })
      } else {
        return address
      }
    }
  },
  pushPropertyDetails ({ commit }, newPropertyDetails) {
    commit('PUSH_PROPERTY_DETAILS', newPropertyDetails)
  },
  pushAddressDetails ({ commit }, addressDetails) {
    commit('PUSH_ADDRESS_DETAILS', addressDetails)
  },
  pushAddressDetailsExtended ({ commit }, addressesDetailsExtended) {
    commit('PUSH_ADDRESS_DETAILS_EXTENDED', addressesDetailsExtended)
  }
}

const mutations = {
  SET_REALESTATEMANAGERS (state, realEstateManagers) {
    state.realEstateManagers = realEstateManagers
    state.realEstateManagerLoading = false
  },
  SET_REALESTATEMANAGER_LOADING (state) {
    state.realEstateManagerLoading = true
  },
  SET_REALESTATEMANAGER_DISPLAY_ITEMS (state, realEstateManagerDisplayItems) {
    state.realEstateManagerDisplayItems = realEstateManagerDisplayItems
  },
  SET_TANKS (state, tanks) {
    state.tanks = tanks
    state.tanksLoading = false
  },
  SET_TANKS_LOADING (state) {
    state.tanksLoading = true
  },
  SET_HEATINGOIL_SUPPLIERS (state, heatingOilSuppliers) {
    state.heatingOilSuppliers = heatingOilSuppliers
    state.heatingOilSuppliersLoading = false
  },
  SET_HEATINGOIL_SUPPLIERS_LOADING (state) {
    state.heatingOilSuppliersLoading = true
  },
  SET_ABACUS_PROPERTIES (state, abacusProperties) {
    Vue.set(state, 'abacusProperties', abacusProperties)
  },
  SET_ABACUS_PROPERTIES_DISPLAY_ITEMS (state, displayItems) {
    Vue.set(state, 'abacusPropertiesDisplayItems', displayItems)
  },
  SET_ABACUS_PROPERTIES_LOADING (state, loading) {
    state.abacusPropertiesLoading = loading
  },
  PUSH_PROPERTY_DETAILS (state, newPropertyDetails) {
    let index = state.propertiesDetails.findIndex(x => x.propertyId === newPropertyDetails.propertyId)
    index = index === -1 ? state.propertiesDetails.length : index
    Vue.set(state.propertiesDetails, index, newPropertyDetails)
  },
  PUSH_ADDRESS_DETAILS (state, newAddressDetails) {
    let index = state.addressesDetails.findIndex(x => x.id === newAddressDetails.id)
    index = index === -1 ? state.addressesDetails.length : index
    Vue.set(state.addressesDetails, index, newAddressDetails)
  },
  PUSH_ADDRESS_DETAILS_EXTENDED (state, newAddressDetails) {
    let index = state.addressesDetailsExtended.findIndex(x => x.id === newAddressDetails.id)
    index = index === -1 ? state.addressesDetailsExtended.length : index
    Vue.set(state.addressesDetailsExtended, index, newAddressDetails)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
