<template>
  <v-slide-y-transition mode="out-in">
    <v-card class="pl-2 pr-2 pb-2 mt-3 grey lighten-5">
      <v-card-title class="pl-1 pb-0">
        <v-icon class="pr-2">mdi-database-import</v-icon>{{ $t('navigation.constructionProjects') }}
      </v-card-title>
      <v-layout wrap class="row pa-2">
        <v-flex class="pl-2 pr-1 mt-2">
            <v-card height="100%">
              <v-container fluid>
                <div class="inline float-left mb-2">
                  <v-btn class="primary ma-2" :loading="constructionProjectLoading" :disabled="constructionProjectLoading" @click="importBuildingProjects()">
                      <v-icon class="pr-2">mdi-database-plus</v-icon> {{ $t('navigation.constructionProjects') }}
                  </v-btn>
                </div>
                <div class="inline float-left mb-2">
                  <v-btn class="primary ma-2" :loading="propertiesLoading" :disabled="propertiesLoading" @click="importProperties()">
                      <v-icon class="pr-2">mdi-database-plus</v-icon> {{ $t('common.properties') }}
                  </v-btn>
                </div>
              </v-container>
            </v-card>
        </v-flex>
      </v-layout>
    </v-card>
  </v-slide-y-transition>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ConstructionProjectImport',
  data () {
    return {
      constructionProjectLoading: false,
      propertiesLoading: false
    }
  },
  components: {
  },
  methods: {
    ...mapActions('notifier', ['setSuccessSnackbar', 'setErrorSnackbar']),
    importBuildingProjects () {
      let that = this
      that.constructionProjectLoading = true
      this.$prestigeConstructionProjects.importBuildingProjects().then(response => {
        if (that.responseSuccess(response.status)) {
          that.constructionProjectLoading = false
          that.triggerSuccessMessage()
        }
      }).catch(error => {
        that.constructionProjectLoading = false
        that.setErrorSnackbar(error)
      })
    },
    importProperties () {
      let that = this
      that.propertiesLoading = true
      this.$prestigeConstructionProjects.importProperties().then(response => {
        if (that.responseSuccess(response.status)) {
          that.propertiesLoading = false
          that.triggerSuccessMessage()
        }
      }).catch(error => {
        that.propertiesLoading = false
        that.setErrorSnackbar(error)
      })
    },
    triggerSuccessMessage () {
      return this.setSuccessSnackbar(this.$t('dataImport.success'))
    },
    responseSuccess (status) {
      return status === 200 || status === 204
    }
  }
}
</script>

<style scoped>
</style>
