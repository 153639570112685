import gql from 'graphql-tag'

const SEARCH_PROPERTY_TICKETING_TICKETS = gql`
  query searchTicketingTickets($query: SearchTicketingTicketsQuery!) {
    searchTicketingTickets(query: $query) {
      items {
        ticketId
        processDefinitionName
        subject
        state
        startedOn
        finishedOn
      }
      totalItems
    }
  }
`

export { SEARCH_PROPERTY_TICKETING_TICKETS }
