import AbacusEnableRentalCase from '../abacus-renting/AbacusEnableRentalCase'
import AbacusDisableRentalCase from '../abacus-renting/AbacusDisableRentalCase'
import AbacusRentalFormList from '../abacus-renting/AbacusRentalFormList'

import AbacusTicketingStart from '../abacus-ticketing/AbacusTicketingStart'
import AbacusTicketingUpdate from '../abacus-ticketing/AbacusTicketingUpdate'
import AbacusTicketingComplete from '../abacus-ticketing/AbacusTicketingComplete'

export const AbacusRoutes = [
  {
    path: '/abacus/rental-case/enable/:rentalContractId',
    name: 'AbacusEnableRentalCase',
    component: AbacusEnableRentalCase,
    meta: {
      abacus: true,
      full: true,
      title: 'abacusEnableRentalCase',
      requireAuth: true,
      requireRoles: [
        'prestige-tools-writer'
      ]
    }
  },
  {
    path: '/abacus/rental-case/disable/:rentalContractId',
    name: 'AbacusDisableRentalCase',
    component: AbacusDisableRentalCase,
    meta: {
      abacus: true,
      full: true,
      title: 'abacusDisableRentalCase',
      requireAuth: true,
      requireRoles: [
        'prestige-tools-writer'
      ]
    }
  },
  {
    path: '/abacus/rental-case/:rentalContractId',
    name: 'AbacusRentalFormList',
    component: AbacusRentalFormList,
    meta: {
      abacus: true,
      full: true,
      title: 'abacusRentalFormList',
      requireAuth: true,
      requireRoles: [
        'prestige-tools-reader'
      ]
    }
  },
  {
    path: '/abacus/ticketing/:processDefinitionKey/:externalTicketId/create',
    name: 'AbacusTicketingStart',
    component: AbacusTicketingStart,
    meta: {
      abacus: true,
      full: true,
      title: 'abacusTicketingStart',
      requireAuth: true,
      requireRoles: [
        'prestige-tools-writer'
      ]
    }
  },
  {
    path: '/abacus/ticketing/:processDefinitionKey/:externalTicketId/:activityId/update',
    name: 'AbacusTicketingUpdate',
    component: AbacusTicketingUpdate,
    meta: {
      abacus: true,
      full: true,
      title: 'abacusTicketingUpdate',
      requireAuth: true,
      requireRoles: [
        'prestige-tools-writer'
      ]
    }
  },
  {
    path: '/abacus/ticketing/:processDefinitionKey/:externalTicketId/:activityId/complete',
    name: 'AbacusTicketingComplete',
    component: AbacusTicketingComplete,
    meta: {
      abacus: true,
      full: true,
      title: 'abacusTicketingComplete',
      requireAuth: true,
      requireRoles: [
        'prestige-tools-writer'
      ]
    }
  }
]
