import Vue from 'vue'
import Vuex from 'vuex'
import abacus from './abacus'
import captcha from './captcha'
import heatingOil from './heatingOil'
import notifier from './notifier'
import printer from './printer'
import ticketingSystem from './ticketingSystem'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    abacus,
    heatingOil,
    notifier,
    printer,
    ticketingSystem,
    captcha
  }
})

export default store
