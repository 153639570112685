<template>
  <div class="tiptap-wrapper" :class="{'err': editorValid}">
    <TipTapExtensionsMenu v-if="editor" :editor="editor"/>
    <EditorContent :editor="editor" />
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import Link from '@tiptap/extension-link'
import TipTapExtensionsMenu from './TipTapExtensionsMenu'
import TextStyle from '@tiptap/extension-text-style'
import { Color } from '@tiptap/extension-color'

export default {
  components: {
    EditorContent,
    TipTapExtensionsMenu
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      editor: null
    }
  },
  watch: {
    value (value) {
      const isSame = this.editor.getHTML() === value.editorHtml
      if (isSame) {
        return
      }
      this.editor.commands.setContent(value.editorHtml, false)
    }
  },
  mounted () {
    this.editor = new Editor({
      content: this.value.editorHtml,
      extensions: [
        Underline,
        TextStyle,
        Color,
        Link.configure({
          autolink: true,
          linkOnPaste: true
        }),
        StarterKit.configure({
          heading: {
            levels: []
          },
          blockquote: {
            HTMLAttributes: {
              style: 'border-left: 2px solid rgba(13,13,13,.1); padding: 15px'
            }
          }
        })
      ],
      autofocus: 'start',
      onUpdate: () => {
        const editorContent = {
          editorHtml: this.editor.getHTML(),
          editorText: this.editor.getText()
        }
        this.$emit('input', editorContent)
      }
    })
  },
  computed: {
    editorValid () {
      this.$emit('editorValid', this.editor?.isEmpty)
      return this.editor?.isEmpty
    }
  },
  beforeDestroy () {
    this.editor.destroy()
  }
}
</script>

<style scoped lang="postcss">
/deep/ .ProseMirror {
  min-height: 345px;
}
.tiptap-wrapper {
  border-bottom: 1px solid;
}
.err {
  border-bottom: 1px solid red;
}
/deep/ .ProseMirror:focus-visible {
  outline: none;
}
/deep/ p {
  font-size: 16px;
  color: rgba(0,0,0,.87);
  white-space: pre-line;
}
</style>
