<template>
  <v-autocomplete
    ref="realEstateManager"
    v-model="mutableForm.value"
    :label="$t('common.buttons.search')"
    :loading="realEstateManagerLoading"
    :search-input.sync="search"
    :disabled="readOnly"
    cache-items
    single-line
    return-object
    :items="displayItems"
    item-text="displayText"
    item-value="addressId"
  ></v-autocomplete>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import _isEmpty from 'lodash.isempty'
import * as globalHelper from '@/helpers/globalHelper'

export default {
  name: 'RealEstateManager',
  props: {
    form: {
      type: Object,
      default: () => {}
    },
    editorUser: {
      type: Object,
      default: () => {}
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      search: '',
      displayItems: [],
      displayNewEditor: {}
    }
  },
  async created () {
    await this.$store.dispatch('abacus/loadRealEstateManagers')
    this.displayItems = this.realEstateManagerDisplayItems
    if (!_isEmpty(this.editorUser)) {
      let isRealEstateManager = this.realEstateManagerDisplayItems.find(x => x.addressId.toString() === this.editorUser.addressId.toString())
      if (!isRealEstateManager) {
        this.setNewEditorDisplayItem()
        this.displayItems.unshift(this.displayNewEditor)
      }
    }
  },
  computed: {
    ...mapState('abacus', ['realEstateManagers', 'realEstateManagerDisplayItems', 'realEstateManagerLoading']),
    ...mapGetters('abacus', ['getRealEstateManagers', 'getRealEstateManagerDisplayItems', 'getRealEstateManagerLoading']),
    mutableForm () {
      return this.form
    }
  },
  methods: {
    ...mapActions('abacus', ['setRealEstateManagers', 'setRealEstateManagerLoading']),
    setNewEditorDisplayItem () {
      this.displayNewEditor = {
        addressId: this.editorUser.addressId,
        displayText: globalHelper.setDisplayText(this.editorUser)
      }
    }
  }
}
</script>
