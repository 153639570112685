const rentalApartment = 'RENTAL_APARTMENT'
const sameAsFirstResident = 'SAME_AS_FIRST_RESIDENT'
const homeOwnership = 'HOME_OWNERSHIP'
const parents = 'PARENTS'

export const currentApartmentStatuses =
{
  rentalApartment,
  sameAsFirstResident,
  homeOwnership,
  parents,
  options: [
    rentalApartment,
    homeOwnership,
    parents
  ]
}
