<template>
  <v-expansion-panel v-if="serviceChargeStatements && serviceChargeStatements.length > 0">
    <v-expansion-panel-header class="pa-2">
      <v-card-subtitle class="pa-0 ma-0 font-weight-bold">
        {{ $t('ticketingSystem.processes.termination.statements.chargeStatements') }}
      </v-card-subtitle>
      <template v-slot:actions>
        <v-icon color="brown lighten-2">mdi-information-outline</v-icon>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="pa-0">
      <v-data-table
        :headers="statementsHeaders"
        :items="serviceChargeStatements"
        :footer-props="{ itemsPerPageOptions: itemsPerPageOptions, itemsPerPageText: itemsPerPageText }"
      >
        <template v-slot:[`item.periodInterval`]="{ item }">
          <td>
            {{ formatDateFunc(item.periodFrom) }} - {{ formatDateFunc(item.periodTo) }}
          </td>
        </template>
        <template v-slot:[`item.onAccount`]="{ item }">
          <td>{{ item.onAccount }}.00</td>
        </template>
        <template slot="no-data">
          <v-alert :value="true" color="error" icon="warning">
            {{ $t('table.noData') }}
          </v-alert>
        </template>
      </v-data-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapActions } from 'vuex'
import { formatDate } from '@/helpers/formatterHelper'

export default {
  name: 'ServiceChargeStatements',
  props: {
    serviceChargeStatements: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
    }
  },
  computed: {
    statementsHeaders () {
      return [
        {
          text: this.$t('ticketingSystem.processes.termination.statements.period'),
          align: 'left',
          value: 'periodInterval'
        },
        {
          text: this.$t('ticketingSystem.processes.termination.statements.costs'),
          align: 'left',
          value: 'incidentalRentalCosts'
        },
        {
          text: this.$t('ticketingSystem.processes.termination.statements.account'),
          align: 'left',
          value: 'onAccount'
        },
        {
          text: this.$t('ticketingSystem.processes.termination.statements.balance'),
          align: 'left',
          value: 'balance'
        }
      ]
    },
    itemsPerPageOptions () {
      return [
        5,
        10,
        15,
        {
          'text': this.$t('common.all'),
          'value': -1
        }
      ]
    },
    itemsPerPageText () {
      return this.$t('common.itemsPerPageText')
    }
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    formatDateFunc: formatDate
  }
}
</script>
<style scoped lang="postcss">
/deep/ .v-expansion-panel-content__wrap {
  padding: 8px;
}
</style>
