<template>
  <v-list-item-content>
    <template v-if="$apollo.loading">
      {{ $t('tasks.info.ticket') }}: <v-progress-circular color="blue" indeterminate></v-progress-circular>
    </template>
    <div v-else-if="ticketingTicket">
      {{ $t('tasks.info.ticket') }}:
      [<router-link :to="{ name: TICKETING_PROCESS_DETAILS, params: { processInstanceId: processInstanceId}}" class="mt-1 mr-2 w-m-75">
        <span class="ma-0 truncate-one-line process-instance-id">
          {{ processInstanceId }}
        </span>
      </router-link>] <StateIcon :state="ticketingTicket.state" />
      <span class="process-label">{{ ticketingTicket.processDefinitionName }} &gt; {{ ticketingTicket.subject }}</span>
    </div>
  </v-list-item-content>
</template>

<script>
import { TASK_TICKETING_TICKET } from '../graphql-apollo/taskTicketingTicket.gql'
import { TICKETING_PROCESS_DETAILS } from '@/config/options/routes/ticketingRoutes'
import StateIcon from '../../../components/StateIcon'
import { mapActions } from 'vuex'

export default {
  name: 'ProcessInstanceInfo',
  components: {StateIcon},
  props: {
    processInstanceId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      TICKETING_PROCESS_DETAILS: TICKETING_PROCESS_DETAILS
    }
  },
  apollo: {
    ticketingTicket: {
      query: TASK_TICKETING_TICKET,
      skip () {
        return !this.processInstanceId
      },
      error (error) {
        this.setErrorSnackbar(error.message)
      },
      variables () {
        return {
          ticketId: this.processInstanceId
        }
      }
    }
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar'])
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="postcss">
.truncate-one-line {
  display: -webkit-inline-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.process-instance-id {
  color: #1976d2;
  text-decoration: none;
  vertical-align: middle;
  max-width: 60px;
  padding-left: 2px;
}
</style>
