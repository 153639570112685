import gql from 'graphql-tag'

const TICKETING_TASKS = gql`
  query tasks (
    $propertyId: Int
    $tenantId: Int
    $caretakerId: Int
    $assignedUserId: String
    $candidateGroup: String
    $userId: String
    $processDefinitionKey: String
    $priorities: [Int]
    $includeAssigned: Boolean = true
    $includeFollowUpInFuture: Boolean = true
    $page: Int = 0
    $size: Int = 10
  ) {
    ticketingTasks(query: {
      propertyId: $propertyId
      tenantId: $tenantId
      caretakerId: $caretakerId
      assignedUserId: $assignedUserId
      candidateGroup: $candidateGroup
      userId: $userId
      processDefinitionKey: $processDefinitionKey
      priorities: $priorities
      includeAssigned: $includeAssigned
      includeFollowUpInFuture: $includeFollowUpInFuture
      page: $page
      size: $size
    }) {
        items {
        taskId
        parentTaskId
        name
        startedOn
        dueDate
        priority
        assignee {
          userId
        }
        ticket {
          subject
          processDefinitionName
          hasUnreadMail
        }
      }
      totalItems
    }
  }
`

export { TICKETING_TASKS }
