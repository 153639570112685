<template>
  <span>{{ name }}</span>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'UserName',
  components: {
  },
  props: {
    user: {
      type: Object
    },
    // TODO remove, once this information is returned from graphql
    userId: {
      type: String
    }
  },
  data () {
    return {
      userName: ''
    }
  },
  created () {
    this.loadTicketingUsers()
  },
  computed: {
    ...mapState('ticketingSystem', ['ticketingUsers']),
    ...mapState('abacus', ['addressesDetails']),
    name () {
      // TODO remove, once this information is returned from graphql
      let userId = this.userId
      if (this.user && this.user.userId) {
        userId = this.user.userId
      }
      if (userId === 'bot') {
        return 'Bot'
      } else if (userId === 'rentingform') {
        return this.$t('ticketingSystem.users.rentingForm')
      } else if (userId && userId !== 'admin' && userId !== 'assignToMe') {
        if (this.ticketingUsers.length > 0) {
          let userExists = this.ticketingUsers.find(x => x.userId.toString() === userId)
          if (userExists) {
            return '[' + userExists.userId + '] ' + userExists.name
          } else {
            this.loadAddressDetails(userId)
            let userDetails = this.addressesDetails.find(x => x.id.toString() === userId.toString())
            if (userDetails) {
              return '[' + userDetails.id + '] ' + userDetails.firstName + ' ' + userDetails.lastName
            }
          }
        }
      }
      return ''
    }
  },
  methods: {
    ...mapActions('abacus', ['loadAddressDetails']),
    ...mapActions('ticketingSystem', ['loadTicketingUsers'])
  }
}

</script>
