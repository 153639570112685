export default {
  abacus: {
    ticketing: {
      common: {
        openTicket: 'Open ticket',
        missingInput: 'Missing parameter: ',
        taskNotFoundException: 'Couldn\'t find task',
        ticketNotFoundException: 'Couldn\'t find ticket'
      },
      complete: {
        success: 'Task completed',
        failure: 'Task couldn\'t get completed: '
      },
      start: {
        success: 'The ticket got successfully created',
        ticketAlreadyExists: 'A ticket with the specific type and id already exists',
        failure: 'Ticket couldn\'t get created: '
      },
      update: {
        success: 'Task updated',
        failure: 'Task couldn\'t get updated: '
      }
    }
  },
  admin: {
    search: {
      reindexAbacusAddresses: 'Reindex Abacus addresses',
      reindexAbacusAddressesDone: 'Number of addresses in queue: ',
      reindexRentingAddresses: 'Reindex Renting addresses',
      reindexRentingAddressesDone: 'Number of addresses in queue: ',
      reindexTickets: 'Reindex tickets',
      reindexTicketsDone: 'Number of tickets in queue: '
    }
  },
  autocomplete: {
    hintCaretaker: '@h Caretaker',
    hintChars: 'with at least 3 characters [a-z] accordingly.',
    hintClient: '@e Client',
    hintCraftsman: '@a Craftsman',
    hintProperties: '@i Properties',
    hintObjects: '@o Objects',
    hintSearchBy: 'Search by:',
    hintPartner: ' @p Partner',
    hintTenant: ' @m Tenant',
    hintUser: '@u Ticketing user'
  },
  bpmnDiagram: {
    actions: {
      exitFullscreen: 'Exit fullscreen',
      fitToViewport: 'Fit to viewport',
      openFullscreen: 'Open fullscreen',
      zoomIn: 'Zoom in',
      zoomOut: 'Zoot out'
    },
    legend: {
      title: 'Legend: color of tasks',
      status: {
        activeCompleted: 'Finished (opened)',
        activeOpened: 'To do (opened)',
        completed: 'Finished',
        opened: 'To do'
      }
    },
    messages: {
      noDiagram: 'The diagram does not exist.'
    }
  },
  combobox: {
    addNew: 'to add a new one',
    noResultsMatching: 'No results matching',
    press: 'Press'
  },
  common: {
    address: 'Address',
    addressFields: {
      place: 'Place',
      street: 'Street',
      zip: 'Zip'
    },
    all: 'All',
    buttons: {
      add: 'Add',
      backToList: 'Back to List',
      cancel: 'Cancel',
      close: 'Close',
      confirm: 'Confirm',
      copy: 'Copy',
      copyEmail: 'Copy email',
      delete: 'Delete',
      download: 'Download',
      edit: 'Edit',
      save: 'Save',
      search: 'Search',
      select: 'Select',
      send: 'Send',
      open: 'Open',
      preview: 'Preview',
      rename: 'Rename'
    },
    candidateGroups: {
      bewirtschaftung: 'Bewirtschaftung',
      buchhaltung: 'Accounting',
      general: 'Customer service',
      innendienst: 'Backoffice',
      sachbearbeitung: 'Clerk'
    },
    caretakers: 'Caretakers',
    clients: 'Clients',
    craftsmen: 'Craftsmen',
    contractStartDate: 'Contract Start Date',
    contractEndDate: 'Contract End Date',
    data: {
      email: 'Email',
      mobile: 'Mobile',
      name: 'Name',
      tel: 'Tel',
      telPhone: 'Phone',
      url: 'Url'
    },
    date: {
      customDate: 'Custom date',
      creationDate: 'Creation Date',
      createdOn: 'Created on',
      dayAfterTomorrow: 'Day after tomorrow',
      dueOn: 'Due on',
      finishedOn: 'Finished on',
      followUpOn: 'Follow-up on',
      lastMonth: 'Last month',
      lastYear: 'Last year',
      title: 'Date',
      today: 'Today',
      tomorrow: 'Tomorrow',
      formatHint: 'Format: DD.MM.YYYY'
    },
    dialogs: {
      removeFile: {
        title: 'DELETE FILE',
        question: 'Are you sure you want to delete this file?'
      }
    },
    errors: {
      dataNotLoaded: 'Data could not be loaded: ',
      error401: 'Login is required to access this resource.',
      error403: 'Missing permission to access this resource.',
      error404: 'This resource is not found.',
      error503: 'This service is not available.',
      invalidDate: 'Invalid date',
      invalidEmail: 'Invalid email',
      required: 'Required field'
    },
    id: 'ID',
    itemsPerPageText: 'Items per page:',
    languages: {
      de: 'German',
      en: 'English'
    },
    mapAddressTypes: {
      BUILDER: 'Owner',
      ARCHITECT: 'Architect',
      GENERAL_CONTRACTOR: 'GU'
    },
    messages: {
      confirmDelete: 'Are you sure you want to delete the task?',
      failed: 'Failed',
      saveChanges: 'Please save the changes.',
      successfullyCopied: 'Data was successfully copied.',
      successfullyDeleted: 'Successfully deleted.',
      successfullyUpdated: 'Data was successfully updated.'
    },
    noTitle: 'No title defined',
    objectFields: {
      floor: 'Floor'
    },
    option: {
      name: 'Name',
      value: 'Value'
    },
    options: {
      yes: 'Yes',
      no: 'No'
    },
    or: 'or',
    properties: 'Properties',
    propertyObjects: 'Objects',
    objects: 'Objects',
    partners: 'Partners',
    tenants: 'Tenants',
    realEstateManager: 'Real Estate Manager',
    salutation: 'Salutation',
    salutationOptions: {
      'MR': 'Mr',
      'MRS': 'Mrs'
    },
    tickets: 'Tickets'
  },
  constructionProjects: {
    between: 'between property and construction project:',
    constructionStart: 'Start of construction',
    flagProperty: 'Flag property',
    ignoreProperty: 'Ignore property',
    info: 'Construction Project Info',
    inThePast: 'is in the past',
    latest: 'Latest construction project:',
    locationAmbiguous: 'Location Ambiguous',
    maxDistance: 'Max Distance',
    meter: 'Meter',
    planningStage: 'Planning stage',
    showIgnored: 'Show ignored',
    status: {
      title: 'Status',
      UNDEFINED: 'Not defined',
      FLAG: 'Flagged',
      IGNORE: 'Ignored'
    },
    title: 'Construction Projects',
    updated: 'updated'
  },
  dashboard: {
    actions: {
      address: 'Address A11',
      newTask: 'New order',
      newTicket: 'New Ticket',
      termination: 'Termination',
      offlineRentalApplication: 'Application (Offline)',
      object: {
        contract: 'Contract',
        title: 'Object'
      },
      property: {
        title: 'Property',
        tenantList: 'Tenant List',
        sharepoint: 'Sharepoint'
      },
      tenant: {
        title: 'Tenant',
        accontStatementTenant: 'Account Statement Tenant'
      }
    },
    abacus: 'Abacus',
    all: 'All',
    blockedProperties: 'Blocked properties',
    caretaker: 'Caretaker',
    craftsman: 'Craftsman',
    craftsmen: 'Craftsmen',
    clearForm: 'Clear form',
    client: 'Client',
    form: {
      addressId: 'Address ID',
      email: 'Email',
      integerNumber: 'Please enter a valid address id',
      name: 'Name',
      phone: 'Phone'
    },
    lookup: 'Dashboard lookup',
    matchingAddresses: 'Matching Addresses',
    partner: 'Partner of',
    person: 'Person',
    personTickets: {
      title: 'Tickets Linked to Person'
    },
    property: 'Property',
    propertyPartners: 'Property partners',
    propertyTickets: {
      title: 'Tickets Linked to Property'
    },
    sublet: 'Sublet',
    taskList: {
      title: 'Linked Orders',
      subject: 'Subject',
      description: 'Description',
      createdOn: 'Created'
    },
    tenant: 'Tenant',
    priorityProperties: 'Priority properties',
    realEstateManager: 'Real Estate Manager',
    relatedProperties: 'Related properties',
    relatedObjects: 'Related objects',
    rentingForm: 'Renting form'
  },
  dataImport: {
    abacus: {
      addresses: 'Addresses',
      properties: 'Properties',
      blockedCraftsmen: 'Blocked Craftsmen',
      deleted: 'Deleted',
      fromTo: 'From - To',
      inserted: 'Inserted',
      job: 'Job',
      priorityCraftsmen: 'Priority Craftsmen',
      rentalContractPartner: 'Rental Contract Partners',
      rentComponents: 'Rent Components',
      serviceChargeStatements: 'Service Charge Statements',
      status: 'Status',
      tanks: 'Tanks',
      rentalContracts: 'Rental Contracts',
      sublets: 'Sublets',
      partner: 'Partner',
      clients: 'Clients',
      updated: 'Updated'
    },
    dossier: {
      fileShare: 'File Share'
    },
    immoSparrow: {
      marketRents: 'Market rents',
      title: 'ImmoSparrow',
    },
    silentsoft: {
      title: 'Silentsoft',
      tankLevel: 'Silentsoft Tank Level'
    },
    success: 'Data was successfully imported.'
  },
  dossier: {
    corporations: {
      recipientDocuments: 'Corporation Documents',
      dossierDocuments: 'Dossier Documents',
      dossierPrint: 'Print Dossier',
      pdf: 'PDF',
      print: 'Print',
      printouts: 'Printouts',
      propertyDocuments: 'Property Documents',
      property: 'Property',
      properties: 'Properties',
      realEstate: 'Real Estate',
      recipient: 'Recipient',
      sentToPrinter: 'Sent to printer',
      noPrintHistory: 'No print history',
      output: 'Output',
      selectDocTypes: 'Everything',
      deselectDocTypes: 'Nothing',
      endOfMandate: 'End of mandate'
    },
    documentTypeConfiguration: {
      addDocumentType: {
        title: 'Add Document Type',
        titleEdit: 'Edit Document Type',
        key: 'Key',
        numberOfCopiesKey: 'Number of copies (Abacus Key)',
        paperTray: {
          options: {
            DEFAULT: 'Default',
            LOGO: 'Logo',
            LOGO_FOLLOWING_PAGE: 'Logo following page',
            COVER_SHEET_YELLOW: 'Cover sheet yellow',
            COVER_SHEET_BLUE: 'Cover sheet blue',
            ADDITIONAL: 'Additional'
          },
          title: 'Paper tray'
        },
        source: {
          options: {
            fileShare: 'Azure Storage',
            dynamicallyCreated: 'Dynamically created'
          },
          title: 'Source'
        }
      },
      title: 'Document type - Configuration',
      sections: {
        recipient: {
          title: 'For each recipient'
        },
        property: {
          title: 'For each property'
        }
      },
      dialog: {
        delete: {
          title: 'DELETE DOCUMENT TYPE',
          question: 'Are you sure you want to delete this document type?'
        }
      }
    },
    printerConfiguration: {
      availablePrinter: 'Printer is available. Job Count:',
      config: {
        configuration: 'Configuration',
        invalid: 'Invalid configuration',
        mediaSources: 'Media Sources',
        missing: 'Configuration is missing',
        saveConfiguration: 'Please save the configuration before selecting paper trays'
      },
      currentPrinter: 'Current printer - Settings',
      dialog: {
        delete: {
          title: 'DELETE PRINTOUT',
          question: 'Are you sure you want to delete this dossier history printout?'
        }
      },
      noConfiguration: 'No configuration available yet',
      settingsIncomplete: 'Printer available, but settings incomplete. Please check configuration.',
      status: 'Status',
      statusUnavailable: 'Printer not available. Please check connection.',
      statusUnknown: 'Printer not available. Please check configuration.',
      subject: 'Subject',
      title: 'Printer Configuration'
    },
    printerQueue: {
      mediaSource: 'Media Source',
      news: 'News',
      state: 'State',
      stateReason: 'State reason'
    },
    recipients: {
      properties: 'Properties',
      recipientDetail: {
        document: 'Document',
        documentsPreselected: 'Printed documents are preselected.',
        doesNotExist: 'Recipient does not exist.',
        go: 'Go',
        version: 'Version',
        number: 'Number',
        noDocuments: 'No Documents',
        printed: 'Printed',
        radioSelection: {
          all: 'All documents',
          onlyNew: 'Only new version',
          nothing: 'Nothing',
          custom: 'Custom'
        },
        selection: 'Selection'
      }
    },
    successfullyPrinted: 'Document has been successfully printed.',
    toolbar: {
      checkConfig: 'Check config',
      queue: 'Queue'
    }
  },
  feedback: {
    alerts: {
      dissatisfied: 'Thank you for your feedback. We regret that you have not been satisfied with our service and we are trying to improve it.',
      enjoyed: 'Thank you for your feedback. We are very pleased that you enjoyed our service.',
      invalidLink: 'Unfortunately the link is no longer valid.',
      INVALID_INPUT: 'Unfortunately you have entered invalid id or token.',
      FEEDBACK_ALREADY_SUBMITTED: 'You have already subbmited this feedback.',
      success: 'Your feedback has been saved successfully.',
      submitError: 'Your feedback could not be submitted. Please try again later.'
    },
    comment: 'Comment',
    evaluate: 'evaluate',
    fillRating: 'Please fill out the rating.',
    from: 'from',
    questions: {
      craftsman: 'How satisfied are you with the contracted craftsman?',
      jobDone: 'Has the job been done? If not, has an appointment already been made?',
      satisfied: 'How satisfied are you with the way the work has been carried out?',
      serviceProvider: 'How satisfied are you with the service provided by Prestige Immobilien AG?'
    },
    info: {
      contactChannels: 'contact channels',
      giveFeedback: 'Feel free to give us your feedback about our others',
      notify: 'notify'
    },
    imprint: 'Imprint',
    list: {
      answered: 'Answered',
      assessment: 'Assessment implementation',
      comment: 'Comment',
      clearDates: 'Clear Dates',
      feedbacks: 'Feedbacks',
      feedbackExcel: 'Feedback Excel',
      jobDone: 'Job done',
      rating: 'Rating Prestige Immobilien AG',
      opened: 'Opened',
      reference: 'Reference',
      requested: 'Requested',
      reviewCraftsmen: 'Review craftsmen',
      scoreCarftsman: 'Score Craftsman',
      scoreExecution: 'Score Execution',
      scorePrestige: 'Score Prestige',
      submitted: 'Submitted',
      type: 'Type'
    },
    serviceOrder: 'Below you can find our service related to the order',
    title: 'Write feedback'
  },
  heatingOil: {
    showInactive: 'Show inactive',
    headers: {
      actions: 'Actions',
      house: 'House',
      lastOrder: 'Last order',
      lastUpdate: 'Last update',
      level: 'Level',
      revision: 'Revision',
      shoppingCart: 'Shopping cart',
      stockVolume: 'Stock / Tank volume'
    },
    subHeaders: {
      amount: 'Amount',
      date: 'Date',
      supplier: 'Supplier'
    },
    tankLevel: {
      fillingLevelDialog: {
        addedToBasket: 'The order is added to the basket.',
        hint: 'Liter',
        remainingAmount: 'Remaining amount',
        statusAdded: 'Status added',
        title: 'Update filling level',
        validAmount: 'Please enter a valid amount'
      },
      shoppingCartDialog: {
        amountLabel: 'Amount (l)',
        defaultSupplier: 'Default Supplier',
        addTitle: 'Add order',
        editTitle: 'Edit order',
        messages: {
          lastOrder: 'Last order was at',
          lastOrderQuestion: 'Are you sure you wish to order again?',
          successfullyEdited: 'The order has been successfully edited!'
        },
        supplier: 'Supplier'
      },
      source: {
        SILENTSOFT: 'SilentSoft',
        MANUAL: 'Manual'
      }
    },
    basket: {
      afterFilling: 'After filling',
      created: 'Created',
      dialog: {
        delete: {
          title: 'DELETE ITEM FROM BASKET',
          question: 'Are you sure you want to delete this entry?'
        }
      },
      order: 'Order',
      orderComplete: 'Order completed',
      orderDate: 'Order date',
      orderQuantity: 'Order quantity',
      overfilled: 'Overfilled',
      postcodeTown: 'Postcode / town',
      tank: 'Tank'
    },
    orders: {
      details: 'Details',
      downloadExcel: 'Download Excel file',
      positions: 'Positions',
      sendEmail: 'Send email',
      supplierOrders: 'Supplier Orders'
    }
  },
  map: {
    clusterProperties: 'Cluster Properties',
    constructionProjects: 'Construction Projects',
    terminations: {
      contractEnd: 'Contract end',
      object: 'Object',
      property: 'Property',
      showAll: 'Show all',
      tenant: 'Tenant'
    }
  },
  navigation: {
    admin: 'Admin',
    abacusDemo: 'Abacus Demo',
    abacusAdapter: 'Abacus Adapter',
    abacusEnableRentalCase: 'Activate token',
    abacusDisableRentalCase: 'Deactivate token',
    abacusRentalFormList: 'Applications',
    abacusTicketingStart: 'Ticket start',
    abacusTicketingUpdate: 'Task update',
    abacusTicketingComplete: 'Task complete',
    autocomplete: 'Autocomplete',
    basket: 'Basket',
    constructionProjects: 'Construction Projects',
    corporations: 'Corporations',
    charts: 'Charts',
    dashboard: 'Dashboard',
    dataImport: 'Data Import',
    diagram: 'Diagram',
    dossier: 'Dossier',
    feedback: 'Feedback',
    heatingOil: 'Heating Oil',
    interestedPersonCreate: 'Create Interested person',
    interestedPersonDetails: 'Interested person',
    map: 'Map',
    openMap: 'Open map',
    orders: 'Orders',
    processes: 'Tickets',
    processDetail: 'Tickets - Detail',
    properties: 'Properties',
    propertyLocations: 'Imprecise Property Locations',
    queue: 'Printer Queue',
    recipients: 'Recipients',
    rentingList: 'Interested',
    settings: 'Settings',
    search: 'Search',
    statistics: 'Statistics',
    tanks: 'Tanks',
    taskCreate: 'Create',
    taskDetail: 'Edit task',
    taskDetails: 'Task Details',
    task: 'Order',
    tasks: 'Orders',
    tasksHome: 'Orders',
    tasksList: 'Tasks List',
    terminations: 'Terminations',
    ticketing: 'Ticketing',
    ticketingList: 'Tasks',
    ticketingStatistics: 'Ticketing Statistics',
    ticketingTaskDetail: 'Tasks - Detail',
    ticketingNew: 'Create Ticket',
    ticketingSystem: 'Ticketing System',
    userInfo: 'User Info',
    feedbackPublicForm: 'Feedback',
    interestedPersonPublicForm: 'Rental Application',
    landlordReferenceForm: 'Reference landlord',
    employerReferenceForm: 'Reference employer'
  },
  renting: {
    abacus: {
      rentalContractIdTitle: 'Rental Contract ID',
      formEnabled: 'Renting form active',
      formDisabled: 'Renting form inactive',
      token: 'Token',
      stateCommitment: 'Commitment',
      statePriority1: '1st priority',
      statePriority2: '2nd priority',
      statePending: 'Pending',
      stateDeclined: 'Declined',
      confirmDeclineTitle: 'Cancel selected?',
      confirmDeclineMessage: 'The status is updated and a rejection email is sent to the selected person.',
      multiDeclineDone: 'Rejection was sent to interested party(ies)'
    },
    additionaInformation: 'Additional information',
    captcha: {
      swipeToRight: 'Swipe to right',
      success: 'Success',
      errorMessage: 'Please solve captcha'
    },
    change: 'Change',
    comment: 'Comment',
    decision: {
      apartmentConclusion: 'Apartment',
      creditRatingConclusion: 'Credit rating',
      decisionTitle: 'Decisions',
      employmentConclusion: 'Employment',
      identityDocumentsConclusion: 'Identity documents',
      interestedPersonDecision: 'Decision'
    },
    documents: {
      conclusionGood: 'Positive',
      conclusionBad: 'Negative',
      creditRating: 'Credit Rating',
      creditRatingConclusion: 'Conclusion',
      debtEnforcement: 'Debt enforcements or certificates of loss in the past two years',
      debtEnforcementPerMailReceived: 'Debt collection statement received and verified',
      debtEnforcementPerMailOriginalReturned: 'Original returned',
      debtEnforcementPerMailOriginalReturnedAt: 'at',
      identityDocuments: 'Identity documents',
      swissCitizenship: 'Swiss citizenship'
    },
    interested: 'Interested',
    interestedPerson: {
      addressLine1: 'Address line 1',
      addressLine2: 'Address line 2',
      addressLine3: 'Address line 3',
      comment: 'Comment',
      dateOfBirth: 'Date of birth',
      email: 'E-Mail',
      firstName: 'First Name',
      lastName: 'Last Name',
      messages: {
        created: 'Interested person created',
        confirmDelete: 'Are you sure you want to delete this interested person?',
        delete: 'Delete interested person',
        doesNotExist: 'Interested Person does not exist.'
      },
      mobile: 'Mobile',
      object: 'Object',
      personal: 'Personal',
      property: 'Property',
      phone1: 'Phone 1',
      phone2: 'Phone 2',
      salutations: {
        DEAR_MRS: 'Dear Mrs.',
        DEAR_MR: 'Dear Mr.',
        DEAR_LADIES_AND_GENTLEMAN: 'Dear Ladies and Gentleman',
        DEAR_FAMILY: 'Dear Family',
        GOOD_DAY: 'Good day'
      },
      salutatoryAddress: 'Salutatory Address',
      salutatoryName: 'Salutation Name',
      interestedPersonUpdateMessage: 'Interested person is updated'
    },
    interestedPersonDecision: {
      successfulMessage: 'Thank you for your reply',
      requestCouldNotBeProcessedMessage: 'Sorry, your request could not be processed. Please contact kundendienst@prestige.ch'
    },
    publicForm: {
      dialogTitle: 'Rental Application Info',
      alerts: {
        invalidLink: 'Unfortunately the link is no longer valid. <br>Please contact us at <a href="mailto:kundendienst@prestige.ch" class="white--text">kundendienst@prestige.ch</a>',
        invalidToken: 'Unfortunately you have entered an invalid token <br>Please contact us at <a href="mailto:kundendienst@prestige.ch" class="white--text">kundendienst@prestige.ch</a>',
        usedToken: 'We already received a feedback. <br>Please contact us at <a href="mailto:kundendienst@prestige.ch" class="white--text">kundendienst@prestige.ch</a> if you have further questions',
        success: 'Your rental form has been successfully created.',
        submitError: 'Your rental form could not be submitted. Please try again later.'
      },
      entryToken: 'Rental token',
      fields: {
        answers: {
          no: 'no',
          yes: 'yes'
        },
        additionalResidentialPartner: {
          title: 'ADDITIONAL RESIDENTIAL PARTNER',
          add: 'add',
          remove: 'remove',
          confirmTitle: 'Remove residential partner',
          confirmMessage: 'Are you sure? You can\' undo this',
          confirmButton: 'remove'
        },
        personInfo: {
          attachments: {
            attachmentAllowedFileTypes: 'Only: JPG, JPEG, PNG and PDF files are allowed',
            validateAttachmentTooBig: 'Max file size is 5MB'
          },
          business: 'Business',
          dateOfBirth: 'Date of birth',
          email: 'E-mail',
          employer: 'Employer',
          familyApartment: 'The apartment serves as a family apartment?',
          familyName: 'Last name',
          firstName: 'First name',
          foreignerInfo: {
            country: 'Residence',
            residencePermitType: 'Residence permit type',
            residencePermitTypeL: 'Short-term residents L',
            residencePermitTypeB: 'Resident foreign nationals B',
            residencePermitTypeC: 'Settled foreign nationals C',
            residencePermitTypeG: 'Cross-border commuter permit G',
            residencePermitTypeN: 'Permit for asylum-seekers N',
            residencePermitTypeF: 'Provisionally admitted foreigners F',
            residencePermitTypeS: 'People in need of protection S',
            residencePermitTypeCi: 'Residence permit with gainful employment Ci',
            titleQuestion: 'Do you have Swiss citizenship?',
            uploadLabel: 'Please upload a copy of your residence permit'
          },
          employment: {
            title: 'Current employment',
            status_EMPLOYED: 'Employed',
            status_SELF_EMPLOYED: 'Self employed',
            status_PENSIONER: 'Pensioner',
            status_SEARCHING_FOR_JOB: 'Searching for job',
            employer: 'Employer',
            employerContactInfo: 'Please provide us with a contact person at the employer to verify the above information',
            employerName: 'Name',
            employerFunction: 'Occupation/Function',
            employerPhone: 'Phone',
            employerEmail: 'Email',
            income: 'Income per Month',
            job: 'Job',
            sinceWhen: 'Since when'
          },
          landlord: {
            name: 'Name',
            function: 'Funktion',
            phone: 'Phone',
            email: 'Email'
          },
          creditRating: {
            assessmentInfo: 'In order to simplify the process of credit assessment for you and us, we would like to ask you to send us the CreditTrust certificate. You can easily order the CreditTrust <a href="https://credittrust.ch/de/" target="_blank">certificate online</a>.',
            title: 'Credit rating',
            orderCertificate: 'Please order a CreditTrust certificate on my behalf. I\'m aware, that CreditTrust will charge me directly for this service',
            validateCertificate: 'Please use existing certificate',
            validateCertificateTooBig: 'Maximum file size: 5 MB',
            validateCertificateAllowedFileTypes: 'Only PDF documents are allowed',
            debtEnforcementPerMail: 'I\'l send a debt collection report via mail to Prestige Immobilien AG, Berninastrasse 29, 8042 Zürich',
            debtEnforcement: 'Have you had any debt enforcements or certificates of loss in the past two years?',
            comment: 'Comment'
          },
          hometown: 'Hometown',
          numberOfPersons: 'Number of persons',
          numberOfChildren: 'of which children (age)',
          phoneInfo: 'Phone / Mobile',
          private: 'Private',
          title: 'PERSONAL INFORMATION',
          apartment: {
            address: 'Address',
            landlordContactInfo: 'Please provide us with a contact person at the landlord/manager to verify their information',
            status_SAME_AS_FIRST_RESIDENT: 'Same as first person',
            status_RENTAL_APARTMENT: 'Rental apartment',
            status_HOME_OWNERSHIP: 'Home ownership',
            status_PARENTS: 'Parents',
            street: 'Street',
            streetNumber: 'Number',
            zip: 'Zip',
            place: 'Place',
            roomNumber: 'Number of rooms',
            roomRent: 'Rent/month: CHF',
            roomRentSince: 'In rent since',
            size: 'Apartment size',
            title: 'Current apartment'
          }
        },
        newApartment: {
          additionalInformation: {
            title: 'Additional information',
            numberOfAdults: 'Number of adults',
            numberOfChildren: 'Number of children',
            pets: {
              breed: 'Dog/Breed',
              title: 'Pets',
              which: 'Which',
              info: 'Pets are only allowed with written approval (additional agreement to the rental agreement).'
            },
            musicInstruments: {
              title: 'Music instruments',
              which: 'Which'
            }
          },
          residenceChangeReason: 'Reason for the change of residence',
          title: 'Information about the desired rental property',
          conditionRate: 'How do you rate the condition of the apartment?',
          concludeContract: 'Is this a condition for concluding a contract?',
          concludedContract: 'Condition for contract conclusion?',
          sublet: {
            subTenants: 'If necessary, do you wish to take on sub-tenants at a later date?',
            title: 'Sublet',
            titleDescription: 'Registration forms must also be filled out by sub-tenants.'
          },
          renovation: 'What should be renovated / renewed on the rental property?',
          renovationNeeded: 'Need for renovation / renewal',
          additionalObjects: 'Additional objects',
          additionalGarage: 'Garage',
          additionalParkingSpot: 'Parking spot',
          additionalMotorcycleParkingSpot: 'Motorcycle parking spot'
        },
        currentTenant: 'Current tenant',
        date: 'Date',
        referenceDate: 'Reference date',
        signature: {
          partner: 'Signature of the spouse/residential partner',
          prospectiveTenant: 'Signature of the prospective tenant'
        }
      },
      desiredDateAlert: 'The desired move-in date is before the earliest move-in date',
      desiredMoveInDate: 'Desired move-in date',
      earliestStartParking: 'Earlies parking rent date',
      earliestStartResidential: 'Earliest move-in date',
      formInfo: 'As the agents of the house owners and as representatives of the house community, we are for the ' +
        'careful selection of new tenants responsible. It goes without saying that the Federal Data Protection Act ' +
        '(DSG) are taken into account in this registration form. We would therefore like to point out that answering the ' +
        'italicized questions is optional.',
      formInfoAgreement: 'The undersigned wishes to conclude a rental agreement for the following property:',
      formNote: 'Please note: Based on this registration form, PRESTIGE Immobilien AG will contact the prospective tenant ' +
        'to gather information. In particular, he authorizes the landlord to use this registration form as an active legitimation ' +
        'to be used for information on debt enforcement law. ' +
        'If the prospective tenant withdraws his interest for any reason after receiving the lease, ' +
        'so he undertakes to pay PRESTIGE Immobilien AG the cost of issuing the rental agreement as well as ' +
        'to remunerate the activities with CHF 100.–. Registration, viewing and rental are free. Even with one ' +
        'If PRESTIGE Immobilien AG rejects the prospective tenant, there are no costs for them. ' +
        'CURRENT OPERATING INFORMATION IN THE ORIGINAL FROM ALL INTERESTED PARTIES must be attached to this registration',
      formNoteIncompleted: 'INCOMPLETELY COMPLETELY COMPLETED APPLICATIONS AND SUCH APPLICATIONS WITHOUT THE DESIRED DOCUMENTS (OPERATIONAL EXTRACTS, COPIES OF FOREIGN IDS ETC.) CANNOT BE PROCESSED.',
      imprint: 'Imprint',
      title: 'RENTAL APPLICATION'
    },
    referencesApartment: {
      title: 'Reference',
      referenceAbout: 'Reference about',
      currentApartment: 'Current apartment',
      currentLandlord: 'Current landlord',
      monthlyRent: 'Rent/Monthly',
      rentStartDate: 'Start rent date',
      rootNumbers: 'Number of rooms',
      notableIncidents: 'Have there been any notable incidents during the lease term such as legal cases, disputes with neighbors, etc.?',
      notableIncidentsComment: 'Have there been any notable incidents during the lease term such as legal cases, disputes with neighbors, etc.? If so, please comment below',
      regularPayment: 'Were payments made regularly and on time?',
      rentalRelationship: 'Would you enter into a rental relationship with the tenant again?',
      sameAsFirstResident: 'Same as first resident',
      success: 'Thank you for the feedback.',
      referenceSaveError: 'Your feedback couldn\'t get saved. <br>Please contact us via <a href="mailto:kundendienst@prestige.ch" class="white--text">kundendienst@prestige.ch</a>',
      apartmentConclusionGood: 'Positive',
      apartmentConclusionBad: 'Negative',
      conclusionInterestedPersonTitle: 'Interested person',
      conclusionRespondentTitle: 'Reference information',
      conclusionTitle: 'Conclusion'
    },
    referencesEmployer: {
      title: 'Reference',
      referenceAbout: 'Reference about',
      employmentDate: 'In employment since',
      income: 'Income at least',
      incomeMonthly: ' CHF per month',
      notTerminatedAndUnlimited: 'Employment relationship not terminated and unlimited',
      success: 'Thank you for the feedback.',
      referenceSaveError: 'Your feedback couldn\'t get saved. <br>Please contact us via <a href="mailto:kundendienst@prestige.ch" class="white--text">kundendienst@prestige.ch</a>',
      employmentConclusionGood: 'Positive',
      employmentConclusionBad: 'Negative',
      conclusionInterestedPersonTitle: 'Interested person',
      conclusionRespondentTitle: 'Reference information',
      conclusionTitle: 'Conclusion'
    },
    referenceFeedback: 'Feedback',
    referenceFunction: 'Occupation/Function',
    rentingForms: 'Rental Applications',
    respondent: 'Respondent',
    status: 'Status',
    statusChangedMessage: 'Interested person status changed',
    statuses: {
      ALREADY_RENTED: 'Already Rented',
      CANCELLATION_BY_INTERESTED_PERSON: 'Cancellation by Interested Person',
      COMMITMENT: 'Commitment',
      IN_PROGRESS: 'In progress',
      PRIORITY_1: '1. priority',
      PRIORITY_2: '2. priority',
      NOT_ELIGIBLE: 'Not Eligible',
      PENDING: 'Pending',
      UNDER_CLARIFICATION: 'Under Clarification'
    }
  },
  statistics: {
    date: {
      from: 'From',
      to: 'To'
    },
    offers: 'Offers',
    totalAmountOil: 'Total amount of oil ordered',
    totalNumberTasks: 'Total number of tasks',
    totalNumberOffers: 'Total number of offers'
  },
  table: {
    noData: 'No data available'
  },
  tasks: {
    alert: 'The Task ID is invalid.',
    attachments: {
      attachmentCheckboxInfo: 'If the checkbox is active, the attachment will be added to the email',
      addToEmail: 'Add to email',
      clickOrDragText: 'Click or drag files here'
    },
    competenceSum: 'Competence sum',
    types: {
      TASK: 'Assignment',
      OFFER: 'Offer'
    },
    list: {
      type: 'Type'
    },
    info: {
      administrativeArea: 'Administrative Area',
      caretaker: 'Caretaker',
      client: 'Client',
      createdBy: 'Created by',
      dialogTitleEditor: 'Select Editor',
      dialogTitleHouse: 'Select House',
      dialogTitleObject: 'Select Object',
      editor: 'Editor',
      house: 'House',
      object: 'Object',
      property: 'Property',
      realEstateManager: 'Real Estate Manager',
      ticket: 'Ticket'
    },
    createTask: {
      typeTitle: 'Art / Type',
      insuranceCase: 'Case of Insurance',
      recentTasks: 'Recent Tasks of the Property',
      subject: 'Subject',
      subject1: 'Subject line 1 / where?',
      subject2: 'Subject line 2',
      successfullyCreated: 'The task has been successfully created.',
      jobDescription: 'Job Description',
      fileMessage: 'Files can be added after saving the task.'
    },
    tenant: {
      comment: 'Comment',
      currentTenant: 'Current tenant',
      newTenant: 'New tenant',
      object: 'Object',
      selectTenant: 'Select tenant',
      currentTenantContractStart: 'Contract start',
      currentTenantContractEnd: 'Contract end',
      newTenantContractStart: 'New contract start',
      tenantOptions: {
        CURRENT: 'Current',
        NEW: 'New',
        MANUAL: 'Manual'
      },
      title: 'Tenant'
    },
    craftsmen: {
      comment: 'Comment for craftsmen',
      hint: 'Does not go to Tenants',
      noResults: 'Craftsmen list is not defined',
      search: 'Search all craftsmen',
      select: 'Select from the list of craftsmen',
      selectManual: 'Select manual craftsman',
      title: 'Craftsmen'
    },
    comment: {
      title: 'Comment',
      hint: 'Not printed'
    },
    dialog: {
      clone: {
        title: 'CLONE TASK',
        question: 'Are you sure you want to copy this task?'
      },
      delete: {
        title: 'DELETE TASK',
        question: 'Are you sure you want to delete this task?'
      },
      email: {
        title: 'SEND EMAIL AGAIN',
        question: 'Emails have already been sent. Do you really want to send them again?'
      }
    },
    email: {
      realEstateManager: 'Real Estate Manager',
      owner: 'Client',
      textField: 'Email',
      saveChanges: 'Changes must be saved first',
      selectionMessages: {
        tenant: 'Please select a tenant',
        caretaker: 'Please select a caretaker',
        client: 'Please select a client',
        craftsman: 'Please select a craftsman',
        realEstateManager: 'Please select a real estate manager'
      },
      sendMail: 'Send email',
      successfullySent: 'Emails were successfully sent',
      title: 'Email delivery',
      history: {
        title: 'Email History',
        noEmailsMessage: 'So far, no emails has been sent.'
      }
    },
    taskSource: 'Source',
    taskSources: {
      TASK: 'Tasks',
      IMOFIX: 'Imofix',
      TICKETING: 'Ticketing'
    },
    warning: {
      ordersDisabled: 'The creation of orders has been disabled in Abacus for this property.'
    }
  },
  tipTap: {
    bold: 'Bold (Ctrl+B)',
    underline: 'Underline (Ctrl+U)',
    italic: 'Italic (Ctrl+I)',
    strike: 'Strikethrough',
    bulletList: 'Bullet list (Ctrl+Shift+8)',
    numberedList: 'Numbered list (Ctrl+Shift+7)',
    link: 'Link',
    linkOff: 'Remove link',
    undo: 'Undo (Ctrl+Z)',
    redo: 'Redo (Ctrl+Y)',
    clearColor: 'Remove color'
  },
  ticketingSystem: {
    charts: {
      completedTasksByAssigneeTitle: 'Completed Tasks by Assignee',
      completedLabel: 'Completed',
      createdLabel: 'Created',
      day: 'Day',
      month: 'Month',
      openCompletedTasksTitle: 'Opened and completed tasks',
      openedTasksByAssigneeTitle: 'Opened Tasks by Assignee',
      openedTasksByCandidateGroupTitle: 'Opened Tasks by Candidate group',
      priority: {
        dueTodayPriority1: 'Today due priority high',
        dueTodayPriority2: 'Today due priority normal',
        additionalPriority1: 'Later due priority high',
        additionalPriority2: 'Later due priority normal'
      },
      userOpenedTasksByAssigneeTitle: 'User Opened Tasks by Assignee',
      unassigned: 'Unassigned',
      uncrossPeople: 'Please click on name to uncross the people',
      week: 'Week'
    },
    deleteProcess: {
      title: 'Delete Process Instance',
      processInstance: 'Process instance',
      reason: 'Reason'
    },
    fileName: 'File name',
    fileCreatedOn: 'Created on',
    fileTypes: {
      email: 'Email file',
      rental: 'Rental application file',
      ticket: 'Ticket file'
    },
    priority: {
      title: 'Priority',
      priority1: 'Hoch',
      priority2: 'Normal',
      undefined: 'Undefined'
    },
    botOverride: {
      context: {
        title: 'Bot Correction',
        description: 'Ticket category',
        submitButton: 'correct',
        successMessage: 'Successfully corrected'
      }
    },
    noteField: {
      title: 'Note'
    },
    candidateGroups: 'Candidate groups',
    childTask: {
      create: 'Create checklist item',
      description: 'Description'
    },
    fileActions: {
      editFileName: 'Edit file name',
      preview: 'Preview'
    },
    updateTask: {
      edit: 'Edit checklist item',
      editTaskName: 'Edit task name and description'
    },
    entry: {
      assignee: 'Assignee',
      created: 'Started',
      due: 'Due',
      finished: 'Finished',
      finishImmediately: 'Finish Immediately',
      followUp: 'Follow up',
      item: 'Item',
      priority: 'Priority'
    },
    editTask: {
      description: 'Description'
    },
    links: 'Links',
    list: {
      clear: 'Clear filters',
      filter: 'Filter',
      filters: {
        displayAssignedTasks: 'Assigned tasks',
        displayFollowUpTasks: 'Follow up tasks',
        searchCaretaker: 'Caretaker',
        searchProperty: 'Property',
        searchTicket: 'Ticket type',
        searchTaskName: 'Task',
        searchTenant: 'Tenant',
        searchUser: 'Ticketing User',
        object: 'Object'
      },
      general: 'Customer service',
      personally: 'Personally'
    },
    messages: {
      addComment: 'Add a comment ...',
      desiredDateNoEqual: 'Desired move in date is not equal to earliest move in date.',
      noContextInfo: 'No context information',
      noReferencesInfo: 'No references information',
      noSuggestions: 'No suggestions',
      startProcess: 'Create Ticket',
      startAndOpenProcess: 'Create and open ticket',
      successfullyAddedComment: 'The comment was successfully added.',
      successfullyAddedCandidateGroup: 'The candidate group was successfully added.',
      successfullyAssignedToMe: 'The task has been successfully assigned to you.',
      successfullyChangedAssignee: 'The task assignee has been successfully changed.',
      successfullyCompleted: 'The task has been successfully completed.',
      successfullyDeletedCandidateGroup: 'The candidate group was successfully deleted.',
      successfullySaved: 'The task has been successfully saved.',
      successfullyStarted: 'The process has been successfully started.',
      successfullyStartedRelated: 'The related ticket has been successfully started.',
      successfullyUpdatedComment: 'The comment was successfully updated.',
      successfullyUpdatedDueDate: 'The due date has been successfully updated.',
      successfullyUpdatedProcessSubject: 'The subject has been successfully updated.',
      successfullyUpdatedFollowUpDate: 'The follow-up date has been successfully updated.',
      successfullyUpdatedTask: 'The item has been successfully updated.',
      successfullyUpdatedTaskPriority: 'The priority has been successfully updated.',
      comment: {
        actionButtonLabel: 'Write comment',
        create: 'Save',
        dialogTitle: 'Write new comment',
        edit: 'Edit',
        hint: 'For internal use only, not sent via email',
        updateDialogTitle: 'Update comment'
      },
      email: {
        actionButtonLabel: 'Send email',
        at: 'at',
        bcc: 'Bcc',
        cc: 'Cc',
        on: 'On',
        replyDialogTitle: 'Reply to email',
        newDialogTitle: 'Send new email',
        wrote: 'wrote',
        to: 'To',
        from: 'From',
        read: 'Read',
        reply: 'Reply',
        subject: 'Subject',
        body: 'Message',
        send: 'Send',
        successfullySent: 'Successfully sent'
      }
    },
    mergeTicket: {
      btnTitle: 'Merge Ticket',
      success: 'Successfully merged ticket'
    },
    note: {
      assignedUser: 'Assignee',
      completeImmediately: 'Complete immediately',
      email: {
        sender: 'Email Sender',
        subject: 'Email Subject',
        message: 'Email Message'
      },
      selectAssignee: 'Assign person',
      selectCandidateGroup: 'Assign group',
      setDueDate: 'Due date',
      files: 'Files',
      title: 'Ticket'
    },
    objects: {
      tenantOf: 'Tenant of',
      subletOf: 'Sublet of'
    },
    processes: {
      completedTasks: 'Completed Tasks',
      diagram: 'Diagram',
      displayDiagram: 'Display diagram',
      editProcessSubject: 'Edit subject',
      processPending: 'Process pending',
      interestedPerson: {
        creditTrustTitle: 'Verification CreditTrust certificate',
        decision: {
          title: 'State',
          reason: 'Reason',
          boxTitle: 'Abbreviate process and adjust status directly',
          submitLabel: 'Set state',
          successMessage: 'Shortcut taken'
        },
        noInfo: 'No Rental Case information',
        notVerified: 'Not verified',
        orderId: 'Order ID',
        orderedCertificate: 'Ordered CreditTrust certificate',
        registration: 'Created at',
        createCertificate: {
          issuable: 'Issuable',
          crifMatch: 'CRIF match',
          crifApproval: 'CRIF approval',
          intrumMatch: 'Intrum match',
          intrumApproval: 'Intrum approval'
        },
        rentalApplicationLabel: 'Show rental application',
        rentalApplicationPdfLabel: 'Download PDF',
        rentalObjectTitle: 'Rental object details',
        title: 'Application from interested person',
        verified: 'Verified'
      },
      offlineRentingForm: {
        rentalContract: 'Rental contract',
        firstName: 'First name',
        lastName: 'Last name',
        email: 'Email',
        phone: 'Phone',
        street: 'Street',
        zip: 'Zip',
        place: 'Place'
      },
      noMessages: 'No history',
      notFound: 'The requested ticket couldn\'t get loaded',
      onlyActive: 'Only active',
      openTasks: 'Open Tasks',
      process: 'Ticket',
      processDetails: 'Open ticket',
      processLinks: 'Ticket links',
      processReferences: 'Parent and Child Tickets',
      rentingForm: {
        createContract: {
          rentComponent: 'Rent component',
          rentComponentAmount: 'Preevious amount',
          rentComponentNewAmount: 'New amount',
          rentComponentComment: 'Remark',
          rentComponentDeposit: 'Deposit',
          abacusLink: 'Object',
          earliestStart: 'Earliest move-in-date',
          moveInDate: 'Move-in date',
          grossRent: 'Gross rent',
          confirmation: 'Confirmed by interested person',
          confirmationComment: 'Note',
          relettingContractSent: 'Contract created and sent:',
          contractFinished: 'Rental contract finished?',
          contractFinishedNo: 'No, declined by interested person',
          contractCompletedDate: 'Contract and all documents countersigned, scanned and returned on'
        },
        viewing: {
          title: 'Viewing(s)',
          headerName: 'Name and Date',
          headerImpression: 'Impression',
          headerInternalRemark: 'Remark',
          headerInterest: 'Interest',
          viewingCancelledYes: 'Viewing cancelled'
        }
      },
      reletting: {
        labels: {
          actions: 'Actions',
          name: 'Name',
          received: 'Received',
          state: 'State',
          step: 'Step',
          formViewReturned: 'Form viewing returned?'
        },
        liableUntil: 'Liable until',
        specialities: 'Specialities',
        title: 'Reletting Process',
        pdf: 'PDF'
      },
      reoccurringTicket: {
        dueDateTitle: 'Due on',
        dueDateHint: 'Due on which day of the following month',
        switchTitle: 'Reoccurring ticket',
        switchHint: 'A new ticket is created, once this ticket is completed',
        title: 'Reoccurring',
        dueDateOnDayOfMonthMinError: 'Please pick a value 1 or greater',
        dueDateOnDayOfMonthMaxError: 'Please pick a value 31 or smaller'
      },
      startedAfter: 'Started after',
      startedBefore: 'Started before',
      termination: {
        contract: 'Contract',
        signaturesCorrect: 'Signatures correct?',
        title: 'Termination Process',
        terminationBy: 'Termination by',
        terminationReceivedOn: 'Termination received on',
        noInfo: 'No Process Termination information',
        statements: {
          account: 'Account',
          balance: 'Balance',
          costs: 'Costs',
          chargeStatements: 'Rental Contract Charge Statements',
          contractPartners: 'Contract partners',
          period: 'Period',
          rentalContractID: 'Rental Contract ID',
          rentalComponents: 'Rental components',
          terminationProperty: 'Termination Property'
        },
        rentalComponentHeaders: {
          component: 'Component',
          old: 'Old',
          new: 'New',
          comment: 'Comment'
        },
        alert: 'Enter new rent in Abacus!'
      },
      key: 'Key',
      variables: {
        structuredData: 'Structured data',
        copyToClipboard: 'Copy to clipboard',
        copiedToClipboard: 'Copied to cliboard',
        files: 'Files',
        showMore: 'Show more',
        title: 'Variables'
      }
    },
    subject: 'Subject',
    suggestions: 'Suggestions',
    startProcess: {
      description: 'Comment',
      maxLength: 'Subject max length is 255.'
    },
    task: 'Task',
    taskDetails: {
      buttons: {
        action: 'Action',
        addComment: 'Add comment',
        assignToMe: 'Assign to me',
        complete: 'Complete',
        done: 'Done',
        restartProcess: 'Reopen ticket',
        startRelatedProcess: 'Related ticket',
        unassigned: 'Unassigned'
      },
      checkList: 'Checklist',
      completeCheckListItems: 'Please complete checklist items',
      completeAbacusControlledTicket: 'Please complete in Abacus',
      dueDate: 'Due date',
      followUpDate: 'Follow-up date',
      form: {
        searchProperty: 'Search property',
        searchPropertyObjects: 'Search property objects',
        title: 'Form'
      },
      history: 'Comments and Emails',
      processReferences: {
        children: 'Started those',
        finishedOn: 'Finished on',
        parent: 'Got started by',
        startedBy: 'Started by',
        startedOn: 'Started on'
      },
      setDueDate: 'Set due date',
      setFollowUpDate: 'Set follow-up date',
      state: 'State',
      states: {
        ACTIVE: 'Active',
        SUSPENDED: 'Suspended',
        COMPLETED: 'Completed',
        EXTERNALLY_TERMINATED: 'Externally terminated',
        INTERNALLY_TERMINATED: 'Internally terminated'
      },
      taskDoesNotExist: 'The task doesn`t exist anymore.'
    },
    context: {
      propertyObject: {
        currentTenant: 'Aktuell',
        newTenant: 'Neu',
        since: 'since: ',
        until: 'until: '
      }
    },
    ticketingUsers: 'Ticketing Users',
    users: {
      rentingForm: 'Renting Form'
    },
    tooltipEditTask: 'Edit task',
    tooltipEditSubject: 'Edit subject'
  }
}
