<template>
  <v-btn @click="downloadPdf" class="mr-2 mt-1 mb-5">
    {{ $t('ticketingSystem.processes.reletting.pdf') }}
    <v-icon>insert_drive_file</v-icon>
  </v-btn>
</template>

<script>
import JsPDF from 'jspdf'

export default {
  props: {
    propertyInfo: {
      type: String,
      required: true
    },
    objectFloorInfo: {
      type: String,
      required: true
    },
    token: {
      type: String,
      required: true
    }
  },
  methods: {
    downloadPdf () {
      const doc = new JsPDF('p', 'pt', 'a4')
      const title = 'Anmeldung Wohnung'
      const mainText = `Die Prestige Immobilien AG bietet Ihren Mietinteressenten einen \ndigitalen Anmeldeprozess an. Sie profitieren bei der digitalen Anmeldung von einer benutzerfreundlichen Eingabemaske.\n \nDie digitale Anmeldung ermöglicht uns eine schnelle Mieterauswahl, die Mietinteressenten erhalten innert weniger Tage eine Zu- oder Absage. \n \nMittels QR Code können Sie sich bequem mit ihrem mobilen Gerät anmelden. Sie haben aber auch die Möglichkeit mittels Zugangscode den Anmeldeprozess über unsere Homepage www.prestige.ch/anmeldung zu starten.`
      const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth()
      const mainTextWidth = pageWidth * 0.90
      const lines = doc.splitTextToSize(mainText, mainTextWidth)
      const sub = 'Anmeldung für'
      const propertyName = `Liegenschaft:     ${this.propertyInfo}`
      const objectName = `Objekt:               ${this.objectFloorInfo}`
      const qrCode = document.getElementById('qrCodeCanvas')
      const qrCodeImg = new Image()
      qrCodeImg.src = qrCode.toDataURL('image/webp', 1.0)
      doc.addImage(qrCodeImg.src, 'WEBP', 70, 480, 155, 155)
      doc.setFontSize(22)
      doc.setTextColor(118, 54, 108)
      doc.setFont('Helvetica', 'normal', 'bold')
      doc.text(title, 85, 180)

      doc.setFontSize(18)
      doc.setTextColor(118, 54, 108)
      doc.text(sub, 85, 425)

      doc.setFontSize(14)
      doc.setTextColor(0, 0, 0)
      doc.setFont('Helvetica', 'normal', 'normal')
      doc.setLineHeightFactor(1.3)
      doc.text(lines, 85, 200)
      doc.text(propertyName, 85, 445)
      doc.text(objectName, 85, 465)
      doc.text(`Zugangscode: ${this.token}`, 87, 647)
      doc.save('QR_Anmeldung.pdf')
    }
  }
}
</script>
