<template>
  <div class="pl-2 pt-0 pr-4">
    <v-card-title class="pl-2 pb-2">{{ $t('dossier.corporations.print') }}</v-card-title>
    <v-layout row wrap class="pl-2">
      <v-flex xs12 sm12 md12 lg2>
        <v-card height="100%" class="pa-4 output">
          <v-card-title class="pa-0">{{ $t('dossier.corporations.output') }}</v-card-title>
          <v-layout>
            <v-checkbox
              :label="$t('dossier.corporations.print')"
              v-model="print"
            ></v-checkbox>
          </v-layout>
          <v-layout>
            <v-checkbox
              :label="$t('dossier.corporations.pdf')"
              v-model="createPdf"
            ></v-checkbox>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex xs12 sm12 md12 lg10>
        <v-card height="100%" class="pa-2 selection">
          <v-layout row class="pl-2 pt-2">
            <v-flex row>
              <v-card-title class="pa-0 ml-3">{{ $t('dossier.recipients.recipientDetail.selection') }}</v-card-title>
              <v-radio-group row v-model="selection" @change="changedRadioForm" class="ml-4">
                <v-radio
                  v-for="(item, i) in documentSelection"
                  :key="i"
                  class="pr-5 mr-0"
                  :label="$t('dossier.corporations.' + item)"
                  :value="item"
                />
              </v-radio-group>
            </v-flex>
          </v-layout>
          <v-layout row wrap class="pl-2">
            <v-flex xs12 sm12 md4 lg4>
              <p>{{ $t('dossier.corporations.dossierDocuments') }}</p>
              <v-checkbox
                class="inline-flex mt-0 mr-4"
                v-for="(document, index) in dossierDocumentTypes"
                :label="document.documentKey"
                v-model="document.active"
                @change="setCustomSelection()"
                :key="index"
              ></v-checkbox>
            </v-flex>
            <v-flex xs12 sm12 md4 lg4>
              <p>{{ $t('dossier.corporations.recipientDocuments') }}</p>
              <v-checkbox
                class="inline-flex mt-0 mr-4"
                v-for="(document, index) in recipientDocumentTypes"
                :label="document.documentKey"
                v-model="document.active"
                @change="setCustomSelection()"
                :key="index"
              ></v-checkbox>
            </v-flex>
            <v-flex xs12 sm12 md4 lg4>
              <p>{{ $t('dossier.corporations.propertyDocuments') }}</p>
              <v-checkbox
                class="inline-flex mt-0 mr-4"
                v-for="(document, index) in propertyDocumentTypes"
                :label="document.documentKey"
                v-model="document.active"
                @change="setCustomSelection()"
                :key="index"
              ></v-checkbox>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout row class="pl-2 pt-2 pb-2">
      <v-flex>
        <v-btn color="primary" :loading="printDocumentsLoading" @click.stop="printCorporation" :disabled="disableBtn">{{ $t('dossier.corporations.dossierPrint') }}</v-btn>
      </v-flex>
    </v-layout>
    <v-list three-line v-if="hasPrintHistory" class="pa-0 mb-1">
      <v-divider :key="'divider-top'"></v-divider>
      <template v-for="(history, index) in printDocumentsResult">
        <v-list-item :key="index">
          <v-list-item-content>
            <v-list-item-title>{{ $t('navigation.corporations') }}: [{{ history.corporation.corporationId }}] {{ history.corporation.name }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ $t('dossier.corporations.sentToPrinter') }}: {{ history.print ? $t('common.options.yes') : $t('common.options.no') }}<br />
              <span v-if="history.pdf">{{ $t('dossier.corporations.pdf') }}: <a @click.stop="downloadPrintPDF(history.dossierPrintHistoryId)">{{ history.dossierPrintHistoryId }}.pdf</a></span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider :key="index + '-divider-bottom'"></v-divider>
      </template>
    </v-list>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'PrintContainer',
  props: {
    corporations: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      print: false,
      createPdf: true,
      printDocumentsLoading: false,
      printDocumentsResult: [],
      allDocumentTypes: [],
      dossierDocumentTypes: [],
      recipientDocumentTypes: [],
      propertyDocumentTypes: [],
      documentTypesActive: [],
      documentSelection: [ 'selectDocTypes', 'deselectDocTypes' ],
      selection: ''
    }
  },
  computed: {
    ...mapState('printer', ['year']),
    hasCorporations () {
      return this.corporations && this.corporations.length > 0
    },
    hasSelectionsCorporationDetail () {
      return this.corporations && this.corporations[0].properties.length > 0 && this.corporations[0].recipients.length > 0
    },
    disableBtn () {
      return (!this.hasCorporations || !this.hasSelectionsCorporationDetail) || (!this.print && !this.createPdf)
    },
    hasPrintHistory () {
      return this.printDocumentsResult && this.printDocumentsResult.length > 0
    }
  },
  mounted () {
    this.printDocumentsResult = []
    this.fetchDocumentConfigurationTypes()
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar', 'setSuccessSnackbar']),
    printCorporation () {
      this.printDocumentsLoading = true
      this.populateDocumentTypesList()
      let dossierPostBody = {
        year: this.year,
        print: this.print,
        pdf: this.createPdf,
        documentTypes: this.documentTypesActive,
        corporations: this.corporations
      }
      let that = this
      this.$prestigeDossierCustomPrintHistory.createNewDossier(dossierPostBody).then(response => {
        that.printDocumentsLoading = false
        that.printDocumentsResult = response.data
        that.$emit('loadData')
        that.setSuccessSnackbar(that.$t('dossier.successfullyPrinted'))
      }).catch(error => {
        that.printDocumentsLoading = false
        that.setErrorSnackbar(error)
      })
    },
    populateDocumentTypesList () {
      this.documentTypesActive = []
      this.filterActiveDocumentTypes(this.dossierDocumentTypes)
      this.filterActiveDocumentTypes(this.propertyDocumentTypes)
      this.filterActiveDocumentTypes(this.recipientDocumentTypes)
    },
    filterActiveDocumentTypes (documentTypes) {
      if (documentTypes.length > 0) {
        let activeDocumentTypes = documentTypes.filter(x => x.active)
        if (activeDocumentTypes.length > 0) {
          activeDocumentTypes.forEach(item => {
            this.documentTypesActive.push(item.documentTypeId)
          })
        }
      }
    },
    downloadPrintPDF (dossierPrintHistoryId) {
      let that = this
      const config = { responseType: 'blob' }
      this.$prestigeDossierPrintHistory.downloadDossierPdf(dossierPrintHistoryId, config).then(response => {
        if (response.data) {
          const headers = response.headers
          const fileName = headers['content-disposition'].split('filename=')[1]
          const file = {
            data: response.data,
            name: fileName ? fileName.substring(1, fileName.length - 1) : ''
          }
          that.$emit('downloadFile', file)
          return true
        }
      }).catch(function (error) {
        that.setErrorSnackbar(error)
      })
    },
    fetchDocumentConfigurationTypes () {
      let that = this
      this.$prestigeDossierDocument.getDocumentTypes().then(response => {
        that.allDocumentTypes = response.data
        that.dossierDocumentTypes = that.allDocumentTypes.find(x => x.category === 'DOSSIER').documentTypes
        that.dossierDocumentTypes = that.setDocumentsActivity(that.dossierDocumentTypes, false)
        that.recipientDocumentTypes = that.allDocumentTypes.find(x => x.category === 'RECIPIENT').documentTypes
        that.recipientDocumentTypes = that.setDocumentsActivity(that.recipientDocumentTypes, false)
        that.propertyDocumentTypes = that.allDocumentTypes.find(x => x.category === 'PROPERTY').documentTypes
        that.propertyDocumentTypes = that.setDocumentsActivity(that.propertyDocumentTypes, false)
      }).catch(error => {
        that.setErrorSnackbar(error)
      })
    },
    changedRadioForm () {
      if (this.selection === 'selectDocTypes') {
        this.selectAll()
      } else if (this.selection === 'deselectDocTypes') {
        this.deselectAll()
      }
    },
    selectAll () {
      this.dossierDocumentTypes = this.setDocumentsActivity(this.dossierDocumentTypes, true)
      this.propertyDocumentTypes = this.setDocumentsActivity(this.propertyDocumentTypes, true)
      this.recipientDocumentTypes = this.setDocumentsActivity(this.recipientDocumentTypes, true)
    },
    deselectAll () {
      this.dossierDocumentTypes = this.setDocumentsActivity(this.dossierDocumentTypes, false)
      this.propertyDocumentTypes = this.setDocumentsActivity(this.propertyDocumentTypes, false)
      this.recipientDocumentTypes = this.setDocumentsActivity(this.recipientDocumentTypes, false)
    },
    setDocumentsActivity (docList, value) {
      return docList.map((obj) => {
        return {
          ...obj,
          active: value
        }
      })
    },
    setCustomSelection () {
      this.selection = ''
    }
  }
}
</script>

<style scoped lang="postcss">
.inline-flex {
  display: inline-flex;
}
.block {
  display: block;
}
/deep/ .output .v-input--checkbox, /deep/ .selection .v-input--checkbox {
  height: 24px;
}
/deep/ .selection p {
  margin-bottom: 2px;
}
</style>
