<template>
  <v-menu offset-y class="shrink">
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on">
        <v-icon>mdi-dots-horizontal</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item v-for="(actionItem, index) in actions" :key="index" @click="actionItem.action(item)">
        <v-list-item-title>{{ actionItem.title }}</v-list-item-title>
        <v-list-item-icon>
          <span v-if="actionItem.icon === 'abacus-logo'" class="abacus-logo"></span>
          <v-icon v-else color="primary" :size="20">{{ actionItem.icon }}</v-icon>
        </v-list-item-icon>
      </v-list-item>
      <template v-if="showDelete">
        <v-divider></v-divider>
        <v-list-item @click="deleteAction.action(item)">
          <v-list-item-title>{{ deleteAction.title }}</v-list-item-title>
          <v-list-item-icon>
            <v-icon :color="deleteAction.color" :size="20">{{ deleteAction.icon }}</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
import _isEmpty from 'lodash.isempty'

export default {
  name: 'ActionsMenu',
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    actions: {
      type: Array,
      default: () => []
    },
    deleteAction: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    showDelete () {
      return !_isEmpty(this.deleteAction)
    }
  }
}
</script>

<style scoped lang="postcss">
.abacus-logo {
  width: 120px;
  content: url('../assets/icons/abacus-logo.svg');
}
</style>
