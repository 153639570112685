<template>
  <div>
    <v-btn block color="primary" target="_blank" :href="'https://abacus.prestige.ch:40001/abastart?mandant=10&program=immo.11&ObjectContractNumber=' + getVariableValue('System_RentalContractId_Long')">
      Serienbrief Unterschrift fehlt
    </v-btn>
  </div>
</template>

<script>
import { formatDate } from '@/helpers/formatterHelper'

export default {
  name: 'TerminationSignature',
  props: {
    processVariables: {
      type: Array,
      default: () => {}
    }
  },
  components: {
  },
  computed: {
    href () {
      return window.location.protocol + '//' + window.location.host + '/renting/form/'
    }
  },
  methods: {
    formatDate: formatDate,
    getVariableValue (variableName) {
      if (this.processVariables) {
        const variable = this.processVariables.find(variable => variable.variableName === variableName)
        if (variable) {
          return variable.variableValue
        }
      }
      return null
    }
  }
}
</script>

<style scoped lang="postcss">
</style>
