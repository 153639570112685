// config-v2.js is included in index.html and overwritten in the runtime
/* global config:true */

export const appBarBackgroundColor = config.ENVIRONMENT === 'local' ? '#a2ae01' : (config.ENVIRONMENT === 'test' ? '#ffa02f' : '#76366c')

export const appBarItems = [
  {
    requireRoles: 'prestige-tools-reader',
    title: 'dashboard',
    name: 'Dashboard',
    target: '/dashboard',
    enabled: config.DASHBOARD_ENABLED
  },
  {
    requireRoles: 'prestige-tools-ticketing',
    title: 'processes',
    name: 'ProcessesList',
    target: '/ticketing/processes',
    enabled: config.TICKETING_ENABLED
  },
  {
    requireRoles: 'prestige-tools-ticketing',
    title: 'ticketingList',
    name: 'TicketingList',
    target: '/ticketing/tasks',
    enabled: config.TICKETING_ENABLED
  },
  {
    requireRoles: 'prestige-tools-reader',
    title: 'tasksHome',
    name: 'TasksHome',
    target: '/tasks',
    enabled: true
  }
]
