<template>
  <v-card class="mb-2">
    <v-card-text v-if="propertyId">
      <v-icon color="primary" @click="toggle" class="float-right">{{ icon }}</v-icon>
      <v-card-title class="pa-0">{{ $t('common.tenants') }}</v-card-title>
    </v-card-text>
    <v-divider></v-divider>
    <v-layout row wrap v-if="isOpen" class="pa-0 ml-2 mb-2">
      <template v-for="(item, index) in groupedPropertiesByHouseStreet">
        <TenantsList :item="item" :key="index" :loading="loading" :property-id="propertyId" />
      </template>
    </v-layout>
  </v-card>
</template>
<script>
import { mapActions } from 'vuex'
import TenantsList from './TenantsList'
import { formatDate } from '@/helpers/formatterHelper'

export default {
  name: 'TenantsView',
  components: {
    TenantsList
  },
  props: {
    propertyId: {
      type: String,
      default: ''
    },
    tenantsItems: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isOpen: true
    }
  },
  computed: {
    icon () {
      return this.isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
    },
    groupedPropertiesByHouseStreet () {
      return this.groupPropertiesByHouseStreet(this.tenantsItems)
    }
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    formatDateFunc: formatDate,
    toggle () {
      this.isOpen = !this.isOpen
    },
    groupPropertiesByHouseStreet (properties) {
      if (properties.length > 0) {
        let that = this
        properties.forEach(property => {
          property.currentTenantContractStartFormatted = that.formatDateFunc(property.currentTenantContractStart)
          property.currentTenantContractEndFormatted = that.formatDateFunc(property.currentTenantContractEnd)
          property.newTenantContractStartFormatted = that.formatDateFunc(property.newTenantContractStart)
        })
        const groupedProperties = properties.reduce((groupedProperties, property) => {
          const houseStreet = property.houseStreet
          if (!groupedProperties[houseStreet]) {
            groupedProperties[houseStreet] = []
          }
          groupedProperties[houseStreet].push(property)
          return groupedProperties
        }, {})
        return Object.keys(groupedProperties).map((houseStreet) => {
          return {
            houseStreet: houseStreet,
            properties: groupedProperties[houseStreet]
          }
        })
      } else {
        return []
      }
    }
  }
}
</script>
<style scoped lang="postcss">
</style>
