<template>
  <v-flex class="mt-4" xs12>
    <v-card>
      <v-card-text>
        <v-layout row wrap>
          <v-flex xs12 sm12 md5 lg5 xl5>
            <v-list class="pa-0">
              <v-list-item-content class="d-block">
                {{ $t('tasks.info.property') }}:
                [<a @click="onPropertyClicked(property.propertyId)">{{ property.propertyId }}</a>]
                {{ propertyAddressInfo }}
              </v-list-item-content>
              <v-list-item-content>
                <PropertyHouseEditor
                  :houseInfo="houseInfo"
                  :property-id="property.propertyId.toString()"
                  :edit-disabled="editDisabled"
                  @houseChanged="onHouseChange"
                />
              </v-list-item-content>
              <PropertyObjectsEditor
                :house-object-info="houseObjectInfo"
                :property-id="property.propertyId.toString()"
                :details-view="detailsView"
                :edit-disabled="editDisabled"
                @updateFormValues="saveObjectEditEditor"
                @propertyObjectChanged="onPropertyObjectChanged"
              />
              <ProcessInstanceInfo
                v-if="task && task.processInstanceId"
                :process-instance-id="task.processInstanceId"
              />
            </v-list>
          </v-flex>
          <v-flex xs12 sm12 md5 lg5 xl5>
            <v-list class="pa-0">
              <v-list-item-content>{{ $t('tasks.info.client') }}: {{ clientInfo }}</v-list-item-content>
              <v-list-item-content>{{ $t('tasks.info.caretaker') }}: {{ caretakerInfo }}</v-list-item-content>
              <v-list-item-content>{{ $t('tasks.info.administrativeArea') }}: {{ administrativeAreaInfo }}</v-list-item-content>
              <v-list-item-content v-if="taskUserId" class="taskCreator">
                {{ $t('tasks.info.createdBy') }}: <UserName :user-id="taskUserId" />
              </v-list-item-content>
              <RealEstateManagerEditor
                :real-estate-manager="form.realEstateManager"
                :details-view="detailsView"
                :edit-disabled="editDisabled"
                @updateFormValues="saveEditEditor"
              />
            </v-list>
          </v-flex>
          <v-flex xs12 sm12 md2 lg2 xl2 class=" pl-15 responsive">
              <v-list class="pa-0">
                <v-list-item-content>{{ $t('tasks.taskSource') }}: {{ task && task.taskSource ? $t('tasks.taskSources.' + task.taskSource) : $t('tasks.taskSources.TASK') }}</v-list-item-content>
                <v-list-item-content>{{ $t('tasks.competenceSum') }}</v-list-item-content>
                <v-list-item-content class="mt-1 headline red--text" v-show="formattedCompetenceSum">{{ formattedCompetenceSum }}</v-list-item-content>
              </v-list>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-flex>
</template>

<script>
import RealEstateManagerEditor from './RealEstateManagerEditor'
import PropertyObjectsEditor from './PropertyObjectsEditor'
import PropertyHouseEditor from './PropertyHouseEditor.vue'
import ProcessInstanceInfo from './ProcessInstanceInfo'
import UserName from '@/components/UserName'
import { openDashboardProperty } from '@/helpers/routesHelper'

export default {
  name: 'AbacusTaskInfo',
  props: {
    property: {
      type: Object,
      default: () => {}
    },
    task: {
      type: Object,
      default: () => {}
    },
    propertyObject: {
      type: Object,
      default: () => {}
    },
    detailsView: {
      type: Boolean,
      default: false
    },
    editDisabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ProcessInstanceInfo,
    PropertyObjectsEditor,
    RealEstateManagerEditor,
    PropertyHouseEditor,
    UserName
  },
  data () {
    return {
      form: {
        realEstateManager: this.property.realEstateManager
      }
    }
  },
  computed: {
    propertyAddressInfo () {
      return this.property.propertyStreet + ', ' + this.property.propertyZip + ' ' + this.property.propertyPlace
    },
    houseInfo () {
      return this.propertyObject ? '[' + (this.propertyObject.houseId || '') + '] ' + (this.propertyObject.houseStreet || '') : ''
    },
    houseObjectInfo () {
      return this.propertyObject ? '[' + (this.propertyObject.objectId || '') + '] ' + (this.propertyObject.objectText || '') : ''
    },
    clientAddress () {
      return '' + (this.property.client.line1 ? this.property.client.line1 + ', ' : '') + (this.property.client.line2 ? this.property.client.line2 + ', ' : '') + (this.property.client.line3 ? this.property.client.line3 + ', ' : '') + this.property.client.zip + ' ' + this.property.client.place
    },
    clientName () {
      return '' + this.property.client.firstName + ' ' + (this.property.client.lastName ? this.property.client.lastName + ', ' : '')
    },
    clientInfo () {
      return this.property.client ? '[' + this.property.client.addressId + '] ' + this.clientName + this.clientAddress : '[?]'
    },
    caretakerInfo () {
      return this.property.caretaker ? '[' + this.property.caretaker.addressId + '] ' + this.property.caretaker.firstName + ' ' + this.property.caretaker.lastName : ''
    },
    administrativeAreaInfo () {
      return '[' + (this.property.administrativeAreaId || '') + '] ' + (this.property.administrativeAreaText || '')
    },
    formattedCompetenceSum () {
      let value = (this.property.competenceSum / 1)
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'") + '.-'
    },
    taskUserId () {
      return this.task?.createdBy ? this.task.createdBy.toString() : null
    }
  },
  methods: {
    saveEditEditor (params) {
      Object.assign(this.form, params)
      this.$emit('updateFormValues', {
        ...this.form
      })
    },
    saveObjectEditEditor (params) {
      Object.assign(this.form, params)
      this.$emit('updateFormValues', {
        ...params
      })
    },
    onPropertyObjectChanged (object) {
      this.$emit('propertyObjectChanged', object)
    },
    onHouseChange (house) {
      this.$emit('houseChanged', house)
    },
    onPropertyClicked (propertyId) {
      openDashboardProperty(propertyId)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.layout {
  padding: 0 10px 0 10px;
}
.v-list-item__content {
  padding: 0;
}
.taskCreator {
  width: 100%;
  display: inline-block;
}
@media (max-width: 960px) {
  .xs12.responsive, .sm12.responsive {
    padding-left: 0 !important;
  }
}
</style>
