<template>
  <v-container fluid fill-height grid-list-lg>
    <v-layout row wrap id="layout">
      <v-flex xs12 sm12 md12 lg12>
        <v-layout row wrap>
          <v-flex xs12 sm12 md6 lg6>
            <DatePicker
              v-model="date"
              :label="$t('map.terminations.contractEnd')"
              :default-value-date="date"
            />
          </v-flex>
          <v-flex xs12 sm12 md6 lg6>
            <v-btn medium class="primary" @click="showAll">
              <v-icon medium class="pr-2">mdi-home-map-marker</v-icon>
              {{ $t('map.terminations.showAll') }} ({{ terminations.length }})
            </v-btn>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs12 sm12 md6 lg6>
            <GmapMap
              :center="{lat: 47.3362615, lng: 8.5130041}"
              :zoom="10"
              ref="mapRef"
              class="gmap-dimensions"
              @idle="filterMarkers()"
            >
              <gmap-info-window
                :options="infoOptions"
                :position="infoWindowPos"
                :opened="infoWinOpen"
                @closeclick="infoWinOpen = false"
              >
                <template v-if="infoWindowData">
                  <v-card-subtitle class="pa-0 font-weight-bold">
                    {{ $t('map.terminations.property') }}
                  </v-card-subtitle>
                  <v-card-text class="pa-0" v-if="infoWindowData.houseStreet">{{ infoWindowData.houseStreet }}</v-card-text>
                  <v-card-text class="pa-0" v-if="infoWindowData.zip || infoWindowData.place">{{ infoWindowData.zip }} {{ infoWindowData.place }}</v-card-text>
                  <v-card-subtitle class="pa-0 font-weight-bold">
                    {{ $t('map.terminations.tenant') }}
                  </v-card-subtitle>
                  <v-card-text class="pa-0" v-if="infoWindowData.currentTenantText">{{ infoWindowData.currentTenantText }}</v-card-text>
                </template>
              </gmap-info-window>
              <template>
                <GmapMarker
                  :key="property.id"
                  v-for="property in terminations"
                  :position="{lat: property.lat, lng: property.lng}"
                  :icon="getSiteIcon"
                  :clickable="true"
                  :draggable="false"
                  @click="displayPropertyInfoWindow(property)">
                </GmapMarker>
              </template>
            </GmapMap>
          </v-flex>
          <v-flex xs12 sm12 md6 lg6>
            <v-data-table
              :headers="headers"
              :items="filteredTerminations"
              :loading="loadingTerminations"
              :options.sync="options"
              :footer-props="{itemsPerPageOptions:itemsPerPageOptions, itemsPerPageText: itemsPerPageText}"
              class="elevation-1"
            >
              <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
              <template v-slot:[`item.zip`]="{ item }">
                <p class="mb-0" v-if="item.houseStreet">{{ item.houseStreet }}</p>
                <p class="mb-0" v-if="item.zip || item.place">{{ item.zip }} {{ item.place }}</p>
              </template>
              <template v-slot:[`item.objectText`]="{ item }">
                <p class="mb-0" v-if="item.objectText">{{ item.objectText }}</p>
              </template>
              <template v-slot:[`item.currentTenantText`]="{ item }">
                <p class="mb-0" v-if="item.currentTenantText">{{ item.currentTenantText }}</p>
              </template>
              <template v-slot:[`item.currentTenantContractEnd`]="{ item }">
                <p class="mb-0" v-if="item.currentTenantContractEnd">
                  {{ formatDateFunc(item.currentTenantContractEnd) }}
                </p>
              </template>
              <template slot="no-data">
                <v-alert class="mt-4" :value="true" color="error" icon="warning">
                  {{ $t('table.noData') }}
                </v-alert>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Vue from 'vue'
import GmapCluster from 'vue2-google-maps/dist/components/cluster'
import DatePicker from '@/components/DatePicker'
import moment from 'moment'
import { formatDate } from '@/helpers/formatterHelper'
import { mapActions } from 'vuex'
Vue.component('GmapCluster', GmapCluster)

export default {
  name: 'MapTerminations',
  components: {
    DatePicker
  },
  watch: {
    'date': 'loadTerminations'
  },
  data () {
    return {
      config: {
        clusterTerminations: true
      },
      loadingTerminations: false,
      terminations: [],
      filteredTerminations: [],
      date: null,
      options: {
        page: 1
      },
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      infoWindowPos: null,
      infoWinOpen: false,
      infoWindowData: null
    }
  },
  computed: {
    headers () {
      return [
        {
          text: this.$t('map.terminations.property'),
          align: 'left',
          value: 'zip'
        },
        {
          text: this.$t('map.terminations.object'),
          align: 'left',
          value: 'objectText'
        },
        {
          text: this.$t('map.terminations.tenant'),
          align: 'left',
          value: 'currentTenantText'
        },
        {
          text: this.$t('map.terminations.contractEnd'),
          align: 'left',
          value: 'currentTenantContractEnd',
          sortable: false
        }
      ]
    },
    itemsPerPageText () {
      return this.$t('common.itemsPerPageText')
    },
    itemsPerPageOptions () {
      return [
        10,
        25,
        {
          'text': this.$t('common.all'),
          'value': -1
        }
      ]
    },
    getSiteIcon () {
      return require('@/assets/images/icons-map/property-green.png')
    }
  },
  created () {
    this.date = moment().endOf('quarter').format('YYYY-MM-DD')
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    formatDateFunc: formatDate,
    loadTerminations () {
      this.terminations = []
      this.filteredTerminations = []
      this.loadingTerminations = true
      let that = this
      this.$abacusProperty.getTerminatedObjects(this.date).then(response => {
        if (response.data && response.data.length > 0) {
          that.terminations = response.data
          let id = 0
          that.terminations.map(item => {
            item.id = id
            id += 1
          })
          that.filterMarkers()
        }
        that.loadingTerminations = false
        return true
      }).catch(function (error) {
        that.setErrorSnackbar(error)
        that.loadingTerminations = false
      })
    },
    filterMarkers () {
      this.options.page = 1
      let bounds = this.$refs.mapRef.$mapObject.getBounds()
      if (bounds) {
        if (bounds.Ua && bounds.La) {
          let latRange = bounds.Ua
          let lngRange = bounds.La
          if (latRange && lngRange && this.terminations.length) {
            this.filteredTerminations = this.terminations.filter(m => {
              return (m.lat > latRange.g && m.lat < latRange.i) && (m.lng > lngRange.g && m.lng < lngRange.i)
            })
          } else {
            this.filteredTerminations = this.terminations
          }
        } else if (bounds.Eb && (bounds.mc || bounds.oc)) {
          let boundsLat = bounds.mc || bounds.oc
          let boundsLng = bounds.Eb
          let latRangeMin = boundsLat.g
          let latRangeMax = boundsLat.i
          let lngRangeMin = boundsLng.g
          let lngRangeMax = boundsLng.i
          if (latRangeMin && latRangeMax && lngRangeMin && lngRangeMax && this.terminations.length) {
            this.filteredTerminations = this.terminations.filter(m => {
              return (m.lat > latRangeMin && m.lat < latRangeMax) && (m.lng > lngRangeMin && m.lng < lngRangeMax)
            })
          } else {
            this.filteredTerminations = this.terminations
          }
        } else {
          this.filteredTerminations = this.terminations
        }
      }
    },
    displayPropertyInfoWindow (property) {
      let markerPosition = {
        lat: property.lat,
        lng: property.lng
      }
      this.infoWindowPos = markerPosition
      this.infoWindowData = property
      this.infoWinOpen = true
      this.$refs.mapRef.$mapPromise.then((map) => {
        map.panTo(markerPosition)
      })
    },
    showAll () {
      this.options.page = 1
      let bounds = this.$refs.mapRef.$mapObject.getBounds()
      this.terminations.forEach(item => {
        let position = { lat: item.lat, lng: item.lng }
        bounds.extend(position)
      })
      this.$refs.mapRef.$mapObject.fitBounds(bounds)
      let center = this.$refs.mapRef.$mapObject.getCenter()
      this.$refs.mapRef.$mapObject.setCenter(center)
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="postcss">
/deep/ .datepicker-input {
  padding: 0px;
  margin: 0px;
}
.gmap-dimensions {
  width: 100%;
  height: 700px;
}
/deep/ tr:nth-child(odd) {
  background: #f8f8f8;
}
</style>
