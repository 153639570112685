<template>
  <v-dialog v-model="mutableShowDialog" max-width="500px" @keydown.esc="closeDialog" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t('tasks.comment.title') }}</span>
        <v-btn icon absolute right>
          <v-icon class="clear" color="primary" :size="28" @click.native="closeDialog"/>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-0 pb-2">
        {{ comment }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="closeDialog">{{ $t('common.buttons.cancel') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'CommentDialog',
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    comment: {
      type: String,
      default: ''
    }
  },
  methods: {
    closeDialog () {
      this.$emit('closeDialog')
    }
  },
  computed: {
    mutableShowDialog () {
      return this.showDialog
    }
  }
}
</script>
