<template>
  <div>
    <v-subheader>
      {{ $t('ticketingSystem.processes.interestedPerson.creditTrustTitle') }}
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            :color="isVerified ? 'green' : 'red'"
            v-bind="attrs"
            v-on="on"
            class="mb-1"
          >
            {{ isVerified ? 'checked' : 'close' }}
          </v-icon>
        </template>
        <span>
              {{
          isVerified
            ? $t('ticketingSystem.processes.interestedPerson.verified')
            : $t('ticketingSystem.processes.interestedPerson.notVerified')
          }}
            </span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <v-btn v-if="resident.verifyCertificateFile" icon ripple @click.stop="downloadAttachment(resident.verifyCertificateFile)" :title="$t('common.buttons.download')">
        <v-icon color="blue lighten-1">file_download</v-icon>
      </v-btn>
    </v-subheader>
    <template v-if="isVerified">
      <v-container fluid>
        <v-layout>
          <v-flex>
            {{ resident.verifyCertificateResponse.identification.firstName }} {{ resident.verifyCertificateResponse.identification.lastName }}
            <v-icon :color="nameMatch ? 'green' : 'orange'" class="mb-1">
              {{ nameMatch ? 'checked' : 'warning' }}
            </v-icon>
          </v-flex>
          <v-flex>
            {{$t('renting.publicForm.fields.personInfo.dateOfBirth')}}:
            {{ formatDateFunc(resident.verifyCertificateResponse.identification.dateOfBirth) }}
            <v-icon :color="dateOfBirthMatch ? 'green' : 'red'" class="mb-1">
              {{ dateOfBirthMatch ? 'checked' : 'close' }}
            </v-icon>
          </v-flex>
          <v-flex>
            {{ $t('ticketingSystem.processes.interestedPerson.registration') }}: {{ formatDateFunc(resident.verifyCertificateResponse.verification.registeredAt) }}
          </v-flex>
        </v-layout>
      </v-container>
    </template>
    <template v-if="resident.createCertificateResponse">
      <v-subheader>
        {{ $t('ticketingSystem.processes.interestedPerson.orderedCertificate') }}
      </v-subheader>
      <v-list-item>
        <v-simple-table>
            <tbody>
              <tr>
                <td>{{ $t('ticketingSystem.processes.interestedPerson.createCertificate.issuable') }}</td>
                <td>
                  <v-icon
                    :color="certificateIssuable ? 'green' : 'red'"
                    v-bind="attrs"
                    v-on="on"
                    class="mb-1"
                  >
                    {{ certificateIssuable ? 'checked' : 'close' }}
                  </v-icon>
                </td>
              </tr>
              <tr>
                <td>{{ $t('ticketingSystem.processes.interestedPerson.createCertificate.crifMatch') }}</td>
                <td>
                  <v-icon
                    :color="crifMatch ? 'green' : 'red'"
                    v-bind="attrs"
                    v-on="on"
                    class="mb-1"
                  >
                    {{ crifMatch ? 'checked' : 'close' }}
                  </v-icon>
                </td>
              </tr>
              <tr v-if="crifMatch">
                <td>{{ $t('ticketingSystem.processes.interestedPerson.createCertificate.crifApproval') }}</td>
                <td>
                  <v-icon
                    :color="crifApproval ? 'green' : 'red'"
                    v-bind="attrs"
                    v-on="on"
                    class="mb-1"
                  >
                    {{ crifApproval ? 'checked' : 'close' }}
                  </v-icon>
                </td>
              </tr>
              <tr>
                <td>{{ $t('ticketingSystem.processes.interestedPerson.createCertificate.intrumMatch') }}</td>
                <td>
                  <v-icon
                    :color="intrumMatch ? 'green' : 'red'"
                    v-bind="attrs"
                    v-on="on"
                    class="mb-1"
                  >
                    {{ intrumMatch ? 'checked' : 'close' }}
                  </v-icon>
                </td>
              </tr>
              <tr v-if="intrumMatch">
                <td>{{ $t('ticketingSystem.processes.interestedPerson.createCertificate.intrumApproval') }}</td>
                <td>
                  <v-icon
                    :color="intrumApproval ? 'green' : 'red'"
                    v-bind="attrs"
                    v-on="on"
                    class="mb-1"
                  >
                    {{ intrumApproval ? 'checked' : 'close' }}
                  </v-icon>
                </td>
              </tr>
            </tbody>
        </v-simple-table>
      </v-list-item>
    </template>
  </div>
</template>

<script>
import { formatDate } from '@/helpers/formatterHelper'
import { useRentingForm } from '@/use/useRentingForm'
const { downloadResidentAttachment } = useRentingForm()

export default {
  name: 'ResidentCertificatePreview',
  props: {
    resident: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    isVerified () {
      return this.resident.verifyCertificateResponse?.verification?.issuerVerified ?? false
    },
    certificateIssuable () {
      return this.resident.createCertificateResponse?.certificateIssuable
    },
    crifApproval () {
      return this.resident.createCertificateResponse?.crifApproval
    },
    crifMatch () {
      return this.resident.createCertificateResponse?.crifMatch
    },
    intrumApproval () {
      return this.resident.createCertificateResponse?.intrumApproval
    },
    intrumMatch () {
      return this.resident.createCertificateResponse?.intrumMatch
    },
    nameMatch () {
      return this.resident.verifyCertificateResponse?.identification?.firstName === this.resident.firstName &&
        this.resident.verifyCertificateResponse?.identification?.lastName === this.resident.lastName
    },
    dateOfBirthMatch () {
      return this.resident.verifyCertificateResponse?.identification?.dateOfBirth === this.resident.dateOfBirth
    }
  },
  methods: {
    formatDateFunc: formatDate,
    downloadAttachment (fileName) {
      downloadResidentAttachment(fileName)
    }
  }
}
</script>
<style scoped>
.width-fill {
  width: -webkit-fill-available;
}
</style>
