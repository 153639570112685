import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export function getColor (percent) {
  if (percent < 25) {
    return 'red'
  } else if (percent < 50) {
    return 'orange'
  } else if (percent < 75) {
    return 'amber'
  } else {
    return 'teal'
  }
}

export function formatVolume (value) {
  let val = (value / 1)
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export function setFileInfo (response) {
  const headers = response.headers
  const fileName = headers['content-disposition'].split('filename=')[1]
  const file = {
    data: response.data,
    name: fileName ? fileName.substring(1, fileName.length - 1) : ''
  }
  return file
}

export function downloadFile (excelFile) {
  let fileDownload = require('js-file-download')
  fileDownload(excelFile.data, excelFile.name)
}
