<template>
  <v-flex class="mt-3" xs12 sm12 md8 lg8 xl8 id="taskInfo">
    <v-card height="100%">
      <v-card-title>
        <v-container fluid grid-list-xs class="pa-0">
          <v-layout row wrap>
            <v-flex sm6>
              <v-radio-group
                v-model="form.taskType"
                class="mt-0"
                :label="$t('tasks.createTask.typeTitle')"
                :disabled="readOnly"
                @change="updateFormValues"
              >
                <v-radio
                  v-for="(item, i) in taskTypeOptions"
                  :key="i" :label="$t('tasks.types.' + item.value)"
                  :value="item.value"
                ></v-radio>
              </v-radio-group>
            </v-flex>
            <v-flex sm6>
              <v-radio-group :label="$t('tasks.createTask.insuranceCase')" class="mt-0">
                <v-layout>
                  <v-checkbox
                    v-model="form.insuranceCase"
                    :label="$t('common.options.yes')"
                    :disabled="readOnly"
                    @change="updateFormValues"
                  ></v-checkbox>
                </v-layout>
              </v-radio-group>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex sm6 class="pr-5">
              <v-text-field
                v-model="form.subjectLine1"
                append-icon="subject"
                :label="$t('tasks.createTask.subject1')"
                :disabled="readOnly"
                @input="updateFormValues"
              >
              </v-text-field>
            </v-flex>
            <v-flex sm6>
              <v-text-field
                v-model="form.subjectLine2"
                append-icon="subject"
                :label="$t('tasks.createTask.subject2')"
                :disabled="readOnly"
                @input="updateFormValues"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-textarea
            v-model="form.jobDescription"
            :disabled="readOnly"
            :label="$t('tasks.createTask.jobDescription')"
            @input="updateFormValues"
          ></v-textarea>
        </v-container>
      </v-card-title>
    </v-card>
  </v-flex>
</template>

<script>
import { taskTypeOptions, insuranceCaseOptions } from '@/config/options/task'

export default {
  name: 'TaskOrder',
  props: {
    taskType: {
      type: String,
      default: ''
    },
    jobDescription: {
      type: String,
      default: ''
    },
    subjectLine1: {
      type: String,
      default: ''
    },
    subjectLine2: {
      type: String,
      default: ''
    },
    insuranceCase: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      taskTypeOptions: taskTypeOptions,
      insuranceCaseOptions: insuranceCaseOptions,
      form: {
        taskType: this.taskType,
        insuranceCase: this.insuranceCase,
        subjectLine1: this.subjectLine1,
        subjectLine2: this.subjectLine2,
        jobDescription: this.jobDescription
      }
    }
  },
  watch: {
    'subjectLine2': 'updateSubjectLine2',
    'subjectLine1': 'updateSubjectLine1'
  },
  methods: {
    updateSubjectLine1 () {
      this.form.subjectLine1 = this.subjectLine1
    },
    updateSubjectLine2 () {
      this.form.subjectLine2 = this.subjectLine2
    },
    updateFormValues () {
      this.$emit('updateFormValues', {
        ...this.form
      })
    }
  }
}
</script>
