<template>
  <v-container class="column pa-2">
    <v-alert class="ma-2 mr-10" :value="true" color="error" icon="warning" v-if="ticketLoadError">
      {{ $t('ticketingSystem.processes.notFound') }}
    </v-alert>
    <template v-else>
      <ProcessInfo
        :process="ticketingTicket"
        :process-variables="processVariables"
        @loadProcess="loadProcessDetails"
        @openTaskDetailsDialog="openTaskDetailsDialog"
        @startRelatedProcess="startRelatedProcess"
      />
      <v-layout wrap>
        <v-flex class="mt-2 tasks-column" xs12 sm12 md8 lg8 xl8>
          <ProcessOpenTasks
            :open-tasks="ticketingTicket ? ticketingTicket.openTasks : []"
            @openTaskDetailsDialog="openTaskDetailsDialog"
          />
          <ProcessCompletedTasks
            :completed-tasks="ticketingTicket ? ticketingTicket.completedTasks : []"
            @openTaskDetailsDialog="openTaskDetailsDialog"
          />
          <v-layout wrap>
            <v-flex xs12 sm12 md7 lg7 xl7 class="mt-2 comment-column">
              <v-card class="pa-6">
                <TicketingHistory
                  ref="ticketingHistory"
                  :process-details="true"
                  :add-comments-enabled="true"
                  :process-instance-id="processInstanceId"
                  :process-variables="processVariables"
                  :additional-links="processReferencesLinks"
                  @loadProcessReferences="loadProcessReferences"
                  @emailFileSent="onEmailFileSent"
                />
              </v-card>
            </v-flex>
            <v-flex xs12 sm12 md5 lg5 xl5 class="mt-2">
              <ProcessVariables
                ref="processVariables"
                :process-variables="processVariables"
                :process-instance-id="processInstanceId"
                :created-on="startedOn"
                @loadProcessVariables="loadProcessVariables"
              />
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex class="mt-2 process-column process-references-column" xs12 sm12 md4 lg4 xl4>
          <ProcessInstanceContext
            :process-instance="true"
            :process="ticketingTicket"
            :process-variables="processVariables"
            :process-references="processReferences"
            :process-references-links="processReferencesLinks"
            @loadDetails="loadProcessDetails"
            @loadProcessReferences="loadProcessReferences">
          </ProcessInstanceContext>
        </v-flex>
      </v-layout>
    </template>
    <TaskDetailsDialog
      v-if="showTaskDetailsDialog"
      :show-dialog="showTaskDetailsDialog"
      @closeDialog="closeDialog"
      @openTaskDetailsDialog="openTaskDetailsDialog"
      @loadProcess="loadProcessDetails"
    />
    <TicketDialog
      v-if="shouldShowNewTicketDialog"
      :start-related-process="true"
      :process-instance-id="processInstanceId"
      :new-links="processReferencesLinks"
      @closeDialog="shouldShowNewTicketDialog = false"
      @loadProcessReferences="loadProcessReferences"
      @loadProcessVariables="loadProcessVariables"
    />
    <SpinnerOverlay :loading="isLoading" />
  </v-container>
</template>

<script>
import ProcessInfo from '../shared/ProcessInfo'
import ProcessOpenTasks from './ProcessOpenTasks'
import ProcessCompletedTasks from './ProcessCompletedTasks'
import TicketingHistory from '../shared/messages/TicketingHistory'
import ProcessVariables from './ProcessVariables'
import ProcessInstanceContext from '../shared/ProcessInstanceContext'
import TicketDialog from '@/features/ticketing/dialogs/TicketDialog'
import TaskDetailsDialog from '../dialogs/TaskDetailsDialog'
import SpinnerOverlay from '@/components/SpinnerOverlay'
import { mapActions, mapState } from 'vuex'
import { TICKETING_TICKET } from '../graphql-apollo/ticketingTicket.gql'

export default {
  name: 'ProcessDetails',
  components: {
    ProcessInfo,
    ProcessOpenTasks,
    ProcessCompletedTasks,
    ProcessVariables,
    TicketingHistory,
    ProcessInstanceContext,
    TaskDetailsDialog,
    TicketDialog,
    SpinnerOverlay
  },
  data () {
    return {
      processReferences: {},
      timer: [],
      ticketLoadError: '',
      shouldShowNewTicketDialog: false,
      processVariables: [],
      reloadProcess: false,
      firstSpinner: true
    }
  },
  apollo: {
    ticketingTicket: {
      query: TICKETING_TICKET,
      skip () {
        return !this.processInstanceId
      },
      error (error) {
        this.ticketLoadError = error.message
      },
      variables () {
        return {
          ticketId: this.processInstanceId
        }
      }
    }
  },
  computed: {
    ...mapState('ticketingSystem', ['ticketingUsers']),
    processInstanceId () {
      return this.$route.params.processInstanceId
    },
    processReferencesLinks () {
      return this.processReferences.links || []
    },
    isLoading () {
      return this.$apollo.loading
    },
    showTaskDetailsDialog () {
      return this.$route.query.taskId !== undefined && this.$route.query.taskId !== null && this.$route.query.taskId !== ''
    },
    startedOn () {
      return this.ticketingTicket?.startedOn || ''
    }
  },
  watch: {
    'processInstanceId': 'loadProcessDetails'
  },
  mounted () {
    if (this.processInstanceId) {
      this.loadProcessDetails()
      let processDiv = document.getElementById(this.processInstanceId)
      if (processDiv) {
        processDiv.scrollIntoView()
      }
    }
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    loadProcessDetails () {
      if (this.$apollo.queries.ticketingTicket) {
        this.ticketLoadError = null
        this.$apollo.queries.ticketingTicket.refresh()
      }
      // TODO remove, once we can load this data with graphql
      this.loadProcessReferences()
      this.loadProcessVariables()
      this.$refs.ticketingHistory.loadTicketingHistory()
    },
    loadProcessVariables () {
      // TODO remove, once we can load this data with graphql
      let that = this
      if (this.processInstanceId) {
        return this.$prestigeTSProcess.getVariablesForProcess(this.processInstanceId).then(function (response) {
          that.processVariables = response.data
          that.processVariables.sort((a, b) => (a.variableName > b.variableName) ? 1 : (b.variableName > a.variableName ? -1 : 0))
        }).catch(error => {
          that.setErrorSnackbar(error)
        })
      }
    },
    loadProcessReferences () {
      // TODO remove, once we can load this data with graphql
      let that = this
      if (this.processInstanceId) {
        this.$prestigeTSProcess.getProcessReferences(this.processInstanceId).then(function (response) {
          if (response.data) {
            that.processReferences = response.data
          }
          return true
        }).catch(error => {
          that.setErrorSnackbar(error)
        })
      }
    },
    openTaskDetailsDialog (taskId) {
      const query = { ...this.$route.query, taskId: taskId }
      this.$router.replace({ query })
    },
    closeDialog () {
      this.reloadProcess = true
      if (this.$route.query.taskId) {
        const query = Object.assign({}, this.$route.query)
        delete query.taskId
        this.$router.replace({ query })
      }
      this.loadProcessDetails()
    },
    startRelatedProcess () {
      this.shouldShowNewTicketDialog = true
    },
    onEmailFileSent () {
      this.$refs.processVariables.$refs.processFileVariables.$refs.ticketFileVariables.$apollo.queries.ticketingHistory.refresh()
    }
  }
}
</script>

<style scoped lang="postcss">
/deep/ .v-dialog--fullscreen .diagram-container {
  height: 80vh;
}
.column {
  background-color: rgb(211, 211, 211, 0.2);
  max-width: 100%;
}
.process-column {
  border-radius: 4px;
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
  overflow-y: auto;
}
@media (min-width: 960px) {
  .diagram-column {
    margin-right: 8px;
  }
  .comment-column {
    padding-right: 8px;
  }
}
.spinner {
  margin-left: 350px;
}
</style>
