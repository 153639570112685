<template>
  <v-card class="mb-2 pa-0" v-if="filteredUsers.length > 0">
    <v-card-text class="pa-4">
      <v-icon color="primary" @click="toggle" class="float-right">{{ icon }}</v-icon>
      <v-card-subtitle class="pa-0 font-weight-bold subtitle-1">
        {{ $t('ticketingSystem.ticketingUsers') }}
      </v-card-subtitle>
    </v-card-text>
    <v-divider></v-divider>
    <v-list v-if="isOpen" class="context overflow-auto">
      <template v-for="(user, index) in filteredUsers">
        <v-list-item class="pl-0 pr-0" :key="index">
          <v-list-item-content class="pa-0">
            <v-card-text>
              <p class="pa-0 ma-0" v-if="user.name">{{ user.name }}</p>
              <p class="pa-0 ma-0" v-if="user.email">{{ user.email }}</p>
            </v-card-text>
          </v-list-item-content>
          <v-list-item-action class="ma-3">
            <ActionsMenu
              :item="getItem(user)"
              :actions="actions"
              :delete-action="deleteAction"
            />
          </v-list-item-action>
        </v-list-item>
        <v-divider :key="index + '_divider'"></v-divider>
      </template>
    </v-list>
  </v-card>
</template>
<script>

import ActionsMenu from '@/components/ActionsMenu'
import { mapState } from 'vuex'

export default {
  name: 'ProcessUsers',
  components: {
    ActionsMenu
  },
  props: {
    userLinks: {
      type: Array,
      default: () => []
    },
    deleteAction: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      isOpen: true
    }
  },
  computed: {
    ...mapState('ticketingSystem', ['ticketingUsers']),
    icon () {
      return this.isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
    },
    filteredUsers () {
      let that = this
      let users = this.ticketingUsers ? this.ticketingUsers.filter((x) => that.userLinks.includes(x.userId)) : []
      if (that.userLinks && that.userLinks.includes('bot')) {
        users.push({
          userId: 'bot',
          linkName: 'user',
          linkValue: 'bot',
          name: 'Bot'
        })
      }
      return users
    },
    actions () {
      return [
        { title: this.$t('dashboard.actions.newTicket'), icon: 'mdi-ticket', action: this.onNewTicket }
      ]
    }
  },
  methods: {
    toggle () {
      this.isOpen = !this.isOpen
    },
    getItem (item) {
      return Object.assign(item, { linkName: 'user', linkValue: item.userId })
    },
    onNewTicket (item) {
      let links = [{
        linkName: item.linkName,
        linkValues: item.linkValue ? [item.linkValue] : []
      }]
      this.$emit('openNewTicketDialog', links)
    }
  }
}
</script>
<style scoped lang="postcss">
</style>
