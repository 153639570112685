<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm12 md12 lg10 xl10>
        <v-card class="elevation-12">
          <v-form ref="form" v-model="valid" class="create-form">
            <v-toolbar dark :color="appBarBackgroundColor" class="mbtm-30">
              <v-toolbar-title>
                <v-img height="70px" width="70px" class="mt-10" src="/Logo-Prestige.png"></v-img>
              </v-toolbar-title>
              <v-toolbar-title class="title-lng pl-4 font-weight-bold d-flex justify-space-between align-center">
                <span>{{ $t('renting.publicForm.title') }}</span>
                <v-btn-toggle
                  v-model="activeLng"
                  mandatory
                  class="lng-group"
                >
                  <v-btn 
                    v-for="(item, index) in languageList"
                    @click="updateLanguage(item.value)"
                    :value="item.value"
                    class="lng-btn"
                    :key="'language-' + index" 
                    :color="appBarBackgroundColor"
                  >
                    <img :src="require('../../assets/icons/' + item.value + '.svg')" />
                  </v-btn>
                </v-btn-toggle>
              </v-toolbar-title>
              <v-fab-transition>
                <v-btn
                  v-show="showAutoFillButton && verified && !formSubmitted"
                  class="cy-rental-form-autofill"
                  color="orange"
                  fab
                  dark
                  small
                  absolute
                  bottom
                  left
                  @click="autoFill"
                >
                  <v-icon>mdi-auto-fix</v-icon>
                </v-btn>
              </v-fab-transition>
            </v-toolbar>
            <v-card-text v-if="verificationLoading">
              <v-progress-linear :indeterminate="true"></v-progress-linear>
            </v-card-text>
            <template v-else-if="formSubmitted">
              <v-card-text>
                <v-alert class="mb-5 cy-rental-form-submit-success" :value="true" type="success">
                  {{ $t('renting.publicForm.alerts.success') }}
                </v-alert>
              </v-card-text>
              <v-card-actions class="pl-4 pr-4">
                <a href="https://www.prestige.ch/impressum/" target="_blank">{{ $t('feedback.imprint') }}</a>
              </v-card-actions>
            </template>
            <template v-else-if="verified">
              <v-card-text class="pa-0 px-4">
                <v-alert class="mb-0 cy-rental-form-submit-failure" v-if="errorMessage" :value="errorMessage" type="warning">
                  {{ errorMessage }}
                </v-alert>
              </v-card-text>
              <v-container>
                <div>
                  <v-layout>
                    <v-flex xs12 sm12 md12 lg12 xl12>
                      <p>{{ $t('renting.publicForm.formInfo') }}</p>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs12 sm12 md12 lg12 xl12>
                      <p>
                        {{ $t('renting.publicForm.formInfoAgreement') }}
                      </p>
                      <v-alert color="info" dense text v-if="isDemoToken">
                        Moosburgstrasse 17, 8307 Effretikon<br />
                        Erdgeschoss 2-Zimmerwohnung links<br />
                        {{ rentingLabel }}: 01.01.2025
                      </v-alert>
                      <v-alert color="info" dense text v-else-if="formInfo.success">
                        {{ formInfo.success.houseStreet }}, {{ formInfo.success.zip}} {{ formInfo.success.place}}<br />
                        {{ formInfo.success.floorText}} {{ formInfo.success.objectText}}<br />
                        {{ rentingLabel }}: {{ formatDate(formInfo.success.earliestStart) }}
                      </v-alert>
                    </v-flex>
                  </v-layout>
                </div>
                <v-layout class="mb-2 flex-column flex-md-row">
                  <v-flex xs12 sm12 md4>
                    <DatePicker
                      dense
                      ref="desiredMoveInDate"
                      :label="$t('renting.publicForm.desiredMoveInDate')"
                      v-model="form.desiredMoveInDate"
                      :default-value="form.desiredMoveInDate ? true : false"
                      :default-value-date="earliestStart(formInfo.success, form.desiredMoveInDate)"
                      :disable-past-dates="true"
                      :key="dateComponentKey"
                      required
                    />
                  </v-flex>
                  <v-flex v-if="formInfo.success" xs12 sm12 md8 class="ml-4">
                    <v-alert v-if="inThePast(form.desiredMoveInDate, formInfo.success.earliestStart)" type="warning">{{ $t('renting.publicForm.desiredDateAlert') }}</v-alert>
                  </v-flex>
                </v-layout>

                <!-- RESIDENTS -->
                <ResidentsForm
                  :form="form"
                  :residential="residential"
                  @validate="validate"
                ></ResidentsForm>

                <!--  ADDITIONAL INFORMATION -->
                <NewApartmentForm
                  :form="form.newApartment"
                  :residential="residential"
                />

                <v-card class="pa-4 mb-4">
                  <v-row>
                    <v-col cols="12">
                      <v-checkbox
                        class="confirmation"
                        v-model="form.confirmation"
                        :label="$t('renting.publicForm.formNote')"
                        :value="true"
                        required
                        :rules="[rules.radioRequired]"
                      ></v-checkbox>
                      <p>{{ $t('renting.publicForm.formNoteIncompleted') }}</p>
                    </v-col>
                  </v-row>
                </v-card>
              </v-container>
              <v-card-actions class="pl-4 pr-4">
                <a href="https://www.prestige.ch/impressum/" target="_blank">{{ $t('renting.publicForm.imprint') }}</a>
                <v-spacer></v-spacer>
                <CaptchaForm v-if="!shouldDisplayCaptcha"
                             :show-error-message="showCaptchaErrorMessage"
                             @successSwipe="showCaptchaErrorMessage = false"/>
                <v-btn color="#6e2c6b" class="white--text cy-rental-form-submit" :loading="submitLoading" @click.stop="submit">
                  {{ $t('common.buttons.send') }}
                </v-btn>
              </v-card-actions>
            </template>
            <template v-else>
              <v-card-text>
                <v-alert :value="true" type="warning" class="cy-rental-form-invalid-token">
                  <span v-if="formInfo.invalid && formInfo.invalid.reason">{{ $t('renting.publicForm.alerts.' + formInfo.invalid.reason) }}</span>
                  <span v-else v-html="$t('renting.publicForm.alerts.invalidLink')"/>
                </v-alert>
              </v-card-text>
              <v-card-actions class="pl-4 pr-4">
                <a href="https://www.prestige.ch/impressum/" target="_blank">{{ $t('renting.publicForm.imprint') }}</a>
              </v-card-actions>
            </template>
          </v-form>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import ResidentsForm from './components/renting-forms/ResidentsForm'
import NewApartmentForm from './components/renting-forms/NewApartmentForm'
import CaptchaForm from '../../components/CaptchaForm'
import DatePicker from '../../components/DatePicker.vue'
import moment from 'moment'

import * as autoFillPublicRentalFormHelper from './helpers/autoFillPublicRentalFormHelper'
import * as demoPublicRentalFormHelper from './helpers/demoPublicRentalFormHelper'
import * as interestedPersonHelper from './helpers/interestedPersonHelper'
import * as validationHelper from '@/helpers/validationHelper'
import { formatDate } from '@/helpers/formatterHelper'

import { appBarBackgroundColor } from '@/config/options/appBar'
import { PUBLIC_RENTAL_FORM } from '@/config/options/routes/rentingRoutes'
import { mapState } from 'vuex'
import { languageList } from '@/config/options/languages'

export const PARKING = 'PARKING'
export const RESIDENTIAL = 'RESIDENTIAL'

export default {
  name: PUBLIC_RENTAL_FORM,
  components: {
    ResidentsForm,
    NewApartmentForm,
    CaptchaForm,
    DatePicker
  },
  created () {
    this.setPublicFormData()
    this.shouldDisplayCaptcha = this.rentingFormCaptchaStatus
    this.updateLanguage(this.$i18n.locale)
    this.activeLanguage()
  },
  data () {
    return {
      errorMessage: '',
      valid: true,
      verified: true,
      verificationLoading: false,
      submitClicked: false,
      submitLoading: false,
      formSubmitted: false,
      form: null,
      dateComponentKey: 0,
      formInfo: {},
      discoveryList: ['NEWSPAPER', 'PREVIOUS_TENANT', 'INTERNET', 'ACQUAINTANCE', 'OTHER'],
      rules: {
        required: (value) => (!!value && value !== undefined) || this.requiredMessage,
        radioRequired: (value) => (value !== undefined && value !== '' && value !== null) || this.requiredMessage
      },
      showCaptchaErrorMessage: false,
      shouldDisplayCaptcha: true,
      appBarBackgroundColor: appBarBackgroundColor,
      languageList: languageList,
      activeLng: 'en',
      residential: false
    }
  },
  watch: {
    // call again the method if the route changes
    '$route': 'verifyToken',
    formInfo: {
      handler () {
        if (this.submitClicked) {
          this.validate()
        }
      },
      deep: true
    }
  },
  mounted () {
    if (!this.isDemoToken) {
      this.verifyToken()
    }
  },
  computed: {
    ...mapState('captcha', ['rentingFormCaptchaStatus']),
    requiredMessage () {
      return this.$t('common.errors.required')
    },
    token () {
      return this.$route.params.token
    },
    isDemoToken () {
      return this.token === 'DEMO'
    },
    showAutoFillButton () {
      return this.$config.ENVIRONMENT === 'local' || this.$config.ENVIRONMENT === 'dev'
    },
    rentingLabel () {
      return this.residential ? this.$t('renting.publicForm.earliestStartResidential') : this.$t('renting.publicForm.earliestStartParking')
    }
  },
  methods: {
    formatDate: formatDate,
    setPublicFormData () {
      this.form = this.isDemoToken ? demoPublicRentalFormHelper.initForm() : interestedPersonHelper.initForm()
    },
    verifyToken () {
      this.verified = false
      this.verificationLoading = true
      this.errorMessage = ''
      this.feedbackSubmitted = false
      let that = this
      this.$prestigeRentalForm.verifyRentalCase(this.$route.params.token).then(function (response) {
        that.verified = response.data.isValid
        that.residential = response.data.objectType === RESIDENTIAL
        if (that.verified) {
          that.formInfo.success = response.data
        }
        that.verificationLoading = false
      }).catch(error => {
        let errorResponse = error.response
        if (errorResponse) {
          that.setErrorMessage(errorResponse)
        }
        that.verificationLoading = false
      })
    },
    autoFill () {
      this.form.residents = []
      this.$nextTick(() => {
        this.forceRerenderDatePicker()
        this.form = this.residential ? autoFillPublicRentalFormHelper.initResidentalForm() : autoFillPublicRentalFormHelper.initParkingForm() 
        this.$refs.form.validate()
      })
    },
    validate () {
      if (this.submitClicked) {
        let valid = true
        if (!this.$refs.form.validate()) {
          valid = false
        }
        return valid
      }
    },
    submit () {
      this.isDemoToken ? this.submitDemoForm() : this.submitRentalForm()
    },
    submitDemoForm () {
      if (!this.rentingFormCaptchaStatus) {
        this.showCaptchaErrorMessage = true
        return false
      } else {
        this.showCaptchaErrorMessage = false
        this.formSubmitted = true
      }
    },
    submitRentalForm () {
      this.submitClicked = true
      if (this.validate()) {
        if (!this.rentingFormCaptchaStatus) {
          this.showCaptchaErrorMessage = true
          return
        } else {
          this.showCaptchaErrorMessage = false
        }
        this.submitLoading = true
        this.errorMessage = ''
        let that = this
        this.$prestigeRentalForm.createRentalForm(this.$route.params.token, this.form).then(function (response) {
          if (response.status === 200) {
            that.formSubmitted = true
            that.submitLoading = false
          }
        }).catch(error => {
          let errorResponse = error.response
          if (errorResponse) {
            that.setErrorMessage(errorResponse)
          }
          that.submitClicked = true
          that.submitLoading = false
        })
      } else {
        this.$nextTick(() => {
          if (this.submitClicked) {
            validationHelper.scrollToFirstInvalidField()
          }
        })
      }
    },
    setErrorMessage (errorResponse) {
      this.formInfo.invalid = errorResponse.status === 400 ? errorResponse.data.reason : null
      this.errorMessage = errorResponse.data.reason ? this.$t('renting.publicForm.alerts.' + errorResponse.data.reason) : ''
    },
    resetCoresidentForm () {
      this.form.coresident = interestedPersonHelper.initPersonForm()
    },
    inThePast (desiredMoveInDate, earliestDate) {
      if (earliestDate) {
        return moment(desiredMoveInDate).isBefore(earliestDate)
      }
    },
    forceRerenderDatePicker() {
      this.dateComponentKey += 1;
    },
    earliestStart (earliestDate, desiredDate) {
      return earliestDate?.earliestStart || desiredDate
    },
    updateLanguage (value) {
      this.$i18n.locale = value
      moment.locale(value)
      localStorage.setItem('language', value)
    },
    activeLanguage () {
      this.activeLng = localStorage.getItem('language')
    }
  }
}
</script>

<style scoped lang="postcss">
.required::after {
  content: '*';
  color: #ff5252;
  font-size: 16px !important;
}
.mbtm-30  {
  margin-bottom: 30px;
}
.create-form .bl {
  border-left: 1px solid rgba(0,0,0,.54);
}
.create-form .subheading {
  font-size: 14px !important;
}
/deep/ .confirmation .v-input__slot {
  align-items: start;
}
.create-form .v-label {
  font-size: 14px !important;;
  color: rgba(0,0,0,.87) !important;;
}
.create-form .v-label--active {
  transform: translateY(-22px) scale(1) !important;
}
/deep/ .v-card__subtitle {
  font-size: 16px;
}
.lng-group {
  width: 100%;
  max-width: 110px;
  height: 40px;
  border-radius: 28px;
}
/deep/ .lng-group .v-btn.v-btn.v-size--default {
  width: 50%;
  height: 100%!important;
  border: 0;
  border-left-radius: 28px;
}
.lng-group .lng-btn:first-child img {
  border-top-left-radius: 28px;
  border-bottom-left-radius: 28px;
}

.lng-group .lng-btn:last-child img {
  border-top-right-radius: 28px;
  border-bottom-right-radius: 28px;
}
.title-lng {
  width: 100%;
}
</style>
