<template>
  <v-data-table
    item-key="id"
    single-select
    class="elevation-1"
    :loading="$apollo.queries.abacusImporterLogs.loading"
    :headers="abacusImporterHeaders"
    :footer-props="{ itemsPerPageOptions: abacusImporterItemsPerPageOptions, itemsPerPageText: abacusImporterItemsPerPageText }"
    :options.sync="options"
    :items="abacusImporterLogsItems"
    :server-items-length="totalItems"
  >
    <template v-slot:[`item.id`]="{ item }">
      <div class="id-col text-truncate">
        {{item.id}}
      </div>
    </template>
    <template v-slot:[`item.status`]="{ item }">
      <v-icon v-if="item.status === 'IN_PROGRESS'" color="orange">error_outline</v-icon>
      <v-icon v-if="item.status === 'SUCCEEDED'" color="green">check_circle</v-icon>
      <v-icon v-if="item.status === 'FAILED'" color="red">cancel</v-icon>
      <span v-if="item.errorMessage">{{ item.errorMessage }}</span>
    </template>
    <template v-slot:[`item.from_to`]="{ item }">
      {{formatDateAndTimeFunc(item.startedOn)}} - {{formatDateAndTimeFunc(item.finishedOn)}}
    </template>
  </v-data-table>
</template>

<script>
import {ABACUS_IMPORTER_LOGS} from "@/features/admin/graphql-apollo/abacusAddress.gql";
import { formatDateAndTime } from '@/helpers/formatterHelper'
import { mapActions } from 'vuex'

export default {
  name:'AbacusImporterLogsTable',
  data () {
    return {
      options: {
        itemsPerPage: 5,
        page: 1
      }
    }
  },
  apollo: {
    abacusImporterLogs: {
      query: ABACUS_IMPORTER_LOGS,
      variables() {
        return {
          size: this.options.itemsPerPage,
          page: this.options.page -1
        }
      },
      pollInterval: 10000,
      error (error) {
        this.setErrorSnackbar(error)
      }
    }
  },
  computed: {
    abacusImporterItemsPerPageOptions () {
      return [
        5,
        10,
        15,
        25
      ]
    },
    abacusImporterItemsPerPageText () {
      return this.$t('common.itemsPerPageText')
    },
    totalItems () {
      return this.abacusImporterLogs?.totalItems
    },
    abacusImporterHeaders () {
      return [
        {
          text: this.$t('common.id'),
          align: 'left',
          value: 'id',
          sortable: false
        },
        {
          text: this.$t('dataImport.abacus.job'),
          align: 'left',
          value: 'job',
          sortable: false
        },
        {
          text: this.$t('dataImport.abacus.status'),
          align: 'left',
          value: 'status',
          sortable: false
        },
        {
          text: this.$t('dataImport.abacus.fromTo'),
          align: 'left',
          value: 'from_to',
          sortable: false
        },
        {
          text: this.$t('dataImport.abacus.inserted'),
          align: 'left',
          value: 'recordsInserted',
          sortable: false
        },
        {
          text: this.$t('dataImport.abacus.updated'),
          align: 'left',
          value: 'recordsUpdated',
          sortable: false
        },
        {
          text: this.$t('dataImport.abacus.deleted'),
          align: 'left',
          value: 'recordsDeleted',
          sortable: false
        }
      ]
    },
    abacusImporterLogsItems () {
      return this.abacusImporterLogs?.items
    }
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    formatDateAndTimeFunc: formatDateAndTime
  }
}
</script>
<style lang="postcss" scoped>
.id-col  {
  width: 80px;
}
</style>