var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"pa-2",scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":"brown lighten-2"}},[_vm._v("mdi-table")])]},proxy:true}])},[_c('v-card-subtitle',{staticClass:"pa-0 ma-0 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('renting.rentingForms'))+" ")])],1),_c('v-expansion-panel-content',{staticClass:"pa-0"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.processesWithVariables,"options":_vm.options,"footer-props":{itemsPerPageOptions:_vm.itemsPerPageOptions, itemsPerPageText: _vm.itemsPerPageText},"item-key":"objectId"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.processStartedOnFormatted",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.processStartedOnFormatted))])]}},{key:"item.processDecision",fn:function(ref){
var item = ref.item;
return [_c('DecisionIcon',{attrs:{"decision":item.processDecision}}),_c('span',[_vm._v(_vm._s(_vm.$t('renting.statuses.' + item.processDecision)))])]}},{key:"item.step",fn:function(ref){
var item = ref.item;
return [(item.processDetails.openTasks)?_c('div',_vm._l((item.processDetails.openTasks),function(task,index){return _c('p',{key:'open_task' + index,staticClass:"open-task pa-1 mb-0"},[_vm._v(" "+_vm._s(task.name)+" ")])}),0):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('nobr',[_c('v-btn',{attrs:{"x-small":"","color":"blue"},on:{"click":function($event){$event.stopPropagation();return _vm.openProcessDetails(item.processDetails.process.processInstanceId)}}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.$t('common.buttons.open')))]),_c('v-icon',{staticClass:"ml-2",attrs:{"small":"","color":"white"}},[_vm._v("mdi-window-open")])],1)],1)]}}],null,true)},[_c('v-progress-linear',{attrs:{"slot":"progress","color":"blue","indeterminate":""},slot:"progress"})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }