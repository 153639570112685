<template>
  <v-main>
    <v-container fluid fill-height>
      <v-layout>
        <v-flex>
          <router-view></router-view>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: 'FullScreenWrapper'
}
</script>

<style scoped>
.container {
  padding: 0px;
}
</style>
