<template>
  <div>
    <v-card class="mt-2" v-if="resident && resident.currentApartmentStatus === currentApartmentStatuses.rentalApartment">
      <v-card-title>{{ $t('renting.referencesApartment.conclusionInterestedPersonTitle') }}: {{ resident.firstName }} {{ resident.lastName }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <span class="text--subtitle-2 font-weight-bold">{{ $t('renting.referencesApartment.currentApartment') }}:</span> <span>{{ resident.currentApartmentStreet }} {{ resident.currentApartmentStreetNumber }}, {{ resident.currentApartmentZip }} {{ resident.currentApartmentPlace }}</span>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4">
            <span class="subtitle-2 font-weight-bold">{{ $t('renting.referencesApartment.monthlyRent') }}:</span> <span>{{ formatAmount(resident.currentApartmentRent) }}</span>
          </v-col>
          <v-col cols="4">
            <span class="subtitle-2 font-weight-bold">{{ $t('renting.referencesApartment.rootNumbers') }}:</span> <span>{{ resident.currentApartmentNumberOfRooms }}</span>
          </v-col>
          <v-col cols="4">
            <span class="subtitle-2 font-weight-bold">{{ $t('renting.referencesApartment.rentStartDate') }}:</span> <span>{{ formatDate(resident.currentApartmentStartRentDate) }}</span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="mt-2" v-if="resident && resident.currentApartmentStatus === currentApartmentStatuses.rentalApartment">
      <v-card-title>{{ $t('renting.referencesApartment.conclusionRespondentTitle') }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="3">
            <span class="subtitle-2 font-weight-bold">{{ $t('common.data.name') }}:</span> <span>{{ resident.respondentLandlordName }}</span>
          </v-col>
          <v-col cols="3">
            <span class="subtitle-2 font-weight-bold">{{ $t('renting.referenceFunction') }}:</span> <span>{{ resident.respondentLandlordFunction }}</span>
          </v-col>
          <v-col cols="3">
            <span class="subtitle-2 font-weight-bold">{{ $t('common.data.telPhone') }}:</span> <span>{{ resident.respondentLandlordPhone }}</span>
          </v-col>
          <v-col cols="3">
            <span class="subtitle-2 font-weight-bold">{{ $t('common.data.email') }}:</span> <span>{{ resident.respondentLandlordEmail }}</span>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4">
            <span class="subtitle-2 font-weight-bold">{{ $t('renting.referencesApartment.regularPayment') }}:</span> <v-icon :color="resident.apartmentRegularlyPayment ? 'green': 'red'">{{ resident.apartmentRegularlyPayment ? 'mdi-check' : 'mdi-close' }}</v-icon>
          </v-col>
          <v-col cols="4">
            <span class="subtitle-2 font-weight-bold">{{ $t('renting.referencesApartment.notableIncidents') }}:</span> <v-icon :color="resident.notableIncidents ? 'red': 'green'">{{ resident.notableIncidents ? 'mdi-check' : 'mdi-close' }}</v-icon>
          </v-col>
          <v-col cols="4">
            <span class="subtitle-2 font-weight-bold">{{ $t('renting.referencesApartment.rentalRelationship') }}:</span> <v-icon :color="resident.apartmentAgainRentalRelationship ? 'green': 'red'">{{ resident.apartmentAgainRentalRelationship ? 'mdi-check' : 'mdi-close' }}</v-icon>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="text-pre-wrap">
            <strong>{{ $t('renting.comment') }}</strong> {{ resident.apartmentComment }}
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="mt-2" v-if="resident && resident.currentApartmentStatus === currentApartmentStatuses.rentalApartment">
      <v-card-title>{{ $t('renting.referencesApartment.conclusionTitle') }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="pt-0">
            <v-radio-group dense class="mt-0" row v-model="resident.apartmentConclusion" :rules="[rules.radioRequired]">
              <v-radio :label="$t('renting.referencesApartment.apartmentConclusionGood')" :value="true" :disabled="formReadOnly"></v-radio>
              <v-radio :label="$t('renting.referencesApartment.apartmentConclusionBad')" :value="false" :disabled="formReadOnly"></v-radio>
            </v-radio-group>

            <v-textarea
              dense
              :label="$t('renting.comment')"
              v-model="resident.apartmentConclusionComment"
              :disabled="formReadOnly"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="mt-2" v-else-if="resident">
      <v-card-text>
        <v-alert dense outlined type="info">
          {{ $t('renting.publicForm.fields.personInfo.apartment.status_' + resident.currentApartmentStatus) }}
        </v-alert>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import * as ticketingHelper from '@/features/ticketing/helpers/ticketingHelper'
import { getGraphQLVariable } from '@/helpers/graphQLHelper'
import { formatDate, formatAmount } from '@/helpers/formatterHelper'
import { mapActions } from 'vuex'
import { currentApartmentStatuses } from '@/config/options/currentApartmentStatuses'
import { TICKETING_TICKET_EXECUTION } from '../../graphql-apollo/ticketingTicket.gql'

import { useRentingForm } from '@/use/useRentingForm'
const { loadRentingForm } = useRentingForm()

export default {
  name: 'RentingFormReferencesApartmentConclusion',
  props: {
    processVariables: {
      type: Array,
      default: () => {
      }
    },
    ticketingTask: {
      type: Object,
      default: () => {}
    },
    formReadOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      currentApartmentStatuses: currentApartmentStatuses,
      rentingForm: {},
      rules: {
        required: (value) => !!value || this.$t('common.errors.required'),
        radioRequired: (value) => (value !== undefined && value !== '' && value !== null) || this.requiredMessage
      }
    }
  },
  apollo: {
    executionVariables: {
      query: TICKETING_TICKET_EXECUTION,
      skip () {
        return !this.ticketId || !this.executionId
      },
      error (error) {
        this.ticketLoadError = error.message
      },
      variables () {
        return {
          ticketId: this.ticketId,
          executionId: this.executionId
        }
      }
    }
  },
  watch: {
    'rentingFormId': {
      handler: 'getRentalFormData',
      immediate: true
    }
  },
  computed: {
    resident () {
      let residents = this.rentingForm?.content?.residents
      if (residents && this.token) {
        return residents.find(resident => resident.landlordToken === this.token)
      } else {
        return null
      }
    },
    rentingFormId () {
      return this.getVariableValue('System_RentingFormId_Long')
    },
    ticketId () {
      return this.ticketingTask?.ticket?.ticketId
    },
    executionId () {
      return this.ticketingTask?.executionId
    },
    token () {
      return getGraphQLVariable(this.executionVariables?.variables, 'landlordToken', 'stringValue')
    },
    requiredMessage () {
      return this.$t('common.errors.required')
    }
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    formatDate: formatDate,
    formatAmount: formatAmount,
    async getRentalFormData () {
      this.rentingForm = await loadRentingForm(this.rentingFormId)
      this.loading = false
    },
    async saveThirdPartyData () {
      await this.$prestigeRenting.updateRentingForm(this.rentingFormId, this.rentingForm.content)
    },
    getVariableValue (variableName) {
      return ticketingHelper.getVariableValue(this.processVariables, variableName)
    }
  }
}
</script>

<style scoped lang="postcss">
</style>
