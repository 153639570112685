
<template>
  <v-card>
    <v-card-title>
      {{ $t('ticketingSystem.charts.openedTasksByCandidateGroupTitle') }}
    </v-card-title>
    <v-card-text v-if="hasProcessDefinitionKeysChecked">
      <v-progress-linear v-if="$apollo.loading" color="blue" indeterminate></v-progress-linear>
      <v-container fluid v-else-if="searchTicketingOpenTasksByCandidateGroupStatistics">
        <v-row>
          <v-col>
            <BarChart
              :chart-options="chart.options"
              :chart-data="chart.data"
              :height="400"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import BarChart from './../../../components/charts/BarChart'
import { getCandidateGroupName } from '@/helpers/candidateGroupsHelper'
import { SEARCH_TICKETING_OPEN_TASKS_BY_CANDIDATE_GROUP } from '@/features/ticketing-statistics/graphql-apollo/searchTicketing.gql'

const DATA_OBJECT_KEYS = [
  {key: 'dueTodayPriority1', color: '#EF5350'},
  {key: 'dueTodayPriority2', color: '#FFA726'},
  {key: 'additionalPriority1', color: '#039BE5'},
  {key: 'additionalPriority2', color: '#B2DFDB'}
]

export default {
  name: 'OpenTasksByCandidateGroup',
  components: {
    BarChart
  },
  props: {
    filters: {
      type: Object,
      default: () => {}
    }
  },
  apollo: {
    searchTicketingOpenTasksByCandidateGroupStatistics: {
      query: SEARCH_TICKETING_OPEN_TASKS_BY_CANDIDATE_GROUP,
      variables () {
        return {
          processDefinitionKeys: this.filters?.processDefinitionKeys
        }
      },
      skip () {
        return this.filters === null || this.filters?.processDefinitionKeys?.length === 0
      }
    }
  },
  computed: {
    chart () {
      return {
        options: {
          legend: {
            display: true
          },
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            datalabels: {
              color: 'white',
              formatter: Math.round,
              padding: 3
            }
          },
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true
            }
          }
        },
        data: {
          labels: this.chartLabels,
          datasets: this.chartDatasets
        }
      }
    },
    chartLabels () {
      return this.searchTicketingOpenTasksByCandidateGroupStatistics.map(
        x => getCandidateGroupName(x.candidateGroup)
      )
    },
    chartDatasets () {
      let datasets = []
      DATA_OBJECT_KEYS.forEach(dataObject => {
        datasets.push({
          label: this.$t('ticketingSystem.charts.priority.' + dataObject.key),
          data: this.searchTicketingOpenTasksByCandidateGroupStatistics.map(x => x.statistic[dataObject.key]),
          backgroundColor: dataObject.color
        })
      })
      return datasets
    },
    hasProcessDefinitionKeysChecked () {
      return this.filters && this.filters.processDefinitionKeys.length > 0
    }
  }
}
</script>
