<template>
  <v-app-bar :color="appBarBackgroundColor" app dense dark>

    <v-app-bar-nav-icon @click="$emit('showNavigationDrawer', true)"></v-app-bar-nav-icon>

    <v-toolbar-title>Prestige Tools <span  v-if="$route.meta.title">- {{ appBarTitle }}</span></v-toolbar-title>

    <v-spacer></v-spacer>

    <template v-if="ticketingEnabledAndTicketingRole">
      <v-btn class="grey lighten-5 pl-3 pr-3 ml-3 cy-toolbar-create-ticket" @click="shouldShowTicketDialog = true">
        <v-icon dense color="grey darken-3">note_add</v-icon>
        <span class="grey--text text--darken-3 pl-1">{{ $t('ticketingSystem.note.title') }}</span>
      </v-btn>
      <TicketDialog v-if="shouldShowTicketDialog" @closeDialog="shouldShowTicketDialog = false"/>
    </template>

    <v-btn v-if="$route.meta.printerToolbar" class="printer grey lighten-5 pl-3 pr-3 ml-3" to="/dossier/configuration">
      <v-icon color="grey darken-3">print</v-icon>
      <v-progress-circular indeterminate v-if="printerStatusLoading" color="red"></v-progress-circular>
      <template v-else-if="printerStatus.connected">
        <v-icon color="green">check</v-icon>
      </template>
      <template v-else>
        <v-icon color="red">close</v-icon>
        <span class="grey--text text--darken-3">{{ $t('dossier.toolbar.checkConfig') }}</span>
      </template>
    </v-btn>
    <v-btn v-if="$route.meta.printerToolbar && printerStatus.connected" class="printer grey lighten-5 pl-3 pr-3 ml-3"
           to="/dossier/queue">
      <v-progress-circular indeterminate v-if="printerStatusLoading" color="red"></v-progress-circular>
      <template v-if="printerStatus.connected">
        <span class="grey--text text--darken-3">{{ $t('dossier.toolbar.queue') }}: {{ printerStatus.isAcceptingJobs ? printerStatus.jobCount : '?' }}</span>
      </template>
    </v-btn>
    <v-menu
      v-if="$route.meta.printerToolbar"
      v-model="activator"
      :value="year"
      :open-on-hover="false"
      :open-on-click="true"
      :close-on-click="true"
      :close-on-content-click="true"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          medium
          class="custom-btn ml-3 pl-3 pr-3 grey lighten-5"
          v-on="on"
        >
          <span class="text grey--text text--darken-3">{{ year }}</span>
          <v-icon color="grey darken-3">arrow_drop_down</v-icon>
        </v-btn>
      </template>

      <v-list class="custom-year-list">
        <div class="list-content">
          <v-list-item
            class="custom-link"
            v-for="(year, index) in yearsList"
            :key="index"
            @click="updateYear(year)"
          >
            <v-list-item-title>{{ year }}</v-list-item-title>
          </v-list-item>
        </div>
      </v-list>
    </v-menu>

    <template v-slot:extension>
      <v-tabs align-with-title optional>
        <v-tab v-for="(item, i) in filteredAppBarItems" :key="'app-bar-tab-' + i" :to="item.target">{{ $t('navigation.' + item.title) }}</v-tab>
      </v-tabs>
    </template>
  </v-app-bar>
</template>
<script>
import { appBarItems, appBarBackgroundColor } from '@/config/options/appBar'
import TicketDialog from '../features/ticketing/dialogs/TicketDialog'
import {mapActions, mapGetters, mapState} from 'vuex'
import {AuthenticationContext} from 'vue-adal'

export default {
  components: {
    TicketDialog
  },
  data () {
    return {
      activator: false,
      ticketingRole: 'prestige-tools-ticketing',
      shouldShowTicketDialog: false,
      timer: [],
      appBarItems: appBarItems,
      appBarBackgroundColor: appBarBackgroundColor
    }
  },
  async created () {
  },
  mounted () {
    this.fetchPrinterConfiguration()
  },
  watch: {
    // call again the method if the route changes
    '$route': 'fetchPrinterConfiguration'
  },
  computed: {
    ...mapGetters('printer', ['getYear', 'getPrinterStatus', 'getPrinterStatusLoading']),
    ...mapState('printer', ['year', 'printerStatus', 'printerStatusLoading']),
    userRoles () {
      return AuthenticationContext && AuthenticationContext.user && AuthenticationContext.user.profile ? AuthenticationContext.user.profile.roles : null
    },
    appBarTitle () {
      return this.$t('navigation.' + this.$route.meta.title)
    },
    yearsList () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2016}, (value, index) => 2017 + index).reverse()
    },
    ticketingEnabledAndTicketingRole () {
      let userRoles = AuthenticationContext && AuthenticationContext.user && AuthenticationContext.user.profile ? AuthenticationContext.user.profile.roles : null
      let hasPrestigeTicketingGroupRole = userRoles ? userRoles.includes(this.ticketingRole) : false
      return this.$ticketingEnabled && hasPrestigeTicketingGroupRole
    },
    filteredAppBarItems () {
      if (this.appBarItems) {
        return this.appBarItems.filter(item => item.enabled && this.hasPrivileges(item.requireRoles))
      } else {
        return []
      }
    }
  },
  methods: {
    ...mapActions('printer', ['setYear']),
    hasPrivileges (role) {
      return this.userRoles ? this.userRoles.includes(role) : true
    },
    updateYear (value) {
      this.setYear(value)
    },
    fetchPrinterConfiguration () {
      if (this.$route.meta.printerToolbar) {
        this.$store.dispatch('printer/fetchPrinterStatus')
        this.$store.dispatch('printer/fetchPrinterConfiguration')
      }
      this.manageTimer()
    },
    manageTimer () {
      this.timer.forEach(timer => clearInterval(timer))
      this.timer = []
      if (this.$route.meta.printerToolbar) {
        this.timer.push(setInterval(this.fetchPrinterConfiguration, 15000))
      }
    },
    goToRoute (name, target) {
      const routeItems = {
        path: target,
        name: name
      }
      this.$router.push(routeItems)
    }
  }
}
</script>

<style lang="postcss">
.custom-list,
.custom-year-list {
  margin-top: 45%;
}

.custom-year-list .list-content {
  height: 200px;
  overflow: auto;
}

.custom-link:hover {
  background: #f8f8f8;
}

.v-app-bar-title__content{
  width: 200px !important;
}
</style>
