import gql from 'graphql-tag'

const ABACUS_PROPERTY_OBJECTS = gql`
  query abacusProperty($propertyId: Int!) {
    abacusProperty(propertyId: $propertyId) {
      objects {
        objectId
        objectText
        floorText
        currentTenantText
      }
    }
  }
`
export { ABACUS_PROPERTY_OBJECTS }
