<template>
  <v-dialog v-model="mutableShowDialog" max-width="500px" @keydown.esc="closeDialog" persistent>
      <v-card>
        <v-card-title>
          <v-btn icon absolute right>
            <v-icon class="clear" color="primary" :size="28" @click.native="closeDialog"/>
          </v-btn>
          <span class="headline">{{ $t('dossier.printerConfiguration.title') }}</span>
        </v-card-title>
        <v-card-text class="pt-0 pb-0">
          <v-container grid-list-xs>
            <v-form ref="form" v-model="valid">
              <v-text-field
                :label="$t('common.data.url')"
                v-model="mutablePrinterConfiguration.printerUrl"
                required
                :rules="[rules.required]"
                @input="debounceInput"
                class="pb-5"
              ></v-text-field>
              <template v-if="printerSupportedMediaSources && mutablePrinterConfiguration.mediaSources">
                <v-select
                  v-for="(mediaType, mediaSourceKey) in mutablePrinterConfiguration.mediaSources"
                  :key="mediaSourceKey"
                  v-model="mutablePrinterConfiguration.mediaSources[mediaSourceKey]"
                  :items="printerSupportedMediaSources"
                  :label="$t('dossier.printerConfiguration.subject') + ': ' + $t('dossier.documentTypeConfiguration.addDocumentType.paperTray.options.' + mediaSourceKey)"
                  :disabled="!printerSupportedMediaSources || disabledDropdown"
                ></v-select>
              </template>
            </v-form>
            <p v-if="disabledDropdown" class="red--text pt-2">{{ $t('dossier.printerConfiguration.config.saveConfiguration') }}</p>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click.native="closeDialog" :disabled="disableBtn">{{ $t('common.buttons.cancel') }}</v-btn>
          <v-btn color="primary" :disabled="!valid || disableBtn" @click.native="savePrinterConfiguration">{{ $t('common.buttons.save') }}</v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>
import _debounce from 'lodash.debounce'

export default {
  name: 'PrinterConfigurationDialog',
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    printerConfiguration: {
      type: Object,
      default: () => {}
    },
    printerSupportedMediaSources: {
      type: Array,
      default: () => []
    },
    printerInitialUrl: {
      type: String,
      default: ''
    },
    disableBtn: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      valid: false,
      disabledDropdown: false,
      rules: {
        required: (value) => !!value || this.$t('common.errors.required')
      }
    }
  },
  methods: {
    closeDialog () {
      this.disabledDropdown = false
      this.$emit('closeDialog')
    },
    disableDropdowns () {
      this.disabledDropdown = this.mutablePrinterConfiguration.printerUrl !== this.printerInitialUrl
    },
    debounceInput: _debounce(function (e) {
      this.disableDropdowns()
    }, 500),
    savePrinterConfiguration () {
      if (this.$refs.form.validate()) {
        this.disabledDropdown = false
        this.$emit('savePrinterConfiguration', this.mutablePrinterConfiguration)
      }
    }
  },
  computed: {
    mutableShowDialog () {
      return this.showDialog
    },
    mutablePrinterConfiguration () {
      return this.printerConfiguration
    }
  }
}
</script>
