<template>
  <v-list>
    <template v-for="(object, index) in tenantAddressObjects">
      <v-list-item v-if="object.objectId && object.objectText" class="px-0" :key="index">
        <v-list-item-content class="body-2">
          <v-list-item-title class="body-2 text-wrap" :class="inThePast(object) ? 'text-decoration-line-through' : ''">
            {{ objectInfo(object) }}
          </v-list-item-title>
          <v-list-item-title v-if="object.currentTenantContractStart" class="mt-2 body-2">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">{{ contractStartDate(object) }} - </span>
              </template>
              <span>{{ $t('common.contractStartDate') }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">{{ contractEndDate(object) }}</span>
              </template>
              <span>{{ $t('common.contractEndDate') }}</span>
            </v-tooltip>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action class="ma-3">
          <ActionsMenu
            :item="getItem(object)"
            :actions="actions"
          />
        </v-list-item-action>
      </v-list-item>
      <v-divider :key="'divider_' + index" class="mr-3"></v-divider>
    </template>
  </v-list>
</template>

<script>
import ActionsMenu from '@/components/ActionsMenu'
import * as globalHelper from '@/helpers/globalHelper'
import { formatDate } from '@/helpers/formatterHelper'
import { isDateInThePast } from '@/helpers/momentHelper'
import { TICKETING_CONSTANTS } from '@/config/options/ticketingConstants'

import { useObjectActions } from '@/use/useObjectActions'
import { useTask } from '@/use/useTask'

const { objectActions, onNewObjectTicket, onOfflineRentalApplication } = useObjectActions()
const { onNewTask } = useTask()

export default {
  name: 'TenantAddressObjects',
  props: {
    tenantId: {
      type: String,
      default: ''
    },
    tenantInfo: {
      type: String,
      default: ''
    },
    tenantAddressObjects: {
      type: Array,
      default: () => []
    },
    processInstanceId: {
      type: String,
      default: ''
    },
  },
  data () {
    return {
      isOpen: true
    }
  },
  components: {
    ActionsMenu
  },
  computed: {
    additionalActions () {
      return [
        { title: this.$t('dashboard.actions.newTicket'), icon: 'mdi-ticket', action: this.onNewObjectTicket },
        { title: this.$t('dashboard.actions.newTask'), icon: 'mdi-calendar-check', action: onNewTask },
        { title: this.$t('dashboard.actions.offlineRentalApplication'), icon: 'mdi-plus', action: this.onOfflineRentalApplication }
      ]
    },
    actions () {
      return this.additionalActions.concat(objectActions)
    }
  },
  methods: {
    objectInfo (item) {
      return globalHelper.setObjectInfo(item)
    },
    onNewObjectTicket (object) {
      let links = onNewObjectTicket(object.property.propertyId, object.objectId)
      if (this.tenantId !== '') {
        links.push({ linkName: 'tenant', linkValues: [this.tenantId] })
      }
      let subject = object.houseStreet + ' - ' + this.tenantInfo
      this.$emit('openNewTicketDialog', links, subject)
    },
    onOfflineRentalApplication (object) {
      let data = onOfflineRentalApplication(object)
      if (this.tenantId !== '') {
        data.links.push({ linkName: 'tenant', linkValues: [this.tenantId] })
      }
      let subject = `${object.houseStreet} - ${object.objectTextShort} - (ex. ${this.tenantInfo})`
      this.$emit('openNewTicketDialog', data.links, subject, TICKETING_CONSTANTS.ANMELDUNG_OFFLINE, data.variables, object.property, object)
    },
    contractStartDate (item) {
      return formatDate(item.currentTenantContractStart)
    },
    contractEndDate (item) {
      return formatDate(item.currentTenantContractEnd)
    },
    inThePast (item) {
      return isDateInThePast(item.currentTenantContractEnd)
    },
    getItem (item) {
      return Object.assign(item, { linkName: 'propertyObject', linkValue: item.propertyId + ':' + item.objectId, processInstanceId: this.processInstanceId })
    }
  }
}
</script>

<style scoped lang="postcss">
.abacus-logo {
  width: 120px;
  content: url('../../assets/icons/abacus-logo.svg');
}
</style>
