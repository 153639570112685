<template>
  <v-timeline-item
    class="mb-4"
    :color="color + ' lighten-3'"
    icon="mdi-flag-triangle"
    fill-dot
  >
    <v-card :color="color + ' lighten-4'">
      <v-card-title class="pa-3">
        <v-list-item dense class="pl-0 pr-0">
          <v-list-item-content>
            <v-list-item-title class="subtitle-1 text-wrap">
              {{ milestone.milestoneName }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ formatTimeFunc(milestone.createdOn) }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card-title>
    </v-card>
  </v-timeline-item>
</template>

<script>
import { formatTime } from '@/helpers/formatterHelper'

export default {
  name: 'TicketingMilestone',
  props: {
    milestone: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    color () {
      return 'lime'
    }
  },
  methods: {
    formatTimeFunc: formatTime
  }
}
</script>

<style scoped lang="postcss">
</style>
