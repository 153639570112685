<template>
  <vue-clip v-if="options && options.url" :options="options" :on-complete="complete">
    <template slot="clip-uploader-body" slot-scope="props">
      <div class="uploader-files">
        <div
          v-for="(file, index) in props.files"
          class="uploader-file"
          :key="index"
        >
          <div v-if="file.status !== 'success'">
            <div class="file-details">
              <v-icon v-if="file.status === 'error'" color="red">error</v-icon>
              <span>{{ file.name }}</span>
              <span v-if="file.status === 'error'">
                {{ $t('common.messages.failed') }}
              </span>
            </div>
            <div class="file-progress" v-if="file.status !== 'error'">
              <span class="progress-indicator" v-bind:style="{width: file.progress - (file.xhrResponse.response?0:5) + '%'}"></span>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template slot="clip-uploader-action" slot-scope="params">
      <div class="uploader-action" v-bind:class="{'is-dragging': params && params.dragging, 'disabled': readOnly }">
        <div class="dz-message">
          <v-icon>cloud_upload</v-icon>
          <div>{{ $t('tasks.attachments.clickOrDragText') }}</div>
        </div>
      </div>
    </template>
  </vue-clip>
</template>

<script>
import _cloneDeep from 'lodash.clonedeep'

export default {
  name: 'FileUpload',
  props: {
    files: {
      type: Array,
      default: () => []
    },
    moduleName: {
      type: String,
      default: ''
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      options: {
        url: '',
        paramName: 'file',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('adal.idtoken')
        }
      }
    }
  },
  computed: {
    routeTaskId () {
      return this.$route && this.$route.params ? this.$route.params.taskId : ''
    }
  },
  mounted () {
    if (this.moduleName === 'task' && this.options) {
      this.options.url = '/api/task-file/v1/files/' + this.routeTaskId
    } else if (this.moduleName === 'emailDialog' && this.options) {
      this.options.url = '/api/ticketing-file/v1/azure-files'
    }
  },
  methods: {
    complete (file, status, xhr) {
      if (status === 'success') {
        file.xhrResponse.response = JSON.parse(xhr.response)
        if (this.moduleName === 'task') {
          this.$emit('addFile', {'id': file.xhrResponse.response})
        } else if (this.moduleName === 'emailDialog') {
          this.$emit('addFile', file.xhrResponse.response)
        }

      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.uploader-action {
  padding: 15px;
}
.uploader-action.is-dragging {
  background: green;
}
.uploader-action.disabled {
  pointer-events: none;
}
.uploader-action:hover {
  cursor: pointer;
}
.dz-message {
  border: 2px dashed #bbb;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  padding: 30px;
  text-align: center;
  color: #bbb;
}
.file-progress {
  height:7px;
  background-color:lightgray;
}
.progress-indicator {
  height: 7px;
  background-color:green;
  display: block;
  border: 1px solid lightgray;
}
</style>
