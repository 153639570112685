<template>
  <v-slide-y-transition mode="out-in">
    <v-card class="pl-2 pr-2 pb-2 mt-3 grey lighten-5">
      <v-card-title class="pl-1 pb-0">
        <v-icon class="pr-2">search</v-icon>{{ $t('navigation.search') }}
      </v-card-title>
      <v-layout wrap class="row pa-2">
        <v-flex class="pl-2 pr-1 mt-2">
          <v-card height="100%">
            <v-container fluid grid-list-xs>
              <div class="inline float-left mb-2">
                <v-btn class="primary ma-2" :loading="reindexTicketsLoading" :disabled="reindexTicketsLoading" @click="reindexTickets()">
                  <v-icon class="pr-2">confirmation_number</v-icon> {{ $t('admin.search.reindexTickets') }}
                </v-btn>
              </div>
              <div class="inline float-left mb-2">
                <v-btn class="primary ma-2" :loading="reindexAbacusAddressesLoading" :disabled="reindexAbacusAddressesLoading" @click="reindexAbacusAddresses()">
                  <v-icon class="pr-2">confirmation_number</v-icon> {{ $t('admin.search.reindexAbacusAddresses') }}
                </v-btn>
              </div>
              <div class="inline float-left mb-2">
                <v-btn class="primary ma-2" :loading="reindexRentingAddressesLoading" :disabled="reindexRentingAddressesLoading" @click="reindexRentingAddresses()">
                  <v-icon class="pr-2">confirmation_number</v-icon> {{ $t('admin.search.reindexRentingAddresses') }}
                </v-btn>
              </div>
            </v-container>
          </v-card>
        </v-flex>
      </v-layout>
    </v-card>
  </v-slide-y-transition>
</template>

<script>
import { mapActions } from 'vuex'
import { TICKETING_TICKETS_REINDEX } from './graphql-apollo/ticketingTickets.gql'
import { ABACUS_ADDRESSES_REINDEX } from "@/features/admin/graphql-apollo/abacusAddress.gql";
import { RENTING_ADDRESSES_REINDEX } from "@/features/admin/graphql-apollo/rentingAddress.gql";

export default {
  name: 'AdminSearch',
  data () {
    return {
      reindexTicketsLoading: false,
      reindexAbacusAddressesLoading: false,
      reindexRentingAddressesLoading: false
    }
  },
  components: {
  },
  methods: {
    ...mapActions('notifier', ['setSuccessSnackbar', 'setErrorSnackbar']),
    reindexTickets () {
      let that = this
      that.reindexTicketsLoading = true
      this.$apollo.mutate({
        mutation: TICKETING_TICKETS_REINDEX
      }).then((response) => {
        that.reindexTicketsLoading = false
        that.setSuccessSnackbar(this.$t('admin.search.reindexTicketsDone') + response.data.ticketingTicketsReindex)
      }).catch((error) => {
        that.reindexTicketsLoading = false
        that.setErrorSnackbar(error)
      })
    },
    reindexAbacusAddresses () {
      let that = this
      that.reindexAbacusAddressesLoading = true
      this.$apollo.mutate({
        mutation: ABACUS_ADDRESSES_REINDEX
      }).then((response) => {
        that.reindexAbacusAddressesLoading = false
        that.setSuccessSnackbar(this.$t('admin.search.reindexAbacusAddressesDone') + response.data.abacusAddressReindex)
      }).catch((error) => {
        that.reindexAbacusAddressesLoading = false
        that.setErrorSnackbar(error)
      })
    },
    reindexRentingAddresses () {
      let that = this
      that.reindexRentingAddressesLoading = true
      this.$apollo.mutate({
        mutation: RENTING_ADDRESSES_REINDEX
      }).then((response) => {
        that.reindexRentingAddressesLoading = false
        that.setSuccessSnackbar(this.$t('admin.search.reindexRentingAddressesDone') + response.data.rentingFormAddressReindex)
      }).catch((error) => {
        that.reindexRentingAddressesLoading = false
        that.setErrorSnackbar(error)
      })
    },
    triggerSuccessMessage () {
      return this.setSuccessSnackbar(this.$t('dataImport.success'))
    },
    responseSuccess (status) {
      return status === 200 || status === 204
    }
  }
}
</script>

<style scoped>
</style>
