<template>
  <v-layout row class="pa-2 mt-1">
    <v-flex xs12 sm12 md12 lg12>
      <v-card class="pa-4">
        <v-card-title class="pa-0 mb-2">
          {{ $t('common.tenants') }}
          <v-btn @click.native="copyToClipboard" class="ml-2">{{ $t('common.buttons.copyEmail') }}</v-btn>
        </v-card-title>
        <v-expansion-panels v-model="panel">
          <v-expansion-panel v-for="(item, index) in groupedObjectsByHouseStreet" :key="index">
            <v-expansion-panel-header class="px-3 py-0">
              <v-layout>
                <v-list-item :key="index" class="pa-0">
                  <v-list-item-content class="pa-0">
                    <v-card-title class="pa-0">{{ item.houseStreet }}</v-card-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <ActionsMenu :item="getItem(item)" :actions="propertyAllActions" />
                  </v-list-item-action>
                </v-list-item>
              </v-layout>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-table
                :class="tableClassName"
                :headers="headers"
                :items="item.objects"
                :options.sync="options"
                :footer-props="{itemsPerPageOptions:itemsPerPageOptions, itemsPerPageText: itemsPerPageText}"
                item-key="objectId"
                :item-class="rowClass"
                class="elevation-1"
                single-select
                @click:row="showAddressDetails"
              >
                <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
                <template v-slot:[`item.actions`]="{ item }">
                  <ActionsMenu :item="item" :actions="objectAllActions" />
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import * as dashboardHelper from './helpers/dashboardHelper'
import ActionsMenu from '@/components/ActionsMenu'
import { getNewTicketFullSubject } from '@/helpers/globalHelper'
import { TICKETING_CONSTANTS } from '@/config/options/ticketingConstants'
import { mapActions } from 'vuex'

import { usePropertyActions } from '@/use/usePropertyActions'
import { useObjectActions } from '@/use/useObjectActions'
import { useTask } from '@/use/useTask'

const { propertyActions } = usePropertyActions()
const { objectActions, onNewObjectTicket, onOfflineRentalApplication } = useObjectActions()
const { onNewTask } = useTask()

export default {
  name: 'DashboardTenantsHouseOverview',
  components: {
    ActionsMenu
  },
  props: {
    property: {
      type: Object,
      default: () => {}
    },
    propertyObjects: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      tableClassName: 'houseOverview',
      groupedObjectsByHouseStreet: {},
      options: {
        page: 1
      },
      panel: [0, 1],
      showDialog: false
    }
  },
  created () {
    this.groupedObjectsByHouseStreet = this.groupObjectsByHouseStreet()
  },
  updated () {
    if (this.routeAddressId) {
      let objectId = localStorage.getItem('dashboardObjectId')
      if (objectId) {
        dashboardHelper.setSelectedItemHighlightClass(objectId, this.tableClassName)
      }
    }
  },
  computed: {
    routeAddressId () {
      return this.$route.params.addressId
    },
    headers () {
      return [
        {
          text: this.$t('common.objectFields.floor'),
          align: 'left',
          value: 'floorText',
          sortable: true
        },
        {
          text: this.$t('tasks.tenant.object'),
          align: 'left',
          value: 'objectText',
          sortable: true
        },
        {
          text: this.$t('tasks.tenant.currentTenant'),
          align: 'left',
          value: 'currentTenantText'
        },
        {
          text: this.$t('common.data.email'),
          align: 'left',
          value: 'currentTenant.email'
        },
        {
          text: this.$t('heatingOil.headers.actions'),
          sortable: false,
          value: 'actions'
        }
      ]
    },
    itemsPerPageText () {
      return this.$t('common.itemsPerPageText')
    },
    itemsPerPageOptions () {
      return [
        10,
        25,
        {
          'text': this.$t('common.all'),
          'value': -1
        }
      ]
    },
    addressId () {
      return this.$route.params.addressId
    },
    propertyAllActions () {
      return this.propertyAdditionalActions.concat(propertyActions)
    },
    propertyAdditionalActions () {
      return [
        { title: this.$t('dashboard.actions.newTicket'), icon: 'mdi-ticket', action: this.onNewPropertyTicket },
        { title: this.$t('dashboard.actions.newTask'), icon: 'mdi-calendar-check', action: onNewTask }
      ]
    },
    objectAllActions () {
      return this.objectAdditionalActions.concat(objectActions)
    },
    objectAdditionalActions () {
      return [
        { title: this.$t('dashboard.actions.newTicket'), icon: 'mdi-ticket', action: this.onNewObjectTicket },
        { title: this.$t('dashboard.actions.newTask'), icon: 'mdi-calendar-check', action: onNewTask },
        { title: this.$t('dashboard.actions.offlineRentalApplication'), icon: 'mdi-plus', action: this.onOfflineRentalApplication }
      ]
    },
    tenantEmails () {
      let propertyTenantEmails = []
      this.propertyObjects.forEach(element => {
        if (element.currentTenant && element.currentTenant.email !== '' && !propertyTenantEmails.includes(element.currentTenant.email)) {
          propertyTenantEmails.push(element.currentTenant.email)
        }
      })
      let separatedMails = []
      propertyTenantEmails.forEach(element => {
        separatedMails.push(element + ';')
      })
      return separatedMails.toString().replace(/,/g, '')
    }
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar', 'setSuccessSnackbar']),
    groupObjectsByHouseStreet () {
      if (this.propertyObjects.length > 0) {
        const groupedObjects = this.propertyObjects.reduce((groupedObjects, object) => {
          const houseStreet = object.houseStreet
          if (!groupedObjects[houseStreet]) {
            groupedObjects[houseStreet] = []
          }
          groupedObjects[houseStreet].push(object)
          return groupedObjects
        }, {})
        return Object.keys(groupedObjects).map((houseStreet) => {
          return {
            propertyId: this.property.propertyId,
            houseStreet: houseStreet,
            objects: groupedObjects[houseStreet]
          }
        })
      } else {
        return []
      }
    },
    showAddressDetails (item, row) {
      if (!row.isSelected) {
        dashboardHelper.removeTableSelections(this.tableClassName)
        row.select(true)
        if (item.currentTenant) {
          let id = item?.currentTenant?.id?.toString()
          let active = this.addressId === id
          if (!active) {
            this.$emit('addressSelected', id, 'tenant')
          }
          localStorage.setItem('dashboardObjectId', item.objectId)
        }
      }
    },
    rowClass (item) {
      return 'row-' + item.objectId
    },
    getItem (item) {
      if (this.property) {
        item.subject = item.houseStreet
        return Object.assign(item, { linkName: 'property', linkValue: this.property.propertyId.toString() })
      }
    },
    onNewPropertyTicket () {
      let links = [
        {
          linkName: 'property',
          linkValues: [this.property.propertyId.toString()]
        }
      ]
      let subject = this.property.displayText.split(']')[1].trim()
      this.$emit('openNewTicketDialog', links, subject)
    },
    onNewObjectTicket (object) {
      let links = onNewObjectTicket(this.property.propertyId, object.objectId)
      if (object.currentTenant?.id) {
        links.push({ linkName: 'tenant', linkValues: [object.currentTenant.id.toString()] })
      }
      let subject = getNewTicketFullSubject(object)
      this.$emit('openNewTicketDialog', links, subject)
    },
    onOfflineRentalApplication (object) {
      let data = onOfflineRentalApplication(object)
      if (object.currentTenant?.id) {
        data.links.push({ linkName: 'tenant', linkValues: [object.currentTenant.id.toString()] })
      }
      let subject = getNewTicketFullSubject(object)
      this.$emit('openNewTicketDialog', data.links, subject, TICKETING_CONSTANTS.ANMELDUNG_OFFLINE, data.variables)
    },
    async copyToClipboard () {
      await navigator.clipboard.writeText(this.tenantEmails)
      if (navigator.clipboard.writeText.length > 0) {
        this.setSuccessSnackbar(this.$t('ticketingSystem.processes.variables.copiedToClipboard'))
      }
    }
  }
}
</script>

<style scoped lang="postcss">
/deep/ tr:nth-child(odd) {
  background: #f8f8f8;
}
/deep/ tr {
  cursor: pointer;
}
/deep/ tr.v-data-table__selected {
  background: rgb(228, 239, 250) !important;
}
/deep/ .v-expansion-panel-content__wrap {
  padding: 8px;
}
</style>
