<template>
  <v-card class="pa-3 mb-2" v-if="displayForm">
    <v-layout row wrap>
      <v-flex lg12 md12 sm12 xs12>
        <v-layout row wrap class="mb-2">
          <v-flex lg12 md12 sm12 xs12>
            <SharedTaskForm
              ref="sharedTaskForm"
              :display-form="displayForm"
              :form="form"
              :form-key="formKey"
              :form-read-only="formReadOnly"
              :ticketing-task="ticketingTask"
              :additional-links="additionalLinks"
              :processVariables="processVariables"
              :process-instance-id="processInstanceId"
              :validate-clicked="completeClicked"
              @updateFormValues="onUpdateForm"
              @autocompleteLinksUpdated="onAutocompleteLinksUpdated"
              @loadProcessVariables="loadProcessVariables"
            />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import SharedTaskForm from '../shared/SharedTaskForm'
import * as validationHelper from '@/helpers/validationHelper'
import { mapActions, mapGetters, mapState } from 'vuex'
import _cloneDeep from 'lodash.clonedeep'

export default {
  name: 'TaskForm',
  components: {
    SharedTaskForm
  },
  data () {
    return {
      form: {},
      processVariables: null,
      defaultVariablesSetted: false,
      completeClicked: false
    }
  },
  props: {
    processInstanceId: {
      type: String
    },
    taskId: {
      type: String
    },
    ticketingTask: {
      type: Object,
      default: () => {}
    },
    formReadOnly: {
      type: Boolean,
      default: false
    },
    hasParentTask: {
      type: Boolean,
      default: false
    },
    additionalLinks: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    'taskId': 'loadData'
  },
  mounted () {
    this.loadData()
  },
  computed: {
    ...mapState('ticketingSystem', ['ticketingUsers']),
    ...mapGetters('abacus', ['getAbacusProperties']),
    ...mapState('abacus', ['abacusProperties', 'abacusPropertiesDisplayItems', 'abacusPropertiesLoading']),
    formKey () {
      return this.ticketingTask?.formKey
    },
    displayForm () {
      if (this.formKey) {
        return true
      } else if (this.form && this.form.formType === 'DYNAMIC') {
        return this.hasFormFields && ((this.hasProcessVariables && this.defaultVariablesSetted) || !this.hasProcessVariables)
      }
      return this.form && this.form.formType === 'STATIC'
    },
    hasFormFields () {
      return this.form && this.form.formFields && this.form.formFields.length > 0
    },
    hasProcessVariables () {
      return this.processVariables && this.processVariables.length > 0
    },
    requiredMessage () {
      return this.$t('common.errors.required')
    },
    dialogRouteTaskId () {
      return this.$route.query.taskId
    },
    mainProcessInstanceId () {
      return this.processInstanceId || this.$route.params.processInstanceId
    }
  },
  methods: {
    ...mapActions('notifier', ['setSuccessSnackbar', 'setErrorSnackbar']),
    loadData () {
      if ((this.mainProcessInstanceId) && !this.hasParentTask) {
        this.defaultVariablesSetted = false
        Promise.all([this.loadFormDefinition(), this.loadProcessVariables()]).then(() => this.setDefaultVariableValues())
      }
    },
    loadFormDefinition () {
      if (!this.formKey) {
        let that = this
        if (this.taskId) {
          return this.$prestigeTSTask.getFormDefinition(this.taskId).then(function (response) {
            that.form = response.data
          }).catch(error => {
            that.form = null
            that.setErrorSnackbar(error)
          })
        }
      }
      return true
    },
    loadProcessVariables () {
      let that = this
      return this.$prestigeTSProcess.getVariablesForProcess(this.mainProcessInstanceId).then(function (response) {
        that.processVariables = response.data
      }).catch(error => {
        that.processVariables = null
        that.setErrorSnackbar(error)
      })
    },
    setDefaultVariableValues () {
      this.defaultVariablesSetted = false
      if (this.hasFormFields) {
        this.setBooleanFieldsDefaultValue()
        if (this.hasProcessVariables) {
          let that = this
          this.processVariables.forEach(item => {
            if (item.variableType === 'boolean' && item.variableValue == null) {
              item.variableValue = false
            } else if (item.variableName.endsWith('_Property') && item.variableValue) {
              let index = that.form.formFields.findIndex(x => x.formFieldId === item.variableName)
              if (index !== -1) {
                that.form.formFields[index].formFieldDefaultValue = parseInt(item.variableValue)
              }
            } else if (item.variableName.endsWith('_PropertyObject') && item.variableValue) {
              let index = that.form.formFields.findIndex(x => x.formFieldId === item.variableName)
              if (index !== -1) {
                that.form.formFields[index].formFieldDefaultValue = parseInt(item.variableValue)
              }
            } else if (item.variableValue && that.form.formFields) {
              let index = that.form.formFields.findIndex(x => x.formFieldId === item.variableName)
              if (index !== -1) {
                let formDefaultValue = that.form.formFields[index].formFieldDefaultValue
                that.form.formFields[index].formFieldDefaultValue = item.variableValue || formDefaultValue
              }
            }
          })
        }
        this.defaultVariablesSetted = true
      }
    },
    setBooleanFieldsDefaultValue () {
      this.form.formFields.map(field => {
        if (field.formFieldType === 'boolean' && field.formFieldDefaultValue === null) {
          field.formFieldDefaultValue = false
        }
      })
    },
    async saveTaskVariables (processReferencesLinks) {
      if (this.$refs.sharedTaskForm) {
        let that = this
        let data = this.getData()
        data.newLinks = processReferencesLinks
        that.$emit('saveStarted')
        await this.saveThirdPartyData()
        this.$prestigeTSTask.saveTaskVariables(this.taskId, data).then(function (response) {
          that.$emit('saveFinished')
          that.$emit('loadProcessReferences')
          that.loadData()
          that.setSuccessSnackbar(that.$t('ticketingSystem.messages.successfullySaved'))
          that.$emit('closeDialog')
          return true
        }).catch(error => {
          that.$emit('saveFinished')
          that.setErrorSnackbar(error)
        })
      } else {
        this.$emit('closeDialog')
      }
    },
    async completeTask (processReferencesLinks) {
      this.completeClicked = true
      let data = this.getData()
      data.newLinks = processReferencesLinks
      if (this.validate()) {
        let that = this
        that.$emit('saveStarted')
        await this.saveThirdPartyData()
        this.$prestigeTSTask.completeTask(this.taskId, data).then(function (response) {
          that.$emit('saveFinished')
          that.setSuccessSnackbar(that.$t('ticketingSystem.messages.successfullyCompleted'))
          let tasks = response.data
          if (tasks && tasks.length > 0) {
            let nextTask = tasks[0]
            if (nextTask) {
              const query = {...that.$route.query, taskId: nextTask.nextTaskId}
              that.$router.replace({query})
              let taskDetailsDialogCard = document.getElementById('taskDetailsDialog')
              if (taskDetailsDialogCard) {
                taskDetailsDialogCard.scrollIntoView()
              }
            } else {
              that.$emit('closeDialog')
            }
          } else {
            that.$emit('closeDialog')
          }
          that.$emit('reloadTasks')
          return true
        }).catch(error => {
          that.$emit('saveFinished')
          that.setErrorSnackbar(error)
        })
      } else {
        this.$nextTick(() => {
          if (this.completeClicked) {
            validationHelper.scrollToFirstInvalidField()
          }
        })
      }
    },
    async saveThirdPartyData () {
      if (this.$refs.sharedTaskForm && this.$refs.sharedTaskForm.saveThirdPartyData) {
        await this.$refs.sharedTaskForm.saveThirdPartyData()
      }
    },
    getData () {
      if (this.displayForm) {
        if (this.$refs.sharedTaskForm) {
          return this.$refs.sharedTaskForm.getGroupedVariables([])
        } else {
          return undefined
        }
      }
      return {}
    },
    validate () {
      if (this.$refs.sharedTaskForm) {
        return this.$refs.sharedTaskForm.isValid()
      }
      return true
    },
    onUpdateForm (params) {
      this.form = Object.assign(_cloneDeep(this.form), params)
    },
    onAutocompleteLinksUpdated (autocompleteLinks) {
      this.$emit('autocompleteLinksUpdated', autocompleteLinks)
    }
  }
}

</script>

<style scoped lang="postcss">
/deep/ .editor {
  height: 150px;
}
/deep/ .v-input--is-readonly {
  pointer-events: none;
}
/deep/ input:read-only {
  color: rgba(0,0,0,.38);
}
</style>
