<template>
  <v-container fluid class="grey lighten-5 pa-2">
    <FillingLevelDialog :show-dialog="showFillingLevelDialog" :item="currentItem" @closeDialog="closeFillingLevelDialog" @saveFillingLevel="saveEditedFillingLevel" />
    <CommentDialog :show-dialog="shouldShowCommentDialog" :comment="currentComment" @closeDialog="closeCommentDialog" />
    <ShoppingCartDialog
      :show-dialog="showShoppingCartDialog"
      :item="currentItem"
      :default-supplier-id="defaultSupplierId"
      :max-amount="maxAmount"
      :disable-btn="disableBtn"
      @closeDialog="closeShoppingCartDialog"
      @saveItemToBasket="saveItemToBasket"
    />

    <v-layout class="row pt-0 pl-0 pr-0">
      <v-card-title>{{ $t('navigation.tanks') }}</v-card-title>
      <v-spacer></v-spacer>
      <v-checkbox :label="$t('heatingOil.showInactive') " hide-details v-model="showInactive"></v-checkbox>
      <v-spacer></v-spacer>
      <v-text-field
        append-icon="search"
        :label="$t('common.buttons.search')"
        single-line
        hide-details
        v-model="search"
      ></v-text-field>
    </v-layout>
    <v-data-table
      :headers="headers"
      :items="allTanks"
      :search="search"
      :custom-filter="customDataTableFilter"
      :loading="tanksLoading"
      :singe-expand="true"
      :expanded="expandedTankOrders"
      item-key="tankId"
      must-sort
      :options.sync="options"
      :hide-default-footer="true"
      :footer-props="{ itemsPerPageOptions }"
      @current-items="closeAllExpanded"
      class="elevation-1"
    >
      <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
      <template v-slot:item="{ item }">
        <tr :class="{inactive: item.inactive}">
          <td>
            [<a href="" @click="openMapProperty(item.propertyId)">{{ item.propertyId }}</a>]
            <span>{{ item.propertyStreet + ', ' + item.propertyZip + ' ' + item.propertyPlace }}</span>
          </td>
          <td>{{ item.houseInfo }}</td>
          <td>
            <nobr>
              {{ item.volumeInfo }}
              <v-btn icon class="mx-0" @click="editFillingLevel(item)">
                <v-icon color="teal">edit</v-icon>
              </v-btn>
            </nobr>
          </td>
          <td>
            <v-progress-linear :color="item.percentColor" v-model="item.percent"></v-progress-linear>
          </td>
          <td>
            <v-flex sm9 v-if="item.updatedOn">
              <nobr>{{ item.updatedOnFormatted }}</nobr><br />
              <nobr class="caption">({{ $t('heatingOil.tankLevel.source.' + item.source) }})</nobr>
            </v-flex>
            <v-flex sm3 class="pl-2" v-if="item.updatedOn">
              <v-icon v-if="item.updatedOnDifferenceInDays > 14" color="orange">warning</v-icon>
            </v-flex>
            <v-flex sm3 class="pl-2" v-else>
              <v-icon color="red">warning</v-icon>
            </v-flex>
          </td>
          <td>
            <v-icon v-if="item.tankRevision" color="red">build</v-icon>
          </td>
          <td>
            <v-btn icon class="mx-0" v-if="item.comment" @click.stop="openCommentDialog(item.comment)" :title="item.comment">
              <v-icon color="blue-grey">note</v-icon>
            </v-btn>
          </td>
          <td>{{ item.orderedOnFormatted }}</td>
          <td>{{ item.basketAmountInfoFormatted }} {{ item.basketAmountInfo > 0 ? $t('heatingOil.tankLevel.fillingLevelDialog.hint') : '' }}</td>
          <td>
            <nobr>
              <v-btn v-if="!item.alreadyInBasket" icon class="mx-0" @click.stop="openShoppingCartDialog(item)">
                <v-icon color="teal">add_shopping_cart</v-icon>
              </v-btn>
              <v-btn v-if="item.orderedOn !== null && item.orderedOn !== undefined" icon class="mx-0" :title="$t('heatingOil.orders.details')" @click.stop="toggleExpander(item)">
                <v-icon v-if="item.expanded" color="primary" :disabled="ordersLoading">remove_circle_outline</v-icon>
                <v-icon v-else color="blue lighten-3">add_circle_outline</v-icon>
              </v-btn>
            </nobr>
          </td>
        </tr>
      </template>
      <template v-slot:expanded-item="{ item }">
        <td colspan="10" class="pl-2 pr-2">
          <v-container fluid grid-list-lg>
            <v-layout row>
              <v-flex xs12>
                <v-card-title class="pl-0">{{ $t('navigation.orders') }}</v-card-title>
                <v-data-table
                  :headers="subHeaders"
                  :items="item.expandedTankOrders"
                  must-sort
                  :loading="ordersLoading"
                  :options.sync="subOptions"
                  class="elevation-1"
                >
                  <template v-slot:[`item.orderedOn`]="{ item }">
                    {{ item.orderedOnFormatted }}
                  </template>
                  <template slot="no-data">
                    <v-alert :value="true" color="error" icon="warning">
                      {{ $t('table.noData') }}
                    </v-alert>
                  </template>
                </v-data-table>
              </v-flex>
            </v-layout>
          </v-container>
        </td>
      </template>
      <template slot="no-data">
        <v-alert :value="true" color="error" icon="warning">
          {{ $t('table.noData') }}
        </v-alert>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import FillingLevelDialog from './dialogs/FillingLevelDialog'
import CommentDialog from './dialogs/CommentDialog'
import ShoppingCartDialog from './dialogs/ShoppingCartDialog'
import * as heatingOilHelper from './helpers/heatingOilHelper'
import { dataTableFilter } from '@/helpers/globalHelper'
import { formatDateAndTime } from '@/helpers/formatterHelper'
import { MAP_PROPERTIES } from '@/config/options/routes/mapRoutes'

import { mapActions, mapGetters, mapState } from 'vuex'
import _cloneDeep from 'lodash.clonedeep'
import moment from 'moment'

export default {
  name: 'HeatingOilTanks',
  components: {
    FillingLevelDialog,
    CommentDialog,
    ShoppingCartDialog
  },
  created () {
    this.loadData()
  },
  data () {
    return {
      shouldShowCommentDialog: false,
      showFillingLevelDialog: false,
      showShoppingCartDialog: false,
      currentItem: {},
      currentComment: '',
      defaultSupplierId: '',
      disableBtn: false,
      maxAmount: null,
      showInactive: false,
      singleExpand: false,
      tankLevels: [],
      expandedTankOrders: [],
      basket: [],
      ordersLoading: false,
      search: '',
      options: {
        sortBy: ['percent']
      },
      subOptions: {
        sortBy: ['orderedOn'],
        sortDesc: [true]
      },
      itemsPerPageOptions: [
        {
          'text': this.$t('common.all'),
          'value': -1
        }
      ]
    }
  },
  computed: {
    ...mapGetters('abacus', ['getTanks', 'getTanksLoading', 'getHeatingOilSuppliers']),
    ...mapGetters('heatingOil', ['getTankOrders']),
    ...mapState('heatingOil', ['tankOrders']),
    tanksLoading () {
      return this.$store.getters['abacus/getTanksLoading']
    },
    allTanks () {
      let allTanks = this.getTanks
      if (allTanks.length && !this.tanksLoading) {
        allTanks = allTanks.filter(tank => this.showInactive || !tank.inactive)
        this.resetAllToggleIconsAndMapTankId(allTanks)
        this.mapTankLevels(allTanks)
        this.mapTankOrders(allTanks)
        this.mapAmountAndAlreadyInBasket(allTanks)
        this.$forceUpdate()
      }
      return allTanks
    },
    headers () {
      return [
        {
          text: this.$t('tasks.info.property'),
          align: 'left',
          value: 'propertyInfo'
        },
        {
          text: this.$t('heatingOil.headers.house'),
          align: 'left',
          value: 'houseInfo'
        },
        {
          text: this.$t('heatingOil.headers.stockVolume'),
          align: 'left',
          value: 'fillingLevel'
        },
        {
          text: this.$t('heatingOil.headers.level'),
          align: 'left',
          value: 'percent'
        },
        {
          text: this.$t('heatingOil.headers.lastUpdate'),
          align: 'left',
          value: 'updatedOn'
        },
        {
          text: this.$t('heatingOil.headers.revision'),
          align: 'left',
          value: 'tankRevision'
        },
        {
          text: this.$t('tasks.comment.title'),
          align: 'left',
          value: 'comment'
        },
        {
          text: this.$t('heatingOil.headers.lastOrder'),
          align: 'left',
          value: 'orderedOn'
        },
        {
          text: this.$t('heatingOil.headers.shoppingCart'),
          align: 'left',
          value: 'basketAmountInfo'
        },
        {
          text: this.$t('heatingOil.headers.actions'),
          align: 'left',
          sortable: false,
          value: 'actions'
        }
      ]
    },
    subHeaders () {
      return [
        {
          text: this.$t('common.id'),
          align: 'left',
          value: 'orderId'
        },
        {
          text: this.$t('heatingOil.subHeaders.amount'),
          align: 'right',
          value: 'orderAmountFormatted'
        },
        {
          text: this.$t('heatingOil.subHeaders.supplier'),
          align: 'left',
          value: 'supplierInfo'
        },
        {
          text: this.$t('heatingOil.subHeaders.date'),
          align: 'left',
          value: 'orderedOn'
        }
      ]
    }
  },
  methods: {
    ...mapActions('abacus', ['setTanks', 'setTanksLoading']),
    ...mapActions('heatingOil', ['setTankOrders']),
    ...mapActions('notifier', ['setSuccessSnackbar', 'setErrorSnackbar']),
    formatDateAndTimeFunc: formatDateAndTime,
    loadData () {
      Promise.all([this.loadTankStatus(), this.$store.dispatch('heatingOil/loadTankOrders'), this.$store.dispatch('abacus/loadHeatingOilSuppliers'), this.loadBasket()]).then(() => this.loadTanks())
    },
    loadTanks () {
      this.$store.dispatch('abacus/loadTanks')
    },
    loadTankStatus () {
      let that = this
      this.$prestigeHeatingOilTankLevel.getTankLevels().then(function (response) {
        that.tankLevels = response.data
        return that.tankLevels
      }).catch(function (error) {
        that.tankLevels = []
        that.setErrorSnackbar(error)
      })
    },
    loadBasket () {
      let that = this
      this.$prestigeHeatingOilBasket.getAllBasket().then(response => {
        that.basket = response.data
        return that.basket
      }).catch(function (error) {
        that.basket = []
        that.setErrorSnackbar(error)
      })
    },
    mapTankLevels (tanks) {
      if (this.tankLevels.length && Array.isArray(this.tankLevels)) {
        let that = this
        tanks.map(item => {
          if (item.id) {
            let tankLevelItem = that.tankLevels.find(x => x.tankId.toString() === item.id.toString())
            if (tankLevelItem) {
              let formattedLevel = tankLevelItem.fillingLevel >= 0 ? heatingOilHelper.formatVolume(tankLevelItem.fillingLevel) : '?'
              tankLevelItem.volumeInfo = formattedLevel + ' / ' + heatingOilHelper.formatVolume(item.volume)
              tankLevelItem.percent = tankLevelItem.fillingLevel ? Math.max(0.0001, tankLevelItem.fillingLevel / item.volume * 100) : ''
              tankLevelItem.percentColor = heatingOilHelper.getColor(tankLevelItem.percent)
              tankLevelItem.updatedOnFormatted = that.formatDateAndTimeFunc(tankLevelItem.updatedOn)
              tankLevelItem.updatedOnDifferenceInDays = moment().diff(moment(tankLevelItem.updatedOn), 'days')
              tankLevelItem.basketAmount = item.volume - tankLevelItem.fillingLevel
              delete tankLevelItem.id
              Object.assign(item, tankLevelItem)
            } else {
              let tankLevelItem = {}
              tankLevelItem.volumeInfo = '? / ' + heatingOilHelper.formatVolume(item.volume)
              Object.assign(item, tankLevelItem)
            }
          }
        })
      }
      return tanks
    },
    mapTankOrders (tanks) {
      if (this.tankOrders.length && Array.isArray(this.tankOrders)) {
        let that = this
        tanks.map(item => {
          if (item.id) {
            let orderItem = that.tankOrders.find(x => x.tankId.toString() === item.id.toString())
            if (orderItem) {
              orderItem.orderedOnFormatted = that.formatDateAndTimeFunc(orderItem.orderedOn)
              orderItem.orderAmount = orderItem.amount
              delete orderItem.amount
              delete orderItem.id
              Object.assign(item, orderItem)
            }
          }
        })
      }
      return tanks
    },
    mapAmountAndAlreadyInBasket (tanks) {
      this.resetAlreadyInBasket(tanks)
      if (this.basket.length) {
        this.basket.forEach(basketItem => {
          let tank = tanks.find(x => x.id.toString() === basketItem.tankId.toString())
          if (tank) {
            tank.basketAmountInfo = basketItem.amount
            tank.basketAmountInfoFormatted = heatingOilHelper.formatVolume(basketItem.amount)
            tank.alreadyInBasket = true
          }
        })
      }
    },
    resetAlreadyInBasket (tanks) {
      tanks.map(tankItem => {
        tankItem.alreadyInBasket = false
        tankItem.basketAmountInfo = 0
        tankItem.basketAmountInfoFormatted = ''
      })
    },
    editFillingLevel (item) {
      this.resetDialogs()
      this.currentItem = Object.assign({}, item)
      this.showFillingLevelDialog = true
    },
    saveEditedFillingLevel () {
      let data = {
        'fillingLevel': parseInt(this.currentItem.fillingLevel)
      }
      let that = this
      this.$prestigeHeatingOilTankLevel.createTankLevel(this.currentItem.id, data).then(response => {
        that.closeFillingLevelDialog()
        that.loadData()
        that.setSuccessSnackbar(that.$t('heatingOil.tankLevel.fillingLevelDialog.statusAdded'))
        return true
      }).catch(function (error) {
        that.setErrorSnackbar(error)
      })
    },
    closeFillingLevelDialog () {
      this.showFillingLevelDialog = false
      this.currentItem = {}
    },
    openCommentDialog (comment) {
      this.resetDialogs()
      this.currentComment = comment
      this.shouldShowCommentDialog = true
    },
    closeCommentDialog () {
      this.currentComment = ''
      this.shouldShowCommentDialog = false
    },
    saveItemToBasket () {
      this.disableBtn = true
      let data = {
        'tankId': this.currentItem.id,
        'supplierId': parseInt(this.currentItem.supplierId),
        'amount': parseInt(this.currentItem.basketAmount)
      }
      let that = this
      this.$prestigeHeatingOilBasket.createBasket(data).then(response => {
        that.closeShoppingCartDialog()
        that.loadData()
        that.setSuccessSnackbar(that.$t('heatingOil.tankLevel.fillingLevelDialog.addedToBasket'))
        return true
      }).catch(function (error) {
        that.setErrorSnackbar(error)
      })
    },
    openShoppingCartDialog (item) {
      this.resetDialogs()
      this.currentItem = Object.assign({}, item)
      this.defaultSupplierId = _cloneDeep(item.supplierId.toString())
      this.maxAmount = item.basketAmount ? _cloneDeep(item.basketAmount) : 0
      this.showShoppingCartDialog = true
    },
    closeShoppingCartDialog () {
      this.showShoppingCartDialog = false
      this.currentItem = {}
      this.defaultSupplierId = ''
      this.disableBtn = false
    },
    async toggleExpander (item) {
      try {
        this.resetToggleIcons(item)
        this.ordersLoading = true
        this.expandedTankOrders = []
        item.expanded = !item.expanded
        if (item.expanded) {
          if (typeof item.expandedTankOrders === 'undefined') {
            let response = await this.$prestigeHeatingOilOrder.getOrdersForTank(item.id).then(response => { return response })
            let orders = response.data
            let that = this
            await orders.map(order => {
              order.orderAmountFormatted = heatingOilHelper.formatVolume(order.amount)
              order.orderedOnFormatted = that.formatDateAndTimeFunc(order.orderedOn)
              let suppliers = this.getHeatingOilSuppliers
              let supplier = suppliers.find(x => x.addressId.toString() === order.supplierId.toString())
              if (supplier) {
                order.supplierInfo = '[' + supplier.addressId + '] ' + (supplier.firstName ? supplier.firstName + ' ' : '') + supplier.lastName
              }
              this.expandedTankOrders.push(order)
            })
            item.expandedTankOrders = this.expandedTankOrders
          } else {
            this.expandedTankOrders = item.expandedTankOrders
          }
        } else {
          this.expandedTankOrders = []
        }
        this.ordersLoading = false
        this.$forceUpdate()
      } catch (error) {
        this.expandedTankOrders = []
        this.setErrorSnackbar(error)
      }
    },
    resetToggleIcons (item) {
      this.allTanks.map(resetItem => {
        if (resetItem.tankId !== item.tankId) {
          resetItem.expanded = false
        }
      })
    },
    resetAllToggleIconsAndMapTankId (tanks) {
      tanks.map(tankItem => {
        tankItem.tankId = tankItem.id
        tankItem.expanded = false
      })
    },
    resetDialogs () {
      this.shouldShowCommentDialog = false
      this.showShoppingCartDialog = false
      this.showFillingLevelDialog = false
    },
    closeAllExpanded () {
      if (this.expandedTankOrders.length > 0) {
        this.allTanks.map(resetItem => {
          if (resetItem.expanded) {
            resetItem.expanded = false
          }
        })
        this.expandedTankOrders = []
      }
    },
    openMapProperty (property) {
      this.$router.push({ name: MAP_PROPERTIES, params: { propertyId: property.toString() } })
    },
    customDataTableFilter (value, search, item) {
      return dataTableFilter(search, item)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="postcss">
/deep/ tr:nth-child(odd) {
  background: #f8f8f8;
}
tr.inactive td {
  text-decoration: line-through;
}
.v-data-table {
  overflow-x: auto !important;
}
</style>
