
<template>
  <v-layout row wrap class="first-row mb-1 mt-3">
    <div class="d-flex flex-column">
      <TaskDates :ticketing-task="ticketingTask" @loadTaskDetails="loadTaskDetails" />
      <div class="assignee pt-2 mr-6 " v-if="!taskStateSuspended">
        <div class="d-flex align-start align-md-center flex-md-row flex-column">
          <div class="flex-md-row flex-column">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  {{ $t('ticketingSystem.note.assignedUser') }}
                </span>
              </template>
              <span>{{ $t('ticketingSystem.entry.assignee') }}</span>
            </v-tooltip>
            <a v-if="!taskFinished && !showDropdown && hasPermissionForTask" @click.stop="showAssigneeDropdown">
              <span v-if="!ticketingTask.assignee">{{ $t('ticketingSystem.taskDetails.buttons.unassigned') }}</span>
              <UserName :user="ticketingTask.assignee" />
            </a>
            <span v-else-if="!showDropdown && ticketingTask.assignee"><UserName :user="ticketingTask.assignee" /></span>
          </div>
          <TaskAssignee
            v-if="showDropdown"
            v-bind:form="select"
            ref="assigneeLink"
            :assigned-to-me="assignedToMe"
            :candidate-groups="ticketingTask.candidateGroups"
            :user-has-ticketing-role="userHasTicketingRole"
            @changedAssignee="changedAssignee(false)"
            @hideAssigneeDropdown="hideAssigneeDropdown"
          />
          <CandidateGroups
            :task-id="ticketingTask.taskId"
            :task-candidate-groups="ticketingTask.candidateGroups"
            :task-finished="taskFinished"
            :finished-loading="finishedLoading"
            show-title-label
          />
        </div>
      </div>
    </div>
  </v-layout>
</template>

<script>
import TaskAssignee from './TaskAssignee'
import CandidateGroups from './CandidateGroups'
import TaskDates from './TaskDates'
import { mapActions, mapState } from 'vuex'
import { AuthenticationContext } from 'vue-adal'
import UserName from '@/components/UserName'

export default {
  name: 'TaskFirstRowInfo',
  components: {
    UserName,
    TaskAssignee,
    CandidateGroups,
    TaskDates
  },
  data () {
    return {
      select: { value: null },
      form: {
        assignee: null
      },
      showDropdown: false
    }
  },
  props: {
    ticketingTask: {
      type: Object,
      default: () => {}
    },
    loggedInUserId: {
      type: String,
      default: ''
    },
    assignedToMe: {
      type: Boolean,
      default: false
    },
    hasPermissionForTask: {
      type: Boolean,
      default: false
    },
    finishedLoading: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    ticketingTask () {
      this.hideAssigneeDropdown()
    }
  },
  computed: {
    ...mapState('ticketingSystem', ['ticketingUsers']),
    userHasTicketingRole () {
      let roles = AuthenticationContext?.user?.profile?.roles
      if (roles) {
        return roles.includes('prestige-tools-ticketing')
      } else {
        return false
      }
    },
    taskStateSuspended () {
      return this.ticketingTask.ticket && this.ticketingTask.ticket.state === 'SUSPENDED'
    },
    taskFinished () {
      return this.ticketingTask && this.ticketingTask.finishedOn !== '' && this.ticketingTask.finishedOn !== null
    },
    routeTaskId () {
      return this.$route.params.taskId || this.$route.query.taskId
    }
  },
  methods: {
    ...mapActions('notifier', ['setSuccessSnackbar', 'setErrorSnackbar']),
    showAssigneeDropdown () {
      if (!this.taskStateSuspended) {
        this.select = { value: { userId: this.ticketingTask?.assignee?.userId } }
        this.showDropdown = true
        this.$nextTick(()=>{
          this.$refs.assigneeLink.$refs.assignee.focus()
          this.$refs.assigneeLink.$refs.assignee.isMenuActive = true
        })
      }
    },
    hideAssigneeDropdown () {
      this.showDropdown = false
    },
    async changedAssignee (assignToMeClicked) {
      if ((this.select.value && this.select.value.userId) || assignToMeClicked) {
        let that = this
        let userId = assignToMeClicked ? 'assignToMe' : this.select.value.userId
        let data = this.setUserIdObj(userId)
        await this.$prestigeTSTask.assignTask(this.routeTaskId, data).then(function (response) {
          that.loadTaskDetails()
          that.setSuccessSnackbar(that.$t('ticketingSystem.messages.successfullyChangedAssignee'))
          return true
        }).catch(error => {
          that.setErrorSnackbar(error)
        })
      }
    },
    setUserIdObj (userId) {
      switch (userId) {
        case 'unassigned':
          return {}
        case 'assignToMe':
          return { userId: this.loggedInUserId }
        default:
          return { userId: this.select.value.userId }
      }
    },
    loadTaskDetails () {
      this.$emit('loadTaskDetails')
    }
  }
}
</script>

<style scoped lang="postcss">
.first-row {
  line-height: 2px;
}
</style>
