var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.multiDeclineOption)?_c('v-btn',{staticClass:"mb-3",attrs:{"disabled":_vm.selected.length === 0,"loading":_vm.declineLoading,"color":"primary"},on:{"click":_vm.openDeclineDialog}},[_vm._v(" "+_vm._s(_vm.$t('renting.abacus.confirmDeclineTitle'))+" ("+_vm._s(_vm.selected.length)+") ")]):_vm._e(),_c('ConfirmationDialog',{attrs:{"data":_vm.declineDialogData,"show-dialog":_vm.showDeclineDialog},on:{"closeDialog":_vm.closeDeclineDialog,"submitDialog":_vm.declineSelected}}),(_vm.rentalForms)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"loading":_vm.loading,"items":_vm.rentalForms,"show-select":_vm.multiDeclineOption,"options":_vm.options,"footer-props":{itemsPerPageOptions:_vm.itemsPerPageOptions, itemsPerPageText: _vm.itemsPerPageText},"item-key":"ticketId"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.processStartedOn",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.processStartedOnFormatted))])]}},{key:"item.desiredMoveInDateFormatted",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.desiredMoveInDateFormatted)+" ")]}},{key:"item.processDecision",fn:function(ref){
var item = ref.item;
return [_c('DecisionIcon',{attrs:{"decision":item.processDecision}}),_c('span',[_vm._v(_vm._s(_vm.$t('renting.statuses.' + item.processDecision)))]),(item.processDecisionComment)?_c('p',{staticClass:"text-pre-wrap",domProps:{"innerHTML":_vm._s(item.processDecisionComment)}}):_vm._e()]}},{key:"item.step",fn:function(ref){
var item = ref.item;
return [(item.openTasks)?_c('div',_vm._l((item.openTasks),function(task,index){return _c('p',{key:'open_task' + index,staticClass:"open-task pa-1 mb-0"},[_vm._v(" "+_vm._s(task.name)+" ")])}),0):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('nobr',[_c('v-btn',{attrs:{"x-small":"","color":"blue"},on:{"click":function($event){$event.stopPropagation();return _vm.openProcessDetails(item.ticketId)}}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.$t('common.buttons.open')))]),_c('v-icon',{staticClass:"ml-2",attrs:{"small":"","color":"white"}},[_vm._v("mdi-window-open")])],1)],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('v-progress-linear',{attrs:{"slot":"progress","color":"blue","indeterminate":""},slot:"progress"})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }