
import Corporations from '../../dossier/corporations/Corporations'
import CorporationDetail from '../../dossier/corporations/CorporationDetail'
import DossierConfiguration from '../../dossier/DossierConfiguration'
import PrinterQueue from '../../dossier/PrinterQueue'
import DossierRecipients from '../../dossier/recipients/DossierRecipients'
import RecipientDetail from '../../dossier/recipients/RecipientDetail'

export const DossierRoutes = [
  {
    path: '/dossier/corporations',
    name: 'Corporations',
    component: Corporations,
    meta: {
      title: 'dossier',
      printerToolbar: true,
      requireAuth: true,
      requireRoles: [
        'prestige-tools-reader'
      ]
    }
  },
  {
    path: '/dossier/corporations/:corporationId',
    name: 'CorporationDetail',
    component: CorporationDetail,
    meta: {
      title: 'dossier',
      printerToolbar: true,
      requireAuth: true,
      requireRoles: [
        'prestige-tools-reader'
      ]
    }
  },
  {
    path: '/dossier/recipients',
    name: 'DossierRecipients',
    component: DossierRecipients,
    meta: {
      title: 'dossier',
      printerToolbar: true,
      requireAuth: true,
      requireRoles: [
        'prestige-tools-reader'
      ]
    }
  },
  {
    path: '/dossier/recipients/:recipientId',
    name: 'RecipientDetail',
    component: RecipientDetail,
    meta: {
      title: 'dossier',
      printerToolbar: true,
      requireAuth: true,
      requireRoles: [
        'prestige-tools-reader'
      ]
    }
  },
  {
    path: '/dossier/queue',
    name: 'PrinterQueue',
    component: PrinterQueue,
    meta: {
      title: 'dossier',
      printerToolbar: true,
      requireAuth: true,
      requireRoles: [
        'prestige-tools-reader'
      ]
    }
  },
  {
    path: '/dossier/configuration',
    name: 'DossierConfiguration',
    component: DossierConfiguration,
    meta: {
      title: 'dossier',
      printerToolbar: true,
      requireAuth: true,
      requireRoles: [
        'prestige-tools-reader'
      ]
    }
  }
]
