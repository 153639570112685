var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.readOnly && _vm.selectedPriority)?_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":_vm.selectedPriority.color},on:{"click":function($event){$event.stopPropagation();return _vm.setReadOnly.apply(null, arguments)}}},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.selectedPriority.icon))])]}}],null,false,471094903)},[_c('span',[_vm._v(_vm._s(_vm.selectedPriority.label))])])],1):(!_vm.readOnly)?_c('v-select',{attrs:{"items":_vm.priorities,"item-value":"value","item-text":"label","label":_vm.$t('ticketingSystem.priority.title')},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.color}},[_vm._v(_vm._s(item.icon))]),_c('span',[_vm._v(_vm._s(item.label))])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.color}},[_vm._v(_vm._s(item.icon))]),_c('span',[_vm._v(_vm._s(item.label))])]}}]),model:{value:(_vm.priority),callback:function ($$v) {_vm.priority=$$v},expression:"priority"}}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }