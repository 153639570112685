<template>
  <div class="main-bpmn-diagram">
    <div class="diagram-actions mb-2" v-if="!noDiagram">
      <v-tooltip v-if="!fullscreen" bottom id="openFullscreen">
        <template v-slot:activator="{ on, attrs }">
          <v-icon :size="26" class="mr-2" v-bind="attrs" v-on="on" @click="openFullscreen">mdi-fullscreen</v-icon>
        </template>
        <span >{{ $t('bpmnDiagram.actions.openFullscreen') }}</span>
      </v-tooltip>
      <v-tooltip v-if="fullscreen" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon :size="26" class="mr-2" v-bind="attrs" v-on="on" @click="closeFullscreen">mdi-fullscreen-exit</v-icon>
        </template>
        <span>{{ $t('bpmnDiagram.actions.exitFullscreen') }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon :size="26" class="mr-2" v-bind="attrs" v-on="on" @click="fitToViewport">all_out</v-icon>
        </template>
        <span>
          {{ $t('bpmnDiagram.actions.fitToViewport') }}
        </span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon :size="26" class="mr-2" v-bind="attrs" v-on="on" @click="zoomIn">zoom_in</v-icon>
        </template>
        <span>
          {{ $t('bpmnDiagram.actions.zoomIn') }}
        </span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon :size="26" class="mr-2" v-bind="attrs" v-on="on" @click="zoomOut">zoom_out</v-icon>
        </template>
        <span>
          {{ $t('bpmnDiagram.actions.zoomOut') }}
        </span>
      </v-tooltip>
    </div>
    <v-card-text v-if="loading">
      <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
    </v-card-text>
    <div ref="diagramContainer" class="diagram-container" v-show="!loading"></div>
    <fieldset class="diagram-legend pa-2" v-if="!noDiagram">
      <legend class="pl-2 pr-2">{{ $t('bpmnDiagram.legend.title') }}</legend>
      <div v-bind:class="[{ 'd-flex justify-space-between': mdAndUp }]">
        <div class="d-flex align-center">
          <span class="box highlight-opened"></span>
          <span class="pl-2">{{ $t('bpmnDiagram.legend.status.opened') }}</span>
        </div>
        <div class="d-flex align-center">
          <span class="box highlight-active-opened"></span>
          <span class="pl-2">{{ $t('bpmnDiagram.legend.status.activeOpened') }}</span>
        </div>
        <div class="d-flex align-center">
          <span class="box highlight-completed"></span>
          <span class="pl-2">{{ $t('bpmnDiagram.legend.status.completed') }}</span>
        </div>
        <div class="d-flex align-center">
          <span class="box highlight-active-completed"></span>
          <span class="pl-2">{{ $t('bpmnDiagram.legend.status.activeCompleted') }}</span>
        </div>
      </div>
    </fieldset>
    <v-card-text v-if="noDiagram">
      {{ $t('bpmnDiagram.messages.noDiagram') }}
    </v-card-text>
  </div>
</template>

<script>
import BpmnNavigatedViewer from 'bpmn-js/lib//NavigatedViewer'
import { mapActions } from 'vuex'

export default {
  name: 'BpmnDiagram',
  Components: {
    BpmnNavigatedViewer
  },
  props: {
    processDefinitionId: {
      type: String,
      default: ''
    },
    dialog: {
      type: Boolean,
      default: false
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    activities: {
      type: Array,
      default: () => []
    },
    showDialog: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      bpmnViewer: null,
      bpmnModel: null,
      noDiagram: false,
      zoom: 0,
      zoomValue: 0.1,
      loading: false
    }
  },
  computed: {
    canvas () {
      return this.bpmnViewer ? this.bpmnViewer.get('canvas') : null
    },
    routeTaskId () {
      return this.$route.params.taskId || this.$route.query.taskId
    },
    mdAndUp () {
      return this.$vuetify.breakpoint.mdAndUp
    }
  },
  watch: {
    bpmnModel (val) {
      if (val) {
        this.importXML(val)
      }
    },
    activities (val) {
      if (val && val.length > 0 && this.canvas) {
        this.setTasksMarkers()
      }
    },
    showDialog (val) {
      if (val) {
        this.fetchDiagram()
      }
    }
  },
  created () {
    this.fetchDiagram()
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    fetchDiagram () {
      this.loading = true
      let that = this
      this.$prestigeTSXMLProcess.getXmlProcessModel(this.processDefinitionId).then(function (response) {
        if (response.data) {
          that.noDiagram = false
          that.bpmnModel = response.data
          if (that.bpmnModel) {
            that.renderDiagram()
          }
        } else {
          that.noDiagram = true
        }
        that.loading = false
      }).catch(error => {
        that.noDiagram = true
        that.bpmnModel = null
        that.loading = false
        that.setErrorSnackbar(error)
      })
    },
    renderDiagram () {
      const diagramContainer = this.$refs.diagramContainer
      if (diagramContainer) {
        diagramContainer.innerHTML = ''
        this.bpmnViewer = new BpmnNavigatedViewer({
          container: diagramContainer
        })
        if (this.bpmnModel && this.activities && this.activities.length > 0) {
          this.importXML(this.bpmnModel)
        }
      }
    },
    importXML (bpmnModel) {
      let that = this
      this.bpmnViewer
        .importXML(bpmnModel)
        .then(() => this.modelCanvas())
        .catch(error => {
          that.setErrorSnackbar(error)
        })
    },
    zoomIn () {
      this.zoom += this.zoomValue
      this.applyZoom()
    },
    zoomOut () {
      if (this.zoom >= 0) {
        this.zoom -= this.zoomValue
        this.applyZoom()
      }
    },
    applyZoom () {
      if (this.canvas) {
        this.canvas.zoom(this.zoom)
      }
    },
    modelCanvas () {
      this.fitToViewport()
    },
    getOpenTasksFromActivities () {
      if (this.activities && this.activities.length > 0) {
        return this.activities.filter(activity => activity.endTime === null)
      } else return []
    },
    getCompletedTasksFromActivities () {
      if (this.activities && this.activities.length > 0) {
        return this.activities.filter((v, i, a) => a.findIndex(t => (t.activityId === v.activityId)) === i)
          .filter(activity => activity.endTime !== null)
      } else return []
    },
    fitToViewport () {
      if (this.canvas && this.bpmnViewer && this.bpmnModel) {
        this.canvas.zoom('fit-viewport')
        this.zoom = this.canvas._cachedViewbox.scale
        let eventBus = this.bpmnViewer.get('eventBus')
        this.setTasksMarkers()
        let that = this
        eventBus.on('element.click', (event) => {
          if (event.element.type === 'bpmn:UserTask') {
            event.stopPropagation()
            if (that.dialog) {
              that.$emit('closeDialog')
            }
            let activityId = event.element.id
            if (that.activities && that.activities.length > 0) {
              let diagramTasks = [...that.getOpenTasksFromActivities(), ...that.getCompletedTasksFromActivities()]
              let task = diagramTasks.find(x => x.activityId === activityId)
              let taskId = task ? task.taskId : null
              let differentTask = taskId && (that.$route.params.taskId !== taskId || that.$route.query.taskId !== taskId)
              if (differentTask) {
                if (that.$route.params.processInstanceId) {
                  let taskFinished = task.endTime !== null && task.endTime !== ''
                  that.$emit('openTask', taskId, taskFinished)
                } else {
                  const query = { ...that.$route.query, taskId: taskId }
                  that.$router.replace({ query })
                }
              }
            }
          }
        })
      }
    },
    setTasksMarkers () {
      this.setOpenTasksMarkers()
      this.setCompletedTasksMarkers()
    },
    setOpenTasksMarkers () {
      const openTasks = this.getOpenTasksFromActivities()
      if (openTasks) {
        this.setTaskMarkers(openTasks, 'highlight-opened')
        this.highlightActiveTask(openTasks, 'highlight-active-opened')
      }
    },
    setCompletedTasksMarkers () {
      const completeTasks = this.getCompletedTasksFromActivities()
      this.setTaskMarkers(completeTasks, 'highlight-completed')
      this.highlightActiveTask(completeTasks, 'highlight-active-completed')
    },
    setTaskMarkers (tasks, highlightClass) {
      if (tasks && tasks.length > 0) {
        let that = this
        tasks.filter(task => task.activityType !== 'multiInstanceBody').forEach(task => {
          that.canvas.addMarker(task.activityId, highlightClass)
          if (task.taskId) {
            that.canvas.addMarker(task.activityId, 'clickable')
          }
        })
      }
    },
    highlightActiveTask (tasks, highlightActiveClass) {
      if (this.routeTaskId && tasks && tasks.length > 0) {
        let activeTask = tasks.filter(x => x.taskId !== null).find(x => x.taskId?.toString() === this.routeTaskId)
        if (activeTask && activeTask.activityId) {
          this.canvas.addMarker(activeTask.activityId, highlightActiveClass)
        }
      }
    },
    openFullscreen () {
      this.$emit('openFullscreen')
      this.fitToViewport()
      let ae = document.activeElement
      if (ae) {
        ae.blur()
      }
    },
    closeFullscreen () {
      this.$emit('closeFullscreen')
      this.fitToViewport()
    }
  }
}
</script>

<style lang="postcss" scoped>
.diagram-container:hover {
  cursor: grab;
}
.diagram-container:active {
  cursor: grabbing;
}
/deep/ .highlight-opened:not(.djs-connection) .djs-visual > :nth-child(1) {
  fill: rgb(228, 239, 250) !important;
}
/deep/ .highlight-active-opened:not(.djs-connection) .djs-visual > :nth-child(1) {
  fill: #1976d2 !important;
}
/deep/ .highlight-completed:not(.djs-connection) .djs-visual > :nth-child(1) {
  fill: rgba(76, 175, 80, 0.4) !important;
}
/deep/ .highlight-active-completed:not(.djs-connection) .djs-visual > :nth-child(1) {
  fill: rgba(76, 175, 80, 1) !important;
}
/deep/ .clickable.hover {
  cursor: pointer;
}
/deep/ .clickable.hover:not(.djs-connection) .djs-visual > :nth-child(1) {
  fill:rgba(245, 245, 220) !important;
}
/deep/ g.djs-element:not(.clickable) {
  pointer-events: none !important;
}
.box {
  width: 14px;
  height: 14px;
  border-radius: 4px;
}
.highlight-opened {
  background-color: rgb(228, 239, 250);
}
.highlight-active-opened {
  background-color: #1976d2;
}
.highlight-completed {
  background-color: rgba(76, 175, 80, 0.4);
}
.highlight-active-completed {
  background-color: rgba(76, 175, 80, 1);
}
</style>
