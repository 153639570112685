<template>
  <div>
    <v-card-text v-if="decisionReadOnly">
      <v-alert :type="decisionType" dense outlined>
        <strong>{{ $t('renting.statuses.' + decision) }}</strong>
        <p class="text-pre-wrap" v-if="decisionComment" v-html="decisionComment"></p>
      </v-alert>
    </v-card-text>
    <template v-else>
      <v-card-text>
        <p>{{ $t('ticketingSystem.processes.interestedPerson.decision.boxTitle') }}</p>
        <v-form ref="decisionForm" v-model="decisionValid">
            <v-select
              :items="statusOptions"
              item-value="value"
              item-text="label"
              dense
              required
              :rules="[rules.required]"
              :label="$t('ticketingSystem.processes.interestedPerson.decision.title')"
              v-model="selectedDecision"
            >
            </v-select>
            <AutocompleteLinks
              ref="decisionComment"
              v-model="selectedDecisionComment"
              :default-html="selectedDecisionComment"
              :label="$t('ticketingSystem.processes.interestedPerson.decision.reason')"
              :validate-clicked="selectedDecisionClicked"
            />
            <v-btn
              @click="sendDecision"
              :loading="processingRequest"
              class="mt-2"
            >
              {{ $t('ticketingSystem.processes.interestedPerson.decision.submitLabel') }}
            </v-btn>
        </v-form>
      </v-card-text>
    </template>
  </div>
</template>

<script>
import AutocompleteLinks from '@/features/ticketing/components/AutocompleteLinks'
import { statusOptions } from '@/config/options/decision'
import { mapActions } from 'vuex'
import { TICKETING_TICKET_OPEN_TASKS } from './../../../../ticketing/graphql-apollo/ticketingTicket.gql'

export default {
  name: 'SetRentalApplicationDecision',
  components: {
    AutocompleteLinks
  },
  data () {
    return {
      selectedDecisionClicked: false,
      selectedDecision: null,
      selectedDecisionComment: '',
      decisionValid: false,
      statusOptions: statusOptions,
      processingRequest: false,
      rules: {
        required: (value) => (!!value && value !== undefined) || this.requiredMessage
      }
    }
  },
  apollo: {
    ticketingTicket: {
      query: TICKETING_TICKET_OPEN_TASKS,
      skip () {
        return !this.processInstanceId
      },
      error (error) {
        this.setErrorSnackbar(error.message)
      },
      variables () {
        return {
          ticketId: this.processInstanceId
        }
      }
    }
  },
  watch: {
    decision: {
      handler () {
        this.selectedDecision = this.decision || 'IN_PROGRESS'
      },
      immediate: true
    },
    decisionComment: {
      handler () {
        this.selectedDecisionComment = this.decisionComment
      },
      immediate: true
    }
  },
  props: {
    processInstanceId: {
      type: String,
      default: null
    },
    decision: {
      type: String,
      default: null
    },
    decisionComment: {
      type: String,
      default: null
    }
  },
  computed: {
    decisionReadOnly () {
      return this.decision && this.decision !== 'IN_PROGRESS' && this.decision !== 'PRIORITY_1' && this.decision !== 'PRIORITY_2'
    },
    decisionType () {
      if (this.decision === 'COMMITMENT') {
        return 'success'
      } else if (this.decision === 'CANCELLATION_BY_INTERESTED_PERSON' || this.decision === 'PRIORITY_1' || this.decision === 'PRIORITY_2') {
        return 'info'
      } else if (this.decision === 'ALREADY_RENTED' || this.decision === 'NOT_ELIGABLE') {
        return 'warning'
      } else {
        return 'error'
      }
    },
    requiredMessage () {
      return this.$t('common.errors.required')
    }
  },
  methods: {
    ...mapActions('notifier', ['setSuccessSnackbar', 'setErrorSnackbar']),
    getEditPendingTaskId () {
      let task = this.ticketingTicket.openTasks.find((task)=> task.taskDefinitionKey === 'editPending')
      return task?.taskId
    },
    sendDecision () {
      this.selectedDecisionClicked = true
      this.$refs.decisionForm.validate()
      if (this.decisionValid && !this.$refs.decisionComment.ariaInvalid) {
        const payload = {
          variables: [
            {
              'variableName': 'System_InterestedPerson_Decision_Enum',
              'variableValue': this.selectedDecision,
              'variableType': 'string'
            },
            {
              'variableName': 'System_InterestedPerson_DecisionComment_String',
              'variableValue': this.selectedDecisionComment,
              'variableType': 'string'
            }
          ]
        }
        this.processingRequest = true

        if (this.selectedDecision === 'IN_PROGRESS' || this.selectedDecision === 'PRIORITY_1' || this.selectedDecision === 'PRIORITY_2') {
          this.$prestigeTSProcess.saveProcessVariables(this.processInstanceId, payload).then(() => {
            this.processingRequest = false
            this.setSuccessSnackbar(this.$t('ticketingSystem.processes.interestedPerson.decision.successMessage'))
            this.$emit('loadDetails')
          }).catch(error => {
            this.processingRequest = false
            this.setErrorSnackbar(error)
          })
        } else {
          this.$prestigeTSProcess.createMessageEvent(this.processInstanceId, 'DecisionEvent', payload).then(async (response) => {
            this.processingRequest = false
            this.setSuccessSnackbar(this.$t('ticketingSystem.processes.interestedPerson.decision.successMessage'))
            this.$emit('loadDetails')
          }).catch(error => {
            this.processingRequest = false
            this.setErrorSnackbar(error)
          })
        }
        if (this.selectedDecision) {
          const taskId = this.getEditPendingTaskId()
          const status = this.statusOptions.find((item) => item.value === this.selectedDecision);
          if (taskId) {
            let that = this
            this.$prestigeTSTask.updateTaskDetails(taskId, {name: `${this.$t('ticketingSystem.processes.processPending')} (${status.label})`}).then(function (response) {
              if (response.status === 200) {
                that.setSuccessSnackbar(that.$t('ticketingSystem.messages.successfullyUpdatedTask'))
              }
              return true
            }).catch(error => {
              that.setErrorSnackbar(error)
            })
          }
        }
      }
    }
  }
}
</script>
