var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"task-dates"},[_c('div',{staticClass:"created-on float-left mr-6 mb-3 mt-2"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"grey darken-2","size":22}},'v-icon',attrs,false),on),[_vm._v("mdi-calendar-start")]),_vm._v(" "+_vm._s(_vm.formatDateAndTimeFunc(_vm.ticketingTask.startedOn))+" ")],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('common.date.createdOn')))])])],1),_c('div',{staticClass:"due-on float-left mr-6 mb-3 mt-2",attrs:{"id":"dueId"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('DueDate',_vm._g(_vm._b({attrs:{"task-finished":_vm.taskFinished,"task-details":_vm.ticketingTask,"attach-selector":'#dueId'},on:{"updateDueDate":_vm.updateTaskDueDate}},'DueDate',attrs,false),on))]}}])})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.taskFinished),expression:"taskFinished"}],staticClass:"finished-on float-left mr-6 mb-3 mt-2"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"grey darken-2","size":22}},'v-icon',attrs,false),on),[_vm._v("mdi-calendar-end")]),_vm._v(" "+_vm._s(_vm.formatDateAndTimeFunc(_vm.ticketingTask.finishedOn))+" ")],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('common.date.finishedOn')))])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }