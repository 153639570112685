import FeedbackWrite from '../../feedback/FeedbackWrite'
import FeedbackList from '../../feedback/FeedbackList'

export const FeedbackRoutes = [
  {
    path: '/feedback/form/:id/:token',
    name: 'FeedbackWrite',
    component: FeedbackWrite,
    meta: {
      full: true,
      title: 'feedbackPublicForm',
      requireAuth: false
    }
  },
  {
    path: '/feedback/list',
    name: 'FeedbackList',
    component: FeedbackList,
    meta: {
      title: 'feedback',
      requireAuth: true,
      requireRoles: [
        'prestige-tools-reader'
      ]
    }
  }
]
