<template>
  <v-card class="mb-2 pa-0">
    <v-card-text class="pa-4">
      <v-icon color="primary" @click="toggle" class="float-right">{{ icon }}</v-icon>
      <v-card-subtitle class="pa-0 font-weight-bold subtitle-1">
        {{ $t('common.properties') }}
      </v-card-subtitle>
    </v-card-text>
    <v-divider></v-divider>
    <v-layout v-if="isOpen" row wrap class="ma-0">
      <template v-for="item in suggestions">
        <template v-for="(property, index) in item.value">
          <v-list-item class="pl-0 pr-0" :key="index" @click="addLinkFromSuggestion(property)">
            <v-list-item-content class="pa-0">
              <v-card-text>
                <p class="pa-0 ma-0">
                  <span>[<a>{{ property.propertyId }}</a>] {{ propertyInfo(property) }}</span>
                </p>
              </v-card-text>
            </v-list-item-content>
          </v-list-item>
          <v-divider :key="'divider_' + index"></v-divider>
        </template>
      </template>
    </v-layout>
  </v-card>
</template>

<script>
import * as globalHelper from '@/helpers/globalHelper'

const PROPERTY = 'property'

export default {
  name: 'SuggestionsProperty',
  props: {
    suggestions: {
      type: Array,
      default: () => []
    },
    linkValues: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      isOpen: true
    }
  },
  computed: {
    icon () {
      return this.isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
    }
  },
  methods: {
    toggle () {
      this.isOpen = !this.isOpen
    },
    propertyInfo (item) {
      return globalHelper.setAddressInfo(item)
    },
    addLinkFromSuggestion (item) {
      this.$emit('addLinkFromSuggestion', {
        linkName: PROPERTY,
        linkValues: [item.propertyId.toString()]
      })
    }
  }
}
</script>
<style scoped lang="postcss">
</style>
