<template>
  <v-card-subtitle class="white text--primary pt-3">
    <p class="ma-0">
      <span class="font-weight-bold">{{ $t('ticketingSystem.messages.email.from') }}: </span>
      <span>{{ email.from }}</span>
    </p>
    <MailClientList v-if="email.to.length > 0" :mails-list="email.to" type="to" />
    <MailClientList v-if="email.cc.length > 0" :mails-list="email.cc" type="cc" />
    <MailClientList v-if="email.bcc.length > 0" :mails-list="email.bcc" type="bcc" />
  </v-card-subtitle>
</template>

<script>
import MailClientList from './MailClientList'

export default {
  name: 'MailClient',
  components: {
    MailClientList
  },
  props: {
    email: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style scoped lang="postcss">
</style>
