<template>
  <v-flex class="mt-3" xs12 sm12 md4 lg4 xl4>
    <v-card height="100%">
      <v-container fluid grid-list-xs>
        <v-layout row wrap>
          <v-flex xs12 sm12 md12 lg12 xl12>
            <v-card-title class="pa-0">{{ $t('tasks.createTask.recentTasks') }}</v-card-title>
            <v-text-field
              class="pr-2"
              append-icon="search"
              :label="$t('common.buttons.search')"
              single-line
              hide-details
              v-model="search"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm12 md12 lg12 xl12>
            <v-data-table
              :headers="headers"
              :items="recentTasks"
              :loading="loading"
              :search="search"
              sort-by="id"
              sort-desc
              searchable
              :footer-props="{itemsPerPageOptions:itemsPerPageOptions, itemsPerPageText: itemsPerPageText}"
              :options.sync="options"
              @click:row="openTaskDetails"
              class="elevation-1 mr-2 mt-2 mb-2"
            >
              <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
              <template v-slot:[`item.jobDescription`]="{ item }">
                <p class="mb-0 truncate-two-lines">{{ item.jobDescription }}</p>
              </template>
              <template v-slot:[`item.createdOn`]="{ item }">
                {{ item.createdOnFormatted }}
              </template>
              <template slot="no-data">
                <v-alert class="mt-4" :value="true" color="error" icon="warning">
                  {{ $t('table.noData') }}
                </v-alert>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </v-flex>
</template>

<script>
import { formatDate } from '@/helpers/formatterHelper'
import { mapActions } from 'vuex'

export default {
  name: 'RecentTasks',
  props: {
    propertyId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      loading: true,
      recentTasks: [],
      search: '',
      options: {
        itemsPerPage: 5
      }
    }
  },
  created () {
    this.loadRecentTasks()
  },
  computed: {
    headers () {
      return [
        {
          text: this.$t('tasks.createTask.jobDescription'),
          align: 'left',
          value: 'jobDescription'
        },
        {
          text: this.$t('common.craftsmen'),
          align: 'left',
          value: 'craftsmanInfo'
        },
        {
          text: this.$t('common.tenants'),
          align: 'left',
          value: 'tenantInfo'
        },
        {
          text: this.$t('common.date.creationDate'),
          align: 'left',
          value: 'createdOn'
        }
      ]
    },
    itemsPerPageOptions () {
      return [
        5,
        10,
        25,
        {
          'text': this.$t('common.all'),
          'value': -1
        }
      ]
    },
    itemsPerPageText () {
      return this.$t('common.itemsPerPageText')
    }
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    formatDateFunc: formatDate,
    loadRecentTasks () {
      let that = this
      this.$prestigeTask.searchTasks(this.propertyId).then(function (response) {
        if (response.data.length) {
          let tasks = response.data
          if (that.$route.params.taskId) {
            tasks = tasks.filter(x => x.id.toString() !== that.$route.params.taskId.toString())
          }
          tasks.forEach(item => {
            item.craftsmanInfo = `${item.craftsman?.firstName ?? ''} ${item.craftsman?.lastName ?? ''}`
            item.tenantInfo = `${item.currentTenant?.firstName ?? ''} ${item.currentTenant?.lastName ?? ''}`
            item.createdOnFormatted = that.formatDateFunc(item.createdOn)
          })
          that.recentTasks = tasks
        }
        that.loading = false
        return true
      }).catch(function (error) {
        that.setErrorSnackbar(error)
        that.loading = false
      })
    },
    openTaskDetails (task) {
      if (this.$route.params && this.$route.params.taskId !== task.id) {
        window.open('/tasks/detail/' + task.id.toString())
      }
    }
  }
}
</script>
<style scoped lang="postcss">
/deep/ tr:nth-child(odd) {
  background: #f8f8f8;
}
/deep/ tr {
  cursor: pointer;
}
.truncate-two-lines {
  display: block;
  display: -webkit-box;
  max-width: 250px;
  height: 39px;
  margin: 0 auto;
  line-height: 1.5;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
