<template>
  <v-container fluid class="ma-0">
    <v-layout>
      <v-flex xs12 sm12 md6 lg4>
        <v-autocomplete
          class="ma-0 pa-0"
          v-model="selectedProperty"
          :items="abacusPropertiesDisplayItems"
          :filter="customPropertyFilter"
          :label="$t('common.buttons.search')"
          :loading="abacusPropertiesLoading"
          cache-items
          single-line
          return-object
          clearable
          item-text="displayText"
          item-value="propertyId"
          @change="changedProperty"
        />
      </v-flex>
      <v-flex class="mt-2">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="clear ml-2 mr-1 float-right" color="primary" :size="28" @click="clearForm" v-bind="attrs" v-on="on" />
          </template>
          <span>{{ $t('dashboard.clearForm') }}</span>
        </v-tooltip>
      </v-flex>
    </v-layout>
    <template v-if="selectedProperty">
      <v-layout row class="d-block ml-0">
        <a @click="openMapProperty(selectedProperty.propertyId)">{{ $t('navigation.openMap') }}</a>
      </v-layout>
      <DashboardPersonsOverview
        :property-items="propertyItems"
        :priority-craftsman-list="priorityCraftsmanList"
        :property-partners="propertyPartners"
        @addressSelected="onAddressSelected"
        @openNewTicketDialog="onOpenNewTicketDialog"
      />
      <DashboardTenantsHouseOverview
        v-if="propertyObjects.length > 0"
        :property="selectedProperty"
        :property-objects="propertyObjects"
        @addressSelected="onAddressSelected"
        @openNewTicketDialog="onOpenNewTicketDialog"
      />
      <TicketDialog
        v-if="shouldShowNewTicketDialog"
        :process-instance="true"
        :new-links="newLinks"
        :default-subject="subject"
        :process-definition-key="newTicketDialogProcessDefinitionKey"
        :variables="newTicketDialogVariables"
        @closeDialog="shouldShowNewTicketDialog = false"
        @updateNewLinks="onUpdateNewLinks"
        @processInstanceCreated="onProcessInstanceCreated"
      />
    </template>
    <SpinnerOverlay :loading="loading" />
  </v-container>
</template>

<script>
import DashboardPersonsOverview from './DashboardPersonsOverview'
import DashboardTenantsHouseOverview from './DashboardTenantsHouseOverview'
import TicketDialog from '@/features/ticketing/dialogs/TicketDialog'
import SpinnerOverlay from '@/components/SpinnerOverlay'
import {mapActions, mapState} from 'vuex'
import {propertyFilter} from '@/helpers/globalHelper'
import _isEmpty from 'lodash.isempty'
import Vue from 'vue'
import {ABACUS_PROPERTY} from "@/features/admin/graphql-apollo/abacusAddress.gql";
import { ABACUS_ADDRESS_PRIORITY_CRAFTSMEN } from "./graphql-apollo/abacusAddress.gql"

export default {
  name: 'DashboardProperty',
  components: {
    DashboardPersonsOverview,
    DashboardTenantsHouseOverview,
    TicketDialog,
    SpinnerOverlay
  },
  data () {
    return {
      loading: false,
      selectedProperty: null,
      propertyItems: [],
      propertyObjects: [],
      priorityCraftsmanList: [],
      propertyPartners: [],
      shouldShowNewTicketDialog: false,
      newLinks: [],
      newTicketDialogProcessDefinitionKey: null,
      newTicketDialogVariables: [],
      subject: ''
    }
  },
  apollo: {
    abacusProperty: {
      query: ABACUS_PROPERTY,
      update (data) {
        if (data.abacusProperty && data.abacusProperty.propertyPartners) {
          data.abacusProperty.propertyPartners = data.abacusProperty.propertyPartners.map(propertyPartner => {
              return Object.assign(propertyPartner, {
                details: this.setPropertyPartnerDetails(propertyPartner.partner)
              })
          })
        }
        this.propertyPartners = data.abacusProperty.propertyPartners
        return data.abacusProperty.propertyPartners
      },
      skip() {
        return !this.routePropertyId
      },
      error (error) {
        this.setErrorSnackbar(error)
      },
      variables() {
        return {
          propertyId: parseInt(this.routePropertyId)
        }
      }
    },
    abacusPropertyPriorityCraftsmen: {
      query: ABACUS_ADDRESS_PRIORITY_CRAFTSMEN,
      update (data) {
        data.abacusPropertyPriorityCraftsmen.forEach(item => {
          item.details = item.address.firstName + ' ' + item.address.lastName
          item.details+= item.address.line1 ? ', ' + item.address.line1 : ''
          item.details+= ', ' + item.address.zip + ' ' + item.address.place
        })
        this.priorityCraftsmanList = data.abacusPropertyPriorityCraftsmen
        return data.abacusPropertyPriorityCraftsmen
      },
      variables () {
        return {
          propertyId: parseInt(this.routePropertyId)
        }
      },
      error (error) {
        this.setErrorSnackbar(error)
      },
      skip () {
        return isNaN(this.routePropertyId)
      },
      fetchPolicy: 'cache-first'
    }
  },
  computed: {
    ...mapState('abacus', ['abacusProperties', 'abacusPropertiesDisplayItems', 'abacusPropertiesLoading']),
    routePropertyId () {
      return this.$route.params.propertyId
    }
  },
  watch: {
    'routePropertyId': 'loadPropertyDetails'
  },
  async created () {
    await this.$store.dispatch('abacus/loadAbacusProperties')
    this.loadPropertyDetails()
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    changedProperty () {
      if (this.selectedProperty && this.selectedProperty.propertyId) {
        this.onPropertySelected(this.selectedProperty.propertyId)
      } else {
        this.$emit('resetRoute', true)
      }
    },
    onAddressSelected (id, name) {
      this.$emit('addressSelected', id, name)
    },
    onPropertySelected (propertyId) {
      this.$emit('propertySelected', propertyId)
    },
    loadPropertyDetails () {
      if (this.routePropertyId) {
        this.loading = true
        this.propertyItems = []
        this.propertyObjects = []
        this.fetchPropertyDetails()
      }
    },
    async fetchPropertyDetails () {
      let that = this
      let propertyId = this.selectedProperty?.propertyId || this.routePropertyId
      await that.$store.dispatch('abacus/loadPropertyDetails', propertyId).then(propertyDetails => {
        if (propertyDetails) {
          if (_isEmpty(that.selectedProperty)) {
            that.selectedProperty = {
              propertyId: propertyDetails.propertyId,
              displayText: `[${propertyDetails.propertyId}] ${propertyDetails.street}, ${propertyDetails.zip} ${propertyDetails.place}`
            }
          }
          if (propertyDetails.client) {
            that.propertyItems.push({ name: 'client', data: propertyDetails.client })
          }
          if (propertyDetails.caretaker) {
            that.propertyItems.push({ name: 'caretaker', data: propertyDetails.caretaker })
          }
          if (propertyDetails.realEstateManager) {
            let realEstateManager = {
              firstName: propertyDetails.realEstateManager.firstName,
              lastName: propertyDetails.realEstateManager.lastName,
              id: propertyDetails.realEstateManager.id
            }
            that.propertyItems.push({ name: 'realEstateManager', data: realEstateManager })
          }
          that.propertyObjects = propertyDetails.objects
        }
        that.loading = false
        return true
      }).catch(function (error) {
        that.setErrorSnackbar(error)
        that.loading = false
      })
    },
    clearForm () {
      this.selectedProperty = null
      this.propertyObjects = []
      this.propertyItems = []
      this.priorityCraftsmanList = []
      this.propertyPartners = []
      this.$emit('resetRoute', true)
    },
    customPropertyFilter (item, queryText, itemText) {
      return propertyFilter(queryText, itemText)
    },
    openMapProperty (propertyId) {
      window.open('/map/properties/' + propertyId.toString())
    },
    onOpenNewTicketDialog (links = [], subject = '', processDefinitionKey = null, variables = []) {
      this.newLinks = links
      this.subject = subject
      this.newTicketDialogProcessDefinitionKey = processDefinitionKey
      this.newTicketDialogVariables = variables
      this.shouldShowNewTicketDialog = true
    },
    onUpdateNewLinks (updatedLinks) {
      Vue.set(this, 'newLinks', updatedLinks)
    },
    onProcessInstanceCreated () {
      this.$emit('processInstanceCreated')
    },
    setPropertyPartnerDetails(propertyPartner) {
      let details = propertyPartner.firstName + ' ' + propertyPartner.lastName
      details += propertyPartner.line1 ? ', ' + propertyPartner.line1 : ''
      details += ', ' + propertyPartner.zip + ' ' + propertyPartner.place
      return details
    }
  }
}
</script>

<style scoped lang="postcss">
</style>
