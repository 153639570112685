<template>
  <v-dialog v-model="mutableShowDialog" max-width="800px" @keydown.esc="closeDialog" persistent>
    <v-card v-if="!noBuildingProjectDetails">
      <v-card-title class="pt-4 pb-2" primary-title>
        <h3>
          {{ buildingProject.title || $t('common.noTitle') }}
        </h3>
        <v-btn icon absolute right>
          <v-icon class="clear" color="primary" :size="28" @click.native="closeDialog"/>
        </v-btn>
      </v-card-title>
      <v-card-title class="pt-2 pb-0 body-2">{{ setValue(buildingProject, 'description') }}</v-card-title>
      <v-card-title class="pt-2 pb-0 body-2">{{ $t('common.address') }}: {{ buildingProjectAddress }}</v-card-title>
      <v-card-title class="pt-2 pb-0 body-2">{{ $t('constructionProjects.planningStage') }}: {{ setValue(buildingProject, 'planningState') }}</v-card-title>
      <v-card-title class="pt-2 pb-0 body-2">
        {{ $t('constructionProjects.constructionStart') }}: {{ buildingProjectStartOfConstruction }}
      </v-card-title>
      <v-card-title class="pt-2 pb-0 body-2">
        {{ $t('common.date.title') }}: {{ formatDateFunc(buildingProject.createdOn) }}
        <template>
          ({{ $t('constructionProjects.updated') }}: {{ buildingProject.createdOn !== buildingProject.updatedOn
            ? formatDateFunc(buildingProject.updatedOn) : ''
          }})
        </template>
      </v-card-title>
      <v-card-title class="pt-2 pb-0 body-2">
        {{ $t('common.id') }}: {{ buildingProject.buildingProjectId }}
      </v-card-title>
      <v-card-title v-if="buildingProject.buildingProjectAddresses" class="body-2">
        <v-layout row wrap class="pl-3 pt-2">
          <v-flex class="pr-4" sm4 v-for="(address, i) in buildingProject.buildingProjectAddresses" :key="i">
            <v-list class="pa-0">
              <p>
                <strong v-if="address.buildingProjectAddressType">{{ $t('common.mapAddressTypes.' + address.buildingProjectAddressType) }}</strong><br/>
                <span v-if="address.name">{{ address.name }}<br /></span>
                <span v-if="address.addressLine1">{{ address.addressLine1 }}<br /></span>
                <span v-if="address.addressLine2">{{ address.addressLine2 }}<br /></span>
                <span v-if="address.addressLine3">{{ address.addressLine3 }}<br /></span>
                <span v-if="address.zip || address.place">{{ address.zip }} {{ address.place }}<br /></span>
                <span v-if="address.phone">{{ address.phone }}<br /></span>
                <span v-if="address.email"><a :href="'mailto:' + address.email">{{ address.email }}</a><br /></span>
                <span v-if="address.website"><a target="_blank" :href="'http://' + address.website">{{ address.website }}</a></span>
              </p>
            </v-list>
          </v-flex>
        </v-layout>
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="mt-2" @click="closeDialog">{{ $t('common.buttons.cancel') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getBuildingProjectAddress } from '@/helpers/buildingProjectsHelper'
import { formatDate } from '@/helpers/formatterHelper'
import _isEmpty from 'lodash.isempty'

export default {
  name: 'BuildingProjectInfoDialog',
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    buildingProject: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    noBuildingProjectDetails () {
      return _isEmpty(this.buildingProject)
    },
    buildingProjectAddress () {
      return getBuildingProjectAddress(this.buildingProject)
    },
    buildingProjectStartOfConstruction () {
      return this.buildingProject && this.buildingProject.startOfConstruction ? this.formatDateFunc(this.buildingProject.startOfConstruction) : ''
    },
    mutableShowDialog () {
      return this.showDialog
    }
  },
  methods: {
    formatDateFunc: formatDate,
    setValue (obj, prop) {
      return !_isEmpty(obj) ? obj[prop] : ''
    },
    closeDialog () {
      this.$emit('closeDialog')
    }
  }
}
</script>

<style scoped lang="postcss">
.body-2 {
  font-weight: normal !important;
}
</style>
