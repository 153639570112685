<template>
  <v-card class="mb-0 pa-0" v-if="children.length > 0">
    <v-card-text class="pa-4">
      <v-icon color="primary" @click="toggle" class="float-right">{{ icon }}</v-icon>
      <v-card-subtitle class="pa-0 font-weight-bold subtitle-1">
        {{ $t('ticketingSystem.taskDetails.processReferences.children') }}
      </v-card-subtitle>
    </v-card-text>
    <v-divider></v-divider>
    <v-layout v-if="isOpen && children.length > 0" row wrap class="pa-0 ma-0">
      <v-flex v-for="(child, index) in children" :key="index" xs12 sm12 md12 lg12 class="mt-1">
        <v-card-text>
          <p class="pa-0 ma-0 flex truncate-line two-line-clamp" v-if="child.processDefinitionName">
            <StateIcon :state="child.state" />
            [<router-link class="process-instance-id truncate-line one-line-clamp" :to="{ name: TICKETING_PROCESS_DETAILS, params: { processInstanceId: child.processInstanceId }}">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on" class="ma-0 process-instance-id truncate-line one-line-clamp">{{ child.processInstanceId }}</span>
                </template>
                <span>{{ $t('ticketingSystem.processes.processDetails') }}</span>
              </v-tooltip>
            </router-link>]
            <span>
              {{ child.processDefinitionName }}
              <span v-if="child.processSubject"> - {{ child.processSubject }}</span>
            </span>
          </p>
          <p class="pt-1 ma-0" v-if="child.startedOn">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon :size="22" v-bind="attrs" v-on="on" class="mr-1">mdi-calendar-start</v-icon>
                <span>{{ formatDateAndTimeFunc(child.startedOn) }}</span>
              </template>
              <span>
                {{ $t('ticketingSystem.taskDetails.processReferences.startedOn') }}
              </span>
            </v-tooltip>
          </p>
          <p class="pt-1 ma-0" v-if="child.finishedOn">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon :size="22" v-bind="attrs" v-on="on" class="mr-1">mdi-calendar-end</v-icon>
                <span>{{ formatDateAndTimeFunc(child.finishedOn) }}</span>
              </template>
              <span>
                {{ $t('ticketingSystem.taskDetails.processReferences.finishedOn') }}
              </span>
            </v-tooltip>
          </p>
        </v-card-text>
        <v-divider></v-divider>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import StateIcon from '@/components/StateIcon'
import { formatDateAndTime } from '@/helpers/formatterHelper'
import { TICKETING_PROCESS_DETAILS } from '@/config/options/routes/ticketingRoutes'

export default {
  name: 'ProcessChildren',
  props: {
    children: {
      type: Array,
      default: () => []
    }
  },
  components: {
    StateIcon
  },
  data () {
    return {
      TICKETING_PROCESS_DETAILS: TICKETING_PROCESS_DETAILS,
      isOpen: true
    }
  },
  computed: {
    icon () {
      return this.isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
    }
  },
  methods: {
    formatDateAndTimeFunc: formatDateAndTime,
    toggle () {
      this.isOpen = !this.isOpen
    }
  }
}
</script>

<style scoped lang="postcss">
.process-instance-id {
  max-width: 60px;
  padding-left: 2px;
}
.truncate-line {
  text-decoration: none;
  vertical-align: middle;
  display: -webkit-inline-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.one-line-clamp {
  -webkit-line-clamp: 1;
}
.two-line-clamp {
  -webkit-line-clamp: 2;
}
</style>
