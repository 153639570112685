<template>
  <v-dialog
    v-model="mutableShowDialog"
    max-width="600"
    @keydown.esc="closeDialog"
    @click:outside="closeDialog"
  >
    <v-card height="100%" class="pa-2">
      <v-card-title>
        <span class="headline">{{ $t('ticketingSystem.processes.editProcessSubject')}}</span>
        <v-btn icon absolute right>
          <v-icon class="clear" color="primary" :size="28" @click.native="closeDialog"/>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-0">
        <v-form ref="form">
          <v-text-field
            v-model="form.processSubject"
            :label="$t('tasks.createTask.subject')"
            @keydown.enter.prevent="updateProcessSubject"
            autofocus
          >
            <template #message="{ message }">
              <span v-if="message === 'required'">{{ requiredMessage }}</span>
            </template>
          </v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions class="mt-4">
        <v-spacer></v-spacer>
        <v-btn @click.native="closeDialog">{{ $t('common.buttons.close') }}</v-btn>
        <v-btn color="success" @click="updateProcessSubject">
          <span>{{ $t('common.buttons.save') }}</span>
          <v-icon class="ml-2">send</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ProcessSubjectDialog',
  props: {
    process: {
      type: Object
    },
    showDialog: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    process: {
      handler: 'initProcessSubject',
      immediate: true
    }
  },
  data () {
    return {
      form: {}
    }
  },
  computed: {
    processInstanceId () {
      return this.process.ticketId
    },
    mutableShowDialog () {
      return this.showDialog
    }
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar', 'setSuccessSnackbar']),
    initProcessSubject () {
      this.form.processSubject = this.process?.subject ?? ''
    },
    updateProcessSubject () {
      let processSubjectBody = {}
      processSubjectBody = {
        processSubject: this.form.processSubject
      }
      let that = this
      this.$prestigeTSProcess.updateProcessSubject(this.processInstanceId, processSubjectBody).then(function () {
        that.closeDialog()
        that.setSuccessSnackbar(that.$t('ticketingSystem.messages.successfullyUpdatedProcessSubject'))
        that.$emit('loadProcess')
        return true
      }).catch(error => {
        that.setErrorSnackbar(error)
      })
    },
    closeDialog () {
      this.$emit('closeDialog')
    }
  }
}
</script>

<style scoped lang="postcss">
/deep/ .diagram-container {
  height: 400px;
}
/deep/ .v-dialog--fullscreen .diagram-container {
  height: 80vh;
}
</style>
