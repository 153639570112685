<template>
  <v-row dense>
    <v-col cols="12">
      <v-card-text class="pl-0">
        {{ $t('ticketingSystem.taskDetails.checkList') }}
      </v-card-text>
    </v-col>
    <v-col cols="12" v-for="(item, i) in checklistItems" :key="i">
      <v-text-field
        class="mt-0 pt-0"
        ref="checklistInput"
        v-model="checklistItems[i].name"
        :label="$t('common.data.name')"
        @input="debounceInput"
      >
        <v-icon
          v-if="checklistItems.length > 1 && checklistItems[i].name !== ''"
          slot="append-outer"
          color="red"
          @click="deleteChecklistItem(i)"
        >
          delete
        </v-icon>
      </v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex'
import _debounce from 'lodash.debounce'

export default {
  name: 'ChecklistItemsVariables',
  data () {
    return {
      checklistItems: [{ name: '' }]
    }
  },
  updated () {
    let ticketDialogLayout = document.getElementById('ticketDialog')
    ticketDialogLayout.scrollTop = ticketDialogLayout.scrollHeight
  },
  methods: {
    ...mapActions('notifier', ['setSuccessSnackbar', 'setErrorSnackbar']),
    debounceInput: _debounce(function (e) {
      this.checklistItems = this.checklistItems.filter(x => x.name !== '')
      this.checklistItems.push({ name: '' })
      this.updateChecklistItemVariables()
    }, 100),
    deleteChecklistItem (index) {
      this.checklistItems.splice(index, 1)
      this.updateChecklistItemVariables()
    },
    updateChecklistItemVariables () {
      let items = this.checklistItems.filter(x => x.name !== '')
      this.$emit('updateChecklistItemVariables', items)
    }
  }
}
</script>

<style scoped lang="postcss">
</style>
