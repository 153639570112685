<template>
  <v-container fluid class="grey lighten-5 pa-2">
    <v-card class="pa-2">
      <v-card-title class="px-0">
        {{ $t('ticketingSystem.deleteProcess.title') }}
      </v-card-title>
      <v-form ref="form">
        <v-text-field
          ref="processInstanceId"
          :label="$t('ticketingSystem.deleteProcess.processInstance')"
          v-model="processInstanceId"
          required
          :rules="[rules.required]"
        >
        </v-text-field>
        <v-text-field
          ref="deleteReason"
          :label="$t('ticketingSystem.deleteProcess.reason')"
          v-model="deleteReason"
          required
          :rules="[rules.required]"
        >
        </v-text-field>
        <v-card-actions>
          <v-btn @click="deleteProcess" :disabled="disabled">{{ $t('common.buttons.delete') }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-container>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  name: 'TicketsAdmin',
  data () {
    return {
      processInstanceId: '',
      deleteReason: '',
      disabled: false,
      rules: {
        required: (value) => (!!value && true) || this.requiredMessage
      }
    }
  },
  computed: {
    requiredMessage () {
      return this.$t('common.errors.required')
    }
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar','setSuccessSnackbar']),
    deleteProcess () {
      if (this.$refs.form.validate()) {
        let that = this
        that.disabled = true
        const deleteReason = {
          deleteReason: this.deleteReason
        }
        this.$prestigeTSProcess.deleteProcess(this.processInstanceId, deleteReason).then(function (response) {
          that.clearForm()
          that.disabled = false
          that.setSuccessSnackbar(that.$t('common.messages.successfullyDeleted'))
        }).catch(error => {
          that.setErrorSnackbar(error)
          that.disabled = false
        })
      }
    },
    clearForm () {
      this.processInstanceId = null
      this.deleteReason = null
      this.$refs.form.reset()
    }
  }
}
</script>
<style scoped lang="postcss">
</style>
