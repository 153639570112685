<template>
  <v-container fluid class="grey lighten-5 pt-2 pl-5 pr-5 pb-15">
    <v-alert v-if="doesNotExist" type="warning">
      {{ $t('renting.interestedPerson.messages.doesNotExist') }}
    </v-alert>
    <v-form ref="form" v-if="showDetails">
      <v-layout row wrap>
        <PropertyInfo
          :property="mappedProperty"
          :property-object="mappedPropertyObject"
        />
      </v-layout>
      <ConfirmationDialog
        :data="deleteData"
        :show-dialog="showDeleteDialog"
        @closeDialog="closeDeleteDialog"
        @submitDialog="deleteInterestedPerson"
      />
      <v-card-title class="pa-0 mt-3 mb-3">{{ $t('renting.interestedPerson.personal') }}</v-card-title>
      <v-layout row wrap class="pl-2" v-if="interestedPerson">
        <v-flex xs12 sm12 md4 lg4 xl4 class="pr-2">
          <v-autocomplete
            :items="salutationItems"
            v-model="interestedPerson.salutatoryAddress"
            :label="$t('renting.interestedPerson.salutatoryAddress')"
          >
            <template slot="selection" slot-scope="{ item }">
              {{ $t('renting.interestedPerson.salutations.' + item.value) }}
            </template>
            <template slot="item" slot-scope="{ item }">
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('renting.interestedPerson.salutations.' + item.value) }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-text-field
            v-model="interestedPerson.salutatoryName"
            :label="$t('renting.interestedPerson.salutatoryName')"
          ></v-text-field>
          <v-text-field
            v-model="interestedPerson.firstName"
            :label="$t('renting.interestedPerson.firstName')"
            required
            :rules="[rules.required]"
          ></v-text-field>
          <v-text-field
            v-model="interestedPerson.lastName"
            :label="$t('renting.interestedPerson.lastName')"
            required
            :rules="[rules.required]"
          ></v-text-field>
          <DatePicker
            v-model="interestedPerson.dateOfBirth"
            class="datepicker-input"
            ref="inputDatePicker"
            :default-value="false"
            :default-value-date="interestedPerson.dateOfBirth"
            :label="$t('renting.interestedPerson.dateOfBirth')"
          ></DatePicker>
        </v-flex>
        <v-flex xs12 sm12 md4 lg4 xl4 class="pr-2">
          <v-text-field
            v-model="interestedPerson.addressLine1"
            :label="$t('renting.interestedPerson.addressLine1')"
          ></v-text-field>
          <v-text-field
            v-model="interestedPerson.addressLine2"
            :label="$t('renting.interestedPerson.addressLine2')"
          ></v-text-field>
          <v-text-field
            v-model="interestedPerson.addressLine3"
            :label="$t('renting.interestedPerson.addressLine3')"
          ></v-text-field>
          <v-text-field
            v-model="interestedPerson.zip"
            :label="$t('common.addressFields.zip')"
          ></v-text-field>
          <v-text-field
            v-model="interestedPerson.place"
            :label="$t('common.addressFields.place')"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm12 md4 lg4 xl4 class="pr-2">
          <v-text-field
            v-model="interestedPerson.phone1"
            :label="$t('renting.interestedPerson.phone1')"
          ></v-text-field>
          <v-text-field
            v-model="interestedPerson.phone2"
            :label="$t('renting.interestedPerson.phone2')"
          ></v-text-field>
          <v-text-field
            v-model="interestedPerson.mobile"
            :label="$t('renting.interestedPerson.mobile')"
          ></v-text-field>
          <v-text-field
            v-model="interestedPerson.email"
            :label="$t('renting.interestedPerson.email')"
            :rules="[rules.email]"
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-card-title class="pa-0 mt-2 mb-2">{{ $t('renting.additionaInformation') }}</v-card-title>
      <v-layout row wrap class="pa-2">
        <v-flex xs12 sm12 md8 lg8 xl8>
          <v-textarea
            :label="$t('renting.interestedPerson.comment')"
            v-model="interestedPerson.comment"
          ></v-textarea>
        </v-flex>
      </v-layout>
      <v-card-title class="pa-0 mt-2 mb-2">{{ $t('renting.status') }}</v-card-title>
      <v-layout row class="ml-1">
        <v-flex>
          <v-radio-group row v-model="selectedStatus" :rules="[rules.required]">
            <v-radio
              v-for="(item, i) in pendingStatusSelection"
              :key="i"
              class="pr-5 mr-0 grow"
              :color="item === 'COMMITMENT' ? 'green' : 'orange'"
              :label="$t('renting.statuses.' + item)"
              :value="item"
            />
          </v-radio-group>
        </v-flex>
        <v-flex>
          <v-radio-group col v-model="selectedStatus" :rules="[rules.requiredWithoutMessage]">
            <v-radio
              v-for="(item) in declinedStatusSelection"
              :key="item"
              class="pr-5 mr-0"
              color="red"
              :label="$t('renting.statuses.' + item)"
              :value="item"
            />
          </v-radio-group>
        </v-flex>
      </v-layout>
      <v-footer inset app height="auto">
        <v-btn color="success" @click="updateInterestedPerson">
          <span>{{ $t('common.buttons.save') }}</span>
          <v-icon>send</v-icon>
        </v-btn>
        <v-btn color="error" class="ml-2" @click="openDeleteDialog">
          <span>{{ $t('common.buttons.delete') }}</span>
          <v-icon>delete</v-icon>
        </v-btn>
      </v-footer>
    </v-form>
    <SpinnerOverlay :loading="loading" />
  </v-container>
</template>

<script>
import PropertyInfo from './components/PropertyInfo'
import DatePicker from '../../components/DatePicker'
import ConfirmationDialog from '@/components/ConfirmationDialog'
import SpinnerOverlay from '@/components/SpinnerOverlay'
import * as validationHelper from '@/helpers/validationHelper'
import { mapActions } from 'vuex'
import { RENTING_LIST } from '@/config/options/routes/rentingRoutes'

export default {
  name: 'InterestedPersonDetails',
  components: {
    PropertyInfo,
    DatePicker,
    ConfirmationDialog,
    SpinnerOverlay
  },
  data () {
    return {
      loading: true,
      mappedProperty: {},
      mappedPropertyObject: {},
      selectedStatus: '',
      interestedPerson: {},
      showDeleteDialog: false,
      pendingStatusSelection: ['PENDING', 'UNDER_CLARIFICATION', 'COMMITMENT'],
      declinedStatusSelection: ['CANCELLATION_BY_INTERESTED_PERSON', 'ALREADY_RENTED', 'NOT_ELIGIBLE'],
      rules: {
        required: (value) => (!!value && value !== undefined) || this.$t('common.errors.required'),
        requiredWithoutMessage: (value) => (!!value && value !== undefined),
        email: (value) => {
          let valid = validationHelper.validateEmail(value)
          return valid
        }
      },
      doesNotExist: false
    }
  },
  created () {
    this.loadData()
  },
  computed: {
    salutationItems () {
      return [
        { value: 'DEAR_MRS' },
        { value: 'DEAR_MR' },
        { value: 'DEAR_LADIES_AND_GENTLEMAN' },
        { value: 'DEAR_FAMILY' },
        { value: 'GOOD_DAY' }
      ]
    },
    showDetails () {
      return !this.loading && !this.doesNotExist
    },
    deleteData () {
      return {
        title: this.$t('renting.interestedPerson.messages.delete'),
        question: this.$t('renting.interestedPerson.messages.confirmDelete'),
        confirmButton: this.$t('common.buttons.delete'),
        action: 'deleteInterestedPerson'
      }
    }
  },
  methods: {
    ...mapActions('notifier', ['setSuccessSnackbar', 'setErrorSnackbar']),
    loadData () {
      let that = this
      this.$prestigeRenting.getInterestedPersonById(this.$route.params.interestedPersonId).then(response => {
        that.interestedPerson = response.data
        that.selectedStatus = that.interestedPerson.personStatus
        if (that.interestedPerson.propertyId) {
          that.getPropertyInfo(that.interestedPerson.propertyId, that.interestedPerson.objectId)
        } else {
          that.loading = false
        }
      }).catch(error => {
        that.setErrorSnackbar(error)
        that.loading = false
        that.doesNotExist = true
      })
    },
    getPropertyInfo (propertyId, objectId) {
      let that = this
      this.$abacusProperty.getPropertyById(propertyId).then(response => {
        if (response.data) {
          let interestedPersonProperty = response.data
          that.mappedProperty = that.mapPropertyInfo(interestedPersonProperty)
          if (interestedPersonProperty.objects && interestedPersonProperty.objects.length > 0) {
            let interestedPersonObject = interestedPersonProperty.objects.find(x => x.objectId.toString() === objectId.toString())
            that.mappedPropertyObject = that.mapPropertyObjectInfo(interestedPersonObject)
          }
          that.loading = false
        }
      }).catch(error => {
        that.setErrorSnackbar(error)
        that.loading = false
      })
    },
    mapPropertyInfo (property) {
      return {
        propertyId: parseInt(property.propertyId),
        propertyStreet: property.street,
        propertyZip: property.zip,
        propertyPlace: property.place,
        client: property.client,
        caretaker: property.caretaker,
        administrativeAreaId: property.administrativeAreaId,
        administrativeAreaText: property.administrativeAreaText
      }
    },
    mapPropertyObjectInfo (object) {
      return {
        houseId: object.houseId,
        houseStreet: object.houseStreet,
        objectId: object.objectId,
        objectText: object.objectText
      }
    },
    updateInterestedPerson () {
      let that = this
      if (this.$refs.form.validate()) {
        let requestBody = this.setRequestBody()
        this.$prestigeRenting.updateInterestedPerson(this.interestedPerson.id, requestBody).then(response => {
          if (response.status === 204) {
            that.setSuccessSnackbar(that.$t('renting.interestedPerson.interestedPersonUpdateMessage'))
            that.loadData()
          }
          return true
        }).catch(error => {
          that.setErrorSnackbar(error)
        })
      }
    },
    setRequestBody () {
      return {
        lastName: this.interestedPerson.lastName,
        firstName: this.interestedPerson.firstName,
        comment: this.interestedPerson.comment,
        propertyId: this.interestedPerson.propertyId,
        objectId: this.interestedPerson.objectId,
        clientId: this.interestedPerson.clientId,
        realEstateManagerId: this.interestedPerson.realEstateManagerId,
        salutatoryAddress: this.interestedPerson.salutatoryAddress,
        salutatoryName: this.interestedPerson.salutatoryName,
        addressLine1: this.interestedPerson.addressLine1,
        addressLine2: this.interestedPerson.addressLine2,
        addressLine3: this.interestedPerson.addressLine3,
        zip: this.interestedPerson.zip,
        place: this.interestedPerson.place,
        phone1: this.interestedPerson.phone1,
        phone2: this.interestedPerson.phone2,
        mobile: this.interestedPerson.mobile,
        fax: this.interestedPerson.fax,
        email: this.interestedPerson.email,
        dateOfBirth: this.interestedPerson.dateOfBirth,
        personStatus: this.selectedStatus
      }
    },
    deleteInterestedPerson () {
      let that = this
      this.$prestigeRenting.deleteInterestedPerson(this.$route.params.interestedPersonId).then(function (response) {
        if (response.status === 204) {
          that.setSuccessSnackbar(that.$t('common.messages.successfullyDeleted'))
          if (that.$route.meta.abacus) {
            window.close()
          } else {
            that.$router.push({ name: RENTING_LIST })
          }
        }
        return true
      }).catch(function (error) {
        that.setErrorSnackbar(error)
      })
      that.closeDeleteDialog()
    },
    openDeleteDialog () {
      this.showDeleteDialog = true
    },
    closeDeleteDialog () {
      this.showDeleteDialog = false
    }
  }
}

</script>
