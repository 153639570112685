<template>
  <div class="d-flex">
    <div>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon @click.prevent="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
            <v-icon :color="editor.isActive('bold') ? '#ffffff' : ''">mdi-format-bold</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('tipTap.bold') }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon @click.prevent="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
            <v-icon :color="editor.isActive('italic') ? '#ffffff' : ''">mdi-format-italic</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('tipTap.italic') }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon @click.prevent="editor.chain().focus().toggleUnderline().run()" :class="{ 'is-active': editor.isActive('underline') }">
            <v-icon :color="editor.isActive('underline') ? '#ffffff' : ''">mdi-format-underline</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('tipTap.underline') }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" class="strike" icon @click.prevent="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
            <v-icon :color="editor.isActive('strike') ? '#ffffff' : ''">mdi-format-strikethrough-variant</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('tipTap.strike') }}</span>
      </v-tooltip>
    </div>
    <v-divider class="mx-2" vertical/>
    <div>
       <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon @click.prevent="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }">
            <v-icon :color="editor.isActive('bulletList') ? '#ffffff' : ''">mdi-format-list-bulleted</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('tipTap.bulletList') }}</span>
       </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon @click.prevent="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }">
            <v-icon :color="editor.isActive('orderedList') ? '#ffffff' : ''">mdi-format-list-numbered</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('tipTap.numberedList') }}</span>
      </v-tooltip>
    </div>
    <v-divider class="mx-2" vertical/>
    <div>
      <v-tooltip top>
         <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon @click.prevent="editor.chain().focus().undo().run()" :disabled="!editor.can().undo()">
            <v-icon>mdi-undo-variant</v-icon>
          </v-btn>
         </template>
         <span>{{ $t('tipTap.undo') }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon @click.prevent="editor.chain().focus().redo().run()" :disabled="!editor.can().redo()">
            <v-icon>mdi-redo-variant</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('tipTap.redo') }}</span>
      </v-tooltip>
    </div>
    <v-divider class="mx-2" vertical/>
    <div>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon @click.prevent="setLink" :class="{ 'is-active': editor.isActive('link') }">
            <v-icon :color="editor.isActive('link') ? '#ffffff' : ''">mdi-link-plus</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('tipTap.link') }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon @click.prevent="editor.chain().focus().unsetLink().run()" :disabled="!editor.isActive('link')">
            <v-icon>mdi-link-off</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('tipTap.linkOff') }}</span>
      </v-tooltip>
    </div>
    <v-divider class="mx-2" vertical/>
    <div>
      <v-btn
        x-small
        min-width="25px"
        color="#76226c"
        class="mr-2"
        dark
        @click="editor.chain().focus().setColor('#76226c').run()" :class="{ 'is-active': editor.isActive('textStyle', { color: '#76226c' }) }"
      />
      <v-btn
        x-small
        min-width="25px"
        color="#c82613"
        class="mr-2"
        dark
        @click="editor.chain().focus().setColor('#c82613').run()" :class="{ 'is-active': editor.isActive('textStyle', { color: '#c82613' }) }"
      />
      <v-btn
        x-small
        min-width="25px"
        color="#0c882a"
        class="mr-2"
        dark
        @click="editor.chain().focus().setColor('#0c882a ').run()" :class="{ 'is-active': editor.isActive('textStyle', { color: '#0c882a ' }) }"
      />
      <v-btn
        x-small
        min-width="25px"
        color="#0c64c0"
        dark
        @click="editor.chain().focus().setColor('#0c64c0').run()" :class="{ 'is-active': editor.isActive('textStyle', { color: '#0c64c0' }) }"
      />
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon @click="editor.chain().focus().unsetColor().run()">
            <v-icon>mdi-water-off</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('tipTap.clearColor') }}</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    editor: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    setLink () {
      const previousUrl = this.editor.getAttributes('link').href
      const url = window.prompt('URL', previousUrl)

      // cancelled
      if (url === null) {
        return
      }

      // empty
      if (url === '') {
        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .unsetLink()
          .run()

        return
      }

      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run()
    }
  }
}
</script>

<style scoped lang="postcss">
.is-active {
  background-color: rgba(0,0,0,.54);
}
/deep/ .theme--light.v-btn.v-btn--icon:hover {
  background-color: rgba(0,0,0,.54);
  color: #ffffff;
}

</style>
