import gql from 'graphql-tag'

const ABACUS_PROPERTY = gql`
  query abacusProperty ($propertyId: Int) {
    abacusProperty(propertyId: $propertyId) {
      propertyId,
      ordersComment
      ordersDisabled
    }
  }
`
const ABACUS_ADDRESS_PRIORITY_CRAFTSMEN = gql`
  query abacusPropertyPriorityCraftsmen ($propertyId: Int) {
    abacusPropertyPriorityCraftsmen(
      propertyId: $propertyId
    ) {
      propertyId
      address {
        addressId
        firstName
        lastName
        line1
        zip
        place
        mobile
        phone1
        phone2
        email
        comment
        country
        dateOfBirth
        fax
        line2
        line3
        salutation
        salutatoryAddress
        salutatoryName
        title
      }
      description
    }
  }
`
export { ABACUS_PROPERTY, ABACUS_ADDRESS_PRIORITY_CRAFTSMEN }
