<template>
  <div>
    <v-text-field
      v-model="myStaticForm.formFields[0].formFieldDefaultValue"
      label="My Text Field"
      :disabled="formReadOnly"
      :required="true"
      :rules="[rules.required]"
      @change="updatedField"
    >
      <template #message="{ message }">
        <span v-if="message === 'required'">{{ requiredMessage }}</span>
      </template>
    </v-text-field>
    <v-checkbox
      class="header-checkbox mt-0"
      primary
      v-model="myStaticForm.formFields[1].formFieldDefaultValue"
      label="My Checkbox"
      :disabled="formReadOnly"
      :required="true"
      :rules="[rules.checkboxRequired]"
      @change="updatedField"
    >
      <template #message="{ message }">
        <span v-if="message === 'required'">{{ requiredMessage }}</span>
      </template>
    </v-checkbox>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'MyStaticForm',
  props: {
    processVariables: {
      type: Array,
      default: () => {}
    },
    formReadOnly: {
      type: Boolean,
      default: false
    },
    validateClicked: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      myStaticForm: {
        formFields: [
          {
            formFieldId: 'Process_N47_01_Static_String',
            formFieldType: 'string',
            formFieldDefaultValue: ''
          },
          {
            formFieldId: 'Process_N47_01_Static_Boolean',
            formFieldType: 'boolean',
            formFieldDefaultValue: null
          }
        ]
      },
      valid: false,
      rules: {
        required: (value) => !!value || this.$t('common.errors.required'),
        checkboxRequired: (value) => (value !== undefined && value !== '' && value !== null && value) || this.requiredMessage
      }
    }
  },
  watch: {
    'processVariables': {
      handler: 'initProcessVariables',
      immediate: true
    }
  },
  computed: {
    requiredMessage () {
      return this.$t('common.errors.required')
    }
  },
  methods: {
    updatedField () {
      this.$emit('updateFormValues', this.myStaticForm)
    },
    initProcessVariables () {
      let that = this
      if (that.myStaticForm && that.processVariables) {
        that.myStaticForm.formFields.forEach((item, index) => {
          let variable = that.processVariables.filter(variable => variable.variableName === item.formFieldId)
          if (variable.length > 0) {
            Vue.set(that.myStaticForm.formFields, index, Object.assign(item, {formFieldDefaultValue: variable[0].variableValue}))
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="postcss">
</style>
