import gql from 'graphql-tag'

const TICKETING_TICKET = gql`
  query ticketingTicket ($ticketId: ID!) {
    ticketingTicket(ticketId: $ticketId) {
      ticketId
      state
      processDefinitionId
      processDefinitionKey
      processDefinitionName
      subject
      startedOn
      finishedOn
      startUser {
        userId
      }
      openTasks {
        taskId
        parentTaskId
        priority
        name
        startedOn
        dueDate
        assignee {
          userId
        }
      }
      completedTasks {
        taskId
        parentTaskId
        name
        startedOn
        finishedOn
        assignee {
          userId
        }
      }
    }
  }
`

const TICKETING_TICKET_OPEN_TASKS = gql`
  query ticketingTicket ($ticketId: ID!) {
    ticketingTicket(ticketId: $ticketId) {
      openTasks {
        taskId
        name
        taskDefinitionKey
      }
    }
  }
`

const TICKETING_TICKET_EXECUTION = gql`
  query ticketingTicket ($ticketId: ID!, $executionId: ID!) {
    executionVariables: ticketingTicket(ticketId: $ticketId) {
      ticketId
      variables(executionId: $executionId) {
      __typename
        ... on TicketingStringVariable {
          name
          stringValue: value
        }
      }
    }
  }
`

export { TICKETING_TICKET, TICKETING_TICKET_EXECUTION, TICKETING_TICKET_OPEN_TASKS }
