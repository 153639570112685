<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="4" lg="1" xl="1">
        <SalutationList
          v-model="mutableForm.salutation"
          required
          :read-only="readOnly"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3" xl="3">
        <v-text-field
          dense
          :label="$t('renting.publicForm.fields.personInfo.firstName')"
          v-model="mutableForm.firstName"
          required
          :rules="[rules.required]"
          :readonly="readOnly"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3" xl="3">
        <v-text-field
          dense
          :label="$t('renting.publicForm.fields.personInfo.familyName')"
          v-model="mutableForm.lastName"
          class="cy-rental-form-resident-lastname"
          required
          :rules="[rules.required]"
          :readonly="readOnly"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="2" xl="2">
        <DateField
          :label="$t('renting.publicForm.fields.personInfo.dateOfBirth')"
          required
          v-model="mutableForm.dateOfBirth"
          :readonly="readOnly"
        />
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="3" xl="3">
        <v-text-field
          dense
          :label="$t('renting.publicForm.fields.personInfo.hometown')"
          v-model="mutableForm.hometown"
          required
          :rules="[rules.required]"
          :readonly="readOnly"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="6" lg="3" xl="3">
        <v-text-field
          dense
          :label="$t('renting.publicForm.fields.personInfo.phoneInfo')"
          v-model="mutableForm.mobile"
          :required="phonesEmpty"
          :rules="phonesEmpty ? [rules.phoneRequired] : []"
          :readonly="readOnly"
          @input="$emit('validate')"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="3" xl="3">
        <v-text-field
          dense
          :label="$t('renting.publicForm.fields.personInfo.private')"
          v-model="mutableForm.phone1"
          :required="phonesEmpty"
          :rules="phonesEmpty ? [rules.phoneRequired] : []"
          :readonly="readOnly"
          @input="$emit('validate')"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="3" xl="3">
        <v-text-field
          dense
          :label="$t('renting.publicForm.fields.personInfo.business')"
          v-model="mutableForm.phone2"
          :required="phonesEmpty"
          :rules="phonesEmpty ? [rules.phoneRequired] : []"
          :readonly="readOnly"
          @input="$emit('validate')"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="3" xl="3">
        <v-text-field
          dense
          :label="$t('renting.publicForm.fields.personInfo.email')"
          v-model="mutableForm.email"
          required
          :rules="[rules.required, rules.email]"
          :readonly="readOnly"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row v-if="residential">
      <v-col cols="12" sm="12">
        <div class="d-md-flex">
          <v-card-subtitle class="pa-0 mr-3 align-md-baseline">{{ $t('renting.publicForm.fields.personInfo.foreignerInfo.titleQuestion') }}</v-card-subtitle>
          <v-radio-group class="mt-0 pt-md-0" row v-model="mutableForm.foreigner" :rules="[rules.radioRequired]" @change="resetResidenceValues" :readonly="readOnly">
            <v-radio :label="$t('common.options.yes')" :value="false"></v-radio>
            <v-radio :label="$t('common.options.no')" :value="true"></v-radio>
          </v-radio-group>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="mutableForm.foreigner">
      <v-col cols="12" sm="6" md="4">
        <v-autocomplete
          :label="$t('renting.publicForm.fields.personInfo.foreignerInfo.country')"
          :items="countryItems"
          v-model="mutableForm.country"
          dense
          required
          item-text="label"
          item-value="value"
          :rules="[rules.required]"
          :readonly="readOnly"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-select
          :label="$t('renting.publicForm.fields.personInfo.foreignerInfo.residencePermitType')"
          :items="foreignerResidencePermitTypeItems"
          v-model="mutableForm.foreignerResidencePermitType"
          dense
          required
          :rules="[rules.required]"
          :readonly="readOnly"
        >
          <template v-slot:item="{ item }">
            {{ $t('renting.publicForm.fields.personInfo.foreignerInfo.residencePermitType' + item) }}
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <template v-for="(item, index) in attachments">
          <v-list-item :key="index">
            <v-list-item-content>
              <v-list-item-title>{{ item.fileName }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn v-if="readOnly" icon ripple @click.stop="downloadAttachment(item.fileName)" :title="$t('common.buttons.download')">
                <v-icon color="blue lighten-1">file_download</v-icon>
              </v-btn>
              <v-btn v-if="!readOnly" small icon ripple @click.stop="deleteAttachment(index)" :title="$t('common.buttons.delete')">
                <v-icon color="red lighten-1">delete</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </template>
        <v-card-subtitle class="px-0 pt-0 pb-1">{{ $t('renting.publicForm.fields.personInfo.foreignerInfo.uploadLabel') }}</v-card-subtitle>
        <vue-clip v-if="!readOnly" :options="attachFileOptions" :on-complete="residentAttachmentUploadComplete">
          <template slot="clip-uploader-body" slot-scope="props">
            <div
              v-for="(file, index) in props.files"
              class="uploader-file"
              :key="index"
            >
              <div v-if="file.status !== 'success'">
                <div class="file-details">
                  <v-icon v-if="file.status === 'error'" color="red">error</v-icon>
                  {{ file.name }}
                  <span v-if="file.status === 'error'">{{ $t('common.messages.failed') }}</span>
                  <span v-if="file.errorMessage">({{ file.errorMessage }})</span>
                </div>
                <div class="file-progress" v-if="file.status !== 'error'">
                  <span class="progress-indicator" v-bind:style="{width: file.progress - (file.xhrResponse.response?0:5) + '%'}"></span>
                </div>
              </div>
            </div>
          </template>
          <template slot="clip-uploader-action" slot-scope="params" v-if="!isDemoToken">
            <div ref="fileField" class="uploader-action" v-bind:class="{'is-dragging': params.dragging }">
              <div class="dz-message">
                <v-icon>cloud_upload</v-icon>
                <div>{{ $t('tasks.attachments.clickOrDragText') }}</div>
              </div>
            </div>
          </template>
        </vue-clip>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="d-md-flex">
        <v-card-title class="mr-3 pa-0 align-md-baseline">{{ $t('renting.publicForm.fields.personInfo.employment.title') }}</v-card-title>
        <v-radio-group dense class="mt-0 w-full" row v-model="mutableForm.employmentStatus" :rules="[rules.radioRequired]" :readonly="readOnly">
          <v-radio v-for="option in employmentStatuses.options" :key="option" :label="$t('renting.publicForm.fields.personInfo.employment.status_' + option)" :value="option"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row dense v-if="additionalEmploymentIncomeQuestions">
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          dense
          :label="$t('renting.publicForm.fields.personInfo.employment.job')"
          v-model="mutableForm.profession"
          :readonly="readOnly"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          dense
          :label="$t('renting.publicForm.fields.personInfo.employment.income')"
          v-model="mutableForm.income"
          required
          :rules="[rules.required]"
          :readonly="readOnly"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense v-else-if="additionalEmploymentQuestions">
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          dense
          :label="$t('renting.publicForm.fields.personInfo.employment.employer')"
          v-model="mutableForm.employer"
          required
          :rules="[rules.required]"
          :readonly="readOnly"
          @input="$emit('validate')"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          dense
          :label="$t('renting.publicForm.fields.personInfo.employment.job')"
          v-model="mutableForm.profession"
          :readonly="readOnly"
          required
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <DatePicker
          dense
          ref="employmentDate"
          :label="$t('renting.publicForm.fields.personInfo.employment.sinceWhen')"
          v-model="mutableForm.employmentDate"
          :default-value="mutableForm.employmentDate ? true : false"
          :default-value-date="mutableForm.employmentDate || null"
          required
          :rules="[rules.required]"
          :readonly="readOnly"
        />
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          dense
          :label="$t('renting.publicForm.fields.personInfo.employment.income')"
          v-model="mutableForm.income"
          required
          :rules="[rules.required]"
          :readonly="readOnly"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense v-if="residential && additionalEmploymentQuestions" class="mb-1">
      <v-col cols="12">
        <v-card-subtitle class="pa-0">{{ $t('renting.publicForm.fields.personInfo.employment.employerContactInfo') }}</v-card-subtitle>
      </v-col>
    </v-row>
    <v-row dense v-if="residential && additionalEmploymentQuestions">
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          dense
          :label="$t('renting.publicForm.fields.personInfo.employment.employerName')"
          v-model="mutableForm.employerName"
          :required="additionalEmploymentQuestions"
          :rules="additionalEmploymentQuestions ? [rules.required] : []"
          :readonly="readOnly"
          @input="$emit('validate')"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          dense
          :label="$t('renting.publicForm.fields.personInfo.employment.employerFunction')"
          v-model="mutableForm.employerFunction"
          :required="additionalEmploymentQuestions"
          :rules="additionalEmploymentQuestions ? [rules.required] : []"
          :readonly="readOnly"
          @input="$emit('validate')"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          dense
          :label="$t('renting.publicForm.fields.personInfo.employment.employerPhone')"
          v-model="mutableForm.employerPhone"
          :required="additionalEmploymentQuestions"
          :rules="additionalEmploymentQuestions ? [rules.required] : []"
          :readonly="readOnly"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          dense
          :label="$t('renting.publicForm.fields.personInfo.employment.employerEmail')"
          v-model="mutableForm.employerEmail"
          :required="additionalEmploymentQuestions"
          :rules="additionalEmploymentQuestions ? [rules.required, rules.email] : []"
          :readonly="readOnly"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-md-flex">
        <v-card-title class="mr-3 pa-0 align-md-baseline">
          <span v-if="residential">{{ $t('renting.publicForm.fields.personInfo.apartment.title') }}</span>
          <span v-else>{{ $t('renting.publicForm.fields.personInfo.apartment.address') }}</span>
        </v-card-title>
        <v-radio-group v-if="residential" dense class="mt-0 w-full" row v-model="mutableForm.currentApartmentStatus" :rules="[rules.radioRequired]" :readonly="readOnly">
          <v-radio :label="$t('renting.publicForm.fields.personInfo.apartment.status_' + currentApartmentStatuses.sameAsFirstResident)" :value="currentApartmentStatuses.sameAsFirstResident" v-if="!firstResident"></v-radio>
          <v-radio v-for="option in currentApartmentStatuses.options" :key="option" :label="$t('renting.publicForm.fields.personInfo.apartment.status_' + option)" :value="option"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row dense v-if="!sameApartment">
      <v-col cols="12" sm="9" md="4">
        <v-text-field
          dense
          :label="$t('renting.publicForm.fields.personInfo.apartment.street')"
          v-model="mutableForm.currentApartmentStreet"
          required
          :rules="[rules.required]"
          :readonly="readOnly"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="3" md="2">
        <v-text-field
          dense
          :label="$t('renting.publicForm.fields.personInfo.apartment.streetNumber')"
          v-model="mutableForm.currentApartmentStreetNumber"
          required
          :rules="[rules.required]"
          :readonly="readOnly"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="3" md="2">
        <v-text-field
          dense
          :label="$t('renting.publicForm.fields.personInfo.apartment.zip')"
          v-model="mutableForm.currentApartmentZip"
          required
          :rules="[rules.required]"
          :readonly="readOnly"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="9" md="4">
        <v-text-field
          dense
          :label="$t('renting.publicForm.fields.personInfo.apartment.place')"
          v-model="mutableForm.currentApartmentPlace"
          required
          :rules="[rules.required]"
          :readonly="readOnly"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row dense v-if="additionalApartmentQuestions">
      <v-col cols="12" sm="4">
        <v-text-field
          dense
          :label="$t('renting.publicForm.fields.personInfo.apartment.roomRent')"
          v-model="mutableForm.currentApartmentRent"
          required
          :rules="[rules.required]"
          :readonly="readOnly"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field
          dense
          :label="$t('renting.publicForm.fields.personInfo.apartment.roomNumber')"
          v-model="mutableForm.currentApartmentNumberOfRooms"
          :readonly="readOnly"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4">
        <DatePicker
          dense
          ref="datePicker"
          :label="$t('renting.publicForm.fields.personInfo.apartment.roomRentSince')"
          v-model="mutableForm.currentApartmentStartRentDate"
          :default-value="true"
          :default-value-date="mutableForm.currentApartmentStartRentDate"
          :readonly="readOnly"
        />
      </v-col>
    </v-row>
    <v-row dense v-if="additionalApartmentQuestions" class="mb-1">
      <v-col cols="12">
        <v-card-subtitle class="pa-0">{{ $t('renting.publicForm.fields.personInfo.apartment.landlordContactInfo') }}</v-card-subtitle>
      </v-col>
    </v-row>
    <v-row dense v-if="additionalApartmentQuestions">
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          dense
          :label="$t('renting.publicForm.fields.personInfo.landlord.name')"
          v-model="mutableForm.landlordName"
          required
          :rules="[rules.required]"
          :readonly="readOnly"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          dense
          :label="$t('renting.publicForm.fields.personInfo.landlord.function')"
          v-model="mutableForm.landlordFunction"
          required
          :rules="[rules.required]"
          :readonly="readOnly"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          dense
          :label="$t('renting.publicForm.fields.personInfo.landlord.phone')"
          v-model="mutableForm.landlordPhone"
          required
          :rules="[rules.required]"
          :readonly="readOnly"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          dense
          :label="$t('renting.publicForm.fields.personInfo.landlord.email')"
          v-model="mutableForm.landlordEmail"
          required
          :rules="[rules.required, rules.email]"
          :readonly="readOnly"
        ></v-text-field>
      </v-col>
    </v-row>

    <template v-if="residential && !isDemoToken">
      <v-row>
        <v-col cols="12">
          <v-card-title class="mr-3 pa-0">{{ $t('renting.publicForm.fields.personInfo.creditRating.title') }}</v-card-title>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" md="6">
          <p v-html="$t('renting.publicForm.fields.personInfo.creditRating.assessmentInfo')"/>
        </v-col>
        <v-col cols="12" md="6">
          <template v-if="validateCertificateFile">
            {{ validateCertificateFile.fileName }}
            <v-btn small icon ripple @click.stop="deleteValidateCertificateFile" :title="$t('common.buttons.delete')">
              <v-icon color="red lighten-1">delete</v-icon>
            </v-btn>
          </template>
          <vue-clip v-else-if="!validateCertificateFile && !readOnly" :options="validateCertificateOptions" :on-complete="validateCertificateUploadComplete">
            <template slot="clip-uploader-body" slot-scope="props">
              <div
                v-for="(file, index) in props.files"
                class="uploader-file"
                :key="index"
              >
                <div v-if="file.status !== 'success'">
                  <div class="file-details">
                    <v-icon v-if="file.status === 'error'" color="red">error</v-icon>
                    {{ file.name }}
                    <span v-if="file.status === 'error'">{{ $t('common.messages.failed') }}</span>
                    <span v-if="file.errorMessage">({{ file.errorMessage }})</span>
                  </div>
                  <div class="file-progress" v-if="file.status !== 'error'">
                    <span class="progress-indicator" v-bind:style="{width: file.progress - (file.xhrResponse.response?0:5) + '%'}"></span>
                  </div>
                </div>
              </div>
            </template>
            <template slot="clip-uploader-action" slot-scope="params">
              <div ref="fileField" class="uploader-action" v-bind:class="{'is-dragging': params.dragging }">
                <div class="dz-message">
                  <v-icon>cloud_upload</v-icon>
                  <div>{{ $t('tasks.attachments.clickOrDragText') }}</div>
                </div>
              </div>
            </template>
          </vue-clip>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-checkbox
            class="mt-0"
            :label="$t('renting.publicForm.fields.personInfo.creditRating.debtEnforcementPerMail')"
            v-model="mutableForm.debtEnforcementPerMail"
            :readonly="readOnly"
          ></v-checkbox>
        </v-col>
      </v-row>
    </template>

    <v-row>
      <v-col cols="12 d-md-flex">
        <v-card-subtitle class="pa-0 mr-3 align-md-baseline">{{ $t('renting.publicForm.fields.personInfo.creditRating.debtEnforcement') }}</v-card-subtitle>
        <v-radio-group class="mt-0 pa-md-0" row v-model="mutableForm.receivedDebtEnforcement" :rules="[rules.radioRequired]" :readonly="readOnly">
          <v-radio :label="$t('common.options.yes')" :value="true"></v-radio>
          <v-radio :label="$t('common.options.no')" :value="false"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="py-0">
        <v-textarea
          class="mt-0 pt-0"
          :label="$t('renting.publicForm.fields.personInfo.creditRating.comment')"
          v-model="mutableForm.creditRatingComment"
          :readonly="readOnly"
          :rows="3"
        ></v-textarea>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SalutationList from '@/components/SalutationList'
import DatePicker from '../../../../../src/components/DatePicker'
import { useRentingForm } from '@/use/useRentingForm'
import * as validationHelper from '@/helpers/validationHelper'
import { mapActions } from 'vuex'

import { employmentStatuses } from '@/config/options/employmentStatuses'
import { currentApartmentStatuses } from '@/config/options/currentApartmentStatuses'
import countries from 'i18n-iso-countries'
import DateField from '@/components/DateField'
countries.registerLocale(require('i18n-iso-countries/langs/en.json'))
countries.registerLocale(require('i18n-iso-countries/langs/de.json'))

const { downloadResidentAttachment } = useRentingForm()

export default {
  name: 'PersonForm',
  props: {
    form: {
      type: Object,
      default: () => {}
    },
    firstResident: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    residential: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      employmentStatuses: employmentStatuses,
      currentApartmentStatuses: currentApartmentStatuses,
      foreignerResidencePermitTypeItems: ['L', 'B', 'C', 'G', 'N', 'F', 'S', 'Ci'],
      validateCertificateFile: null,
      attachments: this.setDefaultAttachments(),
      mutableForm: this.form,
      rules: {
        required: (value) => (!!value && value !== undefined) || this.requiredMessage,
        radioRequired: (value) => (value !== undefined && value !== '' && value !== null) || this.requiredMessage,
        email: (value) => { let valid = validationHelper.validateEmail(value); return valid },
        phoneRequired: () => !this.phonesEmpty || this.requiredMessage
      }
    }
  },
  components: {
    DateField,
    SalutationList,
    DatePicker
  },
  computed: {
    attachFileOptions () {
      return {
        url: '/api/renting/form/v1/files',
        paramName: 'file',
        maxFilesize: {
          limit: 5,
          message: this.$t('renting.publicForm.fields.personInfo.attachment.validateAttachmentTooBig')
        },
        acceptedFiles: {
          extensions: ['application/pdf', 'image/jpeg', 'image/png', 'image/jpg'],
          message: this.$t('renting.publicForm.fields.personInfo.attachments.attachmentAllowedFileTypes')
        }
      }
    },
    isDemoToken () {
      return this.$route.params.token === 'DEMO'
    },
    validateCertificateOptions () {
      return {
        url: '/api/renting/form/v1/files',
        paramName: 'file',
        maxFilesize: {
          limit: 5,
          message: this.$t('renting.publicForm.fields.personInfo.creditRating.validateCertificateTooBig')
        },
        acceptedFiles: {
          extensions: ['application/pdf'],
          message: this.$t('renting.publicForm.fields.personInfo.creditRating.validateCertificateAllowedFileTypes')
        }
      }
    },
    requiredMessage () {
      return this.$t('common.errors.required')
    },
    phonesEmpty () {
      return this.mutableForm.mobile === '' && this.mutableForm.phone1 === '' && this.mutableForm.phone2 === ''
    },
    requiredValidateCertificateFileEmpty () {
      return this.mutableForm.validateCertificateFileId === null
    },
    additionalEmploymentQuestions () {
      return this.mutableForm.employmentStatus === this.employmentStatuses.employed
    },
    additionalEmploymentIncomeQuestions () {
      return this.residential && this.mutableForm.employmentStatus === this.employmentStatuses.selfEmployed || this.mutableForm.employmentStatus === this.employmentStatuses.pensioner
    },
    sameApartment () {
      return this.mutableForm.currentApartmentStatus === this.currentApartmentStatuses.sameAsFirstResident
    },
    additionalApartmentQuestions () {
      return this.residential && this.mutableForm.currentApartmentStatus === this.currentApartmentStatuses.rentalApartment
    },
    countryItems () {
      const countryObject = countries.getNames(this.$i18n.locale, {select: 'official'})
      return Object
        .keys(countryObject)
        .map((key) => {
          return {
            value: key,
            label: countryObject[key]
          }
        })
        .sort((a, b) => a.label.localeCompare(b.label))
    }
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    setDefaultAttachments () {
      if (this.mutableForm?.savedFiles) {
        return this.form.savedFiles.map(fileName => {
          return { fileName }
        })
      }
      return []
    },
    resetResidenceValues () {
      this.mutableForm.country = this.mutableForm.foreigner ? null : 'CH'
      this.mutableForm.foreignerResidencePermitType = null
    },
    deleteValidateCertificateFile () {
      this.validateCertificateFile = null
      this.mutableForm.validateCertificateFileId = null
      this.mutableForm.validateCertificate = false
    },
    deleteAttachment (index) {
      this.mutableForm.fileIds.splice(index, 1)
      this.attachments.splice(index, 1)
    },
    downloadAttachment (fileName) {
      downloadResidentAttachment(fileName)
    },
    validateCertificateUploadComplete (file, status, xhr) {
      let that = this
      if (status === 'success') {
        let variableFile = JSON.parse(xhr.response)
        console.log('upload success', variableFile)
        that.validateCertificateFile = variableFile
        that.mutableForm.validateCertificateFileId = variableFile.id
        that.mutableForm.validateCertificate = true
      } else {
        that.mutableForm.validateCertificate = false
        console.log('upload failed', status)
      }
    },
    residentAttachmentUploadComplete (file, status, xhr) {
      let that = this
      if (status === 'success') {
        let variableFile = JSON.parse(xhr.response)
        that.attachments.push(variableFile)
        console.log('upload success', variableFile)
        if (that.mutableForm.fileIds === undefined) {
          that.mutableForm.fileIds = []
        }
        that.mutableForm.fileIds.push(variableFile.id)
      } else {
        console.log('upload failed', status)
      }
    }
  }
}
</script>

<style scoped lang="postcss">
.variable-file-upload {
  width: 100%;
}
.uploader-action:hover {
  cursor: pointer;
}
.uploader-action.is-dragging {
  background: green;
}
.uploader-action.is-disabled {
  pointer-events: none;
  opacity: 0.5;
}
.dz-message {
  border: 2px dashed #bbb;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  color: #bbb;
}
.file-progress {
  height: 7px;
  background-color: lightgray;
}
.progress-indicator {
  height: 7px;
  background-color: green;
  display: block;
  border: 1px solid lightgray;
}
/deep/ .v-list-item__title {
  color: rgba(0, 0, 0, .6);
  font-weight: 700;
  font-size: .875rem;
  line-height: 1.375rem;
  letter-spacing: .0071428571em;
}
/deep/ .required-border {
  border: 2px dashed #ff5252;
}
.required {
  color: #ff5252 !important;
}
.font-12 {
  font-size: 12px;
}
/deep/ .v-input--is-readonly {
  cursor: not-allowed;
  pointer-events: none;
}
/deep/ .row+.row {
  margin-top: 0;
}
</style>
