import i18n from '@/i18n/i18n.js'
import moment from 'moment'

export function validateEmail (value) {
  if (value && value.length > 0) {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return value === '' || pattern.test(value) || i18n.t('common.errors.invalidEmail')
  } else {
    return true
  }
}

export function validateDate (value) {
  if (value && value.length > 0) {
    const pattern = /^\s*(3[01]|[12][0-9]|0?\d{1}[1-9])\.(1[012]|0?\d{1}[1-9])\.((?:19|20)\d{2})\s*$/
    let valid = false
    if (pattern.test(value)) {
      let newDate = moment(value, 'DD.MM.YYYY')
      valid = newDate._isValid
    }
    return valid || 'invalidDate'
  } else {
    return true
  }
}

export function scrollToFirstInvalidField () {
  let invalidFields = document.getElementsByClassName('v-messages__message')
  let firstInvalid = invalidFields && invalidFields[0]
  if (firstInvalid) {
    firstInvalid.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
}

export function checkIfValidUUID(str) {
  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  return regexExp.test(str);
}
