import gql from 'graphql-tag'

const TICKETING_TASK = gql`
  query ticketingTask ($taskId: ID!) {
    ticketingTask(taskId: $taskId) {
      taskId
      executionId
      formKey
      name
      startedOn
      dueDate
      description
      priority
      finishedOn
      parentTaskId
      assignee {
        userId
      }
      candidateGroups {
        id
      }
      ticket {
        ticketId
        state
        processDefinitionId
        processDefinitionKey
        processDefinitionName
        subject
        startedOn
        finishedOn
        startUser {
          userId
        }
      }
      checklistItems {
        taskId
        priority
        name
        startedOn
        finishedOn
        description
        assignee {
          userId
        }
        dueDate
        candidateGroups {
          id
          name
        }
      }
    }
  }
`

export { TICKETING_TASK }
