<template>
  <v-tooltip bottom v-if="icon">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on" :color="icon.color" :size="22">{{ icon.name }}</v-icon>
    </template>
    <span>{{ $t('renting.statuses.' + decision) }}</span>
  </v-tooltip>
</template>

<script>
const ALREADY_RENTED = 'ALREADY_RENTED'
const CANCELLATION_BY_INTERESTED_PERSON = 'CANCELLATION_BY_INTERESTED_PERSON'
const COMMITMENT = 'COMMITMENT'
const NOT_ELIGIBLE = 'NOT_ELIGIBLE'
const IN_PROGRESS = 'IN_PROGRESS'
const PRIORITY_1 = 'PRIORITY_1'
const PRIORITY_2 = 'PRIORITY_2'

export default {
  name: 'DecisionIcon',
  props: {
    decision: {
      type: String,
      default: ''
    }
  },
  computed: {
    icon () {
      switch (this.decision) {
        case COMMITMENT:
          return {
            color: 'green',
            name: 'mdi-checkbox-marked-circle'
          }
        case PRIORITY_1:
          return {
            color: 'blue',
            name: 'mdi-information'
          }
        case PRIORITY_2:
          return {
            color: 'blue',
            name: 'mdi-information'
          }
        case IN_PROGRESS:
          return {
            color: 'blue',
            name: 'mdi-help-circle'
          }
        case CANCELLATION_BY_INTERESTED_PERSON:
          return {
            color: 'blue',
            name: 'info'
          }
        case ALREADY_RENTED:
          return {
            color: 'orange',
            name: 'warning'
          }
        case NOT_ELIGIBLE:
          return {
            color: 'red',
            name: 'mdi-close-circle'
          }
        default:
          return null
      }
    }
  }
}
</script>

<style scoped lang="postcss">
</style>
