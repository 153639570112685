<template>
  <v-card>
    <v-container fluid grid-list-xs class="pa-0">
      <v-card-text class="pb-0 mb-4">
        <v-icon color="primary" @click="toggle" class="float-right cy-ticket-simple-variables-expand">{{ icon }}</v-icon>
        <v-card-subtitle class="pa-0 font-weight-bold subtitle-1">
          {{ $t('ticketingSystem.processes.variables.title') }}
        </v-card-subtitle>
      </v-card-text>
      <v-divider></v-divider>
      <div v-if="isOpen">
        <v-list>
          <v-list-item
            two-line
            v-for="(item, index) in processVariables"
            :key="item.variableName + '_' + index"
          >
            <v-list-item-content>
              <v-list-item-subtitle class="font-weight-bold pa-0 cy-ticket-simple-variable-name">
                {{ item.variableName }}
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="item.variableName.endsWith('_Text')">
                <div v-html="item.variableValue" class="truncate-two-lines cy-ticket-simple-variable-value"></div>
              </v-list-item-subtitle>
              <v-list-item-subtitle v-else class="ma-0">
                <div class="truncate-two-lines cy-ticket-simple-variable-value">{{ item.variableValue }}</div>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="hasMoreThan50Chars(item.variableValue)">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" @click.stop="openDialog(item)">
                    <v-icon>more</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('ticketingSystem.processes.variables.showMore') }}</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </div>
      <VariableContentDialog
        :title="title"
        :variable-value="variableValue"
        :content-type="title.endsWith('_Text') ? 'html' : 'text'"
        :show-dialog="showDialog"
        @closeDialog="closeDialog"
      />
    </v-container>
  </v-card>
</template>

<script>
import VariableContentDialog from './VariableContentDialog'

export default {
  name: 'ProcessSimpleVariables',
  components: {
    VariableContentDialog
  },
  props: {
    processVariables: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      isOpen: false,
      showDialog: false,
      title: '',
      variableValue: ''
    }
  },
  computed: {
    icon () {
      return this.isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
    }
  },
  methods: {
    toggle () {
      this.isOpen = !this.isOpen
    },
    hasMoreThan50Chars (item) {
      return item.length > 50
    },
    openDialog (item) {
      this.title = item.variableName
      this.variableValue = item.variableValue
      this.showDialog = true
    },
    closeDialog () {
      this.showDialog = false
    }
  }
}
</script>

<style scoped lang="postcss">
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px lightgray;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(99, 99, 99, .7);
  background-color: #fff;
}
.truncate-two-lines {
  max-width: 95%;
  white-space: initial;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}
</style>
