<template>
  <v-timeline-item
    class="mb-4"
    :color="color + ' lighten-2'"
    icon="mdi-comment"
    fill-dot
  >
    <CommentDialog
      v-if="shouldShowCommentDialog"
      :process-instance-id="processInstanceId"
      :comment="comment"
      :task-id="taskId"
      :is-update-comment="true"
      @closeDialog="onCloseDialog"
    />
    <v-card :color="color + ' lighten-4'">
      <v-card-title class="pa-3">
        <v-list-item dense class="pl-0 pr-0">
          <v-list-item-content>
            <v-list-item-title class="subtitle-1 text-wrap">
              <UserName :user-id="comment.userId" />
            </v-list-item-title>
            <v-list-item-subtitle>{{ formatTimeFunc(comment.createdOn) }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-icon v-if="isMessageToCurrentUser">
            <v-btn dark small color="brown" @click.stop="openCommentDialog">
              {{ $t('ticketingSystem.messages.comment.edit') }}
            </v-btn>
          </v-list-item-icon>
        </v-list-item>
      </v-card-title>
      <span v-if="comment" class="truncate-one-line">
        <v-card-text class="white text--primary pt-3" v-html="comment.message" />
      </span>
    </v-card>
  </v-timeline-item>
</template>

<script>
import CommentDialog from './CommentDialog'
import UserName from '@/components/UserName'
import { addAutocompleteClickListeners } from '@/features/ticketing/helpers/autocompleteHelper'
import { formatTime } from '@/helpers/formatterHelper'
import { AuthenticationContext } from 'vue-adal'
import Vue from 'vue'

export default {
  name: 'TicketingComment',
  components: {
    CommentDialog,
    UserName
  },
  data () {
    return {
      shouldShowCommentDialog: false
    }
  },
  props: {
    comment: {
      type: Object,
      default: () => {}
    },
    taskId: {
      type: String,
      default: ''
    },
    processInstanceId: {
      type: String,
      default: ''
    }
  },
  watch: {
    comment: {
      handler () {
        Vue.nextTick(() => {
          addAutocompleteClickListeners()
        })
      },
      immediate: true
    }
  },
  computed: {
    color () {
      return 'brown'
    },
    loggedInUser () {
      return AuthenticationContext && AuthenticationContext.user && AuthenticationContext.user.profile ? AuthenticationContext.user.profile : null
    },
    isMessageToCurrentUser () {
      return this.comment.userId === this.loggedInUser.employeeid
    }
  },
  methods: {
    formatTimeFunc: formatTime,
    openCommentDialog () {
      this.shouldShowCommentDialog = true
    },
    onCloseDialog (reload) {
      if (reload) {
        this.$emit('reloadTicketingHistory')
        this.$emit('loadProcessReferences')
      }
      this.shouldShowCommentDialog = false
    }
  }
}

</script>

<style scoped lang="postcss">
</style>
