<template>
  <v-container fluid grid-list-md>
    <v-alert v-if="!recipientLoading && !recipient" type="warning">
      {{ $t('dossier.recipients.recipientDetail.doesNotExist') }}
    </v-alert>
    <v-layout row wrap>
      <v-flex md3>
        <template v-if="recipientLoading">
          <v-card>
            <v-progress-linear indeterminate></v-progress-linear>
          </v-card>
        </template>
        <v-card v-if="!recipientLoading && recipient" height="100%">
          <v-card-title>{{ $t('common.address') }}</v-card-title>
          <div class="pl-4 pt-3 pb-4">
            {{ recipient.firstName }} {{ recipient.lastName }}<br />
            {{ recipient.addressLine1 }}<br v-if="recipient.addressLine1" />
            {{ recipient.addressLine2 }}<br v-if="recipient.addressLine2" />
            {{ recipient.addressLine3 }}<br v-if="recipient.addressLine3" />
            {{ recipient.zip }} {{ recipient.place }}
          </div>
        </v-card>
      </v-flex>
      <v-flex md3>
        <template v-if="recipientLoading">
          <v-card>
            <v-progress-linear indeterminate></v-progress-linear>
          </v-card>
        </template>
        <v-card v-if="!recipientLoading && recipient" height="100%">
          <div class="pa-4">
            <v-card-title class="pa-0 ma-0">{{ $t('dossier.corporations.printouts') }}</v-card-title>
            <ul class="ul-none mt-6" v-if="recipient.recipientPrintHistory">
              <li v-for="history in recipient.recipientPrintHistory" :key="history.recipientPrintHistoryId">
                <v-tooltip v-if="selectedPrintout === history.recipientPrintHistoryId && history.printedRecipientDocuments" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="green" v-bind="attrs" v-on="on" size="22" class="mb-1">mdi-checkbox-marked-circle</v-icon>
                  </template>
                  <span>{{ $t('dossier.recipients.recipientDetail.documentsPreselected') }}</span>
                </v-tooltip>
                <v-icon v-if="(!selectedPrintout || selectedPrintout !== history.recipientPrintHistoryId) && history.printedRecipientDocuments" color="gray" size="22" class="mb-1">mdi-checkbox-marked-circle</v-icon>
                <v-tooltip v-else-if="!history.printedRecipientDocuments" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" v-bind="attrs" v-on="on" size="22" class="mb-1">info</v-icon>
                  </template>
                  <span>{{ $t('dossier.recipients.recipientDetail.noDocuments') }}</span>
                </v-tooltip>
                <nobr class="print-history-item" @click="setPrintoutDocuments(history)">
                  <span>{{ printHistoryDateFormatted(history.createdOn) }}</span>
                </nobr>
                <v-btn v-if="history.pdf" icon class="ml-4" :title="$t('dossier.corporations.pdf')" @click.stop="downloadPrintPDF(history.recipientPrintHistoryId)">
                  <v-icon color="primary">file_download</v-icon>
                </v-btn>
              </li>
            </ul>
            <v-alert v-else type="info" class="mr-4 mt-5">
              {{ $t('dossier.corporations.noPrintHistory') }}
            </v-alert>
          </div>
        </v-card>
      </v-flex>
      <v-flex md6 v-if="!recipientLoading && recipient">
        <v-card>
          <v-container grid-list-xs>
            <v-card-title class="pa-0 mt-1">{{ $t('dossier.recipients.recipientDetail.selection') }}</v-card-title>
            <v-layout class="pl-1">
              <v-radio-group row v-model="selection" @change="changedRadioForm">
                <v-radio
                  v-for="(item, i) in documentSelection"
                  :key="i"
                  class="col-md-6 pa-1 mr-0"
                  :label="$t('dossier.recipients.recipientDetail.radioSelection.' + item)"
                  :value="item"
                />
              </v-radio-group>
            </v-layout>
            <v-layout row wrap class="pl-2">
              <v-checkbox
                :label="$t('dossier.corporations.print')"
                v-model="print"
              ></v-checkbox>
              <v-checkbox
                :label="$t('dossier.corporations.pdf')"
                v-model="createPdf"
                class="ml-7"
              ></v-checkbox>
            </v-layout>
            <v-layout row class="pl-2 pt-2 text-right">
              <v-flex>
                <v-btn color="primary" :loading="printDocumentsLoading" @click.stop="printDocuments" :disabled="disableBtn">{{ $t('dossier.recipients.recipientDetail.go') }}</v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex md12>
        <template v-if="recipientLoading">
          <v-card>
            <v-progress-linear indeterminate></v-progress-linear>
          </v-card>
        </template>
        <template v-if="!recipientLoading && recipient">
          <v-card class="mb-4 pb-5" v-for="corporation in recipient.corporations" :key="corporation.corporationId">
            <v-card-title>
              [<a @click.stop="goToCorporationDetails(corporation.corporationId)">{{ corporation.corporationId }}</a>]
              {{ corporation.name }}
            </v-card-title>
            <v-card-title class="subtitle-1 font-weight-bold">{{ $t('dossier.corporations.recipientDocuments') }}</v-card-title>
            <div class="ml-4">
              <DocumentDataTable
                :items="corporation.documentTypes"
                :copies="recipient.documentTypes"
                :loading="recipientLoading"
                :selected-documents="selectedDocuments"
                :selected-checkboxes="selectedCheckboxes"
                :selected-printout="selectedPrintout"
                @downloadDocumentPdf="downloadDocumentPdf"
                @setCustomSelection="setCustomSelection"
                @resetPrintout="resetPrintout"
              />
            </div>
            <div v-for="property in corporation.properties" :key="property.propertyId">
              <div>
                <v-card-title class="d-inline-block subtitle-1 font-weight-bold">{{ $t('dossier.corporations.propertyDocuments') }} [<a href="" @click="openMapProperty(property.propertyId)">{{ property.propertyId }}</a>] {{ property.street + ', ' + property.zip + ' ' + property.place }}</v-card-title>
                <p v-if="property.endOfMandate" class="cal-border d-inline-block subtitle-2 mb-0 pl-1 pr-2 red--text rounded-pill">
                  <v-icon color="red" size="20" class="pr-1">mdi-calendar-remove</v-icon>
                  {{ $t('dossier.corporations.endOfMandate') }}: {{ formatEndOfMandate(property.endOfMandate) }}
                </p>
              </div>
              <div class="ml-4">
                <DocumentDataTable
                  :items="property.documentTypes"
                  :copies="recipient.documentTypes"
                  :loading="recipientLoading"
                  :selected-documents="selectedDocuments"
                  :selected-checkboxes="selectedCheckboxes"
                  :selected-printout="selectedPrintout"
                  @downloadDocumentPdf="downloadDocumentPdf"
                  @setCustomSelection="setCustomSelection"
                  @resetPrintout="resetPrintout"
                />
              </div>
            </div>
          </v-card>
        </template>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import DocumentDataTable from './DocumentDataTable.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { formatDate, formatDateAndTime } from '@/helpers/formatterHelper'
import { MAP_PROPERTIES } from '@/config/options/routes/mapRoutes'
import { RECIPIENT_DETAIL, CORPORATION_DETAIL } from '@/config/options/routes/dossierRoutes'

export default {
  name: RECIPIENT_DETAIL,
  components: {
    DocumentDataTable
  },
  data () {
    return {
      recipientLoading: true,
      recipient: {},
      documentSelection: [ 'all', 'onlyNew', 'nothing', 'custom' ],
      selection: '',
      print: false,
      createPdf: false,
      printDocumentsLoading: false,
      selectedCheckboxes: [],
      selectedDocuments: [],
      selectedPrintout: null
    }
  },
  mounted () {
    this.loadData()
  },
  watch: {
    year () {
      this.loadData()
    }
  },
  computed: {
    ...mapState('printer', ['year']),
    disableBtn () {
      return !this.selectedDocuments.length > 0 || (!this.print && !this.createPdf)
    }
  },
  methods: {
    ...mapGetters('printer', ['getYear']),
    ...mapActions('notifier', ['setErrorSnackbar', 'setSuccessSnackbar']),
    formatDateFunc: formatDate,
    formatDateAndTimeFunc: formatDateAndTime,
    loadData () {
      this.resetSelections()
      let that = this
      this.$prestigeDossierRecipient.getRecipient(this.$route.params.recipientId, this.year).then(response => {
        that.recipientLoading = false
        that.recipient = response.data
        that.resetPrintout()
        that.resetDocAndCheckboxes()
      }).catch(error => {
        that.recipientLoading = false
        that.recipient = null
        that.setErrorSnackbar(error)
      })
    },
    resetSelections () {
      this.print = false
      this.createPdf = false
      this.selection = ''
    },
    printHistoryDateFormatted (printHistory) {
      return this.formatDateAndTimeFunc(printHistory)
    },
    downloadDocumentPdf (documentId) {
      let that = this
      const config = { responseType: 'blob' }
      this.$prestigeDossierDocument.getDocumentFile(documentId, config).then(response => {
        if (response.data) {
          const headers = response.headers
          const file = that.populateFile(headers, response.data)
          that.downloadFile(file)
          return true
        }
      }).catch(function (error) {
        that.setErrorSnackbar(error)
      })
    },
    downloadPrintPDF (recipientPrintHistoryId) {
      let that = this
      const config = { responseType: 'blob' }
      this.$prestigeDossierRecipient.getFileForPrintHistory(recipientPrintHistoryId, config).then(response => {
        if (response.data) {
          const headers = response.headers
          const file = that.populateFile(headers, response.data)
          that.downloadFile(file)
          return true
        }
      }).catch(function (error) {
        that.setErrorSnackbar(error)
      })
    },
    populateFile (headers, data) {
      const fileName = headers['content-disposition'].split('filename=')[1]
      const file = {
        data: data,
        name: fileName ? fileName.substring(1, fileName.length - 1) : ''
      }
      return file
    },
    downloadFile (file) {
      let fileDownload = require('js-file-download')
      fileDownload(file.data, file.name)
    },
    goToCorporationDetails (corporationId) {
      this.$router.push({ name: CORPORATION_DETAIL, params: { corporationId: corporationId } })
    },
    changedRadioForm () {
      this.resetPrintout()
      if (this.selection === 'all') {
        this.selectAll()
      } else if (this.selection === 'onlyNew') {
        this.selectOnlyNew()
      } else if (this.selection === 'nothing') {
        this.resetDocAndCheckboxes()
      }
    },
    selectAll () {
      this.resetDocAndCheckboxes()
      this.recipient.corporations.forEach(corporation => {
        corporation.documentTypes.forEach(document => {
          this.pushAllDocuments(document.documents, document.documentTypeId)
        })
        corporation.properties.forEach(property => {
          property.documentTypes.forEach(document => {
            this.pushAllDocuments(document.documents, document.documentTypeId)
          })
        })
      })
    },
    selectOnlyNew () {
      this.resetDocAndCheckboxes()
      this.recipient.corporations.forEach(corporation => {
        corporation.documentTypes.forEach(document => {
          this.pushOnlyNewDocuments(document.documents, document.documentTypeId)
        })
        corporation.properties.forEach(property => {
          property.documentTypes.forEach(document => {
            this.pushOnlyNewDocuments(document.documents, document.documentTypeId)
          })
        })
      })
    },
    pushAllDocuments (documents, documentTypeId) {
      if (documents && documents.length > 0) {
        let newest = documents[documents.length - 1]
        this.selectedCheckboxes.push(newest.documentId)
        let selectedDocument = {
          documentId: newest.documentId,
          documentTypeId: documentTypeId
        }
        this.selectedDocuments.push(selectedDocument)
      }
    },
    pushOnlyNewDocuments (documents, documentTypeId) {
      if (documents && documents.length > 0) {
        let newest = documents[documents.length - 1]
        if (!newest.lastPrinted) {
          this.selectedCheckboxes.push(newest.documentId)
          let selectedDocument = {
            documentId: newest.documentId,
            documentTypeId: documentTypeId
          }
          this.selectedDocuments.push(selectedDocument)
        }
      }
    },
    setCustomSelection () {
      this.selection = 'custom'
    },
    propertySubtitle (property) {
      return this.$t('dossier.corporations.propertyDocuments') + ' [' + property.propertyId + '] ' + property.street + ', ' + property.zip + ' ' + property.place
    },
    formatEndOfMandate (date) {
      return this.formatDateFunc(date)
    },
    printDocuments () {
      this.printDocumentsLoading = true
      let dossierPostBody = {
        year: this.year,
        print: this.print,
        pdf: this.createPdf,
        documents: this.selectedDocuments
      }
      let that = this
      this.$prestigeDossierCustomRecipient.printDocuments(this.recipient.recipientId, dossierPostBody).then(response => {
        that.printDocumentsLoading = false
        that.loadData()
        that.setSuccessSnackbar(that.$t('dossier.successfullyPrinted'))
      }).catch(error => {
        that.printDocumentsLoading = false
        that.setErrorSnackbar(error)
      })
    },
    setPrintoutDocuments (history) {
      this.setCustomSelection()
      this.resetDocAndCheckboxes()
      this.selectedPrintout = this.selectedPrintout === history.recipientPrintHistoryId ? null : history.recipientPrintHistoryId
      if (this.selectedPrintout && history.printedRecipientDocuments) {
        this.selectedDocuments = [...history.printedRecipientDocuments]
        if (this.selectedDocuments.length > 0) {
          this.selectedDocuments.forEach(item => {
            this.selectedCheckboxes.push(item.documentId)
          })
        }
      } else if (!history.printedRecipientDocuments) {
        this.selectedPrintout = null
      }
    },
    resetDocAndCheckboxes () {
      this.selectedDocuments = []
      this.selectedCheckboxes = []
    },
    resetPrintout () {
      this.selectedPrintout = null
    },
    openMapProperty (propertyId) {
      this.$router.push({ name: MAP_PROPERTIES, params: { propertyId: propertyId.toString() } })
    }
  }
}
</script>

<style scoped>
.ul-none {
  list-style: none;
  overflow-y: auto;
  padding: 0;
}
@media (min-width: 960px) {
  .ul-none {
    position: absolute;
    max-height: calc(100% - 72px);
    width: calc(100% - 16px);
  }
}
.print-history-item:hover {
  color: rgba(25, 118, 210);
  background-color: rgba(25, 118, 210, 0.12);
  border-radius: 5px;
  cursor: pointer;
}

.cal-border {
  border: 1px solid red;
}
</style>
