<template>
  <div>
    <v-card class="mt-5" v-for="(resident, index) in residents" v-bind:key="'resident-' + index">
      <ResidentInfo :resident="resident" />
      <v-divider></v-divider>
      <v-card-text v-if="resident.currentApartmentStatus === 'RENTAL_APARTMENT'">
        <v-row>
          <v-col cols="12">
            <span class="text--subtitle-2 font-weight-bold">{{ $t('renting.referencesApartment.currentApartment') }}:</span> <span>{{ resident.currentApartmentStreet }} {{ resident.currentApartmentStreetNumber }}, {{ resident.currentApartmentZip }} {{ resident.currentApartmentPlace }}</span>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4">
            <span class="subtitle-2 font-weight-bold">{{ $t('renting.referencesApartment.monthlyRent') }}:</span> <span>{{ resident.currentApartmentRent }}</span>
          </v-col>
          <v-col cols="4">
            <span class="subtitle-2 font-weight-bold">{{ $t('renting.referencesApartment.rootNumbers') }}:</span> <span>{{ resident.currentApartmentNumberOfRooms }}</span>
          </v-col>
          <v-col cols="4">
            <span class="subtitle-2 font-weight-bold">{{ $t('renting.referencesApartment.rentStartDate') }}:</span> <span>{{ formatDate(resident.currentApartmentStartRentDate) }}</span>
          </v-col>
          <v-col cols="12">
            <span class="text--subtitle-2 font-weight-bold">{{ $t('renting.referencesApartment.currentLandlord') }}:</span> <span>{{ resident.landlordNameAddress }} <a :href="'tel:' + resident.landlordPhone">{{ resident.landlordPhone }}</a> {{ resident.landlordEmail }}</span>
          </v-col>
        </v-row>
        <p class="text-sm-h6 mt-5">{{ $t('renting.respondent') }}</p>
        <v-row>
          <v-col cols="12" sm="3">
            <v-text-field
              :label="$t('common.data.name')"
              v-model="resident.respondentLandlordName"
              persistent-hint
              required
              :rules="[rules.required]"
              :disabled="formReadOnly"
            />
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              :label="$t('renting.referenceFunction')"
              v-model="resident.respondentLandlordFunction"
              persistent-hint
              required
              :rules="[rules.required]"
              :disabled="formReadOnly"
            />
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              :label="$t('common.data.telPhone')"
              v-model="resident.respondentLandlordPhone"
              persistent-hint
              required
              :rules="[rules.required]"
              :disabled="formReadOnly"
            />
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              :label="$t('common.data.email')"
              v-model="resident.respondentLandlordEmail"
              persistent-hint
              required
              :rules="[rules.required, rules.email]"
              :disabled="formReadOnly"
            />
          </v-col>
        </v-row>

        <p class="text-sm-h6 mt-5">{{ $t('renting.referenceFeedback') }}</p>
        <v-row>
          <v-col cols="12" class="pb-0">
            <v-card-subtitle class="pa-0 mr-3">{{ $t('renting.referencesApartment.regularPayment') }}</v-card-subtitle>
            <v-radio-group row v-model="resident.apartmentRegularlyPayment" :rules="[rules.radioRequired]" class="mt-2">
              <v-radio :label="$t('common.options.yes')" :value="true" :disabled="formReadOnly"></v-radio>
              <v-radio :label="$t('common.options.no')" :value="false" :disabled="formReadOnly"></v-radio>
              <template #message="{ message }">
                <span v-if="message === 'required'">{{ requiredMessage }}</span>
              </template>
            </v-radio-group>
          </v-col>
          <v-col cols="12" class="py-0">
            <v-card-subtitle class="pa-0 mr-3">{{ $t('renting.referencesApartment.notableIncidents') }}</v-card-subtitle>
            <v-radio-group row v-model="resident.apartmentNotableIncidents" :rules="[rules.radioRequired]" class="mt-2">
              <v-radio :label="$t('common.options.yes')" :value="true" :disabled="formReadOnly"></v-radio>
              <v-radio :label="$t('common.options.no')" :value="false" :disabled="formReadOnly"></v-radio>
              <template #message="{ message }">
                <span v-if="message === 'required'">{{ requiredMessage }}</span>
              </template>
            </v-radio-group>
          </v-col>
          <v-col cols="12" class="py-0">
            <v-card-subtitle class="pa-0 mr-3">{{ $t('renting.referencesApartment.rentalRelationship') }}</v-card-subtitle>
            <v-radio-group row v-model="resident.apartmentAgainRentalRelationship" :rules="[rules.radioRequired]" class="mt-2">
              <v-radio :label="$t('common.options.yes')" :value="true" :disabled="formReadOnly"></v-radio>
              <v-radio :label="$t('common.options.no')" :value="false" :disabled="formReadOnly"></v-radio>
              <template #message="{ message }">
                <span v-if="message === 'required'">{{ requiredMessage }}</span>
              </template>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-textarea
          :label="$t('renting.comment')"
          v-model="resident.apartmentComment"
          :disabled="formReadOnly"
        ></v-textarea>
      </v-card-text>
      <v-card-text v-else>
        <v-alert dense outlined type="info">
          {{ $t('renting.publicForm.fields.personInfo.apartment.status_' + resident.currentApartmentStatus) }}
        </v-alert>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ResidentInfo from './shared/ResidentInfo'
import * as ticketingHelper from '@/features/ticketing/helpers/ticketingHelper'
import * as globalHelper from '@/helpers/globalHelper'
import * as validationHelper from '@/helpers/validationHelper'
import { getGraphQLVariable } from '@/helpers/graphQLHelper'
import { formatDate } from '@/helpers/formatterHelper'
import { mapActions } from 'vuex'
import { TICKETING_TICKET_EXECUTION } from '../../graphql-apollo/ticketingTicket.gql'

import { useRentingForm } from '@/use/useRentingForm'
const { loadRentingForm } = useRentingForm()

export default {
  name: 'RentingFormReferencesApartment',
  components: {
    ResidentInfo
  },
  props: {
    ticketingTask: {
      type: Object,
      default: () => {}
    },
    processVariables: {
      type: Array,
      default: () => {
      }
    },
    formReadOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      rentingForm: {},
      terminationFile: null,
      rules: {
        required: (value) => !!value || this.$t('common.errors.required'),
        email: (value) => { return validationHelper.validateEmail(value) },
        radioRequired: (value) => (value !== undefined && value !== '' && value !== null) || this.requiredMessage
      }
    }
  },
  apollo: {
    executionVariables: {
      query: TICKETING_TICKET_EXECUTION,
      skip () {
        return !this.ticketId || !this.executionId
      },
      error (error) {
        this.ticketLoadError = error.message
      },
      variables () {
        return {
          ticketId: this.ticketId,
          executionId: this.executionId
        }
      }
    }
  },
  watch: {
    'rentingFormId': {
      handler: 'getRentalFormData',
      immediate: true
    }
  },
  computed: {
    residents () {
      let residents = this.rentingForm?.content?.residents
      if (residents && this.token) {
        return residents.filter(resident => resident.landlordToken === this.token)
      } else if (residents) {
        return residents
      } else {
        return []
      }
    },
    rentingFormId () {
      return this.getVariableValue('System_RentingFormId_Long')
    },
    ticketId () {
      return this.ticketingTask?.ticket?.ticketId
    },
    executionId () {
      return this.ticketingTask?.executionId
    },
    token () {
      return getGraphQLVariable(this.executionVariables?.variables, 'landlordToken', 'stringValue')
    },
    requiredMessage () {
      return this.$t('common.errors.required')
    }
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    formatDate: formatDate,
    customFilter (item, queryText, itemText) {
      return globalHelper.propertyFilter(queryText, itemText)
    },
    async getRentalFormData () {
      this.rentingForm = await loadRentingForm(this.rentingFormId)
      await this.rentingForm?.content?.residents.map((resident) => {
        resident.respondentLandlordName = resident.respondentLandlordName || resident.landlordName,
        resident.respondentLandlordFunction = resident.respondentLandlordFunction || resident.landlordFunction,
        resident.respondentLandlordPhone = resident.respondentLandlordPhone || resident.landlordPhone,
        resident.respondentLandlordEmail = resident.respondentLandlordEmail || resident.landlordEmail
      })
      this.loading = false
    },
    async saveThirdPartyData () {
      await this.$prestigeRenting.updateRentingForm(this.rentingFormId, this.rentingForm.content)
    },
    getVariableValue (variableName) {
      return ticketingHelper.getVariableValue(this.processVariables, variableName)
    }
  }
}
</script>

<style scoped lang="postcss">
</style>
