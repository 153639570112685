import Vue from 'vue'

class TicketingProcessService {
  async getProcesses (queryParams) {
    const response = await Vue.prototype.$prestigeTSProcess.getListOfProcesses({}, queryParams)
    return response
  }
}

export default new TicketingProcessService()
