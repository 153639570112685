<template>
  <v-card class="mb-3" v-if="!noConstructionDetails">
    <v-card-title class="pt-2 pb-2 pl-3" primary-title>
      <h3>
        {{ currentConstructionProjectDetail.title || $t('common.noTitle') }}
      </h3>
      <v-spacer></v-spacer>
      <v-btn icon @click="closeDetails" class="ma-0">
        <v-icon>clear</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-title class="pt-2 pb-0 body-2">{{ setValue(currentConstructionProjectDetail, 'description') }}</v-card-title>
    <v-card-title class="pt-2 pb-0 body-2">{{ $t('common.address') }}: {{ currentConstructionProjectAddress }}</v-card-title>
    <v-card-title class="pt-2 pb-0 body-2">{{ $t('constructionProjects.planningStage') }}: {{ setValue(currentConstructionProjectDetail, 'planningState') }}</v-card-title>
    <v-card-title class="pt-2 pb-0 body-2">
      {{ $t('constructionProjects.constructionStart') }}: {{ currentConstructionStartOfConstruction }}
      <template v-if="inThePast">
        <v-icon color="orange" class="mx-2">
          warning
        </v-icon>
        {{ $t('constructionProjects.inThePast') }}
      </template>
    </v-card-title>
    <v-card-title class="pt-2 pb-0 body-2">
      {{ $t('common.date.title') }}: {{ formatDateFunc(currentConstructionProjectDetail.createdOn) }}
      <template>
        ({{ $t('constructionProjects.updated') }}: {{ currentConstructionProjectDetail.createdOn !== currentConstructionProjectDetail.updatedOn
          ? formatDateFunc(currentConstructionProjectDetail.updatedOn) : ''
        }})
      </template>
    </v-card-title>
    <v-card-title class="pt-2 pb-0 body-2">
      {{ $t('common.id') }}: {{ currentConstructionProjectDetail.buildingProjectId }}
    </v-card-title>
    <v-card-title class="pt-2 pb-0 body-2">
      <v-layout row wrap>
        <v-flex sm4 v-for="(address, i) in currentConstructionProjectDetail.buildingProjectAddresses" :key="i">
          <v-list class="pa-0">
            <p>
              <strong v-if="address.buildingProjectAddressType">{{ $t('common.mapAddressTypes.' + address.buildingProjectAddressType) }}</strong><br/>
              <span v-if="address.name">{{ address.name }}<br /></span>
              <span v-if="address.addressLine1">{{ address.addressLine1 }}<br /></span>
              <span v-if="address.addressLine2">{{ address.addressLine2 }}<br /></span>
              <span v-if="address.addressLine3">{{ address.addressLine3 }}<br /></span>
              <span v-if="address.zip || address.place">{{ address.zip }} {{ address.place }}<br /></span>
              <span v-if="address.phone">{{ address.phone }}<br /></span>
              <span v-if="address.email"><a :href="'mailto:' + address.email">{{ address.email }}</a><br /></span>
              <span v-if="address.website"><a target="_blank" :href="'http://' + address.website">{{ address.website }}</a></span>
            </p>
          </v-list>
        </v-flex>
      </v-layout>
    </v-card-title>

    <v-card-title class="pt-2 pb-0 body-2" v-if="currentConstructionProjectDetail.status">
      <h3>{{ $t('common.properties') }}:</h3>
    </v-card-title>
    <v-card-title class="pt-0 pb-0 body-2" v-if="currentConstructionProjectDetail.status">
      <v-list>
        <v-list-item v-for="item in currentConstructionProjectDetail.status" :key="item.property.propertyId" class="pl-1 pr-1">
          <template>
            <v-list-item-avatar>
              <v-icon :size="22" :title="item.status">{{ getIconByStatus(item.status) }}</v-icon>
            </v-list-item-avatar>
            <v-list-item-content class="d-block">
              [<a href="" @click="openMapProperty(item.property.propertyId)">{{ item.property.propertyId }}</a>] {{ item.property.street }}, {{ item.property.zip }} {{ item.property.place }} ({{ item.property.realEstateManagerId }})
            </v-list-item-content>
            <v-list-item-action>
              <span>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" :disabled="item.status == 'FLAG'" color="orange" @click="updatePropertiesStatus(currentConstructionProjectDetail.buildingProjectId, item.property.propertyId, 'FLAG')">
                      <v-icon>grade</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('constructionProjects.flagProperty') }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" :disabled="item.status == 'IGNORE'" color="teal" @click="updatePropertiesStatus(currentConstructionProjectDetail.buildingProjectId, item.property.propertyId, 'IGNORE')">
                      <v-icon>visibility_off</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('constructionProjects.ignoreProperty') }}</span>
                </v-tooltip>
              </span>
            </v-list-item-action>
          </template>
        </v-list-item>
      </v-list>
    </v-card-title>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import _isEmpty from 'lodash.isempty'
import { getBuildingProjectAddress } from '@/helpers/buildingProjectsHelper'
import { formatDate } from '@/helpers/formatterHelper'
import { isDateInThePast } from '@/helpers/momentHelper'
import { MAP_PROPERTIES, CONSTRUCTION_PROJECTS } from '@/config/options/routes/mapRoutes'

export default {
  name: 'ConstructionProjectsDetail',
  props: {
    currentConstructionProjectDetail: {
      type: Object,
      default: () => {}
    },
    realEstateManagerId: {
      type: String,
      default: ''
    }
  },
  computed: {
    noConstructionDetails () {
      return _isEmpty(this.currentConstructionProjectDetail)
    },
    currentConstructionProjectAddress () {
      return getBuildingProjectAddress(this.buildingProject)
    },
    currentConstructionStartOfConstruction () {
      return this.currentConstructionProjectDetail && this.currentConstructionProjectDetail.startOfConstruction ? this.formatDateFunc(this.currentConstructionProjectDetail.startOfConstruction) : ''
    },
    inThePast () {
      return isDateInThePast(this.currentConstructionStartOfConstruction)
    }
  },
  methods: {
    ...mapActions('notifier', ['setSuccessSnackbar', 'setErrorSnackbar']),
    formatDateFunc: formatDate,
    updatePropertiesStatus (buildingProjectId, propertyId, status) {
      let data = {
        buildingProjectId: buildingProjectId,
        propertyId: propertyId,
        status: status
      }
      let that = this
      this.$prestigeConstructionProjects.addStatus(data).then(function (response) {
        if (response.data) {
          that.currentConstructionProjectsDetail = response.data
        }
        that.loadConstructionProjects()
        that.setSuccessSnackbar(that.$t('common.messages.successfullyUpdated'))
        return true
      }).catch(error => {
        that.setErrorSnackbar(that.$t('common.errors.dataNotLoaded') + error)
      })
    },
    getIconByStatus (status) {
      switch (status) {
        case 'UNDEFINED':
          return 'help_outline'
        case 'FLAG':
          return 'grade'
        case 'IGNORE':
          return 'visibility_off'
      }
    },
    loadConstructionProjects () {
      this.$emit('loadConstructionProjects')
    },
    setValue (obj, prop) {
      return !_isEmpty(obj) ? obj[prop] : ''
    },
    closeDetails () {
      if (this.$route.params) {
        this.$router.push({ name: CONSTRUCTION_PROJECTS })
      }
    },
    openMapProperty (propertyId) {
      this.$router.push({ name: MAP_PROPERTIES, params: { propertyId: propertyId.toString() } })
    }
  }
}
</script>
