import { DASHBOARD_CONSTANTS } from '@/config/options/dashboardConstants'

export function removeTableSelections (tableClassName = false) {
  let className = 'v-data-table__selected'
  if (tableClassName) {
    let tableElement = document.getElementsByClassName(tableClassName)
    if (tableElement && tableElement.length > 0) {
      let selectedRows = tableElement[0].getElementsByClassName(className)
      if (selectedRows.length > 0) {
        removeSelectedClassList(selectedRows, className)
      }
    }
  } else {
    let elements = document.getElementsByClassName(className)
    if (elements && elements.length > 0) {
      removeSelectedClassList(elements, className)
    }
  }
}

export function removeSelectedClassList (elements, className) {
  elements.forEach(el => {
    el.classList.remove(className)
  })
}

export function setSelectedItemHighlightClass (id, tableClassName) {
  if (id) {
    let tableElement = document.getElementsByClassName(tableClassName)
    if (tableElement && tableElement.length > 0) {
      let rowId = 'row-' + id
      let element = tableElement[0].getElementsByClassName(rowId)
      if (element && element.length > 0) {
        element[0].classList.add('v-data-table__selected')
      }
    }
  }
}

export function dataGridClasses (personData, propertyTicketList, taskList) {
  if (personData && propertyTicketList && taskList) {
    return 'md6 lg3 xl3'
  } else if (personData && !propertyTicketList && taskList) {
    return 'md4 lg4 xl4'
  } else if (!personData && propertyTicketList && taskList) {
    return 'md6 lg6 xl6'
  }
}

export function propertyTicketListGridClasses (personData, propertyTicketList, taskList) {
  if (personData && propertyTicketList && taskList) {
    return 'md6 lg3 xl3'
  } else if (!personData && propertyTicketList && taskList) {
    return 'md6 lg6 xl6'
  }
}

export function getDashboardColor (type) {
  switch (type) {
    case DASHBOARD_CONSTANTS.ALL:
      return 'amber'
    case DASHBOARD_CONSTANTS.TENANT: 
      return 'indigo'
    case DASHBOARD_CONSTANTS.CLIENT:
      return 'teal'
    case DASHBOARD_CONSTANTS.CARETAKER:
      return 'green'
    case DASHBOARD_CONSTANTS.CRAFTSMAN:
      return 'blue-grey'
    case DASHBOARD_CONSTANTS.RENTING_FORM:
      return 'brown'
  }
  return ''
}
