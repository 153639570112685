<template>
  <v-card class="pa-4 cy-dashboard-person-address" v-show="addressId && addressDetails" :id="addressId">
    <v-layout :class="mdAndDown ? 'd-block' : ''">
      <v-list-item class="pa-0">
        <v-list-item-content><v-card-title class="pa-0">{{ addressHeader }}</v-card-title></v-list-item-content>
        <v-list-item-action class="ma-3">
          <ActionsMenu :actions="actions" />
        </v-list-item-action>
      </v-list-item>
    </v-layout>
    <v-flex v-if="addressDetails" xs12 sm12 md12 lg12 xl12 class="mb-4">
      <v-card-text class="pa-0">
        <span v-if="addressDetails.line2">{{ addressDetails.line2 }}</span>
      </v-card-text>
      <v-card-text class="pa-0" v-if="addressDetails.zip || addressDetails.place">
        {{ addressDetails.zip }} {{ addressDetails.place }}
      </v-card-text>
      <v-card-text class="pa-0" v-if="addressDetails.mobile">{{ addressDetails.mobile }}</v-card-text>
      <v-card-text class="pa-0" v-if="addressDetails.phone1">{{ addressDetails.phone1 }}</v-card-text>
      <v-card-text class="pa-0" v-if="addressDetails.phone2">{{ addressDetails.phone2 }}</v-card-text>
      <v-card-text class="pa-0" v-if="addressDetails.email">{{ addressDetails.email }}</v-card-text>
      <template v-for="(item, index) in addressProperties">
        <v-card-text :key="index" class="pl-0" v-if="item.property && item.property.realEstateManager && (item.property.realEstateManager.firstName || item.property.realEstateManager.lastName)">
          {{ $t('common.realEstateManager') }}: <strong>{{ item.property.realEstateManager.firstName + ' ' + item.property.realEstateManager.lastName }}</strong>
        </v-card-text>
      </template>
    </v-flex>
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-expansion-panels v-model="panel" mandatory focusable>
        <template v-for="(item, index) in addressProperties">
          <v-expansion-panel v-if="item.value && item.value.length" :key="index" @change="changeRoute(item)" >
            <v-expansion-panel-header expand-icon="mdi-view-list">
              <v-card-title class="pa-0">
                {{ $t('dashboard.' + (item.partner_locale ? item.partner_locale : item.name)) }}
              </v-card-title>
            </v-expansion-panel-header>
            <v-expansion-panel-content v-if="item.name === 'tenant'" @click="changeRoute(item)">
              <AddressProperties :properties="[item.property]" @openNewTicketDialog="onOpenNewTicketDialog" />
              <v-container fluid class="pl-4 pr-1 max-height">
                <v-card-subtitle class="pa-0"><h3>{{ $t('common.propertyObjects') }}</h3></v-card-subtitle>
                <TenantAddressObjects :tenant-info="addressHeader" :tenant-address-objects="item.property.objects" @openNewTicketDialog="onOpenNewTicketDialog" />
              </v-container>
            </v-expansion-panel-content>
            <v-expansion-panel-content v-else-if="item.name === 'sublet'" @click="changeRoute(item)">
              <AddressProperties :properties="[item.property]" @openNewTicketDialog="onOpenNewTicketDialog" />
              <v-container fluid class="pl-4 pr-1 max-height">
                <v-card-subtitle class="pa-0"><h3>{{ $t('common.propertyObjects') }}</h3></v-card-subtitle>
                <TenantAddressObjects :tenant-info="addressHeader" :tenant-address-objects="item.property.objects" @openNewTicketDialog="onOpenNewTicketDialog" />
              </v-container>
            </v-expansion-panel-content>
            <v-expansion-panel-content v-else @click="changeRoute(item)">
              <AddressProperties :properties="item.value" @openNewTicketDialog="onOpenNewTicketDialog" />
            </v-expansion-panel-content>
            <v-expansion-panel-content v-if="item.property && item.property.realEstateManager" class="pa-4">
              <v-flex xs12 sm12 md12 lg12 xl12 class="mb-4">
                <v-card-subtitle class="pa-0"><h3>{{ $t('common.realEstateManager') }}</h3></v-card-subtitle>
                <v-card-text class="pa-0" v-if="item.property.realEstateManager.firstName || item.property.realEstateManager.lastName">
                  {{ item.property.realEstateManager.firstName + ' ' + item.property.realEstateManager.lastName }}
                </v-card-text>
              </v-flex>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </template>
      </v-expansion-panels>
    </v-flex>
    <TicketDialog
      v-if="shouldShowNewTicketDialog"
      :process-instance="true"
      :new-links="newLinks"
      :default-subject="subject"
      :process-definition-key="newTicketDialogProcessDefinitionKey"
      :variables="newTicketDialogVariables"
      @closeDialog="shouldShowNewTicketDialog = false"
      @processInstanceCreated="onProcessInstanceCreated"
      @updateNewLinks="onUpdateNewLinks"
    />
  </v-card>
</template>

<script>
import AddressProperties from './AddressProperties'
import TenantAddressObjects from '@/features/shared/TenantAddressObjects'
import TicketDialog from '@/features/ticketing/dialogs/TicketDialog'
import ActionsMenu from '@/components/ActionsMenu'
import Vue from 'vue'
import { mapActions } from 'vuex'
import { useTenantActions } from '@/use/useTenantActions'
import { useAddressActions } from '@/use/useAddressActions'
import { getAddressInfo, getFullName } from '@/helpers/globalHelper'

const { tenantActions } = useTenantActions()
const { addressActions } = useAddressActions()

export default {
  name: 'AddressOverview',
  data () {
    return {
      dataReady: false,
      panel: null,
      shouldShowNewTicketDialog: false,
      newLinks: [],
      subject: '',
      newTicketDialogProcessDefinitionKey: null,
      newTicketDialogVariables: []
    }
  },
  props: {
    addressDetails: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    AddressProperties,
    TenantAddressObjects,
    TicketDialog,
    ActionsMenu
  },
  computed: {
    additionalActions () {
      return [
        { title: this.$t('dashboard.actions.newTicket'), icon: 'mdi-ticket', action: this.onNewTicket },
        ...addressActions
      ]
    },
    actions () {
      return this.addressDetails?.isTenant || this.addressDetails?.isSublet || this.addressDetails?.isContractPartner ? [...this.additionalActions, ...tenantActions] : this.additionalActions
    },
    addressId () {
      return this.$route.params.addressId
    },
    addressType () {
      return this.$route.params.addressType
    },
    addressHeader () {
      return this.addressDetails ? (this.addressDetails.firstName + ' ' + this.addressDetails.lastName).trim() : ''
    },
    addressProperties () {
      if (this.addressDetails) {
        let propertiesList = []
        if (this.addressDetails.isCaretaker && this.addressDetails.caretakerProperties?.length > 0) {
          propertiesList.push({ name: 'caretaker', title: 'relatedProperties', value: this.addressDetails.caretakerProperties })
        }
        if (this.addressDetails.isClient && this.addressDetails.clientProperties?.length > 0) {
          propertiesList.push({ name: 'client', title: 'relatedProperties', value: this.addressDetails.clientProperties })
        }
        if (this.addressDetails.isCraftsman && this.addressDetails.craftsmanPriorityProperties?.length > 0) {
          propertiesList.push({ name: 'craftsman', title: 'priorityProperties', value: this.addressDetails.craftsmanPriorityProperties })
        }
        if (this.addressDetails.isTenant || this.addressDetails.isContractPartner && this.addressDetails.tenantObjects?.length > 0) {
          // restructure from flat object list to list of objects grouped by property
          this.addressDetails.tenantObjects
            .reduce((group, object) => {
                const { property } = object
                const index = group.findIndex(g => g.propertyId === property.propertyId)
                if (index >= 0) {
                  group[index].objects.push(object)
                } else {
                  group.push(Object.assign({objects: [object]}, property))
                }
                return group
              }, [])
            .forEach(o => propertiesList.push({
              name: 'tenant',
              title: 'relatedObjects',
              property: o,
              value: o.objects
            }))
        }
        if (this.addressDetails.isPartner && this.addressDetails.partnerProperties?.length > 0) {
          propertiesList.push({ partner_locale: 'partner', name: this.addressType, title: 'relatedProperties', value: this.addressDetails.partnerProperties })
        }
        if (this.addressDetails.isSublet && this.addressDetails.subletObjects?.length > 0) {
          // restructure from flat object list to list of objects grouped by property
          this.addressDetails.subletObjects
            .reduce((group, object) => {
              const {property} = object
              const index = group.findIndex(g => g.propertyId === property.propertyId)
              if (index >= 0) {
                group[index].objects.push(object)
              } else {
                group.push(Object.assign({objects: [object]}, property))
              }
              return group
            }, [])
            .forEach(o => propertiesList.push({
              name: 'sublet',
              title: 'relatedObjects',
              property: o,
              value: o.objects
            }))
        }
        if (propertiesList.length > 0) {
          let currentAddress = propertiesList.find(x => x.name === this.addressType)
          if (currentAddress && currentAddress?.value) {
            this.$emit('updateProperties', currentAddress.value)
          }
        }
        return propertiesList
      }
      return ''
    },
    mdAndDown () {
      return this.$vuetify.breakpoint.mdAndDown
    }
  },
  watch: {
    addressProperties () {
      if (this.addressProperties && this.addressProperties.length > 0) {
        this.panel = this.addressProperties.findIndex(x => x.name === this.addressType)
      }
    },
    panel () {
      if (this.addressProperties) {
        this.$emit('expandPanel', this.addressProperties[this.panel])
      }
    }
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    onOpenNewTicketDialog (links = [], subject, processDefinitionKey = null, variables = [], property = null) {
      this.newLinks = links
      if (this.addressType) {
        this.addAddressLink()
      }
      this.subject = subject || this.getSubject(subject, processDefinitionKey, property) || ''
      this.newTicketDialogProcessDefinitionKey = processDefinitionKey
      this.newTicketDialogVariables = variables
      this.shouldShowNewTicketDialog = true
    },
    getSubject (processDefinitionKey, property) {
      if (this.addressDetails) {
        let fullName = getFullName(this.addressDetails)
        if (processDefinitionKey === 'C2_02_07' && property) {
          return property.street + ' - ' + fullName
        } else {
          return getAddressInfo(this.addressDetails) + ' - ' + fullName
        }
      }
    },
    addAddressLink () {
      let addressType = this.addressType
      let addressId = this.$route.params.addressId
      let index = this.newLinks.findIndex(x => x.linkName === addressType)
      if (index !== -1) {
        this.newLinks[index].linkValues.push(this.$route.params.addressId)
      } else {
        let newLink = {
          linkName: addressType,
          linkValues: addressId ? [addressId] : []
        }
        this.newLinks.push(newLink)
      }
    },
    changeRoute (item) {
      let itemAddressType = item?.partner_locale || item.name
      if (this.addressType !== itemAddressType && this.addressId) {
        this.$emit('addressSelected', this.addressId.toString(), itemAddressType)
        this.panel = this.addressProperties.findIndex(x => x.name === itemAddressType)
      }
    },
    onProcessInstanceCreated () {
      this.$emit('processInstanceCreated')
    },
    onNewTicket () {
      this.onOpenNewTicketDialog()
    },
    onUpdateNewLinks (updatedLinks) {
      Vue.set(this, 'newLinks', updatedLinks)
    }
  }
}
</script>

<style scoped lang="postcss">
/deep/ .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
/deep/ .v-expansion-panel-header--active:before {
  background: rgb(0, 124, 255) !important;
}
.d-block {
  display: block !important;
}
.max-height {
  max-height: 500px;
  overflow: auto;
}
</style>
