<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card class="pa-2" outlined tile v-if="loading">
          <v-progress-linear  indeterminate color="blue"></v-progress-linear>
        </v-card>
        <v-card class="pa-2" outlined tile v-if="message">
          <v-alert type="error">
            {{ message }}
          </v-alert>
        </v-card>
        <AbacusRentalCaseStatus v-if="rentalCase" :rental-case="rentalCase" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AbacusRentalCaseStatus from './AbacusRentalCaseStatus'
import { RENTAL_CASE_DISABLE_TOKEN } from '../graphql-apollo/rentalCase.gql'

export default {
  name: 'AbacusEnableRentalCase',
  components: {
    AbacusRentalCaseStatus
  },
  data () {
    return {
      rentalCase: null,
      message: '',
      loading: false
    }
  },
  created () {
    this.disableRentalCase()
  },
  computed: {
    rentalContractId () {
      return parseInt(this.$route.params.rentalContractId)
    }
  },
  methods: {
    disableRentalCase () {
      this.message = null
      if (isNaN(this.rentalContractId)) {
        this.message = 'invalid input'
      } else {
        this.rentalCase = null
        this.$apollo.mutate({
          mutation: RENTAL_CASE_DISABLE_TOKEN,
          variables: {
            rentalContractId: this.rentalContractId
          }
        }).then((response) => {
          this.rentalCase = response.data.rentingDisableTokenForRentalCase
          this.loading = false
        }).catch((error) => {
          this.message = 'failed to set data: ' + error
          this.loading = false
        })
      }
    }
  }
}
</script>

<style scoped lang="postcss">
</style>
