<template>
  <v-container fluid class="grey lighten-5 pa-2">
    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          append-icon="search"
          :label="$t('common.buttons.search')"
          single-line
          hide-details
          @input="debounceInput"
          v-model="search"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :loading="loading"
        sort-by="id"
        sort-desc
        hide-default-footer
        disable-pagination
        @click:row="openTaskDetails"
        class="elevation-1"
      >
        <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
        <template v-slot:[`item.taskType`]="{ item }">
          {{ $t('tasks.types.' + item.taskType) }}
        </template>
        <template v-slot:[`item.jobDescription`]="{ item }">
          <p class="mb-0 truncate-two-lines">{{ item.jobDescription }}</p>
        </template>
        <template v-slot:[`item.craftsmanSubject`]="{ item }">
          <span v-html="item.craftsmanSubject"></span>
        </template>
        <template v-slot:[`item.createdOn`]="{ item }">
          {{ item.createdOnFormatted }}
        </template>
        <template v-slot:[`item.taskSource`]="{ item }">
          {{ item.taskSource ? $t('tasks.taskSources.' + item.taskSource) : '' }}
        </template>
        <template slot="no-data">
          <v-alert class="mt-4" :value="true" color="error" icon="warning">
            {{ $t('table.noData') }}
          </v-alert>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { formatDate } from '@/helpers/formatterHelper'
import _debounce from 'lodash.debounce'

export default {
  name: 'TasksList',
  data () {
    return {
      loading: true,
      search: '',
      items: []
    }
  },
  computed: {
    headers () {
      return [
        {
          text: this.$t('common.id'),
          align: 'left',
          value: 'id'
        },
        {
          text: this.$t('tasks.list.type'),
          align: 'left',
          value: 'taskType'
        },
        {
          text: this.$t('tasks.createTask.subject1'),
          align: 'left',
          value: 'subjectLine1',
          class: 'min-200'
        },
        {
          text: this.$t('tasks.createTask.jobDescription'),
          align: 'left',
          value: 'jobDescription'
        },
        {
          text: this.$t('tasks.tenant.title'),
          align: 'left',
          value: 'subjectLine2',
          class: 'min-200'
        },
        {
          text: this.$t('tasks.craftsmen.title'),
          align: 'left',
          value: 'craftsmanSubject',
          class: 'min-150'
        },
        {
          text: this.$t('common.date.creationDate'),
          align: 'left',
          value: 'createdOn'
        },
        {
          text: this.$t('tasks.taskSource'),
          align: 'left',
          value: 'taskSource'
        }
      ]
    }
  },
  watch: {
    // call again the method if the route changes
    '$route': 'loadTasks'
  },
  mounted: function () {
    this.loadTasks()
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    formatDateFunc: formatDate,
    loadTasks () {
      let propertyId = this.$route.params.propertyId ? parseInt(this.$route.params.propertyId) : undefined
      let objectId = this.$route.params.propertyObjectId ? parseInt(this.$route.params.propertyObjectId) : undefined
      let that = this
      that.loading = true
      this.$prestigeTask.searchTasks(propertyId, objectId).then(function (response) {
        if (response.data.length) {
          that.items = response.data
          that.items.forEach(item => {
            item.craftsmanSubject = item.craftsman ? `<p class="mb-0 truncate-single-line max-150"><nobr>${item.craftsman.firstName} ${item.craftsman.lastName}<nobr></p><p class="mb-0">${item.craftsman.zip} ${item.craftsman.place}</p>` : ''
            item.createdOnFormatted = that.formatDateFunc(item.createdOn)
          })
        }
        that.loading = false
        return true
      }).catch(function (error) {
        that.setErrorSnackbar(error)
        that.loading = false
      })
    },
    debounceInput: _debounce(function (e) {
      this.loadTasks()
    }, 1000),
    openTaskDetails (task) {
      window.open('/abacus/tasks/detail/' + task.id)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="postcss">
/deep/ tr:nth-child(odd) {
  background: #f8f8f8;
}
/deep/ tr {
  cursor: pointer;
}
.truncate-two-lines {
  display: block;
  display: -webkit-box;
  max-width: 250px;
  margin: 0;
  font-size: 13px;
  line-height: 1.5;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.min-200 {
  min-width: 200px;
}
.min-150 {
  min-width: 150px;
}
.max-150 {
  max-width: 150px;
}
.truncate-single-line {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
