<template>
  <v-slide-y-transition mode="out-in">
    <v-card class="pl-2 pr-2 pb-2 mt-3 grey lighten-5">
      <v-card-title class="pl-1 pb-0">
        <v-icon class="pr-2">mdi-database-import</v-icon>{{ $t('navigation.dossier') }}
      </v-card-title>
      <v-layout wrap class="row pa-2">
        <v-flex class="pl-2 pr-1 mt-2">
            <v-card height="100%">
              <v-container fluid>
                <div class="inline float-left mb-2">
                  <v-btn class="primary ma-2" :loading="dossierCorporationsLoading" :disabled="dossierCorporationsLoading" @click="importCorporations()">
                      <v-icon class="pr-2">mdi-database-plus</v-icon> {{ $t('navigation.corporations') }}
                  </v-btn>
                </div>
                <div class="inline float-left mb-2">
                  <v-btn class="primary ma-2" :loading="azureStorageLoading" :disabled="azureStorageLoading" @click="importAzureStorage()">
                      <v-icon class="pr-2">mdi-database-plus</v-icon> {{ $t('dataImport.dossier.fileShare') }}
                  </v-btn>
                </div>
              </v-container>
            </v-card>
        </v-flex>
      </v-layout>
    </v-card>
  </v-slide-y-transition>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'SilentsoftImport',
  data () {
    return {
      dossierCorporationsLoading: false,
      azureStorageLoading: false
    }
  },
  components: {
  },
  methods: {
    ...mapActions('notifier', ['setSuccessSnackbar', 'setErrorSnackbar']),
    importCorporations () {
      let that = this
      that.dossierCorporationsLoading = true
      this.$dossierImporter.importCorporations().then(response => {
        if (that.responseSuccess(response.status)) {
          that.dossierCorporationsLoading = false
          that.triggerSuccessMessage()
        }
      }).catch(error => {
        that.dossierCorporationsLoading = false
        that.setErrorSnackbar(error)
      })
    },
    importAzureStorage () {
      let that = this
      that.azureStorageLoading = true
      this.$dossierImporter.importAzureStorage().then(response => {
        if (that.responseSuccess(response.status)) {
          that.azureStorageLoading = false
          that.triggerSuccessMessage()
        }
      }).catch(error => {
        that.azureStorageLoading = false
        that.setErrorSnackbar(error)
      })
    },
    triggerSuccessMessage () {
      return this.setSuccessSnackbar(this.$t('dataImport.success'))
    },
    responseSuccess (status) {
      return status === 200 || status === 204
    }
  }
}
</script>

<style scoped>
</style>
