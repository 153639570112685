<template>
  <v-dialog v-model="mutableShowDialog" max-width="950px" @keydown.esc="closeDialog" persistent>
    <v-card>
      <v-list-item class="pa-0">
        <v-list-item-content class="pa-0">
          <v-card-title>
            {{ title }}
            <v-btn icon absolute right>
              <v-icon class="clear" color="primary" :size="28" @click.native="closeDialog"/>
            </v-btn>
          </v-card-title>
        </v-list-item-content>
        <v-list-item-action class="mr-4">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" class="mr-10" @click="copyToClipboard">
                <img class="copy-clipboard-img" src="@/assets/icons/copy-to-clipboard.svg" />
              </v-btn>
            </template>
            <span>{{ $t('ticketingSystem.processes.variables.copyToClipboard') }}</span>
          </v-tooltip>
        </v-list-item-action>
      </v-list-item>
      <div v-if="isJson" class="main-layout">
        <pre class='code code-json'><label>JSON</label><code id="textToCopy">{{ jsonData }}</code></pre>
      </div>
      <div v-else-if="isHTML" class="main-layout">
        <pre class='code code-html'><label>HTML</label><code id="textToCopy"><div v-html="variableValue"></div></code></pre>
      </div>
      <div v-else-if="isText" class="main-layout">
        <div class="plain-text-layout plain-text"><span id="textToCopy">{{ variableValue }}</span></div>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="closeDialog">{{ $t('common.buttons.cancel') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'VariableContentDialog',
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    jsonData: {
      type: Array,
      default: () => []
    },
    variableValue: {
      type: String,
      default: ''
    },
    contentType: {
      type: String,
      default: ''
    }
  },
  computed: {
    isJson () {
      return this.contentType === 'json'
    },
    isHTML () {
      return this.contentType === 'html'
    },
    isText () {
      return this.contentType === 'text'
    },
    mutableShowDialog () {
      return this.showDialog
    }
  },
  methods: {
    ...mapActions('notifier', ['setSuccessSnackbar']),
    closeDialog () {
      this.$emit('closeDialog')
    },
    async copyToClipboard () {
      const copyText = document.getElementById('textToCopy').innerHTML
      let textarea = document.createElement('textarea')
      textarea.textContent = copyText
      document.body.appendChild(textarea)

      let selection = document.getSelection()
      let range = document.createRange()
      range.selectNodeContents(textarea)
      range.selectNode(textarea)
      selection.removeAllRanges()
      selection.addRange(range)

      if (document.execCommand('copy')) {
        this.setSuccessSnackbar(this.$t('ticketingSystem.processes.variables.copiedToClipboard'))
      }
      selection.removeAllRanges()
      document.body.removeChild(textarea)
    }
  }
}
</script>

<style scoped lang="postcss">
/deep/ .main-layout {
  padding: 0 15px;
  max-height: 650px;
  overflow: auto;
}
.copy-clipboard-img {
  width: 25px;
  height: 25px;
}
pre {
  width: 100%;
  background: #333;
  white-space: pre;
  word-wrap: break-word;
  overflow: auto;
}

pre.code {
  border-radius: 4px;
  border: 1px solid #292929;
  position: relative;
}

pre.code label {
  font-family: sans-serif;
  font-weight: bold;
  font-size: 13px;
  color: #ddd;
  position: absolute;
  left: 1px;
  top: 15px;
  text-align: center;
  width: 60px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  pointer-events: none;
}

pre.code code {
  font-family: "Inconsolata","Monaco","Consolas","Andale Mono","Bitstream Vera Sans Mono","Courier New",Courier,monospace;
  display: block;
  margin: 0 0 0 60px;
  padding: 15px 16px 14px;
  border-left: 1px solid #555;
  overflow-x: auto;
  font-size: 13px;
  line-height: 19px;
  color: #ddd;
}

pre.code-json code {
  color: #91a7ff;
}

pre.code-html code {
  color: #daa96a;
}

.plain-text {
  color: #fff;
  font-size: 13px;
  font-family: "Inconsolata","Monaco","Consolas","Andale Mono","Bitstream Vera Sans Mono","Courier New",Courier,monospace;
}

.plain-text-layout {
  padding: 15px;
  background: #333;
  border-radius: 4px;
  border: 1px solid #292929;
}
</style>
