import { render, staticRenderFns } from "./TaskDates.vue?vue&type=template&id=c18b6b14&scoped=true&"
import script from "./TaskDates.vue?vue&type=script&lang=js&"
export * from "./TaskDates.vue?vue&type=script&lang=js&"
import style0 from "./TaskDates.vue?vue&type=style&index=0&id=c18b6b14&scoped=true&lang=postcss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c18b6b14",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VIcon,VTooltip})
