<template>
  <v-flex xs12 sm12 md12 lg12>
    <v-card-title class="pa-0 mb-4 mt-2">{{ item.houseStreet }}
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="item.properties"
      :loading="loading"
      :options.sync="options"
      :footer-props="{itemsPerPageOptions:itemsPerPageOptions, itemsPerPageText: itemsPerPageText}"
      item-key="addressId"
      class="elevation-1 mr-4 mb-2"
    >
      <template v-slot:[`item.currentTenantText`]="{ item }">
        <a v-if="item.currentTenant" href="#" @click.prevent="setSelectedCurrentTenant(item.currentTenant)">
          {{ item.currentTenantText }}
        </a>
        <span v-else>
          {{ item.currentTenantText }}
        </span>
      </template>
      <template v-slot:[`item.newTenantText`]="{ item }">
        <a v-if="item.newTenant" href="#" @click.prevent="setSelectedNewTenant(item.newTenant)">
          {{ item.newTenantText }}
        </a>
        <span v-else>
          {{ item.newTenantText }}
        </span>
      </template>
      <template v-slot:[`item.newTenantContractStart`]="{ item }">
        {{ item.newTenantContractStartFormatted }}
      </template>
      <template v-slot:[`item.currentTenantContractStart`]="{ item }">
        {{ item.currentTenantContractStartFormatted }}
      </template>
      <template v-slot:[`item.currentTenantContractEnd`]="{ item }">
        {{ item.currentTenantContractEndFormatted }}
      </template>
      <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
    </v-data-table>
    <ObjectDialog :show-dialog="showDialog" @closeDialog=" showDialog = false" :currentTenant="selectedTenant" :title="title" />
  </v-flex>
</template>

<script>
import ObjectDialog from './dialog/ObjectDialog'

export default {
  name: 'TaskList',
  components: {
    ObjectDialog
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    propertyId () {
      this.options.page = 1
    }
  },
  data () {
    return {
      showDialog: false,
      selectedTenant: {},
      title: '',
      options: {
        page: 1
      }
    }
  },
  computed: {
    headers () {
      return [
        {
          text: this.$t('common.objectFields.floor'),
          align: 'left',
          value: 'floorText',
          sortable: true
        },
        {
          text: this.$t('tasks.tenant.object'),
          align: 'left',
          value: 'objectText',
          sortable: true
        },
        {
          text: this.$t('tasks.tenant.currentTenant'),
          align: 'left',
          value: 'currentTenantText'
        },
        {
          text: this.$t('tasks.tenant.currentTenantContractStart'),
          align: 'left',
          value: 'currentTenantContractStart',
          sortable: true
        },
        {
          text: this.$t('tasks.tenant.currentTenantContractEnd'),
          align: 'left',
          value: 'currentTenantContractEnd',
          sortable: true
        },
        {
          text: this.$t('tasks.tenant.newTenant'),
          align: 'left',
          value: 'newTenantText'
        },
        {
          text: this.$t('tasks.tenant.newTenantContractStart'),
          align: 'left',
          value: 'newTenantContractStart',
          sortable: true
        }
      ]
    },
    itemsPerPageText () {
      return this.$t('common.itemsPerPageText')
    },
    itemsPerPageOptions () {
      return [
        10,
        25,
        {
          'text': this.$t('common.all'),
          'value': -1
        }
      ]
    }
  },
  methods: {
    setSelectedCurrentTenant (currentTenant) {
      this.selectedTenant = currentTenant
      this.showDialog = true
      this.title = this.$t('tasks.tenant.currentTenant')
    },
    setSelectedNewTenant (newTenant) {
      this.selectedTenant = newTenant
      this.showDialog = true
      this.title = this.$t('tasks.tenant.newTenant')
    }
  }
}
</script>
<style scoped lang="postcss">
/deep/ tr:nth-child(odd) {
  background: #f8f8f8;
}
</style>
