<template>
  <v-card class="pa-2" outlined tile>
    <v-card-title>
      {{ $t('renting.abacus.rentalContractIdTitle') }} {{ rentalCase.rentalContractId }}
      <v-chip class="ma-2 cy-rental-form-status-enabled" color="green" text-color="white" v-if="rentalCase.acceptSubmit">
        {{ $t('renting.abacus.formEnabled') }}
      </v-chip>
      <v-chip v-else class="ma-2 cy-rental-form-status-disabled" color="red" text-color="white">
        {{ $t('renting.abacus.formDisabled') }}
      </v-chip>
    </v-card-title>
    <v-card-text v-if="rentalCase.acceptSubmit">
      <v-layout row wrap class="px-3">
        <v-flex>
          <a class="cy-rental-form-link" :href="rentalFormUrl" target="_blank">{{ rentalFormUrl }}</a><br/>
          {{ $t('renting.abacus.token') }}: {{ rentalCase.token }}<br/>
          <ProcessRelettingPdf
            :propertyInfo="propertyInfo"
            :objectFloorInfo="objectInfo"
            :token="rentalCase.token"
          />
        </v-flex>
        <v-flex>
          <QRCode :content="rentalFormUrl"/>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import QRCode from '@/components/QRCode'
import ProcessRelettingPdf from '../../ticketing/shared/process-specific-context/process-reletting/ProcessRelettingPdf'

export default {
  name: 'AbacusRentalCaseStatus',
  components: {
    ProcessRelettingPdf,
    QRCode
  },
  props: {
    rentalCase: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    rentalFormUrl () {
      return window.location.protocol + '//' + window.location.host + '/renting/form/' + this.rentalCase?.token
    },
    propertyInfo () {
      return this.rentalCase?.propertyObject?.houseStreet + ', ' + this.rentalCase?.propertyObject?.property?.zip + ' ' + this.rentalCase?.propertyObject?.property?.place
    },
    objectInfo () {
      return this.rentalCase?.propertyObject?.floorText + ' - ' + this.rentalCase?.propertyObject?.objectText
    }
  }
}
</script>

<style scoped lang="postcss">
</style>
