<template>
  <v-list-item class="pl-0 pr-0">
    <v-list-item-content class="pa-0">
      <v-card-text>
        <p class="pa-0 ma-0 property-link" v-if="orderId">
          [<a @click="onOrderClicked(orderId)">{{ orderId }}</a>] <span v-if="order">{{ order.createdOnFormatted }}</span>
        </p>
        <div v-if="order" class="mb-0 truncate-two-lines">{{ order.jobDescription }}</div>
        <div v-if="order">
          {{ $t('tasks.craftsmen.title') }}: {{ order.craftsmanInfo }}<br />
          {{ $t('tasks.tenant.title') }}: {{ order.tenantInfo }}
        </div>
      </v-card-text>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { formatDate } from '@/helpers/formatterHelper'

export default {
  name: 'OrderLink',
  components: {
  },
  props: {
    orderId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      order: null
    }
  },
  watch: {
    orderId: {
      handler: 'loadOrder',
      immediate: true
    }
  },
  computed: {
    ...mapState('abacus', ['propertiesDetails']),
    icon () {
      return this.isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
    }
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    loadOrder () {
      let that = this
      console.log('load', this.orderId)
      this.$prestigeTask.getTaskById(this.orderId).then(function (response) {
        that.order = response.data
        that.order.craftsmanInfo = (that.order.craftsman?.firstName ?? '') + ' ' + (that.order.craftsman?.lastName ?? '')
        that.order.tenantInfo = that.getTenantInfo(that.order)
        that.order.createdOnFormatted = formatDate(that.order.createdOn)
      }).catch(function (error) {
        that.setErrorSnackbar(error)
      })
    },
    getTenantInfo (order) {
      if (order.tenant === 'CURRENT') {
        return (order.currentTenant?.firstName ?? '') + ' ' + (order.currentTenant?.lastName ?? '')
      } else if (order.tenant === 'NEW') {
        return (order.newTenant?.firstName ?? '') + ' ' + (order.newTenant?.lastName ?? '')
      } else if (order.tenant === 'MANUAL') {
        return (order.manualTenant?.firstName ?? '') + ' ' + (order.manualTenant?.lastName ?? '')
      } else {
        return ''
      }
    },
    onOrderClicked (orderId) {
      window.open('/tasks/detail/' + orderId, false)
    }
  }
}
</script>

<style scoped lang="postcss">

.truncate-two-lines {
  display: block;
  display: -webkit-box;
  white-space: pre;
  line-height: 1.5;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
