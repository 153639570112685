<template>
  <v-dialog
    :value="true"
    scrollable
    max-width="1250px"
    @keydown.esc="closeDialog"
    @click:outside="closeDialog"
  >
    <v-card class="pa-4">
      <v-card-title class="pa-2">
        <span class="headline">{{ isEmailEmpty() ? $t('ticketingSystem.messages.email.newDialogTitle'): $t('ticketingSystem.messages.email.replyDialogTitle') }}</span>
        <v-btn icon absolute right>
          <v-icon class="clear" color="primary" :size="28" @click.native="closeDialog"/>
        </v-btn>
      </v-card-title>
      <v-card-text class="px-2 py-0">
        <v-form ref="form" v-model="valid">
          <v-layout>
            <v-flex xs12 sm12 md12 lg7 xl7>
              <ComboboxChips
                v-model="form.to"
                :label="$t('ticketingSystem.messages.email.to')"
                :items="emailRecipients"
                :selected="form.to"
              />
              <ComboboxChips
                v-model="form.cc"
                :label="$t('ticketingSystem.messages.email.cc')"
                :items="emailRecipients"
                :selected="form.cc"
              />
              <ComboboxChips
                v-model="form.bcc"
                :label="$t('ticketingSystem.messages.email.bcc')"
                :items="emailRecipients"
                :selected="form.bcc"
              />
              <v-text-field
                v-model="form.subject"
                :label="$t('ticketingSystem.messages.email.subject')"
                required
                :rules="[rules.required]"
              >
                <template #message="{ message }">
                  <span v-if="message === 'required'">{{ requiredMessage }}</span>
                </template>
              </v-text-field>
              <TipTap @editorValid="editorValid" v-model="form.body"/>
              <span class="red--text" v-if="editorIsEmpty">{{ requiredMessage }}</span>
            </v-flex>
            <v-flex xs12 sm12 md12 lg5 xl5>
              <FileAttachments
                ref="attachments"
                module-name="emailDialog"
                :files="files"
                :show-info-alert="false"
                :processInstanceId="processInstanceId"
                :process-variables="processVariables"
                @addFile="onAddFile"
                @removeFile="onRemoveFile"
                @updateAttachmentIds="onUpdateAttachmentIds"
              />
            </v-flex>
          </v-layout>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="closeDialog">{{ $t('common.buttons.cancel') }}</v-btn>
        <v-btn color="success" :disabled="!valid" :loading="processingRequest" @click="sendEmail">
          <span>{{ $t('ticketingSystem.messages.email.send') }}</span>
          <v-icon class="ml-2">send</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FileAttachments from '../../../shared/attachments/FileAttachments'
import ComboboxChips from '../../../../components/ComboboxChips'
import { mapActions } from 'vuex'
import { AuthenticationContext } from 'vue-adal'
import { formatDateAndTime } from '@/helpers/formatterHelper'
import _isEmpty from 'lodash.isempty'
import TipTap from './TipTap'

export default {
  name: 'EmailDialog',
  props: {
    processInstanceId: {
      type: String
    },
    processVariables: {
      type: Array,
      default: () => []
    },
    email: {
      type: Object
    },
    emailRecipients: {
      type: Array,
      default: () => []
    }
  },
  components: {
    FileAttachments,
    ComboboxChips,
    TipTap
  },
  data () {
    return {
      valid: false,
      editorIsEmpty: false,
      files: [],
      form: {},
      processingRequest: false,
      rules: {
        required: (value) => !!value || this.requiredMessage
      }
    }
  },
  created () {
    this.files = []
    this.initEmail()
  },
  computed: {
    userName () {
      return AuthenticationContext && AuthenticationContext.user && AuthenticationContext.user.profile ? AuthenticationContext.user.profile.name : ''
    },
    requiredMessage () {
      return this.$t('common.errors.required')
    },
    hasSubject () {
      return this.form.subject && this.form.subject !== ''
    },
    hasRecipient () {
      return (this.form.to && this.form.to.length > 0) || (this.form.cc && this.form.cc.length > 0) || (this.form.bcc && this.form.bcc.length > 0)
    },
    enableSend () {
      return this.hasSubject && !this.editorIsEmpty && this.hasRecipient
    }
  },
  updated () {
    let invalidEmails = document.getElementsByClassName('v-chip--removable red')
    this.valid = invalidEmails.length === 0 && this.enableSend
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar', 'setSuccessSnackbar']),
    formatDateAndTimeFunc: formatDateAndTime,
    initEmail () {
      this.form = { to: [], cc: [], bcc: [], subject: '' }
      if (this.email.id) {
        this.form.to.push(this.email.from)
        this.form.cc = this.email.cc
        this.form.bcc = this.email.bcc
        this.form.subject = 'Re: ' + this.email.subject
        this.form.body = {
          editorHtml: this.formatEmailMessage(this.email)
        }
      } else {
        this.form.body = {
          editorHtml: this.formatEmailSignature()
        }
      }
      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    formatEmailMessage (email) {
      const dateAndTime = this.formatDateAndTimeFunc(email.createdOn).split(' ')
      const formattedMessage = this.$t('ticketingSystem.messages.email.on') +
        ' ' + dateAndTime[0] + ' ' + this.$t('ticketingSystem.messages.email.at') + ' ' + dateAndTime[1] +
        ' ' + this.email.from + ' ' + this.$t('ticketingSystem.messages.email.wrote') + ':\n'
      return `${this.formatEmailSignature()} ${formattedMessage} <blockquote>${email.messageHtml}</blockquote>`
    },
    formatEmailSignature () {
      return `
      <br>
      <br>
      Freundliche Grüsse
      <br>
      ${this.userName}
      <br><br>
      <span style="color: #76226c">
        Prestige Immobilien AG
        <br>
        Berninastrasse 29 · 8042 Zürich
        <br>
        +41 44 316 15 15
        <br>
        <a target="_blank" href="https://www.prestige.ch">www.prestige.ch</a>
        <br><br>
      </span>`
    },
    sendEmail () {
      let that = this
      let email = {
        to: this.form.to,
        cc: this.form.cc,
        bcc: this.form.bcc,
        subject: this.form.subject,
        messageHtml: this.form.body.editorHtml,
        messagePlain: this.form.body.editorHtml,
        azureFileAttachments: this.form.azureFileAttachments
      }
      if (!this.isEmailEmpty()) {
        email.inReplyTo = this.email.emailMessageId
      }
      that.processingRequest = true
      this.$prestigeTSProcess.sendEmail(this.processInstanceId, email).then(function () {
        that.setSuccessSnackbar(that.$t('ticketingSystem.messages.email.successfullySent'))
        that.closeDialog()
        that.processingRequest = false
        that.$emit('emailSent')
        if (email?.azureFileAttachments?.length > 0) {
          that.$emit('emailFileSent')
        }
        return true
      }).catch(error => {
        that.setErrorSnackbar(error)
        that.processingRequest = false
      })
    },
    isEmailEmpty () {
      return _isEmpty(this.email)
    },
    closeDialog () {
      this.$emit('closeDialog')
    },
    onAddFile (file) {
      if (!this.form.azureFileAttachments) {
        this.files = [file]
        Object.assign(this.form, { azureFileAttachments: [file.fileId] })
      } else {
        this.files.push(file)
        this.form.azureFileAttachments.push(file.fileId)
      }
    },
    onRemoveFile (index) {
      this.files.splice(index, 1)
      this.form.azureFileAttachments.splice(index, 1)
    },
    editorValid (value) {
      this.editorIsEmpty = value
    },
    onUpdateAttachmentIds (attachmentId, addInEmail) {
      if (!this.form.azureFileAttachments) {
        Object.assign(this.form, { azureFileAttachments: [] })
      }
      if (addInEmail) {
        if (this.form.azureFileAttachments.findIndex(x => x === attachmentId) === -1) {
          this.form.azureFileAttachments.push(attachmentId)
        }
      } else {
        this.form.azureFileAttachments = this.form.azureFileAttachments.filter(x => x !== attachmentId)
      }
    }
  }
}
</script>

<style scoped lang="postcss">
/deep/ .diagram-container {
  height: 400px;
}
/deep/ .v-dialog--fullscreen .diagram-container {
  height: 80vh;
}
/deep/ .attachments {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  margin: 0 0 0 30px;
  max-height: 400px;
  overflow: auto;
}
/deep/ .attachments > .v-card__title, /deep/ .uploader-action {
  padding: 0px !important;
}
/deep/ .dz-message {
  padding: 15px !important;
}
</style>
