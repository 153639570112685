import { ApolloClient } from 'apollo-client'
import { setContext } from 'apollo-link-context'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import VueApollo from 'vue-apollo'
import { authenticate } from '@/backend/auth'

const getAuthLink = function(adal, appInsights) {
  return setContext(async (_, { headers }) => {
    const token = await authenticate(adal)
    const user = adal?.user?.profile?.employeeid
    if (user && appInsights) {
      appInsights.context.user.id = user
      appInsights.setAuthenticatedUserContext(user)
    }
    return {
      headers: {
        ...headers,
        authorization: token ? 'Bearer ' + token : ''
      }
    };
  })
}

const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: '/graphql'
})

export const createApolloProvider = function(adal, appInsights) {
  return new VueApollo({
    defaultClient: new ApolloClient({
      link: getAuthLink(adal, appInsights).concat(httpLink),
      cache: new InMemoryCache(),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'no-cache'
        },
        query: {
          fetchPolicy: 'no-cache'
        }
      }
    })
  })
}

