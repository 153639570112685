import MapProperties from '../../map/MapProperties'
import MapTerminations from '../../map/MapTerminations'

export const MapRoutes = [
  {
    path: '/map/properties/:propertyId?',
    name: 'MapProperties',
    component: MapProperties,
    meta: {
      title: 'properties',
      requireAuth: true,
      requireRoles: [
        'prestige-tools-reader'
      ]
    }
  },
  {
    path: '/map/terminations',
    name: 'MapTerminations',
    component: MapTerminations,
    meta: {
      title: 'terminations',
      requireAuth: true,
      requireRoles: [
        'prestige-tools-reader'
      ]
    }
  }
]
