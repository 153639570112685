<template>
  <v-card class="mb-2 pa-0">
    <v-card-text class="pa-4">
      <v-icon color="primary" @click="toggle" class="float-right">{{ icon }}</v-icon>
      <v-card-subtitle class="pa-0 font-weight-bold subtitle-1">
        {{ title }}
      </v-card-subtitle>
    </v-card-text>
    <v-divider></v-divider>
    <v-layout v-if="isOpen" row wrap class="ma-0">
      <template v-for="(item, index) in suggestions">
        <v-list-item class="pl-0 pr-0" :key="index" @click="addLinkFromSuggestion(item)">
          <v-list-item-content class="pa-0">
            <v-card-text>
              <p class="pa-0 ma-0" >
                <span>{{ info(item) }}</span>
              </p>
            </v-card-text>
          </v-list-item-content>
        </v-list-item>
        <v-divider :key="'divider_' + index"></v-divider>
      </template>
    </v-layout>
  </v-card>
</template>
<script>

export default {
  name: 'SuggestionsAddress',
  components: {
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    suggestions: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      isOpen: true
    }
  },
  computed: {
    icon () {
      return this.isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
    }
  },
  methods: {
    toggle () {
      this.isOpen = !this.isOpen
    },
    info (item) {
      return '[' + item.id + '] ' + item.firstName + (item.firstName ? ' ' : '') + item.lastName
    },
    addLinkFromSuggestion (item) {
      this.$emit('addLinkFromSuggestion', {
        linkName: this.name,
        linkValues: [item.id.toString()]
      })
    }
  }
}
</script>
<style scoped lang="postcss">
</style>
