<template>
  <v-dialog v-model="mutableShowDialog" scrollable max-width="2000px" @keydown.esc="closeDialog" persistent>
    <v-card>
      <div class="d-flex justify-space-between pa-2">
        <v-card-text class="d-flex dialog-title pa-0">
          <v-card-title v-if="taskDetailsProcess" id="taskDetailsDialog" class="d-flex align-center truncate-one-line pa-2 pr-0">
            {{ title }}
            &nbsp;&middot;&nbsp;
            <span class="cy-task-dialog-subject">{{ taskProcessSubject }}</span>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon class="ma-2" size="18" color="blue" @click="openProcessSubjectDiagram">edit</v-icon>
                </v-btn>
              </template>
              <span>{{$t('ticketingSystem.tooltipEditSubject')}}</span>
            </v-tooltip>
          </v-card-title>
          <v-card-title class="py-2 px-0">
            &nbsp;&middot;&nbsp;
            <TicketPriority
              v-model="taskPriority"
              :read-only="true"
            />
            {{ taskProcessName }}
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon class="ma-2" size="18" color="blue" @click="openEditTaskDialog">edit</v-icon>
                </v-btn>
              </template>
              <span>{{$t('ticketingSystem.tooltipEditTask')}}</span>
            </v-tooltip>
          </v-card-title>
        </v-card-text>
        <v-btn icon>
          <v-icon class="clear" color="primary" :size="28" @click.native="closeDialog"/>
        </v-btn>
      </div>
      <v-card-text class="ticket-main pa-2">
        <v-container v-show="!taskLoadError" class="column pa-2">
          <ProcessInfo
            v-if="taskDetailsProcess"
            :process="taskDetailsProcess"
            @loadProcess="loadTaskDetails"
            @openTaskDetailsDialog="openTaskDetailsDialog"
            @startRelatedProcess="startRelatedProcess"
          />
          <v-layout wrap>
            <v-flex xs12 sm12 md12 lg8 xl8 class="padding">
              <TaskAdvancedInfo
                ref="advancedInfo"
                :ticketing-task="ticketingTask"
                :logged-in-user-id="loggedInUserId"
                :assigned-to-me="assignedToMe"
                :has-permission-for-task="hasPermissionForTask"
                :additional-links="processReferencesLinks"
                :finished-loading="!isLoading"
                :checklist-enabled="!isAbacusControlledTicket"
                @loadTaskDetails="loadTaskDetails"
                @loadProcessReferences="loadProcessReferences"
                @closeDialog="closeDialog"
                @autocompleteLinksUpdated="onAutocompleteLinksUpdated"
                @reloadTasks="reloadTasks"
                @saveStarted="startLoading"
                @saveFinished="stopLoading"
              />
            </v-flex>
            <v-flex id="processReferences" class="mt-2 process-column" xs12 sm12 md12 lg4 xl4>
              <ProcessInstanceContext
                :process="taskDetailsProcess"
                :process-variables="processVariables"
                :process-references="processReferences"
                :process-references-links="processReferencesLinks"
                :created-on="startedOn"
                @loadDetails="loadTaskDetails"
                @deleteLinkFromAutocompleteLinks="onDeleteLinkFromAutocompleteLinks"
                @loadProcessReferences="loadProcessReferences"
                @loadProcessVariables="loadProcessVariables"
              >
              </ProcessInstanceContext>
            </v-flex>
          </v-layout>
          <TicketDialog
            v-if="shouldShowNewTicketDialog"
            :start-related-process="true"
            :process-instance-id="processInstanceId"
            :new-links="processReferencesLinks"
            @closeDialog="shouldShowNewTicketDialog = false"
            @loadProcessReferences="loadProcessReferences"
            @loadProcessVariables="loadProcessVariables"
          />
        </v-container>
        <v-flex v-if="taskLoadError">
          <v-alert class="ma-2" :value="true" color="error" icon="warning">
            {{ $t('ticketingSystem.taskDetails.taskDoesNotExist') }}
          </v-alert>
        </v-flex>
      </v-card-text>

      <SpinnerOverlay :loading="isLoading" />
      <div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click.native="closeDialog" class="mr-2" :x-small="smAndDown">{{ $t('common.buttons.close') }}</v-btn>
          <TaskActionButtons
            v-if="!taskFinished && mutableShowDialog && !taskLoadError"
            :disable-add-and-complete-btns="disableAddAndCompleteBtns"
            :has-active-child-task="hasActiveChildTask"
            @saveTaskVariables="onSaveTaskVariables"
            @completeTask="completeTask"
          />
        </v-card-actions>
        <div class="float-right mr-2" v-if="hasActiveChildTask">{{ $t('ticketingSystem.taskDetails.completeCheckListItems') }}</div>
        <div class="float-right mr-2" v-if="isAbacusControlledTicket">{{ $t('ticketingSystem.taskDetails.completeAbacusControlledTicket') }}</div>
      </div>
    </v-card>
    <ProcessSubjectDialog
      :process="taskDetailsProcess"
      ref="processSubjectDiagram"
      :show-dialog="showProcessSubjectDialog"
      @closeDialog="showProcessSubjectDialog = false"
      @loadProcess="loadTaskDetails"
    />
    <EditTaskDialog
      v-if="shouldShowChildDialog"
      :item="ticketingTask"
      @closeDialog="shouldShowChildDialog = false"
      @loadTaskDetails="loadTaskDetails"
    />
  </v-dialog>
</template>

<script>
import TaskActionButtons from '../task-details/TaskActionButtons.vue'
import ProcessInfo from '../shared/ProcessInfo'
import TaskAdvancedInfo from '../task-details/TaskAdvancedInfo'
import ProcessInstanceContext from '../shared/ProcessInstanceContext'
import TicketDialog from './TicketDialog'
import SpinnerOverlay from '@/components/SpinnerOverlay'
import TicketPriority from '@/features/ticketing/components/TicketPriority'
import ProcessSubjectDialog from '../shared/ProcessSubjectDialog'
import EditTaskDialog from '../task-details/EditTaskDialog'
import { appendLinks } from './../helpers/autocompleteHelper'

import { mapActions, mapState } from 'vuex'
import { AuthenticationContext } from 'vue-adal'
import _cloneDeep from 'lodash.clonedeep'
import _isEqual from 'lodash.isequal'
import _isEmpty from 'lodash.isempty'
import Vue from 'vue'
import { TICKETING_TASK_LIST } from '@/config/options/routes/ticketingRoutes'
import { TICKETING_TASK } from '../graphql-apollo/ticketingTask.gql'

export default {
  name: 'TaskDetailsDialog',
  props: {
    showDialog: {
      type: Boolean,
      default: false
    }
  },
  components: {
    TaskActionButtons,
    SpinnerOverlay,
    ProcessInfo,
    TaskAdvancedInfo,
    ProcessInstanceContext,
    TicketDialog,
    ProcessSubjectDialog,
    EditTaskDialog,
    TicketPriority
  },
  data () {
    return {
      spinnerOverlay: false,
      autoCompleteLinks: [],
      taskDoesNotExist: false,
      processReferences: {},
      processVariables: [],
      shouldShowNewTicketDialog: false,
      taskLoadError: '',
      showProcessSubjectDialog: false,
      shouldShowChildDialog: false
    }
  },
  watch: {
    routeTaskId: {
      handler: 'loadTaskDetails',
      immediate: true
    },
    processInstanceId: {
      handler: 'loadProcessContext',
      immediate: true
    }
  },
  apollo: {
    ticketingTask: {
      query: TICKETING_TASK,
      skip () {
        return !this.routeTaskId
      },
      error (error) {
        this.taskLoadError = error.message
      },
      variables () {
        return {
          taskId: this.routeTaskId
        }
      }
    }
  },
  computed: {
    ...mapState('ticketingSystem', ['ticketingUsers']),
    smAndDown () {
      return this.$vuetify.breakpoint.smAndDown
    },
    loggedInUser () {
      return AuthenticationContext && AuthenticationContext.user && AuthenticationContext.user.profile ? AuthenticationContext.user.profile : null
    },
    loggedInUserId () {
      let loggedInUserProfile = this.loggedInUser
      return loggedInUserProfile ? loggedInUserProfile.employeeid : null
    },
    assignedToMe () {
      return this.loggedInUserId === this.ticketingTask?.assignee?.userId
    },
    hasAssignedUser () {
      return this.ticketingTask && this.ticketingTask.assignee !== null
    },
    hasPermissionForTask () {
      let candidateGroups = this.ticketingTask && this.ticketingTask.candidateGroups ? this.ticketingTask.candidateGroups : []
      if (candidateGroups.length === 0) {
        return true
      } else if (candidateGroups.length > 0 && this.loggedInUser && this.loggedInUser.roles) {
        let roles = this.loggedInUser.roles
        return candidateGroups.map(cq => cq.id).some(r => roles.includes(r))
      } else {
        return false
      }
    },
    isAbacusControlledTicket () {
      return this.taskDetailsProcess?.processDefinitionKey === 'Z1_10'
    },
    disableAddAndCompleteBtns () {
      return !this.hasPermissionForTask || (!this.assignedToMe && this.hasAssignedUser) || this.isAbacusControlledTicket
    },
    taskDetailsProcess () {
      return this.ticketingTask?.ticket
    },
    processInstanceId () {
      return this.ticketingTask?.ticket?.ticketId
    },
    taskFinished () {
      return !_isEmpty(this.ticketingTask) && this.ticketingTask.finishedOn !== '' && this.ticketingTask.finishedOn !== null
    },
    hasActiveChildTask () {
      let checklistItems = this.ticketingTask?.checklistItems
      if (checklistItems != null && checklistItems.length > 0) {
        return checklistItems.some(childTask => childTask.finishedOn === '' || childTask.finishedOn === null)
      }
      return false
    },
    routeTaskId () {
      return this.$route.query.taskId
    },
    title () {
      return this.taskDetailsProcess.processDefinitionName
    },
    taskProcessName () {
      return this.ticketingTask?.name ? this.ticketingTask.name : ''
    },
    taskProcessSubject () {
      return this.taskDetailsProcess?.subject ?? ''
    },
    taskPriority () {
      return this.ticketingTask?.priority
    },
    processReferencesLinks () {
      // base is initial list of links
      let processReferencesLinks = _cloneDeep(this.processReferences.links)

      // add additional links from auto completes
      this.autoCompleteLinks.forEach(autoCompleteLinkList => {
        if (autoCompleteLinkList.links && autoCompleteLinkList.links.length > 0) {
          autoCompleteLinkList.links.forEach(autoCompleteLinks => {
            appendLinks(processReferencesLinks, autoCompleteLinks)
          })
        }
      })
      return processReferencesLinks || []
    },
    isLoading () {
      return this.spinnerOverlay || this.$apollo.loading
    },
    mutableShowDialog () {
      return this.showDialog
    },
    startedOn () {
      return this.taskDetailsProcess?.startedOn || ''
    }
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    startLoading () {
      this.spinnerOverlay = true
    },
    stopLoading () {
      this.spinnerOverlay = false
    },
    loadTaskDetails () {
      if (this.routeTaskId) {
        if (this.$apollo.queries.ticketingTask) {
          this.taskLoadError = null
          this.$apollo.queries.ticketingTask.refresh()
        }
        this.loadProcessVariables()
      }
    },
    goBackToTicketingList () {
      this.$router.push({ name: TICKETING_TASK_LIST })
    },
    onSaveTaskVariables () {
      this.$refs.advancedInfo.saveTaskVariables(this.processReferencesLinks)
    },
    completeTask () {
      this.$refs.advancedInfo.completeTask(this.processReferencesLinks)
    },
    loadProcessContext () {
      // TODO remove, once we can load this data with graphql
      this.loadProcessReferences()
      if (this.processVariables.length === 0) {
        this.loadProcessVariables()
      }
    },
    loadProcessReferences () {
      // TODO remove, once we can load this data with graphql
      let that = this
      if (this.processInstanceId) {
        this.$prestigeTSProcess.getProcessReferences(this.processInstanceId).then(function (response) {
          if (response.data) {
            that.processReferences = response.data
          }
          return true
        }).catch(error => {
          that.setErrorSnackbar(error)
        })
      }
    },
    loadProcessVariables () {
      // TODO remove, once we can load this data with graphql
      let that = this
      if (this.processInstanceId) {
        return this.$prestigeTSProcess.getVariablesForProcess(this.processInstanceId).then(function (response) {
          that.processVariables = response.data
        }).catch(error => {
          that.setErrorSnackbar(error)
        })
      }
    },
    startRelatedProcess () {
      this.shouldShowNewTicketDialog = true
    },
    closeDialog () {
      this.$emit('closeDialog')
    },
    async openTaskDetailsDialog (taskId, taskFinished) {
      this.$emit('openTaskDetailsDialog', taskId, taskFinished)
    },
    onAutocompleteLinksUpdated (autocompleteLinks) {
      const index = this.autoCompleteLinks.findIndex(x => x.name === autocompleteLinks.name)
      if (index !== -1) {
        const isEqual = _isEqual(autocompleteLinks, this.autoCompleteLinks[index])
        if (!isEqual) {
          Vue.set(this.autoCompleteLinks, index, autocompleteLinks)
        }
      } else if (autocompleteLinks && autocompleteLinks.links && autocompleteLinks.links.length > 0) {
        Vue.set(this.autoCompleteLinks, this.autoCompleteLinks.length, autocompleteLinks)
      }
    },
    reloadTasks () {
      this.$emit('reloadTasks')
    },
    onDeleteLinkFromAutocompleteLinks (link) {
      let clonedAutoCompleteLinks = this.autoCompleteLinks
      let index = clonedAutoCompleteLinks[0].links.findIndex(x => x.linkName.toString() === link.linkName.toString())
      if (index !== -1) {
        clonedAutoCompleteLinks[0].links[index].linkValues = clonedAutoCompleteLinks[0].links[index].linkValues.filter(x => x.toString() !== link.linkValue.toString())
        if (clonedAutoCompleteLinks[0].links[index].linkValues.length === 0) {
          clonedAutoCompleteLinks[0].links.splice(index, 1)
        }
      }
      Vue.set(this, 'autoCompleteLinks', clonedAutoCompleteLinks)
    },
    loadProcess () {
      this.$emit('loadProcess')
    },
    openProcessSubjectDiagram () {
      this.showProcessSubjectDialog = true
    },
    openEditTaskDialog () {
      this.shouldShowChildDialog = true
    },
    onLoadTaskDetails () {
      this.$emit('loadTaskDetails')
    }
  }
}
</script>

<style scoped lang="postcss">
@media (min-width: 1264px) {
  .padding {
    padding-right: 8px;
  }
}
.ticket-main {
  overflow-y: auto;
  background-color: rgb(211, 211, 211, 0.2);
}
.column {
  max-width: 100%;
}
.truncate-one-line {
  display: -webkit-inline-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dialog-title {
  color: rgba(0,0,0,.6);
}
</style>
