export const taskTypeOptions = [
  {
    id: 0,
    value: 'TASK'
  },
  {
    id: 1,
    value: 'OFFER'
  }
]

export const insuranceCaseOptions = {
  id: 0,
  value: 'yes'
}
