import TasksHome from '../../tasks/TasksHome'
import TasksList from '../../tasks/TasksList'
import CreateTask from '../../tasks/CreateTask'
import TaskDetail from '../../tasks/TaskDetail'

export const TaskRoutes = [
  {
    path: '/tasks',
    name: 'TasksHome',
    component: TasksHome,
    meta: {
      title: 'tasksHome',
      requireAuth: true,
      requireRoles: [
        'prestige-tools-reader'
      ]
    }
  },
  {
    path: '/abacus/tasks/list/:propertyId?/:propertyObjectId?',
    name: 'TasksList',
    component: TasksList,
    meta: {
      abacus: true,
      full: true,
      requireAuth: true,
      requireRoles: [
        'prestige-tools-writer'
      ],
      newTab: true
    }
  },
  {
    path: '/abacus/tasks/create/:propertyId/:propertyObjectId?',
    name: 'TaskCreate',
    component: CreateTask,
    meta: {
      full: true,
      requireAuth: true,
      requireRoles: [
        'prestige-tools-writer'
      ]
    }
  },
  {
    path: '/tasks/detail/:taskId',
    name: 'TaskDetails',
    component: TaskDetail,
    meta: {
      title: 'taskDetail',
      requireAuth: true,
      requireRoles: [
        'prestige-tools-reader'
      ]
    }
  },
  {
    path: '/abacus/tasks/detail/:taskId',
    name: 'TaskDetailsFullscreen',
    component: TaskDetail,
    meta: {
      abacus: true,
      full: true,
      requireAuth: true,
      requireRoles: [
        'prestige-tools-reader'
      ]
    }
  }
]
