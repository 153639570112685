<template>
  <v-layout wrap>
    <DocumentType :document-types="dossierDocumentTypes" :id="1" :title="$t('navigation.dossier')" @updateDocumentTypes="updateDocumentTypes" />
    <DocumentType :document-types="recipientDocumentTypes" :id="2" :title="$t('dossier.documentTypeConfiguration.sections.recipient.title')" @updateDocumentTypes="updateDocumentTypes" />
    <DocumentType :document-types="propertyDocumentTypes" :id="3" :title="$t('dossier.documentTypeConfiguration.sections.property.title')" @updateDocumentTypes="updateDocumentTypes" />
    <p v-if="changesMade" class="red--text pl-2 pt-2">{{ $t('common.messages.saveChanges') }}</p>
  </v-layout>
</template>

<script>
import DocumentType from './DocumentType.vue'
import { mapActions } from 'vuex'
import _cloneDeep from 'lodash.clonedeep'
import _isEqual from 'lodash.isequal'

export default {
  name: 'DocumentTypeConfiguration',
  components: {
    DocumentType
  },
  data () {
    return {
      initialDocumentTypes: {},
      allDocumentTypes: {},
      dossierDocumentTypes: [],
      propertyDocumentTypes: [],
      recipientDocumentTypes: [],
      changesMade: false
    }
  },
  created () {
    this.fetchDocumentConfigurationTypes()
  },
  watch: {
    allDocumentTypes: {
      handler: function (newVal, oldVal) {
        this.changesMade = !_isEqual(newVal, this.initialDocumentTypes)
      },
      deep: true
    }
  },
  methods: {
    ...mapActions('notifier', ['setSuccessSnackbar', 'setErrorSnackbar']),
    updateDocumentTypes (newDocumentTypes, id) {
      if (newDocumentTypes) {
        if (id === 1) {
          this.dossierDocumentTypes = newDocumentTypes
          this.allDocumentTypes.find(x => x.category === 'DOSSIER').documentTypes = this.dossierDocumentTypes
        } else if (id === 2) {
          this.recipientDocumentTypes = newDocumentTypes
          this.allDocumentTypes.find(x => x.category === 'RECIPIENT').documentTypes = this.recipientDocumentTypes
        } else if (id === 3) {
          this.propertyDocumentTypes = newDocumentTypes
          this.allDocumentTypes.find(x => x.category === 'PROPERTY').documentTypes = this.propertyDocumentTypes
        }
      }
    },
    fetchDocumentConfigurationTypes () {
      let that = this
      this.$prestigeDossierDocument.getDocumentTypes().then(response => {
        that.allDocumentTypes = response.data
        that.initialDocumentTypes = _cloneDeep(that.allDocumentTypes)
        that.dossierDocumentTypes = _cloneDeep(that.allDocumentTypes.find(x => x.category === 'DOSSIER').documentTypes)
        that.recipientDocumentTypes = _cloneDeep(that.allDocumentTypes.find(x => x.category === 'RECIPIENT').documentTypes)
        that.propertyDocumentTypes = _cloneDeep(that.allDocumentTypes.find(x => x.category === 'PROPERTY').documentTypes)
      }).catch(error => {
        that.setErrorSnackbar(error)
      })
    },
    updateDocumentTypeConfiguration () {
      let data = this.allDocumentTypes
      let that = this
      this.$prestigeDossierDocument.updateDocumentTypes(data).then(response => {
        that.changesMade = false
        that.fetchDocumentConfigurationTypes()
        that.setSuccessSnackbar(that.$t('common.messages.successfullyUpdated'))
      }).catch(error => {
        that.setErrorSnackbar(error)
      })
    }
  }
}
</script>

<style scoped>
</style>
