<template>
  <v-container fluid class="grey lighten-5 pa-2">
    <v-card class="pa-2">
      <v-data-table
        :headers="propertyHeaders"
        :items="ambiguousPropertyLocations"
        :loading="propertiesLoading"
        :footer-props="{ itemsPerPageOptions: propertyItemsPerPageOptions, itemsPerPageText: propertyItemsPerPageText }"
      >
        <template v-slot:[`item.propertyInfo`]="{ item }">
          <td>
            {{ item.propertyInfo }}
          </td>
        </template>
        <template v-slot:[`item.location`]="{ item }">
          <td>
            <v-icon v-if="item.location==='AMBIGUOUS'" color="orange">error_outline</v-icon>
            <v-icon v-else-if="item.location==='UNKNOWN'" color="red">close</v-icon>
          </td>
        </template>
        <template slot="no-data">
          <v-alert :value="true" color="error" icon="warning">
            {{ $t('table.noData') }}
          </v-alert>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  name: 'AmbiguousPropertyLocations',
  components: {},
  props: {},
  data () {
    return {
      ambiguousPropertyLocations: [],
      propertiesLoading: false
    }
  },
  computed: {
    propertyHeaders () {
      return [
        {
          text: this.$t('tasks.info.property'),
          align: 'left',
          value: 'propertyInfo'
        },
        {
          text: this.$t('constructionProjects.locationAmbiguous'),
          align: 'left',
          value: 'location'
        }
      ]
    },
    propertyItemsPerPageOptions () {
      return [
        5,
        10,
        15,
        {
          'text': this.$t('common.all'),
          'value': -1
        }
      ]
    },
    propertyItemsPerPageText () {
      return this.$t('common.itemsPerPageText')
    }
  },
  mounted () {
    this.loadAmbiguousPropertyLocations()
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    loadAmbiguousPropertyLocations () {
      this.propertiesLoading = true
      let that = this
      this.$prestigeConstructionProjects.getPropertiesWithAmbiguousLocation().then(function (response) {
        that.ambiguousPropertyLocations = response.data
        that.ambiguousPropertyLocations.forEach(item => {
          item.propertyInfo = '[' + item.propertyId + '] ' + item.street + ', ' + item.zip + ' ' + item.place
        })
        that.propertiesLoading = false
        return true
      }).catch(error => {
        that.propertiesLoading = false
        that.setErrorSnackbar(error)
      })
    }
  }
}
</script>
<style scoped lang="postcss">
</style>
