<template>
  <v-expansion-panel v-if="rentalComponents && rentalComponents.length > 0">
    <v-expansion-panel-header class="pa-2">
      <v-card-subtitle class="pa-0 ma-0 font-weight-bold">
        {{ $t('ticketingSystem.processes.termination.statements.rentalComponents') }}
      </v-card-subtitle>
      <template v-slot:actions>
        <v-icon color="brown lighten-2">mdi-information-outline</v-icon>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="pa-0">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                {{ $t('ticketingSystem.processes.termination.rentalComponentHeaders.component') }}
              </th>
              <th class="text-left">
                {{ $t('ticketingSystem.processes.termination.rentalComponentHeaders.old') }}
              </th>
              <th v-if="hasRentalComponentsNewAmount" class="text-left">
                {{ $t('ticketingSystem.processes.termination.rentalComponentHeaders.new') }}
              </th>
              <th v-if="hasRentalComponentsComment" class="text-left">
                {{ $t('ticketingSystem.processes.termination.rentalComponentHeaders.comment') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in rentalComponents"
              :key="index"
            >
              <td>{{ item.rentalComponent }} {{ formatDateFunc(item.validFrom) }}</td>
              <td>{{ formatAmountFunc(item.amount) }}</td>
              <td v-if="hasRentalComponentsNewAmount">{{ formatAmountFunc(item.newAmount) }}</td>
              <td v-if="hasRentalComponentsComment">{{ item.comment }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { formatDate, formatAmount } from '@/helpers/formatterHelper'

export default {
  props: {
    rentalComponents: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    hasRentalComponentsComment () {
      return this.rentalComponents.findIndex((item) => item.comment) > -1
    },
    hasRentalComponentsNewAmount () {
      return this.rentalComponents.findIndex((item) => item.newAmount) > -1
    }
  },
  methods: {
    formatDateFunc: formatDate,
    formatAmountFunc: formatAmount
  }
}
</script>

<style scoped lang="postcss">
/deep/ .v-expansion-panel-content__wrap {
  padding: 8px;
}
</style>
