import Vue from 'vue'
import Vuex from 'vuex'
import i18n from '@/i18n/i18n.js'

Vue.use(Vuex)

const state = {
  snackbar: {},
  alert: {
    showing: false
  }
}

const actions = {
  setSnackbar ({ commit }, snackbar) {
    snackbar.showing = true
    snackbar.color = snackbar.color || 'success'
    commit('SET_SNACKBAR', snackbar)
  },
  setAlert ({ commit }, alert) {
    alert.showing = true
    commit('SET_ALERT', alert)
  },
  setSuccessSnackbar ({ dispatch }, payload) {
    dispatch('setSnackbar', { text: payload })
  },
  setInfoSnackbar ({ dispatch }, payload) {
    dispatch('setSnackbar', { text: payload, color: 'info' })
  },
  setErrorSnackbar ({ dispatch }, payload) {
    let responseError = false
    let statusHandledError = false
    let payloadIsText = typeof payload === 'string'
    if (payloadIsText) {
      statusHandledError = true
      responseError = payload
    } else if (payload && payload.text) {
      statusHandledError = true
      responseError = payload.text
    } else if (payload && payload.response) {
      let response = payload.response
      let data = response.data
      let status = response.status
      statusHandledError = status === 401 || status === 403 || status === 404 || statusbar === 503
      if (statusHandledError) {
        responseError = i18n.t('common.errors.error' + status)
      } else if (data && data.message) {
        responseError = data.message
      } else if (data && data.error) {
        responseError = data.error
      } else if (data) {
        statusHandledError = true
        responseError = data
      }
    }
    dispatch('setSnackbar', { text: payload, color: 'error', responseError: responseError, statusHandledError: statusHandledError })
  }
}

const mutations = {
  SET_SNACKBAR (state, snackbar) {
    state.snackbar = snackbar
  },
  SET_ALERT (state, alert) {
    state.alert = alert
    window.scrollTo(0, 0)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
