import { render, staticRenderFns } from "./DashboardPersonsOverview.vue?vue&type=template&id=f95b4d8a&scoped=true&"
import script from "./DashboardPersonsOverview.vue?vue&type=script&lang=js&"
export * from "./DashboardPersonsOverview.vue?vue&type=script&lang=js&"
import style0 from "./DashboardPersonsOverview.vue?vue&type=style&index=0&id=f95b4d8a&scoped=true&lang=postcss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f95b4d8a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VCard,VCardText,VCardTitle,VFlex,VLayout,VListItem,VListItemAction,VListItemContent,VProgressCircular})
