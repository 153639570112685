import { render, staticRenderFns } from "./ProcessTermination.vue?vue&type=template&id=3241ef38&scoped=true&"
import script from "./ProcessTermination.vue?vue&type=script&lang=js&"
export * from "./ProcessTermination.vue?vue&type=script&lang=js&"
import style0 from "./ProcessTermination.vue?vue&type=style&index=0&id=3241ef38&scoped=true&lang=postcss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3241ef38",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardSubtitle,VCardText,VDivider,VExpansionPanels,VFlex,VIcon,VLayout})
