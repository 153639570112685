<template>
  <v-list-item v-if="abacusProperty" class="pl-0 pr-0">
    <v-list-item-content class="pa-0">
      <v-card-text>
        <p class="pa-0 ma-0 property-link" v-if="abacusProperty.propertyId">
          [<a @click="onPropertyClicked(abacusProperty.propertyId)">{{ abacusProperty.propertyId }}</a>] {{ abacusProperty.street }}
        </p>
        <p class="pa-0 ma-0" v-if="abacusProperty.zip || abacusProperty.place"> {{ abacusProperty.zip }} {{ abacusProperty.place }}</p>
        <p  class="pa-0 ma-0" v-if="abacusProperty.realEstateManager">{{ $t('common.realEstateManager') }}: <strong>{{ abacusProperty.realEstateManager.firstName + ' ' + abacusProperty.realEstateManager.lastName }}</strong></p>
      </v-card-text>
    </v-list-item-content>
    <v-list-item-action class="ma-3">
      <ActionsMenu
        :item="getItem(abacusProperty)"
        :actions="actions"
        :delete-action="deleteAction"
      />
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import ActionsMenu from '@/components/ActionsMenu'
import { ABACUS_PROPERTY_LINK } from '../../graphql-apollo/abacusProperties.gql'
import { openDashboardProperty } from '@/helpers/routesHelper'
import { usePropertyActions } from '@/use/usePropertyActions'
import { useTask } from '@/use/useTask'

const { propertyActions } = usePropertyActions()
const { onNewTask } = useTask()

export default {
  name: 'ProcessProperty',
  components: {
    ActionsMenu
  },
  props: {
    deleteAction: {
      type: Object,
      default: () => {}
    },
    propertyId: {
      type: String,
      required: true
    },
    processInstanceId: {
      type: String,
      default: ''
    }
  },
  apollo: {
    abacusProperty: {
      query: ABACUS_PROPERTY_LINK,
      variables () {
        return {
          propertyId: parseInt(this.propertyId)
        }
      },
      skip () {
        return !this.propertyId
      },
      fetchPolicy: 'cache-first'
    }
  },
  computed: {
    actions () {
      return this.additionalActions.concat(propertyActions)
    },
    additionalActions () {
      return [
        { title: this.$t('dashboard.actions.newTicket'), icon: 'mdi-ticket', action: this.newPropertyTicket },
        { title: this.$t('dashboard.actions.newTask'), icon: 'mdi-calendar-check', action: onNewTask }
      ]
    }
  },
  methods: {
    getItem (item) {
      return Object.assign(item, { linkName: 'property', linkValue: item.propertyId, processInstanceId: this.processInstanceId })
    },
    onPropertyClicked (propertyId) {
      openDashboardProperty(propertyId)
    },
    newPropertyTicket (item) {
      this.$emit('openNewTicketDialog', item)
    }
  }
}
</script>