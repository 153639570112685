<template>
  <v-layout class="pa-2 ml-2">
    <v-flex>
      <div class="d-flex justify-space-between">
        <v-radio-group row v-model="addressTypeSelection" @change="onChangedAddressType">
          <v-radio
            v-for="(item, i) in personSearchList"
            :key="i"
            class="pr-5 pb-2 mr-0 lighten-3"
            :class="setItemColor(item) + '--text cy-dashboard-person-form-type-' + item"
            :id="'cy-dashboard-person-form-type-' + item"
            :color="setItemColor(item)"
            :label="$t('dashboard.' + item)"
            :value="item"
          />
        </v-radio-group>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon id="cy-dashboard-person-form-clear" class="clear ml-2 mr-1" color="primary" :size="28" @click="clearForm" v-bind="attrs" v-on="on" />
          </template>
          <span>{{ $t('dashboard.clearForm') }}</span>
        </v-tooltip>
      </div>
      <form ref="form">
        <v-row class="pl-1">
          <v-col cols="12" sm="6" md="3" class="custom7cols">
            <v-text-field
              :label="$t('dashboard.form.addressId')"
              type="number"
              min="0"
              :rules="[rules.integerNumber]"
              v-model="form.addressId"
              name="addressId"
              @input="debounceInput"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="3" class="custom7cols">
            <v-text-field
              :label="$t('dashboard.form.name')"
              id="cy-dashboard-person-form-name"
              v-model="form.name"
              @input="debounceInput"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="3" class="custom7cols">
            <v-text-field
              :label="$t('dashboard.form.phone')"
              id="cy-dashboard-person-form-phone"
              v-model="form.phone"
              @input="debounceInput"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="3" class="custom7cols">
            <v-text-field
              :label="$t('dashboard.form.email')"
              v-model="form.email"
              @input="debounceInput"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="3" class="custom7cols">
            <v-text-field
              :label="$t('common.addressFields.street')"
              v-model="form.street"
              @input="debounceInput"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="3" class="custom7cols">
            <v-text-field
              :label="$t('common.addressFields.zip')"
              v-model="form.zip"
              @input="debounceInput"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="3" class="custom7cols">
            <v-text-field
              :label="$t('common.addressFields.place')"
              v-model="form.place"
              @input="debounceInput"
            ></v-text-field>
          </v-col>
        </v-row>
        <MatchingAddresses
          ref="matchingAddresses"
          :address-type="addressTypeSelection"
          :apollo-search-query="apolloSearchQuery"
          @addressSelected="addressSelected"
          @onLoad="onLoad"
        />
      </form>
    </v-flex>
    <SpinnerOverlay :loading="loading" />
  </v-layout>
</template>

<script>
import MatchingAddresses from './MatchingAddresses'
import SpinnerOverlay from '@/components/SpinnerOverlay'
import { mapActions } from 'vuex'
import _debounce from 'lodash.debounce'

import { DASHBOARD_CONSTANTS } from '@/config/options/dashboardConstants'
import { getDashboardColor } from './helpers/dashboardHelper'

export default {
  name: 'DashboardPerson',
  components: {
    MatchingAddresses,
    SpinnerOverlay
  },
  data () {
    return {
      loading: false,
      addressTypeSelection: '',
      personSearchList: [
        DASHBOARD_CONSTANTS.ALL,
        DASHBOARD_CONSTANTS.TENANT,
        DASHBOARD_CONSTANTS.CLIENT,
        DASHBOARD_CONSTANTS.CARETAKER,
        DASHBOARD_CONSTANTS.CRAFTSMAN,
        DASHBOARD_CONSTANTS.RENTING_FORM
      ],
      form: this.initForm(),
      rules: {
        integerNumber: (value) => {
          const valid = Number.isInteger(Number(value))
          return valid || this.$t('dashboard.form.integerNumber')
        }
      },
      apolloSearchQuery: {}
    }
  },
  props: {
    activeTab: {
      type: Number,
      default: 0
    }
  },
  computed: {
    routeAddressId () {
      return this.$route.params.addressId
    },
    routeAddressType () {
      return this.$route.params.addressType
    },
    searchAddressesQuery () {
      return {
        addressId: this.form.addressId,
        name: this.form.name,
        phone: this.form.phone,
        email: this.form.email,
        zip: this.form.zip,
        place: this.form.place,
        street: this.form.street,
        tenant: this.form.tenant,
        client: this.form.client,
        caretaker: this.form.caretaker,
        craftsman: this.form.craftsman,
        rentingForm: this.form.rentingForm
      }
    },
    defaultAddressTypeQuery (addressType) {
      if (this.addressTypeSelection === DASHBOARD_CONSTANTS.ALL) {
        return null
      }
      return this.addressTypeSelection === addressType
    }
  },
  watch: {
    'activeTab': 'setAddressTypeDefaultValue'
  },
  created () {
    this.setAddressTypeDefaultValue()
    this.apolloSearchQuery = this.searchAddressesQuery
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    initForm () {
      return {
        addressId: '',
        name: '',
        phone: this.$route.query.phone || '',
        email: this.$route.query.email || '',
        street: '',
        zip: '',
        place: '',
        tenant: null,
        client: null,
        caretaker: null,
        craftsman: null,
        rentingForm: null
      }
    },
    onChangedAddressType () {
      this.$emit('resetRoute')
      this.resetAddressTypeRadioValues()
      if (this.addressTypeSelection !== DASHBOARD_CONSTANTS.ALL) {
        this.form[this.addressTypeSelection] = true
      }
      this.apolloSearchQuery = this.searchAddressesQuery
    },
    setAddressTypeDefaultValue () {
      if (this.routeAddressType && this.routeAddressType !== 'property') {
        this.addressTypeSelection = this.$route.params.addressType
      } else {
        this.addressTypeSelection = DASHBOARD_CONSTANTS.ALL
        this.resetAddressTypeRadioValues()
      }
    },
    resetAddressTypeRadioValues () {
      this.form.tenant = null
      this.form.client = null
      this.form.caretaker = null
      this.form.craftsman = null
      this.form.rentingForm = null
    },
    addressSelected (item) {
      this.$emit('addressSelected', item.id.toString(), this.getAddressType(item))
    },
    getAddressType (item) {
      if (this.addressTypeSelection && this.addressTypeSelection !== DASHBOARD_CONSTANTS.ALL) {
        return this.addressTypeSelection
      } else if (item.isTenant || item.isSublet || item.isContractPartner) {
        return DASHBOARD_CONSTANTS.TENANT
      } else if (item.isClient) {
        return DASHBOARD_CONSTANTS.CLIENT
      } else if (item.isCaretaker) {
        return DASHBOARD_CONSTANTS.CARETAKER
      } else if (item.isCraftsman) {
        return DASHBOARD_CONSTANTS.CRAFTSMAN
      } else {
        return null
      }
    },
    onLoad (loading) {
      this.loading = loading
    },
    clearForm () {
      this.$emit('resetRoute')
      this.form = this.initForm()
      this.addressTypeSelection = DASHBOARD_CONSTANTS.ALL
      this.apolloSearchQuery = this.searchAddressesQuery
    },
    setItemColor (item) {
      return getDashboardColor(item)
    },
    debounceInput: _debounce(function (e) {
      if (this.routeAddressId && this.routeAddressId !== this.searchAddressesQuery.addressId) {
        this.$router.replace('/')
      }
      this.apolloSearchQuery = this.searchAddressesQuery
    }, 1000)
  }
}
</script>

<style scoped lang="postcss">
/deep/ tr:nth-child(odd) {
  background: #f8f8f8;
}
/deep/ tr {
  cursor: pointer;
}
/deep/ td {
  white-space: pre;
}
/deep/ tr.v-data-table__selected {
  background: rgb(228, 239, 250) !important;
}
/deep/ .v-alert__wrapper {
  max-height: 24px;
}
.amber--text /deep/ i, .amber--text /deep/ label {
  color: #FFC107;
}
.indigo--text /deep/ i, .indigo--text /deep/ label {
  color: indigo;
}
.teal--text /deep/ i, .teal--text /deep/ label {
  color: teal;
}
.green--text /deep/ i, .green--text /deep/ label {
  color: green;
}
.blue-grey--text /deep/ i, .blue-grey--text /deep/ label {
  color: #607D8B;
}
.brown--text /deep/ i, .brown--text /deep/ label {
  color: brown;
}
@media (min-width: 1264px){
  .custom7cols {
    width: 14.2%;
    max-width: 14.2%;
    flex-basis: 14.2%;
  }
}
</style>
