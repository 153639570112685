import i18n from '../i18n/i18n'
const ABACUS_HREF = 'https://abacus.prestige.ch:40001/abastart?mandant=10&program='

export function useObjectActions () {
  const objectActions = [
    { title: i18n.t('dashboard.actions.object.title'), icon: 'abacus-logo', action: onObject },
    { title: i18n.t('dashboard.actions.object.contract'), icon: 'abacus-logo', action: onObjectContract }
  ]

  function getPropertyId (object) {
    return object.property?.propertyId?.toString() || object.propertyId?.toString()
  }

  function onNewObjectTicket (propertyId, objectId) {
    return [
      {
        linkName: 'property',
        linkValues: [propertyId.toString()]
      },
      {
        linkName: 'propertyObject',
        linkValues: [propertyId.toString() + ':' + objectId.toString()]
      }
    ]
  }

  function onObject (object) {
    let url = ABACUS_HREF + 'immo.11&RealEstateNumber=' + getPropertyId(object) + '&ObjectNumber=' + object.objectId
    window.open(url, '_blank')
  }

  function onObjectContract (object) {
    let url = ABACUS_HREF + 'immo.11&ObjectContractNumber=' + object.rentalContractId
    window.open(url, '_blank')
  }

  function onOfflineRentalApplication (object) {
    const propertyId = getPropertyId(object)
    let links = [
      {
        linkName: 'property',
        linkValues: [propertyId]
      },
      {
        linkName: 'propertyObject',
        linkValues: [propertyId + ':' + object.objectId.toString()]
      }
    ]
    let variables = [
      {
        variableName: 'System_RentalContractId_Long',
        variableValue: object.rentalContractId,
        variableType: 'long'
      }
    ]
    return { links: links, variables: variables }
  }

  return {
    objectActions,
    onNewObjectTicket,
    onObject,
    onObjectContract,
    onOfflineRentalApplication
  }
}
