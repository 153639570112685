<template>
  <v-dialog :value="true" scrollable max-width="750px" @keydown.enter.prevent="updateTask" @keydown.esc="closeDialog" persistent>
    <v-card>
      <v-card-title>
        {{dialogTitle}}
        <v-btn icon absolute right>
          <v-icon class="clear" color="primary" :size="28" @click.native="closeDialog"/>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-layout row wrap>
            <v-flex xs12 sm12 md12 lg12 xl12>
              <v-form ref="taskNameForm">
                <v-text-field
                  :label="$t('common.data.name')"
                  v-model="task.name"
                  :required="true"
                  :rules="[rules.required]"
                  autofocus
                >
                  <template #message="{ message }">
                    <span v-if="message === 'required'">{{ requiredMessage }}</span>
                  </template>
                </v-text-field>
              </v-form>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex xs12 sm12 md12 lg12 xl12>
              <v-textarea
                :label="$t('ticketingSystem.editTask.description')"
                v-model="task.description"
              ></v-textarea>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs12 sm12 md12 lg12 xl12>
              <TicketPriority v-model="task.priority" />
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="closeDialog" class="mt-2">{{ $t('common.buttons.cancel') }}</v-btn>
        <v-btn color="success" @click="updateTask" class="mt-2">
          <span>{{ $t('common.buttons.save') }}</span>
          <v-icon class="ml-2">send</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TicketPriority from '@/features/ticketing/components/TicketPriority'
import { mapActions } from 'vuex'

export default {
  name: 'EditTaskDialog',
  components: {
    TicketPriority
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    checkList: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      task: {
        name: this.item.name || '',
        description: this.item.description || '',
        priority: this.item.priority || 2
      },
      rules: {
        required: (value) => !!value || 'required'
      }
    }
  },
  computed: {
    requiredMessage () {
      return this.$t('common.errors.required')
    },
    dialogTitle () {
      return this.checkList ? this.$t('ticketingSystem.updateTask.edit') : this.$t('ticketingSystem.updateTask.editTaskName')
    }
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar', 'setSuccessSnackbar']),
    closeDialog () {
      this.$emit('closeDialog')
    },
    updateTask () {
      if (this.$refs.taskNameForm.validate()) {
        let that = this
        this.$prestigeTSTask.updateTaskDetails(this.item.taskId, this.task).then(function (response) {
          if (response.status === 200) {
            that.setSuccessSnackbar(that.$t('ticketingSystem.messages.successfullyUpdatedTask'))
            that.closeDialog()
            that.loadTaskDetails()
          }
          return true
        }).catch(error => {
          that.loadTaskDetails()
          that.setErrorSnackbar(error)
        })
      }
    },
    loadTaskDetails () {
      this.$emit('loadTaskDetails')
    }
  }
}
</script>

<style scoped lang="postcss">
</style>
