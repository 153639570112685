<template>
  <div>
    <v-progress-linear indeterminate v-if="$apollo.loading"></v-progress-linear>
    <v-row dense v-else>
      <v-col cols="12">
        <RentalComponents
          :rent-components="rentComponents"
          :earliest-start="earliestStart"
          :move-in-date="moveInDate"
          :deposit="deposit"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-btn color="" target="_blank" :href="'https://abacus.prestige.ch:40001/abastart?mandant=10&program=immo.11&RealEstateNumber=' + propertyId + '&ObjectNumber=' + objectId">
          <span class="abacus-logo mr-2"></span> {{ $t('ticketingSystem.processes.rentingForm.createContract.abacusLink') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-radio-group
          row
          v-model="contractFinished"
          required
          :rules="[rules.radioRequired]"
          @change="updatedField"
        >
          <v-list-item-subtitle class="subtitle-1 mb-2">
            {{ $t('ticketingSystem.processes.rentingForm.createContract.contractFinished') }}
          </v-list-item-subtitle>
          <v-radio class="pr-5 mr-0" :label="$t('common.options.yes')" value="CONTRACT_FINISHED_YES" :disabled="formReadOnly" />
          <v-radio class="pr-5 mr-0" :label="$t('ticketingSystem.processes.rentingForm.createContract.contractFinishedNo')" value="CONTRACT_FINISHED_NO" :disabled="formReadOnly" />
          <template #message="{ message }">
            <span v-if="message === 'required'">{{ requiredMessage }}</span>
          </template>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <DatePicker
          v-model="contractCompletedDate"
          :default-value="false"
          :label="$t('ticketingSystem.processes.rentingForm.createContract.contractCompletedDate')"
          :disabled="formReadOnly"
          :required="contractFinished === 'CONTRACT_FINISHED_YES'"
          :rules="contractFinished === 'CONTRACT_FINISHED_YES'? [rules.required] : []"
          attach-selector
          @input="updatedField"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import * as ticketingHelper from '@/features/ticketing/helpers/ticketingHelper'
import { getGraphQLVariable } from '@/helpers/graphQLHelper'
import { formatDate, formatAmount } from '@/helpers/formatterHelper'
import DatePicker from '@/components/DatePicker'
import RentalComponents from '../process-specific-context/common/RentalComponents'
import { RELETTING_TICKETING_TICKET } from '../../graphql-apollo/relettingTicketingTicket.gql'

export default {
  name: 'RentingFormContractClosure',
  props: {
    processVariables: {
      type: Array,
      default: () => {}
    },
    formReadOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      contractFinished: {},
      contractCompletedDate: {},
      ticketLoadError: '',
      rules: {
        required: (value) => !!value || this.$t('common.errors.required'),
        checkboxRequired: (value) => (value !== undefined && value !== '' && value !== null && value) || this.requiredMessage,
        radioRequired: (value) => (value !== undefined && value !== '' && value !== null) || this.requiredMessage
      }
    }
  },

  apollo: {
    relettingTicketingTicket: {
      query: RELETTING_TICKETING_TICKET,
      skip () {
        return !this.relettingProcessId
      },
      error (error) {
        this.ticketLoadError = error.message
      },
      variables () {
        return {
          ticketId: this.relettingProcessId
        }
      }
    }
  },
  components: {
    RentalComponents,
    DatePicker
  },
  computed: {
    propertyId () {
      return this.getVariableValue('System_RentalCase_Property')
    },
    objectId () {
      return this.getVariableValue('System_RentalCase_PropertyObject')
    },
    relettingProcessId () {
      return this.getVariableValue('System_ProcessParentReference_UUID')
    },
    moveInDate () {
      return this.getVariableValue('System_MoveIn_Date')
    },
    rentComponents () {
      return getGraphQLVariable(this.relettingTicketingTicket?.variables, 'System_RentalContractComponents_JSON', 'rentComponents', [])
    },
    deposit () {
      return getGraphQLVariable(this.relettingTicketingTicket?.variables, 'System_ReLetting_Deposit_Long', 'longValue', 0)
    },
    earliestStart () {
      return getGraphQLVariable(this.relettingTicketingTicket?.variables, 'System_ReLetting_EarliestStart_Date', 'dateValue')
    },
    requiredMessage () {
      return this.$t('common.errors.required')
    }
  },
  methods: {
    formatDate: formatDate,
    formatAmount: formatAmount,
    getVariableValue (variableName) {
      return ticketingHelper.getVariableValue(this.processVariables, variableName)
    },
    updatedField () {
      this.$emit('updateFormValues', { formFields: [
        {
          formFieldId: 'System_InterestedPerson_ContractFinished_Enum',
          formFieldType: 'enum',
          formFieldDefaultValue: this.contractFinished
        },
        {
          formFieldId: 'System_RelettingContractCompleted_Date',
          formFieldType: 'enum',
          formFieldDefaultValue: this.contractCompletedDate
        }
      ]})
    }
  }
}
</script>

<style scoped lang="postcss">
.abacus-logo {
  width: 80px;
  content: url('../../../../assets/icons/abacus-logo.svg');
}
</style>
