<template>
  <div>
    <canvas id="qrCodeCanvas" ref="qrCodeCanvas"></canvas>
    <img src="" alt="" ref="qrCodeImage" v-show="false" />
  </div>
</template>

<script>
import QrCodeWithLogo from 'qrcode-with-logos'

export default {
  name: 'QRCode',
  props: {
    content: {
      type: String,
      default: ''
    }
  },
  watch: {
    'content': 'renderQrCodeWithLogo'
  },
  mounted () {
    this.renderQrCodeWithLogo()
  },
  methods: {
    renderQrCodeWithLogo () {
      let qrcode = new QrCodeWithLogo({
        canvas: this.$refs.qrCodeCanvas,
        content: this.content,
        width: 200,
        download: false,
        image: this.$refs.qrCodeImage,
        nodeQrCodeOptions: {
          color: {
            dark: '#6e2c6b'
          }
        },
        logo: {
          src: require('@/assets/prestige-qr-code-logo.png'),
          logoSize: 0.15
        }
      })
      qrcode.toImage()
    }
  }
}
</script>

<style scoped lang="postcss">
</style>
