<template>
  <div class="process-references mt-2 mb-2">
    <ProcessParent :parent="parent" />
    <ProcessChildren :children="children" />
    <v-card v-if="noReferences">
      <v-card-subtitle class="pa-2 font-weight-bold subtitle-1">
        {{ $t('ticketingSystem.processes.processReferences') }}
      </v-card-subtitle>
      <v-card-text class="pl-2">
        {{ $t('ticketingSystem.messages.noReferencesInfo') }}
      </v-card-text>
    </v-card>
  </div>
</template>
<script>

import ProcessParent from './ProcessParent'
import ProcessChildren from './ProcessChildren'
import { mapActions } from 'vuex'

export default {
  name: 'ProcessReferences',
  components: {
    ProcessParent,
    ProcessChildren
  },
  props: {
    processReferences: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    parent () {
      return this.processReferences.parent
    },
    children () {
      return this.processReferences.children
    },
    noReferences () {
      return !this.parent && this.children && this.children.length === 0
    }
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar'])
  }
}
</script>

<style scoped lang="postcss">
</style>
