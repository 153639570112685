var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":""}},[_c('v-card-title',{staticClass:"pa-0 mb-4 mt-2"},[_vm._v(_vm._s(_vm.item.houseStreet)+" ")]),_c('v-data-table',{staticClass:"elevation-1 mr-4 mb-2",attrs:{"headers":_vm.headers,"items":_vm.item.properties,"loading":_vm.loading,"options":_vm.options,"footer-props":{itemsPerPageOptions:_vm.itemsPerPageOptions, itemsPerPageText: _vm.itemsPerPageText},"item-key":"addressId"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.currentTenantText",fn:function(ref){
var item = ref.item;
return [(item.currentTenant)?_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.setSelectedCurrentTenant(item.currentTenant)}}},[_vm._v(" "+_vm._s(item.currentTenantText)+" ")]):_c('span',[_vm._v(" "+_vm._s(item.currentTenantText)+" ")])]}},{key:"item.newTenantText",fn:function(ref){
var item = ref.item;
return [(item.newTenant)?_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.setSelectedNewTenant(item.newTenant)}}},[_vm._v(" "+_vm._s(item.newTenantText)+" ")]):_c('span',[_vm._v(" "+_vm._s(item.newTenantText)+" ")])]}},{key:"item.newTenantContractStart",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.newTenantContractStartFormatted)+" ")]}},{key:"item.currentTenantContractStart",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.currentTenantContractStartFormatted)+" ")]}},{key:"item.currentTenantContractEnd",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.currentTenantContractEndFormatted)+" ")]}}],null,true)},[_c('v-progress-linear',{attrs:{"slot":"progress","color":"blue","indeterminate":""},slot:"progress"})],1),_c('ObjectDialog',{attrs:{"show-dialog":_vm.showDialog,"currentTenant":_vm.selectedTenant,"title":_vm.title},on:{"closeDialog":function($event){_vm.showDialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }