<template>
  <v-autocomplete
    ref="assignee"
    :loading="loading"
    :label="$t('common.buttons.select')"
    single-line
    return-object
    :items="assigneeDisplayItems"
    item-text="displayText"
    item-value="userId"
    :search-input.sync="search"
    v-model="mutableForm.value"
    hide-selected
    v-click-outside="hideAssigneeDropdown"
    class="assignee-dropdown pa-0 ma-0"
    @change="changedAssignee"
  >
  </v-autocomplete>
</template>

<script>
import { mapState } from 'vuex'
import _cloneDeep from 'lodash.clonedeep'
import { AuthenticationContext } from 'vue-adal'

export default {
  name: 'TaskAssignee',
  props: {
    form: {
      type: Object,
      default: () => {}
    },
    assignedToMe: {
      type: Boolean,
      default: false
    },
    candidateGroups: {
      type: Array,
      default: () => []
    },
    userHasTicketingRole: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      assigneeTicketingUsers: [],
      assigneeDisplayItems: [],
      filteredTicketingUsers: [],
      search: '',
      unassigned: 'unassigned',
      assignToMe: 'assignToMe',
      loading: false,
      mutableForm: this.form
    }
  },
  created () {
    if (this.ticketingUsers.length === 0) {
      this.setTicketingUsers()
    }
  },
  mounted () {
    this.updateAssigneeList()
    let userId = this.form && this.form.value ? this.form.value.userId : null
    if (userId) {
      this.mutableForm.value.displayText = userId === this.unassigned ? this.unassignedText : (userId === this.assignToMe) ? this.assignToMeText : this.form.value.displayText
    }
  },
  computed: {
    ...mapState('ticketingSystem', ['ticketingUsers']),
    unassignedText () {
      return this.$t('ticketingSystem.taskDetails.buttons.unassigned')
    },
    assignToMeText () {
      return this.$t('ticketingSystem.taskDetails.buttons.assignToMe')
    },
    loggedInUserId () {
      return AuthenticationContext && AuthenticationContext.user && AuthenticationContext.user.profile ? AuthenticationContext.user.profile.employeeid : null
    }
  },
  methods: {
    hideAssigneeDropdown () {
      this.$emit('hideAssigneeDropdown')
    },
    setTicketingUsers () {
      this.loading = true
      Promise.resolve(this.$store.dispatch('ticketingSystem/loadTicketingUsers')).then(() => {
        this.initTicketingUsers()
        this.loading = false
      })
    },
    updateAssigneeList () {
      this.initTicketingUsers()
      if (this.form?.value?.userId) {
        let unassigned = { userId: this.unassigned, displayText: this.unassignedText }
        this.addItem(unassigned)
      }
    },
    itemIndex (assigneeId) {
      let index = this.assigneeDisplayItems.findIndex(x => x.userId === assigneeId)
      return index
    },
    initTicketingUsers () {
      this.assigneeTicketingUsers = _cloneDeep(this.ticketingUsers)
      let candidateGroups = this.candidateGroups.map(cg => cg.id)
      if (candidateGroups && candidateGroups.length > 0) {
        if (this.assigneeTicketingUsers) {
          this.filteredTicketingUsers = this.assigneeTicketingUsers.filter(function (item) {
            if (item.groups) {
              let hasPermission = item.groups.filter(function (subItem) {
                return candidateGroups.includes(subItem)
              })
              if (hasPermission.length > 0) {
                return item
              }
            }
          })
          this.setDisplayItems(this.filteredTicketingUsers)
        }
      } else {
        this.setDisplayItems(this.assigneeTicketingUsers)
      }
    },
    setDisplayItems (users) {
      if (users && users.length > 0) {
        users.forEach(item => {
          let displayText = '[' + item.userId + '] ' + item.name
          if (this.loggedInUserId === item.userId) {
            displayText += ` (${this.assignToMeText})`
          }
          let displayListItem = {
            userId: item.userId,
            displayText: displayText
          }
          if (this.loggedInUserId === item.userId) {
            this.assigneeDisplayItems.unshift(displayListItem)
          } else {
            this.assigneeDisplayItems.push(displayListItem)
          }
        })
      }
    },
    addItem (item) {
      this.assigneeTicketingUsers.unshift(item)
      this.assigneeDisplayItems.unshift(item)
    },
    changedAssignee () {
      if (this.mutableForm.value) {
        let userId = this.mutableForm.value.userId
        this.mutableForm.value.displayText = userId === this.unassigned ? this.unassignedText : (userId === this.assignToMe ? this.assignToMeText : this.mutableForm.value.displayText)
        this.$emit('changedAssignee', userId)
      } else {
        this.$emit('changedAssignee')
      }
    }
  }
}
</script>

<style scoped lang="postcss">
.assignee-dropdown {
  height: 10px;
  top: -10px;
  left: 2px;
}
</style>
