import TicketingStatistics from '../../ticketing-statistics/TicketingStatistics'

export const TicketingStatisticsRoutes = [
  {
    path: '/ticketing-statistics',
    name: 'TicketingStatistics',
    component: TicketingStatistics,
    meta: {
      title: 'ticketingStatistics',
      requireAuth: true,
      requireRoles: [
        'prestige-tools-admin'
      ]
    }
  }
]