<template>
  <div>
    <v-row dense>
      <v-col>
        <v-autocomplete
          v-if="!overrideRentalContractId"
          v-model="rentalContractId"
          id="propertySelect"
          item-text="displayText"
          item-value="rentalContractId"
          dense
          clearable
          required
          :label="$t('ticketingSystem.processes.offlineRentingForm.rentalContract')"
          :loading="rentalContractsLoading"
          :filter="customFilter"
          :items="rentalContracts"
          :search-input.sync="searchQuery"
          :rules="[rules.required]"
        >
          <template slot="selection" slot-scope="{ item }">
            {{ item.displayText }}
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row dense class="mt-3">
      <v-col>
        <DatePicker
          dense
          ref="desiredMoveInDate"
          :label="$t('renting.publicForm.desiredMoveInDate')"
          v-model="desiredMoveInDate"
          :default-value="desiredMoveInDate ? true : false"
          :default-value-date="desiredMoveInDate || null"
          required
        />
      </v-col>
      <v-col>
        <DatePicker
          dense
          ref="dateOfBirth"
          :label="$t('renting.publicForm.fields.personInfo.dateOfBirth')"
          v-model="dateOfBirth"
          :default-value="dateOfBirth ? true : false"
          :default-value-date="dateOfBirth || null"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" sm="6" md="2">
        <SalutationList
          v-model="salutation"
          required
        />
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          v-model="firstName"
          :label="$t('ticketingSystem.processes.offlineRentingForm.firstName')"
          dense
          :required="true"
          :rules="[rules.required]"
        >
          <template #message="{ message }">
            <span v-if="message === 'required'">{{ requiredMessage }}</span>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="lastName"
          :label="$t('ticketingSystem.processes.offlineRentingForm.lastName')"
          dense
          :required="true"
          :rules="[rules.required]"
        >
          <template #message="{ message }">
            <span v-if="message === 'required'">{{ requiredMessage }}</span>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-text-field
          v-model="email"
          :label="$t('ticketingSystem.processes.offlineRentingForm.email')"
          dense
          :required="true"
          :rules="[rules.required, rules.email]"
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="phone"
          :label="$t('ticketingSystem.processes.offlineRentingForm.phone')"
          dense
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="street"
          :label="$t('ticketingSystem.processes.offlineRentingForm.street')"
          dense
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="2">
        <v-text-field
          v-model="zip"
          :label="$t('ticketingSystem.processes.offlineRentingForm.zip')"
          dense
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          v-model="place"
          :label="$t('ticketingSystem.processes.offlineRentingForm.place')"
          dense
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-select
          :items="decisionOptionItems"
          item-value="value"
          item-text="label"
          clearable
          :label="$t('ticketingSystem.processes.interestedPerson.decision.title')"
          v-model="decision"
        >
        </v-select>
      </v-col>
      <v-col>
        <AutocompleteLinks
          ref="decisionComment"
          class="reason-autocomplete"
          v-model="interestedPersonDecisionComment"
          :label="$t('ticketingSystem.processes.interestedPerson.decision.reason')"
          :validate-clicked="validateClicked"
          @autocompleteLinksUpdated="onAutocompleteLinksUpdated"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-card-text class="pl-0">
          {{ $t('ticketingSystem.note.files') }}
        </v-card-text>
        <TicketFileVariables
          :process-variables="fileUploads"
          @addFileVariable="onAddFileVariable"
          @removeFileVariable="onRemoveFileVariable"
          @loadProcessVariables="onLoadProcessVariables"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import * as validationHelper from '@/helpers/validationHelper'
import * as ticketingHelper from '@/features/ticketing/helpers/ticketingHelper'
import * as globalHelper from '@/helpers/globalHelper'
import { formatDate } from '@/helpers/formatterHelper'
import { statusOptions } from '@/config/options/decision'
import SalutationList from '@/components/SalutationList'
import AutocompleteLinks from '../../components/AutocompleteLinks'
import TicketFileVariables from '../TicketFileVariables'
import DatePicker from '../../../../components/DatePicker'

export default {
  name: 'RentingFormOfflineStart',
  props: {
    processVariables: {
      type: Array,
      default: () => {
      }
    },
    validateClicked: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      rentalContractId: null,
      overrideRentalContractId: null,
      valid: false,
      firstName: '',
      lastName: '',
      salutation: '',
      email: '',
      phone: '',
      street: '',
      zip: '',
      place: '',
      dateOfBirth: null,
      decision: null,
      desiredMoveInDate: null,
      interestedPersonDecisionComment: '',
      searchQuery: null,
      rentalContracts: [],
      rentalContractsLoading: false,
      decisionOptionItems: statusOptions,
      fileUploads: [],
      rules: {
        required: (value) => !!value || this.$t('common.errors.required'),
        email: (value) => { return validationHelper.validateEmail(value) }
      }
    }
  },
  components: {
    SalutationList,
    AutocompleteLinks,
    TicketFileVariables,
    DatePicker
  },
  watch: {
    searchQuery (val) {
      val && this.searchRentalContracts(val)
    },
    'processVariables': {
      handler: 'initProcessVariables',
      immediate: true
    }
  },
  computed: {
    requiredMessage () {
      return this.$t('common.errors.required')
    }
  },
  methods: {
    formatDate: formatDate,
    onAutocompleteLinksUpdated (autocompleteLinks) {
      this.$emit('autocompleteLinksUpdated', autocompleteLinks)
      this.updatedField()
    },
    customFilter (item, queryText, itemText) {
      return globalHelper.propertyFilter(queryText, itemText)
    },
    initProcessVariables () {
      this.overrideRentalContractId = ticketingHelper.getVariableValue(this.processVariables, 'System_RentalContractId_Long')
    },
    searchRentalContracts (searchString) {
      let that = this
      that.rentalContractsLoading = true
      that.rentalContracts = []

      this.$abacusRentalContract.searchRentalContracts(searchString).then(function (response) {
        if (response.data.length) {
          response.data.forEach(item => {
            item.displayText = '[' + item.rentalContractId + '] ' + item.houseStreet + ', ' + item.zip + ' ' + item.place + ' - [' + item.objectId + '] ' + item.floorText + ' ' + item.objectText + ' - ' + item.currentTenant
            that.rentalContracts.push(item)
          })
        }
        that.rentalContractsLoading = false
      }).catch(function (error) {
        that.rentalContracts = []
        that.setErrorSnackbar(error)
      })
    },
    onAddFileVariable (file, variableName) {
      if (file) {
        this.fileUploads.push({
          variableName: variableName,
          variableType: file?.variableType || 'azureFile',
          variableValue: file
        })
      }
    },
    onRemoveFileVariable (variableName) {
      let index = this.fileUploads.findIndex(x => x.variableName === variableName)
      if (index !== -1) {
        this.fileUploads.splice(index, 1)
      }
    },
    onLoadProcessVariables (file, variableName) {
      this.onRemoveFileVariable(variableName)
      this.onAddFileVariable(file, variableName)
    },
    setTicketStartProcessDTOVariables () {
      let variables = [
        {
          variableName: 'System_RentalContractId_Long',
          variableType: 'long',
          variableValue: this.overrideRentalContractId ? this.overrideRentalContractId : this.rentalContractId
        },
        {
          variableName: 'Process_C2_02_03b_FirstName_String',
          variableType: 'string',
          variableValue: this.firstName
        },
        {
          variableName: 'Process_C2_02_03b_LastName_String',
          variableType: 'string',
          variableValue: this.lastName
        },
        {
          variableName: 'Process_C2_02_03b_Salutation_Enum',
          variableType: 'string',
          variableValue: this.salutation
        },
        {
          variableName: 'Process_C2_02_03b_Email_String',
          variableType: 'string',
          variableValue: this.email
        },
        {
          variableName: 'Process_C2_02_03b_Phone_String',
          variableType: 'string',
          variableValue: this.phone
        },
        {
          variableName: 'Process_C2_02_03b_Street_String',
          variableType: 'string',
          variableValue: this.street
        },
        {
          variableName: 'Process_C2_02_03b_Zip_String',
          variableType: 'string',
          variableValue: this.zip
        },
        {
          variableName: 'Process_C2_02_03b_Place_String',
          variableType: 'string',
          variableValue: this.place
        },
        {
          variableName: 'Process_C2_02_03b_DesiredMoveInDate_String',
          variableType: 'string',
          variableValue: this.desiredMoveInDate
        },
        {
          variableName: 'Process_C2_02_03b_DateOfBirth_String',
          variableType: 'string',
          variableValue: this.dateOfBirth
        },
        {
          variableName: 'System_InterestedPerson_Decision_Enum',
          variableType: 'string',
          variableValue: this.decision ? this.decision : 'IN_PROGRESS'
        },
        {
          variableName: 'System_InterestedPerson_DecisionComment_String',
          variableType: 'string',
          variableValue: this.interestedPersonDecisionComment
        }
      ]
      this.fileUploads.forEach(fileUpload => variables.push({
        variableName: fileUpload.variableName,
        variableType: fileUpload.variableType,
        variableValue: fileUpload.variableValue.fileId
      }))
      return variables
    }
  }
}
</script>

<style scoped lang="postcss">
/deep/ .reason-autocomplete .editor {
  height: 90px;
}
</style>
