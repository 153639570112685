import {authenticate} from '@/backend/auth'

// config-v2.js is included in index.html and overwritten in the runtime
/* global config:true */

export const axiosInterceptors = function (axios, vueInstance) {
  let clientId = config.AZURE_APP_CLIENT_ID
  // Setup request interceptors
  if (clientId) {
    axios.interceptors.request.use(config => {
      return new Promise((resolve, reject) => {
        // automatically add Bearer token in case /api is called
        if (config.url.startsWith('/api') && vueInstance.$route.meta && vueInstance.$route.meta.requireAuth) {
          authenticate(vueInstance.$adal)
            .then(token => {
              const user = vueInstance.$adal?.user?.profile?.employeeid
              if (user && vueInstance.$appInsights) {
                vueInstance.$appInsights.context.user.id = user
                vueInstance.$appInsights.setAuthenticatedUserContext(user)
              }
              config.headers['Authorization'] = 'Bearer ' + token
              resolve(config)
            })
            .catch(error => reject(error))
        } else {
          resolve(config)
        }
      })
    }, (error) => {
      console.log('failed', error)
    })
  }
}
