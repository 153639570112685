<template>
  <v-form ref="form" v-model="valid" v-show="displayForm">
    <DynamicForm
      ref="formFields"
      v-if="dynamicForm"
      :form="form"
      :form-read-only="formReadOnly"
      :dialog="dialog"
      :files="files"
      :validate-clicked="validateClicked"
      :additional-links="additionalLinks"
      :process-instance-id="processInstanceId"
      @updateFiles="onUpdateFiles"
      @autocompleteLinksUpdated="onAutocompleteLinksUpdated"
      @loadProcessVariables="onLoadProcessVariables"
    />
    <MyStaticForm
      ref="formFields"
      v-else-if="myStaticForm"
      :process-variables="processVariables"
      :form-read-only="formReadOnly"
      @updateFormValues="updateForm"
    />
    <TicketStart
      ref="formFields"
      v-else-if="isTicketStart"
      :process-instance-id="processInstanceId"
      @updateFormValues="updateTicketStartForm"
      @showDatePicker="$emit('showDatePicker')"
    />
    <RentingFormOfflineStart
      ref="formFields"
      v-else-if="isRentingFormOfflineStart"
      :process-variables="processVariables"
      :validate-clicked="validateClicked"
      @updateFormValues="updateForm"
      @autocompleteLinksUpdated="onAutocompleteLinksUpdated"
      @loadProcessVariables="onLoadProcessVariables"
    />
    <RentingFormOfflineDecision
      ref="formFields"
      v-else-if="isRentingFormOfflineDecision"
      :process-variables="processVariables"
      :validate-clicked="validateClicked"
      @updateFormValues="updateForm"
      @autocompleteLinksUpdated="onAutocompleteLinksUpdated"
    />
    <TerminationConfirmation
      ref="formFields"
      v-else-if="isTerminationConfirmation"
      :process-variables="processVariables"
    />
    <TerminationSignature
      ref="formFields"
      v-else-if="isTerminationSignature"
      :process-variables="processVariables"
    />
    <TerminationSignatureCheck
      ref="formFields"
      v-else-if="isTerminationSignatureCheck"
      :ticketing-task="ticketingTask"
      :process-variables="processVariables"
      :validate-clicked="validateClicked"
      :form-read-only="formReadOnly"
      @updateFormValues="updateForm"
      @loadProcessVariables="onLoadProcessVariables"
    />
    <TerminationSpecialities
      ref="formFields"
      v-else-if="isTerminationSpecialities"
      :process-variables="processVariables"
    />
    <TerminationDecisionReletting
      ref="formFields"
      v-else-if="isTerminationDecisionReletting"
      :process-variables="processVariables"
      :form-read-only="formReadOnly"
      :additional-links="additionalLinks"
      @updateFormValues="updateForm"
      @autocompleteLinksUpdated="onAutocompleteLinksUpdated"
    />
    <TerminationRentalComponentsProposal
      ref="formFields"
      v-else-if="isTerminationRentalComponentsProposal"
      :process-variables="processVariables"
      :form-read-only="formReadOnly"
      @updateFormValues="updateForm"
    />
    <RentingFormReferencesApartment
      ref="formFields"
      v-else-if="isRentingFormReferencesApartment"
      :ticketing-task="ticketingTask"
      :process-variables="processVariables"
      :form-read-only="formReadOnly"
    />
    <RentingFormReferencesApartmentConclusion
      ref="formFields"
      v-else-if="isRentingFormReferencesApartmentConclusion"
      :ticketing-task="ticketingTask"
      :process-variables="processVariables"
      :form-read-only="formReadOnly"
    />
    <RentingFormReferencesEmployer
      ref="formFields"
      v-else-if="isRentingFormReferencesEmployer"
      :ticketing-task="ticketingTask"
      :process-variables="processVariables"
      :form-read-only="formReadOnly"
    />
    <RentingFormCreateContract
      ref="formFields"
      v-else-if="isRentingFormCreateContract"
      :process-variables="processVariables"
      :form-read-only="formReadOnly"
      @updateFormValues="updateForm"
    />
    <RentingFormConfirmation
      ref="formFields"
      v-else-if="isRentingFormConfirmation"
      :processVariables="processVariables"
      :form-read-only="formReadOnly"
      @updateFormValues="updateForm"
    />
    <RentingFormContractClosure
      ref="formFields"
      v-else-if="isRentingFormContractClosure"
      :processVariables="processVariables"
      :form-read-only="formReadOnly"
      @updateFormValues="updateForm"
    />
    <RentingFormDecision
      ref="formFields"
      v-else-if="isRentingFormDecision"
      :ticketing-task="ticketingTask"
      :process-variables="processVariables"
      :form-read-only="formReadOnly"
      :validate-clicked="validateClicked"
      @updateFormValues="updateForm"
      @autocompleteLinksUpdated="onAutocompleteLinksUpdated"
    />
    <RentingFormDocuments
      ref="formFields"
      v-else-if="isRentingFormDocuments"
      :ticketing-task="ticketingTask"
      :process-variables="processVariables"
      :form-read-only="formReadOnly"
    />
    <RentingFormReferencesEmployerConclusion
      ref="formFields"
      v-else-if="isRentingFormReferencesEmployerConclusion"
      :ticketing-task="ticketingTask"
      :process-variables="processVariables"
      :form-read-only="formReadOnly"
    />
    <RelettingProcessApplications
      v-else-if="isRelettingProcesApplications"
      :rental-case-id="rentalCaseId"
    />
    <RelettingConfigureAd
      ref="formFields"
      v-else-if="isRelettingConfigureAd"
      :processVariables="processVariables"
      :form-read-only="formReadOnly"
      @updateFormValues="updateForm"
    />
  </v-form>
</template>

<script>
import DynamicForm from '../shared/DynamicForm'
import TicketStart from '../shared/static-forms/TicketStart'
import MyStaticForm from '../shared/static-forms/MyStaticForm'
import RentingFormOfflineStart from '../shared/static-forms/RentingFormOfflineStart'
import RentingFormOfflineDecision from '../shared/static-forms/RentingFormOfflineDecision'
import TerminationConfirmation from '../shared/static-forms/TerminationConfirmation'
import TerminationSignature from './static-forms/TerminationSignature'
import TerminationDecisionReletting from './static-forms/TerminationDecisionReletting'
import TerminationRentalComponentsProposal from '../shared/static-forms/TerminationRentalComponentsProposal'
import TerminationSpecialities from './static-forms/TerminationSpecialities'
import TerminationSignatureCheck from './static-forms/TerminationSignatureCheck'
import RentingFormReferencesApartment from './static-forms/RentingFormReferencesApartment'
import RentingFormReferencesApartmentConclusion from './static-forms/RentingFormReferencesApartmentConclusion'
import RentingFormReferencesEmployer from './static-forms/RentingFormReferencesEmployer'
import RentingFormReferencesEmployerConclusion from './static-forms/RentingFormReferencesEmployerConclusion'
import RentingFormCreateContract from './static-forms/RentingFormCreateContract'
import RentingFormConfirmation from './static-forms/RentingFormConfirmation'
import RentingFormContractClosure from './static-forms/RentingFormContractClosure'
import RentingFormDecision from './static-forms/RentingFormDecision'
import RentingFormDocuments from './static-forms/RentingFormDocuments'
import RelettingProcessApplications from './static-forms/RelettingProcessApplications'
import RelettingConfigureAd from './static-forms/RelettingConfigureAd'
import * as ticketingHelper from './../helpers/ticketingHelper'

export default {
  name: 'SharedTaskForm',
  props: {
    displayForm: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default: () => {}
    },
    formKey: {
      type: String,
      default: ''
    },
    formReadOnly: {
      type: Boolean,
      default: false
    },
    ticketingTask: {
      type: Object,
      default: () => {}
    },
    additionalLinks: {
      type: Array,
      default: () => []
    },
    processVariables: {
      type: Array,
      default: () => []
    },
    processInstanceId: {
      type: String,
      default: ''
    },
    validateClicked: {
      type: Boolean,
      default: false
    },
    dialog: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      valid: false,
      files: [],
      processLinks: []
    }
  },
  components: {
    DynamicForm,
    MyStaticForm,
    RentingFormOfflineStart,
    RentingFormOfflineDecision,
    TicketStart,
    TerminationSpecialities,
    TerminationConfirmation,
    TerminationRentalComponentsProposal,
    TerminationSignature,
    TerminationSignatureCheck,
    TerminationDecisionReletting,
    RentingFormReferencesApartment,
    RentingFormReferencesApartmentConclusion,
    RentingFormReferencesEmployer,
    RentingFormCreateContract,
    RentingFormConfirmation,
    RentingFormContractClosure,
    RentingFormDecision,
    RentingFormDocuments,
    RentingFormReferencesEmployerConclusion,
    RelettingConfigureAd,
    RelettingProcessApplications
  },
  computed: {
    dynamicForm () {
      return this.formKey === 'DYNAMIC' || this.form?.formType === 'DYNAMIC'
    },
    myStaticForm () {
      return this.formKey === 'myStaticForm' || this.form?.formKey === 'myStaticForm'
    },
    isTicketStart () {
      return this.formKey === 'TicketStart' || this.form?.formKey === 'TicketStart'
    },
    isRentingFormOfflineStart () {
      return this.formKey === 'RentingFormOfflineStart' || this.form?.formKey === 'RentingFormOfflineStart'
    },
    isRentingFormOfflineDecision () {
      return this.formKey === 'RentingFormOfflineDecision' || this.form?.formKey === 'RentingFormOfflineDecision'
    },
    isTerminationConfirmation () {
      return this.formKey === 'TerminationConfirmation' || this.form?.formKey === 'TerminationConfirmation'
    },
    isTerminationRentalComponentsProposal () {
      return this.formKey === 'TerminationRentalComponentsProposal' || this.form?.formKey === 'TerminationRentalComponentsProposal'
    },
    isTerminationDecisionReletting () {
      return this.formKey === 'TerminationDecisionReletting' || this.form?.formKey === 'TerminationDecisionReletting'
    },
    isTerminationSignature () {
      return this.formKey === 'TerminationSignatureFollowUp' || this.form?.formKey === 'TerminationSignatureFollowUp'
    },
    isTerminationSpecialities () {
      return this.formKey === 'TerminationSpecialities' || this.form?.formKey === 'TerminationSpecialities'
    },
    isTerminationSignatureCheck () {
      return this.formKey === 'TerminationSignatureCheck' || this.form?.formKey === 'TerminationSignatureCheck'
    },
    isRentingFormReferencesApartment () {
      return this.formKey === 'RentingFormReferencesApartment' || this.form?.formKey === 'RentingFormReferencesApartment'
    },
    isRentingFormReferencesApartmentConclusion () {
      return this.formKey === 'RentingFormReferencesApartmentConclusion' || this.form?.formKey === 'RentingFormReferencesApartmentConclusion'
    },
    isRentingFormReferencesEmployer () {
      return this.formKey === 'RentingFormReferencesEmployer' || this.form?.formKey === 'RentingFormReferencesEmployer'
    },
    isRentingFormReferencesEmployerConclusion () {
      return this.formKey === 'RentingFormReferencesEmployerConclusion' || this.form?.formKey === 'RentingFormReferencesEmployerConclusion'
    },
    isRentingFormCreateContract () {
      return this.formKey === 'RentingFormCreateContract' || this.form?.formKey === 'RentingFormCreateContract'
    },
    isRentingFormConfirmation () {
      return this.formKey === 'RentingFormConfirmation' || this.form?.formKey === 'RentingFormConfirmation'
    },
    isRentingFormContractClosure () {
      return this.formKey === 'RentingFormContractClosure' || this.form?.formKey === 'RentingFormContractClosure'
    },
    isRentingFormDecision () {
      return this.formKey === 'RentingFormDecision' || this.form?.formKey === 'RentingFormDecision'
    },
    isRentingFormDocuments () {
      return this.formKey === 'RentingFormDocuments' || this.form?.formKey === 'RentingFormDocuments'
    },
    isRelettingProcesApplications () {
      return this.formKey === 'RelettingProcesApplications' || this.form?.formKey === 'RelettingProcesApplications'
    },
    isRelettingConfigureAd () {
      return this.formKey === 'RelettingConfigureAd' || this.form?.formKey === 'RelettingConfigureAd'
    },
    rentalCaseId () {
      let rentalCaseId = ticketingHelper.getVariableValue(this.processVariables, 'System_RentalCaseId_Long')
      return rentalCaseId ? rentalCaseId.toString() : ''
    }
  },
  mounted () {
    // initialize property fields after load
    if (this.dynamicForm) {
      this.$refs.formFields.reinitFields()
    }
  },
  methods: {
    invalidRefForm (refForm) {
      let invalidList = []
      refForm.forEach(item => {
        if (item.ariaInvalid) {
          invalidList.push(item)
        }
      })
      return invalidList.length > 0
    },
    reset () {
      this.files = []
      if (this.$refs.form) {
        this.$refs.form.reset()
      }
    },
    async saveThirdPartyData () {
      if (this.$refs.formFields && this.$refs.formFields.saveThirdPartyData) {
        await this.$refs.formFields.saveThirdPartyData()
      }
    },
    isValid () {
      let inputFieldsValid = true
      let autocompleteValid = true
      let fileUploadValid = true
      if (this.$refs.formFields) {
        if (this.$refs.form) {
          inputFieldsValid = this.$refs.form.validate()
        }
        if (this.$refs.formFields.$refs.formAutocomplete && this.$refs.formFields.$refs.formAutocomplete.length > 0) {
          autocompleteValid = !this.invalidRefForm(this.$refs.formFields.$refs.formAutocomplete)
        }
        if (this.$refs.formFields.$refs.formFile && this.$refs.formFields.$refs.formFile.length > 0) {
          fileUploadValid = !this.invalidRefForm(this.$refs.formFields.$refs.formFile)
        } else if (this.$refs.formFields.$refs.formFile) {
          fileUploadValid = !this.$refs.formFields.$refs.formFile.ariaInvalid
        }
      }
      return inputFieldsValid && autocompleteValid && fileUploadValid
    },
    getGroupedVariables (variablesList) {
      let data = {}
      if (this.form.formFields) {
        let formVariables = variablesList
        let that = this
        this.form.formFields.forEach(item => {
          if (item.formFieldType === 'file' || item.formFieldType === 'azureFile') {
            let formFieldId = item.formFieldId
            let variableFile = that.files.find(x => x.id === formFieldId)
            if (variableFile?.id) {
              let newVariable = {
                variableName: formFieldId,
                variableType: 'azureFile',
                variableValue: null
              }
              if (variableFile.file && variableFile.file.fileId) {
                newVariable.variableValue = variableFile.file.fileId.toString()
              }
              formVariables.push(newVariable)
            }
          } else if (item.formFieldType === 'staticFile') {
            let newVariable = {
              variableName: item.formFieldId,
              variableType: 'azureFile',
              variableValue: item.formFieldDefaultValue
            }
            formVariables.push(newVariable)
          } else {
            let newVariable = {
              variableName: item.formFieldId,
              variableType: item.formFieldType,
              variableValue: item.formFieldDefaultValue
            }
            formVariables.push(newVariable)
          }
        })
        data = {
          variables: formVariables
        }
      }
      return data
    },
    updateForm (params) {
      this.$emit('updateFormValues', params)
    },
    updateTicketStartForm (params) {
      this.$emit('updateTicketStartForm', params)
    },
    onUpdateFiles (newFiles) {
      this.files = newFiles
    },
    onAutocompleteLinksUpdated (autocompleteLinks) {
      this.$emit('autocompleteLinksUpdated', autocompleteLinks)
    },
    onLoadProcessVariables () {
      this.$emit('loadProcessVariables')
    }
  }
}
</script>

<style scoped lang="postcss">
</style>
