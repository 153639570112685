<template>
  <v-container fluid grid-list-md>
    <v-layout row wrap>
      <v-flex md3>
        <v-card height="100%">
          <v-card-title>
            {{ $t('navigation.corporations') }}
          </v-card-title>
          <template v-if="corporationLoading">
            <v-card>
              <v-progress-linear indeterminate></v-progress-linear>
            </v-card>
          </template>
          <template v-else>
            <div class="pl-4 pt-2">
              <p class="mb-0">[{{ corporation.corporationId }}] {{ corporation.name }}</p>
              <div class="pt-5">
                <p>{{ $t('dossier.corporations.printouts') }}</p>
                <ul class="ul-none" v-if="corporation.dossierPrintHistory">
                  <li v-for="history in corporation.dossierPrintHistory" :key="history.dossierPrintHistoryId">
                    <nobr>{{ printHistoryDateFormatted(history.createdOn) }}</nobr>
                    <v-btn icon class="ml-4" @click.stop="openDeleteDialog(history.dossierPrintHistoryId)">
                      <v-icon color="pink">delete</v-icon>
                    </v-btn>
                    <v-btn v-if="history.pdf" icon :title="$t('dossier.corporations.pdf')" @click.stop="downloadPrintPDF(history.dossierPrintHistoryId)">
                      <v-icon color="primary">file_download</v-icon>
                    </v-btn>
                  </li>
                </ul>
                <v-alert v-else type="info" class="mr-4">
                  {{ $t('dossier.corporations.noPrintHistory') }}
                </v-alert>
              </div>
            </div>
          </template>
        </v-card>
      </v-flex>
      <v-flex md9>
        <v-card height="100%">
          <PrintContainer
            :corporations="selectedCorporationMappedIDs"
            v-on:sentToPrinter="sentToPrinter"
            @downloadFile="downloadFile"
            @loadData="loadData">
          </PrintContainer>
        </v-card>
      </v-flex>
      <v-flex md12>
          <template v-if="corporationLoading">
            <v-card>
              <v-progress-linear indeterminate></v-progress-linear>
            </v-card>
          </template>
          <template v-else>
            <v-card height="100%">
              <v-card-title>
                <span v-if="corporation.properties && corporation.properties.length <= 1">{{ $t('dossier.corporations.property') }}</span>
                <span v-else>{{ $t('dossier.corporations.properties') }}</span>
              </v-card-title>
              <v-data-table
                :headers="concatHeaders"
                :items="corporation.properties"
                :loading="corporationLoading"
                :hide-default-footer="true"
                :footer-props="{itemsPerPageOptions}"
                class="elevation-1"
              >
                <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
                <template v-for="h in concatHeaders" v-slot:[`header.${h.value}`]="{ header }">
                  <div v-if="header.recipient" :key="h.value" class="inline-flex">
                    <v-checkbox
                      primary
                      hide-details
                      :input-value="header.selectedRecipient"
                      class="header-checkbox mt-0"
                      @click.stop="checkRecipient(header)"
                    ></v-checkbox>
                    <div>
                      <span class="recipient">
                        [<a @click.stop="goToRecipientDetails(header.recipient.recipientId)">{{ header.recipient.recipientId }}</a>]
                        {{ header.recipient.firstName }} {{ header.recipient.lastName }}
                      </span>
                    </div>
                  </div>
                  <v-layout row v-else :key="h.value">
                    <v-flex class="mt-1 pl-1 pt-0 pb-0 pr-0">
                      <v-checkbox
                        color="info"
                        :label="header.text"
                        :input-value="selectAllProperties"
                        class="header-checkbox"
                        @click.stop="selectAllColumnProperties"
                      ></v-checkbox>
                    </v-flex>
                    <v-flex class="d-contents">
                      <v-checkbox
                        primary
                        hide-details
                        :input-value="selectAllRecipients"
                        class="header-checkbox custom-mt"
                        @click="selectAllHeaderRecipients"
                      ></v-checkbox>
                    </v-flex>
                  </v-layout>
                </template>
                <template v-slot:item="{ item }">
                  <tr>
                    <td>
                      <div class="displayInlineBlock">
                        <v-checkbox
                          color="info"
                          :input-value="item.selectedProperty"
                          class="table-checkbox mt-0"
                          @click.stop="checkProperty(item)"
                        ></v-checkbox>
                        <span class="pt-1 pl-1">[<a href="" @click="openMapProperty(item)">{{ item.propertyId }}</a>] {{ item.street + ', ' + item.zip + ' ' + item.place }} </span>
                      </div>
                    </td>
                    <td v-for="header in recipientsHeaders" :key="header.recipient.recipientId">
                      <div class="pa-0" v-if="endOfMandate(item.recipientProperties, header.recipient.recipientId) && isAssigned(item.recipientProperties, header.recipient.recipientId)">
                        <v-icon color="gray">close</v-icon>
                        <p class="red--text mb-0">{{ $t('dossier.corporations.endOfMandate') }} {{ endOfMandate(item.recipientProperties, header.recipient.recipientId) }}</p>
                      </div>
                      <v-icon color="teal" v-else-if="isAssigned(item.recipientProperties, header.recipient.recipientId)">check</v-icon>
                    </td>
                  </tr>
                </template>
                <template slot="no-data">
                  <v-alert :value="true" color="error" icon="warning">
                      {{ $t('table.noData') }}
                  </v-alert>
                </template>
              </v-data-table>
            </v-card>
          </template>
      </v-flex>
      <div class="pl-1 pb-2 pt-4">
        <v-btn color="default" @click="goToCorporationsList()">
          <span>{{ $t('common.buttons.backToList') }}</span>
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </div>
    </v-layout>
    <ConfirmationDialog :data="deleteData" :show-dialog="showDeleteDialog" @closeDialog="closeDeleteDialog" @submitDialog="deletePrintout" />
  </v-container>
</template>

<script>
import PrintContainer from './PrintContainer'
import ConfirmationDialog from '@/components/ConfirmationDialog'
import Vue from 'vue'
import { formatDateAndTime } from '@/helpers/formatterHelper'
import { mapActions, mapGetters, mapState } from 'vuex'
import { MAP_PROPERTIES } from '@/config/options/routes/mapRoutes'
import { CORPORATION_DETAIL, RECIPIENT_DETAIL, CORPORATIONS } from '@/config/options/routes/dossierRoutes'

export default {
  name: CORPORATION_DETAIL,
  components: {
    PrintContainer,
    ConfirmationDialog
  },
  data () {
    return {
      corporationLoading: true,
      corporation: [],
      options: {
        sortBy: ['corporationId']
      },
      itemsPerPageOptions: [
        {
          'text': this.$t('common.all'),
          'value': -1
        }
      ],
      recipientsHeaders: [],
      showDeleteDialog: false,
      deleteDossierPrintHistoryId: null,
      selectAllRecipients: true,
      selectAllProperties: true
    }
  },
  watch: {
    year () {
      this.loadData()
    }
  },
  mounted () {
    this.loadData()
  },
  computed: {
    ...mapGetters('printer', ['getYear']),
    ...mapState('printer', ['year']),
    defaultHeader () {
      return [
        {
          text: this.$t('dossier.corporations.property'),
          align: 'left',
          value: 'propertiesString',
          sortable: false
        }
      ]
    },
    selectedCorporationMappedIDs () {
      return [
        {
          corporationId: this.corporation.corporationId,
          properties: this.corporation.properties ? this.corporation.properties.filter(x => x.selectedProperty).map(obj => obj.propertyId) : [],
          recipients: this.recipientsHeaders ? this.recipientsHeaders.filter(x => x.selectedRecipient).map(obj => obj.recipient.recipientId) : []
        }
      ]
    },
    deleteData () {
      return {
        title: this.$t('dossier.printerConfiguration.dialog.delete.title'),
        question: this.$t('dossier.printerConfiguration.dialog.delete.question'),
        confirmButton: this.$t('common.buttons.delete'),
        action: 'deletePrintout'
      }
    },
    concatHeaders () {
      return this.defaultHeader.concat(this.recipientsHeaders)
    }
  },
  methods: {
    ...mapActions('notifier', ['setSuccessSnackbar', 'setErrorSnackbar']),
    formatDateAndTimeFunc: formatDateAndTime,
    loadData () {
      let that = this
      this.$prestigeDossierCorporation.getCorporationById(this.$route.params.corporationId, this.year).then(response => {
        let lastSelectedCorporationData = that.selectedCorporationMappedIDs[0]
        let alreadyPrinted = lastSelectedCorporationData.corporationId !== undefined
        that.corporationLoading = false
        that.corporation = response.data
        if (that.corporation.properties && that.corporation.properties.length > 0) {
          that.corporation.properties.sort((a, b) => (a.street > b.street) ? 1 : ((b.street > a.street) ? -1 : 0))
          that.corporation.properties.forEach(property => {
            let properties = lastSelectedCorporationData.properties
            property.selectedProperty = !alreadyPrinted ? true : (properties && properties.includes(property.propertyId))
            property.propertiesString = '[' + property.propertyId + '] ' + property.street + ', ' + property.zip + ' ' + property.place
            property.recipientProperties.map(obj => {
              obj.endOfMandateFormatted = obj.endOfMandate ? this.formatDateAndTimeFunc(obj.endOfMandate) : null
            })
          })
        }
        that.recipientsHeaders = []
        if (that.corporation.recipients) {
          that.corporation.recipients.sort((a, b) => (a.firstName + a.lastName > b.firstName + b.lastName) ? 1 : ((b.firstName + b.lastName > a.firstName + a.lastName) ? -1 : 0))
          that.corporation.recipients.forEach(recipient => {
            let propRecipients = lastSelectedCorporationData.recipients
            let newHeader = {
              text: '[' + recipient.recipientId + '] ' + recipient.lastName + ' ' + recipient.firstName,
              align: 'left',
              sortable: false,
              recipient: recipient,
              selectedRecipient: !alreadyPrinted ? true : (propRecipients && propRecipients.includes(recipient.recipientId))
            }
            that.recipientsHeaders.push(newHeader)
          })
        }
      }).catch(error => {
        that.corporationLoading = false
        that.setErrorSnackbar(error)
      })
    },
    deletePrintout () {
      let that = this
      this.$prestigeDossierPrintHistory.deletePrintHistory(this.deleteDossierPrintHistoryId, this.year).then(response => {
        that.setSuccessSnackbar(that.$t('common.messages.successfullyDeleted'))
        that.closeDeleteDialog()
        that.loadData()
      }).catch(function (error) {
        that.setErrorSnackbar(error)
      })
    },
    downloadPrintPDF (dossierPrintHistoryId) {
      let that = this
      const config = { responseType: 'blob' }
      this.$prestigeDossierPrintHistory.downloadDossierPdf(dossierPrintHistoryId, config).then(response => {
        if (response.data) {
          const headers = response.headers
          const fileName = headers['content-disposition'].split('filename=')[1]
          const file = {
            data: response.data,
            name: fileName ? fileName.substring(1, fileName.length - 1) : ''
          }
          that.downloadFile(file)
          return true
        }
      }).catch(function (error) {
        that.setErrorSnackbar(error)
      })
    },
    downloadFile (file) {
      let fileDownload = require('js-file-download')
      fileDownload(file.data, file.name)
    },
    printHistoryDateFormatted (createdOn) {
      return this.formatDateAndTimeFunc(createdOn)
    },
    goToRecipientDetails (recipientId) {
      this.$router.push({ name: RECIPIENT_DETAIL, params: { recipientId: recipientId } })
    },
    sentToPrinter () {
      this.loadData()
    },
    checkRecipient (header) {
      this.selectAllRecipients = false
      header.selectedRecipient = !header.selectedRecipient
      let notSelectedRecipient = this.recipientsHeaders.filter(x => x.selectedRecipient === false)
      if (notSelectedRecipient && notSelectedRecipient.length === 0) {
        this.selectAllRecipients = true
      }
    },
    checkProperty (item) {
      this.selectAllProperties = false
      item.selectedProperty = !item.selectedProperty
      let index = this.corporation.properties.findIndex(x => x.propertyId === item.propertyId)
      Vue.set(this.corporation.properties, index, item)
      let notSelectedProperties = this.corporation.properties.filter(x => x.selectedProperty === false)
      if (notSelectedProperties && notSelectedProperties.length === 0) {
        this.selectAllProperties = true
      }
    },
    goToCorporationsList () {
      this.$router.push({ name: CORPORATIONS })
    },
    isAssigned (properties, recipientId) {
      return properties.some(el => el.recipientId === recipientId)
    },
    endOfMandate (properties, recipientId) {
      let recipientProperty = properties.find(x => x.recipientId === recipientId)
      return recipientProperty && recipientProperty.endOfMandate ? recipientProperty.endOfMandateFormatted : false
    },
    openDeleteDialog (index) {
      this.showDeleteDialog = true
      this.deleteDossierPrintHistoryId = index
    },
    closeDeleteDialog () {
      this.showDeleteDialog = false
      this.deleteDossierPrintHistoryId = null
    },
    openMapProperty (property) {
      this.$router.push({ name: MAP_PROPERTIES, params: { propertyId: property.propertyId.toString() } })
    },
    selectAllHeaderRecipients () {
      this.selectAllRecipients = !this.selectAllRecipients
      let that = this
      this.recipientsHeaders.forEach(item => {
        item.selectedRecipient = that.selectAllRecipients
      })
    },
    selectAllColumnProperties () {
      this.selectAllProperties = !this.selectAllProperties
      let that = this
      if (this.corporation.properties && this.corporation.properties.length > 0) {
        this.corporation.properties.forEach((item, index) => {
          item.selectedProperty = that.selectAllProperties
          Vue.set(that.corporation.properties, index, item)
        })
      }
    }
  }
}
</script>

<style scoped lang="postcss">
.ul-none {
  left: 15px;
  right: 0;
  list-style: none;
  overflow-y: auto;
  padding: 0;
}
@media (min-width: 960px) {
  .ul-none {
    position: absolute;
    max-height: calc(100% - 156px);
  }
}
.inline-flex {
  display: inline-flex;
}
.recipient {
  line-height: 2rem;
}
/deep/ .table-checkbox label {
  font-size: .875rem;
  color:rgba(0,0,0,.87)
}
/deep/ .header-checkbox label {
  font-size: .75rem;
  color: rgba(0,0,0,.6);
}
.displayInlineBlock {
  display: inline-flex !important;
}
.d-contents {
  display: contents;
}
.custom-mt {
  margin-top: 19px;
  margin-bottom: 5px;
}
</style>
