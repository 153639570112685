import i18n from '@/i18n/i18n'

export const decisionOptions = [
  {
    value: 'COMMITMENT',
    label: i18n.t('renting.statuses.COMMITMENT')
  },
  {
    value: 'CANCELLATION_BY_INTERESTED_PERSON',
    label: i18n.t('renting.statuses.CANCELLATION_BY_INTERESTED_PERSON')
  },
  {
    value: 'ALREADY_RENTED',
    label: i18n.t('renting.statuses.ALREADY_RENTED')
  },
  {
    value: 'NOT_ELIGIBLE',
    label: i18n.t('renting.statuses.NOT_ELIGIBLE')
  }
]

export const statusOptions = [
  {
    value: 'IN_PROGRESS',
    label: i18n.t('renting.statuses.IN_PROGRESS')
  },
  {
    value: 'PRIORITY_2',
    label: i18n.t('renting.statuses.PRIORITY_2')
  },
  {
    value: 'PRIORITY_1',
    label: i18n.t('renting.statuses.PRIORITY_1')
  },
  {
    value: 'COMMITMENT',
    label: i18n.t('renting.statuses.COMMITMENT')
  },
  {
    value: 'CANCELLATION_BY_INTERESTED_PERSON',
    label: i18n.t('renting.statuses.CANCELLATION_BY_INTERESTED_PERSON')
  },
  {
    value: 'ALREADY_RENTED',
    label: i18n.t('renting.statuses.ALREADY_RENTED')
  },
  {
    value: 'NOT_ELIGIBLE',
    label: i18n.t('renting.statuses.NOT_ELIGIBLE')
  }
]
