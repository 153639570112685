<template>
  <Pie
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Pie } from 'vue-chartjs/legacy'
import autocolors from 'chartjs-plugin-autocolors'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale, autocolors)

export default {
  name: 'PieChart',
  components: {
    Pie
  },
  props: {
    chartId: {
      type: String,
      default: 'pie-chart'
    },
    chartOptions: {
      type: Object,
      default: () => {}
    },
    chartData: {
      type: Object,
      default: () => {}
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    },
    labels: {
      type: Array,
      default: () => []
    },
    filters: {
      type: Object,
      default: () => {}
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  // computed: {
  //   chartData () {
  //     return {
  //       labels: ['Created', 'Completed'],
  //       datasets: this.datasets
  //     }
  //   },
  //   datasets () {
  //     if (this.data) {
  //       let createdTotal = 0
  //       let completedTotal = 0
  //       this.data?.created.forEach(x => {
  //         createdTotal += x.numberOfTasks
  //       })
  //       this.data?.completed.forEach(x => {
  //         completedTotal += x.numberOfTasks
  //       })
  //       return [
  //         {
  //           backgroundColor: ['#f87979', '#1E81B0'],
  //           data: [createdTotal, completedTotal]
  //         }
  //       ]
  //     }
  //     return []
  //   }
  // }
}
</script>
