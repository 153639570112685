<template>
  <v-slide-y-transition mode="out-in">
    <v-card class="pl-2 pr-2 pb-2 mt-3 grey lighten-5">
      <v-card-title class="pl-1 pb-0">
        <v-icon class="pr-2">mdi-database-import</v-icon>{{ $t('dataImport.immoSparrow.title') }}
      </v-card-title>
      <v-layout wrap class="row pa-2">
        <v-flex class="pl-2 pr-1 mt-2">
            <v-card height="100%">
              <v-container fluid>
                <div class="inline float-left mb-2">
                  <v-btn
                    class="primary ma-2 immo-sparrow-btn"
                    :loading="marketRentsLoading"
                    :disabled="marketRentsLoading"
                    @click="triggerImportReport(reportNames.MARKET_REPORT, 'marketRentsLoading')"
                  >
                    <v-icon class="pr-2">mdi-database-plus</v-icon> {{ $t('dataImport.immoSparrow.marketRents') }}
                  </v-btn>
                </div>
              </v-container>
            </v-card>
        </v-flex>
      </v-layout>
    </v-card>
  </v-slide-y-transition>
</template>

<script>
import { mapActions } from 'vuex'
import { REPORT_NAMES } from '@/config/options/reportNames'

export default {
  name: 'ImmoSparrowImport',
  data () {
    return {
      reportNames: REPORT_NAMES,
      marketRentsLoading: false,
    }
  },
  methods: {
    ...mapActions('notifier', ['setSuccessSnackbar', 'setErrorSnackbar']),
    triggerImportReport (reportName, loading) {
      let that = this
      that[loading] = true
      that.$abacusImporter.importReport(reportName).then(response => {
        if (that.responseSuccess(response.status)) {
          that[loading] = false
          that.triggerSuccessMessage()
        }
      }).catch(error => {
        that[loading] = false
        that.setErrorSnackbar(error)
      })
    },
    triggerSuccessMessage () {
      return this.setSuccessSnackbar(this.$t('dataImport.success'))
    },
    responseSuccess (status) {
      return status === 200 || status === 204
    }
  }
}
</script>

<style scoped>
</style>
