<template>
  <v-container fluid fill-height grid-list-lg>
    <v-layout row class="main-layout">
      <v-flex xs6 sm6 md6 lg6 class="orders-layout" ref="constructionProjectDetailRef">
        <v-layout row>
          <v-flex>
            <h2>{{ $t('navigation.settings') }}</h2>
          </v-flex>
          <v-flex class="text-right mr-1" v-if="!mapLoading">
            <v-chip
              :color="latestUpdatedOn(constructionProjects.latestUpdatedOn) > 3 ? 'red' : 'green'"
              text-color="white"
            >
              <span>{{ $t('constructionProjects.latest') }} {{ latestUpdatedOnFormatted(constructionProjects.latestUpdatedOn) }}</span>
            </v-chip>
          </v-flex>
        </v-layout>
        <v-slider
          class="pt-6"
          v-model="sliderValue"
          ticks
          persistent-hint
          thumb-label="always"
          min="0"
          max="10000"
          step="50"
          :label="$t('constructionProjects.maxDistance')"
          :hint="$t('constructionProjects.between') + ' ' + sliderValue + ' ' + $t('constructionProjects.meter')"
          @change="onSliderValueChange"
        ></v-slider>
        <v-autocomplete
          ref="realEstateManager"
          :label="$t('tasks.email.realEstateManager')"
          :loading="realEstateManagerLoading"
          cache-items
          single-line
          return-object
          :items="realEstateManagerDisplayItems"
          item-text="displayText"
          append-outer-icon="clear"
          @click:append-outer="clearRealEstateManagerFilter"
          item-value="addressId"
          :search-input.sync="realEstateManagerSearch"
          v-model="realEstateManagerFilter"
        ></v-autocomplete>
        <v-layout row class="pl-2">
          <v-checkbox
            :label="$t('constructionProjects.showIgnored')"
            v-model="config.displayIgnored"
          ></v-checkbox>
        </v-layout>
        <ConstructionProjectsDetail
          :current-construction-project-detail="currentConstructionProjectsDetail"
          :real-estate-manager-id="realEstateManagerFilter ? realEstateManagerFilter.addressId.toString() : null"
          @loadConstructionProjects="loadConstructionProjects"
        />
        <ConstructionProjectsTable
          :construction-projects="constructionProjects.buildingProjects"
          :current-construction-project="currentConstructionProject"
          @showConstructionProjectInfoWindow="showConstructionProjectInfoWindow"
        />
      </v-flex>
      <v-layout flex column xs6 sm6 md6 lg6 class="map-layout">
        <ConstructionProjectsMap
          :construction-projects="constructionProjects"
          :current-construction-project="currentConstructionProject"
          :properties="propertiesDetails"
          :maxDistance="config.maxDistance"
          @showConstructionProjectInfoWindow="showConstructionProjectInfoWindow"
        />
      </v-layout>
    </v-layout>
    <SpinnerOverlay :loading="spinnerOverlay" />
  </v-container>
</template>

<script>
import ConstructionProjectsMap from './ConstructionProjectsMap'
import ConstructionProjectsDetail from './ConstructionProjectsDetail'
import ConstructionProjectsTable from './ConstructionProjectsTable'
import SpinnerOverlay from '@/components/SpinnerOverlay'

import { AuthenticationContext } from 'vue-adal'
import { mapActions, mapGetters, mapState } from 'vuex'
import moment from 'moment'
import { formatDate } from '@/helpers/formatterHelper'
import { CONSTRUCTION_PROJECTS } from '@/config/options/routes/mapRoutes'

export default {
  name: CONSTRUCTION_PROJECTS,
  components: {
    ConstructionProjectsDetail,
    ConstructionProjectsMap,
    ConstructionProjectsTable,
    SpinnerOverlay
  },
  data () {
    return {
      config: {
        maxDistance: 100,
        displayIgnored: false
      },
      sliderValue: 100,
      currentConstructionProject: null,
      currentConstructionProjectsDetail: {},
      realEstateManagerSearch: null,
      realEstateManagerFilter: null,
      constructionProjects: {},
      constructionProjectStatus: {},
      mapLoading: true,
      spinnerOverlay: false,
      defaultRealEstate: ''
    }
  },
  computed: {
    ...mapState('abacus', ['realEstateManagers', 'realEstateManagerDisplayItems', 'realEstateManagerLoading']),
    ...mapGetters('abacus', ['getRealEstateManagers', 'getRealEstateManagerDisplayItems', 'getRealEstateManagerLoading']),
    propertiesDetails () {
      if (this.currentConstructionProjectsDetail && this.currentConstructionProjectsDetail.status) {
        return this.currentConstructionProjectsDetail.status
      }
      return []
    },
    routeBuildingProjectId () {
      return this.$route.params.buildingProjectId
    }
  },
  created () {
    Promise.all([this.$store.dispatch('abacus/loadRealEstateManagers')]).then(() => {
      this.initRealEstate()
      this.loadConstructionProjects()
    })
  },
  watch: {
    '$route': 'initConstructionProjects',
    'realEstateManagerFilter': 'hideDetailsAndLoadConstructionProjects',
    'config.displayIgnored': 'hideDetailsAndLoadConstructionProjects'
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    formatDateFunc: formatDate,
    clearRealEstateManagerFilter () {
      this.realEstateManagerFilter = null
    },
    initRealEstate () {
      let loggedInUserId = AuthenticationContext && AuthenticationContext.user && AuthenticationContext.user.profile ? AuthenticationContext.user.profile.employeeid : null
      if (loggedInUserId) {
        this.realEstateManagerFilter = this.setDefaultRealEstate(loggedInUserId)
      }
    },
    setDefaultRealEstate (loggedInUserId) {
      if (this.realEstateManagerDisplayItems) {
        let userEstateManager = this.realEstateManagerDisplayItems.find(x => x.addressId.toString() === loggedInUserId.toString())
        if (userEstateManager) return userEstateManager
      }
      return null
    },
    loadConstructionProjects () {
      this.spinnerOverlay = true
      let that = this
      let params = {
        radius: this.config.maxDistance,
        realEstateManagerId: this.realEstateManagerFilter ? this.realEstateManagerFilter.addressId : undefined,
        displayIgnored: this.config.displayIgnored
      }
      this.$prestigeConstructionProjects.getAllBuildingProjectsWithProperties(params.radius, params.realEstateManagerId, params.displayIgnored).then(function (response) {
        that.constructionProjects = response.data
        if (that.constructionProjects.buildingProjects && that.constructionProjects.buildingProjects.length > 0) {
          that.constructionProjects.buildingProjects.forEach(item => {
            item.sortText = item.title + item.addressLine1 + item.place
          })
        }
        that.mapLoading = false
        that.spinnerOverlay = false
        that.initConstructionProjects()
        return true
      }).catch(error => {
        that.setErrorSnackbar(that.$t('common.errors.dataNotLoaded') + error)
        that.mapLoading = false
        that.spinnerOverlay = false
      })
    },
    initConstructionProjects () {
      if (!this.routeBuildingProjectId) {
        this.currentConstructionProject = null
        this.currentConstructionProjectsDetail = {}
      }
      if (this.routeBuildingProjectId && this.constructionProjects && this.constructionProjects.buildingProjects) {
        this.currentConstructionProject = this.constructionProjects.buildingProjects.find(constructionProject => constructionProject.buildingProjectId === this.routeBuildingProjectId)
        if (!this.currentConstructionProject) {
          this.$router.push({ name: CONSTRUCTION_PROJECTS })
        } else {
          this.showConstructionProjectInfoWindow(this.currentConstructionProject, this.routeBuildingProjectId)
        }
      }
      this.spinnerOverlay = false
    },
    showConstructionProjectInfoWindow (constructionProject, buildingProjectId) {
      this.spinnerOverlay = true
      this.currentConstructionProject = constructionProject
      let that = this
      let params = {
        radius: this.config.maxDistance,
        realEstateManagerId: this.realEstateManagerFilter ? this.realEstateManagerFilter.addressId : null,
        displayIgnored: this.config.displayIgnored
      }
      this.$prestigeConstructionProjects.getBuildingProjectWithProperties(buildingProjectId, params.radius, params.realEstateManagerId, params.displayIgnored).then(function (response) {
        if (response.data) {
          that.currentConstructionProjectsDetail = response.data
        }
        that.$nextTick(() => {
          if (that.$refs.constructionProjectDetailRef) {
            that.$refs.constructionProjectDetailRef.scrollTop = 100
          }
          that.spinnerOverlay = false
        })
        return true
      }).catch(error => {
        that.currentConstructionProject = null
        that.currentConstructionProjectsDetail = {}
        that.setErrorSnackbar(that.$t('common.errors.dataNotLoaded') + error)
        that.mapLoading = false
        that.spinnerOverlay = false
      })
    },
    latestUpdatedOnFormatted (latestUpdatedOn) {
      return this.formatDateFunc(latestUpdatedOn)
    },
    latestUpdatedOn (latestUpdatedOn) {
      let days = moment().diff(moment(latestUpdatedOn), 'days')
      return days
    },
    hideDetailsAndLoadConstructionProjects () {
      this.spinnerOverlay = true
      if (this.$route.params.buildingProjectId) {
        this.$router.push({ name: CONSTRUCTION_PROJECTS })
      } else {
        this.loadConstructionProjects()
      }
    },
    onSliderValueChange (value) {
      if (this.config.maxDistance !== value) {
        this.config.maxDistance = value
        this.hideDetailsAndLoadConstructionProjects()
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main-layout {
  position: relative;
}
.map-layout {
  flex: 0 0 50%;
}
.orders-layout {
  position: absolute;
  overflow: auto;
  top: 0;
  bottom: 0;
  width:100%;
}
.spinner {
  margin-left: 200px;
}
</style>
