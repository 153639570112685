<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Mietzinsbestandteil
                </th>
                <th class="text-right">
                  Bisheriger Betrag
                </th>
                <th class="text-left">
                  Neuer betrag
                </th>
                <th class="text-left">
                  Bemerkung
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(component, index) in rentalContractComponents" :key="'rental-component-' + index">
                <th>{{component.rentalComponent}}</th>
                <td class="text-right">{{formatAmount(component.amount)}}</td>
                <td>
                  <v-text-field
                    placeholder="Neuer Betrag"
                    dense
                    hide-details
                    v-model="component.newAmount"
                    @change="updateRentComponents"
                    @keyup="updateRentComponents"
                    :disabled="formReadOnly"
                  />
                </td>
                <td>
                  <v-text-field
                    placeholder="Bemerkung"
                    dense
                    hide-details
                    v-model="component.comment"
                    @change="updatedField"
                    :disabled="formReadOnly"
                  />
                </td>
              </tr>

              <tr>
                <th>Depot</th>
                <td class="text-right"></td>
                <td>
                  <v-text-field
                    label="Depot"
                    v-model="deposit"
                    :hint="depositHint"
                    persistent-hint
                    @change="updatedField"
                    :disabled="formReadOnly"
                  />
                </td>
                <td>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as ticketingHelper from '@/features/ticketing/helpers/ticketingHelper'
import { formatAmount } from '@/helpers/formatterHelper'
import _sum from 'lodash.sum'

export default {
  name: 'TerminationRentalComponentsProposal',
  props: {
    processVariables: {
      type: Array,
      default: () => {}
    },
    formReadOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      rentalContractComponents: [],
      deposit: 0,
      depositHint: ''
    }
  },
  components: {
  },
  watch: {
    'processVariables': {
      handler: 'initProcessVariables',
      immediate: true
    }
  },
  computed: {
  },
  methods: {
    formatAmount: formatAmount,
    initProcessVariables () {
      this.rentalContractComponents = this.getVariableValue('System_RentalContractComponents_JSON')
      this.deposit = this.getVariableValue('System_ReLetting_Deposit_Long')
    },
    updateRentComponents () {
      // recalculate deposit
      const factor = 3
      let sum = _sum(this.rentalContractComponents.map(c => c.newAmount ? Number(c.newAmount) : 0))
      this.deposit = factor * sum
      this.depositHint = factor + ' x Bruttomiete ' + this.formatAmount(sum) + ' = ' + this.formatAmount(factor * sum)
      this.updatedField()
    },
    updatedField () {
      this.$emit('updateFormValues', { formFields: [
        {
          formFieldId: 'System_RentalContractComponents_JSON',
          formFieldType: 'json',
          formFieldDefaultValue: this.rentalContractComponents
        },
        {
          formFieldId: 'System_ReLetting_Deposit_Long',
          formFieldType: 'long',
          formFieldDefaultValue: this.deposit
        }
      ]})
    },
    getVariableValue (variableName) {
      return ticketingHelper.getVariableValue(this.processVariables, variableName)
    }
  }
}
</script>

<style scoped lang="postcss">
</style>
