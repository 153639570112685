<template>
  <div>
    <v-progress-linear indeterminate v-if="$apollo.loading || !processVariablesInitialized"></v-progress-linear>
    <template v-else>
      <v-row dense>
        <v-col cols="12">
          <RentalComponents
            :rent-components="rentComponents"
            :earliest-start="earliestStart"
            :deposit="deposit"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-btn color="" target="_blank" :href="'https://abacus.prestige.ch:40001/abastart?mandant=10&program=immo.11&RealEstateNumber=' + propertyId + '&ObjectNumber=' + objectId">
            <span class="abacus-logo mr-2"></span> {{ $t('ticketingSystem.processes.rentingForm.createContract.abacusLink') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-radio-group
            row
            v-model="confirmation"
            required
            :rules="[rules.radioRequired]"
            @change="updatedField"
          >
            <v-list-item-subtitle class="subtitle-1 mb-2">
              {{ $t('ticketingSystem.processes.rentingForm.createContract.confirmation') }}
            </v-list-item-subtitle>
            <v-radio class="pr-5 mr-0" :label="$t('common.options.yes')" value="CONFIRMATION_YES" :disabled="formReadOnly" />
            <v-radio class="pr-5 mr-0" :label="$t('common.options.no')" value="CONFIRMATION_NO" :disabled="formReadOnly" />
            <template #message="{ message }">
              <span v-if="message === 'required'">{{ requiredMessage }}</span>
            </template>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            v-model="confirmationComment"
            :label="$t('ticketingSystem.processes.rentingForm.createContract.confirmationComment')"
            :disabled="formReadOnly"
            @change="updatedField"
          >
            <template #message="{ message }">
              <span v-if="message === 'required'">{{ requiredMessage }}</span>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <DatePicker
            v-model="moveInDate"
            :default-value="false"
            :default-value-date="moveInDate"
            :label="$t('ticketingSystem.processes.rentingForm.createContract.moveInDate')"
            :disabled="formReadOnly"
            :required="confirmation === 'CONFIRMATION_YES'"
            :rules="confirmation === 'CONFIRMATION_YES'? [rules.required] : []"
            attach-selector
            @input="updatedField"
          />
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import * as ticketingHelper from '@/features/ticketing/helpers/ticketingHelper'
import { getGraphQLVariable } from '@/helpers/graphQLHelper'
import { formatDate, formatAmount } from '@/helpers/formatterHelper'
import DatePicker from '@/components/DatePicker'
import RentalComponents from '../process-specific-context/common/RentalComponents'
import { RELETTING_TICKETING_TICKET } from '../../graphql-apollo/relettingTicketingTicket.gql'

export default {
  name: 'RentingFormConfirmation',
  props: {
    processVariables: {
      type: Array,
      default: () => {}
    },
    formReadOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      processVariablesInitialized: false,
      moveInDate: null,
      confirmation: null,
      confirmationComment: '',
      ticketLoadError: '',
      rules: {
        required: (value) => !!value || this.$t('common.errors.required'),
        checkboxRequired: (value) => (value !== undefined && value !== '' && value !== null && value) || this.requiredMessage,
        radioRequired: (value) => (value !== undefined && value !== '' && value !== null) || this.requiredMessage
      }
    }
  },

  apollo: {
    relettingTicketingTicket: {
      query: RELETTING_TICKETING_TICKET,
      skip () {
        return !this.relettingProcessId
      },
      error (error) {
        this.ticketLoadError = error.message
      },
      variables () {
        return {
          ticketId: this.relettingProcessId
        }
      }
    }
  },
  watch: {
    'processVariables': {
      handler: 'initProcessVariables',
      immediate: true
    }
  },
  components: {
    RentalComponents,
    DatePicker
  },
  computed: {
    propertyId () {
      return this.getVariableValue('System_RentalCase_Property')
    },
    objectId () {
      return this.getVariableValue('System_RentalCase_PropertyObject')
    },
    relettingProcessId () {
      return this.getVariableValue('System_ProcessParentReference_UUID')
    },
    rentComponents () {
      return getGraphQLVariable(this.relettingTicketingTicket?.variables, 'System_RentalContractComponents_JSON', 'rentComponents', [])
    },
    deposit () {
      return getGraphQLVariable(this.relettingTicketingTicket?.variables, 'System_ReLetting_Deposit_Long', 'longValue', 0)
    },
    earliestStart () {
      return getGraphQLVariable(this.relettingTicketingTicket?.variables, 'System_ReLetting_EarliestStart_Date', 'dateValue')
    },
    requiredMessage () {
      return this.$t('common.errors.required')
    }
  },
  methods: {
    formatDate: formatDate,
    formatAmount: formatAmount,
    initProcessVariables () {
      if (this.processVariables) {
        this.confirmation = this.getVariableValue('System_InterestedPerson_Confirmation_Enum')
        this.confirmationComment = this.getVariableValue('System_InterestedPerson_ConfirmationComment_String')
        this.moveInDate = this.getVariableValue('System_MoveIn_Date')
        this.processVariablesInitialized = true
      }
    },
    getVariableValue (variableName) {
      return ticketingHelper.getVariableValue(this.processVariables, variableName)
    },
    updatedField () {
      this.$emit('updateFormValues', { formFields: [
        {
          formFieldId: 'System_MoveIn_Date',
          formFieldType: 'date',
          formFieldDefaultValue: this.moveInDate
        },
        {
          formFieldId: 'System_InterestedPerson_Confirmation_Enum',
          formFieldType: 'enum',
          formFieldDefaultValue: this.confirmation
        },
        {
          formFieldId: 'System_InterestedPerson_ConfirmationComment_String',
          formFieldType: 'string',
          formFieldDefaultValue: this.confirmationComment
        }
      ]})
    }
  }
}
</script>

<style scoped lang="postcss">
.abacus-logo {
  width: 80px;
  content: url('../../../../assets/icons/abacus-logo.svg');
}
</style>
