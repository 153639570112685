<template>
  <div>
    <v-row dense>
      <v-col>
        <v-select
          autofocus
          :items="decisionOptionItems"
          item-value="value"
          item-text="label"
          required
          :rules="[rules.required]"
          :label="$t('ticketingSystem.processes.interestedPerson.decision.title')"
          v-model="decision"
          @change="updatedField"
        >
        </v-select>
        <AutocompleteLinks
          ref="decisionComment"
          class="reason-autocomplete"
          v-model="decisionComment"
          :default-html="decisionComment"
          :label="$t('ticketingSystem.processes.interestedPerson.decision.reason')"
          :validate-clicked="validateClicked"
          @input="updatedField"
          @autocompleteLinksUpdated="onAutocompleteLinksUpdated"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import * as ticketingHelper from '@/features/ticketing/helpers/ticketingHelper'
import * as globalHelper from '@/helpers/globalHelper'
import { formatDate } from '@/helpers/formatterHelper'
import { decisionOptions } from '@/config/options/decision'
import AutocompleteLinks from '../../components/AutocompleteLinks'

export default {
  name: 'RentingFormOfflineDecision',
  props: {
    processVariables: {
      type: Array,
      default: () => {
      }
    },
    validateClicked: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      overrideRentalContractId: null,
      valid: false,
      decision: null,
      decisionComment: '',
      rentalContracts: [],
      rentalContractsLoading: false,
      decisionOptionItems: decisionOptions,
      rules: {
        required: (value) => !!value || this.$t('common.errors.required')
      }
    }
  },
  components: {
    AutocompleteLinks
  },
  watch: {
    'processVariables': {
      handler: 'initProcessVariables',
      immediate: true
    }
  },
  computed: {
    requiredMessage () {
      return this.$t('common.errors.required')
    }
  },
  methods: {
    formatDate: formatDate,
    onAutocompleteLinksUpdated (autocompleteLinks) {
      this.$emit('autocompleteLinksUpdated', autocompleteLinks)
      this.updatedField()
    },
    customFilter (item, queryText, itemText) {
      return globalHelper.propertyFilter(queryText, itemText)
    },
    initProcessVariables () {
      const decisionVariable = ticketingHelper.getVariableValue(this.processVariables, 'System_InterestedPerson_Decision_Enum')
      if (decisionVariable !== 'IN_PROGRESS' && decisionVariable !== 'PRIORITY_2' && decisionVariable !== 'PRIORITY_1') {
        this.decision = decisionVariable
      } else {
        this.decision = null
      }
      this.decisionComment = ticketingHelper.getVariableValue(this.processVariables, 'System_InterestedPerson_DecisionComment_String')
    },
    updatedField () {
      this.$emit('updateFormValues', {
        formFields: [
          {
            formFieldId: 'System_InterestedPerson_Decision_Enum',
            formFieldType: 'string',
            formFieldDefaultValue: this.decision ? this.decision : 'IN_PROGRESS'
          },
          {
            formFieldId: 'System_InterestedPerson_DecisionComment_String',
            formFieldType: 'string',
            formFieldDefaultValue: this.decisionComment
          }
        ]
      })
    }
  }
}
</script>

<style scoped lang="postcss">
/deep/ .reason-autocomplete .editor {
  height: 90px;
}
</style>
