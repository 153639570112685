<template>
  <v-card class="mt-1">
    <v-card-title class="pb-0">
      <v-icon class="pr-2">print</v-icon>{{ $t('dossier.printerConfiguration.currentPrinter') }}
      <v-btn icon small color="teal" @click.stop="editConfiguration()">
        <v-icon small>edit</v-icon>
      </v-btn>
    </v-card-title>
    <PrinterConfigurationDialog
      :show-dialog="showPrinterConfigurationDialog"
      :printer-configuration="printerConfigurationEditPayload"
      :printer-supported-media-sources="printerStatus.supportedMediaSources"
      :printer-initial-url="initialUrl"
      :disable-btn="disableBtn"
      @closeDialog="closePrinterConfigurationDialog"
      @savePrinterConfiguration="savePrinterConfiguration"
    />
    <v-card-text v-if="printerStatusLoading">
      <v-progress-circular indeterminate  color="red"></v-progress-circular>
    </v-card-text>
    <v-card-text v-else-if="printerStatus.connected && !printerConfiguration">
      <v-alert :value="true" type="warning">{{ $t('dossier.printerConfiguration.noConfiguration') }}</v-alert>
    </v-card-text>
    <v-card-text v-else>
      <v-list>
        <v-list-item-content>
          <v-list-item-title>{{ $t('common.data.name') }}</v-list-item-title>
          <v-list-item-subtitle class="pt-1 light-grey">{{ printerStatus.name }}</v-list-item-subtitle>
          <v-list-item-title>{{ $t('common.data.url') }}</v-list-item-title>
          <v-list-item-subtitle class="pt-1 light-grey">{{ printerConfiguration.printerUrl }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-content v-if="printerConfiguration">
          <table>
            <tr>
              <th class="text-left">{{ $t('dossier.printerConfiguration.config.mediaSources') }}</th>
              <th class="text-left">{{ $t('dossier.printerConfiguration.config.configuration')}}</th>
            </tr>
            <tr v-for="(mediaSource, mediaSourceKey) in printerConfiguration.mediaSources" :key="mediaSourceKey">
              <td>{{ $t('dossier.documentTypeConfiguration.addDocumentType.paperTray.options.' + mediaSourceKey) }}</td>
              <td>
                <v-icon small color="red" :title="$t('dossier.printerConfiguration.config.missing')" v-if="!mediaSource">warning</v-icon>
                <v-icon small color="red" :title="$t('dossier.printerConfiguration.config.invalid')" v-else-if="!supportsPaperTray(mediaSource)">warning</v-icon>
                {{ mediaSource }}
              </td>
            </tr>
          </table>
        </v-list-item-content>
        <v-list-item-content>
          <v-list-item-title>{{ $t('dossier.printerConfiguration.status') }}</v-list-item-title>
          <v-list-item-subtitle v-if="printerStatus.connected && printerConfiguration" class="pt-1">
            <v-alert :value="true" type="success" class="mb-0">
              {{ $t('dossier.printerConfiguration.availablePrinter') }} {{ printerStatus.jobCount }}
            </v-alert>
          </v-list-item-subtitle>
          <v-list-item-subtitle v-else-if="printerStatus.connected && !printerConfiguration">
            <v-alert :value="true" type="error" class="mb-0">
              {{ $t('dossier.printerConfiguration.settingsIncomplete') }}
            </v-alert>
          </v-list-item-subtitle>
          <v-list-item-subtitle v-else-if="!printerStatus.connected && printerConfiguration">
            <v-alert :value="true" type="error" class="mb-0">{{ $t('dossier.printerConfiguration.statusUnavailable') }}</v-alert>
          </v-list-item-subtitle>
          <v-list-item-subtitle v-else-if="!printerStatus.connected && !printerConfiguration">
            <v-alert :value="true" type="error" class="mb-0">{{ $t('dossier.printerConfiguration.statusUnknown') }}</v-alert>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import PrinterConfigurationDialog from './PrinterConfigurationDialog.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import _cloneDeep from 'lodash.clonedeep'

export default {
  name: 'PrinterConfiguration',
  components: {
    PrinterConfigurationDialog
  },
  data () {
    return {
      disableBtn: false,
      showPrinterConfigurationDialog: false,
      printerConfigurationEditPayload: {},
      initialUrl: ''
    }
  },
  computed: {
    ...mapGetters('printer', ['getPrinterConfiguration', 'getPrinterStatus', 'getPrinterStatusLoading']),
    ...mapState('printer', ['printerConfiguration', 'printerStatus', 'printerStatusLoading'])
  },
  methods: {
    ...mapActions('notifier', ['setSuccessSnackbar', 'setErrorSnackbar']),
    editConfiguration () {
      this.printerConfigurationEditPayload = _cloneDeep(this.printerConfiguration)
      this.initialUrl = _cloneDeep(this.printerConfiguration.printerUrl)
      this.showPrinterConfigurationDialog = true
    },
    closePrinterConfigurationDialog () {
      this.showPrinterConfigurationDialog = false
      this.printerConfigurationEditPayload = {}
      this.disableBtn = false
    },
    supportsPaperTray (mediaSource) {
      return this.printerStatus.supportedMediaSources ? this.printerStatus.supportedMediaSources.includes(mediaSource) : false
    },
    savePrinterConfiguration (data) {
      this.disableBtn = true
      let that = this
      this.$prestigeDossierPrinter.updatePrinterConfiguration(data).then(response => {
        that.closePrinterConfigurationDialog()
        that.$store.dispatch('printer/fetchPrinterConfiguration')
        that.setSuccessSnackbar(that.$t('common.messages.successfullyUpdated'))
      }).catch(error => {
        that.setErrorSnackbar(error)
      })
    }
  }
}
</script>
<style scoped>
.light-grey {
  color:grey;
}
</style>
