<template>
  <v-layout
    row wrap
    class="ma-3"
    v-if="candidateGroups.length > 0"
  >
    <ChartsHeaderFilters
      @updateLabels="setLabelsAndData"
      @updateFilters="onUpdateFilters"
    />
    <template v-if="filters">
      <v-flex
        class="pa-1"
        xs5 sm6 md6 lg6
        v-for="(group, index) in candidateGroups"
        :key="index"
      >
        <GroupCharts
          v-if="responseData"
          :title="group.groupName"
          :labels="labels"
          :filters="filters"
          :data="responseData"
        />
      </v-flex>
    </template>
  </v-layout>
</template>

<script>
import GroupCharts from './GroupCharts'
import ChartsHeaderFilters from '../ticketing-statistics/components/ChartsHeaderFilters'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'ChartExamples',
  components: {
    GroupCharts,
    ChartsHeaderFilters
  },
  data () {
    return {
      labels: [],
      filters: null,
      responseData: {}
    }
  },
  created () {
    if (this.candidateGroups?.length === 0) {
      this.loadCandidateGroups()
    }
    this.setLabelsAndData()
  },
  computed: {
    ...mapState('ticketingSystem', ['candidateGroups'])
  },
  methods: {
    ...mapActions('ticketingSystem', ['loadCandidateGroups']),
    getData () {
      this.responseData = {
        created: [
          {
            date: '08-05-2022',
            numberOfTasks: 5
          },
          {
            date: '09-05-2022',
            numberOfTasks: 3
          }
        ],
        completed: [
          {
            date: '08-05-2022',
            numberOfTasks: 6
          },
          {
            date: '09-05-2022',
            numberOfTasks: 1
          }
        ]
      }
    },
    onUpdateFilters (filters) {
      this.filters = filters
    },
    setLabelsAndData (labels) {
      this.labels = labels
      this.getData()
    }
  }
}
</script>