<template>
  <v-card class="mb-2 pa-0" v-if="links.length > 0">
    <v-card-text class="pa-4">
      <v-icon color="primary" @click="toggle" class="float-right">{{ icon }}</v-icon>
      <v-card-subtitle class="pa-0 font-weight-bold subtitle-1">
        {{ $t('common.' + title) }}
      </v-card-subtitle>
    </v-card-text>
    <v-divider></v-divider>
    <v-list v-if="isOpen" class="context overflow-auto">
      <template v-for="(addressId, index) in links">
        <ProcessAddressLink
          v-if="addressType !== 'tenant'"
          :key="index"
          :addressId="addressId"
          :addressType="addressType"
          :deleteAction="deleteAction"
          :processInstanceId="processInstanceId"
          @openNewTicketDialog="onOpenNewTicketDialog"
        />
        <ProcessAddressTenantLink
          v-else
          :key="index"
          :addressId="addressId"
          :addressType="addressType"
          :deleteAction="deleteAction"
          :processInstanceId="processInstanceId"
          @openNewTicketDialog="onOpenNewTicketDialog"
        />
        <v-divider :key="'divider_' + index" class="mt-4"></v-divider>
      </template>
    </v-list>
  </v-card>
</template>
<script>
import ProcessAddressLink from './ProcessAddressLink'
import ProcessAddressTenantLink from './ProcessAddressTenantLink'

export default {
  name: 'ProcessAddressLinks',
  components: {
    ProcessAddressLink,
    ProcessAddressTenantLink
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    links: {
      type: Array,
      default: () => []
    },
    addressType: {
      type: String,
      default: ''
    },
    deleteAction: {
      type: Object,
      default: () => {}
    },
    processInstanceId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isOpen: true
    }
  },
  computed: {
    icon () {
      return this.isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
    }
  },
  methods: {
    toggle () {
      this.isOpen = !this.isOpen
    },
    onOpenNewTicketDialog (links = [], subject, processDefinitionKey = null, variables = [], property = null, object = null) {
      this.$emit('openNewTicketDialog', links, subject, processDefinitionKey, variables, property, object)
    }
  }
}
</script>
<style scoped lang="postcss">
/deep/ .v-expansion-panel-content__wrap {
  padding: 0px;
}
</style>
