<template>
  <div>
    <v-card :class="'pa-4 mb-4 cy-rental-form-resident-' + index" v-for="(resident, index) in mutableForm.residents" :key="'resident-' + index">
      <v-card-title class="pa-0 pb-2 mb-1">
        {{ index === 0 ? $t('renting.publicForm.fields.personInfo.title') : $t('renting.publicForm.fields.additionalResidentialPartner.title') }}
        <v-spacer></v-spacer>
        <v-btn
          v-if="index > 0 && !readOnly && residential"
          color="red"
          class="white--text"
          @click="openDeleteDialog(index)"
        >
          {{ $t('renting.publicForm.fields.additionalResidentialPartner.remove') }}
          <v-icon>mdi-minus</v-icon>
        </v-btn>
      </v-card-title>
      <PersonForm
        :form="resident"
        :first-resident="index === 0"
        :read-only="readOnly"
        :residential="residential"
        @validate="validate"
      ></PersonForm>
    </v-card>

    <v-card v-if="residential && !readOnly" class="pa-4 mb-4">
      <v-card-title class="pa-0">
        <v-btn
          @click="addResident"
          color="green"
          class="white--text"
        >
          {{ $t('renting.publicForm.fields.additionalResidentialPartner.add') }}
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <span class="ml-2">{{ $t('renting.publicForm.fields.additionalResidentialPartner.title') }}</span>
      </v-card-title>
    </v-card>

    <ConfirmationDialog
      v-if="!readOnly && residential"
      :data="deleteData"
      :show-dialog="showDeleteDialog"
      @closeDialog="closeDeleteDialog"
      @submitDialog="removeResident"
    />
  </div>
</template>

<script>
import PersonForm from './PersonForm'
import ConfirmationDialog from '../../../../components/ConfirmationDialog'
import * as interestedPersonHelper from './../../helpers/interestedPersonHelper'

export default {
  name: 'ResidentsForm',
  data () {
    return {
      showDeleteDialog: false,
      deleteItemId: null
    }
  },
  props: {
    form: {
      type: Object,
      default: () => {}
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    residential: {
      type: Boolean,
      default: false
    }
  },
  components: {
    PersonForm,
    ConfirmationDialog
  },
  computed: {
    deleteData () {
      return {
        title: this.$t('renting.publicForm.fields.additionalResidentialPartner.confirmTitle'),
        question: this.$t('renting.publicForm.fields.additionalResidentialPartner.confirmMessage'),
        confirmButton: this.$t('renting.publicForm.fields.additionalResidentialPartner.confirmButton'),
      }
    },
    mutableForm () {
      return this.form
    }
  },
  methods: {
    validate () {
      this.$emit('validate')
    },
    removeResident () {
      this.mutableForm.residents.splice(this.deleteItemId, 1)
      this.closeDeleteDialog()
    },
    openDeleteDialog (index) {
      this.showDeleteDialog = true
      this.deleteItemId = index
    },
    closeDeleteDialog () {
      this.showDeleteDialog = false
      this.deleteItemId = null
    },
    addResident () {
      this.mutableForm.residents.push(interestedPersonHelper.initPersonForm())
    }
  }
}
</script>
