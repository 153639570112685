import Vue from 'vue'
import Vuex from 'vuex'
import { cloneDeep } from 'lodash'
import { AuthenticationContext } from 'vue-adal'
import * as ticketingHelper from '@/features/ticketing/helpers/ticketingHelper'

Vue.use(Vuex)

const state = {
  createProcessDefinitions: [],
  listProcessDefinitions: [],
  processDefinitionsLoading: false,
  assignedTasks: [],
  assignedTasksLoading: false,
  taskEntries: [],
  taskEntriesLoading: false,
  candidateGroups: [],
  candidateGroupsLoading: false,
  userCandidateGroups: [],
  candidateGroupTasks: [],
  candidateGroupTasksLoading: true,
  ticketingUsers: [],
  ticketingUsersDisplayItems: [],
  ticketingUsersLoading: true,
  ticketingListFilters: {
    includeAssigned: false,
    includeFollowUpInFuture: true
  },
  processesListFilters: {
    state: "ACTIVE"
  },
  startFormDefinitions: []
}

const getters = {
  getCreateProcessDefinitions: state => state.createProcessDefinitions,
  getListProcessDefinitions: state => state.listProcessDefinitions,
  getProcessDefinitionsLoading: state => state.processDefinitionsLoading,
  getAssignedTasks: state => state.assignedTasks,
  getAssignedTasksLoading: state => state.assignedTasksLoading,
  getTaskEntries: state => state.taskEntries,
  getTaskEntriesLoading: state => state.taskEntriesLoading,
  getCandidateGroups: state => state.candidateGroups,
  getCandidateGroupsLoading: state => state.candidateGroupsLoading,
  getUserCandidateGroups: state => state.userCandidateGroups,
  getCandidateGroupTasks: state => state.candidateGroupTasks,
  getCandidateGroupTasksLoading: state => state.candidateGroupTasksLoading,
  getTicketingUsers: state => state.ticketingUsers,
  getTicketingUsersDisplayItems: state => state.ticketingUsers,
  getTicketingUsersLoading: state => state.ticketingUsersLoading,
  getTicketingListFilters: state => state.ticketingListFilters,
  getProcessesListFilters: state => state.processesListFilters,
  getStartFormDefinitions: state => state.startFormDefinitions
}

const actions = {
  setCreateProcessDefinitions ({ commit }, createProcessDefinitions) {
    commit('SET_CREATE_PROCESS_DEFINITIONS', createProcessDefinitions)
  },
  setListProcessDefinitions ({ commit }, listProcessDefinitions) {
    commit('SET_LIST_PROCESS_DEFINITIONS', listProcessDefinitions)
  },
  setProcessDefinitionsLoading ({ commit }) {
    commit('SET_PROCESS_DEFINITIONS_LOADING')
  },
  setAssignedTasks ({ commit }, assignedTasks) {
    commit('SET_ASSIGNED_TASKS', assignedTasks)
  },
  setAssignedTasksLoading ({ commit }, loading) {
    commit('SET_ASSIGNED_TASKS_LOADING', loading)
  },
  setTaskEntries ({ commit }, taskEntries) {
    commit('SET_TASK_ENTRIES', taskEntries)
  },
  setTaskEntriesLoading ({ commit }, loading) {
    commit('SET_TASK_ENTRIES_LOADING', loading)
  },
  setCandidateGroups ({ commit }, taskEntries) {
    commit('SET_CANDIDATE_GROUPS', taskEntries)
  },
  setCandidateGroupsLoading ({ commit }) {
    commit('SET_CANDIDATE_GROUPS_LOADING')
  },
  setUserCandidateGroups ({ commit }, userCandidateGroups) {
    commit('SET_USER_CANDIDATE_GROUPS', userCandidateGroups)
  },
  resetCandidateGroupTasks ({ commit }) {
    commit('RESET_CANDIDATE_GROUP_TASKS')
  },
  pushCandidateGroupTasks ({ commit }, newGroupTasks) {
    commit('PUSH_CANDIDATE_GROUP_TASKS', newGroupTasks)
  },
  setCandidateGroupTasksLoading ({ commit }, loading) {
    commit('SET_CANDIDATE_GROUP_TASKS_LOADING', loading)
  },
  setTicketingUsers ({ commit }, ticketingUsers) {
    commit('SET_TICKETING_USERS', ticketingUsers)
  },
  setTicketingUsersDisplayItems ({ commit }, ticketingUsersDisplayItems) {
    commit('SET_TICKETING_USERS_DISPLAY_ITEMS', ticketingUsersDisplayItems)
  },
  setTicketingUsersLoading ({ commit }) {
    commit('SET_TICKETING_USERS_LOADING')
  },
  updateTicketingListFilters ({ commit }, filter) {
    commit('UPDATE_TICKETING_LIST_FILTERS', filter)
  },
  clearTicketingListFilters ({ commit }) {
    commit('CLEAR_TICKETING_LIST_FILTERS')
  },
  updateProcessesListFilters ({ commit }, filter) {
    commit('UPDATE_PROCESSES_LIST_FILTERS', filter)
  },
  clearProcessesListFilters ({ commit }) {
    commit('CLEAR_PROCESSES_LIST_FILTERS')
  },
  loadProcessDefinitions ({ state, dispatch }) {
    if (state.createProcessDefinitions && state.createProcessDefinitions.length === 0 &&
      state.listProcessDefinitions && state.listProcessDefinitions.length === 0
    ) {
      dispatch('setProcessDefinitionsLoading')
      return Vue.prototype.$prestigeTSProcess.getProcessDefinitions().then(async function (response) {
        let responseData = cloneDeep(response.data)
        let createProcessDefinitions = responseData
        let listProcessDefinitions = responseData
        if (!Vue.prototype.$showAllProcessDefinitions) {
          createProcessDefinitions = await dispatch('filterCreateProcessDefinitions', createProcessDefinitions)
          listProcessDefinitions = await dispatch('filterListProcessDefinitions', listProcessDefinitions)
        }
        dispatch('setCreateProcessDefinitions', createProcessDefinitions)
        dispatch('setListProcessDefinitions', listProcessDefinitions)
        return createProcessDefinitions
      }).catch(error => {
        dispatch('notifier/setErrorSnackbar', { text: error.message }, { root: true })
        dispatch('resetProcessDefinitionsLists')
      })
    }
  },
  async filterCreateProcessDefinitions ({ dispatch }, createProcessDefinitions) {
    let filtered = createProcessDefinitions.filter(x =>
      x.processDefinitionKey !== 'C2_02_03' &&
      x.processDefinitionKey !== 'C2_02_10' &&
      x.processDefinitionKey !== 'C2_02_07' &&
      x.processDefinitionKey !== 'Z1_00' &&
      x.processDefinitionKey !== 'Z1_02' &&
      x.processDefinitionKey !== 'Z1_03' &&
      x.processDefinitionKey !== 'Z1_04' &&
      x.processDefinitionKey !== 'Z1_05' &&
      x.processDefinitionKey !== 'Z1_06' &&
      x.processDefinitionKey !== 'Z1_07' &&
      x.processDefinitionKey !== 'Z1_08' &&
      x.processDefinitionKey !== 'Z1_09'
    )
    return filtered
  },
  async filterListProcessDefinitions ({ dispatch }, listProcessDefinitions) {
    let filtered = listProcessDefinitions.filter(x =>
      x.processDefinitionKey !== 'Z1_00' &&
      x.processDefinitionKey !== 'Z1_02' &&
      x.processDefinitionKey !== 'Z1_03' &&
      x.processDefinitionKey !== 'Z1_04' &&
      x.processDefinitionKey !== 'Z1_05' &&
      x.processDefinitionKey !== 'Z1_06' &&
      x.processDefinitionKey !== 'Z1_07' &&
      x.processDefinitionKey !== 'Z1_08' &&
      x.processDefinitionKey !== 'Z1_09'
    )
    return filtered
  },
  resetProcessDefinitionsLists ({ dispatch }) {
    dispatch('setCreateProcessDefinitions', [])
    dispatch('setListProcessDefinitions', [])
  },
  loadAssignedTasks ({ dispatch }) {
    let userId = AuthenticationContext && AuthenticationContext.user && AuthenticationContext.user.profile ? AuthenticationContext.user.profile.employeeid : null
    dispatch('setAssignedTasksLoading', true)
    let queryParams = { query: state.ticketingListFilters }
    Vue.prototype.$prestigeTSTask.getTaskListForUser(userId, undefined, queryParams).then(async (response) => {
      let tasks = response.data.tasks
      if (tasks && tasks.length > 0) {
        for (const [index, item] of tasks.entries()) {
          if (item.assignee) {
            item.assigneeUserName = await ticketingHelper.getUserName(item.assignee.toString())
            Vue.set(tasks, index, item)
          }
        }
      }
      dispatch('setAssignedTasks', tasks)
      dispatch('setAssignedTasksLoading', false)
    }).catch(error => {
      dispatch('notifier/setErrorSnackbar', { text: error.message }, { root: true })
      dispatch('setAssignedTasks', [])
      dispatch('setAssignedTasksLoading', false)
    })
  },
  loadTaskEntries ({ dispatch }) {
    dispatch('setTaskEntriesLoading', true)
    let queryParams = { query: state.ticketingListFilters }
    Vue.prototype.$prestigeTSTask.getTaskListForCandidateGroup(undefined, undefined, queryParams)
      .then(async (response) => {
        let tasks = response.data.tasks
        if (tasks && tasks.length > 0) {
          for (const [index, item] of tasks.entries()) {
            if (item.assignee) {
              item.assigneeUserName = await ticketingHelper.getUserName(item.assignee.toString())
              Vue.set(tasks, index, item)
            }
          }
        }
        dispatch('setTaskEntries', tasks)
        dispatch('setTaskEntriesLoading', false)
      }).catch(error => {
        dispatch('notifier/setErrorSnackbar', { text: error.message }, { root: true })
        dispatch('setTaskEntries', [])
        dispatch('setTaskEntriesLoading', false)
      })
  },
  loadCandidateGroups ({ dispatch }) {
    let userRoles = AuthenticationContext && AuthenticationContext.user && AuthenticationContext.user.profile ? AuthenticationContext.user.profile.roles : null
    if (state.candidateGroups && state.candidateGroups.length === 0) {
      dispatch('setCandidateGroupsLoading')
      return Vue.prototype.$prestigeTSTask.getListOfCandidateGroups().then(function (response) {
        let status = response.data
        let userCandidateGroups = []
        if (userRoles) {
          userCandidateGroups = status.filter(x => userRoles.includes(x.groupId))
        }
        dispatch('setCandidateGroups', status)
        dispatch('setUserCandidateGroups', userCandidateGroups)
        return userCandidateGroups
      }).catch(error => {
        dispatch('notifier/setErrorSnackbar', { text: error.message }, { root: true })
        dispatch('setCandidateGroups', [])
        dispatch('setUserCandidateGroups', [])
      })
    } else {
      let userCandidateGroups
      if (userRoles) {
        userCandidateGroups = state.candidateGroups.filter(x => userRoles.includes(x.groupId))
      }
      dispatch('setUserCandidateGroups', userCandidateGroups)
    }
  },
  loadCandidateGroupTasks ({ dispatch }) {
    if (state.userCandidateGroups.length > 0) {
      let queryParams = { query: state.ticketingListFilters }
      state.userCandidateGroups.forEach(item => {
        Vue.prototype.$prestigeTSTask.getTaskListForCandidateGroup(item.groupId, undefined, queryParams)
          .then(async (response) => {
            let newGroupTasks = {
              groupId: item.groupId,
              tasks: response.data.tasks
            }
            if (newGroupTasks.tasks && newGroupTasks.tasks.length > 0) {
              for (const [index, item] of newGroupTasks.tasks.entries()) {
                if (item.assignee) {
                  item.assigneeUserName = await ticketingHelper.getUserName(item.assignee.toString())
                  Vue.set(newGroupTasks.tasks, index, item)
                }
              }
            }
            dispatch('pushCandidateGroupTasks', newGroupTasks)
          }).catch(error => {
            dispatch('notifier/setErrorSnackbar', { text: error.message }, { root: true })
            dispatch('resetCandidateGroupTasks')
            dispatch('setCandidateGroupTasksLoading', false)
          })
      })
      dispatch('setCandidateGroupTasksLoading', false)
    } else {
      dispatch('setCandidateGroupTasksLoading', false)
    }
  },
  loadTicketingUsers ({ dispatch }) {
    if (state.ticketingUsers && state.ticketingUsers.length === 0) {
      dispatch('setTicketingUsersLoading')
      return Vue.prototype.$prestigeTSUsers.getPrestigeUsers().then(function (response) {
        let users = response.data
        let displayItems = []
        users.forEach(item => {
          let displayListItem = {
            userId: item.userId,
            displayText: '[' + item.userId + '] ' + item.name
          }
          displayItems.push(displayListItem)
        })
        dispatch('setTicketingUsers', users)
        dispatch('setTicketingUsersDisplayItems', displayItems)
        return users
      }).catch(error => {
        dispatch('notifier/setErrorSnackbar', { text: error.message }, { root: true })
        dispatch('setTicketingUsers', [])
        dispatch('setTicketingUsersDisplayItems', [])
      })
    }
  },
  loadStartFormDefinition ({ state, dispatch }, processDefinitionId) {
    let startFormDefinitionExists = state.startFormDefinitions.find(x => x.processDefinitionId === processDefinitionId)
    if (!startFormDefinitionExists) {
      return Vue.prototype.$prestigeTSStartForm.getStartFormDefinition(processDefinitionId).then(response => {
        let startFormDefinition = response.data
        if (startFormDefinition) {
          startFormDefinition.processDefinitionId = processDefinitionId
          dispatch('pushStartFormDefinition', startFormDefinition)
        }
        return startFormDefinition
      }).catch(error => {
        dispatch('notifier/setErrorSnackbar', { text: error.message }, { root: true })
      })
    } else {
      return startFormDefinitionExists
    }
  },
  pushStartFormDefinition ({ commit }, newStartFormDefinition) {
    commit('PUSH_START_FORM_DEFINITION', newStartFormDefinition)
  }
}

const mutations = {
  SET_CREATE_PROCESS_DEFINITIONS (state, createProcessDefinitions) {
    state.createProcessDefinitions = createProcessDefinitions
    state.processDefinitionsLoading = false
  },
  SET_LIST_PROCESS_DEFINITIONS (state, listProcessDefinitions) {
    state.listProcessDefinitions = listProcessDefinitions
    state.processDefinitionsLoading = false
  },
  SET_PROCESS_DEFINITIONS_LOADING (state) {
    state.processDefinitionsLoading = true
  },
  SET_ASSIGNED_TASKS (state, assignedTasks) {
    state.assignedTasks = assignedTasks
  },
  SET_ASSIGNED_TASKS_LOADING (state, loading) {
    state.assignedTasksLoading = loading
  },
  SET_TASK_ENTRIES (state, taskEntries) {
    state.taskEntries = taskEntries
  },
  SET_TASK_ENTRIES_LOADING (state, loading) {
    state.taskEntriesLoading = loading
  },
  SET_CANDIDATE_GROUPS (state, candidateGroups) {
    state.candidateGroups = candidateGroups
    state.candidateGroupsLoading = false
  },
  SET_CANDIDATE_GROUPS_LOADING (state) {
    state.candidateGroupsLoading = true
  },
  SET_USER_CANDIDATE_GROUPS (state, userCandidateGroups) {
    state.userCandidateGroups = userCandidateGroups
  },
  RESET_CANDIDATE_GROUP_TASKS (state) {
    state.candidateGroupTasks = []
  },
  PUSH_CANDIDATE_GROUP_TASKS (state, newGroupTasks) {
    let index = state.candidateGroupTasks.findIndex(x => x.groupId === newGroupTasks.groupId)
    if (index === -1) {
      state.candidateGroupTasks = [...state.candidateGroupTasks, newGroupTasks]
    } else {
      Vue.set(state.candidateGroupTasks, index, newGroupTasks)
    }
  },
  SET_CANDIDATE_GROUP_TASKS_LOADING (state, loading) {
    state.candidateGroupTasksLoading = loading
  },
  SET_TICKETING_USERS (state, ticketingUsers) {
    Vue.set(state, 'ticketingUsers', cloneDeep(ticketingUsers))
    state.ticketingUsersLoading = false
  },
  SET_TICKETING_USERS_DISPLAY_ITEMS (state, ticketingUsersDisplayItems) {
    Vue.set(state, 'ticketingUsersDisplayItems', cloneDeep(ticketingUsersDisplayItems))
  },
  SET_TICKETING_USERS_LOADING (state) {
    state.ticketingUsersLoading = true
  },
  UPDATE_TICKETING_LIST_FILTERS (state, filters) {
    Vue.set(state, 'ticketingListFilters', filters)
  },
  CLEAR_TICKETING_LIST_FILTERS (state) {
    Vue.set(state, 'ticketingListFilters', {
      includeAssigned: false,
      includeFollowUpInFuture: true,
      processDefinitionKey: 'Z1_01'
    })
  },
  UPDATE_PROCESSES_LIST_FILTERS (state, filters) {
    Vue.set(state, 'processesListFilters', filters)
  },
  CLEAR_PROCESSES_LIST_FILTERS (state) {
    Vue.set(state, 'processesListFilters', { state: "ACTIVE" })
  },
  PUSH_START_FORM_DEFINITION (state, newStartFormDefinition) {
    let index = state.startFormDefinitions.findIndex(x => x.processDefinitionId === newStartFormDefinition.processDefinitionId)
    index = index === -1 ? state.startFormDefinitions.length : index
    Vue.set(state.startFormDefinitions, index, newStartFormDefinition)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
