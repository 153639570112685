import { render, staticRenderFns } from "./CandidateGroups.vue?vue&type=template&id=56d97f36&scoped=true&"
import script from "./CandidateGroups.vue?vue&type=script&lang=js&"
export * from "./CandidateGroups.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56d97f36",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
installComponents(component, {VCardText,VCheckbox})
