export function hashCode (code) {
  let hash = 0
  let chr
  if (!code || code.length === 0) return hash
  for (let i = 0; i < code.length; i++) {
    chr = code.charCodeAt(i)
    hash = ((hash << 5) - hash) + chr
    hash |= 0 // Convert to 32bit integer
  }
  return hash < 0 ? hash * -1 : hash
}
