<template>
  <div>
    <v-card class="mt-3" v-for="(resident, index) in residents" v-bind:key="'resident-' + index">
      <ResidentInfo :resident="resident" />
      <v-divider></v-divider>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left" width="50%">{{ $t('renting.decision.decisionTitle') }}</th>
              <th class="text-left" width="20%">{{ $t('common.option.value') }}</th>
              <th class="text-left" width="30%">{{ $t('renting.comment') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!parking">
              <td>{{ $t('renting.decision.apartmentConclusion') }}: {{ $t('renting.publicForm.fields.personInfo.apartment.status_' + resident.currentApartmentStatus) }}</td>
              <td>
                <v-icon
                  :color="resident.apartmentConclusion  ? 'green' : 'red'"
                  class="mb-1"
                >
                  {{ resident.apartmentConclusion  ? 'checked' : 'close' }}
                </v-icon>
              </td>
              <td>
                <span class="text-pre-wrap">{{ resident.apartmentConclusionComment }}</span>
              </td>
            </tr>
            <tr>
              <td>{{ $t('renting.decision.employmentConclusion') }}: {{ $t('renting.publicForm.fields.personInfo.employment.status_' + resident.employmentStatus) }}</td>
              <td>
                <v-icon
                  :color="resident.employmentConclusion  ? 'green' : 'red'"
                  class="mb-1"
                >
                  {{ resident.employmentConclusion  ? 'checked' : 'close' }}
                </v-icon>
              </td>
              <td>
                <span class="text-pre-wrap">{{ resident.employmentConclusionComment }}</span>
              </td>
            </tr>
            <tr>
              <td>{{ $t('renting.decision.creditRatingConclusion') }}</td>
              <td>
                <v-icon
                  :color="resident.creditRatingConclusion  ? 'green' : 'red'"
                  class="mb-1"
                >
                  {{ resident.creditRatingConclusion  ? 'checked' : 'close' }}
                </v-icon>
              </td>
              <td>
                <span class="text-pre-wrap">{{ resident.creditRatingConclusionComment }}</span>
              </td>
            </tr>
            <tr v-if="!parking">
              <td>{{ $t('renting.decision.identityDocumentsConclusion') }}</td>
              <td>
                <v-icon
                  :color="resident.identityDocumentsConclusion  ? 'green' : 'red'"
                  class="mb-1"
                >
                  {{ resident.identityDocumentsConclusion  ? 'checked' : 'close' }}
                </v-icon>
              </td>
              <td>
                <span class="text-pre-wrap">{{ resident.identityDocumentsComment }}</span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <v-card class="mt-3" v-if="newApartment">
      <v-list-item class="ma-0 pa-0 bg-gray">
        <v-list-item-title>
          <v-card-title>
            {{ $t('renting.publicForm.fields.newApartment.additionalInformation.title') }}
          </v-card-title>
        </v-list-item-title>
      </v-list-item>
      <v-divider></v-divider>
      <v-row v-if="!parking" class="px-4 py-3 mt-1">
        <v-col cols="4" class="py-0">
          <span class="subtitle-2">{{ $t('renting.publicForm.fields.newApartment.additionalInformation.numberOfAdults') }}:</span>
          <span>{{ newApartment.numberOfAdults }}</span>
        </v-col>
        <v-col cols="4" class="py-0">
          <span class="subtitle-2">{{ $t('renting.publicForm.fields.newApartment.additionalInformation.numberOfChildren') }}:</span>
          <span>{{ newApartment.numberOfChildren }}</span>
        </v-col>
        <v-col cols="4" class="py-0">
          <span class="subtitle-2 font-weight-bold">{{ $t('renting.publicForm.fields.newApartment.residenceChangeReason') }}:</span>
          <span>{{ newApartment.reasonForChange }}</span>
        </v-col>
      </v-row>
      <RentalObjects :new-apartment="newApartment" :parking="parking" />
    </v-card>
    <v-card class="mt-3 pb-6">
      <v-list-item class="ma-0 pa-0 bg-gray">
        <v-list-item-title>
          <v-card-title>
            {{ $t('renting.decision.interestedPersonDecision') }}
          </v-card-title>
        </v-list-item-title>
      </v-list-item>
      <v-divider></v-divider>
      <v-card-text>
        <v-select
          autofocus
          v-model="interestedPersonDecision"
          item-value="value"
          item-text="label"
          :label="$t('ticketingSystem.processes.interestedPerson.decision.title')"
          :items="decisionOptions"
          :required="true"
          :rules="[rules.required]"
          :disabled="formReadOnly"
        >
        </v-select>
        <AutocompleteLinks
          ref="decisionComment"
          v-model="interestedPersonDecisionComment"
          required
          :label="$t('ticketingSystem.processes.interestedPerson.decision.reason')"
          :validate-clicked="validateClicked"
          :disabled="formReadOnly"
          @autocompleteLinksUpdated="onAutocompleteLinksUpdated"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ResidentInfo from './shared/ResidentInfo'
import RentalObjects from './../../shared/RentalObjects'
import AutocompleteLinks from '@/features/ticketing/components/AutocompleteLinks'

import { decisionOptions } from '@/config/options/decision'
import * as ticketingHelper from '@/features/ticketing/helpers/ticketingHelper'
import { getGraphQLVariable } from '@/helpers/graphQLHelper'
import { mapActions } from 'vuex'

import { TICKETING_TICKET_EXECUTION } from '../../graphql-apollo/ticketingTicket.gql'

import { useRentingForm } from '@/use/useRentingForm'
const { loadRentingForm } = useRentingForm()

const PARKING = 'PARKING'

export default {
  name: 'RentingFormDecision',
  components: {
    ResidentInfo,
    RentalObjects,
    AutocompleteLinks
  },
  props: {
    ticketingTask: {
      type: Object,
      default: () => {}
    },
    processVariables: {
      type: Array,
      default: () => {
      }
    },
    formReadOnly: {
      type: Boolean,
      default: false
    },
    validateClicked: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      rentingForm: {},
      interestedPersonDecision: '',
      interestedPersonDecisionComment: '',
      decisionOptions: decisionOptions,
      rules: {
        required: (value) => (!!value && value !== undefined) || this.requiredMessage
      }
    }
  },
  apollo: {
    executionVariables: {
      query: TICKETING_TICKET_EXECUTION,
      skip () {
        return !this.ticketId || !this.executionId
      },
      error (error) {
        this.ticketLoadError = error.message
      },
      variables () {
        return {
          ticketId: this.ticketId,
          executionId: this.executionId
        }
      }
    }
  },
  watch: {
    'rentingFormId': {
      handler: 'getRentalFormData',
      immediate: true
    },
    interestedPersonDecision () {
      this.updatedField()
    },
    interestedPersonDecisionComment () {
      this.updatedField()
    }
  },
  computed: {
    residents () {
      let residents = this.rentingForm?.content?.residents
      if (residents && this.token) {
        return residents.filter(resident => resident.landlordToken === this.token)
      } else if (residents) {
        return residents
      } else {
        return []
      }
    },
    newApartment () {
      return this.rentingForm?.content?.newApartment
    },
    rentingFormId () {
      return this.getVariableValue('System_RentingFormId_Long')
    },
    ticketId () {
      return this.ticketingTask?.ticket?.ticketId
    },
    executionId () {
      return this.ticketingTask?.executionId
    },
    token () {
      return getGraphQLVariable(this.executionVariables?.variables, 'landlordToken', 'stringValue')
    },
    requiredMessage () {
      return this.$t('common.errors.required')
    },
    parking () {
      return this.ticketingTask.objectType === PARKING
    }
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    async getRentalFormData () {
      this.rentingForm = await loadRentingForm(this.rentingFormId)
      this.loading = false
    },
    async saveThirdPartyData () {
      await this.$prestigeRenting.updateRentingForm(this.rentingFormId, this.rentingForm.content)
    },
    getVariableValue (variableName) {
      return ticketingHelper.getVariableValue(this.processVariables, variableName)
    },
    onAutocompleteLinksUpdated (autocompleteLinks) {
      this.$emit('autocompleteLinksUpdated', autocompleteLinks)
      this.updatedField()
    },
    updatedField () {
      this.$emit('updateFormValues', { formFields: [
        {
          formFieldId: 'System_InterestedPerson_Decision_Enum',
          formFieldType: 'enum',
          formFieldDefaultValue: this.interestedPersonDecision
        },
        {
          formFieldId: 'System_InterestedPerson_DecisionComment_String',
          formFieldType: 'textarea',
          formFieldDefaultValue: this.interestedPersonDecisionComment
        }
      ]})
    }
  }
}
</script>

<style scoped lang="postcss">
/deep/ .v-input--is-readonly {
  cursor: not-allowed;
  pointer-events: none;
}
.color-card-text {
  color: rgba(0,0,0,.6);
}
.bg-gray {
  background-color: #f5f5f5;
}
</style>
