import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
  printerConfiguration: {},
  printerStatus: {},
  printerStatusLoading: true,
  year: new Date().getFullYear(),
  savedFilters: null
}
const getters = {
  getPrinterConfiguration: state => state.printerConfiguration,
  getPrinterStatus: state => state.printerStatus,
  getPrinterStatusLoading: state => state.printerStatusLoading,
  getYear: state => state.year,
  getSavedFilters: state => state.savedFilters
}
const mutations = {
  SET_PRINTER_CONFIGURATION: (state, configuration) => {
    state.printerConfiguration = configuration
  },
  SET_PRINTER_STATUS: (state, status) => {
    state.printerStatus = status
  },
  SET_PRINTER_STATUS_LOADING: (state, loading) => {
    state.printerStatusLoading = loading
  },
  SET_YEAR: (state, year) => {
    state.year = year
  },
  SET_SAVED_FILTERS: (state, savedFilters) => {
    state.savedFilters = savedFilters
  },
  RESET_SAVED_FILTERS: (state) => {
    state.savedFilters = null
  }
}

const actions = {
  setPrinterConfiguration ({ commit }, configuration) {
    commit('SET_PRINTER_CONFIGURATION', configuration)
  },
  setPrinterStatus ({ commit }, status) {
    commit('SET_PRINTER_STATUS', status)
  },
  setPrinterStatusLoading ({ commit }, loading) {
    commit('SET_PRINTER_STATUS_LOADING', loading)
  },
  setYear ({ commit }, year) {
    commit('SET_YEAR', year)
  },
  setSavedFilters ({ commit }, savedFilters) {
    commit('SET_SAVED_FILTERS', savedFilters)
  },
  resetSavedFilters ({ commit }) {
    commit('RESET_SAVED_FILTERS')
  },
  fetchPrinterStatus ({ dispatch }) {
    Vue.prototype.$prestigeDossierPrinter.getPrinterStatus().then(function (response) {
      let status = response.data
      dispatch('setPrinterStatus', status)
      dispatch('setPrinterStatusLoading', false)
    }).catch(function (error) {
      dispatch('notifier/setErrorSnackbar', { text: error.message }, { root: true })
      dispatch('setPrinterStatusLoading', true)
    })
  },
  fetchPrinterConfiguration ({ dispatch }) {
    Vue.prototype.$prestigeDossierPrinter.getPrinterConfiguration().then(function (response) {
      let configuration = response.data
      dispatch('setPrinterConfiguration', configuration)
    }).catch(function (error) {
      dispatch('notifier/setErrorSnackbar', { text: error.message }, { root: true })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
