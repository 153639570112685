import AuthenticationContext from 'vue-adal'

// config-v2.js is included in index.html and overwritten in the runtime
/* global config:true */

export const authenticate = function(adal) {
  let clientId = config.AZURE_APP_CLIENT_ID
  return new Promise((resolve, reject) => {
    adal.acquireToken(clientId, (err, token) => {
      if (err) {
        let errCode = err.split(':')[0]
        switch (errCode) {
          case 'AADSTS50058':
            // Need to prompt for user sign in
            AuthenticationContext.login()
            break
          case 'AADSTS65001':
            // Token is invalid; grab a new one
            AuthenticationContext.acquireTokenRedirect(clientId)
            break
          case 'AADSTS16000': // No Access
          default:
            // Need a pop-up forcing a login
            AuthenticationContext.login()
            break
        }
        reject(err)
      } else {
        resolve(token)
      }
    })
  })
}
