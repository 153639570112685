<template>
  <div>
    <ProcessComment
      :process-instance-id="processInstanceId"
      :process-variables="processVariables"
      @loadDetails="loadDetails"
      @loadProcessReferences="onLoadProcessReferences"
    />
    <BotOverrideContext ref="processContext" :process="process" :process-variables="processVariables" @loadDetails="loadDetails" />
    <ProcessOfflineRentingForm v-if="isOfflineRentingFormProcess" :process-variables="processVariables" :process-instance-id="processInstanceId" @loadDetails="loadDetails" />
    <ProcessRentingForm
      v-if="isRentingFormProcess"
      :process-variables="processVariables"
      :process-instance-id="processInstanceId"
      @loadDetails="loadDetails" />
    <ProcessTermination v-if="isTerminationProcess" :process-variables="processVariables" />
    <ProcessReletting v-if="isRelettingProcess" :process-variables="processVariables" />
    <ReoccurringProcessInfo
      v-if="isTicketProcess"
      :process-variables="processVariables"
      :process-instance-id="processInstanceId"
      :is-process-finished="processFinished"
      @loadDetails="loadDetails"
    ></ReoccurringProcessInfo>
    <ProcessContext
      ref="processContext"
      :process-instance-id="processInstanceId"
      :process-finished="processFinished"
      :process-references="processReferences"
      :process-references-links="processReferencesLinks"
      @loadDetails="loadDetails"
      @openNewTicketDialog="onOpenNewTicketDialog"
      @deleteReferenceLink="onDeleteReferenceLink"
      @loadProcessReferences="onLoadProcessReferences"
    />
    <ProcessFileVariables
      v-if="routeTaskId"
      :created-on="createdOn"
      :process-variables="processVariables"
      :process-instance-id="processInstanceId"
      @loadProcessVariables="loadProcessVariables"
    />
    <ProcessReferences ref="processReferences" :process-references="processReferences" />
    <TicketDialog
      v-if="shouldShowNewTicketDialog"
      :process-instance="processInstance"
      :new-links="newLinks"
      :default-subject="subject"
      :process-definition-key="newTicketDialogProcessDefinitionKey"
      :variables="newTicketDialogVariables"
      @closeDialog="shouldShowNewTicketDialog = false"
      @processInstanceCreated="onProcessInstanceCreated"
      @loadProcessVariables="loadProcessVariables"
    />
  </div>
</template>
<script>
import BotOverrideContext from './process-common-context/BotOverrideContext'
import ProcessOfflineRentingForm from './process-specific-context/ProcessOfflineRentingForm'
import ProcessRentingForm from './process-specific-context/ProcessRentingForm'
import ProcessContext from './process-common-context/ProcessContext'
import ProcessReferences from './process-references/ProcessReferences'
import ProcessTermination from './process-specific-context/ProcessTermination'
import ProcessReletting from './process-specific-context/ProcessReletting'
import TicketDialog from '@/features/ticketing/dialogs/TicketDialog'
import ProcessComment from './process-specific-context/ProcessComment'
import ProcessFileVariables from '../shared/ProcessFileVariables.vue'
import ReoccurringProcessInfo from "@/features/ticketing/shared/process-common-context/ReoccurringProcessInfo";

export default {
  name: 'ProcessInstanceContext',
  components: {
    ReoccurringProcessInfo,
    BotOverrideContext,
    ProcessOfflineRentingForm,
    ProcessRentingForm,
    ProcessContext,
    ProcessReferences,
    ProcessTermination,
    ProcessReletting,
    TicketDialog,
    ProcessComment,
    ProcessFileVariables
  },
  props: {
    process: {
      type: Object,
      default: () => {}
    },
    processVariables: {
      type: Array,
      default: () => []
    },
    processReferences: {
      type: Object,
      default: () => {}
    },
    processReferencesLinks: {
      type: Array,
      default: () => []
    },
    processInstance: {
      type: Boolean,
      default: false
    },
    createdOn: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      shouldShowNewTicketDialog: false,
      newLinks: [],
      newTicketDialogProcessDefinitionKey: null,
      newTicketDialogVariables: [],
      subject: ''
    }
  },
  computed: {
    processDefinitionKey () {
      return this.process ? this.process.processDefinitionKey : null
    },
    processInstanceId () {
      return this.process ? this.process.ticketId : null
    },
    processFinished () {
      return this.process && this.process.finishedOn !== '' && this.process.finishedOn !== null
    },
    isTicketProcess () {
      return this.processDefinitionKey === 'Z1_01'
    },
    isRentingFormProcess () {
      return this.processDefinitionKey === 'C2_02_03'
    },
    isOfflineRentingFormProcess () {
      return this.processDefinitionKey === 'C2_02_03b'
    },
    isTerminationProcess () {
      return this.processDefinitionKey === 'C2_02_07'
    },
    isRelettingProcess () {
      return this.processDefinitionKey === 'C2_02_10'
    },
    routeTaskId () {
      return this.$route.query.taskId
    }
  },
  methods: {
    loadDetails () {
      this.$emit('loadDetails')
    },
    onOpenNewTicketDialog (links = [], subject = '', processDefinitionKey = null, variables = []) {
      this.newLinks = links
      this.subject = subject
      this.newTicketDialogProcessDefinitionKey = processDefinitionKey
      this.newTicketDialogVariables = variables
      this.shouldShowNewTicketDialog = true
    },
    onProcessInstanceCreated (value) {
      this.$emit('processInstanceCreated', value)
    },
    onDeleteReferenceLink (link) {
      this.$emit('deleteLinkFromAutocompleteLinks', link)
    },
    onLoadProcessReferences () {
      this.$emit('loadProcessReferences')
    },
    loadProcessVariables () {
      this.$emit('loadProcessVariables')
    }
  }
}
</script>

<style scoped lang="postcss">
</style>
