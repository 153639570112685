var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-bpmn-diagram"},[(!_vm.noDiagram)?_c('div',{staticClass:"diagram-actions mb-2"},[(!_vm.fullscreen)?_c('v-tooltip',{attrs:{"bottom":"","id":"openFullscreen"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"size":26},on:{"click":_vm.openFullscreen}},'v-icon',attrs,false),on),[_vm._v("mdi-fullscreen")])]}}],null,false,2435046404)},[_c('span',[_vm._v(_vm._s(_vm.$t('bpmnDiagram.actions.openFullscreen')))])]):_vm._e(),(_vm.fullscreen)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"size":26},on:{"click":_vm.closeFullscreen}},'v-icon',attrs,false),on),[_vm._v("mdi-fullscreen-exit")])]}}],null,false,361525419)},[_c('span',[_vm._v(_vm._s(_vm.$t('bpmnDiagram.actions.exitFullscreen')))])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"size":26},on:{"click":_vm.fitToViewport}},'v-icon',attrs,false),on),[_vm._v("all_out")])]}}],null,false,3852958777)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('bpmnDiagram.actions.fitToViewport'))+" ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"size":26},on:{"click":_vm.zoomIn}},'v-icon',attrs,false),on),[_vm._v("zoom_in")])]}}],null,false,3322626018)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('bpmnDiagram.actions.zoomIn'))+" ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"size":26},on:{"click":_vm.zoomOut}},'v-icon',attrs,false),on),[_vm._v("zoom_out")])]}}],null,false,1891433794)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('bpmnDiagram.actions.zoomOut'))+" ")])])],1):_vm._e(),(_vm.loading)?_c('v-card-text',[_c('v-progress-linear',{attrs:{"slot":"progress","color":"blue","indeterminate":""},slot:"progress"})],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],ref:"diagramContainer",staticClass:"diagram-container"}),(!_vm.noDiagram)?_c('fieldset',{staticClass:"diagram-legend pa-2"},[_c('legend',{staticClass:"pl-2 pr-2"},[_vm._v(_vm._s(_vm.$t('bpmnDiagram.legend.title')))]),_c('div',{class:[{ 'd-flex justify-space-between': _vm.mdAndUp }]},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"box highlight-opened"}),_c('span',{staticClass:"pl-2"},[_vm._v(_vm._s(_vm.$t('bpmnDiagram.legend.status.opened')))])]),_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"box highlight-active-opened"}),_c('span',{staticClass:"pl-2"},[_vm._v(_vm._s(_vm.$t('bpmnDiagram.legend.status.activeOpened')))])]),_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"box highlight-completed"}),_c('span',{staticClass:"pl-2"},[_vm._v(_vm._s(_vm.$t('bpmnDiagram.legend.status.completed')))])]),_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"box highlight-active-completed"}),_c('span',{staticClass:"pl-2"},[_vm._v(_vm._s(_vm.$t('bpmnDiagram.legend.status.activeCompleted')))])])])]):_vm._e(),(_vm.noDiagram)?_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t('bpmnDiagram.messages.noDiagram'))+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }