<template>
  <div class="d-inline">
    <template>
      <v-btn small icon ripple @click.stop="previewFile()" :disabled="disablePreview" :title="$t('common.buttons.preview')">
        <v-icon color="brown" small>mdi-eye</v-icon>
      </v-btn>
      <v-btn v-if="shouldShowEdit" small icon ripple @click.stop="shouldShowEditDialog = true" :disabled="isOldFile" :title="$t('common.buttons.rename')">
        <v-icon color="teal" small>edit</v-icon>
      </v-btn>
      <v-btn small icon ripple @click.stop="downloadFile" :title="$t('common.buttons.download')">
        <v-icon color="blue lighten-1" small>file_download</v-icon>
      </v-btn>
      <v-btn v-if="deletable && !taskFinished && !isFileWithCheckbox" small icon ripple :disabled="disabled" @click.stop="shouldShowDeleteDialog = true" :title="$t('common.buttons.delete')">
        <v-icon color="red lighten-1" small>delete</v-icon>
      </v-btn>
    </template>
    <template>
      <PreviewAzureFile
        v-if="shouldShowPreviewDialog"
        :file-name="fileName"
        :file-response="fileResponse"
        @closeDialog="shouldShowPreviewDialog = false"
      />
      <EditAzureFileName
        v-if="shouldShowEditDialog"
        :file-name="fileName"
        :file-id="azureFileId"
        @closeDialog="shouldShowEditDialog = false"
        @reloadFileName="onReloadFileName"
      />
      <ConfirmationDialog
        :data="removeData"
        :show-dialog="shouldShowDeleteDialog"
        @closeDialog="shouldShowDeleteDialog = false"
        @submitDialog="removeVariableFile"
      />
    </template>
  </div>
</template>

<script>
import PreviewAzureFile from '@/features/ticketing/dialogs/PreviewAzureFile'
import EditAzureFileName from '@/features/ticketing/dialogs/EditAzureFileName'
import ConfirmationDialog from '@/components/ConfirmationDialog'
import { mapActions } from 'vuex'

import { useRentingForm } from '@/use/useRentingForm'
import { useFiles } from '@/use/useFiles'
const { getResidentBlob } = useRentingForm()
const { getFileBlob, getAzureFileBlob, getTaskFileBlob, getEmailFileBlob } = useFiles()

export default {
  name: 'FileActions',
  components: {
    PreviewAzureFile,
    EditAzureFileName,
    ConfirmationDialog
  },
  props: {
    deletable: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    file: {
      type: Object,
      default: () => {}
    },
    fileName: {
      type: String,
      default: ''
    },
    formFieldId: {
      type: String,
      default: ''
    },
    moduleName: {
      type: String,
      default: ''
    },
    processInstanceId: {
      type: String,
      default: ''
    },
    taskFinished: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      fileResponse: null,
      shouldShowDeleteDialog: false,
      shouldShowEditDialog: false,
      shouldShowPreviewDialog: false,
      validPreviewVariableTypes: ['gif', 'jpeg', 'jpg', 'png', 'pdf', 'jfif']
    }
  },
  computed: {
    azureFileId () {
      return this.file?.variableValue?.fileId || this.file?.fileId
    },
    isAzureFile () {
      return this.file.variableType === 'azureFile' || this.moduleName === ''
    },
    isOldFile () {
      return this.file.variableType === 'file'
    },
    isTaskModule () {
      return this.moduleName === 'task'
    },
    isEmailFile () {
      return this.moduleName === 'email'
    },
    isEmailDialog () {
      return this.moduleName === 'emailDialog'
    },
    isFileWithCheckbox () {
      return this.moduleName === 'fileWithCheckbox'
    },
    disablePreview () {
      return !this.validPreviewVariableTypes.includes(this.fileName?.split('.').pop())
    },
    shouldShowEdit () {
      return !this.isFileWithCheckbox && !this.taskFinished &&
        ((!this.isTaskModule && !this.isEmailFile && this.isEmailDialog) ||
        this.isAzureFile && !this.disabled)
    },
    removeData () {
      return {
        title: this.$t('common.dialogs.removeFile.title'),
        question: this.$t('common.dialogs.removeFile.question'),
        confirmButton: this.$t('common.buttons.delete')
      }
    }
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    async previewFile () {
      if (this.file?.rentalForm) {
        this.fileResponse = await getResidentBlob(this.file.fileNameWithPath)
      } else if (this.isTaskModule) {
        this.fileResponse = await getTaskFileBlob(this.file.id, this.file.extension)
      } else if (this.isOldFile) {
        this.fileResponse = await getFileBlob(this.processInstanceId, this.formFieldId)
      } else if (this.isAzureFile || this.azureFileId) {
        this.fileResponse = await getAzureFileBlob(this.azureFileId)
      } else if (this.isEmailFile) {
        this.fileResponse = await getEmailFileBlob(this.file.location, this.file.fileName)
      }
      this.shouldShowPreviewDialog = true
    },
    async downloadFile () {
      if (this.file?.rentalForm) {
        this.jsFileDownload(await getResidentBlob(this.file.fileNameWithPath))
      } else if (this.isTaskModule) {
        this.jsFileDownload(await getTaskFileBlob(this.file.id, this.file.extension))
      } else if (this.isOldFile && this.formFieldId) {
        this.jsFileDownload(await getFileBlob(this.processInstanceId, this.formFieldId))
      } else if (this.isOldFile && this.file?.variableName) {
        this.jsFileDownload(await getFileBlob(this.processInstanceId, this.file.variableName))
      } else if (this.isAzureFile || this.azureFileId) {
        this.jsFileDownload(await getAzureFileBlob(this.azureFileId))
      } else if(this.isEmailFile) {
        this.jsFileDownload(await getEmailFileBlob(this.file.location, this.file.fileName))
      }
    },
    jsFileDownload (fileResponse) {
      let fileDownload = require('js-file-download')
      fileDownload(fileResponse.data, this.fileName)
    },
    removeVariableFile () {
      if (this.isTaskModule || this.isEmailFile || this.isEmailDialog) {
        this.$emit('removeFile')
      } else {
        if (this.isAzureFile || this.azureFileId) {
          this.$emit('removeFileVariable', this.file.variableName || this.formFieldId, this.file)
        } else {
          this.$emit('removeFileVariable', this.formFieldId, this.file)
        }
      }
      this.shouldShowDeleteDialog = false
    },
    onReloadFileName (file) {
      this.$emit('loadProcessVariables', file, this.formFieldId)
    }
  }
}
</script>
