<template>
  <div class="variable-file-upload">
    <v-list-item v-if="variableFile" class="pa-0">
      <v-list-item-content v-if="error === null">
        <v-list-item-subtitle v-if="label && variableFile" class="subtitle-1 mt-2 mb-1">
          {{ label }}
        </v-list-item-subtitle>
        <v-list-item-title class="d-flex">
          <v-checkbox
            v-if="isFileWithCheckbox"
            primary
            hide-details
            :disabled="!variableFile.variableValue.fileId"
            :input-value="checked"
            @click.stop="checked = !checked"
          ></v-checkbox>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="mr-4" v-bind="attrs" v-on="on">{{ icon }}</v-icon>
            </template>
            <span v-if="icon === 'mdi-ticket'">{{ $t('ticketingSystem.fileTypes.ticket') }}</span>
            <span v-else-if="icon === 'mdi-email'">{{ $t('ticketingSystem.fileTypes.email') }}</span>
            <span v-else-if="icon === 'person'">{{ $t('ticketingSystem.fileTypes.rental') }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="truncate-one-line mt-2">
                <span>{{ fileName }}</span>
                <p v-if="fileCreatedOn" class="mb-0 subtitle-color">
                  {{ fileCreatedOn }}
                </p>
              </div>
            </template>
            <span>{{ fileName }}</span>
          </v-tooltip>
        </v-list-item-title>
        <v-list-item-subtitle v-if="variableFile.size && !processInstanceId">{{ fileSizeConverted }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-content v-else class="red--text">
        {{ error.error }}
      </v-list-item-content>
      <v-list-item-action>
        <FileActions
          :deletable="deletable"
          :disabled="disabled"
          :file="variableFile"
          :file-name="fileName"
          :form-field-id="formFieldId || systemFileId"
          :process-instance-id="processInstanceId"
          :task-finished="taskFinished"
          :module-name="moduleName"
          @removeFileVariable="onRemoveFileVariable"
          @loadProcessVariables="onLoadProcessVariables"
        />
      </v-list-item-action>
    </v-list-item>
    <v-list-item-subtitle
      v-if="label && !variableFile"
      class="subtitle-1 mt-2 mb-1"
      v-bind:class="[{ 'required': requiredFileEmpty && validateClicked }]"
    >
      {{ label }}
    </v-list-item-subtitle>
    <vue-clip
      ref="vue_clip"
      v-if="variableFile === null"
      :options="options"
      :on-init="init"
      :on-complete="complete"
    >
      <template slot="clip-uploader-body" slot-scope="props">
        <div
          v-for="(file, index) in props.files"
          class="uploader-file"
          :key="index"
        >
          <div v-if="file.status !== 'success'" class="ma-2">
            <div class="file-details">
              <v-icon v-if="file.status === 'error'" color="red">error</v-icon>
              {{ file.name }}
              <span v-if="file.status === 'error'">{{ $t('common.messages.failed') }}</span>
            </div>
            <div class="file-progress" v-if="file.status !== 'error'">
              <span class="progress-indicator" v-bind:style="{width: file.progress - (file.xhrResponse.response?0:5) + '%'}"></span>
            </div>
          </div>
        </div>
      </template>
      <template slot="clip-uploader-action" slot-scope="params">
        <div ref="fileField" class="uploader-action" v-bind:class="{'is-dragging': params.dragging, 'is-disabled': disabled }">
          <div class="dz-message" v-bind:class="{'required-border': requiredFileEmpty && validateClicked }">
            <v-icon>cloud_upload</v-icon>
            <div>{{ $t('tasks.attachments.clickOrDragText') }}</div>
          </div>
        </div>
      </template>
    </vue-clip>
    <span v-if="requiredFileEmpty && validateClicked" class="required font-12">{{ requiredMessage }}</span>
  </div>
</template>

<script>
import FileActions from './FileActions'
import { formatBytes, formatDateAndTime } from '@/helpers/formatterHelper'

import { mapActions } from 'vuex'
const EMAIL = 'email'

export default {
  name: 'VariableFileUpload',
  components: {
    FileActions
  },
  props: {
    icon: {
      type: String,
      default: 'mdi-ticket'
    },
    variableFile: {
      type: Object,
      default: () => null
    },
    processInstanceId: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    validateClicked: {
      type: Boolean,
      default: false
    },
    deletable: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    taskFinished: {
      type: Boolean,
      default: false
    },
    moduleName: {
      type: String,
      default: ''
    },
    formFieldId: {
      type: String,
      default: ''
    },
    fileVariables: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      addedFileVariables: [],
      error: null,
      options: {
        url: '/api/ticketing-file/v1/azure-files',
        paramName: 'file',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('adal.idtoken')
        }
      },
      checked: false
    }
  },
  watch: {
    checked (value) {
      if (this.variableFile?.variableValue?.fileId) {
        this.$emit('updateAttachmentIds', this.variableFile.variableValue.fileId, value)
      }
    },
    fileVariables () {
      if (this.addedFileVariables.length > 0) {
        this.addedFileVariables = []
      }
    }
  },
  computed: {
    ariaInvalid () {
      return this.requiredFileEmpty
    },
    fileSizeConverted () {
      return formatBytes(this.variableFile.size)
    },
    fileName () {
      return this.variableFile?.fileName || this.variableValueFileName
    },
    fileCreatedOn () {
      if (this.variableFile?.createdOn) {
        return formatDateAndTime(this.variableFile.createdOn)
      }
      return ''
    },
    variableValueFileName () {
      return this.variableFile ? (this.variableFile?.variableValue ? this.variableFile.variableValue.fileName : this.variableFile.fileName) : ''
    },
    requiredMessage () {
      return this.$t('common.errors.required')
    },
    requiredFileEmpty () {
      return this.required && this.variableFile === null
    },
    isFileWithCheckbox () {
      return this.moduleName === 'fileWithCheckbox'
    },
    systemFileId () {
      if (!this.formFieldId) {
        return 'System_TicketFile' + this.nextIncrement + '_File'
      }
      return ''
    },
    lastIncrement () {
      let increments = this.fileVariables.map(x =>
        parseInt(x.variableName.split('System_TicketFile')[1].split('_File'))
      )
      if (increments.length > 0) {
        return Math.max(...increments)
      }
      return ''
    },
    nextIncrement () {
      return this.lastIncrement + this.addedFileVariables.length
    },
    multiple () {
      return this.systemFileId !== ''
    }
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    init () {
      if (this.$refs.vue_clip && !this.multiple) {
        // https://github.com/thetutlage/vue-clip/issues/22
        this.$refs.vue_clip.uploader._uploader.hiddenFileInput.removeAttribute('multiple')
        this.options.maxFiles = 1
      }
    },
    complete (file, status, xhr) {
      let that = this
      if (status === 'success') {
        let variableFile = JSON.parse(xhr.response)
        file.xhrResponse.response = variableFile
        if (that.formFieldId) {
          that.$emit('addFileVariable', variableFile, that.formFieldId)
        } else {
          that.addedFileVariables.push(variableFile)
          that.$emit('addFileVariable', variableFile, that.systemFileId)
        }
      }
    },
    onRemoveFileVariable (formFieldId, file) {
      this.$emit('removeFileVariable', formFieldId, file)
    },
    onLoadProcessVariables (file, formFieldId) {
      this.$emit('loadProcessVariables', file, formFieldId)
    }
  }
}
</script>

<style scoped lang="postcss">
.variable-file-upload {
  width: 100%;
}
.uploader-action:hover {
  cursor: pointer;
}
.uploader-action.is-dragging {
  background: green;
}
.uploader-action.is-disabled {
  pointer-events: none;
  opacity: 0.5;
}
.dz-message {
  border: 2px dashed #bbb;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  color: #bbb;
}
.file-progress {
  height: 7px;
  background-color:lightgray;
}
.progress-indicator {
  height: 7px;
  background-color:green;
  display: block;
  border: 1px solid lightgray;
}
/deep/ .v-list-item__title {
  color: rgba(0,0,0,.6);
  font-weight: 700 ;
  font-size: .875rem;
  line-height: 1.375rem;
  letter-spacing: .0071428571em;
}
/deep/ .required-border {
  border: 2px dashed #ff5252;
}
.required {
  color: #ff5252 !important;
}
.font-12 {
  font-size: 12px;
}
.truncate-one-line {
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.subtitle-color {
  font-weight: 500;
  color: #9e9c9c;
}
/deep/ .v-input--checkbox {
  margin-top: 5px;
}
</style>
