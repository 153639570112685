import { render, staticRenderFns } from "./DossierRecipients.vue?vue&type=template&id=f2159220&scoped=true&"
import script from "./DossierRecipients.vue?vue&type=script&lang=js&"
export * from "./DossierRecipients.vue?vue&type=script&lang=js&"
import style0 from "./DossierRecipients.vue?vue&type=style&index=0&id=f2159220&scoped=true&lang=postcss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2159220",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCard,VCardTitle,VContainer,VDataTable,VLayout,VProgressLinear,VSlideYTransition,VSpacer,VTextField})
