export const languages = {
  DE: 'de',
  EN: 'en'
}

export const languageList = [
  {
    value: languages.DE
  },
  {
    value: languages.EN
  }
]
