<template>
  <v-container class="ml-1">
    <v-card-title class="pa-0 mb-1">{{ $t('dashboard.personTickets.title') }}</v-card-title>
    <v-data-table
      :class="tableClassName"
      :headers="headers"
      :items="processes"
      :item-class="rowClass"
      :loading="loading"
      :footer-props="{itemsPerPageOptions: itemsPerPageOptions, itemsPerPageText: itemsPerPageText}"
      :server-items-length="totalItems"
      :options.sync="options"
      @click:row="processInstanceSelected"
      class="elevation-1"
    >
      <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
      <template v-slot:[`item.processInfo`]="{ item }">
        <p class="truncate-two-lines">
          {{ item.processDefinitionName }}<template v-if="item.processSubject"> - {{ item.processSubject }}</template>
        </p>
      </template>
      <template v-slot:[`item.state`]="{ item }">
        <StateIcon :state="item.state" />
      </template>
      <template v-slot:[`item.dateInfo`]="{ item }">
        <div>{{ item.startedOnFormatted }}</div>
        <div>{{ item.finishedOnFormatted }}</div>
      </template>
      <template slot="no-data">
        <v-alert class="mt-4" :value="true" color="error" icon="warning">
          {{ $t('table.noData') }}
        </v-alert>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import StateIcon from '@/components/StateIcon'
import * as ticketingHelper from '@/features/ticketing/helpers/ticketingHelper'
import { setRowClassByDiff } from '@/helpers/momentHelper'
import { formatDateAndTime } from '@/helpers/formatterHelper'
import TicketingProcessService from '@/services/TicketingProcessService'

export default {
  name: 'PersonTicketList',
  data () {
    return {
      tableClassName: 'personTicketList',
      options: { itemsPerPage: 10, page: 1 },
      itemsPerPageOptions: [10, 15, 25],
      processes: [],
      totalItems: 0,
      loading: false
    }
  },
  components: {
    StateIcon
  },
  props: {
    processInstanceCreated: {
      type: Number,
      default: 0
    }
  },
  watch: {
    'options.page': 'getProcesses',
    'options.itemsPerPage': 'getProcesses',
    routeAddressId (value) {
      if (value && !this.loading && this.routeAddressType) {
        this.getProcesses()
      }
    },
    routeAddressType (value) {
      if (value && !this.loading && this.routeAddressId) {
        this.getProcesses()
      }
    },
    processInstanceCreated (value) {
      if (!this.loading) {
        this.getProcesses()
      }
    }
  },
  created () {
    if (this.routeAddressId && this.routeAddressType) {
      this.getProcesses()
    }
  },
  computed: {
    headers () {
      return [
        {
          text: this.$t('ticketingSystem.processes.process'),
          align: 'left',
          value: 'processInfo',
          sortable: false
        },
        {
          text: this.$t('ticketingSystem.taskDetails.state'),
          align: 'left',
          value: 'state',
          sortable: false
        },
        {
          text: this.dateHeader,
          align: 'left',
          value: 'dateInfo',
          sortable: false
        }
      ]
    },
    itemsPerPageText () {
      return this.$t('common.itemsPerPageText')
    },
    routeAddressId () {
      return this.$route.params.addressId
    },
    routeAddressType () {
      return this.$route.params.addressType
    },
    routeProcessInstanceId () {
      return this.$route.params.processInstanceId
    },
    dateHeader () {
      return this.$t('ticketingSystem.taskDetails.processReferences.startedOn') + ' / ' + this.$t('ticketingSystem.taskDetails.processReferences.finishedOn')
    }
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    formatDateAndTimeFunc: formatDateAndTime,
    async getProcesses () {
      this.loading = true
      this.processes = []
      let processQueryParams = { size: this.options.itemsPerPage, page: this.options.page - 1 }
      processQueryParams = { query: Object.assign(processQueryParams, this.setPersonQueryParams(processQueryParams)) }
      try {
        const response = await TicketingProcessService.getProcesses(processQueryParams)
        this.processes = response.data.items || []
        this.totalItems = response.data.totalItems
        ticketingHelper.mapProcesses(this.processes)
        this.loading = false
      } catch (error) {
        this.setErrorSnackbar(error)
        this.processes = []
        this.loading = false
      }
    },
    setPersonQueryParams () {
      let object = {}
      switch (this.routeAddressType) {
        case 'tenant':
          object.tenantId = this.routeAddressId
          return object
        case 'client':
          object.clientId = this.routeAddressId
          return object
        case 'caretaker':
          object.caretakerId = this.routeAddressId
          return object
        case 'craftsman':
          object.craftsmanId = this.routeAddressId
          return object
      }
    },
    rowClass (item) {
      let className = 'row-' + item.processInstanceId + ' '
      className += setRowClassByDiff(item.startedOn)
      return className
    },
    processInstanceSelected (value) {
      window.open('/ticketing/processes/' + value.processInstanceId)
    }
  }
}
</script>

<style scoped lang="postcss">
/deep/ tr:nth-child(odd) {
  background: #f8f8f8;
}
/deep/ tr {
  cursor: pointer;
}
.truncate-two-lines {
  max-height: 39px;
  display: -webkit-inline-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  margin: 0 auto;
  line-height: 1.5;
}
</style>
