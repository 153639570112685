<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateFormatted"
        @blur="datePickerValue = parseDateFunc(dateFormatted)"
        dense
        :label="label"
        :required="required"
        v-mask="'##.##.####'"
        :readonly="readonly"
        :hint="formatHint"
        :rules="required ? [rules.required, rules.date] : [rules.date]"
        prepend-icon="mdi-calendar"
        v-bind="attrs"
        v-on="on"
      >
        <template #message="{ message }">
          <span v-if="message === 'required'">{{ requiredMessage }}</span>
          <span v-else-if="message === 'invalidDate'">{{ invalidDateMessage }}</span>
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      v-model="datePickerValue"
      no-title
      scrollable
      :locale="$i18n.locale"
      @input="menu = false"
    >
    </v-date-picker>
  </v-menu>
</template>

<script>
import {formatDate, parseDate} from '@/helpers/formatterHelper'
import { validateDate } from '@/helpers/validationHelper'

export default {
  name: "DateField",
  components: {
  },
  props: {
    value: {
      default: null
    },
    label: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      menu: false,
      dateFormatted: '',
      rules: {
        required: (value) => !!value || 'required',
        date: (value) => validateDate(value)
      }
    }
  },
  watch: {
    datePickerValue: {
      handler (value) {
        this.dateFormatted = this.formatDateFunc(value)
      },
      immediate: true
    }
  },
  computed: {
    datePickerValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    requiredMessage () {
      return this.$t('common.errors.required')
    },
    invalidDateMessage () {
      return this.$t('common.errors.invalidDate')
    },
    formatHint () {
      return this.$t('common.date.formatHint')
    }
  },
  methods: {
    formatDateFunc: formatDate,
    parseDateFunc: parseDate
  }
}
</script>
