<template>
  <v-card class="mb-2 pa-0" v-if="ticketLinks.length > 0">
    <v-card-text class="pa-4">
      <v-icon color="primary" @click="toggle" class="float-right">{{ icon }}</v-icon>
      <v-card-subtitle class="pa-0 font-weight-bold subtitle-1">
        {{ $t('common.tickets') }}
      </v-card-subtitle>
    </v-card-text>
    <v-divider></v-divider>
    <v-list v-if="isOpen" class="context overflow-auto">
      <template v-for="(ticketId, index) in ticketLinks">
        <ProcessTicket
          :key="index"
          :ticketId="ticketId"
          :deleteAction="deleteAction"
          @openNewTicketDialog="onNewTicket"
        />
        <v-divider :key="index + '_divider'"></v-divider>
      </template>
    </v-list>
  </v-card>
</template>

<script>
import ProcessTicket from './ProcessTicket'

export default {
  name: 'ProcessTickets',
  components: {
    ProcessTicket
  },
  props: {
    ticketLinks: {
      type: Array,
      default: () => []
    },
    deleteAction: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      isOpen: true
    }
  },
  computed: {
    icon () {
      return this.isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
    }
  },
  methods: {
    toggle () {
      this.isOpen = !this.isOpen
    },
    onNewTicket (item) {
      let links = [{
        linkName: item.linkName,
        linkValues: item.linkValue ? [item.linkValue] : []
      }]
      this.$emit('openNewTicketDialog', links)
    }
  }
}
</script>
<style scoped lang="postcss">
.text {
  display: block;
  -webkit-line-clamp: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
