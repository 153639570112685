<template>
  <v-expansion-panels v-if="rentalCaseId" v-model="panel" mandatory focusable>
    <ProcessRentalFormsTable :rental-case-id="rentalCaseId" />
  </v-expansion-panels>
</template>

<script>
import ProcessRentalFormsTable from '../process-specific-context/process-reletting/ProcessRentalFormsTable'

export default {
  name: 'RelettingProcessApplications',
  data () {
    return {
      panel: 0
    }
  },
  props: {
    rentalCaseId: {
      type: String,
      default: ''
    }
  },
  components: {
    ProcessRentalFormsTable
  }
}
</script>

<style scoped lang="postcss">
</style>
