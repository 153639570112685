<template>
  <v-dialog :value="true" scrollable max-width="1250px" @keydown.esc="closeDialog" persistent>
    <v-card>
      <v-card-title>
        {{ $t('ticketingSystem.fileActions.preview') }}
        <v-btn icon absolute right>
          <v-icon class="clear" color="primary" :size="28" @click.native="closeDialog"/>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <p class="font-weight-bold">{{ fileName }}</p>
        <img v-if="isImage" :src="dataUrl" class="preview-image" />
        <template v-else-if="isPdf">
          <pdf
            v-for="i in numPages"
            :key="i"
            :src="src"
            :page="i"
          ></pdf>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="closeDialog" class="mt-2">{{ $t('common.buttons.close') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import pdf from 'vue-pdf'

export default {
  name: 'PreviewAzureFile',
  components: {
    pdf
  },
  props: {
    fileName: {
      type: String,
      default: ''
    },
    fileResponse: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      src: null,
      numPages: 0
    }
  },
  computed: {
    data () {
      return this.fileResponse?.data
    },
    dataUrl () {
      return URL.createObjectURL(this.data)
    },
    isImage () {
      return this.data?.type?.startsWith('image')
    },
    isPdf () {
      return this.data?.type?.endsWith('pdf')
    }
  },
  mounted() {
    if (this.isPdf) {
      this.src = pdf.createLoadingTask(this.dataUrl)
      this.src.promise.then(pdf => {
        this.numPages = pdf.numPages
      })
    }
	},
  methods: {
    closeDialog () {
      this.$emit('closeDialog')
    }
  }
}
</script>

<style scoped lang="postcss">
.preview-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  max-height: 700px;
  max-width: 700px;
  object-fit: contain;
}
</style>
