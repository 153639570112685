<template>
  <v-container fluid class="grey lighten-5 pa-2">
    <ShoppingCartDialog
      :show-dialog="showEditDialog"
      :item="editedItem"
      :default-supplier-id="defaultSupplierId"
      :max-amount="maxAmount"
      @closeDialog="closeEditDialog"
      @saveItemToBasket="saveItemToBasket"
      edit
    ></ShoppingCartDialog>
    <ConfirmationDialog
      :data="deleteData"
      :show-dialog="showDeleteDialog"
      @closeDialog="closeDeleteDialog"
      @submitDialog="deleteBasketItem"
    />
    <v-layout class="row pt-0 pl-0 pr-0">
      <v-card-title>{{ $t('heatingOil.headers.shoppingCart') }}</v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
        append-icon="search"
        :label="$t('common.buttons.search')"
        single-line
        hide-details
        v-model="search"
      ></v-text-field>
    </v-layout>

    <v-btn class="mb-4" color="primary" :disabled="selected.length == 0" @click="placeOrder"><v-icon class="mr-1">send</v-icon>{{ $t('heatingOil.basket.order') }}</v-btn>
    <v-data-table
      :headers="headers"
      :items="basket"
      :loading="basketLoading"
      v-model="selected"
      :search="search"
      :custom-filter="customDataTableFilter"
      must-sort
      item-key="id"
      show-select
      :options.sync="options"
      :hide-default-footer="true"
      :footer-props="{itemsPerPageOptions:itemsPerPageOptions}"
      class="elevation-1"
    >
      <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
      <template v-slot:items="props">
        <tr :active="props.selected">
          <td>
            <v-checkbox
              primary
              hide-details
              :input-value="props.selected"
              @click="props.selected = !props.selected"
            ></v-checkbox>
          </td>
        </tr>
      </template>
      <template v-slot:[`item.propertyInfo`]="{ item }">
        [<a href="" v-if="item.propertyId" @click="openMapProperty(item.propertyId)">{{ item.propertyId }}</a>]
        <span v-if="item.propertyId">{{ item.propertyStreet + ', ' + item.propertyZip + ' ' + item.propertyPlace }}</span>
      </template>
      <template v-slot:[`item.amount`]="{ item }">
        <strong>{{ item.amountInfo }}</strong>
      </template>
      <template v-slot:[`item.id`]="{ item }">
        {{ item.id }}
      </template>
      <template v-slot:[`item.tankId`]="{ item }">
        [{{ item.tankId }}]
      </template>
      <template v-slot:[`item.comment`]="{ item }">
        <p class="mb-0 truncate-two-lines">{{ item.comment }}</p>
      </template>
      <template v-slot:[`item.fillingLevel`]="{ item }">
        {{ item.volumeInfo }}
      </template>
      <template v-slot:[`item.afterFilling`]="{ item }">
        <v-progress-linear :color="item.percentColor" v-model="item.percent"></v-progress-linear>
      </template>
      <template v-slot:[`item.tankRevision`]="{ item }">
        <v-icon v-if="item.tankRevision" color="red">build</v-icon>
      </template>
      <template v-slot:[`item.orderedOn`]="{ item }">
        <nobr v-if="item.dateDifferenceInMonths != null && item.dateDifferenceInMonths < 2">
          <v-icon color="orange">warning</v-icon> {{ item.orderedOnFormatted }}
        </nobr>
        <span v-else>{{ item.orderedOnFormatted }}</span>
      </template>
      <template v-slot:[`item.addedOn`]="{ item }">
        {{ item.addedOnFormatted }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <nobr>
          <v-btn icon class="mx-0" @click="openEditDialog(item)">
              <v-icon color="teal">edit</v-icon>
          </v-btn>
          <v-btn icon class="mx-0" @click.stop="openDeleteDialog(item.id)">
              <v-icon color="pink">delete</v-icon>
          </v-btn>
        </nobr>
      </template>
      <template slot="no-data">
        <v-alert :value="true" color="error" icon="warning">
          {{ $t('table.noData') }}
        </v-alert>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import ConfirmationDialog from '@/components/ConfirmationDialog'
import ShoppingCartDialog from './dialogs/ShoppingCartDialog'
import * as heatingOilHelper from './helpers/heatingOilHelper'
import { formatDateAndTime } from '@/helpers/formatterHelper'
import { dataTableFilter } from '@/helpers/globalHelper'
import { MAP_PROPERTIES } from '@/config/options/routes/mapRoutes'
import { mapActions, mapGetters, mapState } from 'vuex'
import Vue from 'vue'
import moment from 'moment'
import _cloneDeep from 'lodash.clonedeep'

export default {
  name: 'HeatingOilBasket',
  components: {
    ConfirmationDialog,
    ShoppingCartDialog
  },
  data () {
    return {
      basket: [],
      basketLoading: true,
      showEditDialog: false,
      showDeleteDialog: false,
      defaultSupplierId: '',
      editedItem: {},
      maxAmount: null,
      search: '',
      selected: [],
      selectedState: null,
      deleteItemId: null,
      options: {
        sortBy: ['addedOn']
      },
      itemsPerPageOptions: [-1]
    }
  },
  created () {
    this.loadData()
  },
  computed: {
    ...mapGetters('abacus', ['getTanks', 'getTanksLoading']),
    ...mapGetters('heatingOil', ['getTankOrders']),
    ...mapState('heatingOil', ['tankOrders']),
    headers () {
      return [
        {
          text: this.$t('common.id'),
          align: 'left',
          value: 'id'
        },
        {
          text: this.$t('tasks.info.property'),
          align: 'left',
          value: 'propertyInfo'
        },
        {
          text: this.$t('heatingOil.basket.postcodeTown'),
          align: 'left',
          value: 'postcodeTownInfo'
        },
        {
          text: this.$t('heatingOil.headers.house'),
          align: 'left',
          value: 'houseInfo'
        },
        {
          text: this.$t('heatingOil.basket.tank'),
          align: 'left',
          value: 'tankId'
        },
        {
          text: this.$t('tasks.comment.title'),
          align: 'left',
          value: 'comment'
        },
        {
          text: this.$t('heatingOil.basket.orderQuantity'),
          align: 'left',
          value: 'amount'
        },
        {
          text: this.$t('heatingOil.headers.stockVolume'),
          align: 'left',
          value: 'fillingLevel'
        },
        {
          text: this.$t('heatingOil.basket.afterFilling'),
          align: 'left',
          value: 'afterFilling'
        },
        {
          text: this.$t('heatingOil.subHeaders.supplier'),
          align: 'left',
          value: 'supplierInfo'
        },
        {
          text: this.$t('heatingOil.headers.revision'),
          align: 'left',
          value: 'tankRevision'
        },
        {
          text: this.$t('heatingOil.basket.orderDate'),
          align: 'left',
          value: 'orderedOn'
        },
        {
          text: this.$t('heatingOil.basket.created'),
          align: 'left',
          value: 'addedOn'
        },
        {
          text: this.$t('heatingOil.headers.actions'),
          align: 'left',
          sortable: false,
          value: 'actions'
        }
      ]
    },
    deleteData () {
      return {
        title: this.$t('heatingOil.basket.dialog.delete.title'),
        question: this.$t('heatingOil.basket.dialog.delete.question'),
        confirmButton: this.$t('common.buttons.delete'),
        action: 'deleteBasketItem'
      }
    }
  },
  methods: {
    ...mapActions('heatingOil', ['setTankOrders']),
    ...mapActions('notifier', ['setSuccessSnackbar', 'setErrorSnackbar']),
    formatDateAndTimeFunc: formatDateAndTime,
    loadData () {
      Promise.all([this.$store.dispatch('abacus/loadTanks'), this.$store.dispatch('heatingOil/loadTankOrders')]).then(() => this.loadBasket())
    },
    async loadBasket () {
      try {
        this.basketLoading = true
        let response = await this.$prestigeHeatingOilBasket.getAllBasket().then(response => { return response })
        this.basket = response.data
        if (this.basket.length) {
          let allTanks = await this.getTanks
          let basketEntries = this.basket
          for (let item of basketEntries) {
            item.addedOnFormatted = this.formatDateAndTimeFunc(item.addedOn)
            let currentTank = allTanks.find(x => x.id.toString() === item.tankId.toString())
            if (currentTank) {
              item.propertyId = currentTank.propertyId
              item.propertyStreet = currentTank.propertyStreet
              item.propertyZip = currentTank.propertyZip
              item.propertyPlace = currentTank.propertyPlace
              item.propertyInfo = currentTank.propertyInfo
              item.postcodeTownInfo = currentTank.propertyZip + ' ' + currentTank.propertyPlace
              item.houseInfo = currentTank.houseInfo
              item.comment = currentTank.comment
              item.basketAmount = item.amount
              let formattedFillingLevel = currentTank.fillingLevel ? heatingOilHelper.formatVolume(currentTank.fillingLevel) : '?'
              item.volumeInfo = formattedFillingLevel + ' / ' + heatingOilHelper.formatVolume(currentTank.volume)
              item.fillingLevel = currentTank.fillingLevel
              item.percent = (currentTank.fillingLevel || item.basketAmount) ? Math.max(0.0001, (currentTank.fillingLevel + item.basketAmount) / currentTank.volume * 100) : ''
              item.percentColor = heatingOilHelper.getColor(currentTank.percent)
              item.tankVolume = currentTank.volume
              item.amountInfo = heatingOilHelper.formatVolume(item.amount)
              item.tankRevision = currentTank.tankRevision
              if (this.tankOrders) {
                let order = this.tankOrders.find(x => x.tankId.toString() === item.tankId.toString())
                if (order) {
                  item.orderedOn = order.orderedOn
                  item.orderedOnFormatted = this.formatDateAndTimeFunc(order.orderedOn)
                  item.dateDifferenceInMonths = moment().diff(moment(order.orderedOn), 'months')
                }
              }
            }
            let supplier = await this.getSupplierById(item.supplierId)
            if (supplier) {
              let supplierData = supplier.data
              item.supplierInfo = '[' + supplierData.id + '] ' + (supplierData.firstName ? supplierData.firstName + ' ' : '') + supplierData.lastName
            }
          }
          Vue.set(this.basket, basketEntries)
        }
        this.$forceUpdate()
        this.basketLoading = false
      } catch (error) {
        this.basket = []
        this.basketLoading = false
        this.setErrorSnackbar(error)
      }
    },
    async getSupplierById (supplierId) {
      try {
        if (supplierId) {
          let address = await this.$abacus.getAddressById(supplierId)
          return address
        }
      } catch (error) {
        this.setErrorSnackbar(error)
      }
    },
    openEditDialog (item) {
      this.editedItem = Object.assign({}, item)
      this.defaultSupplierId = _cloneDeep(item.supplierId.toString())
      this.maxAmount = item.fillingLevel ? (item.tankVolume - item.fillingLevel) : item.tankVolume
      this.showEditDialog = true
      this.showDeleteDialog = false
    },
    closeEditDialog () {
      this.showEditDialog = false
      this.editedItem = {}
      this.defaultSupplierId = ''
    },
    saveItemToBasket () {
      let data = {
        'supplierId': this.editedItem.supplierId,
        'amount': this.editedItem.basketAmount
      }
      let that = this
      this.$prestigeHeatingOilBasket.updateBasket(this.editedItem.id, data).then(response => {
        that.closeEditDialog()
        that.setSuccessSnackbar(that.$t('heatingOil.tankLevel.shoppingCartDialog.messages.successfullyEdited'))
        that.loadData()
        return true
      }).catch(error => {
        that.setErrorSnackbar(error)
      })
    },
    deleteBasketItem () {
      let that = this
      this.$prestigeHeatingOilBasket.deleteBasket(this.deleteItemId).then(response => {
        that.selected = that.selected.filter(x => x.id.toString() !== that.deleteItemId.toString())
        that.closeDeleteDialog()
        that.loadData()
        that.setSuccessSnackbar(that.$t('common.messages.successfullyDeleted'))
        return true
      }).catch(error => {
        that.setErrorSnackbar(error)
      })
    },
    openDeleteDialog (basketId) {
      this.showDeleteDialog = true
      this.deleteItemId = basketId
      this.showEditDialog = false
    },
    closeDeleteDialog () {
      this.showDeleteDialog = false
      this.deleteItemId = null
    },
    placeOrder () {
      let data = []
      if (this.selected.length) {
        this.selected.forEach(item => {
          let basketItem = {
            'basketId': item.id
          }
          data.push(basketItem)
        })
      }
      let that = this
      this.$prestigeHeatingOilOrder.placeOrder(data).then(response => {
        that.setSuccessSnackbar(that.$t('heatingOil.basket.orderComplete'))
        that.selected = []
        that.loadData()
        return true
      }).catch(error => {
        that.setErrorSnackbar(error)
      })
    },
    openMapProperty (propertyId) {
      this.$router.push({ name: MAP_PROPERTIES, params: { propertyId: propertyId.toString() } })
    },
    customDataTableFilter (value, search, item) {
      return dataTableFilter(search, item)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="postcss">
/deep/ tr:nth-child(odd) {
  background: #f8f8f8;
}
.truncate-two-lines {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  max-width: 250px;
  height: 39px; /* Fallback for non-webkit $font-size*$line-height*$lines-to-show */
  margin: 0 auto;
  font-size: 13px; /* $font-size */
  line-height: 1.5; /* $line-height */
  -webkit-line-clamp: 2; /* $lines-to-show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
