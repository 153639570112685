import gql from 'graphql-tag'

const SEARCH_TICKETING_COMPLETED_TASKS_BY_ASSIGNEE = gql`
  query searchTicketingCompletedTasksByAssigneeStatistics($from: String!, $to: String!, $interval: SearchStatisticsInterval!, $processDefinitionKeys: [String!]!) {
    searchTicketingCompletedTasksByAssigneeStatistics(
      query: {
        from: $from
        to: $to
        interval: $interval,
        processDefinitionKeys: $processDefinitionKeys
      }
    ) {
      userId
      tasksCompleted {
        date
        count
      }
    }
  }
`

const SEARCH_TICKETING_OPEN_CLOSED_TASKS = gql`
  query searchTicketingOpenClosedTasksStatistics($from: String!, $to: String!, $interval: SearchStatisticsInterval!, $processDefinitionKeys: [String!]!) {
    searchTicketingOpenClosedTasksStatistics(
      query: {
        from: $from
        to: $to
        interval: $interval,
        processDefinitionKeys: $processDefinitionKeys
      }
    ) {
      candidateGroup
      tasksOpened {
        date
        count
      }
      tasksCompleted {
        date
        count
      }
    }
  }
`

const SEARCH_TICKETING_OPEN_TASKS_BY_ASSIGNEE_PER_PRIORITY = gql`
  query searchTicketingOpenTasksByAssigneeStatistics($processDefinitionKeys: [String!]!) {
    searchTicketingOpenTasksByAssigneeStatistics(
      processDefinitionKeys: $processDefinitionKeys
    ) {
      userId
      statistic {
        count
        dueTodayPriority1
        dueTodayPriority2
        additionalPriority1
        additionalPriority2
      }
    }
  }
`

const SEARCH_TICKETING_OPEN_TASKS_BY_CANDIDATE_GROUP = gql`
  query searchTicketingOpenTasksByCandidateGroupStatistics($processDefinitionKeys: [String!]!) {
    searchTicketingOpenTasksByCandidateGroupStatistics(
      processDefinitionKeys: $processDefinitionKeys
    ) {
      candidateGroup
      statistic {
        count
        dueTodayPriority1
        dueTodayPriority2
        additionalPriority1
        additionalPriority2
      }
    }
  }
`

const TICKETING_USER_OPEN_STATISTICS = gql`
  query ticketingUserOpenTaskStatistics($query: TicketingUserOpenTaskStatisticsRequest!) {
    ticketingUserOpenTaskStatistics(query: $query) {
      user
      openTaskStats {
        createdOn
        numberOfOpenTasks
      }
    }
  }
`

export {
  SEARCH_TICKETING_COMPLETED_TASKS_BY_ASSIGNEE,
  SEARCH_TICKETING_OPEN_CLOSED_TASKS,
  SEARCH_TICKETING_OPEN_TASKS_BY_ASSIGNEE_PER_PRIORITY,
  SEARCH_TICKETING_OPEN_TASKS_BY_CANDIDATE_GROUP,
  TICKETING_USER_OPEN_STATISTICS
}
