import gql from 'graphql-tag'

const ABACUS_PROPERTY_LINK = gql`
  query abacusProperty ($propertyId: Int) {
    abacusProperty(
      propertyId: $propertyId
    ) {
      propertyId
      street
      zip
      place
      realEstateManager {
        firstName
        lastName
      }
    }
  }
`
const ABACUS_PROPERTY_OBJECT = gql`
  query abacusPropertyObject($propertyId: Int!, $objectId: Int) {
    abacusPropertyObject(propertyId: $propertyId, objectId: $objectId) {
      objectId
      objectText
      floorText
      objectTextShort
      floorTextShort
      houseStreet
      currentTenantText
      currentTenantContractStart
      currentTenantContractEnd
      rentalContractId
      property {
        propertyId
      }
      currentTenant {
        firstName
        lastName
      }
      newTenant {
        __typename
      }
    }
  }
`

export { ABACUS_PROPERTY_LINK, ABACUS_PROPERTY_OBJECT }
