var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"grey lighten-5 pa-2",attrs:{"fluid":""}},[_c('v-card',[_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"search","label":_vm.$t('common.buttons.search'),"single-line":"","hide-details":""},on:{"input":_vm.debounceInput},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"sort-by":"id","sort-desc":"","options":_vm.options,"footer-props":{ itemsPerPageOptions: _vm.itemsPerPageOptions, itemsPerPageText: _vm.itemsPerPageText }},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.openTaskDetails},scopedSlots:_vm._u([{key:"item.taskType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('tasks.types.' + item.taskType))+" ")]}},{key:"item.propertyInfo",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.propertyInfo)}})]}},{key:"item.jobDescription",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-0 truncate-two-lines"},[_vm._v(_vm._s(item.jobDescription))])]}},{key:"item.craftsmanSubject",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.craftsmanSubject)}})]}},{key:"item.createdOn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.createdOnFormatted)+" ")]}},{key:"item.taskSource",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.taskSource ? _vm.$t('tasks.taskSources.' + item.taskSource) : '')+" ")]}}],null,true)},[_c('v-progress-linear',{attrs:{"slot":"progress","color":"blue","indeterminate":""},slot:"progress"}),_c('template',{slot:"no-data"},[_c('v-alert',{staticClass:"mt-4",attrs:{"value":true,"color":"error","icon":"warning"}},[_vm._v(" "+_vm._s(_vm.$t('table.noData'))+" ")])],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }