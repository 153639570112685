<template>
  <v-card class="mb-2 pa-0">
    <v-card-text class="pa-2">
      <v-icon color="primary" @click="toggle" class="float-right">{{ icon }}</v-icon>
      <v-card-subtitle class="pa-0 font-weight-bold subtitle-1">
        {{ $t('ticketingSystem.processes.reletting.title') }}
      </v-card-subtitle>
    </v-card-text>
    <v-divider></v-divider>
    <v-layout v-if="isOpen" row wrap class="pa-0 ma-0">
      <v-flex xs12 sm12 md12 lg12 v-if="!loading">
        <v-card-text v-if="hasProcessVariables">
          <div v-for="(item, index) in processVariables" :key="index">
            <p v-if="item.variableName === 'System_Reletting_Property' && item.variableValue" class="pa-0 ma-0">
              <v-card-subtitle class="pa-0 ma-0 font-weight-bold">
                {{ $t('dashboard.actions.property.title') }}
              </v-card-subtitle>
              [<a @click="onPropertyClicked">{{ item.variableValue }}</a>]
              {{ propertyInfo }}
            </p>
            <p v-else-if="item.variableName === 'System_Reletting_PropertyObject' && item.variableValue" class="pa-0 ma-0">
              {{ objectFloorInfo }}
            </p>
            <p v-else-if="item.variableName === 'System_TerminationTenantId_Long' && item.variableValue" class="pa-0 ma-0 mb-2">
              <v-card-subtitle class="pa-0 ma-0 font-weight-bold">
                {{ $t('dashboard.actions.tenant.title') }}
              </v-card-subtitle>
              {{ addressInfo }}
            </p>
            <p v-else-if="item.variableName === 'System_TenantLiableUntil_Date' && item.variableValue" class="pa-0 ma-0 mb-2">
              <v-card-subtitle class="pa-0 ma-0 font-weight-bold">
                {{ $t('ticketingSystem.processes.reletting.liableUntil') }}
              </v-card-subtitle>
              {{ formatDateFunc(item.variableValue) }}
            </p>
            <div v-else-if="item.variableName === 'System_RentalCaseToken_String' && token" class="pa-0 ma-0 d-inline-block">
              <a @click="openPublicRentalForm(item.variableValue)">{{ href }}{{ item.variableValue }}</a><br />
              {{ token }}<br />
              <QRCode :content="href + token" />
              <ProcessRelettingPdf
                :propertyInfo="propertyInfo"
                :objectFloorInfo="objectFloorInfo"
                :token="token"
              />
            </div>
          </div>
          <v-expansion-panels v-model="panel">
            <ProcessSpecialities :propertyId="propertyId" />
            <ProcessRentalFormsTable :rental-case-id="rentalCaseId" />
          </v-expansion-panels>
        </v-card-text>
        <v-card-text v-else class="no-info">
          {{ $t('ticketingSystem.processes.termination.noInfo') }}
        </v-card-text>
        <v-divider></v-divider>
      </v-flex>
    </v-layout>
  </v-card>
</template>
<script>
import ProcessSpecialities from './common/ProcessSpecialities'
import ProcessRentalFormsTable from './process-reletting/ProcessRentalFormsTable'
import { mapActions } from 'vuex'
import { formatDate } from '@/helpers/formatterHelper'
import { openDashboardProperty } from '@/helpers/routesHelper'
import QRCode from '@/components/QRCode'
import * as ticketingHelper from '@/features/ticketing/helpers/ticketingHelper'
import ProcessRelettingPdf from './process-reletting/ProcessRelettingPdf'

export default {
  name: 'ProcessReletting',
  components: {
    QRCode,
    ProcessSpecialities,
    ProcessRentalFormsTable,
    ProcessRelettingPdf
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    processVariables: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      panel: [],
      isOpen: true,
      loading: false,
      addressDetails: {},
      addressInfo: '',
      propertyInfo: '',
      objectFloorInfo: '',
      token: ''
    }
  },
  computed: {
    icon () {
      return this.isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
    },
    hasProcessVariables () {
      return this.processVariables && this.processVariables.length > 0
    },
    href () {
      return window.location.protocol + '//' + window.location.host + '/renting/form/'
    },
    propertyId () {
      let propertyId = this.getVariableValue('System_Reletting_Property')
      return propertyId ? propertyId.toString() : ''
    },
    rentalCaseId () {
      let rentalCaseId = this.getVariableValue('System_RentalCaseId_Long')
      return rentalCaseId ? rentalCaseId.toString() : ''
    }
  },
  watch: {
    'processVariables': {
      handler: 'loadData',
      immediate: true
    }
  },
  methods: {
    ...mapActions('abacus', ['loadAddressDetails']),
    ...mapActions('notifier', ['setSuccessSnackbar', 'setErrorSnackbar']),
    formatDateFunc: formatDate,
    getVariableValue (variableName) {
      return ticketingHelper.getVariableValue(this.processVariables, variableName)
    },
    toggle () {
      this.isOpen = !this.isOpen
    },
    loadData () {
      this.processVariables.forEach(item => {
        if (item.variableName === 'System_Reletting_Property') {
          this.getPropertyInfo(this.propertyId)
        }
        if (item.variableName === 'System_TerminationTenantId_Long') {
          this.getAddressInfo(item.variableValue)
        }
        if (item.variableName === 'System_RentalCaseToken_String') {
          this.token = item.variableValue
        }
      })
    },
    getPropertyInfo (propertyId) {
      let that = this
      let objectVariable = this.processVariables.find(x => x.variableName === 'System_Reletting_PropertyObject')
      this.$abacusProperty.getPropertyById(propertyId).then(response => {
        if (response.data) {
          let data = response.data
          that.propertyInfo = data.street + ', ' + data.zip + ' ' + data.place
          if (objectVariable.variableValue) {
            let objectData = data.objects.find(x => x.objectId === parseInt(objectVariable.variableValue))
            if (objectData) {
              that.objectFloorInfo = objectData.floorText + (objectData.objectText ? ' - ' + objectData.objectText : '')
            }
            that.loading = false
          } else {
            that.loading = false
          }
        }
      }).catch(error => {
        that.loading = false
        that.setErrorSnackbar(error)
      })
    },
    async getAddressInfo (addressId) {
      this.addressDetails = await this.loadAddressDetails(addressId)
      this.addressInfo = '[' + this.addressDetails.id + '] ' + this.addressDetails.firstName + ' ' + this.addressDetails.lastName
    },
    onPropertyClicked () {
      openDashboardProperty(this.propertyId, true)
    },
    openPublicRentalForm (token) {
      window.open('/renting/form/' + token)
    }
  }
}
</script>
<style scoped lang="postcss">
.no-info {
  color: rgba(0,0,0,.6)
}
/deep/ tr:nth-child(odd) {
  background: #f8f8f8;
}
</style>
