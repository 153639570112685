<template>
  <div class="task-dates">
    <div class="created-on float-left mr-6 mb-3 mt-2">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span>
            <v-icon color="grey darken-2" :size="22" v-bind="attrs" v-on="on">mdi-calendar-start</v-icon>
            {{ formatDateAndTimeFunc(ticketingTask.startedOn) }}
          </span>
        </template>
        <span>{{ $t('common.date.createdOn') }}</span>
      </v-tooltip>
    </div>
    <!--
    <div id="followUpId" class="follow-up-on float-left mr-6 mb-3 mt-2">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <FollowUpDate
            :task-finished="taskFinished"
            :task-details="ticketingTask"
            :attach-selector="'#followUpId'"
            @updateFollowUpDate="updateTaskFollowUpDate"
          />
        </template>
      </v-tooltip>
    </div>
    -->
    <div id="dueId" class="due-on float-left mr-6 mb-3 mt-2">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <DueDate
            v-bind="attrs"
            v-on="on"
            :task-finished="taskFinished"
            :task-details="ticketingTask"
            :attach-selector="'#dueId'"
            @updateDueDate="updateTaskDueDate"
          />
        </template>
      </v-tooltip>
    </div>
    <div class="finished-on float-left mr-6 mb-3 mt-2" v-show="taskFinished">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span>
            <v-icon color="grey darken-2" :size="22" v-bind="attrs" v-on="on">mdi-calendar-end</v-icon>
            {{ formatDateAndTimeFunc(ticketingTask.finishedOn) }}
          </span>
        </template>
        <span>{{ $t('common.date.finishedOn') }}</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import DueDate from '@/features/ticketing/components/DueDate'
import { formatDateAndTime } from '@/helpers/formatterHelper'
import { mapActions } from 'vuex'

export default {
  name: 'TaskDates',
  components: {
    DueDate
  },
  data () {
    return { }
  },
  props: {
    ticketingTask: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    taskFinished () {
      return this.ticketingTask && this.ticketingTask.finishedOn !== '' && this.ticketingTask.finishedOn !== null
    },
    routeTaskId () {
      return this.$route.params.taskId || this.$route.query.taskId
    }
  },
  methods: {
    ...mapActions('notifier', ['setSuccessSnackbar', 'setErrorSnackbar']),
    formatDateAndTimeFunc: formatDateAndTime,
    updateTaskFollowUpDate (resetFollowUpDate = false, newFollowUpDate) {
      let followUpDateBody = {}
      if (!resetFollowUpDate) {
        followUpDateBody = {
          followUpDate: newFollowUpDate
        }
      }
      let that = this
      this.$prestigeTSTask.updateTaskFollowUpDate(this.routeTaskId, followUpDateBody).then(function () {
        that.$emit('loadTaskDetails')
        that.setSuccessSnackbar(that.$t('ticketingSystem.messages.successfullyUpdatedFollowUpDate'))
        return true
      }).catch(error => {
        that.setErrorSnackbar(error)
      })
    },
    updateTaskDueDate (resetDueDate = false, newDueDate) {
      let dueDateBody = {}
      if (!resetDueDate) {
        dueDateBody = {
          dueDate: newDueDate
        }
      }
      let that = this
      this.$prestigeTSTask.updateTaskDueDate(this.routeTaskId, dueDateBody).then(function () {
        that.$emit('loadTaskDetails')
        that.setSuccessSnackbar(that.$t('ticketingSystem.messages.successfullyUpdatedDueDate'))
        return true
      }).catch(error => {
        that.setErrorSnackbar(error)
      })
    }
  }
}
</script>

<style scoped lang="postcss">
/deep/ a span {
  line-height: 1;
}
.delete_forever {
  cursor: pointer;
}
/deep/ .due-on input:not(.display-time), /deep/ .follow-up-on input:not(.display-time) {
  width: 150px;
}
/deep/ .v-input {
  height: 30px;
  margin: 0;
  padding: 0;
}
/deep/ .v-input__prepend-outer {
  margin-top: 0;
}
/deep/ .v-input__control {
  margin-top: -10px;
  height: 40px;
}
.img-icon {
  vertical-align: middle;
}
</style>
