import Vue from 'vue'
import Router from 'vue-router'

import { AdminRoutes } from '../features/admin/router/routes'
import { AbacusRoutes } from '../features/abacus/router/routes'
import { ConstructionRoutes } from '../features/construction-projects/router/routes'
import { DashboardRoutes } from '../features/dashboard/router/routes'
import { DossierRoutes } from '../features/dossier/router/routes'
import { FeedbackRoutes } from '../features/feedback/router/routes'
import { HeatingOilRoutes } from '../features/heating-oil/router/routes'
import { MapRoutes } from '../features/map/router/routes'
import { RentingRoutes } from '../features/renting/router/routes'
import { StatisticsRoutes } from '../features/statistics/router/routes'
import { TicketingStatisticsRoutes } from '../features/ticketing-statistics/router/routes'
import { TaskRoutes } from '../features/tasks/router/routes'
import { TicketingRoutes } from '../features/ticketing/router/routes'
import { UserRoutes } from '../features/user/router/routes'

Vue.use(Router)

// config-v2.js is included in index.html and overwritten in the runtime
/* global config:true */

const routes = [
  {
    path: '/',
    redirect: config.DASHBOARD_ENABLED ? '/dashboard' : '/tasks'
  },
  ...AdminRoutes,
  ...AbacusRoutes,
  ...ConstructionRoutes,
  ...DashboardRoutes,
  ...DossierRoutes,
  ...FeedbackRoutes,
  ...HeatingOilRoutes,
  ...MapRoutes,
  ...RentingRoutes,
  ...StatisticsRoutes,
  ...TicketingStatisticsRoutes,
  ...TaskRoutes,
  ...TicketingRoutes,
  ...UserRoutes,
  {
    path: '/:catchAll(.*)',
    redirect: '/'
  }
]
const router = new Router({
  mode: 'history',
  routes
})

export default router
