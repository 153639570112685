<template>
  <v-card class="mb-2">
    <v-container fluid grid-list-xs class="pa-0">
      <v-card-text class="pb-0 mb-4">
        <v-icon color="primary" @click="toggle" class="float-right">{{ icon }}</v-icon>
        <v-card-subtitle class="pa-0 font-weight-bold subtitle-1">
          {{ $t('ticketingSystem.processes.variables.structuredData') }}
        </v-card-subtitle>
      </v-card-text>
      <v-divider></v-divider>
      <div v-if="isOpen">
        <v-list>
          <v-list-item
            two-line
            v-for="(item, index) in processVariables"
            :key="item.variableName + '_' + index"
          >
            <v-list-item-content>
              <v-list-item-subtitle class="font-weight-bold pa-0">
                {{ item.variableName }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                {{ item.variableValue }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" @click.stop="openDialog(item)">
                    <v-icon>more</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('ticketingSystem.processes.variables.showMore') }}</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </div>
    </v-container>
    <VariableContentDialog
      :title="title"
      :json-data="data"
      content-type="json"
      :show-dialog="showDialog"
      @closeDialog="closeDialog"
    />
  </v-card>
</template>

<script>
import VariableContentDialog from './VariableContentDialog'

export default {
  name: 'ProcessStructuredVariables',
  components: {
    VariableContentDialog
  },
  props: {
    processVariables: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      isOpen: false,
      showDialog: false,
      title: '',
      data: []
    }
  },
  computed: {
    icon () {
      return this.isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
    }
  },
  methods: {
    toggle () {
      this.isOpen = !this.isOpen
    },
    openDialog (item) {
      this.title = item.variableName
      this.data = item.variableValue
      this.showDialog = true
    },
    closeDialog () {
      this.showDialog = false
    }
  }
}
</script>

<style scoped lang="postcss">
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px lightgray;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(99, 99, 99, .7);
  background-color: #fff;
}
</style>
