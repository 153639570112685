<template>
  <div>
    <v-list-item class="pa-0" v-for="item in form.formFields" :key="item.formFieldId">
      <v-checkbox
        v-if="item.formFieldType === 'boolean'"
        class="header-checkbox mt-0"
        primary
        v-model="item.formFieldDefaultValue"
        :label="item.formFieldLabel"
        :disabled="formReadOnly || item.formFieldReadOnly"
        :required="item.formFieldRequired"
        :rules="item.formFieldRequired ? [rules.checkboxRequired] : []"
      >
        <template #message="{ message }">
          <span v-if="message === 'required'">{{ requiredMessage }}</span>
        </template>
      </v-checkbox>
      <v-text-field
        v-if="item.formFieldType === 'string'"
        v-model="item.formFieldDefaultValue"
        :label="item.formFieldLabel"
        :disabled="formReadOnly || item.formFieldReadOnly"
        :required="item.formFieldRequired"
        :rules="item.formFieldRequired ? [rules.required] : []"
      >
        <template #message="{ message }">
          <span v-if="message === 'required'">{{ requiredMessage }}</span>
        </template>
      </v-text-field>
      <v-text-field
        v-if="item.formFieldType === 'long'"
        v-model.number="item.formFieldDefaultValue"
        type="number"
        :label="item.formFieldLabel"
        :disabled="formReadOnly || item.formFieldReadOnly"
        :readonly="item.formFieldReadOnly"
        :required="item.formFieldRequired"
        :rules="item.formFieldRequired ? [rules.required] : []"
      >
        <template #message="{ message }">
          <span v-if="message === 'required'">{{ requiredMessage }}</span>
        </template>
      </v-text-field>
      <v-flex v-if="item.formFieldType === 'textarea'">
        <AutocompleteLinks
          ref="formAutocomplete"
          v-model="item.formFieldDefaultValue"
          :label="item.formFieldLabel"
          :name="item.formFieldId"
          :default-html="item.formFieldDefaultValue"
          :disabled="formReadOnly || item.formFieldReadOnly"
          :required="item.formFieldRequired"
          :validate-clicked="validateClicked"
          :additional-links="additionalLinks"
          @autocompleteLinksUpdated="onAutocompleteLinksUpdated"
        />
      </v-flex>
      <DatePicker
        ref="formDate"
        v-if="item.formFieldType === 'date'"
        v-model="item.formFieldDefaultValue"
        :label="item.formFieldLabel"
        :default-value="item.formFieldDefaultValue ? true : false"
        :default-value-date="item.formFieldDefaultValue"
        :disabled="formReadOnly || item.formFieldReadOnly"
        :required="item.formFieldRequired"
        :attach-selector="dialog ? '' : '.datepicker-input'"
      />
      <v-radio-group
        row
        v-if="item.formFieldType === 'enum'"
        v-model="item.formFieldDefaultValue"
        :required="item.formFieldRequired"
        :rules="item.formFieldRequired ? [rules.radioRequired] : []"
      >
        <v-list-item-subtitle class="subtitle-1 mb-2">
          {{ item.formFieldLabel }}
        </v-list-item-subtitle>
        <v-radio
          v-for="(subItem, i) in item.formFieldOptions"
          :key="i"
          class="pr-5 mr-0"
          :label="subItem.label"
          :value="subItem.value"
          :disabled="formReadOnly || item.formFieldReadOnly"
        />
        <template #message="{ message }">
          <span v-if="message === 'required'">{{ requiredMessage }}</span>
        </template>
      </v-radio-group>
      <v-flex v-if="item.formFieldType === 'property'">
        <v-autocomplete
          id="propertySelect"
          class="ma-0 pa-0"
          :label="$t('ticketingSystem.taskDetails.form.searchProperty')"
          :disabled="formReadOnly || item.formFieldReadOnly"
          :loading="abacusPropertiesLoading"
          single-line
          :items="abacusPropertiesDisplayItems"
          :filter="customPropertyFilter"
          item-text="displayText"
          item-value="propertyId"
          @change="loadPropertyDetails(item)"
          clearable
          v-model="item.formFieldDefaultValue"
        />
      </v-flex>
      <v-flex v-if="item.formFieldType === 'propertyObject'">
        <v-autocomplete
          id="propertyObject"
          class="ma-0 pa-0"
          :label="$t('ticketingSystem.taskDetails.form.searchPropertyObjects')"
          :disabled="formReadOnly || item.formFieldReadOnly"
          :loading="item.loadingPropertyObjects"
          single-line
          :items="item.options"
          item-text="displayText"
          item-value="objectId"
          clearable
          v-model="item.formFieldDefaultValue"
        />
      </v-flex>
      <v-flex v-if="item.formFieldType === 'file' || item.formFieldType === 'azureFile'">
        <VariableFileUpload
          ref="formFile"
          :deletable="!formReadOnly && !item.formFieldReadOnly"
          :variable-file="setVariableFile(item.formFieldId)"
          :form-field-id="item.formFieldId"
          :disabled="formReadOnly || item.formFieldReadOnly"
          :required="item.formFieldRequired"
          :validate-clicked="validateClicked"
          :label="item.formFieldLabel"
          :process-instance-id="processInstanceId"
          @addFileVariable="onAddFileVariable"
          @removeFileVariable="onRemoveFileVariable"
          @loadProcessVariables="onLoadProcessVariables"
        />
      </v-flex>
    </v-list-item>
  </div>
</template>

<script>
import AutocompleteLinks from '@/features/ticketing/components/AutocompleteLinks'
import VariableFileUpload from '@/features/ticketing/components/VariableFileUpload'
import DatePicker from '@/components/DatePicker'
import * as validationHelper from '@/helpers/validationHelper'
import * as globalHelper from '@/helpers/globalHelper'
import { mapState } from 'vuex'
import Vue from 'vue'
import _cloneDeep from 'lodash.clonedeep'

export default {
  name: 'MyStaticForm',
  props: {
    form: {
      type: Object,
      default: () => {}
    },
    processInstanceId: {
      type: String,
      default: ''
    },
    formReadOnly: {
      type: Boolean,
      default: false
    },
    validateClicked: {
      type: Boolean,
      default: false
    },
    dialog: {
      type: Boolean,
      default: false
    },
    files: {
      type: Array,
      default: () => []
    },
    additionalLinks: {
      type: Array,
      default: () => []
    }
  },
  components: {
    AutocompleteLinks,
    DatePicker,
    VariableFileUpload
  },
  data () {
    return {
      variableFiles: [],
      valid: false,
      rules: {
        required: (value) => !!value || this.$t('common.errors.required'),
        radioRequired: (value) => (value !== undefined && value !== '' && value !== null) || this.requiredMessage,
        checkboxRequired: (value) => (value !== undefined && value !== '' && value !== null && value) || this.requiredMessage,
        maxLength: (value) => (value.length <= 255) || 'maxLength',
        email: (value) => { let valid = validationHelper.validateEmail(value); return valid }
      }
    }
  },
  computed: {
    ...mapState('abacus', ['abacusProperties', 'abacusPropertiesDisplayItems', 'abacusPropertiesLoading']),
    requiredMessage () {
      return this.$t('common.errors.required')
    },
    maxLengthMessage () {
      return this.$t('ticketingSystem.startProcess.maxLength')
    }
  },
  methods: {
    loadPropertyDetails (item) {
      let optionsField = this.form.formFields.find(field => field.formFieldId === item.formFieldId + 'Object')
      let that = this
      let index = this.form.formFields.findIndex(x => x.formFieldId === item.formFieldId + 'Object')
      if (index !== -1 && item.formFieldDefaultValue !== null) {
        let options = []
        that.$set(that.form.formFields, index, Object.assign({}, optionsField, { loadingPropertyObjects: true }))
        this.$abacusProperty.getPropertyById(item.formFieldDefaultValue).then(response => {
          let data = response.data
          if (data) {
            this.propertyObjects = data.objects
            this.propertyObjects.forEach(propertyObject => {
              let displayInfo = '[' + propertyObject.objectId + ']' + ' ' + propertyObject.houseStreet + ' - ' + propertyObject.floorText + ' - ' +
                propertyObject.objectText + ' ' + propertyObject.objectLocationText
              let displayListItem = {
                objectId: propertyObject.objectId,
                displayText: displayInfo
              }
              options.push(displayListItem)
            })
          }
          that.$set(that.form.formFields, index, Object.assign({}, optionsField, { loadingPropertyObjects: false, options }))
          return true
        }).catch(function (error) {
          this.setErrorSnackbar(error)
        })
      }
    },
    customPropertyFilter (item, queryText, itemText) {
      return globalHelper.propertyFilter(queryText, itemText)
    },
    onAddFileVariable (file, formFieldId) {
      let newFile = { id: formFieldId, file: file }
      let index = this.variableFiles.findIndex(x => x.id === formFieldId)
      if (index === -1) {
        this.variableFiles.push(newFile)
      } else {
        Vue.set(this.variableFiles, index, newFile)
      }
      this.$emit('updateFiles', this.variableFiles)
    },
    onRemoveFileVariable (formFieldId, file) {
      let index = this.variableFiles.findIndex(x => x.id === formFieldId)
      if (index !== -1) {
        this.variableFiles.splice(index, 1)
      }
      this.$emit('updateFiles', this.variableFiles)
    },
    onLoadProcessVariables (file, formFieldId) {
      this.onAddFileVariable(file, formFieldId)
    },
    setVariableFile (formFieldId) {
      let variableFile = this.variableFiles.find(x => x.id === formFieldId)
      if (variableFile) {
        return variableFile.file
      }
      return null
    },
    reinitFields () {
      if (this.form && this.form.formFields) {
        this.form.formFields.filter(field => field.formFieldType === 'property').forEach(field => this.loadPropertyDetails(field))
        let fileFields = this.form.formFields.filter(x => x.formFieldType === 'file' || x.formFieldType === 'azureFile')
        if (fileFields) {
          this.variableFiles = []
          let that = this
          fileFields.forEach(x => {
            that.variableFiles.push({ id: x.formFieldId, file: x.formFieldDefaultValue })
          })
          this.$emit('updateFiles', this.variableFiles)
        }
      }
    },
    onAutocompleteLinksUpdated (autocompleteLinks) {
      this.$emit('autocompleteLinksUpdated', autocompleteLinks)
    }
  }
}
</script>

<style scoped lang="postcss">
</style>
