<template>
  <div>
    <v-btn
      v-if="multiDeclineOption"
      :disabled="selected.length === 0"
      :loading="declineLoading"
      color="primary"
      class="mb-3"
      @click="openDeclineDialog">
      {{ $t('renting.abacus.confirmDeclineTitle') }} ({{selected.length}})
    </v-btn>
    <ConfirmationDialog
      :data="declineDialogData"
      :show-dialog="showDeclineDialog"
      @closeDialog="closeDeclineDialog"
      @submitDialog="declineSelected"
    />
    <v-data-table
      v-if="rentalForms"
      v-model="selected"
      dense
      :headers="headers"
      :loading="loading"
      :items="rentalForms"
      :show-select="multiDeclineOption"
      :options.sync="options"
      :footer-props="{itemsPerPageOptions:itemsPerPageOptions, itemsPerPageText: itemsPerPageText}"
      item-key="ticketId"
      class="elevation-1"
    >
      <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
      <template v-slot:[`item.processStartedOn`]="{ item }">
        <span>{{ item.processStartedOnFormatted }}</span>
      </template>
      <template v-slot:[`item.desiredMoveInDateFormatted`]="{ item }">
        {{ item.desiredMoveInDateFormatted }}
      </template>
      <template v-slot:[`item.processDecision`]="{ item }">
        <DecisionIcon :decision="item.processDecision" />
        <span>{{ $t('renting.statuses.' + item.processDecision) }}</span>
        <p class="text-pre-wrap" v-if="item.processDecisionComment" v-html="item.processDecisionComment"></p>
      </template>
      <template v-slot:[`item.step`]="{ item }">
        <div v-if="item.openTasks">
          <p v-for="(task, index) in item.openTasks" :key="'open_task' + index" class="open-task pa-1 mb-0">
            {{ task.name }}
          </p>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <nobr>
          <v-btn x-small color="blue" @click.stop="openProcessDetails(item.ticketId)">
            <span class="white--text">{{ $t('common.buttons.open') }}</span>
            <v-icon small class="ml-2" color="white">mdi-window-open</v-icon>
          </v-btn>
        </nobr>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import DecisionIcon from '../../../components/DecisionIcon'
import ConfirmationDialog from '../../../components/ConfirmationDialog'
import { mapActions } from 'vuex'

export default {
  name: 'RentalFormL',
  components: {
    ConfirmationDialog,
    DecisionIcon
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    multiDeclineOption: {
      type: Boolean,
      default: false
    },
    rentalForms: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      showDeclineDialog: false,
      declineLoading: false,
      message: '',
      selected: [],
      options: {
        sortBy: ['processStartedOn'],
        sortDesc: [true],
        itemsPerPage: 10,
        page: 1
      },
      itemsPerPageOptions: [ 10, 20, 50, 100 ]
    }
  },
  computed: {
    rentalFormUrl () {
      return window.location.protocol + '//' + window.location.host + '/renting/form/' + this.rentalCase?.token
    },
    propertyInfo () {
      return this.rentalCase?.propertyObject?.houseStreet + ', ' + this.rentalCase?.propertyObject?.property?.zip + ' ' + this.rentalCase?.propertyObject?.property?.place
    },
    objectInfo () {
      return this.rentalCase?.propertyObject?.floorText + ' - ' + this.rentalCase?.propertyObject?.objectText
    },
    headers () {
      return [
        {
          text: this.$t('ticketingSystem.processes.reletting.labels.name'),
          align: 'left',
          value: 'name',
          sortable: true,
          width: '20%'
        },
        {
          text: this.$t('ticketingSystem.processes.reletting.labels.received'),
          align: 'left',
          value: 'processStartedOn',
          sortable: true,
          width: '20%'
        },
        {
          text: this.$t('renting.publicForm.desiredMoveInDate'),
          align: 'left',
          value: 'desiredMoveInDateFormatted',
          sortable: true,
          width: '20%'
        },
        {
          text: this.$t('ticketingSystem.processes.reletting.labels.step'),
          value: 'step',
          sortable: false,
          width: '30%'
        },
        {
          text: this.$t('ticketingSystem.processes.reletting.labels.state'),
          align: 'left',
          value: 'processDecision',
          width: '20%'
        },
        {
          text: this.$t('ticketingSystem.processes.reletting.labels.actions'),
          sortable: false,
          value: 'actions',
          width: '10%'
        }
      ]
    },
    itemsPerPageText () {
      return this.$t('common.itemsPerPageText')
    },
    declineDialogData () {
      return {
        title: this.$t('renting.abacus.confirmDeclineTitle'),
        question: this.$t('renting.abacus.confirmDeclineMessage'),
        confirmButton: this.$t('common.buttons.confirm')
      }
    }
  },
  methods: {
    ...mapActions('notifier', ['setSuccessSnackbar', 'setErrorSnackbar']),
    openProcessDetails (processInstanceId) {
      window.open('/ticketing/processes/' + processInstanceId)
    },
    openDeclineDialog () {
      this.showDeclineDialog = true
    },
    closeDeclineDialog () {
      this.showDeclineDialog = false
    },
    declineSelected () {
      this.closeDeclineDialog()
      this.declineLoading = true
      Promise.all(this.selected.map(rentalForm => {
        const payload = {
          variables: [
            {
              'variableName': 'System_InterestedPerson_Decision_Enum',
              'variableValue': 'ALREADY_RENTED',
              'variableType': 'string'
            }
          ]
        }
        return this.$prestigeTSProcess.createMessageEvent(rentalForm.ticketId, 'DecisionEvent', payload)
      })).then(() => {
        this.setSuccessSnackbar(this.$t('renting.abacus.multiDeclineDone'))
        this.selected = []
        this.declineLoading = false
        this.$emit('loadRentalForms')
      }).catch(error => {
        this.setErrorSnackbar(error)
        this.declineLoading = false
      })
    }
  }
}
</script>
<style scoped lang="postcss">
</style>
