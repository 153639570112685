var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"checklist-content-card height mb-2 mr-0"},[_c('v-container',{attrs:{"fluid":"","grid-list-xs":""}},[_c('v-card-text',{staticClass:"pa-0 mb-2"},[_c('v-card-subtitle',{staticClass:"pa-0 font-weight-bold subtitle-1"},[_vm._v(" "+_vm._s(_vm.$t('ticketingSystem.taskDetails.checkList'))+" "),(!_vm.taskFinished && _vm.showAddBtn)?_c('v-btn',{staticClass:"pa-3 white--text float-right",attrs:{"color":"success","x-small":_vm.mdAndDown},on:{"click":function($event){_vm.shouldShowChildDialog = true}}},[_c('span',[_vm._v(_vm._s(_vm.$t('common.buttons.add')))]),_c('v-icon',{staticClass:"pl-1",attrs:{"x-small":_vm.mdAndDown}},[_vm._v("mdi-vector-polyline-plus")])],1):_vm._e()],1)],1),_c('v-main',{staticClass:"pa-0 ma-0"},[(_vm.ticketingTask && _vm.ticketingTask.checklistItems && _vm.ticketingTask.checklistItems.length > 0)?_c('v-data-table',{staticClass:"mt-2",attrs:{"headers":_vm.headers,"items":_vm.ticketingTask.checklistItems,"options":_vm.options,"multi-sort":"","items-per-page":5,"footer-props":{ itemsPerPageOptions: _vm.itemsPerPageOptions, itemsPerPageText: _vm.itemsPerPageText }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.priority",fn:function(ref){
var item = ref.item;
return [_c('TicketPriority',{attrs:{"read-only":true},model:{value:(item.priority),callback:function ($$v) {_vm.$set(item, "priority", $$v)},expression:"item.priority"}})]}},{key:"item.finishedOn",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_c('StateIcon',{attrs:{"state":item.finishedOn === '' || item.finishedOn === null ? 'ACTIVE' : 'COMPLETED'}}),_vm._v(" "+_vm._s(_vm.formatDateAndTimeFunc(item.finishedOn))+" ")],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item)?_c('CheckListItemInfo',{attrs:{"item":item},on:{"loadTaskDetails":_vm.onLoadTaskDetails}}):_vm._e()]}},{key:"item.dueDate",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"due-on mb-5 mt-7 text-no-wrap",attrs:{"id":"childTaskDueDate"}},[_c('DueDate',{ref:"dueDate",attrs:{"task-finished":_vm.childTaskFinished(item),"task-details":item},on:{"updateDueDate":_vm.updateTaskDueDate}})],1)]}},{key:"item.assignee",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"assignee"},[(item.finishedOn)?_c('div',[_c('UserName',{attrs:{"user":item.assignee}})],1):_vm._e(),(!_vm.childTaskFinished(item))?_c('div',{staticClass:"float-left"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"grey darken-2","size":22}},'v-icon',attrs,false),on),[_vm._v("mdi-account-edit-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('ticketingSystem.entry.assignee')))])]),(!(item.showDropdown && item.taskId === _vm.selectedChildTaskId))?_c('a',{on:{"click":function($event){$event.stopPropagation();return _vm.showChildTaskAssigneeDropdown(item)}}},[(!item.showDropdown && !item.assignee && !_vm.childTaskFinished(item))?_c('span',[_vm._v(_vm._s(_vm.$t('ticketingSystem.taskDetails.buttons.unassigned')))]):_vm._e(),_c('UserName',{attrs:{"user":item.assignee}})],1):_vm._e()],1):_vm._e(),_c('div',{staticClass:"float-left"},[(item.showDropdown)?_c('TaskAssignee',{ref:"assigneeLink",attrs:{"form":_vm.select,"assigned-to-me":_vm.childTaskAssignedToMe(item),"user-has-ticketing-role":_vm.userHasTicketingRole},on:{"changedAssignee":function($event){return _vm.changeChildTaskAssignee(false, item.taskId)},"hideAssigneeDropdown":function($event){return _vm.hideChildTaskAssigneeDropdown(item)}}}):_vm._e()],1)])]}},{key:"item.candidateGroups",fn:function(ref){
var item = ref.item;
return [_c('CandidateGroups',{attrs:{"checkList":true,"task-id":item.taskId,"task-candidate-groups":item.candidateGroups,"task-finished":_vm.childTaskFinished(item),"finished-loading":_vm.finishedLoading},on:{"loadTaskDetails":_vm.onLoadTaskDetails}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(!item.finishedOn)?_c('div',[_c('v-btn',{staticClass:"pa-3",attrs:{"color":"primary","x-small":""},on:{"click":function($event){return _vm.assignToLoggedInUserAndCompleteChildTask(item)}}},[_c('span',[_vm._v(_vm._s(_vm.$t('ticketingSystem.taskDetails.buttons.done')))]),_c('v-icon',{staticClass:"pl-1",attrs:{"x-small":""}},[_vm._v("done")])],1)],1):_vm._e()]}}],null,true)}):_c('v-card-text',{staticClass:"mt-2 pa-0"},[_vm._v(" "+_vm._s(_vm.$t('table.noData'))+" ")])],1),(_vm.shouldShowChildDialog)?_c('ChildTaskDialog',{attrs:{"parent-task-id":_vm.ticketingTask.taskId},on:{"closeDialog":function($event){_vm.shouldShowChildDialog = false},"loadTaskDetails":_vm.onLoadTaskDetails}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }