<template>
  <div class="mb-2">
    <v-card-title class="pl-1 pt-4">
      {{ $t('dashboard.matchingAddresses') }}
    </v-card-title>
    <v-data-table
      item-key="id"
      single-select
      class="elevation-1"
      :class="tableClassName + ' cy-dashboard-person-results'"
      :loading="$apollo.loading"
      :headers="addressHeaders"
      :options.sync="options"
      :footer-props="{ itemsPerPageOptions: addressItemsPerPageOptions, itemsPerPageText: addressItemsPerPageText }"
      :items="abacusAddressItems"
      :item-class="rowClass"
      :server-items-length="totalItems"
      @click:row="showAddressDetails"
    >
      <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
      <template v-slot:[`item.icon`]="{ item }">
        <div class="d-flex flex-column">
          <span v-if="item.icon === 'abacus-logo'" class="abacus-logo"></span>
          <span v-else><v-icon color="brown">{{ item.icon }}</v-icon></span>
        </div>
      </template>
      <template v-slot:[`item.phone`]="{ item }">
        <div class="d-flex flex-column">
          <div v-if="item.mobile">{{ item.mobile }}</div>
          <div v-if="item.phone1">{{ item.phone1 }}</div>
          <div v-if="item.phone2">{{ item.phone2 }}</div>
        </div>
      </template>
      <template slot="no-data">
        <v-alert class="mt-4" :value="true" id="cy-dashboard-person-no-results" color="error" icon="warning">
          {{ $t('table.noData') }}
        </v-alert>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { DASHBOARD_CONSTANTS } from '@/config/options/dashboardConstants'
import { SEARCH_ADDRESSES } from './graphql-apollo/searchAddresses.gql'
import { getDashboardColor, removeTableSelections , setSelectedItemHighlightClass } from './helpers/dashboardHelper'

export default {
  name: 'MatchingAddresses',
  props: {
    apolloSearchQuery: {
      type: Object,
      default: () => {}
    },
    addressType: {
      type: String,
      default: ''
    }
  },
  apollo: {
    searchAddresses: {
      query: SEARCH_ADDRESSES,
      update (data) {
        data.searchAddresses.items.forEach(item => {
          item.id = item.addressId
          item.icon = item.processInstanceId ? 'mdi-ticket' : 'abacus-logo'
          let addressLine = (item.processInstanceId ? item.line1 : item.line2).trim()
          item.address = ((addressLine ? addressLine + ', ' : '')  + (item.zip + ' ' + item.place)).trim()
        })

        // open address, if we have only one result
        if (data.searchAddresses.items.length === 1) {
          let id = data.searchAddresses.items[0].id
          if (this.routeAddressId !== id) {
            this.$emit('addressSelected', data.searchAddresses.items[0])
          }
        }
        return data.searchAddresses
      },
      variables () {
        return {
          query: Object.assign({
            ...this.apolloSearchQuery,
            size: this.options.itemsPerPage === -1 ? this.searchAddresses.totalItems : this.options.itemsPerPage,
            page: this.options.page - 1
          })
        }
      }
    }
  },
  created () {
    this.$emit('onLoad', this.$apollo.loading)
  },
  computed: {
    addressHeaders () {
      return [
        {
          text: this.$t('common.id'),
          align: 'left',
          value: 'id',
          sortable: false
        },
        {
          text: this.$t('dashboard.abacus') + '/' + this.$t('dashboard.rentingForm'),
          align: 'left',
          value: 'icon',
          sortable: false
        },
        {
          text: this.$t('dashboard.form.name'),
          align: 'left',
          value: 'name',
          sortable: false
        },
        {
          text: this.$t('common.address'),
          align: 'left',
          value: 'address',
          sortable: false
        },
        {
          text: this.$t('dashboard.form.email'),
          align: 'left',
          value: 'email',
          sortable: false
        },
        {
          text: this.$t('dashboard.form.phone'),
          align: 'left',
          value: 'phone',
          sortable: false
        }
      ]
    },
    addressItemsPerPageOptions () {
      return [
        5,
        10,
        15,
        {
          'text': this.$t('common.all'),
          'value': -1
        }
      ]
    },
    addressItemsPerPageText () {
      return this.$t('common.itemsPerPageText')
    },
    routeAddressId () {
      return this.$route.params.addressId
    },
    abacusAddressItems () {
      return this.searchAddresses?.items
    },
    totalItems () {
      return this.searchAddresses?.totalItems
    },
    onLoad () {
      return this.$apollo.loading
    }
  },
  data () {
    return {
      tableClassName: 'dashboardPerson',
      options: {
        itemsPerPage: 5,
        page: 1
      }
    }
  },
  watch: {
    'routeAddressId': 'highlightAddressIdRow',
    'onLoad': 'loading'
  },
  updated () {
    removeTableSelections(this.tableClassName)
    this.highlightAddressIdRow()
  },
  methods: {
    rowClass (item) {
      return 'row-' + item.id + ' ' + this.getRowColor(item) + ' lighten-5'
    },
    highlightAddressIdRow () {
      if (this.routeAddressId && this.routeAddressType && !this.$apollo.loading) {
        setSelectedItemHighlightClass(this.routeAddressId, this.tableClassName)
      }
    },
    showAddressDetails (item, row) {
      if (item.processInstanceId) {
        this.rentingFormSelected(item.processInstanceId)
      } else if ((!row.isSelected && item.id !== this.routeAddressId) || !this.routeAddressId) {
        localStorage.removeItem('dashboardObjectId')
        removeTableSelections(this.tableClassName)
        row.select(true)
        this.$emit('addressSelected', item)
      }
    },
    rentingFormSelected (processInstanceId) {
      window.open('/ticketing/processes/' + processInstanceId)
    },
    loading (value) {
      this.$emit('onLoad', value)
    },
    getRowColor (item) {
      if (item.isTenant) {
        return getDashboardColor(DASHBOARD_CONSTANTS.TENANT)
      } else if (item.isClient) {
        return getDashboardColor(DASHBOARD_CONSTANTS.CLIENT)
      } else if (item.isCaretaker) {
        return getDashboardColor(DASHBOARD_CONSTANTS.CARETAKER)
      } else if (item.isCraftsman) {
        return getDashboardColor(DASHBOARD_CONSTANTS.CRAFTSMAN)
      } else if (item.processInstanceId) {
        return getDashboardColor(DASHBOARD_CONSTANTS.RENTING_FORM)
      }
      return getDashboardColor(this.addressType)
    }
  }
}
</script>

<style scoped lang="postcss">
/deep/ .v-data-table__selected {
  pointer-events: none;
  cursor: auto;
}
/deep/ .abacus-logo {
  width: 60px;
  content: url('../../assets/icons/abacus-logo.svg');
}
</style>
