var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"py-4"},[_c('v-card-title',{staticClass:"py-0",attrs:{"primary-title":""}},[_c('h3',[_vm._v(_vm._s(_vm.$t('constructionProjects.title'))+" ("+_vm._s(_vm.constructionProjects ? _vm.constructionProjects.length : 0)+")")])]),(_vm.constructionProjects && _vm.constructionProjects.length > 0)?[_c('v-card-text',[_c('v-text-field',{attrs:{"append-icon":"search","label":_vm.$t('common.buttons.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"sort-by":"sortText","headers":_vm.headers,"items":_vm.constructionProjects,"loading":false,"item-class":_vm.rowClass,"search":_vm.search,"searchable":"","footer-props":{ itemsPerPageOptions: _vm.propertyItemsPerPageOptions, itemsPerPageText: _vm.propertyItemsPerPageText }},on:{"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('td',_vm._l((_vm.filterStatusList(item.status)),function(itemStatus,index){return _c('v-list-item-avatar',{key:index,staticClass:"pa-0 ma-0"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.getIconByStatus(itemStatus))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('constructionProjects.status.' + itemStatus)))])])],1)}),1)]}},{key:"item.sortText",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.addressLine1)+", "+_vm._s(item.place))])],1)]}},{key:"item.buildingProjectId",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.showConstructionProjectInfoWindow(item, item.buildingProjectId)}}},[_c('v-icon',[_vm._v("near_me")])],1)]}}],null,true)})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }