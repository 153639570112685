var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.children.length > 0)?_c('v-card',{staticClass:"mb-0 pa-0"},[_c('v-card-text',{staticClass:"pa-4"},[_c('v-icon',{staticClass:"float-right",attrs:{"color":"primary"},on:{"click":_vm.toggle}},[_vm._v(_vm._s(_vm.icon))]),_c('v-card-subtitle',{staticClass:"pa-0 font-weight-bold subtitle-1"},[_vm._v(" "+_vm._s(_vm.$t('ticketingSystem.taskDetails.processReferences.children'))+" ")])],1),_c('v-divider'),(_vm.isOpen && _vm.children.length > 0)?_c('v-layout',{staticClass:"pa-0 ma-0",attrs:{"row":"","wrap":""}},_vm._l((_vm.children),function(child,index){return _c('v-flex',{key:index,staticClass:"mt-1",attrs:{"xs12":"","sm12":"","md12":"","lg12":""}},[_c('v-card-text',[(child.processDefinitionName)?_c('p',{staticClass:"pa-0 ma-0 flex truncate-line two-line-clamp"},[_c('StateIcon',{attrs:{"state":child.state}}),_vm._v(" ["),_c('router-link',{staticClass:"process-instance-id truncate-line one-line-clamp",attrs:{"to":{ name: _vm.TICKETING_PROCESS_DETAILS, params: { processInstanceId: child.processInstanceId }}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"ma-0 process-instance-id truncate-line one-line-clamp"},'span',attrs,false),on),[_vm._v(_vm._s(child.processInstanceId))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('ticketingSystem.processes.processDetails')))])])],1),_vm._v("] "),_c('span',[_vm._v(" "+_vm._s(child.processDefinitionName)+" "),(child.processSubject)?_c('span',[_vm._v(" - "+_vm._s(child.processSubject))]):_vm._e()])],1):_vm._e(),(child.startedOn)?_c('p',{staticClass:"pt-1 ma-0"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"size":22}},'v-icon',attrs,false),on),[_vm._v("mdi-calendar-start")]),_c('span',[_vm._v(_vm._s(_vm.formatDateAndTimeFunc(child.startedOn)))])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('ticketingSystem.taskDetails.processReferences.startedOn'))+" ")])])],1):_vm._e(),(child.finishedOn)?_c('p',{staticClass:"pt-1 ma-0"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"size":22}},'v-icon',attrs,false),on),[_vm._v("mdi-calendar-end")]),_c('span',[_vm._v(_vm._s(_vm.formatDateAndTimeFunc(child.finishedOn)))])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('ticketingSystem.taskDetails.processReferences.finishedOn'))+" ")])])],1):_vm._e()]),_c('v-divider')],1)}),1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }