<template>
  <v-card class="mb-2 pa-0">
    <v-card-text class="pa-2">
      <v-icon color="primary" @click="toggle" class="float-right">{{ icon }}</v-icon>
      <v-card-subtitle class="pa-0 font-weight-bold subtitle-1">
        {{ $t('ticketingSystem.processes.termination.title') }}
      </v-card-subtitle>
    </v-card-text>
    <v-divider></v-divider>
    <v-layout v-if="isOpen" row wrap class="pa-0 ma-0">
      <v-flex xs12 sm12 md12 lg12 v-if="!loading">
        <v-card-text v-if="hasProcessVariables">
          <p v-if="rentalContractId" class="pa-0 ma-0">
            <v-card-subtitle class="pa-0 ma-0 font-weight-bold">
              {{ $t('ticketingSystem.processes.termination.statements.rentalContractID') }}
            </v-card-subtitle>
            {{ rentalContractId }}
          </p>
          <div v-if="rentalContractPartners" class="pa-0 my-2">
            <v-card-subtitle class="pa-0 ma-0 font-weight-bold">
              {{ $t('ticketingSystem.processes.termination.statements.contractPartners') }}
            </v-card-subtitle>
            <p v-for="(partner, index) in rentalContractPartners" :key="index" class="pa-0 ma-0">{{ fullName(partner) }}</p>
          </div>
          <p v-if="terminationReceivedOn" class="pa-0 ma-0">
            <v-card-subtitle class="pa-0 ma-0 font-weight-bold">
              {{ $t('ticketingSystem.processes.termination.terminationReceivedOn') }}
            </v-card-subtitle>
            {{ formatDateFunc(terminationReceivedOn) }}
          </p>
          <p v-if="terminationby" class="pa-0 ma-0">
            <v-card-subtitle class="pa-0 ma-0 font-weight-bold">
              {{ $t('ticketingSystem.processes.termination.terminationBy') }}
            </v-card-subtitle>
            {{ formatDateFunc(terminationby) }}
          </p>
          <div v-if="rentalCaseToken" class="pa-0 ma-0 d-inline-block">
            <a @click="openPublicRentalForm(rentalCaseToken)">{{ href }}{{ rentalCaseToken }}</a><br />
            {{ rentalCaseToken }}<br />
            <QRCode :content="href + rentalCaseToken" />
            <ProcessRelettingPdf
              :propertyInfo="propertyInfo"
              :objectFloorInfo="objectFloorInfo"
              :token="rentalCaseToken"
            />
          </div>
          <v-expansion-panels v-model="panel">
            <RentalComponentsTable :rental-components="rentalContractComponents" />
            <ServiceChargeStatements :service-charge-statements="serviceChargeStatements" />
            <ProcessSpecialities :propertyId="propertyId" />
          </v-expansion-panels>
        </v-card-text>
        <v-card-text v-else class="no-info">
          {{ $t('ticketingSystem.processes.termination.noInfo') }}
        </v-card-text>
        <v-divider></v-divider>
      </v-flex>
    </v-layout>
  </v-card>
</template>
<script>
import { mapActions } from 'vuex'
import { formatDate } from '@/helpers/formatterHelper'
import * as ticketingHelper from '@/features/ticketing/helpers/ticketingHelper'
import QRCode from '@/components/QRCode'
import RentalComponentsTable from './process-termination/RentalComponentsTable.vue'
import ProcessRelettingPdf from './process-reletting/ProcessRelettingPdf.vue'
import ProcessSpecialities from './common/ProcessSpecialities'
import ServiceChargeStatements from './process-termination/ServiceChargeStatements'

export default {
  name: 'ProcessTermination',
  props: {
    title: {
      type: String,
      default: ''
    },
    processVariables: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      panel: [],
      isOpen: true,
      loading: false,
      propertyInfo: '',
      objectFloorInfo: ''
    }
  },
  components: {
    ServiceChargeStatements,
    ProcessSpecialities,
    QRCode,
    RentalComponentsTable,
    ProcessRelettingPdf
  },
  computed: {
    icon () {
      return this.isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
    },
    hasProcessVariables () {
      return this.processVariables && this.processVariables.length > 0
    },
    href () {
      return window.location.protocol + '//' + window.location.host + '/renting/form/'
    },
    itemsPerPageOptions () {
      return [
        5,
        10,
        15,
        {
          'text': this.$t('common.all'),
          'value': -1
        }
      ]
    },
    itemsPerPageText () {
      return this.$t('common.itemsPerPageText')
    },
    rentalCaseToken () {
      return this.getVariableValue('System_RentalCaseToken_String')
    },
    propertyId () {
      return this.getVariableValue('System_Termination_Property')
    },
    rentalContractId () {
      return this.getVariableValue('System_RentalContractId_Long')
    },
    rentalContractPartners () {
      return this.getVariableValue('System_RentalContractPartner_JSON')
    },
    serviceChargeStatements () {
      return this.getVariableValue('System_RentalContractServiceChargeStatements_JSON')
    },
    rentalContractComponents () {
      return this.getVariableValue('System_RentalContractComponents_JSON')
    },
    terminationReceivedOn () {
      return this.getVariableValue('System_TerminationReceivedOn_Date')
    },
    terminationby () {
      return this.getVariableValue('System_TerminationBy_Date')
    },
    displayRentalFormInformation () {
      return this.rentalCaseToken
    }
  },
  watch: {
    'processVariables': {
      handler: 'loadData',
      immediate: true
    }
  },
  methods: {
    ...mapActions('notifier', ['setSuccessSnackbar', 'setErrorSnackbar']),
    formatDateFunc: formatDate,
    toggle () {
      this.isOpen = !this.isOpen
    },
    loadData () {
      this.processVariables.forEach(item => {
        if (item.variableName === 'System_Termination_Property') {
          this.getPropertyInfo(item.variableValue)
        }
      })
    },
    getPropertyInfo (propertyId) {
      let that = this
      let objectVariable = this.processVariables.find(x => x.variableName === 'System_Termination_PropertyObject')
      this.$abacusProperty.getPropertyById(propertyId).then(response => {
        if (response.data) {
          let data = response.data
          that.propertyInfo = data.street + ', ' + data.zip + ' ' + data.place
          if (objectVariable.variableValue) {
            let objectData = data.objects.find(x => x.objectId === parseInt(objectVariable.variableValue))
            if (objectData) {
              that.objectFloorInfo = objectData.floorText + (objectData.objectText ? ' - ' + objectData.objectText : '')
            }
            that.loading = false
          } else {
            that.loading = false
          }
        }
      }).catch(error => {
        that.loading = false
        that.setErrorSnackbar(error)
      })
    },
    openPublicRentalForm (token) {
      window.open('/renting/form/' + token)
    },
    fullName (item) {
      return (item.firstName || item.lastName) ? (item.firstName.trim() + ' ' + item.lastName.trim()).trim() : ''
    },
    getVariableValue (variableName, defaultValue = null) {
      return ticketingHelper.getVariableValue(this.processVariables, variableName, defaultValue)
    }
  }
}
</script>
<style scoped lang="postcss">
.no-info {
  color: rgba(0,0,0,.6)
}
.specialities {
  white-space: pre-line;
}
/deep/ tr:nth-child(odd) {
  background: #f8f8f8;
}
</style>
