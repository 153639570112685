<template>
  <v-dialog :value="true" scrollable max-width="1650px" @keydown.esc="closeDialog" persistent>
    <v-card>
      <v-card-title class="pl-3">
        {{ $t('navigation.ticketingNew') }}
        <v-btn icon right absolute>
          <v-icon class="clear" color="primary" :size="28" @click.native="closeDialog"/>
        </v-btn>
      </v-card-title>
      <v-layout class="overflow-auto" id="ticketDialog">
        <v-container fluid class="pt-0">
          <v-row>
            <v-col cols="12" lg="6">
              <v-container fluid class="pl-0 pr-0">
                <TicketProcessDefinitions
                  ref="processDefinitions"
                  :default-subject="defaultSubject"
                  :process-definition="processDefinition"
                  :process-definition-key="processDefinitionKey"
                  :validate-clicked="validateClicked"
                  :process-references-links="processReferencesLinks"
                  @loading="onLoading"
                  @updateForm="onUpdateForm"
                  @updateProcessDefinition="onUpdateProcessDefinition"
                  @autocompleteLinksUpdated="onAutocompleteLinksUpdated"
                />
                <SharedTaskForm
                  ref="taskForm"
                  :form="form"
                  :display-form="displayForm"
                  :dialog="true"
                  :validate-clicked="validateClicked"
                  :process-variables="variables"
                  :additional-links="processReferencesLinks"
                  :process-instance-id="processInstanceId"
                  @updateFormValues="onUpdateForm"
                  @updateTicketStartForm="onUpdateTicketStartForm"
                  @autocompleteLinksUpdated="onAutocompleteLinksUpdated"
                  @showDatePicker="showDatePicker"
                  @loadProcessVariables="onLoadProcessVariables"
                />
              </v-container>
            </v-col>
            <v-col cols="12" sm="6" lg="3">
              <SuggestionsLinks :links="processReferencesLinks" @addLinkFromSuggestion="addLinkFromSuggestion" />
            </v-col>
            <v-col cols="12" sm="6" lg="3">
              <v-card-title class="pa-2">
                {{ $t('ticketingSystem.links') }}
              </v-card-title>
              <ProcessContext ref="processContext" :process-references-links="processReferencesLinks" @deleteReferenceLink="onDeleteReferenceLink" />
            </v-col>
          </v-row>
        </v-container>
      </v-layout>
      <v-card-actions class="flex-column-reverse flex-sm-row">
        <v-spacer></v-spacer>
        <v-btn @click.native="closeDialog" class="mt-2">{{ $t('common.buttons.cancel') }}</v-btn>
        <v-btn color="success" @click="startProcess(false)" :disabled="loading" class="mt-2">
          <span>{{ $t('ticketingSystem.messages.startProcess') }}</span>
          <v-icon class="ml-2">send</v-icon>
        </v-btn>
        <v-btn color="success" @click="startProcess(true)" :disabled="loading" class="mt-2 cy-create-ticket-btn-create-and-open">
          <span>{{ $t('ticketingSystem.messages.startAndOpenProcess') }}</span>
          <v-icon class="ml-2">send</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
    <SpinnerOverlay :loading="loading" />
  </v-dialog>
</template>

<script>
import Vue from 'vue'
import TicketProcessDefinitions from './TicketProcessDefinitions'
import SharedTaskForm from '../shared/SharedTaskForm'
import SuggestionsLinks from '../shared/process-common-context/SuggestionsLinks'
import ProcessContext from '../shared/process-common-context/ProcessContext'
import SpinnerOverlay from '@/components/SpinnerOverlay'

import _cloneDeep from 'lodash.clonedeep'
import _isEqual from 'lodash.isequal'

import { TICKETING_PROCESS_DETAILS } from '@/config/options/routes/ticketingRoutes'
import { appendLinks, deleteLink } from './../helpers/autocompleteHelper'
import { mapActions } from 'vuex'

export default {
  name: 'TicketDialog',
  props: {
    processInstance: {
      type: Boolean,
      default: false
    },
    newLinks: {
      type: Array,
      default: () => []
    },
    startRelatedProcess: {
      type: Boolean,
      default: false
    },
    processInstanceId: {
      type: String,
      default: ''
    },
    defaultSubject: {
      type: String,
      default: ''
    },
    processDefinitionKey: {
      type: String,
      default: null
    },
    variables: {
      type: Array,
      default: () => []
    }
  },
  components: {
    TicketProcessDefinitions,
    SharedTaskForm,
    SuggestionsLinks,
    ProcessContext,
    SpinnerOverlay
  },
  data () {
    return {
      autoCompleteLinks: [],
      processDefinition: {},
      processVariables: {},
      form: {},
      loading: false,
      linksFromSuggestion: [],
      validateClicked: false
    }
  },
  computed: {
    displayForm () {
      if (this.form && this.form.formType === 'DYNAMIC') {
        return this.hasFormFields
      }
      return this.form && this.form.formType === 'STATIC'
    },
    hasFormFields () {
      return this.form && this.form.formFields && this.form.formFields.length > 0
    },
    processReferencesLinks () {
      // base is initial list of links
      let processReferencesLinks = _cloneDeep(this.newLinks)

      // add additional links from auto completes
      if (this.autoCompleteLinks && this.autoCompleteLinks.length > 0) {
        this.autoCompleteLinks.forEach(autoCompleteLinkList => {
          if (autoCompleteLinkList.links.length > 0) {
            autoCompleteLinkList.links.forEach(autoCompleteLinks => {
              appendLinks(processReferencesLinks, autoCompleteLinks)
            })
          }
        })
      }

      // add suggestion links
      if (this.linksFromSuggestion && this.linksFromSuggestion.length > 0) {
        this.linksFromSuggestion.forEach(suggestionLink => {
          appendLinks(processReferencesLinks, suggestionLink)
        })
      }
      return processReferencesLinks
    },
    ticketProcess () {
      return this.processDefinition.processDefinitionKey === 'Z1_01'
    }
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar', 'setSuccessSnackbar']),
    startProcess (createOpenTicket) {
      this.validateClicked = true
      if (this.validate()) {
        let message = this.$refs.processDefinitions.$refs.descriptionAutocomplete.getHtml()
        let startProcessDTO = { newLinks: this.processReferencesLinks, message: message }
        startProcessDTO.processDefinitionKey = this.processDefinition.processDefinitionKey
        startProcessDTO.processSubject = this.processDefinition.processSubject
        startProcessDTO.variables = []
        if (this.$refs.taskForm.$refs.formFields.setTicketStartProcessDTOVariables) {
          startProcessDTO.variables = this.$refs.taskForm.$refs.formFields.setTicketStartProcessDTOVariables()
        }
        if (this.startRelatedProcess) {
          let relatedProcessVariable = this.setStartRelatedProcessVariable()
          startProcessDTO.variables.push(relatedProcessVariable)
        }
        if (this.displayForm && this.$refs.taskForm && this.hasFormFields && !this.ticketProcess) {
          let data = this.$refs.taskForm.getGroupedVariables(startProcessDTO.variables)
          startProcessDTO.variables = data.variables
        }
        this.startProcessOrCreateNote(startProcessDTO, createOpenTicket)
      }
    },
    setStartRelatedProcessVariable () {
      return {
        variableName: 'System_ProcessParentReference_UUID',
        variableValue: this.processInstanceId,
        variableType: 'string'
      }
    },
    async startProcessOrCreateNote (startProcessDTO, createOpenTicket) {
      let that = this
      that.loading = true
      await this.saveThirdPartyData()
      this.$prestigeTSProcess.startProcess(startProcessDTO).then(function (response) {
        that.loading = false
        if (response.data) {
          let processInstanceId = response.data.processInstanceId
          if (processInstanceId) {
            that.setSuccessMessage()
            that.closeDialog()
            if (createOpenTicket) {
              let nextTasks = response.data.nextTaskIds
              if (nextTasks) {
                let nextTask = nextTasks[0].nextTaskId
                that.openTicket(processInstanceId, nextTask)
              } else {
                that.openProcessDetailsView(processInstanceId)
              }
            } else {
              that.reloadTasks()
              if (that.processInstance) {
                that.$emit('processInstanceCreated', true)
              } else if (that.startRelatedProcess) {
                that.$emit('loadProcessReferences')
              }
            }
          }
        }
        return true
      }).catch(error => {
        that.loading = false
        that.setErrorSnackbar(error)
      })
    },
    async saveThirdPartyData () {
      if (this.$refs.taskForm && this.$refs.taskForm.saveThirdPartyData) {
        await this.$refs.taskForm.saveThirdPartyData()
      }
    },
    reloadTasks () {
      this.$store.dispatch('ticketingSystem/loadAssignedTasks')
      this.$store.dispatch('ticketingSystem/loadTaskEntries')
      this.$store.dispatch('ticketingSystem/loadCandidateGroupTasks')
    },
    onUpdateForm (value) {
      this.form = Object.assign(_cloneDeep(this.form), value)
      this.loading = false
    },
    onUpdateTicketStartForm (variables) {
      this.processVariables = _cloneDeep(variables)
    },
    onUpdateProcessDefinition (value) {
      this.processDefinition = Object.assign(_cloneDeep(this.processDefinition), value)
    },
    onLoadProcessVariables () {
      this.$emit('loadProcessVariables')
    },
    validate () {
      if (this.$refs.taskForm) {
        let taskFormValid = this.$refs.taskForm.isValid()
        let subjectValid = true
        if (this.$refs.processDefinitions.$refs.subjectForm) {
          subjectValid = this.$refs.processDefinitions.$refs.subjectForm.validate()
        }
        return taskFormValid && subjectValid
      }
      return true
    },
    setSuccessMessage () {
      let successMessage = 'successfullyStarted'
      if (this.startRelatedProcess) {
        successMessage = 'successfullyStartedRelated'
      }
      this.setSuccessSnackbar(this.$t('ticketingSystem.messages.' + successMessage))
    },
    onAutocompleteLinksUpdated (autocompleteLinks) {
      const index = this.autoCompleteLinks.findIndex(x => x.name === autocompleteLinks.name)
      if (index !== -1) {
        const isEqual = _isEqual(autocompleteLinks, this.autoCompleteLinks[index])
        if (!isEqual) {
          Vue.set(this.autoCompleteLinks, index, autocompleteLinks)
        }
      } else if (autocompleteLinks && autocompleteLinks.links && autocompleteLinks.links.length > 0) {
        Vue.set(this.autoCompleteLinks, this.autoCompleteLinks.length, autocompleteLinks)
      }
    },
    addLinkFromSuggestion (suggestionLink) {
      const index = this.linksFromSuggestion.findIndex(x => x.name === suggestionLink.linkName)
      if (index !== -1) {
        this.linksFromSuggestion[index].linkValues.push(suggestionLink.linkValues[0])
      } else {
        this.linksFromSuggestion.push(suggestionLink)
      }
    },
    onDeleteReferenceLink (link) {
      this.deleteFromAutocompleteLinks(link)
      this.deleteFromSuggestionLinks(link)
      this.deleteFromNewLinks(link)
    },
    deleteFromAutocompleteLinks (link) {
      if (this.autoCompleteLinks.length > 0) {
        let that = this
        this.autoCompleteLinks.forEach((autocomplete, index) => {
          let updatedLinks = deleteLink(autocomplete.links, link)
          Vue.set(that.autoCompleteLinks[index], 'links', updatedLinks)
        })
      }
    },
    deleteFromSuggestionLinks (link) {
      let updatedLinks = deleteLink(this.linksFromSuggestion, link)
      Vue.set(this, 'linksFromSuggestion', updatedLinks)
    },
    deleteFromNewLinks (link) {
      let newLinks = deleteLink(this.newLinks, link)
      const isEqual = _isEqual(this.newLinks, newLinks)
      if (!isEqual) {
        this.$emit('updateNewLinks', newLinks)
      }
    },
    openTicket (processInstanceId, nextTaskId) {
      this.$router.push({ name: TICKETING_PROCESS_DETAILS, params: { processInstanceId: processInstanceId }, query: { taskId: nextTaskId } })
    },
    openProcessDetailsView (processInstanceId) {
      this.$router.push({ name: TICKETING_PROCESS_DETAILS, params: { processInstanceId: processInstanceId } })
    },
    closeDialog () {
      this.$emit('closeDialog')
    },
    onLoading (value) {
      this.loading = value
    },
    showDatePicker () {
      let el = document.getElementById('ticketDialog')
      if (el) {
        setTimeout(() => {
          el.scrollBy({
            top: el.scrollHeight,
            left: 0,
            behavior: 'smooth'
          })
        }, 500)
      }
    }
  }
}
</script>

<style scoped lang="postcss">
#ticketDialog {
  position: relative;
}
/deep/ .atwho-panel {
  margin-top: 40px;
  top: 10% !important;
}
/deep/ .editor {
  height: 150px;
}
.spinner {
  margin-left: 100px;
}
.img-icon {
  vertical-align: middle;
}
/deep/ .datepicker-input {
  padding-top: 0;
  margin-top: 0;
}
/deep/ .v-textarea .v-text-field__slot label {
  top: -3px;
}
/deep/ .v-textarea .v-text-field__slot textarea {
  min-height: 172px;
}
</style>
