import store from '../../../store'
import Vue from 'vue'
import i18n from '../../../i18n/i18n'
import { formatDateAndTime } from '@/helpers/formatterHelper'
import { isDateToday, isDateInThePast } from '@/helpers/momentHelper'

export function setTicketingListHeight () {
  let ticketingListEl = document.getElementById('ticketingList')
  if (ticketingListEl) {
    let filtersHeight = document.getElementById('filters').clientHeight + 130
    ticketingListEl.style.height = (window.innerHeight - filtersHeight) + 'px'
    ticketingListEl.style.maxHeight = (window.innerHeight - filtersHeight) + 'px'
  }
}

export function setTicketingColumnHeight () {
  let columnElements = document.getElementsByClassName('column-content')
  if (columnElements && columnElements.length > 0) {
    let containerElement = document.getElementById('ticketingList').clientHeight
    columnElements.forEach(el => {
      el.style.height = containerElement - 58 + 'px'
    })
  }
}

export async function getUserName (userId) {
  if (userId === 'bot') {
    return 'Bot'
  } else if (userId === 'rentingform') {
    return i18n.t('ticketingSystem.users.rentingForm')
  } else if (userId && userId !== 'admin' && userId !== 'assignToMe') {
    let ticketingUsers = store.getters['ticketingSystem/getTicketingUsers']
    if (ticketingUsers.length > 0) {
      let userExists = ticketingUsers.find(x => x.userId.toString() === userId)
      if (userExists) {
        let userName = '[' + userExists.userId + '] ' + userExists.name
        return userName
      } else {
        let userDetails = await store.dispatch('abacus/loadAddressDetails', userId)
        if (userDetails) {
          let userName = '[' + userDetails.id + '] ' + userDetails.firstName + ' ' + userDetails.lastName
          return userName
        }
      }
    }
  }
}

export async function mapProcesses (processes) {
  if (processes && processes.length > 0) {
    for (const [index, item] of processes.entries()) {
      item.startedOnFormatted = formatDateAndTime(item.startedOn)
      item.finishedOnFormatted = formatDateAndTime(item.finishedOn)
      item.startUser = item.startUserId ? await getUserName(item.startUserId.toString()) : ''
      Vue.set(processes, index, item)
    }
  }
}

export function getVariableValue (variables, variableName, defaultValue = null) {
  if (variables && variableName) {
    const variable = variables.find(variable => variable.variableName === variableName)
    if (variable) {
      return variable.variableValue
    }
  }
  return defaultValue
}

export function dueDateColor (date) {
  let now = isDateToday(date)
  let over = isDateInThePast(date)
  if (over) {
    return 'orange lighten-4'
  } else if (now) {
    return 'amber lighten-5'
  }
  return ''
}
