<template>
  <v-expansion-panel v-if="specialities">
    <v-expansion-panel-header class="pa-2">
      <v-card-subtitle class="pa-0 ma-0 font-weight-bold">
        {{ $t('ticketingSystem.processes.reletting.specialities') }}
      </v-card-subtitle>
      <template v-slot:actions>
        <v-icon color="brown lighten-2">mdi-information-outline</v-icon>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="pa-0">
      <p class="mb-0 specialities" v-text="specialities"></p>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ProcessSpecialities',
  props: {
    propertyId: {
      type: [Number, String],
      default: ''
    }
  },
  watch: {
    'propertyId': {
      handler: 'getPropertySpecialties',
      immediate: true
    }
  },
  data () {
    return {
      specialities: ''
    }
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    getPropertySpecialties () {
      if (this.propertyId) {
        let that = this
        this.$abacusProperty.getSpecialitiesForPropertyId(this.propertyId).then(response => {
          if (response.data) {
            that.specialities = response.data.specialities
          }
        }).catch(error => {
          that.setErrorSnackbar(error)
        })
      }
    }
  }
}
</script>
<style scoped lang="postcss">
.specialities {
  white-space: pre-line;
}
/deep/ .v-expansion-panel-content__wrap {
  padding: 8px;
}
</style>
