<template>
  <div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">
            {{ $t('ticketingSystem.processes.rentingForm.createContract.rentComponent') }}
          </th>
          <th class="text-right">
            {{ $t('ticketingSystem.processes.rentingForm.createContract.rentComponentAmount') }}
          </th>
          <th class="text-right">
            {{ $t('ticketingSystem.processes.rentingForm.createContract.rentComponentNewAmount') }}
          </th>
          <th class="text-left">
            {{ $t('ticketingSystem.processes.rentingForm.createContract.rentComponentComment') }}
          </th>
        </tr>
        </thead>
        <tbody>
        <template v-for="(component, index) in rentComponents">
          <tr v-if="component.amount || component.newAmount" :key="'rental-component-' + index">
            <th>{{component.rentalComponent}}</th>
            <td class="text-right">{{formatAmount(component.amount) }}</td>
            <td class="text-right"><strong>{{ formatAmount(component.newAmount) }}</strong></td>
            <td>{{ component.comment }}</td>
          </tr>
        </template>

        <tr>
          <th>{{ $t('ticketingSystem.processes.rentingForm.createContract.grossRent') }}</th>
          <td class="text-right"></td>
          <td class="text-right">
            <strong>{{ formatAmount(gross) }}</strong>
          </td>
          <td>
          </td>
        </tr>

        <tr>
          <th>{{ $t('ticketingSystem.processes.rentingForm.createContract.rentComponentDeposit') }}</th>
          <td class="text-right"></td>
          <td class="text-right">
            <strong>{{ formatAmount(deposit) }}</strong>
          </td>
          <td>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>

    <p v-if="earliestStart">{{ $t('ticketingSystem.processes.rentingForm.createContract.earliestStart') }}: <strong>{{ formatDate(earliestStart) }}</strong></p>
    <p v-if="moveInDate">{{ $t('ticketingSystem.processes.rentingForm.createContract.moveInDate') }}: <strong>{{ formatDate(moveInDate) }}</strong></p>

  </div>
</template>

<script>
import { formatDate, formatAmount } from '@/helpers/formatterHelper'
import _sum from 'lodash.sum'

export default {
  name: 'RentalComponents',
  props: {
    rentComponents: {
      type: Array,
      default: () => []
    },
    deposit: {
      type: Number,
      default: 0
    },
    earliestStart: {
      type: String,
      default: ''
    },
    moveInDate: {
      type: String,
      default: ''
    }
  },
  computed: {
    gross () {
      if (this.rentComponents) {
        return _sum(this.rentComponents.map(c => c.newAmount ? Number(c.newAmount) : 0))
      } else {
        return 0
      }
    }
  },
  methods: {
    formatDate: formatDate,
    formatAmount: formatAmount
  }
}
</script>

<style scoped lang="postcss">
</style>
