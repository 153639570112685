const ASSIGNEE = 'Process_Z1_01_Assignee_String'
const CANDIDATE_GROUPS = 'Process_Z1_01_CandidateGroups_String'
const CHECKLIST_ITEMS = 'Process_Z1_01_ChecklistItems_JSON'
const COMPLETE_IMMEDIATELY = 'Process_Z1_01_CompleteImmediately_Boolean'
const DUE_DATE = 'Process_Z1_01_DueDate_Date'
const PRIORITY = 'Process_Z1_01_Priority_Long'

export const ticketingZ101Constants = {
  ASSIGNEE,
  CANDIDATE_GROUPS,
  CHECKLIST_ITEMS,
  COMPLETE_IMMEDIATELY,
  DUE_DATE,
  PRIORITY
}
