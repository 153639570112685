<template>
  <v-card class="open-tasks-content-card height">
    <v-container fluid grid-list-xs>
      <v-card-text class="pa-0">
        <v-card-subtitle class="pa-0 font-weight-bold subtitle-1">
          {{ $t('ticketingSystem.processes.openTasks') }}
        </v-card-subtitle>
      </v-card-text>
      <v-main class="pa-0 ma-0">
        <v-data-table
          v-if="openTasks && openTasks.length > 0"
          sort-by="dueDate"
          class="elevation-1 mt-2 cy-ticket-open-tasks"
          :item-class="rowColor"
          :headers="headers"
          :items="filteredOpenTasks"
          :items-per-page="5"
          :footer-props="{ itemsPerPageOptions: itemsPerPageOptions, itemsPerPageText: itemsPerPageText }"
          @click:row="goToTaskDetails"
        >
          <template v-slot:[`item.priority`]="{ item }">
            <TicketPriority v-model="item.priority" read-only />
          </template>
          <template v-slot:[`item.startedOn`]="{ item }">
            {{ formatDateAndTimeFunc(item.startedOn) }}
          </template>
          <template v-slot:[`item.dueDate`]="{ item }">
            {{ formatDateAndTimeFunc(item.dueDate) }}
          </template>
          <template v-slot:[`item.assignee`]="{ item }">
            <UserName :user="item.assignee" />
          </template>
        </v-data-table>
        <v-card-text v-else class="mt-2 pa-0">
          {{ $t('table.noData') }}
        </v-card-text>
      </v-main>
    </v-container>
  </v-card>
</template>

<script>
import { formatDateAndTime, formatDate } from '@/helpers/formatterHelper'
import { dueDateColor } from './../helpers/ticketingHelper'
import TicketPriority from '@/features/ticketing/components/TicketPriority'
import UserName from '@/components/UserName'
import { mapState } from 'vuex'

export default {
  name: 'ProcessOpenTasks',
  components: {
    UserName,
    TicketPriority
  },
  props: {
    openTasks: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState('ticketingSystem', ['ticketingUsers']),
    headers () {
      return [
        {
          text: this.$t('ticketingSystem.priority.title'),
          align: 'left',
          value: 'priority'
        },
        {
          text: this.$t('ticketingSystem.task'),
          align: 'left',
          value: 'name'
        },
        {
          text: this.$t('ticketingSystem.entry.created'),
          align: 'left',
          value: 'startedOn'
        },
        {
          text: this.$t('ticketingSystem.entry.due'),
          align: 'left',
          value: 'dueDate'
        },
        {
          text: this.$t('ticketingSystem.entry.assignee'),
          align: 'left',
          value: 'assignee'
        }
      ]
    },
    filteredOpenTasks () {
      return this.openTasks.filter(t => t.parentTaskId == null)
    },
    itemsPerPageOptions () {
      return [
        5,
        10,
        15
      ]
    },
    itemsPerPageText () {
      return this.$t('common.itemsPerPageText')
    }
  },
  methods: {
    formatDateAndTimeFunc: formatDateAndTime,
    formatDate: formatDate,
    goToTaskDetails (item) {
      if (item.taskId) {
        let taskFinished = false
        this.$emit('openTaskDetailsDialog', item.taskId, taskFinished)
      }
    },
    rowColor (item) {
      return dueDateColor(item.dueDate)
    }
  }
}
</script>

<style scoped lang="postcss">
/deep/ tr {
  cursor: pointer;
}
@media (min-width: 960px) {
  .height {
    margin-right: 8px;
  }
}
.open-tasks-content-card {
  position: relative;
  overflow-y: auto;
}
</style>
