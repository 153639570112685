<template>
  <div>
    <v-card-title class="pa-2">
      {{ $t('ticketingSystem.suggestions') }}
    </v-card-title>
    <template v-if="hasSuggestions">
      <SuggestionsProperty v-if="suggestedProperties.length > 0"
                          :suggestions="suggestedProperties"
                          :link-values="propertyLinkValues"
                          @addLinkFromSuggestion="addLinkFromSuggestion"
      />
      <SuggestionsObject v-if="suggestedObjects.length > 0"
                          :suggestions="suggestedObjects"
                          :link-values="objectLinkValues"
                          :property-link-values="propertyLinkValues"
                          @addLinkFromSuggestion="addLinkFromSuggestion"
      />
      <SuggestionsAddress v-if="suggestedCaretakers.length > 0"
                          :title="$t('common.caretakers')"
                          :suggestions="suggestedCaretakers"
                          name="caretaker"
                          @addLinkFromSuggestion="addLinkFromSuggestion"
      />
      <SuggestionsAddress v-if="suggestedClients.length > 0"
                          :title="$t('common.clients')"
                          :suggestions="suggestedClients"
                          name="client"
                          @addLinkFromSuggestion="addLinkFromSuggestion"
      />
    </template>
    <v-card v-else class="mb-2 pa-0">
      <v-card-text class="pa-4">
        {{ $t('ticketingSystem.messages.noSuggestions') }}
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import SuggestionsProperty from './SuggestionsProperty'
import SuggestionsObject from './SuggestionsObject'
import SuggestionsAddress from './SuggestionsAddress'
import { mapActions, mapState } from 'vuex'
import _cloneDeep from 'lodash.clonedeep'

const CARETAKER = 'caretaker'
const CLIENT = 'client'
const PROPERTY = 'property'
const PROPERTY_OBJECT = 'propertyObject'
const TENANT = 'tenant'

export default {
  name: 'SuggestionsLinks',
  components: {
    SuggestionsProperty,
    SuggestionsObject,
    SuggestionsAddress
  },
  props: {
    links: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState('abacus', ['addressesDetailsExtended', 'propertiesDetails']),
    properties () {
      let propertiesList = []
      propertiesList.push(...this.propertyLinkValues)

      // TODO
      // for each client, get address-detail and add properties defined in clientObjects
      // for each caretaker, get address-detail and add properties defined in caretakerProperties
      // for each craftsman, get address-detail and add properties defined in craftsmanPriorityProperties

      propertiesList.forEach(async propertyId => {
        await this.loadPropertyDetails(propertyId)
      })

      return propertiesList
    },
    filteredProperties () {
      let that = this
      return this.propertiesDetails ? this.propertiesDetails.filter((x) => that.properties.includes(String(x.propertyId))) : []
    },
    suggestions () {
      let suggestions = []
      let addressDetails = _cloneDeep(this.addressesDetailsExtended)
      let addressDetailsFromTenantLinks = addressDetails.filter(address => this.tenantLinkValues.includes(address.id.toString()))
      if (this.tenantLinkValues?.length > 0 && addressDetailsFromTenantLinks?.length > 0) {
        this.tenantLinkValues
          .map(tenantId => addressDetailsFromTenantLinks.find(x => x.id.toString() === tenantId.toString()))
          .filter(address => address?.tenant && address?.tenantObjects?.length > 0)
          .forEach(address => {
            suggestions.push({name: PROPERTY, title: 'relatedProperties', value: address.tenantObjects})
            address.tenantObjects.forEach(item => {
              if (item.objects) {
                const propertyObjectSuggestionsIndex = suggestions.findIndex(x => x.name === PROPERTY_OBJECT)
                item.objects.map(x => {
                  x.propertyId = item.propertyId
                  x.houseStreet = item.street
                  return x
                })
                if (propertyObjectSuggestionsIndex !== -1) {
                  suggestions[propertyObjectSuggestionsIndex].value.push(...item.objects)
                  suggestions[propertyObjectSuggestionsIndex].value =
                    suggestions[propertyObjectSuggestionsIndex].value.reduce((acc, current) => {
                      const x = acc.find(item => item.propertyId === current.propertyId && item.objectId === current.objectId)
                      if (!x) {
                        return acc.concat([current])
                      }
                      return acc
                    }, [])
                } else {
                  suggestions.push({ name: PROPERTY_OBJECT, title: 'relatedPropertiesObjects', value: item.objects })
                }
              }
            })
          })
      }
      return suggestions
    },
    propertyLinkValues () {
      let link = this.links.find(x => x.linkName === PROPERTY)
      return link ? link.linkValues : []
    },
    objectLinkValues () {
      let link = this.links.find(x => x.linkName === PROPERTY_OBJECT)
      return link ? link.linkValues : []
    },
    caretakerLinkValues () {
      let link = this.links.find(x => x.linkName === CARETAKER)
      return link ? link.linkValues : []
    },
    clientLinkValues () {
      let link = this.links.find(x => x.linkName === CLIENT)
      return link ? link.linkValues : []
    },
    tenantLinkValues () {
      let link = this.links.find(x => x.linkName === TENANT)
      const tenantLinkValues = link ? link.linkValues : []
      tenantLinkValues.forEach(link => this.loadAddressDetailsExtended(link))
      return tenantLinkValues
    },
    suggestedCaretakers () {
      return this.filteredProperties
        .filter(property => property.caretaker && !this.caretakerLinkValues.includes(property.caretaker.id.toString()))
        .map(property => property.caretaker)
        .filter((value, index, self) => index === self.findIndex((t) => (t.id === value.id)))
    },
    suggestedClients () {
      return this.filteredProperties
        .filter(property => property.client && !this.clientLinkValues.includes(property.client.id.toString()))
        .map(property => property.client)
        .filter((value, index, self) => index === self.findIndex((t) => (t.id === value.id)))
    },
    suggestedProperties () {
      let that = this
      return this.suggestions.filter(suggestion => suggestion.name === PROPERTY).map(item => {
        if (item.value.length > 0) {
          item.value = item.value.filter(x => !that.propertyLinkValues.includes(x.propertyId.toString()))
        }
        return item
      }).filter(y => y.value.length > 0)
    },
    suggestedObjects () {
      let that = this
      return this.suggestions.filter(suggestion => suggestion.name === PROPERTY_OBJECT).map(item => {
        if (item.value.length > 0) {
          item.value = item.value.filter(x => !that.objectLinkValues.includes(`${x.propertyId.toString()}:${x.objectId.toString()}`))
        }
        return item
      }).filter(y => y.value.length > 0)
    },
    hasSuggestions () {
      return this.suggestedCaretakers?.length > 0 || this.suggestedClients?.length > 0 ||
        this.suggestedProperties?.length > 0 || this.suggestedObjects?.length > 0
    }
  },
  methods: {
    ...mapActions('abacus', ['loadPropertyDetails', 'loadAddressDetailsExtended']),
    addLinkFromSuggestion (link) {
      this.$emit('addLinkFromSuggestion', link)
    }
  }
}
</script>
<style scoped lang="postcss">
</style>
