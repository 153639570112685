var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"open-tasks-content-card height"},[_c('v-container',{attrs:{"fluid":"","grid-list-xs":""}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-card-subtitle',{staticClass:"pa-0 font-weight-bold subtitle-1"},[_vm._v(" "+_vm._s(_vm.$t('ticketingSystem.processes.openTasks'))+" ")])],1),_c('v-main',{staticClass:"pa-0 ma-0"},[(_vm.openTasks && _vm.openTasks.length > 0)?_c('v-data-table',{staticClass:"elevation-1 mt-2 cy-ticket-open-tasks",attrs:{"sort-by":"dueDate","item-class":_vm.rowColor,"headers":_vm.headers,"items":_vm.filteredOpenTasks,"items-per-page":5,"footer-props":{ itemsPerPageOptions: _vm.itemsPerPageOptions, itemsPerPageText: _vm.itemsPerPageText }},on:{"click:row":_vm.goToTaskDetails},scopedSlots:_vm._u([{key:"item.priority",fn:function(ref){
var item = ref.item;
return [_c('TicketPriority',{attrs:{"read-only":""},model:{value:(item.priority),callback:function ($$v) {_vm.$set(item, "priority", $$v)},expression:"item.priority"}})]}},{key:"item.startedOn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateAndTimeFunc(item.startedOn))+" ")]}},{key:"item.dueDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateAndTimeFunc(item.dueDate))+" ")]}},{key:"item.assignee",fn:function(ref){
var item = ref.item;
return [_c('UserName',{attrs:{"user":item.assignee}})]}}],null,true)}):_c('v-card-text',{staticClass:"mt-2 pa-0"},[_vm._v(" "+_vm._s(_vm.$t('table.noData'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }