<template>
  <v-list-item class="pa-0 ma-0">
    <EditTaskDialog
      v-if="shouldShowChildDialog"
      :checkList="true"
      :item="item"
      @closeDialog="shouldShowChildDialog = false"
      @loadTaskDetails="onLoadTaskDetails"
    />
    <v-list-item-content>
      <v-list-item-title class="multi-line">{{ item.name }}</v-list-item-title>
      <v-list-item-subtitle class="multi-line">{{ item.description }}</v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action v-if="!checkListItemFinished">
      <v-icon size="18" color="blue" @click="shouldShowChildDialog = true">edit</v-icon>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import EditTaskDialog from './EditTaskDialog'
export default {
  name: 'CheckListItemInfo',
  components: {
    EditTaskDialog
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      shouldShowChildDialog: false
    }
  },
  computed: {
    checkListItemFinished () {
      return this.item && this.item.finishedOn !== '' && this.item.finishedOn !== null
    }
  },
  methods: {
    onLoadTaskDetails () {
      this.$emit('loadTaskDetails')
    }
  }
}
</script>

<style scoped lang="postcss">
.multi-line {
  max-width: 300px;
  white-space: pre-line;
}
</style>
