<template>
  <v-card class="mb-2">
    <v-card-text v-if="propertyId">
      <v-icon color="primary" @click="toggle" class="float-right">{{ icon }}</v-icon>
      <v-card-title class="pa-0">{{ $t('navigation.tasksHome') }}</v-card-title>
    </v-card-text>
    <v-divider></v-divider>
    <div v-if="isOpen" class="pa-2 ml-2 mb-2">
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        sort-by="id"
        sort-desc
        :options.sync="options"
        :footer-props="{itemsPerPageOptions:itemsPerPageOptions, itemsPerPageText: itemsPerPageText}"
        @click:row="openTaskDetails"
        class="elevation-1 mr-2 mt-2 mb-2"
      >
        <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
        <template v-slot:[`item.taskType`]="{ item }">
          {{ $t('tasks.types.' + item.taskType) }}
        </template>
        <template v-slot:[`item.jobDescription`]="{ item }">
          <p class="mb-0 truncate-two-lines">{{ item.jobDescription }}</p>
        </template>
        <template v-slot:[`item.createdOn`]="{ item }">
          {{ item.createdOnFormatted }}
        </template>
        <template slot="no-data">
          <v-alert class="mt-4" :value="true" color="error" icon="warning">
            {{ $t('table.noData') }}
          </v-alert>
        </template>
      </v-data-table>
    </div>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import { formatDate } from '@/helpers/formatterHelper'
import { TASK_DETAILS } from '@/config/options/routes/taskRoutes'

export default {
  name: 'TaskList',
  props: {
    propertyId: {
      type: String,
      default: ''
    }
  },
  watch: {
    '$route': 'loadTasks'
  },
  created () {
    this.loadTasks()
  },
  data () {
    return {
      loading: true,
      isOpen: true,
      items: [],
      options: {
        page: 1
      }
    }
  },
  computed: {
    icon () {
      return this.isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
    },
    headers () {
      return [
        {
          text: this.$t('common.id'),
          align: 'left',
          value: 'id'
        },
        {
          text: this.$t('tasks.list.type'),
          align: 'left',
          value: 'taskType'
        },
        {
          text: this.$t('tasks.createTask.subject1'),
          align: 'left',
          value: 'subjectLine1',
          class: 'min-200'
        },
        {
          text: this.$t('tasks.createTask.jobDescription'),
          align: 'left',
          value: 'jobDescription'
        },
        {
          text: this.$t('tasks.tenant.title'),
          align: 'left',
          value: 'subjectLine2',
          class: 'min-200'
        },
        {
          text: this.$t('common.date.creationDate'),
          align: 'left',
          value: 'createdOn'
        }
      ]
    },
    itemsPerPageText () {
      return this.$t('common.itemsPerPageText')
    },
    itemsPerPageOptions () {
      return [
        10,
        25,
        {
          'text': this.$t('common.all'),
          'value': -1
        }
      ]
    }
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    formatDateFunc: formatDate,
    toggle () {
      this.isOpen = !this.isOpen
    },
    loadTasks () {
      let that = this
      that.loading = true
      this.$prestigeTask.searchTasks(this.propertyId).then(function (response) {
        that.options.page = 1
        if (response.data.length) {
          that.items = response.data
          that.items.forEach(item => {
            item.createdOnFormatted = that.formatDateFunc(item.createdOn)
          })
        }
        that.loading = false
        return true
      }).catch(function (error) {
        that.setErrorSnackbar(error)
        that.loading = false
      })
    },
    openTaskDetails (task) {
      if (this.$route.params && this.$route.params.taskId !== task.id) {
        this.$router.push({ name: TASK_DETAILS, params: { taskId: task.id } })
      }
    }
  }
}
</script>
<style scoped lang="postcss">
/deep/ tr:nth-child(odd) {
  background: #f8f8f8;
}
/deep/ tr {
  cursor: pointer;
}
.truncate-two-lines {
  display: block;
  display: -webkit-box;
  max-width: 250px;
  height: 39px;
  margin: 0 auto;
  line-height: 1.5;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
