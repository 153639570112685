import i18n from '../i18n/i18n'
import vm from '../main'
const ABACUS_HREF = 'https://abacus.prestige.ch:40001/abastart?mandant=10&program='

export function useAddressActions (item) {
  const addressActions = [
    { title: i18n.t('dashboard.actions.address'), icon: 'abacus-logo', action: onAddress }
  ]

  function onAddress (item) {
    let addressId = item?.id || item?.data?.id || vm.$route.params.addressId
    let url = ABACUS_HREF + 'adre.11&Address=' + addressId
    window.open(url, '_blank')
  }

  return {
    addressActions,
    onAddress
  }
}
