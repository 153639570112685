<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-card class="elevation-12">
        <v-form ref="form" class="create-form" @submit.prevent="sendFeedback">
          <v-toolbar dark :color="appBarBackgroundColor" class="mb-4">
            <v-toolbar-title>
              <v-img height="70px" width="70px" class="mt-10" src="/Logo-Prestige.png"></v-img>
            </v-toolbar-title>
            <v-toolbar-title class="pl-4 font-weight-bold">{{ $t('renting.referencesEmployer.title') }}</v-toolbar-title>
          </v-toolbar>

          <template v-if="state === 'TOKEN_VERIFY'">
            <v-card-text>
              <v-progress-linear color="blue" indeterminate></v-progress-linear>
            </v-card-text>
          </template>

          <template v-else-if="state === 'TOKEN_VERIFIED'">
            <template v-if="invalidToken">
              <v-card-text>
                <v-alert :value="true" v-html="errorMessage" type="warning"/>
              </v-card-text>
            </template>
            <v-container v-else class="px-4">

              <p class="text-sm-h6 mt-5">{{ $t('renting.referencesEmployer.referenceAbout') }}</p>
              <v-row>
                <v-col cols="12">
                  <span>{{ metadata.firstName }} {{ metadata.lastName }}, {{ metadata.street }} {{ metadata.streetNumber }}, {{ metadata.zip }} {{ metadata.place }}</span>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <span class="text--subtitle-2 font-weight-bold">{{ $t('renting.publicForm.fields.personInfo.employment.job') }}:</span> <span>{{ metadata.profession }}</span>
                </v-col>
              </v-row>

              <p class="text-sm-h6 mt-5">{{ $t('renting.respondent') }}</p>
              <v-row>
                <v-col cols="12" sm="3">
                  <v-text-field
                    :label="$t('common.data.name')"
                    v-model="form.employerName"
                    required
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field
                    :label="$t('renting.referenceFunction')"
                    v-model="form.employerFunction"
                    required
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field
                    :label="$t('common.data.telPhone')"
                    v-model="form.employerPhone"
                    required
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field
                    :label="$t('common.data.email')"
                    v-model="form.employerEmail"
                    required
                    :rules="[rules.required, rules.email]"
                  />
                </v-col>
              </v-row>

              <p class="text-sm-h6 mt-5">{{ $t('renting.referenceFeedback') }}</p>
              <v-row>
                <v-col cols="12">
                  <v-card-subtitle class="pa-0 mr-3">{{ $t('renting.referencesEmployer.employmentDate') }}: {{ formatDate(metadata.employmentDate) }}</v-card-subtitle>
                  <v-radio-group row v-model="form.employmentDateConfirmed" :rules="[rules.radioRequired]">
                    <v-radio :label="$t('common.options.yes')" :value="true"></v-radio>
                    <v-radio :label="$t('common.options.no')" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-card-subtitle class="pa-0 mr-3">{{ $t('renting.referencesEmployer.income') }} {{ formatAmount(metadata.income) }} {{ $t('renting.referencesEmployer.incomeMonthly') }}</v-card-subtitle>
                  <v-radio-group row v-model="form.employmentIncomeConfirmed" :rules="[rules.radioRequired]">
                    <v-radio :label="$t('common.options.yes')" :value="true"></v-radio>
                    <v-radio :label="$t('common.options.no')" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-card-subtitle class="pa-0 mr-3">{{ $t('renting.referencesEmployer.notTerminatedAndUnlimited') }}</v-card-subtitle>
                  <v-radio-group row v-model="form.employmentNotTerminatedAndUnlimited" :rules="[rules.radioRequired]">
                    <v-radio :label="$t('common.options.yes')" :value="true"></v-radio>
                    <v-radio :label="$t('common.options.no')" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>

              <v-textarea
                :label="$t('renting.comment')"
                v-model="form.employmentComment"
              ></v-textarea>
            </v-container>

          </template>

          <template v-else-if="state === 'REFERENCE_SENT'">
            <v-card-text v-if="referenceSendSuccess">
              <v-alert :value="true" v-html="successMessage" type="success"/>
            </v-card-text>
            <v-card-text v-else>
              <v-alert :value="true" v-html="errorMessage" type="warning" />
            </v-card-text>
          </template>

          <v-card-actions class="pl-4 pr-4">
            <a href="https://www.prestige.ch/impressum/" target="_blank">{{ $t('renting.publicForm.imprint') }}</a>
            <v-spacer></v-spacer>
            <v-btn v-if="state === 'TOKEN_VERIFIED' && !invalidToken" color="#6e2c6b" class="white--text" :loading="verificationLoading || referenceSending" @click.stop="sendFeedback">
              {{ $t('common.buttons.confirm') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import { appBarBackgroundColor } from '@/config/options/appBar'
import * as validationHelper from '@/helpers/validationHelper'
import { formatDate, formatAmount } from '@/helpers/formatterHelper'
import _cloneDeep from 'lodash.clonedeep'

export default {
  name: 'EmployeeReferenceForm',
  components: {
  },
  data () {
    return {
      invalidToken: false,
      verificationLoading: false,
      referenceSending: false,
      referenceSendSuccess: false,
      metadata: {},
      form: {},
      state: 'TOKEN_VERIFY',
      successMessage: null,
      errorMessage: null,
      rules: {
        radioRequired: (value) => (value !== undefined && value !== '' && value !== null) || this.requiredMessage,
        email: (value) => { let valid = validationHelper.validateEmail(value); return valid },
        required: (value) => (!!value && value !== undefined) || this.requiredMessage
      },
      appBarBackgroundColor: appBarBackgroundColor
    }
  },
  computed: {
    requiredMessage () {
      return this.$t('common.errors.required')
    },
    rentalFormId () {
      return this.$route.params.rentalFormId
    },
    token () {
      return this.$route.params.employerToken
    }
  },
  mounted () {
    this.verifyToken()
  },
  methods: {
    formatDate: formatDate,
    formatAmount: formatAmount,
    validate () {
      let valid = true
      if (!this.$refs.form.validate()) {
        valid = false
      }
      return valid
    },
    verifyToken () {
      let that = this
      that.verificationLoading = true
      this.$prestigeRenting.getResidentEmployerMetadata(this.rentalFormId, this.token).then(function (response) {
        that.metadata = response.data
        that.form = _cloneDeep(that.metadata)
        that.invalidToken = false
        that.verificationLoading = false
        that.state = 'TOKEN_VERIFIED'
      }).catch(error => {
        if (error?.response?.status === 400) {
          that.errorMessage = that.$t('renting.publicForm.alerts.usedToken')
        } else {
          that.errorMessage = that.$t('renting.publicForm.alerts.invalidToken')
        }
        that.verificationLoading = false
        that.invalidToken = true
        that.state = 'TOKEN_VERIFIED'
      })
    },
    sendFeedback () {
      if (this.validate()) {
        let that = this
        that.referenceSending = true
        that.$prestigeRenting.setEmployerTokenUsedAndUpdateEmployerContent(this.rentalFormId, this.token, this.form).then(function (response) {
          that.successMessage = that.$t('renting.referencesEmployer.success')
          that.referenceSendSuccess = true
          that.referenceSending = false
          that.state = 'REFERENCE_SENT'
        }).catch(() => {
          that.errorMessage = that.$t('renting.referencesEmployer.referenceSaveError')
          that.referenceSendSuccess = false
          that.referenceSending = false
          that.state = 'REFERENCE_SENT'
        })
      }
    }
  }
}
</script>

<style scoped lang="postcss">
</style>
