<template>
  <v-app>
    <v-alert type="error" v-model="alert.showing" transition="scale-transition" class="ma-2">
      {{ alert.text }}
    </v-alert>
    <template v-if="$route.meta.full">
      <FullScreenWrapper />
    </template>
    <template v-else>
      <AppBar @showNavigationDrawer="showNavigationDrawer"/>
      <AppNavigation :show-navigation-drawer="navigationDrawer" @showNavigationDrawer="showNavigationDrawer" />
      <v-main>
        <router-view />
      </v-main>
    </template>
    <v-snackbar
      v-model="snackbar.showing"
      :timeout="2000"
      :color="snackbar.color"
      :bottom="true"
      :right="true"
    >
      <template v-if="snackbar.responseError">
        <p class="mb-0">{{ snackbar.responseError }}</p>
      </template>
      <template v-if="!snackbar.statusHandledError">
        <p class="mb-0">{{ snackbar.text }}</p>
      </template>
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.showing = false">{{ $t('common.buttons.close') }}</v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import AppBar from './structure/AppBar'
import AppNavigation from './structure/AppNavigation'
import FullScreenWrapper from './structure/FullScreenWrapper'

import { mapState } from 'vuex'

export default {
  name: 'App',
  components: {
    AppBar,
    FullScreenWrapper,
    AppNavigation
  },
  data () {
    return {
      navigationDrawer: false
    }
  },
  watch: {
    '$route': 'updatePageTitle'
  },
  created () {
    this.updatePageTitle()
    if (this.$appInsights) {
      this.$appInsights.trackPageView()
    }
  },
  computed: {
    ...mapState('notifier', ['snackbar', 'alert'])
  },
  methods: {
    showNavigationDrawer (show) {
      this.navigationDrawer = show
    },
    updatePageTitle () {
      if (this.$route && this.$route.meta && this.$route.meta.title) {
        document.title = 'Prestige Tools - ' + this.$t('navigation.' + this.$route.meta.title)
      } else {
        document.title = 'Prestige Tools'
      }
    }
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
html {
  overflow-y: auto;
}
</style>
