<template>
  <div>
    <div v-if="!parking">
      <v-subheader>{{ $t('ticketingSystem.processes.interestedPerson.rentalObjectTitle') }}</v-subheader>
      <v-simple-table v-if="!parking">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">{{ $t('common.option.name') }}</th>
            <th class="text-left">{{ $t('common.option.value') }}</th>
            <th class="text-left">{{ $t('renting.comment') }}</th>
          </tr>
          </thead>
          <tbody>
            <tr>
              <td width="50%">{{ $t('renting.publicForm.fields.newApartment.additionalInformation.pets.title') }}</td>
              <td width="20%"><v-icon :color="iconColor(newApartment.hasPets)">{{ icon(newApartment.hasPets) }}</v-icon></td>
              <td width="30%"><span v-if="newApartment.hasPets">{{ newApartment.pet }} {{ newApartment.dogBreed }}</span></td>
            </tr>
            <tr>
              <td>{{ $t('renting.publicForm.fields.newApartment.additionalInformation.musicInstruments.title') }}</td>
              <td><v-icon :color="iconColor(newApartment.hasMusicalInstruments)">{{ icon(newApartment.hasMusicalInstruments) }}</v-icon></td>
              <td><span v-if="newApartment.hasMusicalInstruments">{{ newApartment.musicalInstrument }}</span></td>
            </tr>
            <tr>
              <td>{{ $t('renting.publicForm.fields.newApartment.sublet.title') }}</td>
              <td colspan="2"><v-icon :color="iconColor(newApartment.takeSubtenantsLater)">{{ icon(newApartment.takeSubtenantsLater) }}</v-icon></td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="pa-4">
      <v-row>
        <v-col cols="12" class="py-0 mt-2">
          <span class="subtitle-2">{{ $t('renting.publicForm.fields.newApartment.renovationNeeded') }}: </span>
          <span v-if="newApartment.conclusionOfContract" class="body-2">{{ newApartment.renovationRequirement }}</span>
        </v-col>
        <v-col cols="12">
          <span class="subtitle-2">{{ $t('renting.publicForm.fields.newApartment.concludedContract') }}</span>
          <v-icon
            :color="newApartment.conclusionOfContract  ? 'red' : 'green'"
            class="mb-1"
          >
            {{ icon(newApartment.conclusionOfContract) }}
          </v-icon>
        </v-col>
      </v-row>
    </div>
    <div v-if="!parking">
      <v-divider></v-divider>
      <v-subheader>{{ $t('renting.publicForm.fields.newApartment.additionalObjects') }}</v-subheader>
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr>
              <td width="50%">{{ $t('renting.publicForm.fields.newApartment.additionalGarage') }}</td>
              <td width="50%"><v-icon :color="apartmentIconColor(newApartment.additionalGarage)">{{ icon(newApartment.additionalGarage) }}</v-icon></td>
            </tr>
            <tr>
              <td>{{ $t('renting.publicForm.fields.newApartment.additionalParkingSpot') }}</td>
              <td><v-icon :color="apartmentIconColor(newApartment.additionalParkingSpot)">{{ icon(newApartment.additionalParkingSpot) }}</v-icon></td>
            </tr>
            <tr>
              <td>{{ $t('renting.publicForm.fields.newApartment.additionalMotorcycleParkingSpot') }}</td>
              <td><v-icon :color="apartmentIconColor(newApartment.additionalMotorcycleParkingSpot)">{{ icon(newApartment.additionalMotorcycleParkingSpot) }}</v-icon></td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RentalObjects',
  props: {
    newApartment: {
      type: Object,
      default: () => {}
    },
    parking: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    icon (value) {
      return value ? 'checked' : 'close'
    },
    iconColor (value) {
      return value ? 'orange' : 'green'
    },
    apartmentIconColor (value) {
      return value ? 'green' : 'red'
    }
  }
}
</script>

<style scoped>
</style>
