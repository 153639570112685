<template>
  <v-flex xs12 sm12 md6 lg6 xl6 class="mt-3" :class="$vuetify.breakpoint.mdAndDown ? 'pb-6' : ''">
    <v-card height="100%">
      <v-container fluid grid-list-xs>
        <v-card-title class="pa-0">{{ $t('tasks.email.history.title') }}</v-card-title>
        <v-list two-line subheader v-if="mailHistory.length > 0">
          <div v-for="mail in mailHistory" :key="mail.id">
            <v-list-item>
              <v-list-item-avatar v-if="mail.success">
                <v-icon color="teal">check_circle</v-icon>
              </v-list-item-avatar>
              <v-list-item-avatar v-else>
                <v-icon color="red">error</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ formatDateAndTimeFunc(mail.sentOn) }} {{ setMailReceiver(mail.receiver) }}</v-list-item-title>
                <v-list-item-subtitle v-if="mail.success">{{ mail.sentTo }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </div>
        </v-list>
        <div class="pt-2" v-else>
          <v-alert type="info" :value="true">
            {{ $t('tasks.email.history.noEmailsMessage') }}
          </v-alert>
        </div>
      </v-container>
    </v-card>
  </v-flex>
</template>

<script>
import { formatDateAndTime } from '@/helpers/formatterHelper'

export default {
  name: 'MailHistory',
  props: {
    mailHistory: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    formatDateAndTimeFunc: formatDateAndTime,
    setMailReceiver (mailReceiver) {
      switch (mailReceiver) {
        case 'TENANT':
          return this.$t('tasks.tenant.title')
        case 'CLIENT':
          return this.$t('tasks.email.owner')
        case 'CARETAKER':
          return this.$t('tasks.info.caretaker')
        case 'CRAFTSMAN':
          return this.$t('tasks.craftsmen.title')
        case 'REAL_ESTATE_MANAGER':
          return this.$t('tasks.email.realEstateManager')
        default:
          return ''
      }
    }
  }
}
</script>
