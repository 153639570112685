<template>
  <div class="mr-4">
    <div class="column">
      <drag-verify
        :width="width"
        :height="height"
        :text="text"
        :success-text="successText"
        :background="background"
        :progress-bar-bg="progressBarBg"
        :completed-bg="completedBg"
        :handler-bg="handlerBg"
        :handler-icon="handlerIcon"
        :text-size="textSize"
        :success-icon="successIcon"
        :circle="getShape"
        @passcallback="callback" />
    </div>
    <div
      v-if="showError"
      class="error--text captcha-error"
    >
      {{ $t('renting.captcha.errorMessage')}}
    </div>
  </div>
</template>

<script>
import dragVerify from 'vue-drag-verify'
import { mapActions } from 'vuex'
export default {
  name: 'CaptchaForm',
  components: {
    dragVerify
  },
  props: {
    showErrorMessage: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      handlerIcon: 'fa fa-angle-double-right',
      successIcon: 'fa fa-check',
      background: '#cccccc',
      progressBarBg: '#76366C',
      completedBg: '#66cc66',
      handlerBg: '#fff',
      width: 220,
      height: 30,
      textSize: '13px',
      isCircle: 'true'
    }
  },
  mounted() {
    if (window.Cypress) {
      window.CaptchaForm = this;
    }
  },
  computed: {
    getShape () {
      return this.isCircle === 'true'
    },
    showError () {
      return this.showErrorMessage
    },
    text () {
      return this.$t('renting.captcha.swipeToRight')
    },
    successText () {
      return this.$t('renting.captcha.success')
    }
  },
  methods: {
    ...mapActions('captcha', ['setRentingFormCaptchaStatus']),
    callback () {
      this.setRentingFormCaptchaStatus(true)
      this.$emit('successSwipe')
    }
  }
}
</script>

<style scoped lang="postcss">
.head{
  margin-bottom: 50px;
}
.captcha-error {

}
.column {
  float: left;
  width:100%;
}
table input {
    font: inherit;
    margin-bottom: 10px;
    margin: 0;
    outline: none;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    float:left;
}
table { display: block; width: 100%; overflow: auto; word-break: normal; word-break: keep-all; -webkit-overflow-scrolling: touch; }
table th { font-weight: bold; }
table th, table td { padding: 0.5rem 1rem;  }
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
