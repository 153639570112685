import vm from '../main'
import i18n from '../i18n/i18n'

const ABACUS_HREF = 'https://abacus.prestige.ch:40001/abastart?mandant=10&program='

export function useTenantActions () {
  const tenantActions = [
    { title: i18n.t('dashboard.actions.tenant.title'), icon: 'abacus-logo', action: onTenant },
    { title: i18n.t('dashboard.actions.tenant.accontStatementTenant'), icon: 'abacus-logo', action: onAccountStatement }
  ]

  function onTenant () {
    let url = ABACUS_HREF + 'immo.12&TenantNumber=' + vm.$route.params.addressId
    window.open(url, '_blank')
  }

  function onAccountStatement () {
    let url = ABACUS_HREF + 'debi.32&CustomerNumber=' + vm.$route.params.addressId
    window.open(url, '_blank')
  }

  return {
    tenantActions,
    onTenant,
    onAccountStatement
  }
}
