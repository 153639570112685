import Vue from 'vue'
import VueI18n from 'vue-i18n'
import moment from 'moment'
import de from '@/i18n/locales/de.js'
import en from '@/i18n/locales/en.js'

Vue.use(VueI18n)

export const locale = localStorage.getItem('language') || 'de'

moment.locale(locale)

export const i18n = new VueI18n({
  locale,
  fallbackLocale: 'de',
  messages: {
    de,
    en
  }
})

export default i18n
