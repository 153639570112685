<template>
  <v-container fluid class="grey lighten-5">
    <v-card class="pa-2 mb-2" width="100%" ref="ticketsFilter">
      <v-layout wrap class="filters">
        <v-flex shrink class="mr-4">
          <v-card-subtitle class="pa-0 mt-3 ml-1 mb-1 font-weight-bold subtitle-1">
            {{ $t('ticketingSystem.list.filter') }}
          </v-card-subtitle>
        </v-flex>
        <v-flex grow class="mr-4">
          <v-autocomplete
            :label="$t('ticketingSystem.list.filters.searchUser')"
            :loading="userLoading || $apollo.loading"
            return-object
            :items="userLinkOptions"
            item-text="name"
            item-value="userId"
            @change="changedUserValue"
            v-model="selectedUser"
            clearable
          />
        </v-flex>
        <v-flex grow class="mr-4">
          <v-autocomplete
            :label="$t('ticketingSystem.list.filters.searchProperty')"
            :loading="abacusPropertiesLoading || $apollo.loading"
            return-object
            :items="propertyLinkOptions"
            :filter="customPropertyFilter"
            item-text="name"
            item-value="propertyId"
            @change="changedPropertyValue"
            v-model="selectedProperty"
            clearable
          />
        </v-flex>
        <v-flex grow class="mr-4">
          <v-autocomplete
            :label="$t('ticketingSystem.list.filters.object')"
            :loading="$apollo.queries.abacusProperty.loading"
            return-object
            :items="propertyObjectLinkOptions"
            item-text="displayText"
            item-value="objectId"
            @change="changedObjectValue"
            v-model="selectedObject"
            clearable
            :disabled="!selectedProperty"
          />
        </v-flex>
        <v-flex grow class="mr-4">
          <v-autocomplete
            :label="$t('ticketingSystem.list.filters.searchTicket')"
            :loading="processDefinitionsLoading || $apollo.loading"
            return-object
            :items="processDefinitionOptions"
            item-text="name"
            item-value="id"
            @change="changedProcessDefinitionValue"
            v-model="selectedProcessDefinition"
            clearable
          ></v-autocomplete>
        </v-flex>
        <v-flex grow class="mr-4">
          <v-autocomplete
            :label="$t('ticketingSystem.list.filters.searchTaskName')"
            :loading="$apollo.loading"
            return-object
            :items="taskNameOptions"
            item-text="name"
            item-value="id"
            @change="changedTaskNameValue"
            v-model="selectedTaskName"
            clearable
          ></v-autocomplete>
        </v-flex>
        <v-flex shrink class="mt-4 ml-2">
          <v-checkbox
            class="ma-0 pa-0 checkbox"
            :label="$t('ticketingSystem.processes.onlyActive')"
            value="ACTIVE"
            v-model="stateFilter"
            @change="changedStateValue"
          ></v-checkbox>
        </v-flex>
        <v-flex text-end class="mt-3">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="clear ml-2 mr-1" color="primary" :size="28" @click="clearFilters" v-bind="attrs" v-on="on" />
            </template>
            <span>{{ $t('ticketingSystem.list.clear') }}</span>
          </v-tooltip>
        </v-flex>
      </v-layout>
    </v-card>
    <v-layout class="processes-list mt-2">
      <v-flex>
        <v-data-table
          :headers="headers"
          :items="searchTicketingTickets ? searchTicketingTickets.items : []"
          :loading="$apollo.loading || skipTicketSearch || ticketsLoading"
          :footer-props="{itemsPerPageOptions: itemsPerPageOptions, itemsPerPageText: itemsPerPageText}"
          :server-items-length="searchTicketingTickets ? searchTicketingTickets.totalItems : 0"
          :item-class="rowClass"
          :options.sync="options"
          @click:row="goToProcessDetails"
          fixed-header
          class="elevation-1"
        >
          <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
          <template v-slot:[`item.processDefinitionName`]="{ item }">
            <p class="d-flex">
              <v-icon v-if="item.hasUnreadMail" color="primary" class="mr-2">email</v-icon>
              <span>{{ item.processDefinitionName }}</span>
            </p>
          </template>
          <template v-slot:[`item.subject`]="{ item }">
            <span class="truncate-two-lines cy-ticketing-list-entry-subject" :key="item.taskId" >{{ item.subject }}</span>
          </template>
          <template v-slot:[`item.state`]="{ item }">
            <StateIcon :state="item.state" />
          </template>
          <template v-slot:[`item.openTasks`]="{ item }">
            <p v-for="task in item.openTasks" :key="task.taskId">{{task.name}} <span class="caption" v-if="task.dueDate">({{ $t('common.date.dueOn') }}: {{ formatDateAndTimeFunc(task.dueDate) }})</span></p>
          </template>
          <template v-slot:[`item.startedOn`]="{ item }">
            {{ formatDateAndTimeFunc(item.startedOn) }}
          </template>
          <template v-slot:[`item.finishedOn`]="{ item }">
            {{ formatDateAndTimeFunc(item.finishedOn) }}
          </template>
          <template v-slot:[`item.startUser`]="{ item }">
            <UserName v-if="item.startUser" :user-id="item.startUser" />
          </template>
          <template slot="no-data">
            <v-alert class="mt-4" :value="true" color="error" icon="warning">
              {{ $t('table.noData') }}
            </v-alert>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import StateIcon from '@/components/StateIcon'
import UserName from '@/components/UserName'

import * as globalHelper from '@/helpers/globalHelper'
import { formatDateAndTime } from '@/helpers/formatterHelper'
import { setRowClassByDiff } from '@/helpers/momentHelper'

import { TICKETING_PROCESS_DETAILS } from '@/config/options/routes/ticketingRoutes'
import { mapActions, mapState } from 'vuex'
import { SEARCH_TICKETING_TICKETS } from '../graphql-apollo/searchTicketingTickets.gql'
import { ABACUS_PROPERTY_OBJECTS } from '../graphql-apollo/abacusPropertyObjects.gql'

export default {
  name: 'ProcessesList',
  components: {
    StateIcon,
    UserName
  },
  data () {
    return {
      readyForProcessLoading: false,
      selectedProperty: null,
      selectedObject: null,
      selectedProcessDefinition: null,
      selectedTaskName: null,
      stateFilter: 'ACTIVE',
      searchTicketingTickets: {},
      ticketsLoading: true,
      options: {
        sortDesc: [true],
        sortBy: ['startedOn'],
        itemsPerPage: 100,
        page: 1
      },
      totalItems: 0,
      itemsPerPageOptions: [ 20, 50, 100 ],
      userLoading: false,
      filters: {},
      selectedUser: null,
    }
  },
  apollo: {
    searchTicketingTickets: {
      query: SEARCH_TICKETING_TICKETS,
      variables () {
        return {
          query: {
            state: this.processesListFilters.state,
            processDefinitionKey: this.processesListFilters?.processDefinition?.id,
            propertyIds: this.processesListFilters?.property?.propertyId,
            propertyObjectId: this.processesListFilters?.property?.propertyId && this.processesListFilters?.propertyObject?.objectId ? `${this.processesListFilters?.property?.propertyId}:${this.processesListFilters.propertyObject?.objectId}` : null,
            userId: this.processesListFilters?.userId?.userId,
            orderBy: this.setSortBy(this.options.sortBy[0]),
            orderByDirection: this.options.sortDesc && this.options.sortDesc[0] ? 'DESC' : 'ASC',
            page: this.options.page - 1,
            size: this.options.itemsPerPage,
            openTaskShortName: this.selectedTaskName?.id
          }
        }
      },
      manual: true,
      result ({ data, loading }) {
        if (!loading) {
          this.ticketsLoading = true

          if (data?.searchTicketingTickets?.items) {
            let promises = []
            // TODO this is currently needed to avoid duplicate requests to retrieve user details, as BE doesn't return the user's name
            // ensure first that we have all the required users loaded, otherwise maaaany request are done.
            const users = [...new Set(data.searchTicketingTickets.items.map(item => item.startUser))]
            users.forEach(user => {
              if (user !== 'bot' && user !== 'rentingform' && user !== 'admin') {
                promises.push(this.loadAddressDetails(user))
              }
            })
            Promise.all(promises).then(() => {
              this.searchTicketingTickets = data?.searchTicketingTickets
              this.ticketsLoading = false
            })
          } else {
            this.ticketsLoading = false
          }
        }
      },
      skip () {
        return this.skipTicketSearch
      }
    },
    abacusProperty: {
      query: ABACUS_PROPERTY_OBJECTS,
      variables () {
        return {
          propertyId: Number(this.selectedProperty?.propertyId)
        }
      },
      skip () {
        return !this.selectedProperty?.propertyId
      },
      fetchPolicy: 'cache-first'
    }
  },
  created () {
    this.loadAbacusProperties()
    this.loadTicketingUsers()
    Promise.all([this.$store.dispatch('ticketingSystem/loadProcessDefinitions')]).then(() => {
      this.initDefaultFilters()
    })
  },
  mounted () {
    this.addStyle()
    window.addEventListener('resize', this.addStyle)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.addStyle)
  },
  computed: {
    ...mapState('ticketingSystem', ['ticketingUsersDisplayItems', 'ticketingUsers', 'processesListFilters', 'listProcessDefinitions', 'processDefinitionsLoading']),
    ...mapState('abacus', ['abacusPropertiesDisplayItems', 'abacusPropertiesLoading', 'addressesDetails']),
    headers () {
      return [
        {
          text: this.$t('ticketingSystem.processes.process'),
          align: 'left',
          value: 'processDefinitionName',
          sortable: false
        },
        {
          text: this.$t('ticketingSystem.subject'),
          align: 'left',
          value: 'subject'
        },
        {
          text: this.$t('ticketingSystem.taskDetails.state'),
          align: 'left',
          value: 'state'
        },
        {
          text: this.$t('ticketingSystem.processes.openTasks'),
          align: 'left',
          value: 'openTasks'
        },
        {
          text: this.$t('ticketingSystem.taskDetails.processReferences.startedOn'),
          align: 'left',
          value: 'startedOn'
        },
        {
          text: this.$t('ticketingSystem.taskDetails.processReferences.finishedOn'),
          align: 'left',
          value: 'finishedOn'
        },
        {
          text: this.$t('ticketingSystem.taskDetails.processReferences.startedBy'),
          align: 'left',
          value: 'startUser',
          sortable: false
        }
      ]
    },
    itemsPerPageText () {
      return this.$t('common.itemsPerPageText')
    },
    propertyObjects () {
      return this.abacusProperty ? this.abacusProperty.objects.map((object) => {
        return {
          displayText: `[${object.objectId}] ${object.objectText} ${object.floorText} - ${object.currentTenantText}`,
          objectId: object.objectId
        }
      }) : []
    },
    skipTicketSearch () {
      return !this.ticketingUsers || this.ticketingUsers.length === 0
    },
    userLinkOptions () {
      let buckets = this.getBuckets('userLinks')
      if (buckets && buckets.length > 0) {
        return buckets.map(({ name, count}) => ({
          userId: name,
          name: (this.ticketingUsersDisplayItems.find(x => x.userId === name)?.displayText || '[' + name + ']') + ' (' + count + ')'
        }))
      } else {
        return []
      }
    },
    propertyLinkOptions () {
      let buckets = this.getBuckets('propertyLinks')
      if (buckets && buckets.length > 0) {
        return buckets.map(({ name, count}) => ({
          propertyId: name,
          name: (this.abacusPropertiesDisplayItems.find(x => x.propertyId.toString() === name)?.displayText || '[' + name + ']') + ' (' + count + ')'
        }))
      } else {
        return []
      }
    },
    propertyObjectLinkOptions () {
      let buckets = this.getBuckets('propertyObjectLinks')
      if (buckets && buckets.length > 0) {
        return buckets
          .filter(bucket => bucket.name.includes(this.selectedProperty?.propertyId + ':'))
          .map(bucket => {
            const propertyObject = this.propertyObjects.find(propertyObject => bucket.name === this.selectedProperty?.propertyId + ':' + propertyObject.objectId)
            if (propertyObject) {
              return {
                objectId: propertyObject.objectId,
                displayText: propertyObject.displayText + ' (' + bucket.count + ')'
              }
            } else {
              return undefined
            }
          })
          .filter(match => match)
      } else {
        return []
      }
    },
    processDefinitionOptions () {
      let buckets = this.getBuckets('processDefinitionKey')
      if (buckets && buckets.length > 0) {
        return buckets.map(({ name, count}) => ({
          id: name,
          name: (this.listProcessDefinitions.find(x => x.processDefinitionKey === name)?.processDefinitionName || '[' + name + ']') + ' (' + count + ')'
        }))
      } else {
        return []
      }
    },
    taskNameOptions () {
      let buckets = this.getBuckets('openTaskShortName')
      if (buckets && buckets.length > 0) {
        return buckets.map(({ name, count}) => ({
          id: name,
          name: name + ' (' + count + ')'
        }))
      } else {
        return []
      }
    }
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    ...mapActions('abacus', ['loadAddressDetails', 'loadAbacusProperties']),
    ...mapActions('ticketingSystem', ['loadTicketingUsers']),
    formatDateAndTimeFunc: formatDateAndTime,
    addStyle () {
      let ticketFilter = document.getElementsByClassName('v-data-table__wrapper')
      for (let i = 0; i < ticketFilter.length; i++) {
        ticketFilter[i].style.maxHeight = `calc(100vh - (195px + ${this.filterTicketHeight()}))`
      }
      return ticketFilter
    },
    filterTicketHeight () {
      return this.$refs.ticketsFilter.$el.clientHeight + 'px'
    },
    initDefaultFilters () {
      this.selectedUser = this.processesListFilters?.userId
      this.selectedProperty = this.processesListFilters?.property
      this.selectedObject = this.processesListFilters.propertyObject
      this.selectedProcessDefinition = this.processesListFilters.processDefinition
      this.selectedTaskName = this.processesListFilters?.taskName
      this.stateFilter = this.processesListFilters?.state
    },
    changedUserValue (value) {
      this.updateFilters({userId: value})
    },
    changedPropertyValue () {
      this.updateFilters({property: this.selectedProperty})
      this.updateFilters({propertyObject: null})
    },
    changedObjectValue () {
      this.updateFilters({propertyObject: this.selectedObject})
    },
    changedProcessDefinitionValue (value) {
      this.updateFilters({processDefinition: value})
    },
    changedTaskNameValue (value) {
      this.updateFilters({taskName: value})
    },
    changedStateValue (value) {
      this.updateFilters({state: value})
    },
    setSortBy (sortBy) {
      let formattedSortBy
      if (sortBy === 'processDefinitionKey') {
        formattedSortBy = 'DEFINITION¨_KEY'
      } else if (sortBy === 'subject') {
        formattedSortBy = 'SUBJECT'
      } else if (sortBy === 'state') {
        formattedSortBy = 'STATE'
      } else if (sortBy === 'startedOn') {
        formattedSortBy = 'STARTED_ON'
      } else if (sortBy === 'openTasks') {
        formattedSortBy = 'OPEN_TASK_DUE_DATE'
      } else if (sortBy === 'finishedOn') {
        formattedSortBy = 'FINISHED_ON'
      } else if (sortBy === 'startUser') {
        formattedSortBy = 'START_USER_ID'
      }
      return formattedSortBy
    },
    updateFilters (update) {
      let updatedFilters = Object.assign(this.processesListFilters, update)
      updatedFilters = Object.fromEntries(Object.entries(updatedFilters).filter(([_, v]) => v != null))
      this.$store.dispatch('ticketingSystem/updateProcessesListFilters', updatedFilters)
      if (this.options.page > 1) {
        this.options.page = 1
      }
    },
    clearFilters () {
      this.$store.dispatch('ticketingSystem/clearProcessesListFilters')
      this.initDefaultFilters()
      if (this.options.page > 1) {
        this.options.page = 1
      }
    },
    goToProcessDetails (process) {
      this.$router.push({ name: TICKETING_PROCESS_DETAILS, params: { processInstanceId: process.ticketId } })
    },
    rowClass (item) {
      return setRowClassByDiff(item.startedOn)
    },
    customPropertyFilter (item, queryText, itemText) {
      return globalHelper.propertyFilter(queryText, itemText)
    },
    getBuckets (facetName) {
      return this.searchTicketingTickets?.facets?.find(facet => facet.name === facetName)?.buckets
    }
  }
}
</script>

<style scoped lang="postcss">
/deep/ tr:nth-child(odd) {
  background: #f8f8f8;
}
/deep/ tbody tr {
  cursor: pointer;
}
/deep/ .grow {
  max-width: 320px;
}
/deep/ .filters .v-input {
  padding-top: 8px;
  margin-top: 0px;
  height: 40px;
}
/deep/ .filters .checkbox .v-input__control {
  height: 20px;
}
/deep/ .filters .checkbox .v-input__slot {
  margin-bottom: 0;
}
.v-data-table {
  overflow-x: auto !important;
}
.truncate-two-lines {
  display: -webkit-inline-box;
  margin: 0 auto;
  line-height: 1.5;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
