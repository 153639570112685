<template>
  <v-card class="mb-2 pa-0">
    <v-card-text class="pa-2">
      <v-list-item class="pa-0">
        <v-list-item-content class="pl-1">
          <v-card-subtitle class="pa-0 font-weight-bold subtitle-1">
            {{ $t('ticketingSystem.processes.interestedPerson.title') }}
          </v-card-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card-text>
    <v-divider></v-divider>
    <template v-if="rentingFormData">
      <v-list>
        <v-subheader class="flex-column align-start subheader">
          <div v-if="rentingFormData.desiredMoveInDate">
            {{ $t('renting.publicForm.desiredMoveInDate') }}: {{formatDateFunc(rentingFormData.desiredMoveInDate)}}
          </div>
          <div v-if="highlightEarliestMoveInDate(rentingFormEarliestDate, rentingFormData.desiredMoveInDate)" class="d-flex align-end">
            {{ $t('renting.publicForm.earliestStart') }}: {{formatDateFunc(rentingFormEarliestDate)}}
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" color="orange" class="pl-2">warning</v-icon>
              </template>
              <span>{{ $t('ticketingSystem.messages.desiredDateNoEqual') }}</span>
            </v-tooltip>
          </div>
          <div>Interessent(en)</div>
        </v-subheader>
        <template v-for="(resident, index) in rentingFormData.residents">
          <v-card :key="'residents-' + index" class="ma-2">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ setSalutation(resident.salutation) }} {{ resident.firstName }} {{ resident.lastName }}</v-list-item-title>
                <v-list-item-subtitle>{{ resident.mobile }} {{ resident.phone1 }} {{ resident.phone2 }} {{ resident.email }}</v-list-item-subtitle>
                <v-list-item-subtitle>{{ resident.currentApartmentStreet }} {{ resident.currentApartmentZip }} {{ resident.currentApartmentPlace }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </template>
      </v-list>
      <v-divider></v-divider>
    </template>
    <RentingViewings :renting-form-id="rentingFormId"></RentingViewings>
    <template v-if="rentingFormData">
      <SetRentalApplicationDecision
        :process-instance-id="processInstanceId"
        :decision="decision"
        :decision-comment="decisionComment"
        @loadDetails="loadDetails"
      />
    </template>
    <v-expansion-panels v-if="rentalContractId" v-model="panel" class="pa-2">
      <ProcessesRentalApplications :rentalContractId="rentalContractId" :isExpanded="isExpanded"/>
    </v-expansion-panels>
  </v-card>
</template>
<script>
import SetRentalApplicationDecision from './process-renting-form/SetRentalApplicationDecision'
import RentingViewings from './process-renting-form/RentingViewings'
import ProcessesRentalApplications from './process-reletting/ProcessesRentalApplications.vue'
import * as ticketingHelper from '@/features/ticketing/helpers/ticketingHelper'
import { formatDate } from '@/helpers/formatterHelper'
import { mapActions } from 'vuex'
import { salutationItems } from '@/config/options/salutation'

import { useRentingForm } from '@/use/useRentingForm'
const { loadRentingForm } = useRentingForm()

export default {
  name: 'ProcessOfflineRentingForm',
  props: {
    title: {
      type: String,
      default: ''
    },
    processInstanceId: {
      type: String,
      default: ''
    },
    processVariables: {
      type: Array,
      default: () => {
      }
    }
  },
  components: {
    SetRentalApplicationDecision,
    RentingViewings,
    ProcessesRentalApplications
  },
  data () {
    return {
      rentingForm: null,
      loading: false,
      panel: [],
      isExpanded: false
    }
  },
  computed: {
    rentalContractId () {
      return this.getVariableValue('System_RentalContractId_Long')
    },
    rentingFormId () {
      return this.getVariableValue('System_RentingFormId_Long')
    },
    rentingFormData () {
      return this.rentingForm && this.rentingForm.content ? this.rentingForm.content : undefined
    },
    rentingFormEarliestDate () {
      return this.rentingForm?.rentalCase?.earliestStart
    },
    decision () {
      return this.getVariableValue('System_InterestedPerson_Decision_Enum')
    },
    decisionComment () {
      return this.getVariableValue('System_InterestedPerson_DecisionComment_String')
    }
  },
  watch: {
    'rentingFormId': {
      handler: 'loadData',
      immediate: true
    },
    'panel': 'expandedPanel'
  },
  methods: {
    formatDateFunc: formatDate,
    ...mapActions('notifier', ['setErrorSnackbar']),
    async loadData () {
      this.loading = true
      this.rentingForm = await loadRentingForm(this.rentingFormId)
      this.loading = false
    },
    getVariableValue (variableName) {
      return ticketingHelper.getVariableValue(this.processVariables, variableName)
    },
    loadDetails () {
      this.$emit('loadDetails')
    },
    expandedPanel (value ){
      if(value >= 0) {
        this.isExpanded = true
      }
    },
    highlightEarliestMoveInDate (earliestDate, desiredDate) {
      if (earliestDate !== desiredDate) {
        return this.formatDateFunc(earliestDate)
      }
      return null
    },
    setSalutation (salutation) {
      if (salutation) {
        return salutationItems.find(x => x.key === salutation)?.value || ''
      }
      return ''
    }
  }
}
</script>
<style scoped lang="postcss">
.no-info {
  color: rgba(0,0,0,.6)
}
/deep/ .editor {
  height: 75px;
}
.subheader {
  height: 100%;
}
</style>
