export const documentSourceOptions = [
  {
    id: 0,
    key: 'fileShare',
    value: 'FILE_SHARE'
  },
  {
    id: 1,
    key: 'dynamicallyCreated',
    value: 'GENERATED'
  }
]

export const paperTrayOptions = [
  {
    id: 0,
    key: 'default',
    value: 'DEFAULT'
  },
  {
    id: 1,
    key: 'logo',
    value: 'LOGO'
  },
  {
    id: 2,
    key: 'logoFollowingPage',
    value: 'LOGO_FOLLOWING_PAGE'
  },
  {
    id: 3,
    key: 'coverSheetYellow',
    value: 'COVER_SHEET_YELLOW'
  },
  {
    id: 4,
    key: 'coverSheetBlue',
    value: 'COVER_SHEET_BLUE'
  },
  {
    id: 5,
    key: 'additional',
    value: 'ADDITIONAL'
  }
]
