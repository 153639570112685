import i18n from '@/i18n/i18n'

export const salutationItems = [
  {
    key: 'MR',
    value: i18n.t('common.salutationOptions.MR')
  },
  {
    key: 'MRS',
    value: i18n.t('common.salutationOptions.MRS')
  }
]
