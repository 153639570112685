<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :loading="loading"
    :hide-default-footer="true"
    :footer-props="{itemsPerPageOptions}"
    class="elevation-1"
  >
    <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
    <template v-slot:item="{ item }">
      <tr>
        <td class="min-350">{{ item.documentKey.toUpperCase() }}</td>
        <td :class="headers.length > 6 ? 'min-250' : ''" v-for="(version, index) in headers.slice(1, -1)" :key="index">
          <div v-if="!item.documents">
            <v-icon v-if="index === 0" color="primary">info</v-icon>
            <span v-if="index === 0" class="text-12">{{ $t('dossier.recipients.recipientDetail.noDocuments') }}</span>
          </div>
          <div v-else>
            <v-layout v-if="item.documents[index]">
              <v-checkbox
                prepend-icon="file_download"
                v-model="selected"
                persistent-hint
                :label="'V' + item.documents[index].version"
                :hint="item.documents[index].lastPrinted ? $t('dossier.recipients.recipientDetail.printed') + ': ' + formatDateAndTimeFunc(item.documents[index].lastPrinted) : ''"
                :value="item.documents[index].documentId"
                @change="updateSelectedDocuments(item.documentTypeId, item.documents[index].documentId)"
                @click:prepend="downloadDocument(item.documents[index].documentId)"
              >
              </v-checkbox>
            </v-layout>
            <div v-else>
              <v-icon v-if="index === 0" color="primary">info</v-icon>
              <span v-if="index === 0" class="text-12">{{ $t('dossier.recipients.recipientDetail.noDocuments') }}</span>
            </div>
          </div>
        </td>
        <td>{{ numberOfCopies(item.documentTypeId) }}</td>
      </tr>
    </template>
    <template slot="no-data">
      <v-alert :value="true" color="error" icon="warning">
        {{ $t('table.noData') }}
      </v-alert>
    </template>
  </v-data-table>
</template>

<script>
import { formatDateAndTime } from '@/helpers/formatterHelper'

export default {
  name: 'DocumentDataTable',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    selectedDocuments: {
      type: Array,
      default: () => []
    },
    selectedCheckboxes: {
      type: Array,
      default: () => []
    },
    copies: {
      type: Array,
      default: () => []
    },
    selectedPrintout: {
      type: Number
    }
  },
  data () {
    return {
      headersTemplate: this.setInitialHeaders(),
      itemsPerPageOptions: [
        {
          'text': this.$t('common.all'),
          'value': -1
        }
      ],
      mutableSelectedDocuments: this.selectedDocuments,
      mutableSelectedCheckboxes: this.selectedCheckboxes
    }
  },
  computed: {
    selected: {
      get: function () {
        return this.mutableSelectedCheckboxes
      },
      set: function (newValue) {
        return newValue
      }
    },
    headers: {
      get: function () {
        return this.headersTemplate
      },
      set: function (newValue) {
        return newValue
      }
    },
    numberOfVersions () {
      return this.headers.length - 2
    }
  },
  watch: {
    items () {
      this.setHeaders()
    }
  },
  mounted () {
    this.setHeaders()
  },
  methods: {
    formatDateAndTimeFunc: formatDateAndTime,
    setHeaders () {
      let that = this
      this.headersTemplate = this.setInitialHeaders()
      let filtered = this.items.filter(x => x.documents)
      if (filtered.length > 0) {
        let maxDocuments = filtered.sort(function (a, b) {
          return b.documents.length - a.documents.length
        })
        if (maxDocuments && maxDocuments[0].documents.length) {
          for (let i = 0; i < maxDocuments[0].documents.length - 1; i++) {
            let newIndex = i + 2
            let newHeader = {
              text: this.$t('dossier.recipients.recipientDetail.version') + ' ' + newIndex,
              align: 'left',
              value: 'documents-' + newIndex
            }
            that.headers = that.headersTemplate.splice(that.headers.length - 1, 0, newHeader)
          }
        }
      }
    },
    setInitialHeaders () {
      return [
        {
          text: this.$t('dossier.recipients.recipientDetail.document'),
          align: 'left',
          value: 'documentKey'
        },
        {
          text: this.$t('dossier.recipients.recipientDetail.version') + ' 1',
          align: 'left',
          value: 'documents-1'
        },
        {
          text: this.$t('dossier.recipients.recipientDetail.number'),
          align: 'left',
          value: 'copies'
        }
      ]
    },
    downloadDocument (documentId) {
      this.$emit('downloadDocumentPdf', documentId)
    },
    updateSelectedDocuments (documentTypeId, documentId) {
      if (this.selectedPrintout) {
        this.$emit('resetPrintout')
      }
      this.$emit('setCustomSelection')
      let index = this.mutableSelectedDocuments.findIndex(x => x.documentTypeId === documentTypeId && x.documentId === documentId)
      if (index === -1) {
        let selectedDocument = {
          documentId: documentId,
          documentTypeId: documentTypeId
        }
        this.mutableSelectedDocuments.push(selectedDocument)
        this.mutableSelectedCheckboxes.push(selectedDocument.documentId)
      } else {
        this.mutableSelectedDocuments.splice(index, 1)
        this.mutableSelectedCheckboxes.splice(index, 1)
      }
    },
    numberOfCopies (documentTypeId) {
      let listOfDocumentTypes = this.copies.find(x => x.documentTypeId === documentTypeId)
      let numberOfCopies = listOfDocumentTypes ? listOfDocumentTypes.numberOfCopies : 1
      return numberOfCopies
    }
  }
}
</script>

<style scoped>
.text-12 {
  font-size: 12px;
}
.min-350 {
  width: 350px;
  min-width: 350px;
}
.min-250 {
  width: 250px;
  min-width: 250px;
}
</style>
