<template>
  <v-flex class="mt-3" xs12 sm12 md3 lg3 xl3>
    <v-card height="100%">
      <v-container fluid grid-list-xs>
        <v-card-title class="pa-0">{{ $t('tasks.tenant.title') }}</v-card-title>
        <template v-if="form.tenant === 'MANUAL'">
          <v-autocomplete
            v-model="select"
            cache-items
            single-line
            return-object
            :label="$t('tasks.tenant.selectTenant')"
            :loading="loading"
            :search-input.sync="search"
            :disabled="readOnly"
            :items="items"
            item-text="displayText"
            item-value="addressId"
            @change="changedValue"
          ></v-autocomplete>

          <v-card-text class="pa-0" v-if="form.manualTenant">
            {{ name }}: {{ displayName(form.manualTenant) }}<br />
            {{ tel }}: {{ form.manualTenant.phone1 }}<br />
            {{ tel }}2: {{ form.manualTenant.phone2 }}<br />
            {{ mobile }}: {{ form.manualTenant.mobile }}<br />
            {{ email }}: {{ form.manualTenant.email }}<br />
            {{ comment }}: {{ form.manualTenant.comment }}<br />
          </v-card-text>
        </template>
        <template v-else>
          <v-radio-group v-model="form.tenant" :disabled="readOnly" label="" class="mt-0">
            <v-radio
              :label="tenantObjects ? currentTenantTextValue : ''"
              :value="'CURRENT'"
              :disabled="!form.currentTenant"
              @change="updateTenant('CURRENT')"
            />
            <v-radio
              v-if="form.newTenant"
              :label="tenantObjects ? newTenantTextValue : ''"
              :value="'NEW'"
              @change="updateTenant('NEW')"
            />
          </v-radio-group>

          <div v-if="form.tenant === 'CURRENT'">
            <v-card-text class="pa-0">
              {{ name }}: {{ displayName(tenantObjects.currentTenant) }}<br />
              {{ tel }}: {{ tenantObjects.currentTenant ? tenantObjects.currentTenant.phone1 : ''}}<br />
              {{ tel }}2: {{ tenantObjects.currentTenant ? tenantObjects.currentTenant.phone2 : '' }}<br />
              {{ mobile }}: {{ tenantObjects.currentTenant ? tenantObjects.currentTenant.mobile : ''}}<br />
              {{ email }}: {{ tenantObjects.currentTenant ? tenantObjects.currentTenant.email : '' }}<br />
              {{ comment }}: {{tenantObjects.currentTenant ? tenantObjects.currentTenant.comment : ''}}
            </v-card-text>
          </div>
          <div v-if="form.tenant === 'NEW'">
            <v-card-text class="pa-0">
              {{ name }}: {{ displayName(tenantObjects.newTenant) }}<br />
              {{ tel }}: {{ tenantObjects.newTenant ? tenantObjects.newTenant.phone1 : '' }}<br />
              {{ tel }}2: {{ tenantObjects.newTenant ? tenantObjects.newTenant.phone2 : '' }}<br />
              {{ mobile }}: {{ tenantObjects.newTenant ? tenantObjects.newTenant.mobile : '' }}<br />
              {{ email }}: {{ tenantObjects.newTenant ? tenantObjects.newTenant.email : '' }}<br />
              {{ comment }}: {{ tenantObjects.newTenant ? tenantObjects.newTenant.comment : '' }}<br />
            </v-card-text>
          </div>
        </template>
      </v-container>
    </v-card>
  </v-flex>
</template>

<script>
import { mapActions } from 'vuex'
import { formatDate } from '@/helpers/formatterHelper'
import * as globalHelper from '@/helpers/globalHelper'

export default {
  name: 'AbacusTenant',
  props: {
    tenant: {
      type: String,
      default: ''
    },
    tenantObjects: {
      type: Object,
      default: () => {}
    },
    newTenantContractStart: {
      type: String,
      default: ''
    },
    propertyId: {
      type: Number,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      items: [],
      search: null,
      select: null,
      form: {
        tenant: '',
        currentTenant: null,
        newTenant: null,
        manualTenant: null
      }
    }
  },
  watch: {
    // call again the method if the route changes
    'propertyId': 'fetchInitialData',
    'tenantObjects': {
      handler: 'setForm',
      deep: true
    }
  },
  computed: {
    name () {
      return this.$t('common.data.name')
    },
    tel () {
      return this.$t('common.data.tel')
    },
    mobile () {
      return this.$t('common.data.mobile')
    },
    email () {
      return this.$t('common.data.email')
    },
    currentTenantTextValue () {
      return this.$t('tasks.tenant.tenantOptions.CURRENT') + (this.tenantObjects.currentTenant ? (': ' + this.displayName(this.tenantObjects.currentTenant)) : '')
    },
    newTenantTextValue () {
      return this.$t('tasks.tenant.tenantOptions.NEW') +
        (this.tenantObjects.newTenant ? (': ' + this.displayName(this.tenantObjects.newTenant)) : '') +
        (this.newTenantContractStart ? ' (' + this.formatDateFunc(this.newTenantContractStart) + ')' : '')
    },
    comment () {
      return this.$t('tasks.tenant.comment')
    }
  },
  mounted () {
    this.loadTenants()
    this.setForm()
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    formatDateFunc: formatDate,
    async setForm () {
      this.form.tenant = this.tenant
      if (this.tenantObjects) {
        this.form.currentTenant = this.tenantObjects.currentTenant
        this.form.newTenant = this.tenantObjects.newTenant
        this.form.manualTenant = this.tenantObjects.manualTenant
      }
    },
    fetchInitialData () {
      this.loadTenants()
    },
    loadTenants () {
      if (this.propertyId) {
        this.loading = true
        let that = this
        this.$abacusProperty.getTenantsForPropertyId(this.propertyId).then(function (response) {
          if (response.data.length) {
            that.items = response.data
            that.items.forEach(item => {
              item.addressId = item.id
              delete item.id
              item.displayText = globalHelper.setDisplayText(item)
            })
          }
          that.loading = false
        }).catch(function (error) {
          that.setErrorSnackbar(error)
          that.items = []
        })
      }
    },
    changedValue () {
      this.form.manualTenant = this.select
      this.$emit('setSubjectLine2', {
        ...this.form
      })
      this.updateFormValues()
    },
    updateTenant (value) {
      this.form.tenant = value
      if (value !== 'MANUAL') {
        this.$emit('setSubjectLine2', {
          ...this.form
        })
      }
      this.updateFormValues()
    },
    updateFormValues () {
      this.$emit('updateFormValues', {
        ...this.form
      })
    },
    displayName (tenant) {
      return tenant ? (tenant.text ? tenant.text + ' ' : '') + (tenant.firstName ? tenant.firstName + ' ' : '') + (tenant.lastName ? tenant.lastName : '') : ''
    }
  }
}
</script>

<style scoped>
.input-group--selection-controls label {
  max-width: calc(100% - 32px);
}
</style>
