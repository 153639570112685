import Vue from 'vue'
import store from '../store/index'

export function useFiles () {
  const config = { responseType: 'blob' }

  async function getFileBlob (processInstanceId, formFieldId) {
    try {
      let response = await Vue.prototype.$prestigeTSProcess.downloadFileForVariable(processInstanceId, formFieldId, config)
      return response
    } catch (error) {
      store.dispatch('notifier/setErrorSnackbar', error)
    }
  }

  async function getAzureFileBlob (azureFileId) {
    try {
      let response = await Vue.prototype.$prestigeTSFile.downloadAzureFile(azureFileId, config)
      return response
    } catch (error) {
      store.dispatch('notifier/setErrorSnackbar', error)
    }
  }

  async function getTaskFileBlob (fileId, fileExtension) {
    try {
      let response = await Vue.prototype.$prestigeTaskApiFile.getFileById(fileId, fileExtension, config)
      return response
    } catch (error) {
      store.dispatch('notifier/setErrorSnackbar', error)
    }
  }

  async function getEmailFileBlob (fileLocation, fileName) {
    try {
      let response = await Vue.prototype.$prestigeTSFile.downloadFile('/' + fileLocation + '/' + fileName, config)
      return response
    } catch (error) {
      store.dispatch('notifier/setErrorSnackbar', error)
    }
  }

  return {
    getFileBlob,
    getAzureFileBlob,
    getTaskFileBlob,
    getEmailFileBlob
  }
}