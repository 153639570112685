var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.process)?_c('v-card',{staticClass:"process-info-content-card"},[_c('v-card-text',{staticClass:"pa-4"},[_c('v-btn',{staticClass:"float-right pa-3 mr-2",on:{"click":_vm.startRelatedProcess}},[_c('span',[_vm._v(_vm._s(_vm.$t('ticketingSystem.taskDetails.buttons.startRelatedProcess')))]),_c('v-icon',{staticClass:"pl-1"},[_vm._v("mdi-plus")])],1),(_vm.isReadyForMerge)?_c('v-menu',{attrs:{"offset-y":"","max-height":"500","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"float-right pa-3 mr-2",on:{"click":function($event){return _vm.$apollo.queries.ticketingTickets.refresh()}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('ticketingSystem.mergeTicket.btnTitle'))+" ")])]}}],null,false,3117507458)},[_c('v-list',[_vm._l((_vm.ticketsList),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.mergeTickets(item.ticketId)}}},[_c('v-list-item-title',{staticClass:"title-wrap"},[_vm._v(" [ "),_c('span',{staticClass:"merge-title text-truncate"},[_vm._v(_vm._s(item.ticketId))]),_vm._v(" ] "+_vm._s(item.subject)+" ")])],1)}),_c('infinite-loading',{on:{"infinite":_vm.infiniteHandler}},[_c('div',{attrs:{"slot":"no-more"},slot:"no-more"}),_c('div',{attrs:{"slot":"no-results"},slot:"no-results"})])],2)],1):_vm._e(),(_vm.isRestartOfProcessAllowed)?_c('v-btn',{staticClass:"float-right pa-3 mr-2",attrs:{"loading":_vm.loading},on:{"click":_vm.restartProcess}},[_c('span',[_vm._v(_vm._s(_vm.$t('ticketingSystem.taskDetails.buttons.restartProcess')))])]):_vm._e(),(!_vm.externallyTerminated)?_c('v-btn',{staticClass:"float-right mt-1 mr-2",attrs:{"small":"","icon":"","ripple":"","right":"","title":_vm.$t('ticketingSystem.deleteProcess.title')},on:{"click":_vm.openDeleteDialog}},[_c('v-icon',{attrs:{"color":"red lighten-1","dense":""}},[_vm._v("delete")])],1):_vm._e(),_c('ProcessReasonDeleteDialog',{attrs:{"processInstanceId":_vm.processInstanceId,"show-dialog":_vm.showDeleteDialog},on:{"closeDialog":_vm.closeDeleteDialog}}),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"pa-0 mx-0 my-2 process-info"},[_c('v-card-text',{staticClass:"pa-0 d-flex"},[(!_vm.processDetailsPage)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('router-link',{class:{'mt-1 mr-2 w-m-75' : !_vm.processDetailsPage},attrs:{"to":{ name: _vm.TICKETING_PROCESS_DETAILS, params: { processInstanceId: _vm.processInstanceId, processDefinitionKey: _vm.process.processDefinitionKey }}}},[_c('span',{staticClass:"ma-0 gray-text"},[_vm._v(" ["),_c('span',_vm._g(_vm._b({staticClass:"ma-0 truncate-one-line process-instance-id"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.processInstanceId)+" ")]),_vm._v("] ")])])]}}],null,false,4010010699)},[_c('span',[_vm._v(_vm._s(_vm.$t('ticketingSystem.processes.processDetails')))])]):_vm._e(),(!_vm.processQueryId)?_c('v-card-subtitle',{staticClass:"pa-0 font-weight-bold subtitle-1 w-p-90"},[_c('span',{staticClass:"truncate-one-line"},[_vm._v(" "+_vm._s(_vm.processInfo)+" "),(_vm.processSubject)?_c('span',{staticClass:"process-subject"},[_vm._v(" · "),_c('span',{staticClass:"cy-ticket-detail-subject"},[_vm._v(_vm._s(_vm.processSubject))])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[(!_vm.processFinished)?_c('v-icon',{staticClass:"ma-2",attrs:{"size":"18","color":"blue"},on:{"click":_vm.openProcessSubjectDiagram}},[_vm._v("edit")]):_vm._e()],1)]}}],null,false,1708683302)},[_c('span',[_vm._v(_vm._s(_vm.$t('ticketingSystem.tooltipEditSubject')))])])],1)]):_vm._e()],1)],1)],1),_c('v-layout',{staticClass:"mx-0 py-2",attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg2":"","xl2":""}},[_c('v-card-text',{staticClass:"pa-0 column-title cy-ticket-detail-state"},[_c('span',{staticClass:"pr-2"},[_vm._v(_vm._s(_vm.$t('ticketingSystem.taskDetails.state'))+":")]),_c('StateIcon',{attrs:{"state":_vm.state}})],1)],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg3":"","xl3":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',{staticClass:"vertical-align-sub"},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"grey darken-2","size":22}},'v-icon',attrs,false),on),[_vm._v("mdi-calendar-start")]),_c('span',{staticClass:"pl-2"},[_vm._v(_vm._s(_vm.formattedStartedOnDate))])],1)]}}],null,false,2061389354)},[_c('span',[_vm._v(_vm._s(_vm.$t('common.date.createdOn')))])])],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg2":"","xl2":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',{staticClass:"vertical-align-sub"},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"grey darken-2","size":22}},'v-icon',attrs,false),on),[_vm._v("mdi-calendar-end")]),_c('span',{staticClass:"pl-2 cy-ticket-detail-finished-on"},[_vm._v(_vm._s(_vm.formattedFinishedOn))])],1)]}}],null,false,733237022)},[_c('span',[_vm._v(_vm._s(_vm.$t('common.date.finishedOn')))])])],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg3":"","xl3":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',{staticClass:"vertical-align-sub"},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"grey darken-2","size":22}},'v-icon',attrs,false),on),[_vm._v("person_outline")]),_c('span',{staticClass:"pl-2"},[_c('UserName',{attrs:{"user":_vm.process.startUser}})],1)],1)]}}],null,false,823872202)},[_c('span',[_vm._v(_vm._s(_vm.$t('ticketingSystem.entry.assignee')))])])],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg2":"","xl2":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2 diagram-btn",attrs:{"icon":""},on:{"click":_vm.openDiagram}},'v-btn',attrs,false),on),[_c('img',{attrs:{"src":require("@/assets/icons/diagram.svg")}})])]}}],null,false,3972550031)},[_c('span',[_vm._v(_vm._s(_vm.$t('ticketingSystem.processes.displayDiagram')))])])],1)],1)],1),(_vm.showDiagramDialog)?_c('DiagramDialog',{ref:"diagram",attrs:{"process-definition-id":_vm.process.processDefinitionId,"process-instance-id":_vm.processInstanceId,"show-dialog":_vm.showDiagramDialog},on:{"closeDialog":function($event){_vm.showDiagramDialog = false},"openTask":_vm.openTask}}):_vm._e(),_c('ProcessSubjectDialog',{ref:"processSubjectDiagram",attrs:{"process":_vm.process,"show-dialog":_vm.showProcessSubjectDialog},on:{"closeDialog":function($event){_vm.showProcessSubjectDialog = false},"loadProcess":_vm.loadProcess}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }