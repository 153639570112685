import { render, staticRenderFns } from "./OrderLink.vue?vue&type=template&id=5ecc68a0&scoped=true&"
import script from "./OrderLink.vue?vue&type=script&lang=js&"
export * from "./OrderLink.vue?vue&type=script&lang=js&"
import style0 from "./OrderLink.vue?vue&type=style&index=0&id=5ecc68a0&scoped=true&lang=postcss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ecc68a0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardText } from 'vuetify/lib/components/VCard';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
installComponents(component, {VCardText,VListItem,VListItemContent})
