<template>
  <v-navigation-drawer v-model="drawer" temporary absolute>
    <v-list nav dense>
      <v-list-item class="px-2">
        <v-list-item-avatar>
          <v-icon size="25">account_circle</v-icon>
        </v-list-item-avatar>
        <v-list-item-title>{{ userName }}</v-list-item-title>
      </v-list-item>
      <v-divider></v-divider>
      <template v-for="(item, i) in navigationItems">
        <template v-if="hasPrivileges(item.requireRoles) && item.enabled">
          <template v-if="item.items">
            <v-divider :key="i + '-divider-up'"></v-divider>
            <v-list-group
              :key="i"
              :prepend-icon="item.icon"
              v-model="item.active"
              class="list-group"
            >
              <v-divider></v-divider>
              <v-list-item
                class="navigation-item pa-0"
                :key="item.title"
                slot="activator"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ $t('navigation.' + item.title) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-for="(subItem, i) in item.items"
                :key="i"
                @click="goToRoute(subItem)"
                class="navigation-subitem"
                v-bind:class="$route.path.startsWith(subItem.target) ? 'v-list-item--active' : ''"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ $t('navigation.' + subItem.title) }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action v-if="subItem.icon">
                  <v-icon v-text="subItem.icon"></v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list-group>
            <v-divider :key="i + '-divider-bottom'"></v-divider>
          </template>
          <template v-else>
            <v-list-item
              :key="i"
              color="primary"
              @click="goToRoute(item)"
              v-bind:class="$route.path.startsWith(item.target) ? 'v-list-item--active' : ''"
            >
              <v-list-item-action v-if="item.icon">
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t('navigation.' + item.title) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider :key="i + '-divider-bottom'"></v-divider>
          </template>
        </template>
      </template>
      <v-divider></v-divider>
      <v-list-item class="px-2">
        <v-btn
          x-small
          v-for="(item, index) in languageList"
          :key="'language-' + index"
          class="mx-4"
          @click="updateLanguage(item.value)"
        >
          {{ item.value.toUpperCase() }}
        </v-btn>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { languageList } from '@/config/options/languages'
import { navigationItems } from '@/config/options/navigationItems'
import { AuthenticationContext } from 'vue-adal'
import moment from 'moment'

export default {
  data () {
    return {
      navigationItems: navigationItems,
      params: {},
      languageList: languageList,
      propertyParams: {
        propertyId: 367001,
        propertyObjectId: 8
      },
      ticketParams: {
        processDefinitionKey: 'Z1_10',
        externalTicketId: '12345',
        activityId: 'task_termination'
      },
      ticketQuery: {
        name: 'Test'
      }
    }
  },
  props: {
    showNavigationDrawer: {
      type: Boolean,
      default: false
    }
  },
  created () {
    this.updateActiveItem()
    this.updateLanguage(this.$i18n.locale)
  },
  watch: {
    // call again the method if the route changes
    '$route': 'updateActiveItem',
    showNavigationDrawer: {
      handler (value, oldValue) {
        this.drawer = value
      }
    }
  },
  computed: {
    userRoles () {
      return AuthenticationContext && AuthenticationContext.user && AuthenticationContext.user.profile ? AuthenticationContext.user.profile.roles : null
    },
    userName () {
      return AuthenticationContext && AuthenticationContext.user && AuthenticationContext.user.profile ? AuthenticationContext.user.profile.name : ''
    },
    drawer: {
      get () {
        return this.showNavigationDrawer
      },
      set (value) {
        this.$emit('showNavigationDrawer', value)
      }
    }
  },
  methods: {
    hasPrivileges (role) {
      return this.userRoles ? this.userRoles.includes(role) : true
    },
    updateActiveItem () {
      this.navigationItems.map(x => {
        x.active = x.items && x.items.some(value => this.$route.path.startsWith(value.target))
      })
    },
    goToRoute (item) {
      this.params = {}
      this.query = {}
      if (item.propertyParams) {
        this.params = this.propertyParams
      }
      if (item.ticketParams) {
        this.params = this.ticketParams
        this.query = this.ticketQuery
      }
      if (this.$route.path !== item.target) {
        let routeItems = {
          path: item.target,
          name: item.name,
          params: {
            ...this.params
          },
          query: {
            ...this.query
          }
        }
        if (item.newTab) {
          let routeData = this.$router.resolve(routeItems)
          window.open(routeData.href, '_blank')
        } else {
          this.$router.push(routeItems)
        }
      }
    },
    updateLanguage (value) {
      this.$i18n.locale = value
      moment.locale(value)
      localStorage.setItem('language', value)
    }
  }
}
</script>

<style scoped lang="postcss">
</style>
