var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"grey lighten-5 pa-2",attrs:{"fluid":""}},[_c('v-layout',{staticClass:"row pt-0 pl-0 pr-0"},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('navigation.orders')))]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"search","label":_vm.$t('common.buttons.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.orders,"loading":_vm.ordersLoading,"search":_vm.search,"single-expand":true,"expanded":_vm.expandedOrders,"item-key":"orderId","must-sort":"","options":_vm.options,"footer-props":{ itemsPerPageOptions: _vm.itemsPerPageOptions, itemsPerPageText: _vm.itemsPerPageText }},on:{"update:options":function($event){_vm.options=$event},"current-items":_vm.closeAllExpanded},scopedSlots:_vm._u([{key:"item.amountSum",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.amountInfo)+" ")]}},{key:"item.orderedOn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.orderedOnFormatted)+" ")]}},{key:"item.emailSentOn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.emailSentOnFormatted)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mx-0",attrs:{"icon":"","disabled":item.emailLoading,"loading":item.emailLoading,"title":_vm.$t('heatingOil.orders.sendEmail')},on:{"click":function($event){$event.stopPropagation();return _vm.sendEmail(item)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-send")])],1),_c('v-btn',{staticClass:"mx-0",attrs:{"icon":"","title":_vm.$t('heatingOil.orders.downloadExcel'),"disabled":item.excelLoading,"loading":item.excelLoading},on:{"click":function($event){$event.stopPropagation();return _vm.downloadExcel(item)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("file_download")])],1),_c('v-btn',{staticClass:"mx-0",attrs:{"icon":"","title":_vm.$t('heatingOil.orders.details')},on:{"click":function($event){$event.stopPropagation();return _vm.toggleExpander(item)}}},[(item.expanded)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("remove_circle_outline")]):_c('v-icon',{attrs:{"color":"blue lighten-3"}},[_vm._v("add_circle_outline")])],1)]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"pl-2 pr-2 bg-white",attrs:{"colspan":"10"}},[_c('v-container',{attrs:{"fluid":"","grid-list-lg":""}},[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card-title',{staticClass:"pl-0"},[_vm._v(_vm._s(_vm.$t('heatingOil.orders.positions')))]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.subHeaders,"items":item.expandedOrders,"item-key":"tankId","must-sort":"","hide-default-footer":true,"loading":_vm.expandedLoading,"options":_vm.subOptions},on:{"update:options":function($event){_vm.subOptions=$event}},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.amountFormatted)+" ")]}},{key:"item.propertyInfo",fn:function(ref){
var item = ref.item;
return [_vm._v(" ["),_c('a',{attrs:{"href":""},on:{"click":function($event){return _vm.openMapProperty(item.propertyId)}}},[_vm._v(_vm._s(item.propertyId))]),_vm._v("] "),_c('span',[_vm._v(_vm._s(item.propertyInfo))])]}}],null,true)},[_c('template',{slot:"no-data"},[_c('v-alert',{attrs:{"value":true,"color":"error","icon":"warning"}},[_vm._v(" "+_vm._s(_vm.$t('table.noData'))+" ")])],1)],2)],1)],1)],1)],1)]}}],null,true)},[_c('v-progress-linear',{attrs:{"slot":"progress","color":"blue","indeterminate":""},slot:"progress"}),_c('template',{slot:"no-data"},[_c('v-alert',{attrs:{"value":true,"color":"error","icon":"warning"}},[_vm._v(" "+_vm._s(_vm.$t('table.noData'))+" ")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }