<template>
  <v-card class="mb-2 pa-0">
    <v-card-text class="pa-4">
      <v-icon color="primary" @click="toggle" class="float-right">{{ icon }}</v-icon>
      <v-card-subtitle class="pa-0 font-weight-bold subtitle-1">
        {{ $t('ticketingSystem.processes.reoccurringTicket.title') }}
      </v-card-subtitle>
    </v-card-text>
    <v-divider></v-divider>
    <div v-if="isOpen" class="px-2">
      <v-form v-model="valid">
        <v-switch  class="ml-2"
          :disabled="isProcessFinished"
          :label="$t('ticketingSystem.processes.reoccurringTicket.switchTitle')"
          :hint="$t('ticketingSystem.processes.reoccurringTicket.switchHint')"
          persistent-hint
          v-model="isReoccurring"
        >
        </v-switch>
        <v-text-field class="ml-2 mr-2"
           v-if="isReoccurring"
           :readonly="isProcessFinished"
           type="number"
           required
           :rules="[rules.required, rules.dueDateOnDayOfMonthMin, rules.dueDateOnDayOfMonthMax]"
           v-model="dueDateOnDayOfMonth"
          :label="$t('ticketingSystem.processes.reoccurringTicket.dueDateTitle')"
          :hint="$t('ticketingSystem.processes.reoccurringTicket.dueDateHint')"
          persistent-hint>
        </v-text-field>
        <v-card-actions>
          <v-btn
            v-if="!isProcessFinished"
            small
            @click="updateVariables"
            :disabled="!valid"
            :loading="processingRequest"
          >
            <v-icon color="primary" dark>done</v-icon>
          </v-btn>
        </v-card-actions>
      </v-form>
    </div>
  </v-card>
</template>

<script>
import {mapActions} from 'vuex'
import {getVariableValue} from "@/features/ticketing/helpers/ticketingHelper";

export default {
  name: 'ReoccurringProcessInfo',
  watch: {
    processVariables (value) {
      this.dueDateOnDayOfMonth = getVariableValue(value,'System_ReoccurringDueOnDayOfMonth_Long',1)
      this.isReoccurring = getVariableValue(value,'System_ReoccurringEnabled_Boolean',false)
      this.isOpen = this.isReoccurring
    }
  },
  mounted() {
    this.dueDateOnDayOfMonth = getVariableValue(this.processVariables,'System_ReoccurringDueOnDayOfMonth_Long',1)
    this.isReoccurring = getVariableValue(this.processVariables,'System_ReoccurringEnabled_Boolean',false)
    this.isOpen = this.isReoccurring
  },
  data () {
    return {
      valid: false,
      isOpen: false,
      isReoccurring: false,
      dueDateOnDayOfMonth: 1,
      processingRequest: false,
      rules: {
        required: (value) => !!value || this.$t('common.errors.required'),
        dueDateOnDayOfMonthMin: (value) => value && value > 0 || this.$t('ticketingSystem.processes.reoccurringTicket.dueDateOnDayOfMonthMinError'),
        dueDateOnDayOfMonthMax: (value) => value && value < 32 || this.$t('ticketingSystem.processes.reoccurringTicket.dueDateOnDayOfMonthMaxError')
      }
    }
  },
  props: {
    processVariables: {
      type: Array,
      default: () => []
    },
    processInstanceId: {
      type: String,
      default: ''
    },
    isProcessFinished: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    icon () {
      return this.isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
    }
  },
  methods: {
    ...mapActions('notifier', ['setSuccessSnackbar', 'setErrorSnackbar']),
    toggle () {
      this.isOpen = !this.isOpen
    },
    updateVariables () {
      let that = this
      let reoccurringDueDate = {
        variableName: 'System_ReoccurringDueOnDayOfMonth_Long',
        variableType: 'long',
        variableValue: that.dueDateOnDayOfMonth
      }
      let reoccurringEnabled = {
        variableName: 'System_ReoccurringEnabled_Boolean',
        variableType: 'boolean',
        variableValue: that.isReoccurring
      }
      this.processingRequest = true
      let data = { newLinks: [], variables: [reoccurringDueDate, reoccurringEnabled]}
      this.$prestigeTSProcess.saveProcessVariables(this.processInstanceId, data).then(() => {
        that.processingRequest = false
        that.onSuccessReloadData()
        return true
      }).catch(error => {
        that.processingRequest = false
        that.setErrorSnackbar(error)
      })
    },
    onSuccessReloadData () {
      this.$emit('loadDetails')
    }
  }
}
</script>

<style scoped lang="postcss">
/deep/ .v-input--is-readonly {
  cursor: not-allowed;
  pointer-events: none;
}
</style>
