import AbacusAdapter from '../../admin/AbacusAdapter'
import AdminSearch from '../../admin/AdminSearch'
import AmbiguousPropertyLocations from '../../admin/AmbiguousPropertyLocations'
import DataImport from '../../admin/data-import/DataImport'
import TicketsAdmin from '../../admin/TicketsAdmin'
import ChartExamples from  '../ChartExamples'

export const AdminRoutes = [
  {
    path: '/admin/tickets',
    name: 'TicketsAdmin',
    component: TicketsAdmin,
    meta: {
      title: 'ticketing',
      requireAuth: true,
      requireRoles: [
        'prestige-tools-admin'
      ]
    }
  },
  {
    path: '/admin/abacus-adapter',
    name: 'AbacusAdapter',
    component: AbacusAdapter,
    meta: {
      title: 'abacusAdapter',
      requireAuth: true,
      requireRoles: [
        'prestige-tools-admin'
      ]
    }
  },
  {
    path: '/admin/search',
    name: 'AdminSearch',
    component: AdminSearch,
    meta: {
      title: 'search',
      requireAuth: true,
      requireRoles: [
        'prestige-tools-admin'
      ]
    }
  },
  {
    path: '/admin/property-locations',
    name: 'AmbiguousPropertyLocations',
    component: AmbiguousPropertyLocations,
    meta: {
      title: 'propertyLocations',
      requireAuth: true,
      requireRoles: [
        'prestige-tools-reader'
      ]
    }
  },
  {
    path: '/admin/data-import',
    name: 'DataImport',
    component: DataImport,
    meta: {
      title: 'dataImport',
      requireAuth: true,
      requireRoles: [
        'prestige-tools-admin'
      ]
    }
  },
  {
    path: '/admin/charts',
    name: 'Charts',
    component: ChartExamples,
    meta: {
      title: 'charts',
      requireAuth: true,
      requireRoles: [
        'prestige-tools-admin'
      ]
    }
  }
]
