<template>
  <v-flex class="mt-3" xs12 sm12 md6 lg6 xl6>
    <v-card height="100%">
      <v-container fluid grid-list-xs>
        <v-card-title class="pa-0">{{ $t('tasks.craftsmen.title') }}</v-card-title>
        <v-card-text class="pa-0" v-if="form.craftsman">
          [{{ craftsman.addressId }}] {{ form.craftsman.firstName }} {{ form.craftsman.lastName }} {{ form.craftsman.description }}<br />
          {{ craftsman.zip }} {{ form.craftsman.place }}<br />
          {{ concatenatePhoneNumbers(craftsman) }}<br />
          {{ craftsman.email }}
        </v-card-text>
        <v-layout row wrap>
          <v-flex md6>
            <template v-if="abacusPropertyPriorityCraftsmen">
              <v-autocomplete
                v-model="select"
                :label="$t('tasks.craftsmen.select')"
                :loading="$apollo.queries.abacusPropertyPriorityCraftsmen.loading"
                :items="abacusPropertyPriorityCraftsmen"
                :disabled="readOnly"
                single-line
                return-object
                item-value="description"
                item-text="displayText"
                @change="changedValuePriority"
              ></v-autocomplete>
              <v-btn
                v-if="!selectManual"
                :disabled="readOnly"
                @click="selectManual = true"
              >
                {{ $t('tasks.craftsmen.selectManual') }}
              </v-btn>
            </template>
            <v-autocomplete
              v-if="selectManual || !abacusPropertyPriorityCraftsmen"
              v-model="select"
              single-line
              cache-items
              return-object
              item-text="displayText"
              item-value="addressId"
              :label="$t('tasks.craftsmen.search')"
              :loading="loading"
              :items="displayItems"
              :search-input.sync="search"
              :disabled="readOnly"
              @change="changedValue"
            ></v-autocomplete>
          </v-flex>
          <v-flex md6 :class="$vuetify.breakpoint.mdAndUp ? 'pl-3' : ''">
            <v-textarea
              v-model="form.craftsmenComment"
              :label="$t('tasks.craftsmen.comment')"
              :hint="$t('tasks.craftsmen.hint')"
              :disabled="readOnly"
              persistent-hint
              @input="updateFormValues"
            ></v-textarea>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </v-flex>
</template>

<script>
import { mapActions } from 'vuex'
import { ABACUS_ADDRESS_PRIORITY_CRAFTSMEN } from '../graphql-apollo/abacusProperty.gql'
import _cloneDeep from 'lodash.clonedeep'
import * as globalHelper from '@/helpers/globalHelper'

export default {
  name: 'AbacusCraftsmen',
  props: {
    clientId: {
      type: Number,
      default: null
    },
    propertyId: {
      type: Number,
      default: null
    },
    craftsman: {
      type: Object,
      default: () => {}
    },
    comment: {
      type: String,
      default: ''
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    search (val) {
      val && this.querySelections(val)
    },
    'propertyId': 'fetchInitialData'
  },
  data () {
    return {
      loading: false,
      priorityCraftsmenListLoading: false,
      craftsmenListSearch: null,
      blockedCraftsmenList: [],
      search: null,
      select: null,
      selectManual: false,
      items: [],
      displayItems: [],
      form: {
        craftsman: this.craftsman,
        craftsmenComment: this.comment
      }
    }
  },
  apollo: {
    abacusPropertyPriorityCraftsmen: {
      query: ABACUS_ADDRESS_PRIORITY_CRAFTSMEN,
      update (data) {
        data.abacusPropertyPriorityCraftsmen.forEach(item => {
          item.addressId = item.address.addressId
          item.firstName = item.address.firstName
          item.lastName = item.address.lastName
          item.line1 = item.address.line1
          item.zip = item.address.zip
          item.place = item.address.place
          item.mobile = item.address.mobile
          item.phone1 = item.address.phone1
          item.phone2 = item.address.phone2
          item.email = item.address.email
          item.comment = item.address.comment
          item.country = item.address.country
          item.dateOfBirth = item.address.dateOfBirth
          item.fax = item.address.fax
          item.line2 = item.address.line2
          item.line3 = item.address.line3
          item.salutation = item.address.salutation
          item.salutatoryAddress = item.address.salutatoryAddress
          item.salutatoryName = item.address.salutatoryName
          item.title = item.address.title
          item.addressId = item.address.addressId
          item.email = item.address.email
          item.displayText = '[' + item.address.addressId + '] ' + item.description
          item.displayText += ' - ' + item.address.lastName + ', ' + item.address.zip + ' ' + item.address.place
        })
        return data.abacusPropertyPriorityCraftsmen
      },
      variables () {
        return {
          propertyId: parseInt(this.propertyId)
        }
      },
      error (error) {
        this.setErrorSnackbar(error)
      },
      skip () {
        return isNaN(this.propertyId)
      },
      fetchPolicy: 'cache-first'
    }
  },
  created () {
    if (this.select) {
      this.search = this.form.craftsman.firstName || this.form.craftsman.lastName
      this.select = {
        addressId: this.form.craftsman.addressId,
        displayText: globalHelper.setDisplayText(this.form.craftsman)
      }
    }
  },
  mounted () {
    this.fetchInitialData()
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    concatenatePhoneNumbers: globalHelper.concatenatePhoneNumbers,
    fetchInitialData () {
      this.loadBlockedCraftsmen()
    },
    querySelections (searchString) {
      this.loading = true
      let that = this
      let queryParams = { query: { searchString: searchString, craftsman: true } }
      that.displayItems = []
      this.$abacus.getAllAddressesV2(undefined, queryParams).then(function (response) {
        if (response.data.length) {
          that.items = response.data
          that.items = globalHelper.sortByFirstNameAndLastName(that.items)
          that.items.forEach(item => {
            item.addressId = item.id
            delete item.id
            let line = globalHelper.setDisplayText(item)
            let displayListItem = {
              addressId: item.addressId,
              displayText: (that.blockedCraftsmenList.indexOf(item.id) >= 0 ? 'BLOCKLIST - ' : '') + line
            }
            that.displayItems.push(displayListItem)
          })
        }
        that.loading = false
      }).catch(function (error) {
        that.setErrorSnackbar(error)
        that.items = []
        that.displayItems = []
      })
    },
    loadBlockedCraftsmen () {
      if (this.clientId) {
        let that = this
        this.$abacusBlockedCraftsman.getAllBlockedCraftsmenByClientId(this.clientId).then(response => {
          that.blockedCraftsmenList = response.data
        }).catch(function (error) {
          that.blockedCraftsmenList = []
          that.setErrorSnackbar(error)
        })
      }
    },
    changedValue (value) {
      this.updateCraftsman(this.items, value)
    },
    changedValuePriority (value) {
      this.updateCraftsman(this.abacusPropertyPriorityCraftsmen, value)
      this.selectManual = false
    },
    updateCraftsman (list, value) {
      if (value.description) {
        this.form.craftsman =  _cloneDeep(list.find(x => x.addressId === value.addressId && x.description === value.description))
      } else {
        this.form.craftsman =  _cloneDeep(list.find(x => x.addressId === value.addressId))
      }
      this.updateFormValues()
    },
    updateFormValues () {
      this.$emit('updateFormValues', {
        ...this.form
      })
    }
  }
}
</script>
