<template>
  <v-card>
    <v-card-title>
      {{ $t('ticketingSystem.charts.userOpenedTasksByAssigneeTitle') }}
    </v-card-title>
    <v-card-subtitle>
      {{ $t('ticketingSystem.charts.uncrossPeople') }}
    </v-card-subtitle>
    <v-card-text v-if="hasProcessDefinitionKeysChecked">
      <v-progress-linear v-if="$apollo.loading" color="blue" indeterminate></v-progress-linear>
      <v-container fluid v-else-if="ticketingUserOpenTaskStatistics">
        <v-row>
          <v-col>
            <BarChart
              :chart-options="chart.options"
              :chart-data="chart.data"
              :height="600"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import BarChart from './../../../components/charts/BarChart'
import { getIntervalLabels } from '@/helpers/momentHelper'
import { mapActions, mapState } from 'vuex'
import { TICKETING_USER_OPEN_STATISTICS } from '@/features/ticketing-statistics/graphql-apollo/searchTicketing.gql'
import moment from 'moment'

export default {
  name: 'UserOpenTasksByAssignee',
  components: {
    BarChart
  },
  props: {
    filters: {
      type: Object,
      default: () => {}
    }
  },
  apollo: {
    ticketingUserOpenTaskStatistics: {
      query: TICKETING_USER_OPEN_STATISTICS,
      variables () {
        return {
          query: {
            from: moment(this.filters.from).format('YYYY-MM-DD'),
            to: moment(this.filters.to).format('YYYY-MM-DD'),
            interval: this.filters.interval.toUpperCase()
          }
        }
      },
      update (data) {
        data.ticketingUserOpenTaskStatistics.forEach(item => {
          item.openTaskStats.forEach(stats => {
            stats.date = stats.createdOn
          })
        })
        return data.ticketingUserOpenTaskStatistics
      },
      skip () {
        return this.filters === null || this.filters?.processDefinitionKeys?.length === 0
      }
    }
  },
  async created () {
    if (this.ticketingUsers?.length === 0) {
      await this.loadTicketingUsers()
    }
  },
  computed: {
    ...mapState('ticketingSystem', ['ticketingUsers']),
    chart () {
      return {
        options: {
          responsive: true,
          maintainAspectRatio: false,
          interaction: {
            mode: 'index',
            intersect: false
          },
          plugins: {
            datalabels: {
              backgroundColor: function(context) {
                return context.dataset.backgroundColor
              },
              display: function(context) {
                return context.dataset.data[context.dataIndex] > 0
              },
              borderRadius: 4,
              color: 'white',
              formatter: Math.round,
              padding: 3
            }
          },
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true
            }
          }
        },
        data: {
          labels: this.getLabels(),
          datasets: this.ticketingUserOpenTaskStatistics.map(user => {
            return {
              label: this.getUser(user.user),
              data: user.openTaskStats.map(x => x.numberOfOpenTasks),
              hidden: true
            }
          })
        }
      }
    },
    hasProcessDefinitionKeysChecked () {
      return this.filters && this.filters.processDefinitionKeys.length > 0
    }
  },
  methods: {
    ...mapActions('ticketingSystem', ['loadTicketingUsers']),
    getUser(id) {
      const found = this.ticketingUsers.find(item => item.userId === id)
      if (found) {
        return found.name
      } else if (id === '') {
        return this.$t('ticketingSystem.charts.unassigned')
      }
      return id
    },
    getLabels () {
      if (this.ticketingUserOpenTaskStatistics.length > 0) {
        return getIntervalLabels(this.ticketingUserOpenTaskStatistics[0].openTaskStats, this.filters?.interval.toUpperCase())
      } else {
        this.$emit('hideUserOpenTasksByAssigneeChart', false)
        return null
      }
    }
  }
}
</script>
