<template>
  <div>
    <v-card class="mt-5" v-for="(resident, index) in residents" v-bind:key="'resident-' + index">
      <v-card-title>{{ resident.firstName }} {{ resident.lastName }}</v-card-title>
      <v-card-text v-if="resident.employmentStatus === 'EMPLOYED'">
        <v-row>
          <v-col cols="12">
            <span class="text--subtitle-2 font-weight-bold">{{ $t('renting.publicForm.fields.personInfo.employment.job') }}:</span> <span>{{ resident.employer }} {{ resident.profession }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span class="subtitle-2 font-weight-bold">{{ $t('renting.publicForm.fields.personInfo.employment.employer') }}:</span> <span><a :href="'tel:' + resident.employerPhone">{{ resident.employerPhone }}</a> {{ resident.employerEmail }}</span>
          </v-col>
        </v-row>
        <p class="text-sm-h6 mt-5">{{ $t('renting.respondent') }}</p>
        <v-row>
          <v-col cols="12" sm="3">
            <v-text-field
              v-model="resident.respondentEmployerName"
              :label="$t('common.data.name')"
              :rules="[rules.required]"
              :disabled="formReadOnly"
              persistent-hint
              required
            />
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              :label="$t('renting.referenceFunction')"
              v-model="resident.respondentEmployerFunction"
              persistent-hint
              required
              :rules="[rules.required]"
              :disabled="formReadOnly"
            />
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              :label="$t('common.data.telPhone')"
              v-model="resident.respondentEmployerPhone"
              persistent-hint
              required
              :rules="[rules.required]"
              :disabled="formReadOnly"
            />
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              :label="$t('common.data.email')"
              v-model="resident.respondentEmployerEmail"
              persistent-hint
              required
              :rules="[rules.required, rules.email]"
              :disabled="formReadOnly"
            />
          </v-col>
        </v-row>

        <p class="text-sm-h6 mt-5">{{ $t('renting.referenceFeedback') }}</p>
        <v-row>
          <v-col cols="12" class="pb-0">
            <v-card-subtitle class="pa-0 mr-3">{{ $t('renting.referencesEmployer.employmentDate') }}: {{ formatDate(resident.employmentDate) }}</v-card-subtitle>
            <v-radio-group :rules="[rules.radioRequired]" row v-model="resident.employmentDateConfirmed" class="mt-2">
              <v-radio :label="$t('common.options.yes')" :value="true" :disabled="formReadOnly"></v-radio>
              <v-radio :label="$t('common.options.no')" :value="false" :disabled="formReadOnly"></v-radio>
              <template #message="{ message }">
                <span v-if="message === 'required'">{{ requiredMessage }}</span>
              </template>
            </v-radio-group>
          </v-col>

          <v-col cols="12" class="py-0">
            <v-card-subtitle class="pa-0 mr-3">{{ $t('renting.referencesEmployer.income') }} {{ resident.income + $t('renting.referencesEmployer.incomeMonthly') }}</v-card-subtitle>
            <v-radio-group :rules="[rules.radioRequired]" row v-model="resident.employmentIncomeConfirmed" class="mt-2">
              <v-radio :label="$t('common.options.yes')" :value="true" :disabled="formReadOnly"></v-radio>
              <v-radio :label="$t('common.options.no')" :value="false" :disabled="formReadOnly"></v-radio>
              <template #message="{ message }">
                <span v-if="message === 'required'">{{ requiredMessage }}</span>
              </template>
            </v-radio-group>
          </v-col>
          <v-col cols="12" class="py-0">
            <v-card-subtitle class="pa-0 mr-3">{{ $t('renting.referencesEmployer.notTerminatedAndUnlimited') }}</v-card-subtitle>
            <v-radio-group :rules="[rules.radioRequired]" row v-model="resident.employmentNotTerminatedAndUnlimited" class="mt-2">
              <v-radio :label="$t('common.options.yes')" :value="true" :disabled="formReadOnly"></v-radio>
              <v-radio :label="$t('common.options.no')" :value="false" :disabled="formReadOnly"></v-radio>
              <template #message="{ message }">
                <span v-if="message === 'required'">{{ requiredMessage }}</span>
              </template>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-textarea
          :label="$t('renting.comment')"
          v-model="resident.employmentComment"
          :disabled="formReadOnly"
        ></v-textarea>
      </v-card-text>
      <v-card-text v-else>
        <v-alert dense outlined type="info">
          {{ $t('renting.publicForm.fields.personInfo.employment.status_' + resident.employmentStatus) }}
        </v-alert>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import * as ticketingHelper from '@/features/ticketing/helpers/ticketingHelper'
import * as globalHelper from '@/helpers/globalHelper'
import * as validationHelper from '@/helpers/validationHelper'
import { getGraphQLVariable } from '@/helpers/graphQLHelper'
import { formatDate } from '@/helpers/formatterHelper'
import { mapActions } from 'vuex'
import { TICKETING_TICKET_EXECUTION } from '../../graphql-apollo/ticketingTicket.gql'

import { useRentingForm } from '@/use/useRentingForm'
const { loadRentingForm } = useRentingForm()

export default {
  name: 'RentingFormReferencesEmployer',
  props: {
    ticketingTask: {
      type: Object,
      default: () => {}
    },
    processVariables: {
      type: Array,
      default: () => {
      }
    },
    formReadOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      rentingForm: {},
      terminationFile: null,
      rules: {
        required: (value) => !!value || this.$t('common.errors.required'),
        email: (value) => { return validationHelper.validateEmail(value) },
        radioRequired: (value) => (value !== undefined && value !== '' && value !== null) || this.requiredMessage
      }
    }
  },
  apollo: {
    executionVariables: {
      query: TICKETING_TICKET_EXECUTION,
      skip () {
        return !this.ticketId || !this.executionId
      },
      error (error) {
        this.ticketLoadError = error.message
      },
      variables () {
        return {
          ticketId: this.ticketId,
          executionId: this.executionId
        }
      }
    }
  },
  watch: {
    'rentingFormId': {
      handler: 'getRentalFormData',
      immediate: true
    }
  },
  computed: {
    residents () {
      let residents = this.rentingForm?.content?.residents
      if (residents && this.token) {
        return residents.filter(resident => resident.employerToken === this.token)
      } else if (residents) {
        return residents
      } else {
        return []
      }
    },
    rentingFormId () {
      return this.getVariableValue('System_RentingFormId_Long')
    },
    ticketId () {
      return this.ticketingTask?.ticket?.ticketId
    },
    executionId () {
      return this.ticketingTask?.executionId
    },
    token () {
      return getGraphQLVariable(this.executionVariables?.variables, 'employerToken', 'stringValue')
    },
    requiredMessage () {
      return this.$t('common.errors.required')
    }
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    formatDate: formatDate,
    customFilter (item, queryText, itemText) {
      return globalHelper.propertyFilter(queryText, itemText)
    },
    async getRentalFormData () {
      this.rentingForm = await loadRentingForm(this.rentingFormId)
      await this.rentingForm?.content?.residents.map((resident) => {
        resident.respondentEmployerName = resident.respondentEmployerName || resident.employerName,
        resident.respondentEmployerFunction = resident.respondentEmployerFunction || resident.employerFunction,
        resident.respondentEmployerPhone = resident.respondentEmployerPhone || resident.employerPhone,
        resident.respondentEmployerEmail = resident.respondentEmployerEmail || resident.employerEmail
      })
      this.loading = false
    },
    async saveThirdPartyData () {
      await this.$prestigeRenting.updateRentingForm(this.rentingFormId, this.rentingForm.content)
    },
    getVariableValue (variableName) {
      return ticketingHelper.getVariableValue(this.processVariables, variableName)
    }
  }
}
</script>

<style scoped lang="postcss">
</style>
