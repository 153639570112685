<template>
  <v-container fluid class="pa-2">
    <v-card class="mb-2 pa-0">
      <v-card-text v-if="!isOpen" class="pa-4">
        <v-icon color="primary" @click="toggle" class="float-right">{{ icon }}</v-icon>
        <v-card-subtitle class="pa-0 font-weight-bold subtitle-1">
          {{ $t('dashboard.lookup') }}
        </v-card-subtitle>
      </v-card-text>
      <v-divider></v-divider>
      <v-layout row wrap class="pa-0 ma-0">
        <v-flex v-if="isOpen" xs12 sm12 md12 lg12>
          <v-tabs class="mt-1" v-model="activeTab">
            <v-tab @click="onPersonTabClicked" :key="personTab" id="cy-dashboard-tabs-person">
              <v-icon left>mdi-home-search</v-icon>
              <span>{{ $t('dashboard.person') }}</span>
            </v-tab>
            <v-tab @click="onPropertyTabClicked" :key="propertyTab" id="cy-dashboard-tabs-property">
              <v-icon left>mdi-account-search</v-icon>
              <span>{{ $t('dashboard.property') }}</span>
            </v-tab>
            <v-spacer></v-spacer>
            <v-icon color="primary" @click="toggle" class="pa-4 float-right">{{ icon }}</v-icon>
            <v-tab-item>
              <v-card>
                <DashboardPerson
                  ref="dashboardPerson"
                  :active-tab="activeTab"
                  @addressSelected="onAddressSelected"
                  @resetRoute="onResetRoute"
                />
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card>
                <DashboardProperty
                  ref="dashboardProperty"
                  @processInstanceCreated="setProcessInstanceCreated"
                  @addressSelected="onAddressSelected"
                  @propertySelected="onPropertySelected"
                  @resetRoute="onResetRoute"
                />
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-flex>
      </v-layout>
    </v-card>
    <v-layout wrap class="pa-0">
      <v-flex v-if="isAbacusAddress" xs12 sm12 :class="dataGridClasses">
        <AddressOverview
          v-show="!$apollo.loading"
          :address-details="abacusAddress"
          @addressSelected="onAddressSelected"
          @processInstanceCreated="setProcessInstanceCreated"
          @expandPanel="onExpandPanel"
          @updateProperties="onUpdateProperties"
        />
      </v-flex>
      <v-flex v-if="isAbacusAddress && hasAddressType && !isPartner" xs12 sm12 :class="dataGridClasses">
        <PersonTicketList
          ref="personTicketRef"
          :process-instance-created="processInstanceCreated"
        />
      </v-flex>
      <v-flex v-if="(isAbacusAddress || routePropertyId) && shouldShowPropertyTicketList" xs12 sm12 :class="propertyTicketListGridClasses">
        <PropertyTicketList
          ref="propertyTicketRef"
          :properties="properties"
          :process-instance-created="processInstanceCreated"
        />
      </v-flex>
      <v-flex v-if="(isAbacusAddress || routePropertyId) && shouldShowTaskList" xs12 sm12 :class="dataGridClasses">
        <TaskList
          ref="propertyTicketRef"
          :route-address-id="routeAddressId && routeAddressId.toString()"
          :route-address-type="routeAddressType"
          :property-id="propertyId && propertyId.toString()"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import DashboardPerson from './DashboardPerson'
import DashboardProperty from './DashboardProperty'
import AddressOverview from './AddressOverview'
import PersonTicketList from './PersonTicketList'
import PropertyTicketList from './PropertyTicketList'
import * as dashboardHelper from './helpers/dashboardHelper'

import TaskList from './TaskList'
import { mapActions } from 'vuex'
import { ABACUS_ADDRESS_WITH_PROPERTIES } from './graphql-apollo/abacusAddress.gql'

import _cloneDeep from 'lodash.clonedeep'

const DASHBOARD = 'Dashboard'
const DASHBOARD_PERSON_DETAILS = 'DashboardPersonDetails'
const DASHBOARD_PROPERTY_DETAILS = 'DashboardPropertyDetails'

export default {
  name: DASHBOARD,
  components: {
    DashboardPerson,
    DashboardProperty,
    AddressOverview,
    PersonTicketList,
    PropertyTicketList,
    TaskList
  },
  apollo: {
    abacusAddress: {
      query: ABACUS_ADDRESS_WITH_PROPERTIES,
      update (data) {
        data.abacusAddress.id = data.abacusAddress.addressId
        return data.abacusAddress
      },
      variables () {
        return {
          addressId: parseInt(this.routeAddressId)
        }
      },
      skip () {
        return !this.isAbacusAddress
      },
      fetchPolicy: 'cache-first'
    }
  },
  computed: {
    icon () {
      return this.isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
    },
    routeAddressId () {
      return this.$route.params.addressId
    },
    routeAddressType () {
      return this.$route.params.addressType
    },
    routePropertyId () {
      return this.$route.params.propertyId
    },
    hasAddressType () {
      return this.routeAddressType !== '' && this.routeAddressType !== null && this.routeAddressType !== undefined
    },
    isPartner () {
      return this.routeAddressType === 'partner'
    },
    shouldShowPropertyTicketList () {
      return (this.hasAddressType || this.routePropertyId)
    },
    shouldShowTaskList () {
      return this.hasAddressType || this.routePropertyId
    },
    dataGridClasses () {
      return dashboardHelper.dataGridClasses(this.hasAddressType, this.shouldShowPropertyTicketList, this.shouldShowTaskList)
    },
    propertyTicketListGridClasses () {
      return dashboardHelper.propertyTicketListGridClasses(this.hasAddressType, this.shouldShowPropertyTicketList, this.shouldShowTaskList)
    },
    isAbacusAddress () {
      return !isNaN(this.routeAddressId)
    }
  },
  data () {
    return {
      isOpen: true,
      personTab: 0,
      propertyTab: 1,
      activeTab: this.propertyTab,
      processInstanceCreated: 0,
      propertyId: null,
      properties: [],
      params: {}
    }
  },
  watch: {
    'routePropertyId': 'loadProperty'
  },
  created () {
    this.loadInitialDashboardDetails()
    this.loadProcessDefinitions()
    this.loadCandidateGroups()
    this.isOpen = localStorage.getItem('deepLink') === 'false' || this.$route.params.addressId === undefined
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    ...mapActions('abacus', ['loadPropertyDetails']),
    ...mapActions('ticketingSystem', ['loadProcessDefinitions', 'loadCandidateGroups']),
    toggle () {
      this.isOpen = !this.isOpen
      localStorage.setItem('deepLink', false)
    },
    loadInitialDashboardDetails () {
      if (this.routePropertyId) {
        this.loadProperty()
      }
    },
    async loadProperty () {
      this.activeTab = 1
      this.properties = []
      let propertyData = await this.loadPropertyDetails(this.routePropertyId)
      if (propertyData) {
        this.propertyId = propertyData.propertyId
        this.properties.push(propertyData)
      }
    },
    onAddressSelected (id, type) {
      this.params = Object.assign({}, { addressId: id, addressType: type === 'sublet' ? 'tenant' : type, processInstanceId: null })
      this.updateRoute(DASHBOARD_PERSON_DETAILS)
    },
    onPropertySelected (propertyId) {
      this.params = Object.assign({}, { propertyId: propertyId })
      this.updateRoute(DASHBOARD_PROPERTY_DETAILS)
    },
    setProcessInstanceCreated () {
      let that = this
      setTimeout(function () { that.processInstanceCreated++ }, 500)
    },
    updateRoute (routerName) {
      this.$router.push(
        {
          name: routerName,
          params: this.params
        }
      )
      localStorage.setItem('deepLink', false)
    },
    onResetRoute (resetPropertyId = false) {
      if (this.routeAddressId || this.routePropertyId) {
        this.$router.push({ name: DASHBOARD })
        localStorage.setItem('deepLink', false)
      }
      this.properties = []
      if (resetPropertyId) {
        this.propertyId = null
      }
    },
    onPersonTabClicked () {
      this.onResetRoute()
    },
    onPropertyTabClicked () {
      dashboardHelper.removeTableSelections('dashboardPerson')
      this.propertyId ? this.onPropertySelected(this.propertyId) : this.onResetRoute()
    },
    onExpandPanel (data) {
      if (data?.name === 'tenant') {
        this.properties = [{ propertyId: data.property.propertyId }]
      } else if (data?.value) {
        this.properties = data.value
      }
    },
    onUpdateProperties (addressProperties) {
      this.properties = addressProperties
    }
  }
}
</script>
<style scoped lang="postcss">
#processDetails {
  background-color: white;
  padding: 0px !important;
}
</style>
