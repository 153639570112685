<template>
  <v-flex class="pl-1 pr-1 mt-2" xs12 sm12 md12 lg4 xl4>
    <v-card height="100%">
      <v-container fluid grid-list-xs>
        <v-card-title class="pa-0">{{ title }}</v-card-title>
        <v-list class="dossier-documents">
          <div class="dossier-documents-types">
              <table class="pa-0 drag-wrapper">
                <draggable v-model="newDocumentTypes" tag="tbody" ghost-class="ghost">
                  <tr v-for="(item, index) in newDocumentTypes" :key="index">
                    <td width="200" class="text-left pt-1">{{ item.documentKey }}</td>
                    <td width="220" class="text-left pt-1">{{ $t('dossier.documentTypeConfiguration.addDocumentType.paperTray.options.' + item.paperTray) }} </td>
                    <td width="150" class="text-right">
                      <v-btn icon small @click.stop="editDocumentType(index)">
                        <v-icon small color="teal">edit</v-icon>
                      </v-btn>
                      <v-btn icon small color="pink" @click.stop="openDeleteDialog(index)">
                        <v-icon small>delete</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </draggable>
              </table>
            <div class="text-right">
              <v-btn icon class="mx-0 mt-2" @click.stop="addDocumentType()">
                <v-icon color="blue lighten-3">add_circle_outline</v-icon>
              </v-btn>
            </div>
          </div>
        </v-list>
      </v-container>
    </v-card>
    <DocumentTypeDialog
      :show-dialog="showDocumentTypeDialog"
      :disable-btn="disableBtn"
      :editing-document="editingDocument"
      :edit="edit"
      :id="id"
      @closeDialog="closeDocumentTypeDialog"
      @saveDocumentType="saveDocumentType"
      @saveEditedDocumentType="saveEditedDocumentType"
    />
    <ConfirmationDialog :data="deleteData" :show-dialog="showDeleteDialog" @closeDialog="closeDeleteDialog" @submitDialog="deleteDocumentType" />
  </v-flex>
</template>

<script>
import DocumentTypeDialog from './DocumentTypeDialog'
import ConfirmationDialog from '@/components/ConfirmationDialog'
import { mapActions } from 'vuex'
import draggable from 'vuedraggable'

export default {
  name: 'DossierDocument',
  components: {
    DocumentTypeDialog,
    ConfirmationDialog,
    draggable
  },
  data () {
    return {
      disableBtn: false,
      showDocumentTypeDialog: false,
      editingDocument: {},
      edit: false,
      showDeleteDialog: false,
      deleteItemIndex: null
    }
  },
  computed: {
    deleteData () {
      return {
        title: this.$t('dossier.documentTypeConfiguration.dialog.delete.title'),
        question: this.$t('dossier.documentTypeConfiguration.dialog.delete.question'),
        confirmButton: this.$t('common.buttons.delete'),
        action: 'deleteDocumentType'
      }
    },
    newDocumentTypes: {
      get: function () {
        return this.documentTypes
      },
      set: function (newValue) {
        this.updateDocumentTypes(newValue)
        return newValue
      }
    }
  },
  props: {
    documentTypes: {
      type: Array,
      default: () => []
    },
    id: {
      type: Number,
      default: null
    },
    title: {
      type: String,
      default: ''
    }
  },
  methods: {
    ...mapActions('notifier', ['setSuccessSnackbar', 'setErrorSnackbar']),
    addDocumentType (documentEntry) {
      this.editingDocument = {}
      this.edit = false
      this.openDocumentTypeDialog()
    },
    updateDocumentTypes (newDocumentTypes) {
      this.$emit('updateDocumentTypes', newDocumentTypes, this.id)
    },
    saveDocumentType (documentEntry) {
      this.newDocumentTypes.push(documentEntry)
      this.updateDocumentTypes(this.newDocumentTypes)
      this.closeDocumentTypeDialog()
    },
    saveEditedDocumentType (editedDocumentType) {
      this.newDocumentTypes = Object.assign(this.newDocumentTypes, this.newDocumentTypes.map(el => el.documentTypeId === editedDocumentType.documentTypeId ? editedDocumentType : el))
      this.closeDocumentTypeDialog()
    },
    editDocumentType (index) {
      this.editingDocument = Object.assign({}, this.newDocumentTypes[index])
      this.edit = true
      this.openDocumentTypeDialog()
    },
    openDeleteDialog (index) {
      this.showDeleteDialog = true
      this.deleteItemIndex = index
    },
    closeDeleteDialog () {
      this.showDeleteDialog = false
      this.deleteItemIndex = null
    },
    deleteDocumentType () {
      this.newDocumentTypes.splice(this.deleteItemIndex, 1)
      this.updateDocumentTypes(this.newDocumentTypes)
      this.closeDeleteDialog()
    },
    openDocumentTypeDialog () {
      this.showDocumentTypeDialog = true
    },
    closeDocumentTypeDialog () {
      this.edit = false
      this.editingDocument = {}
      this.showDocumentTypeDialog = false
      this.disableBtn = false
    }
  }
}
</script>

<style scoped lang="postcss">
/* draggable items */
table {
  border-collapse: collapse;
}

tr {
  border-radius: 0.2em;
}

tr:hover {
  cursor: pointer;
  box-shadow: 0 0 0 2px grey, inset 0 0 0 1px #ddd;
}

.ghost {
  opacity: 0.5;
  color: white;
  box-shadow: 0 0 0 2px grey, inset 0 0 0 1px #ddd;
  background:rgb(188, 211, 188);
}
</style>
