var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","grid-list-md":""}},[_c('v-slide-y-transition',{attrs:{"mode":"out-in"}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"md12":""}},[_c('v-card',{staticClass:"mt-1",attrs:{"height":"100%"}},[_c('PrintContainer',{attrs:{"corporations":_vm.selectedCorporationMappedIDs},on:{"sentToPrinter":_vm.sentToPrinter,"downloadFile":_vm.downloadFile}})],1)],1),_c('v-flex',{staticClass:"mt-2",attrs:{"md12":""}},[_c('v-card',[_c('v-layout',{staticClass:"row pr-4 pl-2"},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('navigation.corporations')))]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"search","label":_vm.$t('common.buttons.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.corporations,"loading":_vm.loading,"options":_vm.options,"hide-default-footer":true,"footer-props":{itemsPerPageOptions: _vm.itemsPerPageOptions},"search":_vm.search,"must-sort":"","show-select":"","item-key":"corporationId"},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.openCorporationDetail},scopedSlots:_vm._u([_vm._l((_vm.headers),function(h){return {key:("header." + (h.value)),fn:function(ref){
var header = ref.header;
return [_c('v-layout',{key:h.value},[_c('v-checkbox',{staticClass:"header-checkbox",attrs:{"primary":"","hide-details":"","input-value":header.filterable,"label":header.text},on:{"click":function($event){$event.stopPropagation();_vm.filterable[h.value] = !_vm.filterable[h.value]}}})],1)]}}}),{key:"items",fn:function(props){return [_c('tr',{attrs:{"active":props.selected}},[_c('td',[_c('v-checkbox',{attrs:{"primary":"","hide-details":"","input-value":props.selected},on:{"click":function($event){props.selected = !props.selected}}})],1)])]}},{key:"item.recipientsString",fn:function(ref){
var item = ref.item;
return [_c('ul',_vm._l((item.recipients),function(recipient){return _c('li',{key:recipient.recipientId},[_vm._v(" "+_vm._s(recipient.firstName)+" "+_vm._s(recipient.lastName)+", "+_vm._s(recipient.zip)+" "+_vm._s(recipient.place)),_c('br')])}),0)]}},{key:"item.propertiesString",fn:function(ref){
var item = ref.item;
return [_c('ul',_vm._l((item.properties),function(property){return _c('li',{key:property.propertyId},[_vm._v(" "+_vm._s(property.street)+", "+_vm._s(property.zip)+" "+_vm._s(property.place)),_c('br')])}),0)]}},{key:"item.dossierPrintHistoryString",fn:function(ref){
var item = ref.item;
return [_c('ul',_vm._l((item.dossierPrintHistory),function(printHistory){return _c('li',{key:printHistory.dossierPrintHistoryId},[_c('nobr',[_vm._v(_vm._s(printHistory.year)+": "+_vm._s(_vm.printHistoryDateFormatted(printHistory)))])],1)}),0)]}}],null,true),model:{value:(_vm.selectedCorporations),callback:function ($$v) {_vm.selectedCorporations=$$v},expression:"selectedCorporations"}},[_c('v-progress-linear',{attrs:{"slot":"progress","color":"blue","indeterminate":""},slot:"progress"}),_c('template',{slot:"no-data"},[_c('v-alert',{attrs:{"value":true,"color":"error","icon":"warning"}},[_vm._v(" "+_vm._s(_vm.$t('table.noData'))+" ")])],1)],2)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }