<template>
  <div>
    <v-list-item>
      <v-list-item-action v-if="isTaskModule">
        <v-checkbox
          v-model="sourceFile.attachInNotification"
          :title="$t('tasks.attachments.addToEmail')"
          @change="onUpdateFile(sourceFile)"
        ></v-checkbox>
      </v-list-item-action>
      <v-list-item-content v-if="error === null">
        <v-list-item-title>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">{{ sourceFile.fileName }}</span>
            </template>
            <span>{{ sourceFile.fileName }}</span>
          </v-tooltip>
        </v-list-item-title>
        <v-list-item-subtitle>{{ fileSizeConverted }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-content v-else class="red--text">
        {{ error.error }}
      </v-list-item-content>
      <v-list-item-action>
        <FileActions
          :deletable="true"
          :file="sourceFile"
          :file-name="sourceFile.fileName"
          :module-name="moduleName"
          @removeFile="onRemoveFile"
          @loadProcessVariables="onUpdateFile"
        />
      </v-list-item-action>
    </v-list-item>
    <v-divider></v-divider>
  </div>
</template>

<script>
import FileActions from '@/features/ticketing/components/FileActions'
import { mapActions } from 'vuex'
import { formatBytes } from '@/helpers/formatterHelper'

export default {
  name: 'FilePreview',
  components: {
    FileActions
  },
  props: {
    file: {
      type: Object,
      default: () => {}
    },
    moduleName: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      sourceFile: {},
      error: null,
      showDialog: false
    }
  },
  mounted () {
    if (this.isTaskModule) {
      this.fetchTaskMetadata()
    } else if (this.moduleName === 'email' || this.moduleName === 'emailDialog') {
      this.sourceFile = this.file
    }
  },
  computed: {
    isTaskModule () {
      return this.moduleName === 'task'
    },
    fileSizeConverted () {
      return formatBytes(this.sourceFile.size)
    },
    removeData () {
      return {
        title: this.$t('common.dialogs.removeFile.title'),
        question: this.$t('common.dialogs.removeFile.question'),
        confirmButton: this.$t('common.buttons.delete'),
        action: 'removeFile'
      }
    }
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    fetchTaskMetadata () {
      let that = this
      this.$prestigeTaskApiFile.getFileMetadataById(this.file.id).then(response => {
        that.sourceFile = response.data
      }).catch(function (error) {
        that.setErrorSnackbar(error)
      })
    },
    onRemoveFile () {
      this.$emit('removeFile', this.file)
    },
    openDialog () {
      this.showDialog = true
    },
    closeDialog () {
      this.showDialog = false
    },
    onUpdateFile (file) {
      this.sourceFile = file
      this.$emit('updateFile', file)
    }
  }
}
</script>
