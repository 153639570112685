<template>
  <v-layout row wrap>
    <v-flex lg12 md12 sm12 xs12>
      <EmailDialog
        ref="botEmailDialog"
        v-if="shouldShowEmailDialog"
        :process-instance-id="processInstanceId"
        :process-variables="processVariables"
        :email="emailReply"
        :email-recipients="emailRecipients"
        @closeDialog="shouldShowEmailDialog = false"
        @emailSent="loadTicketingHistory"
        @emailFileSent="onEmailFileSent"
      />
      <CommentDialog
        v-if="shouldShowCommentDialog"
        :process-instance-id="processInstanceId"
        :additional-links="additionalLinks"
        :task-id="taskId"
        @closeDialog="onCloseCommentDialog"
      />

      <v-card-subtitle class="pa-0 font-weight-bold subtitle-1">
        {{ $t('ticketingSystem.taskDetails.history') }}
      </v-card-subtitle>

      <v-timeline dense clipped align-top>
        <v-timeline-item class="mb-6" hide-dot>
          <v-btn dark color="brown" small class="mr-2 mb-2" @click.stop="openCommentDialog">
            {{ $t('ticketingSystem.messages.comment.actionButtonLabel') }}
          </v-btn>
          <v-btn dark color="blue-grey" small class="mb-2" @click.stop="openNewEmailDialog">
            {{ $t('ticketingSystem.messages.email.actionButtonLabel') }}
          </v-btn>
        </v-timeline-item>

        <template v-for="item in groupedMessagesArray">
          <v-timeline-item :key="'message-group-' + item.createdOn" class="mb-6" hide-dot>
            <span class="text-h5">{{ formatDateFunc(item.createdOn) }}</span>
          </v-timeline-item>

          <template v-for="message in item.messages">
            <TicketingEmail
              v-if="message.__typename === 'TicketingEmail'"
              :key="'message-item-' + message.id"
              :email="message"
              @openReplyDialog="openReplyEmailDialog"
            />
            <TicketingComment
              v-else-if="message.__typename === 'TicketingComment'"
              :process-instance-id="processInstanceId"
              :task-id="taskId"
              :key="'message-item-' + message.id"
              :comment="message"
              @reloadTicketingHistory="loadTicketingHistory"
              @loadProcessReferences="onLoadProcessReferences"
            />
            <TicketingMilestone
              v-else-if="message.__typename === 'TicketingMilestone'"
              :key="'message-item-' + message.milestoneId"
              :milestone="message"
            />
          </template>
        </template>

        <v-timeline-item v-if="!hasMessages && processDetails" class="mb-6" hide-dot >
          {{ $t('ticketingSystem.processes.noMessages') }}
        </v-timeline-item>
      </v-timeline>
    </v-flex>
  </v-layout>
</template>

<script>
import EmailDialog from './EmailDialog'
import CommentDialog from './CommentDialog'
import TicketingEmail from './TicketingEmail'
import TicketingComment from './TicketingComment'
import TicketingMilestone from './TicketingMilestone'
import { formatDate } from '@/helpers/formatterHelper'
import { TICKETING_HISTORY } from '../../graphql-apollo/ticketingHistory.gql'

import Vue from 'vue'
import { mapActions } from 'vuex'

export default {
  name: 'TicketingHistory',
  components: {
    EmailDialog,
    CommentDialog,
    TicketingEmail,
    TicketingComment,
    TicketingMilestone
  },
  data () {
    return {
      emailRecipients: [],
      groupedMessagesArray: [],
      timer: [],
      emailReply: {},
      shouldShowEmailDialog: false,
      shouldShowCommentDialog: false
    }
  },
  props: {
    taskId: {
      type: String,
      default: ''
    },
    processDetails: {
      type: Boolean,
      default: false
    },
    processInstanceId: {
      type: String,
      default: ''
    },
    processVariables: {
      type: Array,
      default: () => []
    },
    additionalLinks: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    'processInstanceId': 'changedTask',
    'personReferenceLinks': 'loadAddressesAndStoreEmails',
    'ticketingHistory': 'groupTicketingHistoryByDate'
  },
  computed: {
    hasMessages () {
      return this.groupedMessagesArray.length > 0
    },
    personReferenceLinks () {
      return this.additionalLinks.filter(x =>
        x.linkName === 'caretaker' ||
        x.linkName === 'tenant' ||
        x.linkName === 'craftsman' ||
        x.linkName === 'client' ||
        x.linkName === 'partner'
      )
    }
  },
  beforeDestroy () {
    this.clearInterval()
  },
  apollo: {
    ticketingHistory: {
      query: TICKETING_HISTORY,
      skip () {
        return !this.processInstanceId
      },
      error (error) {
        this.setErrorSnackbar(error.message)
      },
      variables () {
        return {
          ticketId: this.processInstanceId
        }
      }
    }
  },
  created () {
    this.setInterval()
  },
  methods: {
    ...mapActions('notifier', ['setSuccessSnackbar', 'setErrorSnackbar']),
    ...mapActions('abacus', ['loadAddressDetails']),
    formatDateFunc: formatDate,
     groupTicketingHistoryByDate () {
      this.groupedMessagesArray = this.getGroupedTicketingHistoryByDate(this.ticketingHistory.items)
      if (this.groupedMessagesArray && this.groupedMessagesArray.length > 0) {
        for (const [index, item] of this.groupedMessagesArray.entries()) {
          if (item?.messages?.length > 0) {
            let that = this
            item.messages.forEach(message => {
              that.addEmails(message.to)
              that.addEmails(message.cc)
              that.addEmails(message.bcc)
            })
            Vue.set(this.groupedMessagesArray, index, item)
          }
        }
      }
    },
    loadTicketingHistory () {
      if (this.processInstanceId) {
        this.$apollo.queries.ticketingHistory.refetch()
      }
    },
    loadAddressesAndStoreEmails () {
      if (this.personReferenceLinks.length > 0) {
        let that = this
        this.personReferenceLinks.forEach(reference => {
          if (reference && reference.linkValues.length > 0) {
            reference.linkValues.forEach(async addressId => {
              let addressDetails = await that.loadAddressDetails(addressId)
              if (addressDetails?.email) {
                let emailIndex = that.emailRecipients.findIndex(x => x.email === addressDetails.email)
                if (emailIndex === -1) {
                  let name = (addressDetails.firstName + ' ' + addressDetails.lastName).trim()
                  that.emailRecipients.push({
                    name,
                    email: addressDetails.email,
                    displayText: name ? name + ' ' + addressDetails.email : addressDetails.email
                  })
                }
              }
            })
          }
        })
        return true
      }
    },
    addEmails (emailsList) {
      if (emailsList && emailsList.length > 0) {
        emailsList.forEach(email => {
          let emailIndex = this.emailRecipients.findIndex(x => x.email === email)
          if (emailIndex === -1) {
            this.emailRecipients.push({ name: null, email, displayText: email })
          }
        })
      }
    },
    onEmailFileSent () {
      this.$emit('emailFileSent')
    },
    onLoadProcessReferences () {
      return this.$emit('loadProcessReferences')
    },
    changedTask () {
      this.groupedMessagesArray = []
      this.clearInterval()
      this.loadTicketingHistory()
    },
    setInterval () {
      if (this.$ticketingEnabled) {
        if (this.timer.length === 0) {
          this.timer.push(setInterval(this.loadTicketingHistory, 15000))
        }
      }
    },
    clearInterval () {
      if (this.$ticketingEnabled) {
        this.timer.forEach(timer => clearInterval(timer))
      }
      this.loadTicketingHistory()
    },
    getGroupedTicketingHistoryByDate (messages) {
      this.groupedMessagesArray = []
      if (messages && messages.length > 0) {
        const groupedMessages = messages.reduce((groupedMessages, message) => {
          const date = message.createdOn.split('T')[0]
          if (!groupedMessages[date]) {
            groupedMessages[date] = []
          }
          if (message.commentId) {
            message.id = message.commentId
          } else if (message.emailId) {
            message.id = message.emailId
          } else {
            message.id = 'undefined'
          }
          groupedMessages[date].push(message)
          return groupedMessages
        }, {})

        const groupedMessagesArray = Object.keys(groupedMessages).map((date) => {
          return {
            createdOn: date,
            messages: groupedMessages[date]
          }
        })
        return groupedMessagesArray
      } else {
        return []
      }
    },
    getCreatedOnTime (date) {
      return date.split('T')[1]
    },
    openNewEmailDialog () {
      this.emailReply = {}
      this.shouldShowEmailDialog = true
    },
    openReplyEmailDialog (message = {}) {
      this.emailReply = message
      this.shouldShowEmailDialog = true
    },
    openCommentDialog () {
      this.shouldShowCommentDialog = true
    },
    onCloseCommentDialog (reload = false) {
      this.shouldShowCommentDialog = false
      if (reload) {
        this.loadTicketingHistory()
        this.onLoadProcessReferences()
      }
    }
  }
}

</script>

<style scoped lang="postcss">
/deep/ .displayEditor {
  width: 100%;
  max-width: 100%;
  overflow: auto;
  border-left: 1px solid rgba(0, 0, 0, .3);
  padding: .6em;
  margin: .2em;
}
.baseline {
  align-items: baseline;
}
/deep/ .height-150 .editor {
  height: 150px;
}
/deep/ a.ticketing-user {
  pointer-events: none;
  cursor: unset;
}
/deep/ span.property-slot, /deep/ span.tenant-slot, /deep/ span.client-slot,
/deep/ span.caretaker-slot, /deep/ span.craftsman-slot {
  cursor: pointer;
}
/deep/ a.tenant::after {
  content: url('../../../../assets/icons/tenant.svg');
  vertical-align: sub;
  padding-left: 2px;
}
/deep/ a.caretaker::after {
  content: url('../../../../assets/icons/caretaker.svg');
  vertical-align: sub;
  padding-left: 2px;
}
/deep/ a.ticketing-user::after {
  content: url('../../../../assets/icons/user.svg');
  vertical-align: sub;
  padding-left: 2px;
}
/deep/ a.property::after {
  content: url('../../../../assets/icons/property.svg');
  vertical-align: sub;
  padding-left: 4px;
}
/deep/ a.client::after {
  content: url('../../../../assets/icons/client.svg');
  vertical-align: sub;
  padding-left: 4px;
}
/deep/ a.craftsmen::after, /deep/ a.craftsman::after {
  content: url('../../../../assets/icons/craftsman.svg');
  vertical-align: sub;
  padding-left: 4px;
}
</style>
