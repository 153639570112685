export function initResidentalForm () {
  return {
    desiredMoveInDate: '2023-01-01',
    residents: [
      {
        salutation: 'MR',
        lastName: 'Muster',
        firstName: 'Bob',
        dateOfBirth: '1980-04-01',
        hometown: 'Zürich',
        mobile: '079 123 45 67',
        phone1: '',
        phone2: '',
        email: 'bob@muster.ch',
        profession: 'Tester',
        income: '6000',
        employmentStatus: 'EMPLOYED',
        employer: 'Prestige Immobilien AG',
        employerName: 'Marco Heusser',
        employerFunction: 'Geschäftsführer',
        employerPhone: '044 316 15 15',
        employerEmail: 'info@prestige.ch',
        employmentDate: '2016-01-01',
        landlordName: 'Prestige, Berinastrasse 29, Zürich',
        landlordFunction: 'Verwaltung',
        landlordPhone: '044 316 15 15',
        landlordEmail: 'info@prestige.ch',
        creditRatingComment: 'Während langen Ferien',
        receivedDebtEnforcement: true,
        debtEnforcement: '',
        foreigner: false,
        country: 'CH',
        settlementPermit: null,
        foreignerResidencePermitType: null,
        currentApartmentStatus: 'RENTAL_APARTMENT',
        currentApartmentSameAsFirstResident: false,
        currentApartmentStreet: 'Mustergasse',
        currentApartmentStreetNumber: '11',
        currentApartmentZip: '8005',
        currentApartmentPlace: 'Zürich',
        currentApartmentNumberOfRooms: 4,
        currentApartmentRent: 2300,
        currentApartmentStartRentDate: '2005-01-01',
        orderCertificate: false,
        validateCertificate: false,
        debtEnforcementPerMail: false
      },
      {
        salutation: 'MRS',
        lastName: 'Muster',
        firstName: 'Eve',
        dateOfBirth: '1970-04-01',
        hometown: 'Zürich',
        mobile: '079 111 22 33',
        phone1: '',
        phone2: '',
        email: 'eve@muster.ch',
        profession: 'Tester',
        income: '2000',
        employmentStatus: 'EMPLOYED',
        employer: 'Prestige Immobilien AG',
        employerName: 'Marco Heusser',
        employerFunction: 'Geschäftsführer',
        employerPhone: '044 316 15 15',
        employerEmail: 'info@prestige.ch',
        employmentDate: '2020-01-01',
        creditRatingComment: '',
        receivedDebtEnforcement: false,
        debtEnforcement: '',
        foreigner: false,
        country: 'CH',
        settlementPermit: null,
        foreignerResidencePermitType: null,
        currentApartmentStatus: 'SAME_AS_FIRST_RESIDENT',
        orderCertificate: false,
        validateCertificate: false,
        debtEnforcementPerMail: true
      }
    ],
    newApartment: {
      apartmentConditionRate: '7',
      reasonForChange: 'Jobwechsel',
      numberOfAdults: 1,
      numberOfChildren: 0,
      hasPets: true,
      pet: 'Hund',
      dogBreed: 'Dalmatiner',
      hasMusicalInstruments: false,
      musicalInstrument: '',
      takeSubtenantsLater: false,
      renovationRequirement: 'Badezimmer sollte renoviert werden.',
      conclusionOfContract: true,
      additionalGarage: true,
      additionalParkingSpot: true,
      additionalMotorcycleParkingSpot: false
    },
    confirmation: true
  }
}

export function initParkingForm () {
  return {
    desiredMoveInDate: '2023-01-01',
    residents: [
      {
        salutation: 'MR',
        lastName: 'Muster',
        firstName: 'Bob',
        dateOfBirth: '1980-04-01',
        hometown: 'Zürich',
        mobile: '079 123 45 67',
        phone1: '',
        phone2: '',
        email: 'bob@muster.ch',
        employmentStatus: 'EMPLOYED',
        employer: 'Prestige Immobilien AG',
        profession: 'Tester',
        employmentDate: '2016-01-01',
        income: '6000',
        currentApartmentStreet: 'Mustergasse',
        currentApartmentStreetNumber: '11',
        currentApartmentZip: '8005',
        currentApartmentPlace: 'Zürich',
        receivedDebtEnforcement: true,
        creditRatingComment: 'Während langen Ferien',
      }
    ],
    newApartment: {
      renovationRequirement: 'Parkplatz sollte renoviert werden.',
      conclusionOfContract: true
    },
    confirmation: true
  }
}
