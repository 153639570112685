import gql from 'graphql-tag'

const TICKETING_HISTORY = gql`
  query ticketingHistory ($ticketId: ID!) {
    ticketingHistory(ticketId: $ticketId) {
      items {
        ticketId
        createdOn
        ... on TicketingEmail {
          emailId
          createdBy
          from
          to
          cc
          bcc
          messageId
          messageInReplyTo
          messageReferences
          subject
          messagePlain
          messageHtml
          isRead
          attachments {
            attachmentId
            fileId
            fileName
            location
            mimeType
            size
          }
        }
        ... on TicketingComment {
          commentId
          userId
          taskId
          message
        }
        ... on TicketingMilestone {
          ticketId
          createdOn
          milestoneId
          milestoneName
        }
      }
    }
  }
`

const TICKETING_HISTORY_EMAIL_ATTACHMENTS = gql`
  query ticketingHistory ($ticketId: ID!) {
    ticketingHistory(ticketId: $ticketId) {
      items {
        ticketId
        createdOn
        ... on TicketingEmail {
          attachments {
            attachmentId
            fileId
            fileName
            location
            mimeType
            size
          }
        }
      }
    }
  }
`


export { TICKETING_HISTORY, TICKETING_HISTORY_EMAIL_ATTACHMENTS }
