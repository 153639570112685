import StatisticsData from '../../statistics/StatisticsData'

export const StatisticsRoutes = [
  {
    path: '/statistics',
    name: 'StatisticsData',
    component: StatisticsData,
    meta: {
      title: 'statistics',
      requireAuth: true,
      requireRoles: [
        'prestige-tools-admin'
      ]
    }
  }
]
