import gql from 'graphql-tag'

const START_PROCESS = gql`
  mutation startProcessForExternalTicketId ($processDefinitionKey: String!, $externalTicketId: String!) {
    startProcessForExternalTicketId(startProcessRequest: {
      processDefinitionKey: $processDefinitionKey
      externalTicketId: $externalTicketId
    }) {
      ticketId
    }
  }
`

const UPDATE_TASK = gql`
  mutation updateTaskForExternalTicketId (
      $processDefinitionKey: String!,
      $externalTicketId: String!,
      $activityId: String!,
      $name: String!,
      $dueDate: String,
      $candidateGroups: [String]!
    ) {
    updateTaskForExternalTicketId(updateTaskRequest: {
      processDefinitionKey: $processDefinitionKey
      externalTicketId: $externalTicketId
      activityId: $activityId
      name: $name
      dueDate: $dueDate
      candidateGroups: $candidateGroups
    }) {
      taskId
      ticket {
        ticketId
      }
    }
  }
`

const COMPLETE_TASK = gql`
  mutation completeTaskForExternalTicketId (
      $processDefinitionKey: String!,
      $externalTicketId: String!,
      $activityId: String!
    ) {
    completeTaskForExternalTicketId(completeTaskRequest: {
      processDefinitionKey: $processDefinitionKey
      externalTicketId: $externalTicketId
      activityId: $activityId
    }) {
      taskId
      ticket {
        ticketId
      }
    }
  }
`
const SET_EMAIL_IS_READ = gql`
  mutation setEmailIsRead (
    $isRead: Boolean!,
    $emailId: String!
  ) {
    setEmailIsRead(emailUpdateRequest: {
      isRead: $isRead
      emailId: $emailId
    }) {
      ticketId
      isRead
    }
  }
`


export { START_PROCESS, UPDATE_TASK, COMPLETE_TASK, SET_EMAIL_IS_READ }
