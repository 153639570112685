<template>
  <div>
    <v-progress-linear indeterminate v-if="$apollo.loading"></v-progress-linear>
    <v-row dense v-else>
      <v-col cols="12">
        <RentalComponents
          :rent-components="rentComponents"
          :earliest-start="earliestStart"
          :deposit="deposit"
          :form-read-only="formReadOnly"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-radio-group
          row
          v-model="formViewReturned"
          @change="updatedField"
        >
          <v-list-item-subtitle class="subtitle-1 mb-2">
            {{ $t('ticketingSystem.processes.reletting.labels.formViewReturned') }}
          </v-list-item-subtitle>
          <v-radio class="pr-5 mr-0" :label="$t('common.options.yes')" value="YES" :disabled="formReadOnly" />
          <v-radio class="pr-5 mr-0" :label="$t('common.options.no')" value="NO" :disabled="formReadOnly" />
        </v-radio-group>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import * as ticketingHelper from '@/features/ticketing/helpers/ticketingHelper'
import { formatDate, formatAmount } from '@/helpers/formatterHelper'
import RentalComponents from '../process-specific-context/common/RentalComponents'

export default {
  name: 'RelettingConfigureAd',
  props: {
    processVariables: {
      type: Array,
      default: () => {}
    },
    formReadOnly: {
      type: Boolean,
      default: false
    }
  },
  components: {
    RentalComponents
  },
  data () {
    return {
      formViewReturned: {}
    }
  },
  computed: {
    rentComponents () {
      return this.getVariableValue('System_RentalContractComponents_JSON')
    },
    deposit () {
      return this.getVariableValue('System_ReLetting_Deposit_Long')
    },
    earliestStart () {
      return this.getVariableValue('System_ReLetting_EarliestStart_Date')
    },
    requiredMessage () {
      return this.$t('common.errors.required')
    }
  },
  watch: {
    'processVariables': {
      handler: 'initProcessVariables',
      immediate: true
    }
  },
  methods: {
    formatDate: formatDate,
    formatAmount: formatAmount,
    getVariableValue (variableName) {
      return ticketingHelper.getVariableValue(this.processVariables, variableName)
    },
    initProcessVariables () {
      this.formViewReturned = this.getVariableValue('System_ReLetting_FormViewingReturned_Boolean')
    },
    updatedField () {
      let formFields = [
        {
          formFieldId: 'System_ReLetting_FormViewingReturned_Boolean',
          formFieldType: 'enum',
          formFieldDefaultValue: this.formViewReturned
        }
      ]
      this.$emit('updateFormValues', { formFields })
    }
  }
}
</script>

<style scoped lang="postcss">
.abacus-logo {
  width: 80px;
  content: url('../../../../assets/icons/abacus-logo.svg');
}
</style>
