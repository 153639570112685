<template>
  <div>
    <v-row dense v-if="isLocalOrDev">
      <v-col>
        <v-autocomplete
          v-model="selectedProcessDefinition"
          :label="$t('ticketingSystem.list.filters.searchTicket')"
          :loading="processDefinitionsLoading"
          :items="createProcessDefinitions"
          cache-items
          dense
          single-line
          return-object
          item-text="processDefinitionName"
          item-value="processDefinitionKey"
          @change="updateProcessDefinition"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-form ref="subjectForm">
          <v-text-field
            v-model="processDefinition.processSubject"
            autofocus
            dense
            :label="$t('ticketingSystem.subject')"
            :rules="processDefinition.processSubject ? [rules.maxLength] : []"
            class="cy-create-ticket-subject"
            @change="updateProcessSubject"
          >
            <template #message="{ message }">
              <span v-if="message === 'maxLength'">{{ maxLengthMessage }}</span>
            </template>
          </v-text-field>
        </v-form>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <AutocompleteLinks
          class="comment-autocomplete"
          ref="descriptionAutocomplete"
          name="description"
          :label="description"
          :required="false"
          :validate-clicked="validateClicked"
          :additional-links="processReferencesLinks"
          @autocompleteLinksUpdated="onAutocompleteLinksUpdated"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AutocompleteLinks from '@/features/ticketing/components/AutocompleteLinks'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'TicketProcessDefinitions',
  components: {
    AutocompleteLinks
  },
  data () {
    return {
      isLocalOrDev: this.$config.ENVIRONMENT === 'local' || this.$config.ENVIRONMENT === 'dev',
      processDefinition: {
        processDefinitionId: '',
        processDefinitionKey: 'Z1_01',
        startFormType: '',
        processSubject: ''
      },
      selectedProcessDefinition: 'Z1_01',
      rules: {
        maxLength: (value) => (value.length <= 255) || 'maxLength'
      }
    }
  },
  props: {
    defaultSubject: {
      type: String,
      default: ''
    },
    validateClicked: {
      type: Boolean,
      default: false
    },
    processReferencesLinks: {
      type: Array,
      default: () => []
    },
    processDefinitionKey: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState('ticketingSystem', ['createProcessDefinitions', 'processDefinitionsLoading']),
    description () {
      return this.$t('ticketingSystem.startProcess.description')
    },
    maxLengthMessage () {
      return this.$t('ticketingSystem.startProcess.maxLength')
    }
  },
  async created () {
    this.$emit('loading', true)
    await this.loadProcessDefinitions()
    await this.loadCandidateGroups()
    this.selectedProcessDefinition = this.processDefinitionKey || 'Z1_01'
    if (this.defaultSubject) {
      this.processDefinition.processSubject = this.defaultSubject
    }
    this.updateProcessDefinition({processDefinitionKey: this.selectedProcessDefinition})
  },
  methods: {
    ...mapActions('ticketingSystem', ['loadCandidateGroups', 'loadProcessDefinitions', 'loadStartFormDefinition']),
    async updateProcessDefinition (value) {
      if (this.createProcessDefinitions && value) {
        let selectedProcessDefinition = this.createProcessDefinitions.find(x => x.processDefinitionKey === value.processDefinitionKey)
        if (selectedProcessDefinition) {
          this.processDefinition.processDefinitionKey = selectedProcessDefinition.processDefinitionKey
          this.processDefinition.processDefinitionId = selectedProcessDefinition.processDefinitionId
          this.processDefinition.startFormType = selectedProcessDefinition.startFormType
          this.$emit('updateProcessDefinition', this.processDefinition)
          if (this.processDefinition.startFormType === 'DYNAMIC' || this.processDefinition.startFormType === 'STATIC') {
            this.loadProcessStartForm(this.processDefinition.processDefinitionId)
          } else {
            this.$emit('updateForm', null)
          }
        }
      }
    },
    updateProcessSubject () {
      this.$emit('updateProcessDefinition', this.processDefinition)
    },
    async loadProcessStartForm (processDefinitionId) {
      let form = await this.loadStartFormDefinition(processDefinitionId)
      if (form) {
        this.$emit('loading', false)
        this.$emit('updateForm', form)
      }
    },
    onAutocompleteLinksUpdated (autocompleteLinks) {
      this.$emit('autocompleteLinksUpdated', autocompleteLinks)
    }
  }
}
</script>

<style scoped lang="postcss">
/deep/ .comment-autocomplete .editor {
  height: 360px;
}
</style>
