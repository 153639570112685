<template>
  <v-container fluid class="grey lighten-5 pt-2 pl-5 pr-5 pb-15">
    <v-form ref="form" v-if="!loading">
      <v-layout row wrap v-if="mappedProperty && mappedPropertyObject">
        <PropertyInfo
          :property="mappedProperty"
          :property-object="mappedPropertyObject"
        />
      </v-layout>
      <v-card-title class="pa-0 mt-3 mb-3">{{ $t('renting.interestedPerson.personal') }}</v-card-title>
      <v-layout row wrap class="pl-2">
        <v-flex xs12 sm12 md4 lg4 xl4 class="pr-2">
          <v-autocomplete
            :items="salutationItems"
            v-model="interestedPerson.salutatoryAddress"
            :label="$t('renting.interestedPerson.salutatoryAddress')"
          >
            <template slot="selection" slot-scope="{ item }">
              {{ $t('renting.interestedPerson.salutations.' + item.value) }}
            </template>
            <template slot="item" slot-scope="{ item }">
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('renting.interestedPerson.salutations.' + item.value) }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-text-field
            v-model="interestedPerson.salutatoryName"
            :label="$t('renting.interestedPerson.salutatoryName')"
          ></v-text-field>
          <v-text-field
            v-model="interestedPerson.firstName"
            :label="$t('renting.interestedPerson.firstName')"
            required
            :rules="[rules.required]"
          ></v-text-field>
          <v-text-field
            v-model="interestedPerson.lastName"
            :label="$t('renting.interestedPerson.lastName')"
            required
            :rules="[rules.required]"
          ></v-text-field>
          <DatePicker
            v-model="interestedPerson.dateOfBirth"
            class="datepicker-input"
            ref="inputDatePicker"
            :default-value="false"
            :label="$t('renting.interestedPerson.dateOfBirth')"
          ></DatePicker>
        </v-flex>
        <v-flex xs12 sm12 md4 lg4 xl4 class="pr-2">
          <v-text-field
            v-model="interestedPerson.addressLine1"
            :label="$t('renting.interestedPerson.addressLine1')"
          ></v-text-field>
          <v-text-field
            v-model="interestedPerson.addressLine2"
            :label="$t('renting.interestedPerson.addressLine2')"
          ></v-text-field>
          <v-text-field
            v-model="interestedPerson.addressLine3"
            :label="$t('renting.interestedPerson.addressLine3')"
          ></v-text-field>
          <v-text-field
            v-model="interestedPerson.zip"
            :label="$t('common.addressFields.zip')"
          ></v-text-field>
          <v-text-field
            v-model="interestedPerson.place"
            :label="$t('common.addressFields.place')"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm12 md4 lg4 xl4 class="pr-2">
          <v-text-field
            v-model="interestedPerson.phone1"
            :label="$t('renting.interestedPerson.phone1')"
          ></v-text-field>
          <v-text-field
            v-model="interestedPerson.phone2"
            :label="$t('renting.interestedPerson.phone2')"
          ></v-text-field>
          <v-text-field
            v-model="interestedPerson.mobile"
            :label="$t('renting.interestedPerson.mobile')"
          ></v-text-field>
          <v-text-field
            v-model="interestedPerson.email"
            :label="$t('renting.interestedPerson.email')"
            :rules="[rules.email]"
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-card-title class="pa-0 mt-2 mb-2">{{ $t('renting.additionaInformation') }}</v-card-title>
      <v-layout row wrap class="pa-2">
        <v-flex xs12 sm12 md8 lg8 xl8>
          <v-textarea
            :label="$t('renting.interestedPerson.comment')"
            v-model="interestedPerson.comment"
          ></v-textarea>
        </v-flex>
      </v-layout>
      <v-card-title class="pa-0 mt-2 mb-2">{{ $t('renting.status') }}</v-card-title>
      <v-layout row class="ml-1">
        <v-flex>
          <v-radio-group row v-model="selectedStatus" :rules="[rules.required]">
            <v-radio
              v-for="(item, i) in pendingStatusSelection"
              :key="i"
              :color="item === 'COMMITMENT' ? 'green' : 'orange'"
              class="pr-5 mr-0 grow"
              :label="$t('renting.statuses.' + item)"
              :value="item"
            />
          </v-radio-group>
        </v-flex>
        <v-flex>
          <v-radio-group col v-model="selectedStatus" :rules="[rules.requiredWithoutMessage]">
            <v-radio
              v-for="(item) in declinedStatusSelection"
              :key="item"
              class="pr-5 mr-0"
              color="red"
              :label="$t('renting.statuses.' + item)"
              :value="item"
            />
          </v-radio-group>
        </v-flex>
      </v-layout>
      <v-footer inset app height="auto">
        <v-btn color="success" @click="createInterestedPerson">
          <span>{{ $t('common.buttons.save') }}</span>
          <v-icon>send</v-icon>
        </v-btn>
      </v-footer>
    </v-form>
    <SpinnerOverlay :loading="loading" />
  </v-container>
</template>
<script>
import PropertyInfo from './components/PropertyInfo'
import DatePicker from '../../components/DatePicker'
import SpinnerOverlay from '@/components/SpinnerOverlay'
import * as validationHelper from '@/helpers/validationHelper'
import { mapActions } from 'vuex'

export default {
  name: 'InterestedPersonCreate',
  components: {
    PropertyInfo,
    DatePicker,
    SpinnerOverlay
  },
  data () {
    return {
      loading: true,
      mappedProperty: {},
      mappedPropertyObject: {},
      selectedStatus: 'UNDER_CLARIFICATION',
      interestedPerson: {},
      pendingStatusSelection: ['PENDING', 'UNDER_CLARIFICATION', 'COMMITMENT'],
      declinedStatusSelection: ['CANCELLATION_BY_INTERESTED_PERSON', 'ALREADY_RENTED', 'NOT_ELIGIBLE'],
      rules: {
        required: (value) => (!!value && value !== undefined) || this.$t('common.errors.required'),
        requiredWithoutMessage: (value) => (!!value && value !== undefined),
        email: (value) => {
          let valid = validationHelper.validateEmail(value)
          return valid
        }
      }
    }
  },
  computed: {
    salutationItems () {
      return [
        { value: 'DEAR_MRS' },
        { value: 'DEAR_MR' },
        { value: 'DEAR_LADIES_AND_GENTLEMAN' },
        { value: 'DEAR_FAMILY' },
        { value: 'GOOD_DAY' }
      ]
    }
  },
  created () {
    this.loadPropertyInfo()
  },
  methods: {
    ...mapActions('notifier', ['setSuccessSnackbar', 'setErrorSnackbar']),
    loadPropertyInfo () {
      let that = this
      this.$abacusProperty.getPropertyById(that.$route.params.propertyId).then(response => {
        if (response.data) {
          that.mapInfo(response.data)
        }
      })
    },
    mapInfo (response) {
      this.mappedProperty = this.mapPropertyInfo(response)
      if (response.objects && response.objects.length > 0) {
        let interestedPersonObject = response.objects.find(x => x.objectId.toString() === this.$route.params?.propertyObjectId?.toString())
        if (interestedPersonObject) {
          this.mappedPropertyObject = this.mapPropertyObjectInfo(interestedPersonObject)
        }
      }
      this.loading = false
    },
    mapPropertyInfo (property) {
      return {
        propertyId: parseInt(property.propertyId),
        propertyStreet: property.street,
        propertyZip: property.zip,
        propertyPlace: property.place,
        client: property.client,
        caretaker: property.caretaker,
        administrativeAreaId: property.administrativeAreaId,
        administrativeAreaText: property.administrativeAreaText
      }
    },
    mapPropertyObjectInfo (object) {
      return {
        houseId: object.houseId,
        houseStreet: object.houseStreet,
        objectId: object.objectId,
        objectText: object.objectText
      }
    },
    createInterestedPerson () {
      let that = this
      if (this.$refs.form.validate() && this.$route.params.propertyId && this.$route.params.propertyObjectId) {
        let requestBody = this.setRequestBody()
        this.$prestigeRenting.createInterestedPerson(requestBody).then(response => {
          if (response.status === 201) {
            that.setSuccessSnackbar(that.$t('renting.interestedPerson.messages.created'))
            let interestedPersonId = response.data
            let mainPath = `/renting/interested-persons/${interestedPersonId}`
            let routePath = that.$route.meta.abacus ? '/abacus' + mainPath : mainPath
            that.$router.push({ path: routePath })
          }
          return true
        }).catch(error => {
          that.setErrorSnackbar(error)
        })
      }
    },
    setRequestBody () {
      return {
        propertyId: this.$route.params.propertyId,
        objectId: this.$route.params.propertyObjectId,
        lastName: this.interestedPerson.lastName || '',
        firstName: this.interestedPerson.firstName || '',
        comment: this.interestedPerson.comment || '',
        clientId: this.interestedPerson.clientId || '',
        realEstateManagerId: this.interestedPerson.realEstateManagerId || '',
        salutatoryAddress: this.interestedPerson.salutatoryAddress || '',
        salutatoryName: this.interestedPerson.salutatoryName || '',
        addressLine1: this.interestedPerson.addressLine1 || '',
        addressLine2: this.interestedPerson.addressLine2 || '',
        addressLine3: this.interestedPerson.addressLine3 || '',
        zip: this.interestedPerson.zip || '',
        place: this.interestedPerson.place || '',
        phone1: this.interestedPerson.phone1 || '',
        phone2: this.interestedPerson.phone2 || '',
        mobile: this.interestedPerson.mobile || '',
        fax: this.interestedPerson.fax || '',
        email: this.interestedPerson.email || '',
        dateOfBirth: this.interestedPerson.dateOfBirth || '',
        personStatus: this.selectedStatus || ''
      }
    }
  }
}

</script>
