<template>
  <div>
    <v-card-title class="pl-0 mt-5">
      {{ $t('navigation.feedback') }}
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn :loading="excelLoading" @click="downloadExcel()" class="ml-1" v-bind="attrs" v-on="on" icon float>
            <v-icon color="primary">file_download</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('feedback.list.feedbackExcel') }}</span>
      </v-tooltip>
    </v-card-title>
    <v-layout>
      <v-flex xs12 sm12 md12 lg6 xl6>
        <v-card class="mr-2">
          <v-card v-if="feedbackStatsLoading" class="pa-5">
            <v-progress-linear indeterminate></v-progress-linear>
          </v-card>
          <v-list v-else class="pr-1">
            <v-list-item>
              <v-list-item-content><v-list-item-title>{{ $t('feedback.list.requested') }}:</v-list-item-title></v-list-item-content>
              <v-list-item-action v-if="feedbackStats.numberOfRequested">{{feedbackStats.numberOfRequested}} (100%)</v-list-item-action>
              <v-list-item-action v-else>{{ $t('table.noData') }}</v-list-item-action>
            </v-list-item>
            <v-list-item>
              <v-list-item-content><v-list-item-title>{{ $t('feedback.list.opened') }}:</v-list-item-title></v-list-item-content>
              <v-list-item-action v-if="feedbackStats.numberOfOpened">
                {{ feedbackStats.numberOfOpened }} ({{ displayPercentage(feedbackStats.numberOfOpened) }})
              </v-list-item-action>
              <v-list-item-action v-else>{{ $t('table.noData') }}</v-list-item-action>
            </v-list-item>
            <v-list-item>
              <v-list-item-content><v-list-item-title>{{ $t('feedback.list.submitted') }}:</v-list-item-title></v-list-item-content>
              <v-list-item-action v-if="feedbackStats.numberOfSubmitted">
                {{ feedbackStats.numberOfSubmitted }} ({{ displayPercentage(feedbackStats.numberOfSubmitted) }})
              </v-list-item-action>
              <v-list-item-action v-else>{{ $t('table.noData') }}</v-list-item-action>
            </v-list-item>
            <v-list-item>
              <v-list-item-content><v-list-item-title>{{ $t('feedback.list.scoreExecution') }}:</v-list-item-title></v-list-item-content>
              <v-list-item-action><v-rating readonly half-increments v-model="feedbackStats.averageScoreExecution"></v-rating></v-list-item-action>
            </v-list-item>
            <v-list-item>
              <v-list-item-content><v-list-item-title>{{ $t('feedback.list.scoreCarftsman') }}:</v-list-item-title></v-list-item-content>
              <v-list-item-action><v-rating readonly half-increments v-model="feedbackStats.averageScoreCraftsman"></v-rating></v-list-item-action>
            </v-list-item>
            <v-list-item>
              <v-list-item-content><v-list-item-title>{{ $t('feedback.list.scorePrestige') }}:</v-list-item-title></v-list-item-content>
              <v-list-item-action><v-rating readonly half-increments v-model="feedbackStats.averageScorePrestige"></v-rating></v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'FeedbackStats',
  data () {
    return {
      feedbackStats: {},
      feedbackStatsLoading: true,
      form: {
        from: null,
        to: null
      },
      excelLoading: false
    }
  },
  computed: {
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    loadFeedbackStats (from, to) {
      let that = this
      that.feedbackStatsLoading = true
      this.$prestigeFeedback.getFeedbackStatistics(from, to).then(function (response) {
        that.feedbackStats = response.data
        that.form.from = from
        that.form.to = to
        that.feedbackStatsLoading = false
        return true
      }).catch(error => {
        this.feedbackStats = {}
        that.feedbackStatsLoading = false
        that.setErrorSnackbar(error)
      })
    },
    displayPercentage (value) {
      return (this.feedbackStats.numberOfRequested ? Number(100 * value / this.feedbackStats.numberOfRequested).toFixed(2) : '') + '%'
    },
    downloadExcel () {
      this.excelLoading = true
      let that = this
      const config = { responseType: 'blob' }
      this.$prestigeFeedback.getFeedbackExcelReport(this.form.from, this.form.to, config).then(function (response) {
        const headers = response.headers
        const fileName = headers['content-disposition'] ? headers['content-disposition'].split('filename=')[1] : ''
        const file = {
          data: response.data,
          name: fileName ? fileName.substring(1, fileName.length - 1) : 'feedback-export.xlsx'
        }
        that.excelLoading = false
        that.downloadFile(file)
        return true
      }).catch(error => {
        that.excelLoading = false
        that.setErrorSnackbar(error)
      })
    },
    downloadFile (excelFile) {
      let fileDownload = require('js-file-download')
      fileDownload(excelFile.data, excelFile.name)
    }
  }
}
</script>
