<template>
  <v-dialog v-model="mutableShowDialog" scrollable max-width="1250px" @keydown.esc="$emit('closeDialog')" persistent>
    <v-card>
      <v-card-title>
        {{ $t('renting.publicForm.dialogTitle') }}
        <v-btn icon absolute right>
          <v-icon class="clear" color="primary" :size="28" @click.native="$emit('closeDialog')"/>
        </v-btn>
      </v-card-title>
      <v-card-text class="ma-0 pa-0">
        <ResidentsForm :form="form" :read-only="true"></ResidentsForm>
        <NewApartmentForm :form="newApartmentForm" :read-only="true" />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="$emit('closeDialog')" class="mt-2">{{ $t('common.buttons.close') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ResidentsForm from '@/features/renting/components/renting-forms/ResidentsForm'
import NewApartmentForm from '@/features/renting/components/renting-forms/NewApartmentForm'

export default {
  name: 'RentalApplicationDialog',
  components: {
    ResidentsForm,
    NewApartmentForm
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    newApartmentForm () {
      return Object.assign(
        this.form.newApartment,
        {
          numberOfAdults: parseInt(this.form.newApartment.numberOfAdults),
          numberOfChildren: parseInt(this.form.newApartment.numberOfChildren)
        }
      )
    },
    mutableShowDialog () {
      return this.showDialog
    }
  }
}
</script>
