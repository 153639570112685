import vm from '../main'

export function useTask () {
  function onNewTask (item) {
    let routeData
    let routeParams = {}
    routeParams['propertyId'] = item.propertyId?.toString() || item.property?.propertyId?.toString()
    if (item && item.objectId) {
      routeParams['propertyObjectId'] = item.objectId.toString()
    }
    routeData = vm.$router.resolve(({ name: 'TaskCreate', params: routeParams }))
    let url = routeData.href
    if (item && item.processInstanceId) {
      url = url + '?processInstanceId=' + item.processInstanceId
    }
    window.open(url, '_blank')
  }

  return {
    onNewTask
  }
}
