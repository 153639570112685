import gql from 'graphql-tag'

const RELETTING_TICKETING_TICKET = gql`
  query relettingTicketingTicket ($ticketId: ID!) {
    relettingTicketingTicket: ticketingTicket(ticketId: $ticketId) {
      ticketId
      variables {
      __typename
        ... on TicketingDateVariable {
          name
          dateValue: value
        }
        ... on TicketingLongVariable {
          name
          longValue: value
        }
        ... on TicketingRentComponentsVariable {
          name
          rentComponents: value {
            validFrom
            rentalComponent
            amount
            newAmount
            comment
          }
        }
      }
    }
  }
`

export { RELETTING_TICKETING_TICKET }
