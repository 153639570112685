<template>
  <v-card class="file-variables mb-2">
    <v-container fluid grid-list-xs class="pa-0">
      <v-card-text class="pb-0 mb-4">
        <v-icon color="primary" @click="toggle" class="float-right">{{ icon }}</v-icon>
        <v-card-subtitle class="pa-0 font-weight-bold subtitle-1">
          {{ $t('ticketingSystem.processes.variables.files') }}
        </v-card-subtitle>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text v-if="isOpen" class="pa-0">
        <TicketFileVariables
          ref="ticketFileVariables"
          :process-view="true"
          :process-instance-id="processInstanceId"
          :process-variables="processVariables"
          :created-on="createdOn"
          @addFileVariable="onAddSystemFileVariable"
          @removeFileVariable="onRemoveSystemFileVariable"
          @loadProcessVariables="$emit('loadProcessVariables')"
        />
      </v-card-text>
    </v-container>
  </v-card>
</template>

<script>
import TicketFileVariables from './TicketFileVariables'
import { mapActions } from 'vuex'

export default {
  name: 'ProcessFileVariables',
  components: {
    TicketFileVariables
  },
  props: {
    processVariables: {
      type: Array,
      default: () => []
    },
    processInstanceId: {
      type: String,
      default: null
    },
    createdOn: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isOpen: true
    }
  },
  computed: {
    icon () {
      return this.isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
    }
  },
  methods: {
    ...mapActions('notifier', ['setSuccessSnackbar', 'setErrorSnackbar']),
    toggle () {
      this.isOpen = !this.isOpen
    },
    onAddSystemFileVariable (file, formFieldId) {
      this.saveFileVariable({
        variableName: formFieldId,
        variableType: file?.variableType || 'azureFile',
        variableValue: file?.fileId || null
      })
    },
    onRemoveSystemFileVariable (formFieldId, file) {
      this.saveFileVariable({
        variableName: formFieldId,
        variableType: file?.variableType || 'azureFile',
        variableValue: null
      })
    },
    async saveFileVariable (newVariable) {
      let data = { newLinks: [], variables: [newVariable] }
      try {
        let response = await this.$prestigeTSProcess.saveProcessVariables(this.processInstanceId, data)
        if (response) {
          this.$emit('loadProcessVariables')
          this.setSuccessSnackbar(this.$t('ticketingSystem.messages.successfullySaved'))
        }
      } catch (error) {
        this.setErrorSnackbar(error)
      }
    }
  }
}
</script>

<style scoped lang="postcss">
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px lightgray;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(99, 99, 99, .7);
  background-color: #fff;
}
</style>
