<template>
  <v-dialog v-model="mutableShowDialog" max-width="500px" @keydown.esc="closeDialog" persistent>
      <v-card>
        <v-card-title>
          <v-btn icon absolute right>
            <v-icon class="clear" color="primary" :size="28" @click.native="closeDialog"/>
          </v-btn>
          <span class="headline" v-if="!edit">{{ $t('dossier.documentTypeConfiguration.addDocumentType.title') }}</span>
          <span class="headline" v-else>{{ $t('dossier.documentTypeConfiguration.addDocumentType.titleEdit') }}</span>
        </v-card-title>
        <v-card-text class="pt-0 pb-0">
          <v-container grid-list-xs>
            <v-form ref="form" v-model="valid">
              <v-radio-group v-model="form.source" :rules="[rules.required]" :label="$t('dossier.documentTypeConfiguration.addDocumentType.source.title')" class="mt-0">
                <v-radio
                  v-for="(item, i) in documentSourceOptions"
                  :key="i"
                  :label="$t('dossier.documentTypeConfiguration.addDocumentType.source.options.' + item.key)"
                  :value="item.value"
                />
              </v-radio-group>
              <v-text-field
                :label="$t('dossier.documentTypeConfiguration.addDocumentType.key')"
                v-model="form.documentKey"
                :rules="[rules.required]"
                class="mb-1"
              ></v-text-field>
              <v-text-field
                :label="$t('dossier.documentTypeConfiguration.addDocumentType.numberOfCopiesKey')"
                v-model="form.numberOfCopiesKey"
                class="mb-1"
              ></v-text-field>
              <v-radio-group v-model="form.paperTray" :rules="[rules.required]" :label="$t('dossier.documentTypeConfiguration.addDocumentType.paperTray.title')" class="mt-0">
                <v-radio
                  v-for="(item, i) in paperTrayOptions"
                  :key="i"
                  :label="$t('dossier.documentTypeConfiguration.addDocumentType.paperTray.options.' + item.value)"
                  :value="item.value"
                />
              </v-radio-group>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click.native="closeDialog" :disabled="disableBtn">{{ $t('common.buttons.cancel') }}</v-btn>
          <v-btn color="primary" :disabled="!valid || disableBtn" @click.native="saveDocumentType">{{ $t('common.buttons.save') }}</v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>
import * as dossierDocuments from '@/config/options/dossierDocument'

export default {
  name: 'DocumentTypeDialog',
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    disableBtn: {
      type: Boolean,
      default: false
    },
    editingDocument: {
      type: Object,
      default: () => {}
    },
    edit: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number,
      default: null
    }
  },
  watch: {
    showDialog (value) {
      if (value) {
        this.form = this.edit ? this.editForm() : this.initForm()
      }
    }
  },
  data () {
    return {
      documentSourceOptions: dossierDocuments.documentSourceOptions,
      paperTrayOptions: dossierDocuments.paperTrayOptions,
      form: {},
      valid: false,
      rules: {
        required: (value) => !!value || this.$t('common.errors.required')
      }
    }
  },
  methods: {
    editForm () {
      return {
        documentTypeId: this.editingDocument.documentTypeId,
        source: this.editingDocument.source,
        documentKey: this.editingDocument.documentKey,
        paperTray: this.editingDocument.paperTray,
        numberOfCopiesKey: this.editingDocument.numberOfCopiesKey
      }
    },
    initForm () {
      return {
        documentTypeId: null,
        source: 'GENERATED',
        documentKey: '',
        paperTray: '',
        numberOfCopiesKey: ''
      }
    },
    closeDialog () {
      this.reset()
      this.$emit('closeDialog')
    },
    reset () {
      this.$refs.form.resetValidation()
      this.form = this.initForm()
    },
    saveDocumentType () {
      if (this.$refs.form.validate()) {
        if (this.edit) {
          this.$emit('saveEditedDocumentType', this.form)
        } else {
          this.$emit('saveDocumentType', this.form)
        }
        this.reset()
      }
    }
  },
  computed: {
    mutableShowDialog () {
      return this.showDialog
    }
  }
}
</script>
