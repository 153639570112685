<template>
  <v-layout row class="pa-2">
    <v-flex
      v-for="(item, index) in propertyItems"
      class="mb-3"
      xs12 sm12
      :class="responsiveClass"
      :key="index"
    >
      <v-card
        v-if="item"
        :id="item.name"
        height="100%"
        class="px-4 py-2 mt-2 mr-3 card-content max-height"
        :class="setCardHighlightClass(item)"
        @click="showAddressDetails(item)"
      >
        <v-list-item :key="index" class="pa-0">
          <v-list-item-content class="pa-0">
            <v-card-title class="pa-0 mb-1">{{ $t('dashboard.' + item.name) }}</v-card-title>
          </v-list-item-content>
          <v-list-item-action>
            <ActionsMenu v-if="item.name !== 'realEstateManager'" :item="getItem(item)" :actions="actions" />
          </v-list-item-action>
        </v-list-item>
        <v-card-text class="pa-0" v-if="item.data.firstName || item.data.lastName">
          {{ item.data.firstName + ' ' + item.data.lastName }}
        </v-card-text>
        <v-card-text class="pa-0" v-if="item.data.line1">{{ item.data.line1 }}</v-card-text>
        <v-card-text class="pa-0" v-if="item.data.line2">{{ item.data.line2 }}</v-card-text>
        <v-card-text class="pa-0" v-if="item.data.line3">{{ item.data.line3 }}</v-card-text>
        <v-card-text class="pa-0" v-if="item.data.zip || item.data.place">
          {{ item.data.zip + ' ' + item.data.place }}
        </v-card-text>
        <v-card-text class="pa-0" v-if="item.data.phone">{{ item.data.phone }}</v-card-text>
        <v-card-text class="pa-0" v-if="item.data.mobile">{{ item.data.mobile }}</v-card-text>
        <v-card-text class="pa-0" v-if="item.data.email">{{ item.data.email }}</v-card-text>
      </v-card>
    </v-flex>
    <v-flex
      :class="responsiveClass"
      class="mb-3"
      v-if="priorityCraftsmanList.length > 0"
    >
      <v-card height="100%" class="pa-4 mt-2 craftsmen mr-3 max-height">
        <v-card-title class="pa-0 mb-1">{{ $t('dashboard.craftsmen') }}</v-card-title>
        <v-list-item v-for="(item, index) in priorityCraftsmanList" :key="index" class="pa-0 craftsman-item">
          <v-list-item-content class="pa-0">
            <v-card-text
              :class="routeAddressId && routeAddressId === item.address.addressId.toString() ? 'selected' : ''"
              @click="showAddressDetails(item, 'craftsman')" class="pa-0"
            >
              <span v-if="item.details">{{ item.details }}</span>
              <v-progress-circular v-else :value="20"></v-progress-circular>
            </v-card-text>
          </v-list-item-content>
          <v-list-item-action>
            <ActionsMenu :item="getCraftsmanItem(item)" :actions="actions" />
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-flex>
    <v-flex
      :class="responsiveClass"
      class="mb-3"
      v-if="validPropertyPartners.length > 0"
      >
      <v-card v-if="validPropertyPartners" height="100%" class="px-4 py-2 mt-2 mr-3 max-height craftsmen">
        <v-card-title class="pa-0 mb-1">{{ $t('dashboard.propertyPartners') }}</v-card-title>
        <v-list-item v-for="(item, index) in validPropertyPartners" :key="index" class="pa-0 craftsman-item">
          <v-list-item-content class="pa-0">
            <v-card-text class="pa-0" @click="showAddressDetails(item, 'partner')">
              <span v-if="item.details">{{ item.details }}</span>
            </v-card-text>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import ActionsMenu from '@/components/ActionsMenu'
import { removeTableSelections } from './helpers/dashboardHelper'
import { useAddressActions } from '@/use/useAddressActions'
import {isDateInTheFuture, isDateInThePast} from "@/helpers/momentHelper";

const { addressActions } = useAddressActions()

export default {
  name: 'DashboardPersonsOverview',
  components: {
    ActionsMenu
  },
  data() {
    return {
      isPartnerValid: true,
      longGrid: false
    }
  },
  props: {
    propertyItems: {
      type: Array,
      default: () => []
    },
    priorityCraftsmanList: {
      type: Array,
      default: () => []
    },
    propertyPartners: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    validPropertyPartners () {
      return this.propertyPartners.filter(propertyPartner => {
        return this.isValidPropertyPartner(propertyPartner)
      })
    },
    responsiveClass () {
      let hasPriorityCraftsman = this.priorityCraftsmanList
      let hasPropertyPartners = this.validPropertyPartners.length > 0
      let length = this.propertyItems && this.propertyItems.length
      if (hasPriorityCraftsman) {
        length++
      }
      if (hasPropertyPartners) {
        length++
      }
      return this.setLayoutClass(length)
    },
    routeAddressId () {
      return this.$route.params.addressId
    },
    routeAddressType () {
      return this.$route.params.addressType
    },
    actions () {
      return [
        { title: this.$t('dashboard.actions.newTicket'), icon: 'mdi-ticket', action: this.onNewTicket },
        ...addressActions
      ]
    }
  },
  methods: {
    setCardHighlightClass (item) {
      return this.routeAddressId && this.routeAddressId === item.data.id.toString() &&
        this.routeAddressType && this.routeAddressType === item.name ? 'selected' : ''
    },
    showAddressDetails (item, itemName = '') {
      removeTableSelections(this.tableClassName)
      localStorage.removeItem('dashboardObjectId')
      let id = itemName === 'craftsman' ? item.address.addressId.toString() : (itemName === 'partner' ? item.partner.addressId.toString() : item.data.id.toString())
      let name = itemName || item.name
      let active = this.routeAddressId && this.routeAddressId.toString() === id
      if (!active) {
        this.$emit('addressSelected', id, name)
      }
    },
    getItem (item) {
      item.subject = (item.data.firstName + ' ' + item.data.lastName).trim()
      return Object.assign(item, { linkName: item.name, linkValue: item.data.id.toString() })
    },
    getCraftsmanItem (item) {
      item.subject = item.description.trim()
      return Object.assign(item, { linkName: 'craftsman', linkValue: item.address.addressId.toString() })
    },
    onNewTicket (item) {
      let links = [{
        linkName: item.linkName,
        linkValues: item.linkValue ? [item.linkValue] : []
      }]
      let subject = item.subject
      this.$emit('openNewTicketDialog', links, subject)
    },
    setLayoutClass (length) {
      switch (length) {
        case 5:
          return 'md3 lg3-custom'
        case 4:
          return 'md3 lg3'
        case 3:
          return 'md4 lg4'
        case 2:
          return 'md6 lg6'
        default:
          return 'md12 lg12'
      }
    },
    isValidPropertyPartner(propertyPartner) {
      let result = true
      if (propertyPartner.validFrom !== null && isDateInTheFuture(propertyPartner.validFrom)) {
          result = false;
      }
      if (propertyPartner.validUntil !== null && isDateInThePast(propertyPartner.validUntil)) {
          result = false
      }
      return result
    }
  }
}
</script>

<style scoped lang="postcss">
.card-content, /deep/ .craftsmen .v-card__text {
  cursor: pointer;
}
.card-content:hover, /deep/ .craftsmen .v-card__text:hover, .selected {
  border-radius: 5px;
  background-color: rgb(228, 239, 250);
  color: #1976d2;
}
.max-height {
  max-height: 200px;
  overflow: auto;
}
.selected span {
  color: #1976d2;
}
.craftsman-item {
  height: 0px;
}
#realEstateManager {
  cursor: default;
  pointer-events: none;
}
@media (min-width: 1264px) and (max-width: 1920px) {
  .flex.lg3-custom {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
}
</style>
