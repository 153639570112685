import gql from 'graphql-tag'

const RENTING_VIEWINGS = gql`
  query ($rentingFormId: Int!) {
    rentingViewings(rentingRentalForm: $rentingFormId) {
      propertyId
      objectId
      rentalContractId
      href
      propertyObject {
        houseStreet
        property {
          place
        }
        objectTextShort
        floorTextShort
      }
      viewings {
        viewingDate
        firstName
        lastName
        impression
        interest
        internalRemark
        cancelled
      }
    }
  }
`

export { RENTING_VIEWINGS }
