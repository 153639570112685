<template>
  <div v-if="abacusAddress">
    <v-list-item>
      <v-list-item-content>
        <v-card-text class="pa-0">
          <p class="pa-0 ma-0">
            <span v-if="abacusAddress.addressId">[<a @click="goToDashboardAddressDetails(abacusAddress.addressId.toString())">{{ abacusAddress.addressId }}</a>]</span>
            <span class="pa-0 ma-0" v-if="abacusAddress.firstName || abacusAddress.lastName">
              {{ abacusAddress.firstName }} {{ abacusAddress.lastName }}
            </span>
          </p>
          <p class="pa-0 ma-0" v-if="abacusAddress.line1 || abacusAddress.line2">{{ abacusAddress.line1 || abacusAddress.line2 }}</p>
          <p class="pa-0 ma-0" v-if="abacusAddress.zip || abacusAddress.place">{{ abacusAddress.zip }} {{ abacusAddress.place }}</p>
          <p class="pa-0 ma-0" v-if="abacusAddress.phone1">{{ abacusAddress.phone1 }}</p>
          <p class="pa-0 ma-0" v-if="abacusAddress.phone2">{{ abacusAddress.phone2 }}</p>
          <p class="pa-0 ma-0" v-if="abacusAddress.mobile">{{ abacusAddress.mobile }}</p>
          <p class="pa-0 ma-0" v-if="abacusAddress.email">{{ abacusAddress.email }}</p>
        </v-card-text>
      </v-list-item-content>
      <v-list-item-action>
        <ActionsMenu
          :item="getItem(abacusAddress)"
          :actions="actions"
          :delete-action="deleteAction"
        />
      </v-list-item-action>
    </v-list-item>
  </div>
</template>

<script>
import ActionsMenu from '@/components/ActionsMenu'
import { useAddressActions } from '@/use/useAddressActions'
import { getFullName } from '@/helpers/globalHelper'
import { openDashboardAddress } from '@/helpers/routesHelper'
import { ABACUS_ADDRESS_LINKS } from '../../../dashboard/graphql-apollo/abacusAddress.gql'

const { addressActions } = useAddressActions()

export default {
  name: 'ProcessAddressLink',
  components: {
    ActionsMenu
  },
  props: {
    addressId: {
      type: String,
      required: true
    },
    addressType: {
      type: String,
      default: ''
    },
    deleteAction: {
      type: Object,
      default: () => {}
    },
    processInstanceId: {
      type: String,
      default: ''
    }
  },
  apollo: {
    abacusAddress: {
      query: ABACUS_ADDRESS_LINKS,
      variables () {
        return {
          addressId: parseInt(this.addressId)
        }
      },
      skip () {
        return !this.addressId
      },
      fetchPolicy: 'cache-first'
    }
  },
  computed: {
    actions () {
      return [
        { title: this.$t('dashboard.actions.newTicket'), icon: 'mdi-ticket', action: this.onNewTicket },
        ...addressActions
      ]
    }
  },
  methods: {
    getItem (item) {
      return Object.assign(item, { linkName: this.addressType, linkValue: this.abacusAddress.addressId.toString() })
    },
    onNewTicket (item) {
      let links = [{
        linkName: item.linkName,
        linkValues: item.linkValue ? [item.linkValue] : []
      }]
      let subject = getFullName(item)
      this.$emit('openNewTicketDialog', links, subject)
    },
    goToDashboardAddressDetails (addressId) {
      openDashboardAddress(addressId, this.addressType)
      localStorage.setItem('deepLink', true)
    },
    onOpenNewTicketDialog (links = [], subject, processDefinitionKey = null, variables = [], property = null, object = null) {
      this.$emit('openNewTicketDialog', links, subject, processDefinitionKey, variables, property, object)
    }
  }
}
</script>
