import { render, staticRenderFns } from "./TicketingComment.vue?vue&type=template&id=1747b94d&scoped=true&"
import script from "./TicketingComment.vue?vue&type=script&lang=js&"
export * from "./TicketingComment.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1747b94d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';
installComponents(component, {VBtn,VCard,VCardText,VCardTitle,VListItem,VListItemContent,VListItemIcon,VListItemSubtitle,VListItemTitle,VTimelineItem})
