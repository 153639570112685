<template>
  <v-dialog
    v-model="mutableShowDialog"
    max-width="1600px"
    :fullscreen="showFullscreen"
    @keydown.esc="closeDialog"
    @click:outside="closeDialog"
  >
    <v-card height="100%" class="pa-4">
      <v-card-title>
        <span class="headline">{{ $t('ticketingSystem.processes.diagram') }}</span>
        <v-btn icon absolute right>
          <v-icon class="clear" color="primary" :size="28" @click.native="closeDialog"/>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-0">
        <BpmnDiagram
          ref="bpmnDiagram"
          :dialog="true"
          :fullscreen="showFullscreen"
          :process-definition-id="processDefinitionId"
          :show-dialog="mutableShowDialog"
          :activities="processActivities"
          @openTask="openTask"
          @openFullscreen="openFullscreen"
          @closeFullscreen="closeFullscreen"
          @closeDialog="closeDialog"
        />
      </v-card-text>
      <v-card-actions class="mt-4" v-if="!showFullscreen">
        <v-spacer></v-spacer>
        <v-btn @click.native="closeDialog">{{ $t('common.buttons.close') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BpmnDiagram from '../../bpmn-diagram/BpmnDiagram'
import { mapActions } from 'vuex'

export default {
  name: 'DiagramDialog',
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    processDefinitionId: {
      type: String,
      default: ''
    },
    processInstanceId: {
      type: String,
      default: ''
    }
  },
  components: {
    BpmnDiagram
  },
  watch: {
    'routeTaskId': 'loadProcessActivities',
    'processInstanceId': 'loadProcessActivities',
    showDialog (val) {
      if (val && this.$refs.bpmnDiagram) {
        this.showFullscreen = false
      }
    }
  },
  data () {
    return {
      showFullscreen: false,
      processActivities: []
    }
  },
  computed: {
    routeTaskId () {
      return this.$route.params.taskId || this.$route.query.taskId
    },
    mainProcessInstanceId () {
      return this.$route.params.processInstanceId || this.processInstanceId
    },
    mutableShowDialog () {
      return this.showDialog
    }
  },
  created () {
    this.loadProcessActivities()
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar', 'setSuccessSnackbar']),
    closeDialog () {
      this.$emit('closeDialog')
    },
    openFullscreen () {
      this.showFullscreen = true
    },
    closeFullscreen () {
      this.showFullscreen = false
    },
    openTask (taskId, taskFinished) {
      this.$emit('openTask', taskId, taskFinished)
    },
    loadProcessActivities () {
      let that = this
      if (this.mainProcessInstanceId) {
        this.$prestigeTSProcess.getActivitiesForProcess(this.mainProcessInstanceId).then(function (response) {
          if (response.data) {
            that.processActivities = response.data
          }
          return true
        }).catch(error => {
          that.setErrorSnackbar(error)
        })
      }
    }
  }
}
</script>

<style scoped lang="postcss">
/deep/ .diagram-container {
  height: 400px;
}
/deep/ .v-dialog--fullscreen .diagram-container {
  height: 80vh;
}
</style>
