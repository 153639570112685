<template>
  <v-flex class="mt-3" xs12 sm12 md3 lg3 xl3>
    <v-card height="100%">
      <v-container fluid grid-list-xs>
        <v-card-title class="pa-0">{{ $t('tasks.info.caretaker') }}</v-card-title>
        <v-card-text class="pa-0" v-if="form.caretaker">
          [{{ form.caretaker.addressId }}] {{ form.caretaker.firstName }} {{ form.caretaker.lastName }} {{ form.caretaker.line1 }}<br />
          {{ form.caretaker.line2 }}<br />
          {{ form.caretaker.zip }} {{ form.caretaker.place }}<br />
          {{ concatenatePhoneNumbers(form.caretaker) }}<br />
          {{ form.caretaker.email }}
        </v-card-text>
        <v-autocomplete
          v-model="select"
          single-line
          cache-items
          return-object
          item-text="displayText"
          item-value="addressId"
          :label="$t('common.buttons.search')"
          :loading="loading"
          :items="displayItems"
          :search-input.sync="search"
          :disabled="readOnly"
          @change="changedValue"
        ></v-autocomplete>
      </v-container>
    </v-card>
  </v-flex>
</template>

<script>
import * as globalHelper from '@/helpers/globalHelper'
import { mapActions } from 'vuex'

export default {
  name: 'AbacusCaretaker',
  watch: {
    search (val) {
      val && this.querySelections(val)
    }
  },
  props: {
    caretaker: {
      type: Object,
      default: () => {}
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      search: null,
      select: null,
      items: [],
      displayItems: [],
      form: {
        caretaker: null
      }
    }
  },
  created () {
    if (this.caretaker) {
      this.search = this.caretaker.firstName || this.caretaker.lastName
      this.select = {
        addressId: this.caretaker.addressId,
        displayText: globalHelper.setDisplayText(this.caretaker)
      }
    }
  },
  mounted () {
    this.setForm()
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    concatenatePhoneNumbers: globalHelper.concatenatePhoneNumbers,
    async setForm () {
      this.form.caretaker = this.caretaker
    },
    querySelections (searchString) {
      this.loading = true
      let that = this
      let queryParams = { query: { searchString: searchString, caretaker: true } }
      that.displayItems = []
      this.$abacus.getAllAddressesV2(undefined, queryParams).then(function (response) {
        if (response.data.length) {
          that.items = response.data
          that.items = globalHelper.sortByFirstNameAndLastName(that.items)
          that.items.forEach(item => {
            item.addressId = item.id
            delete item.id
            let displayListItem = {
              addressId: item.addressId,
              displayText: globalHelper.setDisplayText(item)
            }
            that.displayItems.push(displayListItem)
          })
        }
        that.loading = false
      }).catch(function (error) {
        that.items = []
        that.displayItems = []
        that.setErrorSnackbar(error)
      })
    },
    changedValue () {
      this.form.caretaker = this.items.find(x => x.addressId === this.select.addressId)
      this.updateFormValues()
    },
    updateFormValues () {
      this.$emit('updateFormValues', {
        ...this.form
      })
    }
  }
}
</script>
