import gql from 'graphql-tag'

const ABACUS_ADDRESS_WITH_PROPERTIES = gql`
  query abacusAddress ($addressId: Int) {
    abacusAddress(
    addressId: $addressId
    ) {
      addressId
      firstName
      lastName
      line2
      zip
      place
      phone1
      phone2
      mobile
      email
      isCraftsman
      isClient
      isTenant
      isCaretaker
      isSublet
      isPartner
      isContractPartner
      clientProperties {
        propertyId
        street
        zip
        place
      }
      caretakerProperties {
        propertyId
        street
        zip
        place
      }
      craftsmanPriorityProperties {
        propertyId
        street
        zip
        place
      }
      craftsmanBlockedProperties {
        propertyId
        street
        zip
        place
      }
      tenantObjects {
        objectId
        houseStreet
        objectText
        objectTextShort
        floorText
        rentalContractId
        property {
          propertyId
          street
          realEstateManager {
            firstName
            lastName
          }
        }
        currentTenantContractStart
        currentTenantContractEnd
      }
      subletObjects {
        objectId
        objectText
        floorText
        rentalContractId
        property {
        propertyId
        street
        realEstateManager {
            firstName
            lastName
          }
        }
      }
      partnerProperties {
        propertyId
        street
        zip
        place
      }
    }
  }
`
const ABACUS_ADDRESS_ITEMS = gql`
  query abacusAddress ($query: AbacusAddressSearchQuery! $page: Int $size: Int) {
    abacusAddresses(
      query: $query
      page: $page
      size: $size
    ) {
      items {
        addressId
        firstName
        lastName
        line2
        zip
        place
        email
        phone1
        phone2
        mobile
        isCraftsman
        isClient
        isTenant
        isCaretaker
        isSublet
        isContractPartner
      }
      totalItems
    }
  }
`
const ABACUS_ADDRESS_LINKS = gql`
  query abacusAddress ($addressId: Int) {
    abacusAddress(
    addressId: $addressId
    ) {
      addressId
      firstName
      lastName
      line2
      zip
      place
      phone1
      phone2
      mobile
      email
    }
  }
`

const ABACUS_ADDRESS_TENANT = gql`
  query abacusAddress ($addressId: Int) {
    abacusAddress(
    addressId: $addressId
    ) {
      addressId
      firstName
      lastName
      line2
      zip
      place
      phone1
      phone2
      mobile
      email
      tenantObjects {
        objectId
        objectText
        objectTextShort
        floorText
        houseStreet
        rentalContractId
        property {
          propertyId
        }
        currentTenantContractStart
        currentTenantContractEnd
      }
      subletObjects {
        objectId
        objectText
        objectTextShort
        floorText
        houseStreet
        rentalContractId
        property {
          propertyId
          street
          realEstateManager {
            firstName
            lastName
          }
        }
      }
    }
  }
`
const ABACUS_ADDRESS_PRIORITY_CRAFTSMEN = gql`
  query abacusPropertyPriorityCraftsmen ($propertyId: Int) {
    abacusPropertyPriorityCraftsmen(
      propertyId: $propertyId
    ) {
      propertyId
      address {
        addressId
        firstName
        lastName
        line1
        zip
        place
      }
      description
    }
  }
`


export {
  ABACUS_ADDRESS_WITH_PROPERTIES,
  ABACUS_ADDRESS_ITEMS, 
  ABACUS_ADDRESS_LINKS,
  ABACUS_ADDRESS_TENANT,
  ABACUS_ADDRESS_PRIORITY_CRAFTSMEN
}