<template>
  <div id="mapContainer">
    <h2>{{ $t('navigation.map') }}</h2>
    <GmapMap
      ref="mapRef"
      :center="{lat: 47.3362615, lng: 8.5130041}"
      :zoom="10"
      class="gmap-dimensions"
    >
      <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">
          {{ address }}
      </gmap-info-window>
      <template v-if="currentConstructionProject">
        <GmapMarker
          v-for="(m, index) in properties"
          :key="m.property.propertyId"
          :position="{lat: parseFloat(m.property.lat), lng: parseFloat(m.property.lng)}"
          :icon="getSiteIcon(1)"
          :clickable="true"
          :draggable="false"
          :z-index="5"
          @click="displayPropertyInfoWindow(m.property, index)"
        ></GmapMarker>
        <GmapCircle
          v-if="currentConstructionProject && currentConstructionProject.locationPrecision === 1"
          :center="{lat: parseFloat(currentConstructionProject.lat), lng: parseFloat(currentConstructionProject.lng)}"
          :radius="maxDistance"
          :options="{fillColor: '#fff', fillOpacity: .6, strokeColor: '#313131', strokeOpacity: .4, strokeWeight: .8}"
        ></GmapCircle>
      </template>
      <GmapMarker
        v-for="m in constructionProjects.buildingProjects"
        :key="m.buildingProjectId"
        :position="{lat: parseFloat(m.lat), lng: parseFloat(m.lng)}"
        :icon="getSiteIcon(2, m.buildingProjectId)"
        :clickable="true"
        :draggable="false"
        @click="showConstructionProjectInfoWindow(m, m.buildingProjectId)"
      ></GmapMarker>
    </GmapMap>
  </div>
</template>

<script>
import Vue from 'vue'
import GmapCircle from 'vue2-google-maps/dist/components/circle'
import { mapActions } from 'vuex'
import { CONSTRUCTION_PROJECTS } from '@/config/options/routes/mapRoutes'

Vue.component('GmapCircle', GmapCircle)

export default {
  name: 'ConstructionProjectsMap',
  data () {
    return {
      address: '',
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35
        }
      }
    }
  },
  props: {
    constructionProjects: {
      type: Object,
      default: () => {}
    },
    currentConstructionProject: {
      type: Object,
      default: () => {}
    },
    properties: {
      type: Array,
      default: () => []
    },
    maxDistance: {
      type: Number
    }
  },
  watch: {
    currentConstructionProject () {
      this.centerMap()
    }
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    centerMap () {
      this.$refs.mapRef.$mapPromise.then((map) => {
        if (this.currentConstructionProject) {
          let position = {
            lat: this.currentConstructionProject.lat,
            lng: this.currentConstructionProject.lng
          }
          map.panTo(position)
          map.setZoom(this.currentConstructionProject.locationPrecision === 1 ? 16 : 14)
        } else {
          map.panTo({lat: 47.3362615, lng: 8.5130041})
          map.setZoom(10)
        }
      })
    },
    getSiteIcon (reference, buildingProjectId = '') {
      let color = reference === 2 ? (this.currentConstructionProject === null || this.currentConstructionProject.buildingProjectId === buildingProjectId ? 'red' : 'grey') : ''
      switch (reference) {
        case 1:
          return require('@/assets/images/icons-map/property-green.png')
        case 2:
          return color ? require('@/assets/images/icons-map/construction-' + color + '.png') : ''
      }
    },
    showConstructionProjectInfoWindow (constructionProject, buildingProjectId) {
      if (this.$route.params && this.$route.params.buildingProjectId !== buildingProjectId) {
        this.$router.push({ name: CONSTRUCTION_PROJECTS, params: { buildingProjectId: buildingProjectId } })
      } else {
        this.$emit('showConstructionProjectInfoWindow', constructionProject, buildingProjectId)
      }
    },
    displayPropertyInfoWindow (property, idx) {
      let markerPosition = {
        lat: parseFloat(property.lat),
        lng: parseFloat(property.lng)
      }
      this.infoWindowPos = markerPosition
      this.address = property.street + ', ' + property.zip + ' ' + property.place
      if (this.currentMidx === idx) {
        this.infoWinOpen = !this.infoWinOpen
      } else {
        this.infoWinOpen = true
        this.currentMidx = idx
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.gmap-dimensions {
  width: 100%;
  height: 100%;
}
#mapContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 50%;
}
</style>
