<template>
  <v-flex v-bind:id="mutableTaskEntry.taskId" class="mt-2 mb-2" xs12>
    <v-card :class="ticketColor" class="ma-1 task-entry" @click="openTaskDetailsDialog">
      <v-card-text class="pa-2">
        <v-list-item class="pa-0">
          <v-list-item-title>
            <p v-if="mutableTaskEntry.ticket" class="mb-1 text-wrap d-block">
              {{ mutableTaskEntry.ticket.processDefinitionName }}<template v-if="mutableTaskEntry.ticket.subject"> &rsaquo; {{ mutableTaskEntry.ticket.subject }}</template>
            </p>
            <p v-else-if="mutableTaskEntry.parentTaskId">
              <span class="pa-0">{{ $t('ticketingSystem.taskDetails.checkList') }}</span>
            </p>
          </v-list-item-title>
          <v-list-item-action v-if="hasUnreadMail">
            <v-icon color="primary">email</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-card-title class="pa-0 truncate-two-lines">
          <TicketPriority v-model="mutableTaskEntry.priority" read-only /> {{ mutableTaskEntry.name }}
        </v-card-title>
        <v-layout row wrap class="pl-3 pb-3 mt-1 task-info">
          <v-flex md12 v-if="mutableTaskEntry.dueDate">{{ $t('ticketingSystem.entry.due') }}: {{ formatDateAndTimeFunc(mutableTaskEntry.dueDate) }}</v-flex>
          <!-- <v-flex md12 v-if="taskEntry.followUpDate">{{ $t('ticketingSystem.entry.followUp') }}: {{ formatDateAndTimeFunc(taskEntry.followUpDate) }}</v-flex> -->
          <v-flex md12>{{ $t('ticketingSystem.entry.created') }}: {{ formatDateAndTimeFunc(mutableTaskEntry.startedOn) }}</v-flex>
          <v-flex md12 v-if="!hideAssignee && mutableTaskEntry.assignee">{{ $t('ticketingSystem.entry.assignee') }}: <UserName :user="mutableTaskEntry.assignee" /></v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-flex>
</template>

<script>
import TicketPriority from '@/features/ticketing/components/TicketPriority'
import UserName from '@/components/UserName'
import { formatDateAndTime } from '@/helpers/formatterHelper'
import { dueDateColor } from '@/features/ticketing/helpers/ticketingHelper'

export default {
  name: 'TicketingTaskEntry',
  props: {
    taskEntry: {
      type: Object,
      default: () => {}
    },
    hideAssignee: {
      type: Boolean,
      default: false
    },
    columnId: {
      type: String,
      default: 'personal'
    }
  },
  data () {
    return {
      ticketingTaskEntry: this.taskEntry
    }
  },
  components: {
    TicketPriority,
    UserName
  },
  computed: {
    ticketColor () {
      return dueDateColor(this.ticketingTaskEntry.dueDate)
    },
    hasUnreadMail () {
      return this.ticketingTaskEntry.ticket.hasUnreadMail
    },
    mutableTaskEntry () {
      return this.taskEntry
    }
  },
  methods: {
    formatDateAndTimeFunc: formatDateAndTime,
    openTaskDetailsDialog () {
      this.$emit('openTaskDetailsDialog', this.taskEntry.parentTaskId ? this.taskEntry.parentTaskId : this.taskEntry.taskId)
    }
  }
}
</script>

<style scoped lang="postcss">
.task-entry:hover {
  background: #f7f7f7;
}
.task-info {
  color: rgba(0,0,0,.87);
}
.beige {
  background-color: rgba(245, 245, 220) !important;
}
.truncate-two-lines {
  display: block;
  display: -webkit-box;
  margin: 0;
  line-height: 1.5;
  font-size: 1.0em;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.selected-task {
  background-color: rgb(228, 239, 250) !important;
}
</style>
