<template>
  <v-container fluid class="grey lighten-5 pa-3">
    <v-layout wrap>
      <v-layout>
        <v-flex xs12 sm12 md7 lg5 xl5 class="pa-0 ma-0 xs4">
          <v-autocomplete
            :label="$t('renting.status')"
            cache-items
            :items="personStatusItems"
            return-object
            clearable="clearable"
            required
            @change="changedValue"
            v-model="selectedPersonStatus"
          >
            <template slot="selection" slot-scope="{ item }">
              {{ $t('renting.statuses.' + item.value) }}
            </template>
            <template slot="item" slot-scope="{ item }">
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('renting.statuses.' + item.value) }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-flex>
        <v-flex xs12 sm12 md5 lg2 xl2 class="mt-3 ml-3">
          <v-btn color="primary" :disabled="selected.length === 0 || selectedPersonStatus === null" @click="changeStatus"><v-icon class="mr-1">send</v-icon>{{ $t('renting.change') }}</v-btn>
        </v-flex>
      </v-layout>
      <v-flex xs12 sm12 md3 lg3 xl3 class="mb-3">
        <v-text-field
          append-icon="search"
          :label="$t('common.buttons.search')"
          hide-details
          @input="debounceInput"
          v-model="search"
        ></v-text-field>
      </v-flex>
    </v-layout>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :custom-filter="customDataTableFilter"
        v-model="selected"
        @click:row="openInterestedPersonDetails"
        :footer-props="{itemsPerPageOptions:itemsPerPageOptions, itemsPerPageText: itemsPerPageText}"
        show-select
        :loading="loading"
        :options.sync="options"
      >
        <template v-slot:[`item.personStatus`]="{ item }">
          <v-icon v-if="item.personStatus === 'PENDING'" color="orange">error_outline</v-icon>
          <v-icon v-if="item.personStatus === 'UNDER_CLARIFICATION'" color="orange">access_time</v-icon>
          <v-icon v-if="item.personStatus === 'COMMITMENT'" color="green">check</v-icon>
          <v-icon v-if="item.personStatus === 'CANCELLATION_BY_INTERESTED_PERSON'" color="red">cancel</v-icon>
          <v-icon v-if="item.personStatus === 'ALREADY_RENTED'" color="red">cancel</v-icon>
          <v-icon v-if="item.personStatus === 'NOT_ELIGIBLE'" color="red">cancel</v-icon>
          <span v-if="item.personStatus">{{ $t('renting.statuses.' + item.personStatus )}}</span>
        </template>
        <template v-slot:[`item.createdOn`]="{ item }">
            {{ item.createdOnFormatted }}
        </template>
        <template v-slot:[`item.propertyInfo`]="{ item }">
          <span v-if="item.propertyId">[<a @click.stop="onPropertyClicked(item.propertyId)">{{ item.propertyId }}</a>]</span>
          <span v-html="item.propertyInfo"></span>
        </template>
        <template v-slot:[`item.objectInfo`]="{ item }">
          <span v-if="item.objectId">[{{ item.objectId }}]</span>
          <span v-html="item.objectInfo"></span>
        </template>
        <template slot="no-data">
          <v-alert class="mt-4" :value="true" color="error" icon="warning">
            {{ $t('table.noData') }}
          </v-alert>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>

import { dataTableFilter } from '@/helpers/globalHelper'
import { formatDate } from '@/helpers/formatterHelper'
import { openDashboardProperty } from '@/helpers/routesHelper'
import { mapActions } from 'vuex'
import _debounce from 'lodash.debounce'
import { INTERESTED_PERSON_DETAILS } from '@/config/options/routes/rentingRoutes'

export default {
  name: 'RentingList',
  data () {
    return {
      loading: true,
      items: [],
      selected: [],
      search: '',
      selectedPersonStatus: null,
      itemsPerPageOptions: [ 10, 25, 50, 100 ],
      options: {
        page: 1,
        sortBy: ['createdOn'],
        sortDesc: [true]
      }
    }
  },
  created () {
    this.loadInterestedPersons()
  },
  watch: {
    '$route': 'loadInterestedPersons'
  },
  computed: {
    headers () {
      let header = [{
        text: this.$t('common.date.creationDate'),
        align: 'left',
        value: 'createdOn'
      },
      {
        text: this.$t('renting.interested'),
        align: 'left',
        value: 'fullName'
      },
      {
        text: this.$t('renting.status'),
        align: 'left',
        value: 'personStatus'
      },
      {
        text: this.$t('renting.comment'),
        align: 'left',
        value: 'comment'
      }
      ]
      if (!this.$route.params.propertyId && !this.$route.params.propertyObjectId) {
        header.push({
          text: this.$t('renting.interestedPerson.property'),
          align: 'left',
          value: 'propertyInfo'
        })
        header.push({
          text: this.$t('renting.interestedPerson.object'),
          align: 'left',
          value: 'objectInfo'
        })
      }
      return header
    },
    personStatusItems () {
      return [
        { value: 'PENDING' },
        { value: 'UNDER_CLARIFICATION' },
        { value: 'COMMITMENT' },
        { value: 'CANCELLATION_BY_INTERESTED_PERSON' },
        { value: 'ALREADY_RENTED' },
        { value: 'NOT_ELIGIBLE' }
      ]
    },
    itemsPerPageText () {
      return this.$t('common.itemsPerPageText')
    }
  },
  methods: {
    ...mapActions('notifier', ['setSuccessSnackbar', 'setErrorSnackbar']),
    formatDateFunc: formatDate,
    loadInterestedPersons () {
      this.options.page = 1
      let propertyId = this.$route.params.propertyId ? parseInt(this.$route.params.propertyId) : undefined
      let propertyObjectId = this.$route.params.propertyObjectId ? parseInt(this.$route.params.propertyObjectId) : undefined
      let that = this
      that.loading = true
      this.$prestigeRenting.getInterestedPersons(propertyId, propertyObjectId).then(function (response) {
        if (response.data.length) {
          that.items = response.data
          that.items.forEach(item => {
            if (item.createdOn) {
              item.createdOnFormatted = that.formatDateFunc(item.createdOn)
              item.fullName = ((item.firstName || '') + ' ' + (item.lastName || '')).trim()
            }
            if (item.propertyAndObject) {
              item.propertyId = item.propertyAndObject.propertyId
              item.propertyInfo = `${item.propertyAndObject.street}, ${item.propertyAndObject.zip} ${item.propertyAndObject.place}`
              if (item.propertyAndObject.objectId) {
                item.objectId = item.propertyAndObject.objectId
                item.objectInfo = `${item.propertyAndObject.objectText}`
              }
            }
          })
        }
        that.loading = false
        return true
      }).catch(function (error) {
        that.setErrorSnackbar(error)
        that.loading = false
      }
      )
    },
    changedValue (value) {
      this.selectedPersonStatus = value
    },
    changeStatus () {
      let data = []
      if (this.selected.length) {
        this.selected.forEach(item => {
          data.push(item.id)
        })
      }
      let that = this
      let requestBody = {
        'status': this.selectedPersonStatus.value,
        'interestedPersonIds': data
      }
      this.$prestigeRenting.changeStatusOnPersons(requestBody).then(response => {
        that.setSuccessSnackbar(that.$t('renting.statusChangedMessage'))
        that.loadInterestedPersons()
        that.selected = []
        return true
      }).catch(error => {
        that.setErrorSnackbar(error)
      })
    },
    openInterestedPersonDetails (interestedPerson) {
      if (this.$route.meta.newTab) {
        window.open('/abacus/renting/interested-persons/' + interestedPerson.id.toString())
      } else {
        this.$router.push({ name: INTERESTED_PERSON_DETAILS, params: { interestedPersonId: interestedPerson.id.toString() } })
      }
    },
    onPropertyClicked (propertyId) {
      openDashboardProperty(propertyId)
    },
    debounceInput: _debounce(function (e) {
      this.loadInterestedPersons()
    }, 1000),
    customDataTableFilter (value, search, item) {
      return dataTableFilter(search, item)
    }
  }
}
</script>
<style scoped lang="postcss">
/deep/ tr:nth-child(odd) {
  background: #f8f8f8;
}
/deep/ tr {
  cursor: pointer;
}
.truncate-two-lines {
  display: block;
  display: -webkit-box;
  max-width: 800px;
  margin: 0;
  font-size: 13px;
  line-height: 1.5;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.min-200 {
  min-width: 200px;
}
.min-150 {
  min-width: 150px;
}
.max-150 {
  max-width: 150px;
}
</style>
