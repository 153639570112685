<template>
  <div class="hello">
    <v-container fluid class="grey lighten-5">

      Address from GraphQL: {{ abacusAddress }}
      <v-btn @click="$apollo.queries.abacusAddress.refresh()">load</v-btn>
      <v-row cols="12">
        <v-col>
          <v-card
            class="mx-auto"
          >
            <v-card-text>
              <v-text-field
                label="Address ID"
                placeholder="ID"
                v-model="addressId"
              ></v-text-field>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card
            class="mx-auto"
          >
            <v-card-text>
              <v-text-field
                label="Property ID"
                placeholder="ID"
                v-model="propertyId"
              ></v-text-field>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card
            class="mx-auto"
          >
            <v-card-text>
              <v-text-field
                label="Client ID"
                placeholder="ID"
                v-model="clientId"
              ></v-text-field>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid class="grey lighten-5">
      <v-row cols="12">
        <v-col>
          <v-card
            class="mx-auto"
          >
            <v-card-text>
              <p class="display-1 text--primary">
                Address
              </p>
              <div class="text--primary" v-text="addressResponse">
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card
            class="mx-auto"
          >
            <v-card-text>
              <p class="display-1 text--primary">
                Property
              </p>
              <div class="text--primary" v-text="propertyResponse">
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card
            class="mx-auto"
          >
            <v-card-text>
              <p class="display-1 text--primary">
                Tenants
              </p>
              <div class="text--primary" v-text="tenantsResponse">
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid class="grey lighten-5">
      <v-row cols="12">
        <v-col>
          <v-card class="mx-auto">
            <v-card-text>
              <p class="display-1 text--primary">
                Blocked Craftsmen
              </p>
              <div class="text--primary" v-text="blockedCraftsmenResponse">
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card class="mx-auto">
            <v-card-text>
              <p class="display-1 text--primary">
                Priority Craftsmen
              </p>
              <div class="text--primary" v-text="priorityCraftsmenResponse">
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid class="grey lighten-5">
      <v-row cols="12">
        <v-col>
          <v-card class="mx-auto">
            <v-card-text>
              <p class="display-1 text--primary">
                Real Estate Managers
              </p>
              <div class="text--primary" v-text="realEstateManagersResponse">
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card class="mx-auto">
            <v-card-text>
              <p class="display-1 text--primary">
                Craftsmen
              </p>
              <v-autocomplete
                label="Search"
                cache-items
                :loading="craftsmenIsLoading"
                single-line
                return-object
                :items="craftsmenItems"
                item-text="lastName"
                item-value="id"
                :search-input.sync="craftsmenSearchQuery"
                v-model="craftsmenSelected"
              ></v-autocomplete>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card class="mx-auto">
            <v-card-text>
              <p class="display-1 text--primary">
                Caretaker
              </p>
              <v-autocomplete
                label="Search"
                cache-items
                :loading="caretakerIsLoading"
                single-line
                return-object
                :items="caretakerItems"
                item-text="lastName"
                item-value="id"
                :search-input.sync="caretakerSearchQuery"
                v-model="caretakerSelected"
              ></v-autocomplete>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { ABACUS_ADDRESS } from './graphql-apollo/abacusAddress.gql'

export default {
  data () {
    return {
      addressId: '',
      addressResponse: '',
      propertyId: '',
      propertyResponse: '',
      clientId: '',
      blockedCraftsmenResponse: '',
      priorityCraftsmenResponse: '',
      tenantsResponse: '',
      realEstateManagersResponse: '',
      craftsmenIsLoading: false,
      craftsmenSearchQuery: '',
      craftsmenItems: [],
      craftsmenSelected: {},
      caretakerIsLoading: false,
      caretakerSearchQuery: '',
      caretakerItems: [],
      caretakerSelected: {}
    }
  },
  apollo: {
    abacusAddress: {
      query: ABACUS_ADDRESS,
      variables: {
        addressId: 1
      }
    }
  },
  mounted () {
    this.loadRealEstateManagers()
  },
  methods: {
    loadAddress: function () {
      let that = this
      this.$abacus.getAddressById(this.addressId).then(function (response) {
        that.addressResponse = response.data
      }).catch(function (error) {
        that.addressResponse = error
      })
    },
    loadProperty: function () {
      let that = this
      this.$abacusProperty.getPropertyById(this.propertyId).then(function (response) {
        that.propertyResponse = response.data
      }).catch(function (error) {
        that.propertyResponse = error
      })
    },
    loadTenants: function () {
      let that = this
      this.$abacusProperty.getTenantsForPropertyId(this.propertyId).then(function (response) {
        that.tenantsResponse = response.data
      }).catch(function (error) {
        that.tenantsResponse = error
      })
    },
    loadBlockedCraftsmen: function () {
      let that = this
      this.$abacusBlockedCraftsman.getAllBlockedCraftsmenByClientId(this.clientId).then(function (response) {
        that.blockedCraftsmenResponse = response.data
      }).catch(function (error) {
        that.blockedCraftsmenResponse = error
      })
    },
    loadPriorityCraftsmen: function () {
      let that = this
      this.$abacusPriorityCraftsman.getAllPriorityCraftsmen(this.propertyId).then(function (response) {
        that.priorityCraftsmenResponse = response.data
      }).catch(function (error) {
        that.priorityCraftsmenResponse = error
      })
    },
    loadRealEstateManagers: function () {
      let that = this
      let queryParams = { query: { realEstateManager: true } }
      this.$abacus.getAllAddressesV2(undefined, queryParams).then(function (response) {
        that.realEstateManagersResponse = response.data
      }).catch(function (error) {
        console.log(error)
        that.realEstateManagersResponse = error
      })
    }
  },
  watch: {
    propertyId: function () {
      this.loadProperty()
      this.loadTenants()
      this.loadPriorityCraftsmen()
    },
    clientId: function () {
      this.loadBlockedCraftsmen()
    },
    addressId: function () {
      this.loadAddress()
    },
    craftsmenSearchQuery (val) {
      if (val) {
        let that = this
        this.craftsmenIsLoading = true
        let queryParams = { query: { searchString: val, craftsman: true } }
        this.$abacus.getAllAddressesV2(undefined, queryParams).then(function (response) {
          console.log('got', response.data)
          that.craftsmenItems = response.data
          that.craftsmenIsLoading = false
        }).catch(function (error) {
          console.log(error)
          that.craftsmenItems = []
          that.craftsmenIsLoading = false
        })
      }
    },
    caretakerSearchQuery (val) {
      if (val) {
        let that = this
        this.caretakerIsLoading = true
        let queryParams = { query: { searchString: val, caretaker: true } }
        this.$abacus.getAllAddressesV2(undefined, queryParams).then(function (response) {
          console.log('got', response.data)
          that.caretakerItems = response.data
          that.caretakerIsLoading = false
        }).catch(function (error) {
          console.log(error)
          that.caretakerItems = []
          that.caretakerIsLoading = false
        })
      }
    }
  }
}
</script>
