import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
  rentingFormCaptchaStatus: false
}

const getters = {
  getRentingFormCaptchaStatus: state => state.rentingFormCaptchaStatus
}

const actions = {
  setRentingFormCaptchaStatus ({ commit }, status) {
    commit('SET_RENTING_FORM_CAPTCHA_STATUS', status)
  }
}

const mutations = {
  SET_RENTING_FORM_CAPTCHA_STATUS (state, status) {
    state.rentingFormCaptchaStatus = status
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
