
// TICKETING SYSTEM
import TicketingList from '../../ticketing/tasks/TicketingList'
import ProcessesList from '../../ticketing/processes/ProcessesList'
import ProcessDetails from '../../ticketing/processes/ProcessDetails'

export const TicketingRoutes = [
  {
    path: '/ticketing/tasks',
    name: 'TicketingTaskList',
    component: TicketingList,
    meta: {
      title: 'ticketingList',
      requireAuth: true,
      requireRoles: [
        'prestige-tools-reader'
      ]
    }
  },
  {
    path: '/ticketing/processes',
    name: 'TicketingProcessList',
    component: ProcessesList,
    meta: {
      title: 'processes',
      requireAuth: true,
      requireRoles: [
        'prestige-tools-reader'
      ]
    }
  },
  {
    path: '/ticketing/processes/:processInstanceId?',
    name: 'TicketingProcessDetails',
    component: ProcessDetails,
    meta: {
      title: 'processDetail',
      requireAuth: true,
      requireRoles: [
        'prestige-tools-reader'
      ]
    }
  }
]
