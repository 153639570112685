var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"grey lighten-5 pa-2",attrs:{"fluid":""}},[_c('ShoppingCartDialog',{attrs:{"show-dialog":_vm.showEditDialog,"item":_vm.editedItem,"default-supplier-id":_vm.defaultSupplierId,"max-amount":_vm.maxAmount,"edit":""},on:{"closeDialog":_vm.closeEditDialog,"saveItemToBasket":_vm.saveItemToBasket}}),_c('ConfirmationDialog',{attrs:{"data":_vm.deleteData,"show-dialog":_vm.showDeleteDialog},on:{"closeDialog":_vm.closeDeleteDialog,"submitDialog":_vm.deleteBasketItem}}),_c('v-layout',{staticClass:"row pt-0 pl-0 pr-0"},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('heatingOil.headers.shoppingCart')))]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"search","label":_vm.$t('common.buttons.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-btn',{staticClass:"mb-4",attrs:{"color":"primary","disabled":_vm.selected.length == 0},on:{"click":_vm.placeOrder}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("send")]),_vm._v(_vm._s(_vm.$t('heatingOil.basket.order')))],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.basket,"loading":_vm.basketLoading,"search":_vm.search,"custom-filter":_vm.customDataTableFilter,"must-sort":"","item-key":"id","show-select":"","options":_vm.options,"hide-default-footer":true,"footer-props":{itemsPerPageOptions:_vm.itemsPerPageOptions}},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('tr',{attrs:{"active":props.selected}},[_c('td',[_c('v-checkbox',{attrs:{"primary":"","hide-details":"","input-value":props.selected},on:{"click":function($event){props.selected = !props.selected}}})],1)])]}},{key:"item.propertyInfo",fn:function(ref){
var item = ref.item;
return [_vm._v(" ["),(item.propertyId)?_c('a',{attrs:{"href":""},on:{"click":function($event){return _vm.openMapProperty(item.propertyId)}}},[_vm._v(_vm._s(item.propertyId))]):_vm._e(),_vm._v("] "),(item.propertyId)?_c('span',[_vm._v(_vm._s(item.propertyStreet + ', ' + item.propertyZip + ' ' + item.propertyPlace))]):_vm._e()]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.amountInfo))])]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" ")]}},{key:"item.tankId",fn:function(ref){
var item = ref.item;
return [_vm._v(" ["+_vm._s(item.tankId)+"] ")]}},{key:"item.comment",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-0 truncate-two-lines"},[_vm._v(_vm._s(item.comment))])]}},{key:"item.fillingLevel",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.volumeInfo)+" ")]}},{key:"item.afterFilling",fn:function(ref){
var item = ref.item;
return [_c('v-progress-linear',{attrs:{"color":item.percentColor},model:{value:(item.percent),callback:function ($$v) {_vm.$set(item, "percent", $$v)},expression:"item.percent"}})]}},{key:"item.tankRevision",fn:function(ref){
var item = ref.item;
return [(item.tankRevision)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v("build")]):_vm._e()]}},{key:"item.orderedOn",fn:function(ref){
var item = ref.item;
return [(item.dateDifferenceInMonths != null && item.dateDifferenceInMonths < 2)?_c('nobr',[_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("warning")]),_vm._v(" "+_vm._s(item.orderedOnFormatted)+" ")],1):_c('span',[_vm._v(_vm._s(item.orderedOnFormatted))])]}},{key:"item.addedOn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.addedOnFormatted)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('nobr',[_c('v-btn',{staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){return _vm.openEditDialog(item)}}},[_c('v-icon',{attrs:{"color":"teal"}},[_vm._v("edit")])],1),_c('v-btn',{staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.openDeleteDialog(item.id)}}},[_c('v-icon',{attrs:{"color":"pink"}},[_vm._v("delete")])],1)],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('v-progress-linear',{attrs:{"slot":"progress","color":"blue","indeterminate":""},slot:"progress"}),_c('template',{slot:"no-data"},[_c('v-alert',{attrs:{"value":true,"color":"error","icon":"warning"}},[_vm._v(" "+_vm._s(_vm.$t('table.noData'))+" ")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }