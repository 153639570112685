<template>
  <v-container fluid class="white pt-2 pl-5 pr-5">
    <v-form ref="form" v-model="valid">
      <v-layout class="row pa-2">
        <v-flex class="pr-2" xs12 sm12 md6 lg6 xl6>
          <DatePicker :label="$t('statistics.date.from')" :from="true" v-model="form.from" />
        </v-flex>
        <v-flex class="pl-2" xs12 sm12 md6 lg6 xl6>
          <DatePicker :label="$t('statistics.date.to')" v-model="form.to" />
        </v-flex>
        <v-btn class="primary" @click="search()" :disabled="!valid">
          <v-icon>search</v-icon> {{ $t('common.buttons.search') }}
        </v-btn>
      </v-layout>
    </v-form>
    <TaskStats v-show="searchClicked" ref="taskStatsRef" />
    <HeatingOilStats v-show="searchClicked" ref="heatingOilStatsRef" />
    <FeedbackStats v-show="searchClicked" ref="feedbackStatsRef" />
  </v-container>
</template>

<script>
import DatePicker from '../../components/DatePicker'
import TaskStats from './components/TaskStats'
import HeatingOilStats from './components/HeatingOilStats'
import FeedbackStats from './components/FeedbackStats'

export default {
  name: 'StatisticsData',
  data () {
    return {
      searchClicked: false,
      valid: false,
      form: {
        from: null,
        to: null
      }
    }
  },
  components: {
    DatePicker,
    TaskStats,
    HeatingOilStats,
    FeedbackStats
  },
  mounted () {
    this.search()
  },
  methods: {
    search () {
      this.searchClicked = true
      this.$refs.taskStatsRef.loadTaskStats(this.form.from, this.form.to)
      this.$refs.heatingOilStatsRef.loadHeatingOilStats(this.form.from, this.form.to)
      this.$refs.feedbackStatsRef.loadFeedbackStats(this.form.from, this.form.to)
    }
  }
}
</script>

<style scoped>
</style>
