export const AxiosDefaultConfigFactory = baseURL => ({
  baseURL,
  timeout: 20000, // 20 sec timeout for backend requests
  validateStatus: function (status) {
    return status < 400 // Reject  if the status code is greater than or equal to 400
  }
})

export const AxiosCustomConfigFactory = (baseURL, timeout) => ({
  baseURL,
  timeout: timeout, // timeout for backend requests
  validateStatus: function (status) {
    return status < 400 // Reject  if the status code is greater than or equal to 400
  }
})
