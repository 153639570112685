<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card class="pa-2" outlined tile v-if="loading">
          <v-progress-linear  indeterminate color="blue"></v-progress-linear>
        </v-card>
        <v-card class="pa-2" outlined tile v-if="message">
          <v-alert type="error" class="cy-abacus-ticketing-complete-error">
            {{ message }}
          </v-alert>
        </v-card>
        <v-card class="pa-2" outlined tile v-if="completeTaskResponse">
          <v-alert type="success" class="cy-abacus-ticketing-complete-success">
            {{ $t('abacus.ticketing.complete.success') }}
          </v-alert>
          <p><router-link :to="{ name: TICKETING_PROCESS_DETAILS, params: { processInstanceId: completeTaskResponse.ticket.ticketId}}">{{ $t('abacus.ticketing.common.openTicket') }}</router-link></p>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { TICKETING_PROCESS_DETAILS } from '@/config/options/routes/ticketingRoutes'
import {COMPLETE_TASK} from '../graphql-apollo/abacusTicketing.gql.js'

export default {
  name: 'AbacusTicketingComplete',
  data () {
    return {
      completeTaskResponse: null,
      message: '',
      loading: false,
      TICKETING_PROCESS_DETAILS: TICKETING_PROCESS_DETAILS
    }
  },
  created () {
    this.updateTask()
  },
  computed: {
    processDefinitionKey () {
      return this.$route.params.processDefinitionKey
    },
    externalTicketId () {
      return this.$route.params.externalTicketId
    },
    activityId () {
      return this.$route.params.activityId
    }
  },
  methods: {
    updateTask () {
      this.message = null
      if (!this.processDefinitionKey) {
        this.message = this.$t('abacus.ticketing.common.missingInput') + 'processDefinitionKey'
      } else if (!this.externalTicketId) {
        this.message = this.$t('abacus.ticketing.common.missingInput') + 'externalTicketId'
      } else if (!this.activityId) {
        this.message = this.$t('abacus.ticketing.common.missingInput') + 'activityId'
      } else {
        this.completeTaskResponse = null
        this.loading = true
        this.$apollo.mutate({
          mutation: COMPLETE_TASK,
          variables: {
            processDefinitionKey: this.processDefinitionKey,
            externalTicketId: this.externalTicketId,
            activityId: this.activityId
          }
        }).then((response) => {
          this.completeTaskResponse = response.data.completeTaskForExternalTicketId
          this.loading = false
        }).catch((error) => {
          if (error.toString().includes("TaskNotFoundException")) {
            this.message = this.$t('abacus.ticketing.common.taskNotFoundException')
          } else if (error.toString().includes("TicketNotFoundException")) {
            this.message = this.$t('abacus.ticketing.common.ticketNotFoundException')
          } else {
            this.message = this.message = this.$t('abacus.ticketing.complete.failure') + error
          }
          this.loading = false
        })
      }
    }
  }
}
</script>

<style scoped lang="postcss">
</style>
