import HeatingOilTanks from '../../heating-oil/HeatingOilTanks'
import HeatingOilBasket from '../../heating-oil/HeatingOilBasket'
import HeatingOilOrders from '../../heating-oil/HeatingOilOrders'

export const HeatingOilRoutes = [
  {
    path: '/heating-oil/tanks',
    name: 'HeatingOilTanks',
    component: HeatingOilTanks,
    meta: {
      title: 'heatingOil',
      requireAuth: true,
      requireRoles: [
        'prestige-tools-reader'
      ]
    }
  },
  {
    path: '/heating-oil/basket',
    name: 'HeatingOilBasket',
    component: HeatingOilBasket,
    meta: {
      title: 'heatingOil',
      requireAuth: true,
      requireRoles: [
        'prestige-tools-reader'
      ]
    }
  },
  {
    path: '/heating-oil/orders',
    name: 'HeatingOilOrders',
    component: HeatingOilOrders,
    meta: {
      title: 'heatingOil',
      requireAuth: true,
      requireRoles: [
        'prestige-tools-reader'
      ]
    }
  }
]
