import moment from 'moment'

export function formatAmount (x) {
  if (x) {
    if (isNaN(x)) {
      return x
    } else {
      return parseFloat(x).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1\'')
    }
  } else {
    return x
  }
}

export function formatDate (date) {
  return date ? moment(date).format('DD.MM.YYYY') : ''
}

export function formatDateAndTime (date) {
  return date ? moment(date).format('DD.MM.YYYY HH:mm') : ''
}

export function formatTime (date) {
  return date ? moment(date).format('HH:mm') : ''
}

export function formatBytes (bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes'
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export function parseDate (date) {
  if (!date) return null
  const [day, month, year] = date.split('\.')
  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
}
