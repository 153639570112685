<template>
  <v-card class="checklist-content-card height mb-2 mr-0">
    <v-container fluid grid-list-xs>
      <v-card-text class="pa-0 mb-2">
        <v-card-subtitle class="pa-0 font-weight-bold subtitle-1">
          {{ $t('ticketingSystem.taskDetails.checkList') }}
          <v-btn
            v-if="!taskFinished && showAddBtn"
            color="success"
            class="pa-3 white--text float-right"
            :x-small="mdAndDown"
            @click="shouldShowChildDialog = true"
          >
            <span>{{ $t('common.buttons.add') }}</span>
            <v-icon class="pl-1" :x-small="mdAndDown">mdi-vector-polyline-plus</v-icon>
          </v-btn>
        </v-card-subtitle>
      </v-card-text>
      <v-main class="pa-0 ma-0">
        <v-data-table
          v-if="ticketingTask && ticketingTask.checklistItems && ticketingTask.checklistItems.length > 0"
          :headers="headers"
          :items="ticketingTask.checklistItems"
          :options.sync="options"
          multi-sort
          :items-per-page="5"
          :footer-props="{ itemsPerPageOptions: itemsPerPageOptions, itemsPerPageText: itemsPerPageText }"
          class="mt-2"
        >
          <template v-slot:[`item.priority`]="{ item }">
            <TicketPriority
              v-model="item.priority"
              :read-only="true"
            />
          </template>
          <template v-slot:[`item.finishedOn`]="{ item }">
            <div class="text-no-wrap">
              <StateIcon :state="item.finishedOn === '' || item.finishedOn === null ? 'ACTIVE' : 'COMPLETED'" />
              {{ formatDateAndTimeFunc(item.finishedOn) }}
            </div>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <CheckListItemInfo
              v-if="item"
              :item="item"
              @loadTaskDetails="onLoadTaskDetails"
            />
          </template>
          <template v-slot:[`item.dueDate`]="{ item }">
            <div id="childTaskDueDate" class="due-on mb-5 mt-7 text-no-wrap">
              <DueDate
                ref="dueDate"
                :task-finished="childTaskFinished(item)"
                :task-details="item"
                @updateDueDate="updateTaskDueDate"
              />
            </div>
          </template>
          <template v-slot:[`item.assignee`]="{ item }">
            <div class="assignee">
              <div v-if="item.finishedOn">
                <UserName :user="item.assignee" />
              </div>
              <div class="float-left" v-if="!childTaskFinished(item)">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span>
                      <v-icon color="grey darken-2" :size="22" v-bind="attrs" v-on="on">mdi-account-edit-outline</v-icon>
                    </span>
                  </template>
                  <span>{{ $t('ticketingSystem.entry.assignee') }}</span>
                </v-tooltip>
                <a
                   v-if="!(item.showDropdown && item.taskId === selectedChildTaskId)"
                   @click.stop="showChildTaskAssigneeDropdown(item)"
                >
                  <span v-if="!item.showDropdown && !item.assignee && !childTaskFinished(item)">{{ $t('ticketingSystem.taskDetails.buttons.unassigned') }}</span>
                  <UserName :user="item.assignee" />
                </a>
              </div>
              <div class="float-left">
                <TaskAssignee
                  v-if="item.showDropdown"
                  ref="assigneeLink"
                  v-bind:form="select"
                  :assigned-to-me="childTaskAssignedToMe(item)"
                  :user-has-ticketing-role="userHasTicketingRole"
                  @changedAssignee="changeChildTaskAssignee(false, item.taskId)"
                  @hideAssigneeDropdown="hideChildTaskAssigneeDropdown(item)"
                />
              </div>
            </div>
          </template>
          <!--
          <template v-slot:[`item.followUpDate`]="{ item }">
            <div id="childTaskFollowUpDate" class="follow-up-on mb-5 mt-7" v-click-outside="cancelSetFollowUpDate">
              <FollowUpDate
                ref="followUpDate"
                :task-finished="childTaskFinished(item)"
                :task-details="item"
                @updateFollowUpDate="updateTaskFollowUpDate"/>
            </div>
          </template>
          -->
          <template v-slot:[`item.candidateGroups`]="{ item }">
            <CandidateGroups
              :checkList="true"
              :task-id="item.taskId"
              :task-candidate-groups="item.candidateGroups"
              :task-finished="childTaskFinished(item)"
              :finished-loading="finishedLoading"
              @loadTaskDetails="onLoadTaskDetails"
            />
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <div v-if="!item.finishedOn">
              <v-btn
                color="primary"
                class="pa-3"
                x-small
                @click="assignToLoggedInUserAndCompleteChildTask(item)"
              >
                <span>{{ $t('ticketingSystem.taskDetails.buttons.done') }}</span>
                <v-icon class="pl-1" x-small>done</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
        <v-card-text v-else class="mt-2 pa-0">
          {{ $t('table.noData') }}
        </v-card-text>
      </v-main>
      <ChildTaskDialog
        v-if="shouldShowChildDialog"
        :parent-task-id="ticketingTask.taskId"
        @closeDialog="shouldShowChildDialog = false"
        @loadTaskDetails="onLoadTaskDetails"
      />
    </v-container>
  </v-card>
</template>

<script>
import TaskAssignee from './TaskAssignee'
import ChildTaskDialog from './ChildTaskDialog'
import CandidateGroups from './CandidateGroups'
import CheckListItemInfo from './CheckListItemInfo'
import TicketPriority from '@/features/ticketing/components/TicketPriority'
import DueDate from '@/features/ticketing/components/DueDate'
import StateIcon from '@/components/StateIcon'
import UserName from '@/components/UserName'

import { mapActions, mapState } from 'vuex'
import { AuthenticationContext } from 'vue-adal'
import { formatDateAndTime } from '@/helpers/formatterHelper'

export default {
  name: 'CheckList',
  components: {
    UserName,
    TaskAssignee,
    ChildTaskDialog,
    DueDate,
    StateIcon,
    TicketPriority,
    CandidateGroups,
    CheckListItemInfo
  },
  data () {
    return {
      shouldShowChildDialog: false,
      options: {
        page: 1,
        sortBy: ['finishedOn', 'dueDate'],
        sortDesc: [false, false]
      },
      assignee: { },
      select: { value: null },
      selectedChildTaskId: '',
      showDropdown: false
    }
  },
  props: {
    ticketingTask: {
      type: Object,
      default: () => {}
    },
    taskFinished: {
      type: Boolean,
      default: false
    },
    showAddBtn: {
      type: Boolean,
      default: false
    },
    finishedLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('ticketingSystem', ['ticketingUsers']),
    headers () {
      return [
        {
          text: this.$t('ticketingSystem.entry.priority'),
          align: 'left',
          value: 'priority'
        },
        {
          text: this.$t('ticketingSystem.taskDetails.state'),
          align: 'left',
          value: 'finishedOn'
        },
        {
          text: this.$t('ticketingSystem.entry.item'),
          align: 'left',
          value: 'name'
        },
        {
          text: this.$t('ticketingSystem.entry.due'),
          align: 'left',
          value: 'dueDate'
        },
        {
          text: this.$t('ticketingSystem.note.selectCandidateGroup'),
          align: 'left',
          value: 'candidateGroups'
        },
        {
          text: this.$t('ticketingSystem.entry.assignee'),
          align: 'left',
          value: 'assignee'
        },
        //       {
        //          text: this.$t('ticketingSystem.entry.followUp'),
        //          align: 'left',
        //          value: 'followUpDate'
        //        },
        {
          text: this.$t('ticketingSystem.taskDetails.buttons.action'),
          align: 'left',
          value: 'action'
        }
      ]
    },
    itemsPerPageOptions () {
      return [
        5,
        10,
        15
      ]
    },
    itemsPerPageText () {
      return this.$t('common.itemsPerPageText')
    },
    mdAndDown () {
      return this.$vuetify.breakpoint.mdAndDown
    },
    loggedInUserId () {
      return AuthenticationContext && AuthenticationContext.user && AuthenticationContext.user.profile ? AuthenticationContext.user.profile.employeeid : null
    },
    userHasTicketingRole () {
      let userRoles = AuthenticationContext && AuthenticationContext.user && AuthenticationContext.user.profile ? AuthenticationContext.user.profile.roles : null
      return userRoles.includes('prestige-tools-ticketing')
    }
  },
  methods: {
    ...mapActions('notifier', ['setSuccessSnackbar', 'setErrorSnackbar']),
    formatDateAndTimeFunc: formatDateAndTime,
    childTaskFinished (childTask) {
      return childTask && childTask.finishedOn !== '' && childTask.finishedOn !== null
    },
    showChildTaskAssigneeDropdown (item) {
      if (item.assignee) {
        this.select = { value: { userId: item.assignee.userId } }
        this.selectedChildTaskId = item.taskId
      }
      this.$set(item, 'showDropdown', true)
      this.$nextTick(()=>{
        this.$refs.assigneeLink.$refs.assignee.focus()
        this.$refs.assigneeLink.$refs.assignee.isMenuActive = true
      })
    },
    childTaskAssignedToMe (item) {
      return item.assignee && item.assignee.userId === this.loggedInUserId
    },
    hideChildTaskAssigneeDropdown (item) {
      this.$set(item, 'showDropdown', false)
    },
    changeChildTaskAssignee (assignToMeClicked, childTaskId) {
      if ((this.select.value && this.select.value.userId) || assignToMeClicked) {
        let that = this
        let userId = assignToMeClicked ? 'assignToMe' : this.select.value.userId
        let data = this.setUserIdObj(userId)
        this.$prestigeTSTask.assignTask(childTaskId, data).then(function (response) {
          that.onLoadTaskDetails()
          that.setSuccessSnackbar(that.$t('ticketingSystem.messages.successfullyChangedAssignee'))
          return true
        }).catch(error => {
          that.reloadTaskAndShowError(error)
        })
      }
    },
    assignToLoggedInUserAndCompleteChildTask (childTask) {
      let that = this
      let data = this.setUserIdObj('assignToMe')
      that.$prestigeTSTask.assignTask(childTask.taskId, data).then(function () {
        that.setSuccessSnackbar(that.$t('ticketingSystem.messages.successfullyChangedAssignee'))
        that.completeChildTask(childTask)
        return true
      }).catch(error => {
        that.reloadTaskAndShowError(error)
      })
    },
    setUserIdObj (userId) {
      switch (userId) {
        case 'unassigned':
          return {}
        case 'assignToMe':
          return { userId: this.loggedInUserId }
        default:
          return { userId: this.select.value.userId }
      }
    },
    completeChildTask (childTask) {
      let data = {
        variables: [],
        newLinks: []
      }
      let that = this
      this.$prestigeTSTask.completeTask(childTask.taskId, data).then(function (response) {
        if (response.data) {
          that.onLoadTaskDetails()
          that.setSuccessSnackbar(that.$t('ticketingSystem.messages.successfullyCompleted'))
        }
        return true
      }).catch(error => {
        that.reloadTaskAndShowError(error)
      })
    },
    updateTaskFollowUpDate (resetFollowUpDate = false, newFollowUpDate, childTask) {
      let followUpDateBody = {}
      if (!resetFollowUpDate) {
        followUpDateBody = {
          followUpDate: newFollowUpDate
        }
      }
      let that = this
      this.$prestigeTSTask.updateTaskFollowUpDate(childTask.taskId, followUpDateBody).then(function () {
        that.onLoadTaskDetails()
        that.setSuccessSnackbar(that.$t('ticketingSystem.messages.successfullyUpdatedFollowUpDate'))
        return true
      }).catch(error => {
        that.reloadTaskAndShowError(error)
      })
    },
    updateTaskDueDate (resetDueDate = false, newDueDate, childTask) {
      let dueDateBody = {}
      if (!resetDueDate) {
        dueDateBody = {
          dueDate: newDueDate
        }
      }
      let that = this
      this.$prestigeTSTask.updateTaskDueDate(childTask.taskId, dueDateBody).then(function () {
        that.onLoadTaskDetails()
        that.setSuccessSnackbar(that.$t('ticketingSystem.messages.successfullyUpdatedDueDate'))
        return true
      }).catch(error => {
        that.reloadTaskAndShowError(error)
      })
    },
    onLoadTaskDetails () {
      this.$emit('loadTaskDetails')
    },
    cancelSetFollowUpDate () {
      if (this.$refs.followUpDate) {
        this.$refs.followUpDate.cancelSetFollowUpDate()
      }
    },
    cancelSetDueDate () {
      if (this.$refs.dueDate) {
        this.$refs.dueDate.cancelSetDueDate()
      }
    },
    reloadTaskAndShowError (error) {
      this.onLoadTaskDetails()
      this.setErrorSnackbar(error)
    }
  }
}
</script>

<style scoped lang="postcss">
/deep/ .v-data-table__wrapper > table > tbody > tr:nth-child(odd) {
  background: #f8f8f8;
}
@media (min-width: 960px) {
  .height {
    margin-right: 8px;
  }
}
.checklist-content-card {
  position: relative;
  overflow-y: auto;
}
</style>
