<template>
  <v-list-item v-if="abacusPropertyObject">
    <v-list-item-content>
      <v-card-text class="pa-0">
        <p class="text-wrap pa-0 ma-0">
          [{{ abacusPropertyObject.objectId }}] {{ abacusPropertyObject.houseStreet }} - {{ abacusPropertyObject.floorText }} {{ abacusPropertyObject.objectText }}
        </p>
        <v-list-item-subtitle class="text-wrap" v-show="abacusPropertyObject.currentTenant">
          {{ $t('ticketingSystem.context.propertyObject.currentTenant') }}: {{ abacusPropertyObject.currentTenantText }} ({{ currentTenantContract(abacusPropertyObject) }})
        </v-list-item-subtitle>
        <v-list-item-subtitle class="text-wrap" v-show="abacusPropertyObject.newTenant">
          {{ $t('ticketingSystem.context.propertyObject.newTenant') }}: {{ abacusPropertyObject.newTenantText }} ({{ newTenantContract(abacusPropertyObject) }})
        </v-list-item-subtitle>
      </v-card-text>
    </v-list-item-content>
    <v-list-item-action>
      <ActionsMenu
        :item="getItem(abacusPropertyObject)"
        :actions="actions"
        :delete-action="deleteAction"
      />
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import ActionsMenu from '@/components/ActionsMenu'
import { formatDate } from '@/helpers/formatterHelper'
import { useTask } from '@/use/useTask'
import { useObjectActions } from '@/use/useObjectActions'
import { ABACUS_PROPERTY_OBJECT } from '../../graphql-apollo/abacusProperties.gql'

const { objectActions } = useObjectActions()
const { onNewTask } = useTask()

export default {
  name: 'ProcessPropertyObject',
  components: {
    ActionsMenu
  },
  props: {
    deleteAction: {
      type: Object,
      default: () => {}
    },
    propertyObjectId: {
      type: String,
      required: true
    },
    propertyId: {
      type: String,
      required: true
    },
    processInstanceId: {
      type: String,
      default: ''
    }
  },
  apollo: {
    abacusPropertyObject: {
      query: ABACUS_PROPERTY_OBJECT,
      variables () {
        return {
          objectId: parseInt(this.propertyObjectId),
          propertyId: parseInt(this.propertyId)
        }
      },
      skip () {
        return !this.propertyObjectId && !this.propertyId
      },
      fetchPolicy: 'cache-first'
    }
  },
  computed: {
    actions () {
      return this.additionalActions.concat(objectActions)
    },
    additionalActions () {
      return [
        { title: this.$t('dashboard.actions.newTicket'), icon: 'mdi-ticket', action: this.onNewObjectTicket },
        { title: this.$t('dashboard.actions.newTask'), icon: 'mdi-calendar-check', action: onNewTask },
        { title: this.$t('dashboard.actions.offlineRentalApplication'), icon: 'mdi-plus', action: this.onOfflineRentalApplication }
      ]
    }
  },
  methods: {
    currentTenantContract (propertyObject) {
      if (propertyObject.currentTenantContractStart && propertyObject.currentTenantContractEnd) {
        return formatDate(propertyObject.currentTenantContractStart) + ' - ' + formatDate(propertyObject.currentTenantContractEnd)
      } else if (propertyObject.currentTenantContractStart) {
        return this.$t('ticketingSystem.context.propertyObject.since') + formatDate(propertyObject.currentTenantContractStart)
      } else if (propertyObject.currentTenantContractEnd) {
        return this.$t('ticketingSystem.context.propertyObject.until') + formatDate(propertyObject.currentTenantContractEnd)
      } else {
        return '?'
      }
    },
    newTenantContract (propertyObject) {
      return formatDate(propertyObject.newTenantContractStart)
    },
    onNewObjectTicket (object) {
      this.$emit('openNewTicketDialog', object)
    },
    onOfflineRentalApplication (object) {
      this.$emit('openOfflineRentalApplication', object)
    },
    getItem (item) {
      return Object.assign(item, { linkName: 'propertyObject', linkValue: item.property.propertyId + ':' + item.objectId, processInstanceId: this.processInstanceId })
    }
  }
}
</script>