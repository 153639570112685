<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm12 md4 lg4 xl4>
        <v-card class="elevation-12">
          <v-form ref="form" class="create-form" @submit.prevent="verifyToken">
            <v-toolbar dark :color="appBarBackgroundColor" class="mb-4">
              <v-toolbar-title>
                <v-img height="70px" width="70px" class="mt-10" src="/Logo-Prestige.png"></v-img>
              </v-toolbar-title>
              <v-toolbar-title class="title-lng pl-4 font-weight-bold d-flex justify-space-between align-center">
                {{ $t('renting.publicForm.title') }}
                <v-btn-toggle
                  v-model="activeLng"
                  mandatory
                  class="lng-group"
                >
                  <v-btn 
                    v-for="(item, index) in languageList"
                    @click="updateLanguage(item.value)"
                    :value="item.value"
                    class="lng-btn"
                    :key="'language-' + index" 
                    :color="appBarBackgroundColor"
                  >
                    <img :src="require('../../assets/icons/' + item.value + '.svg')" />
                  </v-btn>
                </v-btn-toggle>
              </v-toolbar-title>
            </v-toolbar>
            <v-container class="px-4">
              <v-layout wrap>
                <v-flex xs12 sm12 md12 lg12 xl12>
                  <v-text-field
                    :label="$t('renting.publicForm.entryToken')"
                    v-model="token"
                    required
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
            <template v-if="invalidToken">
              <v-card-text class="py-0">
                <v-alert class="mb-5" :value="true" v-html="$t('renting.publicForm.alerts.invalidToken')" type="warning"/>
              </v-card-text>
            </template>
            <v-card-actions class="pl-4 pr-4 mt-6">
              <a href="https://www.prestige.ch/impressum/" target="_blank">{{ $t('renting.publicForm.imprint') }}</a>
              <v-spacer></v-spacer>
              <template>
                <CaptchaForm
                  :show-error-message="showCaptchaErrorMessage"
                  @successSwipe="showCaptchaErrorMessage = false"
                />
              </template>
              <v-btn color="#6e2c6b" class="white--text" :loading="verificationLoading" @click.stop="verifyToken" @keydown.enter="verifyToken">
                {{ $t('common.buttons.confirm') }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import CaptchaForm from '../../components/CaptchaForm'
import moment from 'moment'
import { appBarBackgroundColor } from '@/config/options/appBar'
import { mapActions, mapState } from 'vuex'
import { PUBLIC_RENTAL_FORM } from '@/config/options/routes/rentingRoutes'
import {languageList} from '@/config/options/languages'

export default {
  name: 'PublicRentalFormEntry',
  components: {
    CaptchaForm
  },
  data () {
    return {
      invalidToken: false,
      verificationLoading: false,
      token: '',
      rules: {
        required: (value) => (!!value && value !== undefined) || this.requiredMessage
      },
      showCaptchaErrorMessage: false,
      appBarBackgroundColor: appBarBackgroundColor,
      languageList: languageList,
      activeLng: 'en'
    }
  },
  created () {
    this.setRentingFormCaptchaStatus(false)
  },
  computed: {
    ...mapState('captcha', ['rentingFormCaptchaStatus']),
    requiredMessage () {
      return this.$t('common.errors.required')
    }
  },
  methods: {
    ...mapActions('captcha', ['setRentingFormCaptchaStatus']),
    verifyToken () {
      if (this.validate()) {
        this.verificationLoading = true
        if (this.token === 'DEMO') {
          this.invalidToken = false
          this.enterRentalForm()
        } else {
          this.verifyPublicFormToken()
        }
      }
    },
    validate () {
      let valid = true
      if (!this.$refs.form.validate()) {
        valid = false
      }
      if (!this.rentingFormCaptchaStatus) {
        this.showCaptchaErrorMessage = true
        valid = false
      } else {
        this.showCaptchaErrorMessage = false
      }
      return valid
    },
    verifyPublicFormToken () {
      let that = this
      this.$prestigeRentalForm.verifyRentalCase(this.token).then(function (response) {
        that.verificationLoading = false
        if (response.data.isValid) {
          that.invalidToken = false
          that.enterRentalForm()
        } else {
          that.invalidToken = true
        }
      }).catch(error => {
        let errorResponse = error.response
        if (errorResponse) {
          that.setErrorMessage(errorResponse)
        }
        that.verificationLoading = false
        that.invalidToken = true
      })
    },
    enterRentalForm () {
      this.$router.push({ name: PUBLIC_RENTAL_FORM, params: { token: this.token } })
    },
    updateLanguage (value) {
      this.$i18n.locale = value
      moment.locale(value)
      localStorage.setItem('language', value)
    },
    activeLanguage () {
      this.activeLng = localStorage.getItem('language')
    }
  }
}
</script>

<style scoped lang="postcss">
.lng-group {
  width: 100%;
  max-width: 110px;
  height: 40px;
  border-radius: 28px;
}
/deep/ .lng-group .v-btn.v-btn.v-size--default {
  width: 50%;
  height: 100%!important;
  border: 0;
  border-left-radius: 28px;
}
.lng-group .lng-btn:first-child img {
  border-top-left-radius: 28px;
  border-bottom-left-radius: 28px;
}

.lng-group .lng-btn:last-child img {
  border-top-right-radius: 28px;
  border-bottom-right-radius: 28px;
}
.title-lng {
  width: 100%;
}
</style>
