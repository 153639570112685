var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-title',{staticClass:"pl-0"},[_vm._v(_vm._s(_vm.$t('navigation.tasks')))]),(_vm.administrativeAreaStats.length > 0)?_c('v-card-title',{staticClass:"subtitle-1 pa-0"},[_vm._v(" "+_vm._s(_vm.$t('statistics.totalNumberTasks'))+": "+_vm._s(_vm.totalNumberTasks)+", "+_vm._s(_vm.$t('statistics.totalNumberOffers'))+": "+_vm._s(_vm.totalNumberOffers)+" ")]):_vm._e(),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg4":"","xl4":""}},[_c('v-data-table',{staticClass:"elevation-1 mr-2 ml-2 mt-2",class:_vm.$ticketingEnabled ? 'tasks-overlay' : '',attrs:{"headers":_vm.admininstrativeAreaHeaders,"items":_vm.administrativeAreaStats,"loading":_vm.taskStatsLoading,"options":_vm.administrativeOptions,"footer-props":{itemsPerPageOptions: _vm.itemsPerPageOptions},"hide-default-footer":true},on:{"update:options":function($event){_vm.administrativeOptions=$event},"click:row":_vm.displayAdministrativeTasks}},[_c('template',{slot:"no-data"},[_c('v-alert',{attrs:{"value":true,"color":"error","icon":"warning"}},[_vm._v(" "+_vm._s(_vm.$t('table.noData'))+" ")])],1)],2)],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg4":"","xl4":""}},[_c('v-data-table',{staticClass:"elevation-1 ml-2 mr-2 mt-2",class:_vm.$ticketingEnabled ? 'tasks-overlay' : '',attrs:{"headers":_vm.realEstateHeaders,"items":_vm.realEstateManagerStats,"loading":_vm.taskStatsLoading,"options":_vm.editorOptions,"hide-default-footer":true,"footer-props":{itemsPerPageOptions: _vm.itemsPerPageOptions}},on:{"update:options":function($event){_vm.editorOptions=$event},"click:row":_vm.displayRealEstateManagerTasks},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.dateFormatted))])]}}],null,true)},[_c('template',{slot:"no-data"},[_c('v-alert',{attrs:{"value":true,"color":"error","icon":"warning"}},[_vm._v(" "+_vm._s(_vm.$t('table.noData'))+" ")])],1)],2)],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg4":"","xl4":""}},[_c('v-data-table',{staticClass:"elevation-1 ml-2 mt-2 mr-2",class:_vm.$ticketingEnabled ? 'tasks-overlay' : '',attrs:{"headers":_vm.craftsmenHeaders,"items":_vm.craftsmenStats,"loading":_vm.taskStatsLoading,"options":_vm.craftsmenOptions,"footer-props":{ itemsPerPageOptions: _vm.craftsmenItemsPerPageOptions, itemsPerPageText: _vm.craftsmenItemsPerPageText }},on:{"update:options":function($event){_vm.craftsmenOptions=$event},"click:row":_vm.displayCraftsmenTasks},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.dateFormatted))])]}}],null,true)},[_c('template',{slot:"no-data"},[_c('v-alert',{attrs:{"value":true,"color":"error","icon":"warning"}},[_vm._v(" "+_vm._s(_vm.$t('table.noData'))+" ")])],1)],2)],1),(_vm.$ticketingEnabled)?_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-card',{staticClass:"ml-20",attrs:{"light":""}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t('navigation.tasksList')))]),_c('v-icon',{staticClass:"float-right",attrs:{"color":"primary"},on:{"click":function($event){_vm.overlay = false}}},[_vm._v("close")])],1),_c('v-container',[_c('v-layout',{staticClass:"overlay-content",attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":"","xl12":""}},[_c('v-data-table',{staticClass:"elevation-1 mr-4 ml-4 mb-2 mt-0 tasks-table",attrs:{"headers":_vm.taskHeaders,"items":_vm.tasksList,"loading":_vm.tasksListLoading,"options":_vm.tasksListOptions},on:{"update:options":function($event){_vm.tasksListOptions=$event},"click:row":_vm.openTaskDetails},scopedSlots:_vm._u([{key:"item.taskType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('tasks.types.' + item.taskType))+" ")]}},{key:"item.createdOn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.createdOnFormatted)+" ")]}},{key:"item.jobDescription",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-0 truncate-two-lines"},[_vm._v(_vm._s(item.jobDescription))])]}}],null,true)},[_c('template',{slot:"no-data"},[_c('v-alert',{attrs:{"value":true,"color":"error","icon":"warning"}},[_vm._v(" "+_vm._s(_vm.$t('table.noData'))+" ")])],1)],2)],1)],1)],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }