<template>
  <v-tooltip bottom v-if="icon">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on" :color="icon.color" :size="22">{{ icon.name }}</v-icon>
    </template>
    <span>{{ $t('ticketingSystem.taskDetails.states.' + state) }}</span>
  </v-tooltip>
</template>

<script>
const ACTIVE = 'ACTIVE'
const COMPLETED = 'COMPLETED'
const SUSPENDED = 'SUSPENDED'
const EXTERNALLY_TERMINATED = 'EXTERNALLY_TERMINATED'
const INTERNALLY_TERMINATED = 'INTERNALLY_TERMINATED'

export default {
  name: 'StateIcon',
  props: {
    state: {
      type: String,
      default: ''
    }
  },
  computed: {
    icon () {
      switch (this.state) {
        case ACTIVE:
          return {
            color: 'blue',
            name: 'mdi-clipboard-arrow-right-outline'
          }
        case COMPLETED:
          return {
            color: 'green',
            name: 'mdi-clipboard-check'
          }
        case SUSPENDED:
          return {
            color: 'orange',
            name: 'mdi-clipboard-alert'
          }
        case EXTERNALLY_TERMINATED:
          return {
            color: 'red',
            name: 'mdi-clipboard-remove-outline'
          }
        case INTERNALLY_TERMINATED:
          return {
            color: 'red',
            name: 'mdi-clipboard-remove-outline'
          }
        default:
          return null
      }
    }
  }
}
</script>

<style scoped lang="postcss">
</style>
