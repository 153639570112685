<template>
  <v-autocomplete
    ref="realEstateManager"
    :label="$t('common.buttons.search')"
    cache-items
    single-line
    return-object
    :items="displayItems"
    item-text="displayText"
    item-value="objectId"
    :search-input.sync="search"
    v-model="mutableForm.value"
  ></v-autocomplete>
</template>

<script>

export default {
  name: 'PropertyObjects',
  props: {
    form: {
      type: Object,
      default: () => {}
    },
    displayItems: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      search: '',
      displayNewEditor: {}
    }
  },
  computed: {
    mutableForm () {
      return this.form
    }
  }
}
</script>
