<template>
  <v-select
    class="pa-0 ma-0"
    :label="$t('common.salutation')"
    :items="salutationOptions"
    v-model="salutation"
    item-value="key"
    item-text="value"
    :readonly="readOnly"
    :required="required"
    :rules="required ? [rules.required] : []"
  >
  </v-select>
</template>

<script>
import { salutationItems } from '@/config/options/salutation'

export default {
  name: 'SalutationList',
  props: {
    value: {
      type: String,
      default: ''
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      salutationOptions: salutationItems,
      rules: {
        required: (value) => (!!value && value !== undefined) || this.requiredMessage
      }
    }
  },
  computed: {
    salutation: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    requiredMessage () {
      return this.$t('common.errors.required')
    }
  }
}
</script>

<style scoped lang="postcss">
</style>
