<template>
  <div>
    <v-progress-linear indeterminate v-if="$apollo.loading"></v-progress-linear>
    <v-row dense v-else>
      <v-col cols="12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">
                {{ $t('ticketingSystem.processes.rentingForm.createContract.rentComponent') }}
              </th>
              <th class="text-right">
                {{ $t('ticketingSystem.processes.rentingForm.createContract.rentComponentAmount') }}
              </th>
              <th class="text-right">
                {{ $t('ticketingSystem.processes.rentingForm.createContract.rentComponentNewAmount') }}
              </th>
              <th class="text-left">
                {{ $t('ticketingSystem.processes.rentingForm.createContract.rentComponentComment') }}
              </th>
            </tr>
            </thead>
            <tbody>
            <template v-for="(component, index) in rentComponents">
              <tr v-if="component.amount || component.newAmount" :key="'rental-component-' + index">
                <th>{{component.rentalComponent}}</th>
                <td class="text-right">{{formatAmount(component.amount) }}</td>
                <td class="text-right"><strong>{{ formatAmount(component.newAmount) }}</strong></td>
                <td>{{ component.comment }}</td>
              </tr>
            </template>

            <tr>
              <th>{{ $t('ticketingSystem.processes.rentingForm.createContract.rentComponentDeposit') }}</th>
              <td class="text-right"></td>
              <td class="text-right">
                <strong>{{ formatAmount(deposit) }}</strong>
              </td>
              <td>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>

        <p>{{ $t('ticketingSystem.processes.rentingForm.createContract.earliestStart') }}: <strong>{{ formatDate(earliestStart) }}</strong></p>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-btn color="" target="_blank" :href="'https://abacus.prestige.ch:40001/abastart?mandant=10&program=immo.11&RealEstateNumber=' + propertyId + '&ObjectNumber=' + objectId">
          <span class="abacus-logo mr-2"></span> {{ $t('ticketingSystem.processes.rentingForm.createContract.abacusLink') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <DatePicker
          ref="tenantLiableUntil"
          v-model="relettingContractSent"
          :default-value="false"
          :label="$t('ticketingSystem.processes.rentingForm.createContract.relettingContractSent')"
          :disabled="formReadOnly"
          required
          attach-selector
          @input="updatedField"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import * as ticketingHelper from '@/features/ticketing/helpers/ticketingHelper'
import { getGraphQLVariable } from '@/helpers/graphQLHelper'
import { formatDate, formatAmount } from '@/helpers/formatterHelper'
import DatePicker from '@/components/DatePicker'
import { RELETTING_TICKETING_TICKET } from '../../graphql-apollo/relettingTicketingTicket.gql'

export default {
  name: 'RentingFormCreateContract',
  props: {
    processVariables: {
      type: Array,
      default: () => {}
    },
    formReadOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      relettingContractSent: {},
      ticketLoadError: ''
    }
  },

  apollo: {
    relettingTicketingTicket: {
      query: RELETTING_TICKETING_TICKET,
      skip () {
        return !this.relettingProcessId
      },
      error (error) {
        this.ticketLoadError = error.message
      },
      variables () {
        return {
          ticketId: this.relettingProcessId
        }
      }
    }
  },
  components: {
    DatePicker
  },
  computed: {
    propertyId () {
      return this.getVariableValue('System_RentalCase_Property')
    },
    objectId () {
      return this.getVariableValue('System_RentalCase_PropertyObject')
    },
    relettingProcessId () {
      return this.getVariableValue('System_ProcessParentReference_UUID')
    },
    rentComponents () {
      return getGraphQLVariable(this.relettingTicketingTicket?.variables, 'System_RentalContractComponents_JSON', 'rentComponents', [])
    },
    deposit () {
      return getGraphQLVariable(this.relettingTicketingTicket?.variables, 'System_ReLetting_Deposit_Long', 'longValue', 0)
    },
    earliestStart () {
      return getGraphQLVariable(this.relettingTicketingTicket?.variables, 'System_ReLetting_EarliestStart_Date', 'dateValue')
    }
  },
  methods: {
    formatDate: formatDate,
    formatAmount: formatAmount,
    getVariableValue (variableName) {
      return ticketingHelper.getVariableValue(this.processVariables, variableName)
    },
    updatedField () {
      this.$emit('updateFormValues', { formFields: [
        {
          formFieldId: 'System_RelettingContractSent_Date',
          formFieldType: 'date',
          formFieldDefaultValue: this.relettingContractSent
        }
      ]})
    }
  }
}
</script>

<style scoped lang="postcss">
.abacus-logo {
  width: 80px;
  content: url('../../../../assets/icons/abacus-logo.svg');
}
</style>
