<template>
  <v-dialog :value="true" scrollable max-width="750px" @keydown.enter.prevent="createChildTask" @keydown.esc="closeDialog" persistent>
    <v-card>
      <v-card-title>
        {{ $t('ticketingSystem.childTask.create') }}
        <v-btn icon absolute right>
          <v-icon class="clear" color="primary" :size="28" @click.native="closeDialog"/>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-layout row wrap>
            <v-flex xs12 sm12 md12 lg12 xl12>
              <v-form ref="name">
                <v-text-field
                  :label="$t('common.data.name')"
                  v-model="childTask.name"
                  :required="true"
                  :rules="[rules.required]"
                  autofocus
                >
                  <template #message="{ message }">
                    <span v-if="message === 'required'">{{ requiredMessage }}</span>
                  </template>
                </v-text-field>
              </v-form>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex xs12 sm12 md12 lg12 xl12>
              <v-textarea
                :label="$t('ticketingSystem.childTask.description')"
                v-model="childTask.description"
              ></v-textarea>
            </v-flex>
          </v-layout>
          <v-layout row wrap class="mt-7 mb-2">
            <!--
            <v-flex xs12 sm12 md6 lg6>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <FollowUpDate
                    ref="followUpDate"
                    :task-finished="false"
                    :task-details="childTask"
                    @updateFollowUpDate="updateFollowUpDateValue"
                  />
                </template>
              </v-tooltip>
            </v-flex>
            -->
            <v-flex xs12 sm12 md6 lg6>
              <v-card-text class="pt-1 pl-0 pb-2">{{ $t('ticketingSystem.note.setDueDate') }}</v-card-text>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <DueDate
                    ref="dueDate"
                    v-bind="attrs"
                    v-on="on"
                    :task-finished="false"
                    :task-details="childTask"
                    @updateDueDate="onUpdateDueDateValue"
                  />
                </template>
              </v-tooltip>
            </v-flex>
            <v-flex xs12 sm6 md6 lg6>
              <TicketPriority v-model="childTask.priority" />
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex xs12 sm12 md6 lg6>
              <v-card-text class="pl-0 pb-2">{{ $t('ticketingSystem.note.selectCandidateGroup') }}</v-card-text>
              <v-checkbox
                class="inline-flex mt-0 mr-4"
                v-for="(candidateGroup, i) in candidateGroups"
                :key="i"
                multiple
                :value="candidateGroup.groupId"
                :label="getCandidateGroupNameTranslation(candidateGroup.groupId)"
                v-model="childTask.candidateGroups"
              ></v-checkbox>
            </v-flex>
            <v-flex xs12 sm12 md6 lg6>
              <v-card-text class="pl-0">
                {{ $t('ticketingSystem.note.selectAssignee') }}
              </v-card-text>
              <TaskAssignee
                v-bind:form="assignee"
                :assigned-to-me="assignedToMe"
                :user-has-ticketing-role="true"
                @changedAssignee="changedAssignee"
                @hideAssigneeDropdown="hideAssigneeDropdown"
              />
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="closeDialog" class="mt-2">{{ $t('common.buttons.cancel') }}</v-btn>
        <v-btn color="success" @click="createChildTask" class="mt-2">
          <span>{{ $t('navigation.taskCreate') }}</span>
          <v-icon class="ml-2">send</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TaskAssignee from './TaskAssignee'
import DueDate from '@/features/ticketing/components/DueDate'
import TicketPriority from '@/features/ticketing/components/TicketPriority'
import { getCandidateGroupName } from '@/helpers/candidateGroupsHelper'

import { AuthenticationContext } from 'vue-adal'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'ChildTaskDialog',
  components: {
    TaskAssignee,
    TicketPriority,
    DueDate
  },
  data () {
    return {
      childTask: {
        name: '',
        description: '',
        candidateGroups: [],
        assignee: '',
        dueDate: null,
        followUpDate: null,
        parentTaskId: '',
        priority: 2
      },
      assignee: { },
      rules: {
        required: (value) => !!value || 'required',
        maxLength: (value) => (value.length <= 255) || this.$t('ticketingSystem.startProcess.maxLength')
      }
    }
  },
  props: {
    parentTaskId: {
      type: String,
      default: ''
    }
  },
  mounted () {
    this.assignee = {value: {userId: null}}
    this.childTask.assignee = null
    this.loadCandidateGroups()
  },
  computed: {
    ...mapState('ticketingSystem', ['candidateGroups']),
    assignedToMe () {
      return this.childTask.assignee === this.loggedInUserId
    },
    loggedInUserId () {
      return AuthenticationContext && AuthenticationContext.user && AuthenticationContext.user.profile ? AuthenticationContext.user.profile.employeeid : null
    },
    requiredMessage () {
      return this.$t('common.errors.required')
    }
  },
  methods: {
    ...mapActions('ticketingSystem', ['loadCandidateGroups']),
    ...mapActions('notifier', ['setErrorSnackbar', 'setSuccessSnackbar']),
    getCandidateGroupNameTranslation (groupId) {
      return getCandidateGroupName(groupId)
    },
    onUpdateDueDateValue (resetDueDate = false, newDueDate) {
      if (!resetDueDate) {
        this.childTask.dueDate = newDueDate
      } else {
        this.childTask.dueDate = null
      }
    },
    hideAssigneeDropdown () {
      this.showDropdown = false
    },
    changedAssignee (userId) {
      if (this.assignee.value && this.assignee.value.userId === 'assignToMe') {
        this.childTask.assignee = this.loggedInUserId
      } else if (this.assignee.value && this.assignee.value.userId !== 'unassigned') {
        this.childTask.assignee = userId
      } else {
        this.childTask.assignee = null
      }
    },
    closeDialog () {
      this.$emit('closeDialog')
    },
    createChildTask () {
      if (this.$refs.name.validate()) {
        this.childTask.parentTaskId = this.parentTaskId
        let that = this
        this.$prestigeTSTask.createTask(this.childTask).then(function (response) {
          if (response.status === 201) {
            that.setSuccessSnackbar(that.$t('tasks.createTask.successfullyCreated'))
            that.closeDialog()
            that.loadTaskDetails()
          }
          return true
        }).catch(error => {
          that.setErrorSnackbar(error)
        })
      }
    },
    loadTaskDetails () {
      this.$emit('loadTaskDetails')
    }
  }
}
</script>

<style scoped lang="postcss">
/deep/ .atwho-panel {
  margin-top: 40px;
  top: 10% !important;
}
/deep/ .editor {
  height: 150px;
}
.img-icon {
  vertical-align: middle;
}
/deep/ .datepicker-input {
  padding-top: 0;
  margin-top: 0;
}
</style>
