<template>
  <v-dialog v-model="mutableShowDialog" ref="confirmationDialog" max-width="500px" @keydown.esc="closeDialog" persistent>
    <v-card>
      <v-card-title>
        {{ data.title }}
        <v-btn icon absolute right>
          <v-icon class="clear" color="primary" :size="28" @click.native="closeDialog"/>
        </v-btn>
      </v-card-title>
      <v-card-text>
        {{ data.question }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="closeDialog" :disabled="disabled">{{ $t('common.buttons.cancel') }}</v-btn>
        <v-btn color="primary" @click.native="submitDialog" :disabled="disabled">{{ data.confirmButton }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmationDialog',
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    mutableShowDialog () {
      return this.showDialog
    }
  },
  methods: {
    closeDialog () {
      this.$emit('closeDialog')
    },
    submitDialog () {
      this.$emit('submitDialog')
    }
  }
}
</script>
