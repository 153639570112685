
import Dashboard from '../../dashboard/Dashboard'

export const DashboardRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      title: 'dashboard',
      requireAuth: true,
      requireRoles: [
        'prestige-tools-reader'
      ]
    }
  },
  {
    path: '/dashboard/address/:addressId/:addressType?/:processInstanceId?',
    name: 'DashboardPersonDetails',
    component: Dashboard,
    meta: {
      title: 'dashboard',
      requireAuth: true,
      requireRoles: [
        'prestige-tools-reader'
      ]
    }
  },
  {
    path: '/dashboard/property/:propertyId',
    name: 'DashboardPropertyDetails',
    component: Dashboard,
    meta: {
      title: 'dashboard',
      requireAuth: true,
      requireRoles: [
        'prestige-tools-reader'
      ]
    }
  }
]
