const ADDRESS_REPORT = 'ADDRESS_REPORT'
const BLOCKED_CRAFTSMEN_REPORT = 'BLOCKED_CRAFTSMEN_REPORT'
const CORPORATION_MASTER_DATA_REPORT = 'CORPORATION_MASTER_DATA_REPORT'
const HEATING_OIL_TANK_REPORT = 'HEATING_OIL_TANK_REPORT'
const PROPERTY_REPORT = 'PROPERTY_REPORT'
const PROPERTY_CLIENT_REPORT = 'PROPERTY_CLIENT_REPORT'
const PROPERTY_PARTNER_REPORT = 'PROPERTY_PARTNER_REPORT'
const PRIORITY_CRAFTSMEN_REPORT = 'PRIORITY_CRAFTSMEN_REPORT'
const RENT_COMPONENTS_REPORT = 'RENT_COMPONENTS_REPORT'
const RENTAL_CONTRACTS_REPORT = 'RENTAL_CONTRACTS_REPORT'
const RENTAL_CONTRACT_PARTNERS_REPORT = 'RENTAL_CONTRACT_PARTNERS_REPORT'
const RENTAL_CONTRACT_SUBLET_REPORT = 'RENTAL_CONTRACT_SUBLET_REPORT'
const MARKET_REPORT = 'MARKET_REPORT'
const SERVICE_CHARGE_STATEMENT_REPORT = 'SERVICE_CHARGE_STATEMENT_REPORT'
const SPECIALITIES_REPORT = 'SPECIALITIES_REPORT'

export const REPORT_NAMES = {
  ADDRESS_REPORT,
  BLOCKED_CRAFTSMEN_REPORT,
  CORPORATION_MASTER_DATA_REPORT,
  HEATING_OIL_TANK_REPORT,
  PROPERTY_REPORT,
  PROPERTY_CLIENT_REPORT,
  PROPERTY_PARTNER_REPORT,
  PRIORITY_CRAFTSMEN_REPORT,
  RENT_COMPONENTS_REPORT,
  RENTAL_CONTRACTS_REPORT,
  RENTAL_CONTRACT_PARTNERS_REPORT,
  RENTAL_CONTRACT_SUBLET_REPORT,
  MARKET_REPORT,
  SERVICE_CHARGE_STATEMENT_REPORT,
  SPECIALITIES_REPORT
}