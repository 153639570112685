<template>
  <v-card v-if="process" class="process-info-content-card">
    <v-card-text class="pa-4">
      <v-btn
        class="float-right pa-3 mr-2"
        @click="startRelatedProcess"
      >
        <span>{{ $t('ticketingSystem.taskDetails.buttons.startRelatedProcess') }}</span>
        <v-icon class="pl-1">mdi-plus</v-icon>
      </v-btn>
      <v-menu offset-y max-height="500" max-width="400" v-if="isReadyForMerge">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="float-right pa-3 mr-2"
            v-bind="attrs"
            v-on="on"
            @click="$apollo.queries.ticketingTickets.refresh()"
          >
            {{$t('ticketingSystem.mergeTicket.btnTitle')}}
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in ticketsList"
            :key="index"
            @click="mergeTickets(item.ticketId)"
          >
            <v-list-item-title class="title-wrap">
              [ <span class="merge-title text-truncate">{{ item.ticketId }}</span> ]
              {{ item.subject }}
            </v-list-item-title>
          </v-list-item>
          <infinite-loading @infinite="infiniteHandler">
            <div slot="no-more"></div>
            <div slot="no-results"></div>
          </infinite-loading>
        </v-list>
      </v-menu>
      <v-btn
        class="float-right pa-3 mr-2"
        @click="restartProcess"
        v-if="isRestartOfProcessAllowed"
        :loading="loading"
      >
        <span>{{ $t('ticketingSystem.taskDetails.buttons.restartProcess') }}</span>
      </v-btn>
      <v-btn v-if="!externallyTerminated" small icon ripple right class="float-right mt-1 mr-2" @click="openDeleteDialog" :title="$t('ticketingSystem.deleteProcess.title')">
        <v-icon color="red lighten-1" dense>delete</v-icon>
      </v-btn>
      <ProcessReasonDeleteDialog 
        :processInstanceId="processInstanceId"
        :show-dialog="showDeleteDialog"
        @closeDialog="closeDeleteDialog" 
      />
      <v-layout row>
        <v-flex class="pa-0 mx-0 my-2 process-info">
          <v-card-text class="pa-0 d-flex">
            <v-tooltip v-if="!processDetailsPage" bottom>
              <template v-slot:activator="{ on, attrs }">
                <router-link :to="{ name: TICKETING_PROCESS_DETAILS, params: { processInstanceId: processInstanceId, processDefinitionKey: process.processDefinitionKey }}" v-bind:class="{'mt-1 mr-2 w-m-75' : !processDetailsPage}">
                  <span class="ma-0 gray-text">
                    [<span v-bind="attrs" v-on="on" class="ma-0 truncate-one-line process-instance-id">
                      {{ processInstanceId }}
                    </span>]
                  </span>
                </router-link>
              </template>
              <span>{{ $t('ticketingSystem.processes.processDetails') }}</span>
            </v-tooltip>
            <v-card-subtitle v-if="!processQueryId" class="pa-0 font-weight-bold subtitle-1 w-p-90">
              <span class="truncate-one-line">
                {{ processInfo }}
                <span v-if="processSubject" class="process-subject">&nbsp;&middot;&nbsp;<span class="cy-ticket-detail-subject">{{ processSubject }}</span></span>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon v-if="!processFinished" class="ma-2" size="18" color="blue" @click="openProcessSubjectDiagram">edit</v-icon>
                    </v-btn>
                  </template>
                  <span>{{$t('ticketingSystem.tooltipEditSubject')}}</span>
                </v-tooltip>
              </span>
            </v-card-subtitle>
          </v-card-text>
        </v-flex>
      </v-layout>
      <v-layout row wrap class="mx-0 py-2">
        <v-flex xs12 sm12 md12 lg2 xl2>
          <v-card-text class="pa-0 column-title cy-ticket-detail-state">
            <span class="pr-2">{{ $t('ticketingSystem.taskDetails.state') }}:</span>
            <StateIcon :state="state" />
          </v-card-text>
        </v-flex>
        <v-flex xs12 sm12 md12 lg3 xl3>
          <v-tooltip bottom>
             <template v-slot:activator="{ on, attrs }">
               <span class="vertical-align-sub">
                 <v-icon color="grey darken-2" :size="22" v-bind="attrs" v-on="on">mdi-calendar-start</v-icon>
                 <span class="pl-2">{{ formattedStartedOnDate }}</span>
               </span>
             </template>
             <span>{{ $t('common.date.createdOn') }}</span>
          </v-tooltip>
        </v-flex>
        <v-flex xs12 sm12 md12 lg2 xl2>
          <v-tooltip bottom>
             <template v-slot:activator="{ on, attrs }">
               <span class="vertical-align-sub">
                 <v-icon color="grey darken-2" :size="22" v-bind="attrs" v-on="on">mdi-calendar-end</v-icon>
                 <span class="pl-2 cy-ticket-detail-finished-on">{{ formattedFinishedOn }}</span>
               </span>
             </template>
             <span>{{ $t('common.date.finishedOn') }}</span>
          </v-tooltip>
        </v-flex>
        <v-flex xs12 sm12 md12 lg3 xl3>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span class="vertical-align-sub">
                <v-icon color="grey darken-2" :size="22" v-bind="attrs" v-on="on">person_outline</v-icon>
                <span class="pl-2"><UserName :user="process.startUser" /></span>
              </span>
            </template>
            <span>{{ $t('ticketingSystem.entry.assignee') }}</span>
          </v-tooltip>
        </v-flex>
        <v-flex xs12 sm12 md12 lg2 xl2>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon @click="openDiagram" v-bind="attrs" v-on="on" class="mr-2 diagram-btn">
                <img src="@/assets/icons/diagram.svg" />
              </v-btn>
            </template>
            <span>{{ $t('ticketingSystem.processes.displayDiagram') }}</span>
          </v-tooltip>
        </v-flex>
      </v-layout>
    </v-card-text>
    <DiagramDialog
      ref="diagram"
      v-if="showDiagramDialog"
      :process-definition-id="process.processDefinitionId"
      :process-instance-id="processInstanceId"
      :show-dialog="showDiagramDialog"
      @closeDialog="showDiagramDialog = false"
      @openTask="openTask"
    />
    <ProcessSubjectDialog
      :process="process"
      ref="processSubjectDiagram"
      :show-dialog="showProcessSubjectDialog"
      @closeDialog="showProcessSubjectDialog = false"
      @loadProcess="loadProcess"
    />
  </v-card>
</template>

<script>
import DiagramDialog from './process-diagram/DiagramDialog'
import ProcessSubjectDialog from './ProcessSubjectDialog'
import ProcessReasonDeleteDialog from './ProcessReasonDeleteDialog'
import StateIcon from '@/components/StateIcon'
import { formatDateAndTime } from '@/helpers/formatterHelper'
import { mapActions, mapState } from 'vuex'
import { TICKETING_PROCESS_DETAILS } from '@/config/options/routes/ticketingRoutes'
import { TICKETING_TICKETS } from '../graphql-apollo/ticketingTickets.gql'
import { MERGE_TICKETS } from '../graphql-apollo/mergeTickets.gql'
import UserName from '@/components/UserName'
import InfiniteLoading from 'vue-infinite-loading'

export default {
  name: 'ProcessInfo',
  components: {
    UserName,
    ProcessSubjectDialog,
    DiagramDialog,
    StateIcon,
    ProcessReasonDeleteDialog,
    InfiniteLoading
  },
  props: {
    process: {
      type: Object,
      default: () => {}
    },
    processVariables: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      TICKETING_PROCESS_DETAILS: TICKETING_PROCESS_DETAILS,
      showDiagramDialog: false,
      showProcessSubjectDialog: false,
      loading: false,
      showDeleteDialog: false,
      ticketsList: [],
      page: 0,
      totalItems: 0
    }
  },
  apollo: {
    ticketingTickets: {
      query: TICKETING_TICKETS,
      variables () {
        return {
          query: {
            processDefinitionKey: 'Z1_01',
            onlyActive: true,
            page: 0,
            size: 20
          }
        }
      },
      manual: true,
      result ({ data }) {
        if (data?.ticketingTickets) {
          this.ticketsList = [...data.ticketingTickets.items].filter(x => x.ticketId !== this.$route.params.processInstanceId)
          this.totalItems = data.ticketingTickets.totalItems        
        }
      },
      error (error) {
        this.setErrorSnackbar(error)
      },
      skip () {
        return !this.$route.params.processInstanceId && this.process.processDefinitionKey !== 'Z1_01'
      }
    }
  },
  computed: {
    ...mapState('ticketingSystem', ['ticketingUsers']),
    processDetailsPage () {
      return this.$route.params.processInstanceId && this.$route.params.processInstanceId !== null
    },
    processInstanceId () {
      return this.process.ticketId
    },
    processQueryId () {
      return this.$route.query.taskId
    },
    processInfo () {
      return this.process.processDefinitionName
    },
    processSubject () {
      return this.process.subject
    },
    processFinished () {
      return this.process && this.process.finishedOn !== '' && this.process.finishedOn !== null
    },
    state () {
      return this.process.state
    },
    externallyTerminated () {
      return this.state == 'EXTERNALLY_TERMINATED' || this.state == 'COMPLETED'
    },
    formattedStartedOnDate () {
      return this.formatDateAndTimeFunc(this.process.startedOn)
    },
    formattedFinishedOn () {
      return this.formatDateAndTimeFunc(this.process.finishedOn)
    },
    processInstanceIdText () {
      return '[' + this.processInstanceId + ']'
    },
    isRestartOfProcessAllowed () {
      let processHasUuid = false
      this.processVariables.forEach(value => {
        if (value.variableName === 'System_ProcessIdentifier_UUID') {
          processHasUuid = true
        }
      })
      return this.process.processDefinitionKey === 'Z1_01' && this.process.finishedOn != null && processHasUuid
    },
    isReadyForMerge () {
      return this.process.processDefinitionKey === 'Z1_01' && this.ticketsList.length > 0 && this.process.state === 'ACTIVE'
    }
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar', 'setSuccessSnackbar']),
    formatDateAndTimeFunc: formatDateAndTime,
    openDiagram () {
      this.showDiagramDialog = true
    },
    openProcessSubjectDiagram () {
      this.showProcessSubjectDialog = true
    },
    loadProcess () {
      this.$emit('loadProcess')
    },
    async openTask (taskId, taskFinished) {
      this.$emit('openTaskDetailsDialog', taskId, taskFinished)
    },
    startRelatedProcess () {
      this.$emit('startRelatedProcess')
    },
    restartProcess () {
      let that = this
      this.loading = true
      this.$prestigeTSProcess.restartProcess(this.processInstanceId).then(response => {
        that.loading = false
        const processInstanceId = response.data.processInstanceId
        this.$router.push({ name: TICKETING_PROCESS_DETAILS, params: { processInstanceId } })
      }).catch(error => {
        that.loading = false
        that.setErrorSnackbar(error)
      })
    },
    openDeleteDialog () {
      this.showDeleteDialog = true
    },
    closeDeleteDialog () {
      this.showDeleteDialog = false
    },
    mergeTickets(mergeTicketId) {
      this.$apollo.mutate({
        mutation: MERGE_TICKETS,
        variables: {
          baseTicket: this.processInstanceId,
          mergeTicket: mergeTicketId
        }
      }).then(() => {
        this.setSuccessSnackbar(this.$t('ticketingSystem.mergeTicket.success'))
        setTimeout(() => {
          window.location.reload()
        }, 100)  
      }).catch((error) => {
        this.setErrorSnackbar(error)
      })
    },
    async infiniteHandler ($state) {
      this.page++
      await this.$apollo.queries.ticketingTickets.fetchMore({
        variables: {
          query: {
            processDefinitionKey: 'Z1_01',
            onlyActive: true,
            page: this.page,
            size: 20
          }
        },
        updateQuery: (previous, {fetchMoreResult}) => {
          this.ticketsList.push(...fetchMoreResult.ticketingTickets.items)
          this.totalItems = fetchMoreResult.ticketingTickets.totalItems
          return {
            ...previous
          }
        }
      })
      if (this.ticketsList.length < this.totalItems) {
        $state.loaded()
      } else {
        $state.complete()
      }
    }
  }
}
</script>

<style scoped lang="postcss">
.column-title {
  font-size: 1em;
}
.process-info {
  max-height: 20px;
}
.process-info a {
  text-decoration: none;
}
.gray-text {
  color: rgba(0,0,0,.6);
}
.truncate-one-line {
  display: -webkit-inline-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.process-instance-id {
  color: #1976d2;
  text-decoration: none;
  vertical-align: middle;
  max-width: 60px;
  padding-left: 2px;
}
.process-subject {
  height: 20px;
}
.vertical-align-sub {
  vertical-align: sub;
}
.diagram-btn {
  margin-left: -5px;
}
.w-m-75 {
  width: 75px;
  max-width: 75px;
}
.w-p-90 {
  width: 90%;
}
.title-wrap {
  white-space: pre-line;
  line-height: 1.5!important;
}
.merge-title {
  display: inline-block;
  vertical-align: middle;
  max-width: 55px;
}
</style>
