<template>
  <v-card class="mb-2 pa-0 bot-override-context" v-show="show">
    <v-card-subtitle class="pa-2 font-weight-bold subtitle-1">
      {{ $t('ticketingSystem.botOverride.context.title') }}
    </v-card-subtitle>
    <v-card-text class="pl-2">
      <v-autocomplete
        class="ma-0 pa-0"
        :label="$t('ticketingSystem.botOverride.context.description')"
        single-line
        return-object
        loading="processDefinitionsLoading"
        :items="filteredProcessDefinitions"
        item-text="displayText"
        item-value="processDefinitionKey"
        clearable
        v-model="botOverride"
      />
      <v-btn @click.stop="overrideBot" :disabled="!botOverride">
        {{ $t('ticketingSystem.botOverride.context.submitButton') }}
      </v-btn>
    </v-card-text>
  </v-card>
</template>
<script>
import _cloneDeep from 'lodash.clonedeep'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'BotOverrideContext',
  components: {
  },
  data () {
    return {
      botOverride: null
    }
  },
  props: {
    process: {
      type: Object,
      default: () => {}
    },
    processVariables: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState('ticketingSystem', ['createProcessDefinitions', 'processDefinitionsLoading']),
    filteredProcessDefinitions () {
      if (this.createProcessDefinitions && this.processVariables) {
        let that = this
        let displayItems = _cloneDeep(this.createProcessDefinitions).filter(processDefinition => processDefinition.processDefinitionKey >= 'Z1_02' && processDefinition.processDefinitionKey <= 'Z1_10')
        return displayItems.map(processDefinition => {
          let process = that.processVariables.find(x => x.variableName.includes(processDefinition.processDefinitionKey))
          if (process) {
            processDefinition.displayText = processDefinition.processDefinitionName + ' (' + that.displayPercentage(process.variableValue) + ')'
          }
          return processDefinition
        })
      } else {
        return []
      }
    },
    show () {
      return this.process && this.process.startUserId === 'bot' && this.process.state === 'ACTIVE'
    }
  },
  methods: {
    ...mapActions('notifier', ['setSuccessSnackbar']),
    overrideBot () {
      let data = {
        newLinks: [],
        variables: [
          {
            variableName: 'System_BotCategoryOverride_Enum',
            variableValue: this.botOverride.processDefinitionKey,
            variableType: 'string'
          }
        ]
      }
      this.$prestigeTSProcess.saveProcessVariables(this.process.processInstanceId, data).then(async (response) => {
        this.setSuccessSnackbar(this.$t('ticketingSystem.botOverride.context.successMessage'))
        this.$emit('loadDetails')
      })
    },
    displayPercentage (value) {
      return (value ? Number(100 * value).toFixed(2) : '') + '%'
    }
  }
}
</script>

<style scoped lang="postcss">
</style>
