<template>
  <v-container grid-list-md fluid>
    <v-card>
      <v-card-title>{{ $t('feedback.list.feedbackExcel') }}
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="clearDates()" class="ml-1" v-bind="attrs" v-on="on" icon float>
              <v-icon color="primary">mdi-calendar-remove</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('feedback.list.clearDates') }}</span>
        </v-tooltip>
      </v-card-title>
      <v-form ref="form">
        <v-layout class="row pl-2 pr-3">
          <v-flex class="pr-2" xs12 sm12 md6 lg6 xl6>
          <DatePicker ref="datePickerFrom" :label="$t('statistics.date.from')" :from="true" v-model="form.from" :default-value="true" />
          </v-flex>
          <v-flex class="pl-2" xs12 sm12 md6 lg6 xl6>
          <DatePicker ref="datePickerTo" :label="$t('statistics.date.to')" v-model="form.to" :default-value="true" />
          </v-flex>
          <v-btn :loading="excelLoading" class="primary ml-2 mb-5 pl-2 pr-2" @click="downloadExcel()">
            <v-icon>file_download</v-icon> {{ $t('heatingOil.orders.downloadExcel') }}
          </v-btn>
        </v-layout>
      </v-form>
    </v-card>
    <v-card class="mt-4">
      <v-card-title>{{ $t('feedback.list.feedbacks') }}</v-card-title>
      <v-data-table
        :headers="headers"
        :items="feedbacks"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalItems"
        :footer-props="{itemsPerPageOptions:itemsPerPageOptions, itemsPerPageText: itemsPerPageText}"
        must-sort
        class="elevation-1"
      >
        <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
        <template v-slot:[`item.feedbackRequested`]="{ item }">
          {{ item.feedbackRequestedFormatted }}
        </template>
        <template v-slot:[`item.feedbackFormSubmitted`]="{ item }">
          {{ item.feedbackFormSubmittedFormatted }}
        </template>
        <template v-slot:[`item.feedbackFormOpened`]="{ item }">
          {{ item.feedbackFormOpenedFormatted }}
        </template>
        <template v-slot:[`item.taskDone`]="{ item }">
          <v-icon v-if="item.taskDone" color="teal" size="18" class="ml-6">mdi-check-outline</v-icon>
          <v-icon v-else color="gray" size="18" class="ml-6">mdi-close-outline</v-icon>
        </template>
        <template v-slot:[`item.scoreExecution`]="{ item }">
          <v-rating v-if="item.scoreExecution > 0" v-model="item.scoreExecution" readonly small dense></v-rating>
          <span v-else>n/a</span>
        </template>
        <template v-slot:[`item.scoreCraftsman`]="{ item }">
          <v-rating v-if="item.scoreExecution > 0" v-model="item.scoreCraftsman" readonly small dense></v-rating>
          <span v-else>n/a</span>
        </template>
        <template v-slot:[`item.scorePrestige`]="{ item }">
          <v-rating v-if="item.scorePrestige > 0" v-model="item.scorePrestige" readonly small dense></v-rating>
          <span v-else>n/a</span>
        </template>
        <template v-slot:[`item.comment`]="{ item }">
          <span class="text-pre-wrap">{{ item.comment }}</span>
        </template>
        <template v-slot:[`item.feedbackReference`]="{ item }">
          <v-btn @click="navigateToTaskDetails(item.feedbackReference)" icon float :disabled="!item.feedbackReference">
            <v-icon color="primary">link</v-icon>
          </v-btn>
        </template>
        <template slot="no-data">
          <v-alert :value="true" color="error" icon="warning">
            {{ $t('table.noData') }}
          </v-alert>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import DatePicker from '../../components/DatePicker'
import { formatDateAndTime } from '@/helpers/formatterHelper'
import { mapActions } from 'vuex'
import { TASK_DETAILS } from '@/config/options/routes/taskRoutes'

export default {
  name: 'FeedbackList',
  data () {
    return {
      feedbacks: [],
      loading: true,
      itemsPerPageOptions: [ 10, 25, 50, 100 ],
      options: {
        sortDesc: [true],
        sortBy: ['feedbackRequested'],
        page: 1
      },
      form: {
        from: null,
        to: null
      },
      totalItems: 0,
      excelLoading: false
    }
  },
  components: {
    DatePicker
  },
  mounted () {
    this.loadData()
  },
  computed: {
    headers () {
      return [
        {
          text: this.$t('feedback.list.requested'),
          align: 'left',
          value: 'feedbackRequested'
        },
        {
          text: this.$t('feedback.list.opened'),
          align: 'left',
          value: 'feedbackFormOpened'
        },
        {
          text: this.$t('feedback.list.answered'),
          align: 'left',
          value: 'feedbackFormSubmitted'
        },
        {
          text: this.$t('common.data.name'),
          align: 'left',
          value: 'respondent'
        },
        {
          text: this.$t('feedback.list.jobDone'),
          align: 'left',
          value: 'taskDone'
        },
        {
          text: this.$t('feedback.list.assessment'),
          align: 'left',
          value: 'scoreExecution'
        },
        {
          text: this.$t('feedback.list.reviewCraftsmen'),
          align: 'left',
          value: 'scoreCraftsman'
        },
        {
          text: this.$t('feedback.list.rating'),
          align: 'left',
          value: 'scorePrestige'
        },
        {
          text: this.$t('feedback.list.comment'),
          align: 'left',
          value: 'comment'
        },
        {
          text: this.$t('feedback.list.reference'),
          align: 'left',
          value: 'feedbackReference'
        }
      ]
    },
    itemsPerPageText () {
      return this.$t('common.itemsPerPageText')
    }
  },
  watch: {
    // call again the method if the route changes
    '$route': 'loadData',
    search: {
      handler () {
        this.loadData()
      },
      deep: true
    },
    options: {
      handler () {
        this.loadData()
      },
      deep: true
    }
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    formatDateAndTimeFunc: formatDateAndTime,
    loadData () {
      this.loading = true
      const { sortDesc, page, itemsPerPage } = this.options
      let sortBy = this.options.sortBy[0] === 'respondent' ? 'respondentFirstName' : this.options.sortBy[0]
      let direction = sortDesc && sortDesc[0] ? 'desc' : 'asc'
      let that = this
      this.$prestigeFeedback.getAllFeedbacks(page - 1, itemsPerPage, sortBy, direction).then(function (response) {
        let responseData = response.data
        that.feedbacks = responseData.items || []
        if (that.feedbacks.length > 0) {
          that.feedbacks.forEach(item => {
            item.feedbackRequestedFormatted = item.feedbackRequested ? that.formatDateAndTimeFunc(item.feedbackRequested) : 'n/a'
            item.feedbackFormSubmittedFormatted = item.feedbackFormSubmitted ? that.formatDateAndTimeFunc(item.feedbackFormSubmitted) : 'n/a'
            item.feedbackFormOpenedFormatted = item.feedbackFormOpened ? that.formatDateAndTimeFunc(item.feedbackFormOpened) : 'n/a'
          })
        }
        that.totalItems = responseData.totalItems
        that.loading = false
      }).catch(error => {
        that.feedbacks = []
        that.totalItems = 0
        that.loading = false
        that.setErrorSnackbar(error)
      })
    },
    navigateToTaskDetails (feedbackReference) {
      this.$router.push({ name: TASK_DETAILS, params: { taskId: feedbackReference } })
    },
    downloadExcel () {
      this.excelLoading = true
      let that = this
      const config = { responseType: 'blob' }
      this.$prestigeFeedback.getFeedbackExcelReport(this.form.from, this.form.to, config).then(function (response) {
        const headers = response.headers
        const fileName = headers['content-disposition'] ? headers['content-disposition'].split('filename=')[1] : ''
        const file = {
          data: response.data,
          name: fileName ? fileName.substring(1, fileName.length - 1) : 'feedback-export.xlsx'
        }
        that.excelLoading = false
        that.downloadFile(file)
        return true
      }).catch(error => {
        that.excelLoading = false
        that.setErrorSnackbar(error)
      })
    },
    downloadFile (excelFile) {
      let fileDownload = require('js-file-download')
      fileDownload(excelFile.data, excelFile.name)
    },
    clearDates () {
      this.form.from = null
      this.form.to = null
      this.$refs.datePickerFrom.clearDate()
      this.$refs.datePickerTo.clearDate()
    }
  }
}
</script>

<style scoped lang="postcss">
/deep/ tr:nth-child(odd) {
  background: #f8f8f8;
}
.text-pre-wrap {
  white-space: pre-wrap;
}
</style>
