import AbacusTicketingUpdate from "@/features/abacus/abacus-ticketing/AbacusTicketingUpdate";

export default {
  abacus: {
    ticketing: {
      common: {
        openTicket: 'Ticket öffnen',
        missingInput: 'Parameter fehlt: ',
        taskNotFoundException: 'Der Task wurde nicht gefunden',
        ticketNotFoundException: 'Das Ticket wurde nicht gefunden'
      },
      complete: {
        success: 'Task wurde abgeschlossen',
        failure: 'Task konnte konnte nicht abgeschlossen werden: '
      },
      start: {
        success: 'Das Ticket wurde erfolgreich erstellt',
        ticketAlreadyExists: 'Ein Ticket von diesem Typ existiert bereits für die definierte ID',
        failure: 'Ticket konnte nicht erstellt werden: '
      },
      update: {
        success: 'Task wurde aktualisiert',
        failure: 'Task konnte konnte nicht aktualisiert werden: '
      }
    }
  },
  admin: {
    search: {
      reindexAbacusAddresses: 'Abacus-Adressen indexieren',
      reindexAbacusAddressesDone: 'Anzahl Adressen in Warteschlange: ',
      reindexRentingAddresses: 'Mietadressen neu indexieren',
      reindexRentingAddressesDone: 'Anzahl Adressen in Warteschlange: ',
      reindexTickets: 'Tickets indexieren',
      reindexTicketsDone: 'Anzahl Tickets in Warteschlange: '
    }
  },
  autocomplete: {
    hintCaretaker: '@h Hauswart',
    hintChars: 'mit mindestens 3 Zeichen [a-z] entsprechend.',
    hintClient: '@e Eigentümer',
    hintCraftsman: '@a Handwerker',
    hintProperties: '@i Immobilien',
    hintObjects: '@o Objekte',
    hintSearchBy: 'Suche nach:',
    hintPartner: ' @p Partner',
    hintTenant: ' @m Mieter',
    hintUser: '@u Ticketing-Benutzer'
  },
  bpmnDiagram: {
    actions: {
      exitFullscreen: 'Vollbild beenden',
      fitToViewport: 'Zum Ansichtsfenster anpassen',
      openFullscreen: 'Vollbild öffnen',
      zoomIn: 'Vergrössern',
      zoomOut: 'Verkleinern'
    },
    legend: {
      title: 'Legende: Farben von Tasks',
      status: {
        activeCompleted: 'Abgeschlossen (geöffnet)',
        activeOpened: 'Zu bearbeiten (geöffnet)',
        completed: 'Abgeschlossen',
        opened: 'Zu bearbeiten'
      }
    },
    messages: {
      noDiagram: 'Dieses Diagramm existiert nicht.'
    }
  },
  combobox: {
    addNew: 'um einen neuen Eintrag hinzuzufügen',
    noResultsMatching: 'Keine passenden Ergebnisse',
    press: 'Drücken Sie'
  },
  common: {
    address: 'Adresse',
    addressFields: {
      place: 'Ort',
      street: 'Strasse',
      zip: 'PLZ'
    },
    all: 'Alle',
    buttons: {
      add: 'Hinzufügen',
      backToList: 'Zurück zur Liste',
      cancel: 'Abbrechen',
      copy: 'Kopieren',
      copyEmail: 'E-Mail kopieren',
      close: 'Schliessen',
      confirm: 'Bestätigen',
      delete: 'Löschen',
      download: 'Herunterladen',
      edit: 'Bearbeiten',
      save: 'Speichern',
      search: 'Suchen',
      select: 'Wählen',
      send: 'Senden',
      open: 'Öffnen',
      preview: 'Vorschau',
      rename: 'Umbenennen'
    },
    candidateGroups: {
      bewirtschaftung: 'Bewirtschaftung',
      buchhaltung: 'Buchhaltung',
      general: 'Kundendienst',
      innendienst: 'Backoffice',
      sachbearbeitung: 'Sachbearbeiter',
    },
    caretakers: 'Hauswarte',
    clients: 'Eigentümer',
    craftsmen: 'Handwerker',
    contractStartDate: 'Vertragsbeginn',
    contractEndDate: 'Vertragsende',
    data: {
      name: 'Name',
      tel: 'Tel',
      telPhone: 'Telefon',
      mobile: 'Mobile',
      email: 'E-mail',
      url: 'Url'
    },
    date: {
      customDate: 'Benutzerdefiniertes Datum',
      creationDate: 'Erstellungsdatum',
      createdOn: 'Gestartet am',
      dayAfterTomorrow: 'Übermorgen',
      dueOn: 'Fällig am',
      finishedOn: 'Beendet am',
      followUpOn: 'Nachfassen am',
      lastMonth: 'Letzter Monat',
      lastYear: 'Letztes Jahr',
      title: 'Datum',
      today: 'Heute',
      tomorrow: 'Morgen',
      formatHint: 'Format: TT.MM.JJJJ'
    },
    dialogs: {
      removeFile: {
        title: 'DATEI LÖSCHEN',
        question: 'Möchten Sie diesen Datei wirklich löschen?'
      }
    },
    errors: {
      dataNotLoaded: 'Daten konnten nicht geladen werden: ',
      error401: 'Ein Login ist notwendig um auf diese Resource zuzugreifen.',
      error403: 'Fehlende Berechtigung um auf diese Resource zuzugreifen.',
      error404: 'Diese Ressource wurde nicht gefunden.',
      error503: 'Dieser Dienst ist nicht verfügbar.',
      invalidDate: 'Ungültiges Datum',
      invalidEmail: 'Ungültige E-Mail',
      required: 'Pflichtfeld'
    },
    id: 'ID',
    itemsPerPageText: 'Zeilen pro Seite:',
    languages: {
      de: 'Deutsche',
      en: 'Englisch'
    },
    mapAddressTypes: {
      BUILDER: 'Bauherr',
      ARCHITECT: 'Architekt',
      GENERAL_CONTRACTOR: 'GU'
    },
    messages: {
      confirmDelete: 'Sind Sie sicher, dass Sie den Auftrag löschen möchten?',
      failed: 'Fehlgeschlagen',
      saveChanges: 'Bitte speichern Sie die Änderungen.',
      successfullyCopied: 'Daten wurden erfolgreich kopiert.',
      successfullyDeleted: 'Erfolgreich gelöscht.',
      successfullyUpdated: 'Daten wurden erfolgreich gespeichert.'
    },
    noTitle: 'Kein Titel definiert',
    objectFields: {
      floor: 'Stockwerk'
    },
    option: {
      name: 'Name',
      value: 'Wert'
    },
    options: {
      yes: 'Ja',
      no: 'Nein'
    },
    or: 'oder',
    properties: 'Immobilien',
    propertyObjects: 'Objekte',
    objects: 'Objekte',
    partners: 'Partner',
    tenants: 'Mieterinnen',
    realEstateManager: 'Bewirtschafter',
    salutation: 'Anrede',
    salutationOptions: {
      'MR': 'Herr',
      'MRS': 'Frau'
    },
    tickets: 'Tickets'
  },
  constructionProjects: {
    between: 'zwischen Immobilie und Bauvorhaben:',
    constructionStart: 'Baubeginn',
    flagProperty: 'Immobilie markieren',
    ignoreProperty: 'Immobilie ignorieren',
    info: 'Informationen zum Bauvorhaben',
    inThePast: 'liegt in der Vergangenheit',
    latest: 'Neustes Bauvorhaben:',
    locationAmbiguous: 'Ort Mehrdeutig',
    maxDistance: 'Max. Distanz',
    meter: 'Meter',
    planningStage: 'Planungs stadium',
    showIgnored: 'Ignorierte anzeigen',
    status: {
      title: 'Status',
      UNDEFINED: 'Nicht definiert',
      FLAG: 'Markiert',
      IGNORE: 'Ignoriert'
    },
    title: 'Bauvorhaben',
    updated: 'aktualisiert'
  },
  dashboard: {
    actions: {
      address: 'Adresse A11',
      newTask: 'Neuer Auftrag',
      newTicket: 'Neues Ticket',
      termination: 'Kündigung',
      offlineRentalApplication: 'Anmeldung (Offline)',
      object: {
        contract: 'Vertrag',
        title: 'Objekt'
      },
      property: {
        title: 'Immobilie',
        tenantList: 'Mieterspiegel',
        sharepoint: 'Sharepoint'
      },
      tenant: {
        title: 'Mieter',
        accontStatementTenant: 'Kontoauszug Mieter'
      }
    },
    abacus: 'Abacus',
    all: 'Alle',
    blockedProperties: 'Blockierte Immobilien',
    caretaker: 'Hauswart',
    craftsman: 'Handwerker',
    craftsmen: 'Handwerker',
    client: 'Eigentümer',
    clearForm: 'Form löschen',
    form: {
      addressId: 'Adress-ID',
      email: 'Email',
      integerNumber: 'Bitte geben Sie eine gültige Adress-ID ein',
      name: 'Name',
      phone: 'Telefon'
    },
    lookup: 'Dashboard-Suche',
    matchingAddresses: 'Übereinstimmende Adressen',
    partner: 'Partner von',
    person: 'Person',
    personTickets: {
      title: 'Mit Person verknüpfte Tickets'
    },
    property: 'Immobilie',
    propertyPartners: 'Immobilienpartner',
    propertyTickets: {
      title: 'Mit Immobilie verknüpfte Tickets'
    },
    sublet: 'Untermieter',
    taskList: {
      title: 'Verknüpfte Aufträge',
      subject: 'Betreff',
      description: 'Beschreibung',
      createdOn: 'Erstellt'
    },
    tenant: 'Mieter',
    priorityProperties: 'Priorität Immobilien',
    realEstateManager: 'Bewirtschafter',
    relatedProperties: 'Verbunden Immobilien',
    relatedObjects: 'Verbunden Objekte',
    rentingForm: 'Anmeldung'
  },
  dataImport: {
    abacus: {
      addresses: 'Adressen',
      properties: 'Immobilien',
      blockedCraftsmen: 'Blockierte Handwerker',
      deleted: 'Gelöscht',
      fromTo: 'Von - Bis',
      inserted: 'Hinzugefügt',
      job: 'Job',
      priorityCraftsmen: 'Vorrangige Handwerker',
      rentalContractPartner: 'Vertragspartner',
      rentComponents: 'Mietzinsbestandteile',
      serviceChargeStatements: 'NK Abrechnungen',
      status: 'Status',
      tanks: 'Tanks',
      rentalContracts: 'Mietverträge',
      sublets: 'Untermieter',
      partner: 'Partner',
      clients: 'Eigentümer',
      updated: 'Geändert'
    },
    dossier: {
      fileShare: 'Dateifreigabe'
    },
    immoSparrow: {
      marketRents: 'Marktmieten',
      title: 'ImmoSparrow'
    },
    silentsoft: {
      title: 'Silentsoft',
      tankLevel: 'Silentsoft Tank Level'
    },
    success: 'Daten wurden erfolgreich importiert.'
  },
  dossier: {
    corporations: {
      recipientDocuments: 'Dokumente Unternehmen',
      dossierDocuments: 'Dokumente Dossier',
      dossierPrint: 'Dossier drucken',
      pdf: 'PDF',
      print: 'Drucken',
      printouts: 'Ausdrucke',
      propertyDocuments: 'Dokumente Liegenschaft',
      property: 'Liegenschaft',
      properties: 'Liegenschaften',
      realEstate: 'Liegenschaft',
      recipient: 'Empfänger',
      sentToPrinter: 'Zum Drucker gesendet',
      noPrintHistory: 'Kein Druckverlauf',
      output: 'Ausgabe',
      selectDocTypes: 'Alles',
      deselectDocTypes: 'Nichts',
      endOfMandate: 'Mandat-Ende'
    },
    documentTypeConfiguration: {
      addDocumentType: {
        title: 'Dokumenttyp hinzufügen',
        titleEdit: 'Dokumenttyp bearbeiten',
        key: 'Schlüssel',
        numberOfCopiesKey: 'Anzahl der Exemplare (Abacus Schlüssel)',
        paperTray: {
          options: {
            DEFAULT: 'Standard',
            LOGO: 'Logo',
            LOGO_FOLLOWING_PAGE: 'Logo folgende Seite',
            COVER_SHEET_YELLOW: 'Deckblatt gelb',
            COVER_SHEET_BLUE: 'Deckblatt blau',
            ADDITIONAL: 'Zusätzlich'
          },
          title: 'Papierfach'
        },
        source: {
          options: {
            fileShare: 'Azure Storage',
            dynamicallyCreated: 'Dynamisch erstellt'
          },
          title: 'Quelle'
        }
      },
      title: 'Dokumententyp - Konfiguration',
      sections: {
        recipient: {
          title: 'Für jeden Empfänger'
        },
        property: {
          title: 'Für jede Liegenschaft'
        }
      },
      dialog: {
        delete: {
          title: 'DOKUMENTENTYP LÖSCHEN',
          question: 'Möchten Sie diesen Dokumenttyp wirklich löschen?'
        }
      }
    },
    printerConfiguration: {
      availablePrinter: 'Drucker ist verfügbar. Job Count:',
      config: {
        configuration: 'Konfiguration',
        invalid: 'Konfiguration ungültig',
        mediaSources: 'Schacht',
        missing: 'Konfiguration fehlt',
        saveConfiguration: 'Bitte speichern Sie die Konfiguration, bevor Sie Papierfächer auswählen'
      },
      currentPrinter: 'Aktuelle Drucker - Einstellungen',
      dialog: {
        delete: {
          title: 'PRINTOUT LÖSCHEN',
          question: 'Möchten Sie diesen Ausdruck des Dossierverlaufs wirklich löschen?'
        }
      },
      noConfiguration: 'Noch keine Konfiguration vorhanden',
      settingsIncomplete: 'Drucker verfügbar, Einstellungen jedoch unvollständig. Bitte Konfiguration prüfen.',
      status: 'Status',
      statusUnavailable: 'Drucker nicht verfügbar. Bitte Verbindung prüfen.',
      statusUnknown: 'Drucker nicht verfügbar. Bitte Konfiguration prüfen.',
      subject: 'Fach',
      title: 'Druckerkonfiguration'
    },
    printerQueue: {
      mediaSource: 'Medienquelle',
      news: 'Nachrichten',
      state: 'Status',
      stateReason: 'Erklärung'
    },
    recipients: {
      properties: 'Immobilien',
      recipientDetail: {
        document: 'Dokument',
        documentsPreselected: 'Gedruckte Dokumente werden ausgewählt.',
        doesNotExist: 'Empfänger existiert nicht.',
        go: 'Weiter',
        version: 'Version',
        number: 'Anzahl',
        noDocuments: 'Keine Dokumente',
        printed: 'Gedruckt',
        radioSelection: {
          all: 'Alle Dokumente',
          onlyNew: 'Nur neue Version',
          nothing: 'Nichts',
          custom: 'Benutzerdefiniert'
        },
        selection: 'Auswahl'
      }
    },
    successfullyPrinted: 'Dokument wurde erfolgreich gedruckt.',
    toolbar: {
      checkConfig: 'Konfig prüfen',
      queue: 'Queue'
    }
  },
  feedback: {
    alerts: {
      dissatisfied: 'Vielen Dank für Ihr Feedback. Wir bedauern, dass unsere Dienstleistung Sie nicht zufrieden gestellt hat und versuchen dies zu verbessern.',
      enjoyed: 'Vielen Dank für Ihr Feedback. Es freut uns sehr, dass Ihnen unsere Dienstleistung gefallen hat.',
      invalidLink: 'Der Link ist leider nicht mehr gültig.',
      INVALID_INPUT: 'Leider haben Sie eine ungültige ID oder ein ungültiges Token eingegeben.',
      FEEDBACK_ALREADY_SUBMITTED: 'Sie haben dieses Feedback bereits eingereicht.',
      success: 'Ihr Feedback wurde erfolgreich gespeichert.',
      submitError: 'Ihr Feedback konnte nicht übermittelt werden. Bitte versuchen Sie es später nocheinmal.'
    },
    comment: 'Bemerkung',
    evaluate: 'bewerten',
    fillRating: 'Bitte füllen Sie die Bewertung aus.',
    from: 'vom',
    questions: {
      craftsman: 'Wie zufrieden sind Sie mit dem beauftragten Handwerker?',
      jobDone: 'Wurde der Auftrag erledigt? Wenn nein, wurde bereits ein Termin vereinbart?',
      satisfied: 'Wie zufrieden sind Sie mit der Ausführung der Arbeiten?',
      serviceProvider: 'Wie zufrieden sind Sie mit der Dienstleistung der Prestige Immobilien AG?'
    },
    info: {
      contactChannels: 'Kontakt-Kanäle',
      giveFeedback: 'Sie können uns Ihr Feedback gerne über unsere anderen',
      notify: 'mitteilen'
    },
    imprint: 'Impressum',
    list: {
      answered: 'Beantwortet',
      assessment: 'Bewertung Durchführung',
      comment: 'Kommentar',
      clearDates: 'Daten löschen',
      feedbacks: 'Feedbacks',
      feedbackExcel: 'Feedback Excel',
      jobDone: 'Auftrag erledigt',
      opened: 'Geöffnet',
      rating: 'Bewertung Prestige Immobilien AG',
      reference: 'Referenz',
      requested: 'Angefragt',
      reviewCraftsmen: 'Bewertung Handwerker',
      scoreCarftsman: 'Punktzahl Handwerker',
      scoreExecution: 'Punktzahl Ausführung',
      scorePrestige: 'Punktzahl Prestige',
      submitted: 'Eingereicht',
      type: 'Typ'
    },
    serviceOrder: 'Nachfolgend können Sie unseren Service im Zusammenhang mit dem Auftrag',
    title: 'Feedback schreiben'
  },
  heatingOil: {
    showInactive: 'Inaktive anzeigen',
    headers: {
      actions: 'Aktionen',
      house: 'Haus',
      lastOrder: 'Letzte Bestellung',
      lastUpdate: 'Letzte Aktualisierung',
      level: 'Füllstand',
      revision: 'Revision',
      shoppingCart: 'Warenkorb',
      stockVolume: 'Bestand / Tankvolumen'
    },
    subHeaders: {
      amount: 'Menge',
      date: 'Datum',
      supplier: 'Lieferant'
    },
    tankLevel: {
      fillingLevelDialog: {
        addedToBasket: 'Die Bestellung wurde dem Warenkorb hinzugefügt.',
        hint: 'Liter',
        remainingAmount: 'Restmenge',
        statusAdded: 'Status hinzugefügt',
        title: 'Füllstand aktualisieren',
        validAmount: 'Bitte geben Sie einen gültigen Betrag ein'
      },
      shoppingCartDialog: {
        amountLabel: 'Menge (l)',
        defaultSupplier: 'Standard Lieferant',
        addTitle: 'Bestellung hinzufügen',
        editTitle: 'Bestellung bearbeiten',
        messages: {
          lastOrder: 'Letzte Bestellung war am',
          lastOrderQuestion: 'Wirklich schon wieder bestellen?',
          successfullyEdited: 'Die Bestellung wurde erfolgreich bearbeitet!'
        },
        supplier: 'Lieferant'
      },
      source: {
        SILENTSOFT: 'SilentSoft',
        MANUAL: 'Manual'
      }
    },
    basket: {
      afterFilling: 'Nach füllung',
      created: 'Erstellt',
      dialog: {
        delete: {
          title: 'EINTRAG AUS DEM WARENKORB LÖSCHEN',
          question: 'Möchten Sie diesen Eintrag wirklich löschen?'
        }
      },
      order: 'Bestellen',
      orderComplete: 'Bestellung ausgeführt',
      orderDate: 'Datum Bestellung',
      orderQuantity: 'Bestellmenge',
      overfilled: 'überfüllt',
      postcodeTown: 'PLZ Ort',
      tank: 'Tank'
    },
    orders: {
      details: 'Einzelheiten',
      downloadExcel: 'Excel-Datei herunterladen',
      positions: 'Positionen',
      sendEmail: 'E-mail senden',
      supplierOrders: 'Lieferantenbestellungen'
    }
  },
  map: {
    clusterProperties: 'Cluster Immobilien',
    constructionProjects: 'Bauvorhaben',
    terminations: {
      contractEnd: 'Vertragsende',
      object: 'Objekt',
      property: 'Immobilie',
      showAll: 'Zeige alles',
      tenant: 'Mieter'
    }
  },
  navigation: {
    admin: 'Admin',
    abacusDemo: 'Abacus Demo',
    abacusAdapter: 'Abacus Adapter',
    abacusEnableRentalCase: 'Token aktivieren',
    abacusDisableRentalCase: 'Token deaktivieren',
    abacusTicketingStart: 'Ticket starten',
    abacusTicketingUpdate: 'Task aktualisieren',
    abacusTicketingComplete: 'Task abschliessen',
    abacusRentalFormList: 'Anmeldungen',
    autocomplete: 'Autocomplete',
    basket: 'Warenkorb',
    constructionProjects: 'Bauvorhaben',
    corporations: 'Unternehmen',
    charts: 'Charts',
    dashboard: 'Dashboard',
    dataImport: 'Datenimport',
    diagram: 'Diagramm',
    dossier: 'Dossier',
    feedback: 'Feedback',
    heatingOil: 'Heizöl',
    interestedPersonCreate: 'Neuen Interessenten erfassen',
    interestedPersonDetails: 'Interessenten bearbeiten',
    map: 'Karte',
    openMap: 'Karte öffnen',
    orders: 'Bestellungen',
    processes: 'Tickets',
    processDetail: 'Tickets - Detail',
    properties: 'Immobilien',
    propertyLocations: 'Ungenaue Immobilienstandorte',
    queue: 'Druckaufträge',
    recipients: 'Empfänger',
    rentingList: 'Interessenten',
    settings: 'Einstellungen',
    search: 'Suche',
    statistics: 'Statistiken',
    tanks: 'Tanks',
    taskCreate: 'Erstellen',
    taskDetail: 'Auftrag bearbeiten',
    taskDetails: 'Auftragdetails',
    task: 'Auftrag',
    tasks: 'Aufträge',
    tasksHome: 'Aufträge',
    tasksList: 'Liste Aufträge',
    terminations: 'Kündigungen',
    ticketingStatistics: 'Ticketing Statistiken',
    ticketing: 'Ticketing',
    ticketingList: 'Tasks',
    ticketingTaskDetail: 'Tasks - Detail',
    ticketingNew: 'Ticket erstellen',
    ticketingSystem: 'Ticketing System',
    userInfo: 'Benutzerinformation',
    feedbackPublicForm: 'Feedback',
    interestedPersonPublicForm: 'Mietantrag',
    landlordReferenceForm: 'Referenzauskunft Verwalter',
    employerReferenceForm: 'Referenzauskunft Arbeitgeber'
  },
  renting: {
    abacus: {
      rentalContractIdTitle: 'Mietvertrag ID',
      formEnabled: 'Anmeldeformular aktiv',
      formDisabled: 'Anmeldeformular inaktiv',
      token: 'Token',
      stateCommitment: 'Zugesagt',
      statePriority1: 'Erste Priorität',
      statePriority2: 'Zweite Priorität',
      statePending: 'In Bearbeitung',
      stateDeclined: 'Abgesagt',
      confirmDeclineTitle: 'Ausgewählte Interessenten absagen?',
      confirmDeclineMessage: 'Der Status wird aktualisiert und eine Absage-E-Mail an die ausgewählten Interessenten gesendet.',
      multiDeclineDone: 'Interessent(en) wurde(n) Absage gsendet'
    },
    additionaInformation: 'Zusätzliche informationen',
    captcha: {
      swipeToRight: 'Kreis nach rechts ziehen',
      success: 'Erfolgreich',
      errorMessage: 'Bitte Captcha lösen'
    },
    change: 'Ändern',
    comment: 'Kommentar',
    decision: {
      apartmentConclusion: 'Aktuelle Wohnsituation',
      creditRatingConclusion: 'zur Kreditwürdigkeit',
      decisionTitle: 'Beschlüsse',
      employmentConclusion: 'Beschäftigung',
      identityDocumentsConclusion: 'Identitätsdokumente',
      interestedPersonDecision: 'Entscheidung'
    },
    documents: {
      conclusionGood: 'Positiv',
      conclusionBad: 'Negativ',
      creditRating: 'Bonität',
      creditRatingConclusion: 'Fazit',
      debtEnforcement: 'Betreibungen oder Verlustbescheinigungen der letzten zwei Jahre',
      debtEnforcementPerMailReceived: 'Betreibungsauszug eingetroffen und geprüft',
      debtEnforcementPerMailOriginalReturned: 'Original zurück gesendet',
      debtEnforcementPerMailOriginalReturnedAt: 'am',
      identityDocuments: 'Ausweisdokumente',
      swissCitizenship: 'Schweizer Staatsbürgerschaft'
    },
    interested: 'Interessent',
    interestedPerson: {
      addressLine1: 'Zeile 1',
      addressLine2: 'Zeile 2',
      addressLine3: 'Zeile 3',
      comment: 'Bemerkungen',
      dateOfBirth: 'Geburtsdatum',
      email: 'E-Mail',
      firstName: 'Vorname*',
      lastName: 'Nachname*',
      messages: {
        created: 'Interessierte Person erstellt',
        confirmDelete: 'Möchten Sie diese interessierte Person wirklich löschen?',
        delete: 'Interessierte Person löschen',
        doesNotExist: 'Interessierte Person existiert nicht.'
      },
      mobile: 'Mobile',
      object: 'Objekte',
      personal: 'Personalien',
      property: 'Immobilie',
      phone1: 'Telefon',
      phone2: 'Telefon 2',
      salutations: {
        DEAR_MRS: 'Sehr geehrte Frau',
        DEAR_MR: 'Sehr geehrter Herr',
        DEAR_LADIES_AND_GENTLEMAN: 'Sehr geehrte Damen und Herren',
        DEAR_FAMILY: 'Liebe Familie',
        GOOD_DAY: 'Guten Tag'
      },
      salutatoryAddress: 'Anrede',
      salutatoryName: 'Anrede Name',
      interestedPersonUpdateMessage: 'Interessierte Person wird aktualisiert'
    },
    publicForm: {
      dialogTitle: 'Informationen zum Mietantrag',
      alerts: {
        invalidLink: 'Der Link ist leider nicht mehr gültig. <br>Bitte kontaktieren Sie uns über <a href="mailto:kundendienst@prestige.ch" class="white--text">kundendienst@prestige.ch</a>',
        invalidToken: 'Leider haben Sie einen ungültigen Code eingegeben. <br>Bitte kontaktieren Sie uns über <a href="mailto:kundendienst@prestige.ch" class="white--text">kundendienst@prestige.ch</a>',
        usedToken: 'Wir haben bereits eine Rückmeldung erhalten. <br>Bitte kontaktieren Sie uns bei Fragen über <a href="mailto:kundendienst@prestige.ch" class="white--text">kundendienst@prestige.ch</a>',
        success: 'Ihr Mietformular wurde erfolgreich erstellt.',
        submitError: 'Ihr Mietformular konnte nicht übermittelt werden. Bitte versuchen Sie es später noch einmal.'
      },
      entryToken: 'Mietantrag code',
      fields: {
        answers: {
          no: 'nein',
          yes: 'ja'
        },
        additionalResidentialPartner: {
          title: 'ZUSÄTZLICHE VERTRAGSPARTNER',
          add: 'hinzufügen',
          remove: 'entfernen',
          confirmTitle: 'Vertragspertner entfernen',
          confirmMessage: 'Sind Sie sicher? Dieser Schritt kann nicht rückgängig gemacht werden',
          confirmButton: 'entfernen'
        },
        personInfo: {
          attachments: {
            attachmentAllowedFileTypes: 'Nur: JPG-, JPEG-, PNG- und PDF-Dateien sind erlaubt',
            validateAttachmentTooBig: 'Die maximale Dateigröße beträgt 5 MB'
          },
          business: 'Geschäft',
          dateOfBirth: 'Geburtsdatum',
          email: 'E-Mail',
          employer: 'Arbeitgeber',
          familyApartment: 'Dient die Wohnung als Familien Wohnung?',
          familyName: 'Familienname',
          firstName: 'Vorname',
          foreignerInfo: {
            country: 'Staatsbürgerschaft',
            residencePermitType: 'Aufenthaltsbewilligung',
            residencePermitTypeL: 'Kurzaufenthalt L',
            residencePermitTypeB: 'Aufenthalt B',
            residencePermitTypeC: 'Niederlassung C',
            residencePermitTypeG: 'Grenzgänger G',
            residencePermitTypeN: 'Asylsuchend N',
            residencePermitTypeF: 'vorläufig aufgenommen F',
            residencePermitTypeS: 'Schutzbedürftig S',
            residencePermitTypeCi: 'Vertreter Ci',
            titleQuestion: 'Verfügen Sie über die Schweizer Staatsbürgerschaft?',
            uploadLabel: 'Bitte laden Sie eine Kopie ihrer Aufenthaltsbewilligung hoch'
          },
          employment: {
            title: 'Aktuelles Arbeitsverhältnis',
            status_EMPLOYED: 'Angestellt',
            status_SELF_EMPLOYED: 'Selbständig',
            status_PENSIONER: 'Rentner',
            status_SEARCHING_FOR_JOB: 'Auf Stellensuche',
            employer: 'Arbeitgeber',
            employerContactInfo: 'Bitte geben Sie uns eine Kontaktperson beim Arbeitgeber zur Überprüfung obiger Auskünfte',
            employerName: 'Name',
            employerFunction: 'Beruf/Funktion',
            employerPhone: 'Telefon',
            employerEmail: 'E-Mail',
            income: 'Einkommen pro Monat',
            job: 'Beruf',
            sinceWhen: 'Seit wann'
          },
          landlord: {
            name: 'Name',
            function: 'Funktion',
            phone: 'Telefon',
            email: 'E-Mail'
          },
          creditRating: {
            assessmentInfo: 'Um Ihnen und uns den Prozess der Bonitätsprüfung zu vereinfachen, möchten wir Sie bitten, uns ein CreditTrust-Zertifikat zu senden. Das CreditTrust-Zertifikat können Sie ganz einfach <a href="https://credittrust.ch/de/" target="_blank">online bestellen</a>.',
            title: 'Bonitätseinstufung',
            orderCertificate: 'Bitte ein CreditTrust Zertifikat für mich bestellen. Ich bin mir bewusst, dass CreditTrust mir diesen Service direkt in Rechnung stellen wird',
            validateCertificate: 'Bitte bestehendes Zertifikat verwenden',
            validateCertificateTooBig: 'Maximale Dateigrösse: 5 MB',
            validateCertificateAllowedFileTypes: 'Nur PDF Dateien sind erlaubt',
            debtEnforcementPerMail: 'Ich werde einen Betreibungsregisterauszug per Post an Prestige Immobilien AG, Berninastrasse 29, 8042 Zürich senden',
            debtEnforcement: 'Hatten Sie in den letzten zwei Jahren Betreibungen oder Verlustscheine?',
            comment: 'Kommentar'
          },
          hometown: 'Heimatort',
          numberOfPersons: 'Personenzahl',
          numberOfChildren: 'davon Kinder (Alter)',
          phoneInfo: 'Telefon / Mobile',
          private: 'Privat',
          title: 'SELBSTAUSKUNFT',
          apartment: {
            address: 'Addresse',
            landlordContactInfo: 'Bitte geben Sie uns eine Kontaktperson beim Vermieter/Verwalter zur Überprüfung ihrer Angaben an',
            status_SAME_AS_FIRST_RESIDENT: 'Identisch mit erster Person',
            status_RENTAL_APARTMENT: 'Mietwohnung',
            status_HOME_OWNERSHIP: 'Wohneigentum',
            status_PARENTS: 'Eltern',
            street: 'Strasse',
            streetNumber: 'Nummer',
            zip: 'PLZ',
            place: 'Ort',
            roomNumber: 'Anzahl Zimmer',
            roomRent: 'Miete/Monat: CHF',
            roomRentSince: 'In Miete seit',
            size: 'Grösse der Wohnung',
            title: 'Aktuelle Wohnung',
            zipTown: 'PLZ/Wohnort'
          }
        },
        newApartment: {
          additionalInformation: {
            title: 'Zusätzliche Informationen',
            numberOfAdults: 'Anzahl Erwachsene',
            numberOfChildren: 'Anzahl Kinder',
            pets: {
              breed: 'Hund/Rasse',
              title: 'Haustiere',
              which: 'Welche',
              info: 'Haustiere werden nur mit schriftlicher Bewilligung (Zusatzvereinbarung zum Mietvertrag) bewilligt.'
            },
            musicInstruments: {
              title: 'Musikinstrumente',
              which: 'Welche'
            }
          },
          residenceChangeReason: 'Grund des Wohnungswechsels',
          title: 'Angaben über das gewünschte Mietobjekt',
          conditionRate: 'Wie beurteilen Sie den Zustand der Wohnung?',
          concludeContract: 'Ist dies Bedingung zum Vertragsabschluss?',
          concludedContract: 'Bedingung für Vertragsabschluss?',
          sublet: {
            subTenants: 'Wünschen Sie allenfalls später Untermieter aufzunehmen?',
            title: 'Untermiete',
            titleDescription: 'Von Untermietern sind ebenfalls Anmeldeformulare auszufüllen.'
          },
          renovation: 'Was sollte am Mietobjekt renoviert/erneuert werden?',
          renovationNeeded: 'Bedarf an Renovation / Erneuerung',
          additionalObjects: 'Zusätzliche Objekte',
          additionalGarage: 'Garage',
          additionalParkingSpot: 'Parkplatz',
          additionalMotorcycleParkingSpot: 'Motorradplatz'
        },
        currentTenant: 'Jetziger Mieter',
        date: 'Datum',
        referenceDate: 'Bezugstermin',
        signature: {
          partner: 'Unterschrift des Ehe-/Wohnpartners',
          prospectiveTenant: 'Unterschrift des Mietinteressenten'
        }
      },
      desiredDateAlert: 'Der gewählte Bezugstermin liegt vor dem frühest möglichen Einzugsdatum',
      desiredMoveInDate: 'Gewünschter Bezugstermin',
      earliestStartParking: 'Objekt vermietbar ab',
      earliestStartResidential: 'Wohnung vermietbar ab',
      formInfo: 'Als die Beauftragten der Hauseigentümer sowie als Interessenvertreterin der Hausgemeinschaft sind wir für die ' +
        'sorgfältige Auswahl neuer Mieter verantwortlich. Selbstverständlich wird das Eidgenössische Datenschutzgesetz ' +
        '(DSG) bei diesem Anmeldeformular berücksichtigt. Wir weisen Sie deshalb darauf hin, dass die Beantwortung der ' +
        'kursiv gesetzten Fragen freiwillig ist.',
      formInfoAgreement: 'Der/Die Unterzeichnende wünscht den Abschluss eines Mietvertrages für folgendes Objekt:',
      formNote: 'Zur Beachtung: Gestützt auf dieses Anmeldeformular wird die PRESTIGE Immobilien AG über den Mietinteressenten ' +
        'Informationen einholen. Er bevollmächtigt die Vermieterin insbesondere, dieses Anmeldeformular als Aktivlegitimation ' +
        'für betreibungsrechtliche Auskünfte zu verwenden. ' +
        'Sollte der Mietinteressent nach Erhalt des Mietvertrages sein Interesse aus irgendeinem Grund rückgängig machen, ' +
        'so verpflichtet er sich, der PRESTIGE Immobilien AG deren Selbstkosten für die Ausstellung des Mietvertrages sowie ' +
        'die Umtriebe mit CHF 100.– zu vergüten. Die Anmeldung, Besichtigung und Vermietung ist kostenlos. Auch bei einer ' +
        'Absage seitens der PRESTIGE Immobilien AG an den Mietinteressenten entstehen für diesen keine Kosten. ' +
        'Dieser Anmeldung sind AKTUELLE BETREIBUNGSAUSKÜNFTE IM ORIGINAL VON ALLEN INTERESSENTEN beizufügen, ',
      formNoteIncompleted: 'UNVOLLSTÄNDIG AUSGEFÜLLTE ANMELDUNGEN UND SOLCHE OHNE DIE GEWÜNSCHTEN UNTERLAGEN (BETREIBUNGSAUSZÜGE, KOPIE AUSLÄNDERAUSWEISE ETC.) KÖNNEN NICHT BEARBEITET WERDEN.',
      imprint: 'Impressum',
      title: 'MIETANTRAG'
    },
    referencesApartment: {
      title: 'Referenzauskunft',
      referenceAbout: 'Auskunft über',
      currentApartment: 'Aktuelle Wohnung',
      currentLandlord: 'Aktueller Vermieter / Verwalter',
      monthlyRent: 'Miete/Monat',
      rentStartDate: 'In Miete seit',
      rootNumbers: 'Anzahl Zimmer',
      notableIncidents: 'Gab es während der Mietdauer nennenswerte Vorkommnisse wie Rechtsfälle, Streitigkeiten mit Nachbarn, etc.?',
      notableIncidentsComment: 'Gab es während der Mietdauer nennenswerte Vorkommnisse wie Rechtsfälle, Streitigkeiten mit Nachbarn, etc.? Falls ja, bitte beschreiben.',
      regularPayment: 'Erfolgten die Zahlungen regelmässig und pünktlich?',
      rentalRelationship: 'Würden Sie mit dem Mieter erneut ein Mietverhältnis eingehen?',
      sameAsFirstResident: 'Identisch wie erste Person',
      success: 'Besten Dank für Ihre Rückmeldung',
      referenceSaveError: 'Ihre Referenzauskunft konnte leider nicht gespeichert werden. <br>Bitte kontaktieren Sie uns über <a href="mailto:kundendienst@prestige.ch" class="white--text">kundendienst@prestige.ch</a>',
      apartmentConclusionGood: 'Positiv',
      apartmentConclusionBad: 'Negativ',
      conclusionInterestedPersonTitle: 'Person',
      conclusionRespondentTitle: 'Referenz-Auskunft',
      conclusionTitle: 'Fazit'
    },
    referencesEmployer: {
      title: 'Referenzauskunft',
      referenceAbout: 'Auskunft über',
      employmentDate: ' In Anstellung seit',
      income: 'Einkommen mindestens CHF',
      incomeMonthly: '/Monat',
      notTerminatedAndUnlimited: 'Arbeitsverhältnis ungekündigt und unbefristet',
      success: 'Besten Dank für Ihre Rückmeldung',
      referenceSaveError: 'Ihre Referenzauskunft konnte leider nicht gespeichert werden. <br>Bitte kontaktieren Sie uns über <a href="mailto:kundendienst@prestige.ch" class="white--text">kundendienst@prestige.ch</a>',
      employmentConclusionGood: 'Positiv',
      employmentConclusionBad: 'Negativ',
      conclusionInterestedPersonTitle: 'Person',
      conclusionRespondentTitle: 'Referenz-Auskunft',
      conclusionTitle: 'Fazit'
    },
    referenceFeedback: 'Feedback',
    referenceFunction: 'Beruf/Funktion',
    respondent: 'Referenzperson',
    rentingForms: 'Anmeldungen',
    status: 'Status',
    statusChangedMessage: 'Status der interessierten Person geändert',
    statuses: {
      ALREADY_RENTED: 'Bereits Vermietet',
      CANCELLATION_BY_INTERESTED_PERSON: 'Absage Mieter',
      COMMITMENT: 'Zusage',
      IN_PROGRESS: 'In Bearbeitung',
      PRIORITY_1: '1. Priorität',
      PRIORITY_2: '2. Priorität',
      NOT_ELIGIBLE: 'Nicht in Frage kommend',
      PENDING: 'Pendent',
      UNDER_CLARIFICATION: 'In Abklärung'
    }
  },
  statistics: {
    date: {
      from: 'Von',
      to: 'Bis'
    },
    offers: 'Offerten',
    totalAmountOil: 'Gesamtmenge des bestellten Öls',
    totalNumberTasks: 'Gesamtzahl der Aufträge',
    totalNumberOffers: 'Gesamtzahl der Offerten'
  },
  table: {
    noData: 'Keine Daten vorhanden'
  },
  tasks: {
    alert: 'Die Aufgaben-ID ist ungültig.',
    attachments: {
      attachmentCheckboxInfo: 'Wenn Checkbox aktiv, wird Attachment im E-Mail hinzugefügt',
      addToEmail: 'In E-Mail hinzufügen',
      clickOrDragText: 'Klicke oder ziehe Dateien hier hin'
    },
    competenceSum: 'Kompetenzsumme',
    types: {
      TASK: 'Auftrag',
      OFFER: 'Offerte'
    },
    list: {
      type: 'Typ'
    },
    info: {
      administrativeArea: 'Bewirtschaftung',
      caretaker: 'Hauswart',
      client: 'Mandant',
      createdBy: 'Erstellt von',
      dialogTitleEditor: 'Bearbeiter auswählen',
      dialogTitleHouse: 'Haus auswählen',
      dialogTitleObject: 'Objekt auswählen',
      editor: 'Bearbeiter',
      house: 'Haus',
      object: 'Objekt',
      property: 'Immobilie',
      realEstateManager: 'Bewirtschafter',
      ticket: 'Ticket'
    },
    createTask: {
      typeTitle: 'Art / Typ',
      insuranceCase: 'Versicherungsfall',
      recentTasks: 'Letzte Aufträge der Immobilie',
      subject: 'Betreff',
      subject1: 'Betreff Zeile 1 / Wo?',
      subject2: 'Betreff Zeile 2',
      successfullyCreated: 'Der Auftrag wurde erfolgreich erstellt.',
      jobDescription: 'Arbeitsbeschrieb',
      fileMessage: 'Dateien können nach dem Speichern der Aufgabe hinzugefügt werden.'
    },
    tenant: {
      comment: 'Bemerkung',
      currentTenant: 'Aktueller Mieter',
      title: 'Mieter',
      newTenant: 'Neuer tenant',
      selectTenant: 'Mieter auswählen',
      object: 'Objekt',
      currentTenantContractStart: 'Vertragsbeginn',
      currentTenantContractEnd: 'Vertragsende',
      newTenantContractStart: 'Neuer Vertragsstart',
      tenantOptions: {
        CURRENT: 'Aktueller',
        NEW: 'Neuer',
        MANUAL: 'Manuelle'
      }
    },
    craftsmen: {
      comment: 'Bemerkung für Handwerker',
      hint: 'Geht nicht an Mieter',
      noResults: 'Keine Handwerkerliste definiert',
      search: 'In allen Handwerker suchen',
      select: 'Aus Handwerkerliste auswählen',
      selectManual: 'Handwerker manuell auswählen',
      title: 'Handwerker'
    },
    comment: {
      title: 'Bemerkung',
      hint: 'Wird nicht gedruckt'
    },
    dialog: {
      clone: {
        title: 'AUFTRAG KOPIEREN',
        question: 'Möchten Sie diesen Auftrag wirklich kopieren?'
      },
      delete: {
        title: 'AUFTRAG LÖSCHEN',
        question: 'Möchten Sie diesen Auftrag wirklich löschen?'
      },
      email: {
        title: 'E-MAIL ERNEUT SENDEN',
        question: 'E-Mails wurden bereits gesendet. Wirklich wieder senden?'
      }
    },
    email: {
      realEstateManager: 'Bewirtschafter',
      owner: 'Eigentümer',
      textField: 'E-mail',
      saveChanges: 'Änderungen müssen zuerst gespeichert werden',
      sendMail: 'E-Mails senden',
      selectionMessages: {
        caretaker: 'Bitte wählen Sie einen Hauswart',
        client: 'Bitte wählen Sie einen Eigentümer',
        craftsman: 'Bitte wählen Sie einen Handwerker',
        realEstateManager: 'Bitte wählen Sie einen Bewirtschafter',
        tenant: 'Bitte wählen Sie einen Mieter'
      },
      successfullySent: 'E-Mails wurden erfolgreich gesendet',
      title: 'E-mail Versand',
      history: {
        title: 'E-mail Verlauf',
        noEmailsMessage: 'Bisher wurden keine E-Mails versendet'
      }
    },
    taskSource: 'Quelle',
    taskSources: {
      TASK: 'Aufträge',
      IMOFIX: 'Imofix',
      TICKETING: 'Ticketing'
    },
    warning: {
      ordersDisabled: 'Die Erstellung von Aufträgen wurde in Abacus für diese Immobilie deaktiviert..'
    }
  },
  tipTap: {
    bold: 'Deutlich: Ctrl+B',
    underline: 'Unterstreichen: Ctrl+U',
    italic: 'Kursiv: Ctrl+I',
    strike: 'Durchgestrichen',
    bulletList: 'Aufzählung: Ctrl+Shift+8',
    numberedList: 'Nummerierte Liste: Ctrl+Shift+7',
    link: 'Verknüpfung',
    linkOff: 'Verknüpfung',
    undo: 'Rückgängig machen: Ctrl+Z',
    redo: 'Wiederholen: Ctrl+Y',
    clearColor: 'Farbe entfernen'
  },
  ticketingSystem: {
    charts: {
      completedTasksByAssigneeTitle: 'Erledigte Tasks nach Person',
      completedLabel: 'Abgeschlossen',
      createdLabel: 'Gestartet',
      day: 'Tag',
      month: 'Monat',
      openCompletedTasksTitle: 'Gestartete und abgeschlossene Tasks',
      openedTasksByAssigneeTitle: 'Offene Tasks pro Person',
      openedTasksByCandidateGroupTitle: 'Offene Tasks pro Gruppe',
      priority: {
        dueTodayPriority1: 'Heute fällig Prio hoch',
        dueTodayPriority2: 'Heute fällig Prio normal',
        additionalPriority1: 'Später fällig Prio hoch',
        additionalPriority2: 'Später fällig Prio normal'
      },
      userOpenedTasksByAssigneeTitle: 'Benutzer Offene Tasks pro Person',
      unassigned: 'Nicht zugewiesen',
      uncrossPeople: 'Bitte auf den Namen klicken um die Daten anzuzeigen',
      week: 'Woche'
    },
    deleteProcess: {
      title: 'Prozessinstanz löschen',
      processInstance: 'Prozessinstanz',
      reason: 'Grund'
    },
    fileName: 'Dateiname',
    fileCreatedOn: 'Erstellt am',
    fileTypes: {
      email: 'E-Mail Datei',
      rental: 'Anmeldung Datei',
      ticket: 'Ticketdatei'
    },
    priority: {
      title: 'Priorität',
      priority1: 'Hoch',
      priority2: 'Normal',
      undefined: 'Undefiniert'
    },
    botOverride: {
      context: {
        title: 'Bot Korrektur',
        description: 'Ticket-Kategorie',
        submitButton: 'korrigieren',
        successMessage: 'Erfolgreich korrigiert'
      }
    },
    noteField: {
      title: 'Bemerkung'
    },
    candidateGroups: 'Kandidaten Gruppen',
    childTask: {
      create: 'Checklist-Eintrag hinzufügen',
      description: 'Beschreibung'
    },
    fileActions: {
      editFileName: 'Dateiname bearbeiten',
      preview: 'Vorschau'
    },
    updateTask: {
      edit: 'Checklist-Eintrag bearbeiten',
      editTaskName: 'Tasknamen und -beschreibung bearbeiten'
    },
    entry: {
      assignee: 'Zugewiesen',
      created: 'Gestartet',
      due: 'Fällig',
      finished: 'Beendet',
      finishImmediately: 'Sofort fertig',
      followUp: 'Nachfassen',
      item: 'Aufgabe',
      priority: 'Priorität'
    },
    editTask: {
      description: 'Beschreibung'
    },
    links: 'Links',
    list: {
      clear: 'Filter löschen',
      filter: 'Filter',
      filters: {
        displayAssignedTasks: 'Zugewiesene Aufträge',
        displayFollowUpTasks: 'Nachfass-Aufträge',
        searchCaretaker: 'Hauswarte',
        searchProperty: 'Immobilien',
        searchTicket: 'Ticket-Typ',
        searchTaskName: 'Task',
        searchTenant: 'Mieter',
        searchUser: 'Ticketing-Benutzer',
        object: 'Objekt'
      },
      general: 'Kundendienst',
      personally: 'Persönlich'
    },
    messages: {
      addComment: 'Einen Kommentar hinzufügen ...',
      desiredDateNoEqual: 'Gewünschter Einzugstermin ist nicht gleich frühester Einzugstermin',
      noContextInfo: 'Keine Kontextinformationen',
      noReferencesInfo: 'Keine Referenzinformationen',
      noSuggestions: 'Keine Vorschläge',
      startProcess: 'Ticket erstellen',
      startAndOpenProcess: 'Ticket erstellen und öffnen',
      successfullyAddedComment: 'Der Kommentar wurde erfolgreich hinzugefügt.',
      successfullyAddedCandidateGroup: 'Die Kandidatengruppe wurde erfolgreich hinzugefügt.',
      successfullyAssignedToMe: 'Die Aufgabe wurde Ihnen erfolgreich zugewiesen.',
      successfullyChangedAssignee: 'Der Aufgabenempfänger wurde erfolgreich geändert.',
      successfullyCompleted: 'Der Auftrag wurde erfolgreich abgeschlossen.',
      successfullyDeletedCandidateGroup: 'Die Kandidatengruppe wurde erfolgreich gelöscht.',
      successfullySaved: 'Die Aufgabe wurde erfolgreich gespeichert.',
      successfullyStarted: 'Der Prozess wurde erfolgreich gestartet.',
      successfullyStartedRelated: 'Das verbunde Ticket wurde erfolgreich gestartet.',
      successfullyUpdatedComment: 'Der Kommentar wurde erfolgreich aktualisiert.',
      successfullyUpdatedDueDate: 'Die Fälligkeit wurde erfolgreich aktualisiert.',
      successfullyUpdatedProcessSubject: 'Der Betreff wurde erfolgreich aktualisiert.',
      successfullyUpdatedFollowUpDate: 'Das Nachfassdatum wurde erfolgreich aktualisiert.',
      successfullyUpdatedTask: 'Der Eintrag wurde erfolgreich aktualisiert.',
      successfullyUpdatedTaskPriority: 'Die Priorität wurde erfolgreich aktualisiert.',
      comment: {
        actionButtonLabel: 'Kommentar schreiben',
        create: 'Speichern',
        dialogTitle: 'Neuen Kommentar schreiben',
        edit: 'Bearbeiten',
        hint: 'Nur für internen Gebrauch. Wird nicht via E-Mail versendet',
        updateDialogTitle: 'Kommentar aktualisieren'
      },
      email: {
        actionButtonLabel: 'E-Mail senden',
        at: 'um',
        bcc: 'Bcc',
        cc: 'Cc',
        on: 'Am',
        replyDialogTitle: 'Auf E-Mail antworten',
        newDialogTitle: 'Neue E-Mail senden',
        wrote: 'schrieb',
        to: 'An',
        from: 'Von',
        read: 'Gelesen',
        reply: 'Antworten',
        subject: 'Betreff',
        body: 'Nachricht',
        send: 'Senden',
        successfullySent: 'Erfolgreich versendet'
      }
    },
    mergeTicket: {
      btnTitle: 'Ticket zusammenführen',
      success: 'Ticket erfolgreich zusammengeführt'
    },
    note: {
      assignedUser: 'Zugewiesen',
      completeImmediately: 'Sofort beenden',
      email: {
        sender: 'E-Mail Absender',
        subject: 'E-Mail Betreff',
        message: 'Email Nachricht'
      },
      selectAssignee: 'Person zuweisen',
      selectCandidateGroup: 'Gruppe zuweisen',
      setDueDate: 'Fälligkeit',
      files: 'Dateien',
      title: 'Ticket'
    },
    objects: {
      tenantOf: 'Mieter von',
      subletOf: 'Untermieter von'
    },
    processes: {
      process: 'Ticket',
      diagram: 'Diagramm',
      displayDiagram: 'Diagramm anzeigen',
      editProcessSubject: 'Betreff bearbeiten',
      files: 'Files',
      processPending: 'Bearbeitung pendent',
      interestedPerson: {
        creditTrustTitle: 'Verifikation CreditTrust Zertifikat',
        decision: {
          title: 'Status',
          reason: 'Begründung',
          boxTitle: 'Status anpassen und Prozess abkürzen',
          submitLabel: 'Status setzen',
          successMessage: 'Abkürzung genommen'
        },
        noInfo: 'Keine Vermietungsfallinformationen',
        notVerified: 'Nicht verifiziert',
        orderId: 'Bestell-ID',
        orderedCertificate: 'Bestelltes CreditTrust Zertifikat',
        createCertificate: {
          issuable: 'Ausstellbar',
          crifMatch: 'CRIF Treffer',
          crifApproval: 'CRIF Check',
          intrumMatch: 'Intrum Treffer',
          intrumApproval: 'Intrum Check'
        },
        interestedPersonDecision: {
          successfulMessage: 'Danke für die Beantwortung',
          requestCouldNotBeProcessedMessage: 'Leider konnte Ihre Anfrage nicht bearbeitet werden. Bitte wenden Sie sich an kundendienst@prestige.ch'
        },
        registration: 'Erstellt am',
        rentalApplicationLabel: 'Mietantrag anzeigen',
        rentalApplicationPdfLabel: 'PDF herunterladen',
        rentalObjectTitle: 'Angaben zum Mietobjekt',
        title: 'Anmeldung durch Interessenten',
        verified: 'Verifiziert'
      },
      offlineRentingForm: {
        rentalContract: 'Mietvertrag',
        firstName: 'Vorname',
        lastName: 'Nachname',
        email: 'E-Mail',
        phone: 'Telefon',
        street: 'Strasse',
        zip: 'PLZ',
        place: 'Ort'
      },
      noMessages: 'Keine Einträge',
      notFound: 'Das Ticket konnte nicht geladen werden',
      completedTasks: 'Abgeschlossene Tasks',
      onlyActive: 'Nur Aktive',
      openTasks: 'Laufende Tasks',
      processDetails: 'Ticket öffnen',
      processLinks: 'Ticket-Verknüpfungen',
      processReferences: 'Übergeordnete und untergeordnete Tickets',
      rentingForm: {
        createContract: {
          rentComponent: 'Mietzinsbestandteil',
          rentComponentAmount: 'Bisheriger Betrag',
          rentComponentNewAmount: 'Neuer betrag',
          rentComponentComment: 'Bemerkung',
          rentComponentDeposit: 'Depot',
          abacusLink: 'Objekt',
          earliestStart: 'Frühester Bezugstermin',
          moveInDate: 'Bezugstermin',
          grossRent: 'Bruttomiete',
          confirmation: 'Bestätigung durch Interessenten',
          confirmationComment: 'Bemerkung',
          relettingContractSent: 'MV erstellt / versendet am:',
          contractFinished: 'Mietvertrag abgeschlossen?',
          contractFinishedNo: 'Nein, Absage durch Interessenten',
          contractCompletedDate: 'MV und alle Dokumente gegengezeichnet, eingescannt und retourniert am'
        },
        viewing: {
          title: 'Besichtigung(en)',
          headerName: 'Name und Datum',
          headerImpression: 'Eindruck',
          headerInternalRemark: 'Bemerkung',
          headerInterest: 'Interesse',
          viewingCancelledYes: 'Besichtigung Abgesagt'
        }
      },
      reletting: {
        labels: {
          actions: 'Aktionen',
          name: 'Name',
          received: 'Eingang',
          state: 'Status',
          step: 'Schritt',
          formViewReturned: 'Formular Besichtigung retourniert?'
        },
        liableUntil: 'Haftung bis',
        specialities: 'Spezialitäten',
        title: 'Wiedervermietungs-Prozess',
        pdf: 'PDF'
      },
      reoccurringTicket: {
        dueDateTitle: 'Fällig am',
        dueDateHint: 'An welchem Tag des folgenden Monats fällig',
        switchTitle: 'Wiederkehrendes Ticket',
        switchHint: 'Ein neues Ticket wird erstellt, wenn dieses abgeschlossen wird',
        title: 'Wiederkehrend',
        dueDateOnDayOfMonthMinError: 'Bitte einen Wert 1 oder grösser wählen',
        dueDateOnDayOfMonthMaxError: 'Bitte einen Wert 31 oder kleiner wählen'
      },
      startedAfter: 'Gestartet nach',
      startedBefore: 'Gestartet bevor',
      termination: {
        contract: 'Vertrag',
        signaturesCorrect: 'Unterschriften korrekt?',
        title: 'Kündigung',
        terminationBy: 'Kündigung per',
        terminationReceivedOn: 'Eingangsdatum',
        noInfo: 'Keine Informationen zur Prozessbeendigung',
        statements: {
          account: 'Akkonto',
          balance: 'Saldo',
          costs: 'Kosten',
          chargeStatements: 'NK Abrechnung',
          contractPartners: 'Vertragspartner',
          period: 'Periode',
          rentalContractID: 'Mietvertrag ID',
          rentalComponents: 'Mietkomponenten',
          terminationProperty: 'Kündigung Immobilie'
        },
        rentalComponentHeaders: {
          component: 'Mietzinsbestanteil',
          old: 'Alt',
          new: 'Neu',
          comment: 'Bemerkung'
        },
        alert: 'Neuer Mietzins in Abacus erfassen!'
      },
      key: 'Schlüssel',
      variables: {
        structuredData: 'Strukturierte Daten',
        copyToClipboard: 'In die Zwischenablage kopieren',
        copiedToClipboard: 'In die Zwischenablage kopiert',
        files: 'Dateien',
        showMore: 'Mehr anzeigen',
        title: 'Variablen'
      }
    },
    subject: 'Betreff',
    suggestions: 'Vorschläge',
    startProcess: {
      description: 'Kommentar',
      maxLength: 'Die maximale Länge des Betreffs beträgt 255.'
    },
    task: 'Task',
    taskDetails: {
      buttons: {
        action: 'Aktion',
        addComment: 'Bemerkung hinzufügen',
        assignToMe: 'Mir zuweisen',
        complete: 'Abschliessen',
        done: 'Erledigt',
        restartProcess: 'Ticket öffnen',
        startRelatedProcess: 'Zugehöriges Ticket',
        unassigned: 'Nicht zugewiesen'
      },
      checkList: 'Checkliste',
      completeCheckListItems: 'Bitte Checkliste abschliessen',
      completeAbacusControlledTicket: 'Bitte in Abacus abschliessen',
      dueDate: 'Fälligkeitsdatum',
      followUpDate: 'Nachfassen-Datum',
      form: {
        searchProperty: 'Immobilie suchen',
        searchPropertyObjects: 'Immobilienobjekte suchen',
        title: 'Form'
      },
      history: 'Kommentare und E-Mails',
      processReferences: {
        children: 'Hat diese gestartet',
        finishedOn: 'Beendet',
        parent: 'Wurde gestartet durch',
        startedBy: 'Gestartet durch',
        startedOn: 'Gestartet'
      },
      setDueDate: 'Fälligkeitsdatum festlegen',
      setFollowUpDate: 'Nachfassdatum festlegen',
      state: 'Status',
      states: {
        ACTIVE: 'Aktiv',
        SUSPENDED: 'Suspendiert',
        COMPLETED: 'Abgeschlossen',
        EXTERNALLY_TERMINATED: 'Extern beendet',
        INTERNALLY_TERMINATED: 'Intern beendet'
      },
      taskDoesNotExist: 'Die Aufgabe existiert nicht mehr.'
    },
    context: {
      propertyObject: {
        currentTenant: 'Aktuell',
        newTenant: 'Neu',
        since: 'seit: ',
        until: 'bis: '
      }
    },
    ticketingUsers: 'Ticketing-Benutzer',
    users: {
      rentingForm: 'Mietantrag'
    },
    tooltipEditTask: 'Task bearbeiten',
    tooltipEditSubject: 'Betreff bearbeiten'
  }
}
