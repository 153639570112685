<template>
  <v-list-item-content>
    <v-dialog v-model="show" ref="editDialog" max-width="500px" @keydown.esc="closeEditEditor" persistent>
      <v-card>
        <v-card-title>
            {{ $t('tasks.info.dialogTitleObject') }}
          <v-btn icon absolute right>
            <v-icon class="clear" color="primary" :size="28" @click.native="closeEditEditor"/>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <PropertyObjects v-bind:form="select" :display-items="displayItems" :property-id="propertyId.toString()" />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click.native="closeEditEditor">{{ $t('common.buttons.cancel') }}</v-btn>
          <v-btn color="primary" @click.native="saveEditEditor">{{ $t('common.buttons.save') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div>
      {{ $t('tasks.info.object') }}:  {{ houseObjectInfo }}
      <span v-if="!editDisabled">
        [<a @click.stop="openEditEditor">{{ $t('common.buttons.edit') }}</a>]
      </span>
    </div>
  </v-list-item-content>
</template>

<script>
import PropertyObjects from './PropertyObjects.vue'
import _cloneDeep from 'lodash.clonedeep'

export default {
  name: 'PropertyObjectsEditor',
  components: {
    PropertyObjects
  },
  watch: {
    search (val) {
      val && this.querySelections(val)
    }
  },
  props: {
    propertyId: {
      type: String,
      default: () => ''
    },
    houseObjectInfo: {
      type: String,
      default: ''
    },
    editDisabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      select: { value: null },
      show: false,
      form: {
        realEstateManager: null
      },
      displayItems: [],
      dataObjects: []
    }
  },
  created () {
    this.getPropertyObjects()
  },
  methods: {
    openEditEditor () {
      this.select = { value: null }
      this.show = true
    },
    closeEditEditor () {
      this.show = false
    },
    saveEditEditor () {
      if (this.select.value) {
        let selectedObjectId = this.select.value.objectId
        let object = _cloneDeep(this.dataObjects.find(x => x.objectId.toString() === selectedObjectId.toString()))
        this.$emit('propertyObjectChanged', object)
      }
      this.show = false
    },
    getPropertyObjects () {
      let that = this
      this.$abacusProperty.getPropertyById(that.propertyId).then(response => {
        that.dataObjects = response.data.objects
        if (that.dataObjects && that.dataObjects.length > 0) {
          that.dataObjects.forEach(object => {
            let text = '[' + object.objectId + ']' + ' ' + object.houseStreet + '-' + object.floorText + ' ' + object.objectText + ' - ' + object.currentTenantText
            let displayItem = {
              objectId: object.objectId,
              displayText: text
            }
            that.displayItems.push(displayItem)
          })
        }
      })
    }
  }
}
</script>

<style scoped>
</style>
