<template>
  <v-container fluid class="pl-0 pr-0 pb-0 max-height">
    <v-list class="pa-4">
      <template v-for="(item, index) in properties">
        <v-list-item class="pl-0 pr-0 list-item-height" :key="index">
          <v-list-item-content>
            <span>[<a v-if="item.propertyId" @click="onPropertyClicked(item.propertyId)">{{ item.propertyId }}</a>] {{ addressInfo(item) }}</span>
          </v-list-item-content>
          <ActionsMenu
            :item="item"
            :actions="actions"
          />
        </v-list-item>
        <v-divider :key="'divider_' + index"></v-divider>
      </template>
    </v-list>
  </v-container>
</template>

<script>
import ActionsMenu from '@/components/ActionsMenu'
import * as globalHelper from '@/helpers/globalHelper'
import * as routesHelper from '@/helpers/routesHelper'
import { usePropertyActions } from '@/use/usePropertyActions'
import { useTask } from '@/use/useTask'

const { propertyActions } = usePropertyActions()
const { onNewTask } = useTask()

export default {
  name: 'AddressProperties',
  components: {
    ActionsMenu
  },
  props: {
    properties: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    additionalActions () {
      return [
        { title: this.$t('dashboard.actions.newTicket'), icon: 'mdi-ticket', action: this.onNewPropertyTicket },
        { title: this.$t('dashboard.actions.newTask'), icon: 'mdi-calendar-check', action: onNewTask }
      ]
    },
    actions () {
      return this.additionalActions.concat(propertyActions)
    }
  },
  methods: {
    addressInfo (item) {
      return globalHelper.setAddressInfo(item)
    },
    onPropertyClicked (propertyId) {
      routesHelper.openDashboardProperty(propertyId)
    },
    onNewPropertyTicket (item) {
      let links = [
        {
          linkName: 'property',
          linkValues: [item.propertyId.toString()]
        }
      ]
      this.$emit('openNewTicketDialog', links)
    }
  }
}
</script>

<style scoped lang="postcss">
.list-item-height {
  height: 0px;
}
</style>
