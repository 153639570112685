<template>
  <v-container fluid class="ma-0">
    <v-layout row wrap>
      <v-flex grow xs12 sm12 md12 lg12 xl12>
        <v-btn-toggle
          v-model="toggle_range"
          tile
          group
          color="blue"
        >
          <v-btn value="TODAY">{{ $t('common.date.today') }}</v-btn>
          <v-btn value="LAST_MONTH">{{ $t('common.date.lastMonth') }}</v-btn>
          <v-btn value="LAST_YEAR">{{ $t('common.date.lastYear') }}</v-btn>
        </v-btn-toggle>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="pa-2">
      <v-flex grow xs12 sm12 md6 lg3 xl3 class="pl-2 pr-2">
        <DatePicker
          ref="from"
          v-model="from"
          :clearable="true"
          :label="$t('statistics.date.from')"
          :default-value="false"
          :default-value-date="from"
          @input="onUpdateFilters"
        />
      </v-flex>
      <v-flex grow xs12 sm12 md6 lg3 xl3 class="pl-2 pr-2">
        <DatePicker
          ref="to"
          v-model="to"
          :clearable="true"
          :label="$t('statistics.date.to')"
          :default-value="false"
          :default-value-date="to"
          @input="onUpdateFilters"
        />
      </v-flex>
      <v-flex grow xs12 sm12 md12 lg3 xl3 class="pl-2">
        <v-radio-group row v-model="periodTypeSelection" @change="onUpdateFilters">
          <v-radio
            v-for="(item, i) in periodList"
            :key="i"
            class="pr-5 mr-0"
            :label="periodLabels[item]"
            :value="item"
          />
        </v-radio-group>
      </v-flex>
      <v-flex grow xs12 sm12 md12 lg3 xl3 class="pl-2 d-flex">
        <v-checkbox
          primary
          hide-details
          v-model="processDefinitionKeys"
          :value="TICKETING_CONSTANTS.ANMELDUNG"
          label="Anmeldung"
          class="anmeldung-checkbox pr-4"
        ></v-checkbox>
        <v-checkbox
          primary
          hide-details
          v-model="processDefinitionKeys"
          :value="TICKETING_CONSTANTS.ANMELDUNG_OFFLINE"
          label="Anmeldung (Offline)"
          class="anmeldung-offline-checkbox pr-4"
        ></v-checkbox>
        <v-checkbox
          primary
          hide-details
          v-model="processDefinitionKeys"
          :value="TICKETING_CONSTANTS.TICKET"
          label="Ticket"
          class="ticket-checkbox"
        ></v-checkbox>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { TICKETING_CONSTANTS } from '@/config/options/ticketingConstants'
import DatePicker from '@/components/DatePicker'
import {
  getLastDayOfCurrentMonth,
  getMondayMinusNMonths,
  getMondayMinusNWeeks,
  getTodaysDateISOString
} from '@/helpers/momentHelper'

const TODAY = 'TODAY'
const LAST_MONTH = 'LAST_MONTH'
const LAST_YEAR = 'LAST_YEAR'

const DAY = 'day'
const WEEK = 'week'
const MONTH = 'month'

export default {
  name: 'ChartHeaderFilters',
  components: {
    DatePicker
  },
  data () {
    return {
      TICKETING_CONSTANTS,
      from: null,
      to: null,
      toggle_range: '',
      periodTypeSelection: '',
      periodList: [DAY, WEEK, MONTH],
      processDefinitionKeys: [TICKETING_CONSTANTS.ANMELDUNG, TICKETING_CONSTANTS.ANMELDUNG_OFFLINE, TICKETING_CONSTANTS.TICKET]
    }
  },
  created () {
    this.periodTypeSelection = WEEK
    this.toggle_range = LAST_MONTH
    this.setDateRangeInputValues()
    this.onUpdateFilters()
  },
  computed: {
    periodLabels () {
      return {
        day: this.$t('ticketingSystem.charts.day'),
        month: this.$t('ticketingSystem.charts.month'),
        week: this.$t('ticketingSystem.charts.week')
      }
    }
  },
  methods: {
    onUpdateFilters () {
      this.$emit('updateFilters', {
        from: this.from,
        to: this.to,
        interval: this.periodTypeSelection,
        processDefinitionKeys: this.processDefinitionKeys
      })
    },
    setDateRangeInputValues () {
      switch (this.toggle_range) {
        case TODAY:
          return this.setTodaysDate()
        case LAST_MONTH:
          return this.setLastMonthDateRange() 
        case LAST_YEAR:
          return this.setLastYearDateRange()
        default:
          return null
      }
    },
    setTodaysDate () {
      this.from = getTodaysDateISOString()
      this.to = getTodaysDateISOString()
      this.periodTypeSelection = DAY
    },
    setLastMonthDateRange () {
      this.from = getMondayMinusNWeeks(4)
      this.to = getTodaysDateISOString()
      this.periodTypeSelection = WEEK
    },
    setLastYearDateRange () {
      this.from = getMondayMinusNMonths(12)
      this.to = getLastDayOfCurrentMonth()
      this.periodTypeSelection = MONTH
    }
  },
  watch: {
    processDefinitionKeys () {
      this.onUpdateFilters()
    },
    toggle_range () {
      this.setDateRangeInputValues()
      this.onUpdateFilters()
    }
  }
}
</script>
