// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import i18n from '@/i18n/i18n'
import vuetify from '@/plugins/vuetify' // path to vuetify export
import vuex from 'vuex'
import store from './store'
import AuthenticationContext from 'vue-adal'

import axios from 'axios'
import VueAxios from 'vue-axios'
import { AxiosDefaultConfigFactory, AxiosCustomConfigFactory } from './backend/rest/axiosConfig'
import { axiosInterceptors } from './backend/rest/axiosInterceptors'

import VueApollo from 'vue-apollo'
import {createApolloProvider} from './backend/graphql'

import VueClip from 'vue-clip'
import * as VueGoogleMaps from 'vue2-google-maps'
import { VueMaskDirective } from 'v-mask'

import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { AbacusPropertyApiFactory, AbacusPriorityCraftsmenApiFactory, AbacusHeatingOilTanksApiFactory, AbacusBlockedCraftsmenApiFactory, AbacusAddressApiFactory, AbacusImporterApiFactory, AbacusRentalContractApiFactory } from '@n47/prestige-abacusadapter-axios-client'
import { TaskApiFactory, TaskPdfApiFactory, TaskEmailApiFactory, TaskFileApiFactory, TaskStatisticsApiFactory } from '@n47/prestige-task-axios-client'
import { HeatingOilTankLevelApiFactory, HeatingOilOrderApiFactory, HeatingOilStatisticsApiFactory, HeatingOilBasketApiFactory, HeatingOilImporterApiFactory } from '@n47/prestige-heating-oil-axios-client'
import { DossierPrintHistoryApiFactory, DossierCorporationApiFactory, DossierDocumentApiFactory, DossierPrinterApiFactory, DossierRecipientApiFactory, DossierScanApiFactory, DossierImporterApiFactory } from '@n47/prestige-dossier-axios-client'
import { FeedbackRequestApiFactory } from '@n47/prestige-feedback-axios-client'
import { ConstructionProjectsApiFactory } from '@n47/prestige-construction-projects-axios-client'
import { ProcessApiFactory, TicketingTaskApiFactory, UserApiFactory, XmlProcessModelApiFactory, TicketingStartFormApiFactory, TicketingFileApiFactory } from '@n47/prestige-ticketing-axios-client'
import { RentalFormApiFactory, RentingApiFactory, RentingFileApiFactory } from '@n47/prestige-renting-axios-client'

import 'material-design-icons-iconfont/dist/material-design-icons.css'

// config-v2.js is included in index.html and overwritten in the runtime
/* global config:true */

let axiosInstance = axios.create(AxiosDefaultConfigFactory(''))
let customAxiosInstance = axios.create(AxiosCustomConfigFactory('', 5 * 60 * 1000))

Vue.use(VueAxios, axiosInstance)
Vue.use(VueAxios, customAxiosInstance)
Vue.use(VueClip)
Vue.use(VueApollo)
Vue.directive('mask', VueMaskDirective)

Vue.config.productionTip = false

// Abacus APIs
Vue.prototype.$abacus = new AbacusAddressApiFactory({}, '/api/abacus', axiosInstance)
Vue.prototype.$abacusBlockedCraftsman = new AbacusBlockedCraftsmenApiFactory({}, '/api/abacus', axiosInstance)
Vue.prototype.$abacusPriorityCraftsman = new AbacusPriorityCraftsmenApiFactory({}, '/api/abacus', axiosInstance)
Vue.prototype.$abacusProperty = new AbacusPropertyApiFactory({}, '/api/abacus', customAxiosInstance)
Vue.prototype.$abacusHeatingOil = new AbacusHeatingOilTanksApiFactory({}, '/api/abacus', axiosInstance)
Vue.prototype.$abacusImporter = new AbacusImporterApiFactory({}, '/api/abacus', customAxiosInstance)
Vue.prototype.$abacusRentalContract = new AbacusRentalContractApiFactory({}, '/api/abacus', customAxiosInstance)

// Task APIs
Vue.prototype.$prestigeTask = new TaskApiFactory({}, '/api', axiosInstance)
Vue.prototype.$prestigeTaskApiFile = new TaskFileApiFactory({}, '/api', axiosInstance)
Vue.prototype.$prestigeTaskEmail = new TaskEmailApiFactory({}, '/api', axiosInstance)
Vue.prototype.$prestigeTaskPdf = new TaskPdfApiFactory({}, '/api', axiosInstance)
Vue.prototype.$prestigeTaskStats = new TaskStatisticsApiFactory({}, '/api', axiosInstance)

// Heating Oil APIs
Vue.prototype.$prestigeHeatingOilTankLevel = new HeatingOilTankLevelApiFactory({}, '/api/heating-oil/', axiosInstance)
Vue.prototype.$prestigeHeatingOilBasket = new HeatingOilBasketApiFactory({}, '/api/heating-oil', axiosInstance)
Vue.prototype.$prestigeHeatingOilOrder = new HeatingOilOrderApiFactory({}, '/api/heating-oil/', axiosInstance)
Vue.prototype.$prestigeHeatingOilStats = new HeatingOilStatisticsApiFactory({}, '/api/heating-oil', axiosInstance)
Vue.prototype.$silentsoftImporter = new HeatingOilImporterApiFactory({}, '/api/heating-oil', customAxiosInstance)

// Dossier APIs
Vue.prototype.$prestigeDossierCustomPrintHistory = new DossierPrintHistoryApiFactory({}, '/api/dossier', customAxiosInstance)
Vue.prototype.$prestigeDossierPrintHistory = new DossierPrintHistoryApiFactory({}, '/api/dossier', axiosInstance)
Vue.prototype.$prestigeDossierCorporation = new DossierCorporationApiFactory({}, '/api/dossier', axiosInstance)
Vue.prototype.$prestigeDossierDocument = new DossierDocumentApiFactory({}, '/api/dossier', axiosInstance)
Vue.prototype.$prestigeDossierPrinter = new DossierPrinterApiFactory({}, '/api/dossier', axiosInstance)
Vue.prototype.$prestigeDossierRecipient = new DossierRecipientApiFactory({}, '/api/dossier', axiosInstance)
Vue.prototype.$prestigeDossierCustomRecipient = new DossierRecipientApiFactory({}, '/api/dossier', customAxiosInstance)
Vue.prototype.$prestigeDossierScan = new DossierScanApiFactory({}, '/api/dossier', axiosInstance)
Vue.prototype.$dossierImporter = new DossierImporterApiFactory({}, '/api/dossier', customAxiosInstance)

// Ticketing System APIs
Vue.prototype.$prestigeTSUsers = new UserApiFactory({}, '/api', axiosInstance)
Vue.prototype.$prestigeTSProcess = new ProcessApiFactory({}, '/api', customAxiosInstance)
Vue.prototype.$prestigeTSXMLProcess = new XmlProcessModelApiFactory({}, '/api', customAxiosInstance)
Vue.prototype.$prestigeTSTask = new TicketingTaskApiFactory({}, '/api', axiosInstance)
Vue.prototype.$prestigeTSStartForm = new TicketingStartFormApiFactory({}, '/api', axiosInstance)
Vue.prototype.$prestigeTSFile = new TicketingFileApiFactory({}, '/api', axiosInstance)
Vue.prototype.$ticketingEnabled = config.TICKETING_ENABLED
Vue.prototype.$showAllProcessDefinitions = config.SHOW_ALL_PROCES_DEFINITIONS
// Feedback APIs
Vue.prototype.$prestigeFeedback = new FeedbackRequestApiFactory({}, '/api', customAxiosInstance)

// Construction Projects APIs
Vue.prototype.$prestigeConstructionProjects = new ConstructionProjectsApiFactory({}, '/api', customAxiosInstance)

// Renting APIs
Vue.prototype.$prestigeRenting = new RentingApiFactory({}, '/api', customAxiosInstance)
Vue.prototype.$prestigeRentalForm = new RentalFormApiFactory({}, '/api', customAxiosInstance)
Vue.prototype.$prestigeRentingFile = new RentingFileApiFactory({}, '/api', customAxiosInstance)

// Configuration object
Vue.prototype.$config = config

if (config.AZURE_DEV_TENANT_ID && config.AZURE_APP_CLIENT_ID) {
  Vue.use(AuthenticationContext, {
    // This config gets passed along to Adal, so all settings available to adal can be used here.
    config: {
      // 'common' (multi-tenant gateway) or Azure AD Tenant ID
      tenant: config.AZURE_DEV_TENANT_ID,

      // Application ID
      clientId: config.AZURE_APP_CLIENT_ID,

      // Host URI
      redirectUri: location.protocol + '//' + location.host,

      cacheLocation: 'localStorage'
    },

    // Set this to true for authentication on startup
    requireAuthOnInitialize: false,

    // Pass a vue-router object in to add route hooks with authentication and role checking
    router: router
  })
} else {
  console.log('security not enabled')
}

if (config.AZURE_INSIGHTS_INSTRUMENTATION_KEY) {
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: config.AZURE_INSIGHTS_INSTRUMENTATION_KEY,
      appId: 'frontend',
      enableDebug: true,
      enableAutoRouteTracking: true,
      autoTrackPageVisitTime: true,
      distributedTracingMode: 2 // only w3c mode
    }
  })
  appInsights.loadAppInsights()
  Vue.prototype.$appInsights = appInsights
} else {
  console.log('no instrumentationKey defined')
}

if (config.GOOGLE_MAPS_KEY) {
  Vue.use(VueGoogleMaps, {
    load: {
      key: config.GOOGLE_MAPS_KEY
    }
  })
} else {
  console.log('no googleMapsKey defined')
}

const apolloProvider = createApolloProvider(Vue.prototype.$adal, Vue.prototype.$appInsights)

const vm = new Vue({ // eslint-disable-line no-new
  el: '#app',
  vuetify,
  router,
  i18n,
  vuex,
  apolloProvider,
  store,
  components: { App },
  template: '<App/>',
  created () {
    axiosInterceptors(axiosInstance, this)
    axiosInterceptors(customAxiosInstance, this)
  }
}).$mount('#app')

export default vm
