<template>
  <v-progress-linear indeterminate color="blue" v-if="$apollo.loading"></v-progress-linear>
  <v-list dense v-else-if="hasViewings">
    <v-subheader>{{ $t('ticketingSystem.processes.rentingForm.viewing.title') }}</v-subheader>
    <v-list-item
      v-for="rentingViewing in rentingViewings"
      v-bind:key="'viewing-' + rentingViewing.propertyId + '-' + rentingViewing.objectId"
    >
      <v-list-item-content>
        <v-list-item-title>
          <a
            :href="rentingViewing.href"
            target="_blank"
          >
            {{ rentingViewing.propertyObject.houseStreet }} {{ rentingViewing.propertyObject.property.place }} - {{ rentingViewing.propertyObject.objectTextShort }} {{ rentingViewing.propertyObject.floorTextShort }}
          </a>
        </v-list-item-title>

        <v-simple-table dense>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left" width="25%">{{ $t('ticketingSystem.processes.rentingForm.viewing.headerName') }}</th>
              <th class="text-left" width="25%">{{ $t('ticketingSystem.processes.rentingForm.viewing.headerImpression') }}</th>
              <th class="text-left" width="25%">{{ $t('ticketingSystem.processes.rentingForm.viewing.headerInternalRemark') }}</th>
              <th class="text-left" width="25%">{{ $t('ticketingSystem.processes.rentingForm.viewing.headerInterest') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(viewing, index) in rentingViewing.viewings" v-bind:key="'viewing-' + rentingViewing.propertyId + '-' + rentingViewing.objectId + '-' + index">
              <td>{{ viewing.firstName }} {{ viewing.lastName }}<br />{{ formatDateFunc(viewing.viewingDate) }}<v-icon v-if="viewing.cancelled" :title="$t('ticketingSystem.processes.rentingForm.viewing.viewingCancelledYes')">mdi-calendar-remove</v-icon></td>
              <td>{{ viewing.impression }}</td>
              <td class="text-pre-wrap">{{ viewing.internalRemark }}</td>
              <td>{{ viewing.interest }}</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
  </v-list>
</template>

<script>
import { mapActions } from 'vuex'
import {formatDate} from '@/helpers/formatterHelper'
import { RENTING_VIEWINGS } from './../../../../ticketing/graphql-apollo/rentingViewings.gql'

export default {
  name: 'RentingViewings',
  apollo: {
    rentingViewings: {
      query: RENTING_VIEWINGS,
      skip () {
        return !this.rentingFormId
      },
      error (error) {
        this.setErrorSnackbar(error.message)
      },
      variables () {
        return {
          rentingFormId: this.rentingFormId
        }
      }
    }
  },
  props: {
    rentingFormId: {
      type: Number,
      default: null
    }
  },
  computed: {
    hasViewings () {
      return this.rentingViewings && this.rentingViewings.length > 0
    }
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    formatDateFunc: formatDate
  }
}
</script>
