<template>
  <div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              Name
            </th>
            <th class="text-left">
              Wert
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Entscheid Kündigung terminlich</td>
            <td>{{ terminationOnOffSchedule }}</td>
          </tr>
          <tr>
            <td>Haftung</td>
            <td>
              {{ tenantLiable }}<span v-if="liableUntil"> (bis {{formatDate(liableUntil)}})</span><br />
              {{getVariableValue('System_TenantLiableReason_String')}}
            </td>
          </tr>
          <tr v-if="displayRentalFormInformation">
            <td>Mietantrag (Link)</td>
            <td>
              <a :href="href + rentalCaseToken" target="_blank">Mietantrag</a>
            </td>
          </tr>
          <tr v-if="displayRentalFormInformation">
            <td>Mietantrag (Token)</td>
            <td>{{rentalCaseToken}}</td>
          </tr>
          <tr v-if="!displayRentalFormInformation">
            <td>Mietantrag (QR Code)</td>
            <td>
              <QRCode :content="href + rentalCaseToken" />
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-alert type="warning" transition="scale-transition">
      {{ $t('ticketingSystem.processes.termination.alert') }}
    </v-alert>
    <v-btn block color="primary" target="_blank" :href="'https://abacus.prestige.ch:40001/abastart?mandant=10&program=immo.11&ObjectContractNumber=' + getVariableValue('System_RentalContractId_Long')">
      Serienbrief Kündigungsbestätigung
    </v-btn>
  </div>
</template>

<script>
import * as ticketingHelper from '@/features/ticketing/helpers/ticketingHelper'
import { formatDate } from '@/helpers/formatterHelper'
import QRCode from '@/components/QRCode'

export default {
  name: 'TerminationConfirmation',
  props: {
    processVariables: {
      type: Array,
      default: () => {}
    }
  },
  components: {
    QRCode
  },
  computed: {
    href () {
      return window.location.protocol + '//' + window.location.host + '/renting/form/'
    },
    terminationOnOffSchedule () {
      const terminationOnOffSchedule = this.getVariableValue('System_TerminationOnOffSchedule_Enum')
      if (terminationOnOffSchedule === 'ON_SCHEDULE') {
        return 'Terminlich'
      } else if (terminationOnOffSchedule === 'OFF_SCHEDULE') {
        return 'Ausserterminlich'
      } else {
        return 'n/a'
      }
    },
    tenantLiable  () {
      const terminationOnOffSchedule = this.getVariableValue('System_TenantLiable_Enum')
      if (terminationOnOffSchedule === 'YES') {
        return 'Ja'
      } else if (terminationOnOffSchedule === 'NO') {
        return 'Nein'
      } else {
        return 'n/a'
      }
    },
    rentalCaseToken () {
      return this.getVariableValue('System_RentalCaseToken_String')
    },
    liableUntil () {
      return this.getVariableValue('System_TenantLiableUntil_Date')
    },
    displayRentalFormInformation () {
      return this.rentalCaseToken
    }
  },
  methods: {
    formatDate: formatDate,
    getVariableValue (variableName) {
      return ticketingHelper.getVariableValue(this.processVariables, variableName)
    }
  }
}
</script>

<style scoped lang="postcss">
</style>
