<template>
  <div>
    <v-card class="mt-5" v-for="(resident, index) in residents" v-bind:key="'resident-' + index">
      <ResidentInfo :resident="resident" />
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="12" xl="6">
            <v-card>
              <v-card-title>{{ $t('renting.documents.creditRating') }}</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="4" class="py-0">
                    <span class="subtitle-2 font-weight-bold">{{ $t('renting.publicForm.fields.personInfo.employment.employer') }}:</span> <span>{{ resident.employer }}</span>
                  </v-col>
                  <v-col cols="4" class="py-0">
                    <span class="subtitle-2 font-weight-bold">{{ $t('renting.publicForm.fields.personInfo.employment.job') }}:</span> <span>{{ resident.profession }}</span>
                  </v-col>
                  <v-col cols="4" class="py-0">
                    <span class="subtitle-2 font-weight-bold">{{ $t('renting.publicForm.fields.personInfo.employment.income') }}:</span> <span>{{ resident.income }}</span>
                  </v-col>
                </v-row>
                <v-row v-if="resident.validateCertificate">
                  <v-col cols="12">
                    <v-card>
                      <ResidentCertificatePreview :resident="resident" />
                    </v-card>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="py-0">
                    <span class="subtitle-2 font-weight-bold">{{ $t('renting.documents.debtEnforcement') }}:</span>
                    <v-icon
                      :color="resident.receivedDebtEnforcement ? 'red' : 'green'"
                      class="mb-1"
                    >
                      {{ resident.receivedDebtEnforcement ? 'checked' : 'close' }}
                    </v-icon>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="py-0 d-flex">
                    <span class="subtitle-2 font-weight-bold py-1 pr-1">{{ $t('renting.documents.debtEnforcementPerMailReceived') }}:</span>
                    <v-radio-group dense class="mt-0" row v-model="resident.debtEnforcementPerMailReceived" :rules="[rules.radioRequired]">
                      <v-radio :label="$t('common.options.yes')" :value="true" :disabled="formReadOnly"></v-radio>
                      <v-radio :label="$t('common.options.no')" :value="false" :disabled="formReadOnly"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row class="mt-0">
                  <v-col cols="12" class="py-0 d-flex">
                    <span class="subtitle-2 font-weight-bold py-1">{{ $t('renting.documents.debtEnforcementPerMailOriginalReturned') }}:</span>
                    <v-radio-group
                      dense
                      row
                      class="mt-0 mr-2"
                      v-model="resident.debtEnforcementPerMailOriginalReturned"
                      :rules="[rules.radioRequired]"
                      @change="!resident.debtEnforcementPerMailOriginalReturned ? resident.debtEnforcementPerMailOriginalReturnedAt = null : null"
                    >
                      <v-radio :label="$t('common.options.yes')" :value="true" :disabled="formReadOnly"></v-radio>
                      <v-radio :label="$t('common.options.no')" :value="false" :disabled="formReadOnly"></v-radio>
                    </v-radio-group>
                    <DatePicker
                      v-if="resident.debtEnforcementPerMailOriginalReturned"
                      v-model="resident.debtEnforcementPerMailOriginalReturnedAt"
                      :default-value="false"
                      :default-value-date="resident.debtEnforcementPerMailOriginalReturnedAt"
                      :label="$t('renting.documents.debtEnforcementPerMailOriginalReturnedAt')"
                      :disabled="formReadOnly"
                      :required="resident.debtEnforcementPerMailOriginalReturned"
                      :rules="resident.debtEnforcementPerMailOriginalReturned ? [rules.required] : []"
                      attach-selector
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="py-0">
                    <span class="subtitle-2 font-weight-bold">{{ $t('renting.publicForm.fields.personInfo.creditRating.comment') }}: </span>
                    <span>{{ resident.creditRatingComment }}</span>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-title class="pb-1">{{ $t('renting.documents.creditRatingConclusion') }}</v-card-title>
              <v-card-text>
                <v-radio-group dense class="mt-0" row v-model="resident.creditRatingConclusion" :rules="[rules.radioRequired]">
                  <v-radio :label="$t('renting.documents.conclusionGood')" :value="true" :disabled="formReadOnly"></v-radio>
                  <v-radio :label="$t('renting.documents.conclusionBad')" :value="false" :disabled="formReadOnly"></v-radio>
                </v-radio-group>
                <v-textarea
                  dense
                  class="mt-0 pt-0"
                  :label="$t('renting.publicForm.fields.personInfo.creditRating.comment')"
                  v-model="resident.creditRatingConclusionComment"
                  :disabled="formReadOnly"
                ></v-textarea>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col v-if="!parking" cols="12" xl="6">
            <v-card>
              <v-card-title>{{ $t('renting.documents.identityDocuments') }}</v-card-title>
              <v-card-text>
                <v-row v-if="resident.foreigner">
                  <v-col cols="4" class="py-0">
                    <span class="subtitle-2 font-weight-bold">{{ $t('renting.publicForm.fields.personInfo.foreignerInfo.country') }}:</span> <span>{{ getResidentCountryName(resident) }}</span>
                    <v-icon color="orange" class="mb-1">
                      warning
                    </v-icon>
                  </v-col>
                  <v-col cols="4" class="py-0">
                    <span class="subtitle-2 font-weight-bold">{{ $t('renting.publicForm.fields.personInfo.foreignerInfo.residencePermitType') }}:</span>
                    <span>{{ $t('renting.publicForm.fields.personInfo.foreignerInfo.residencePermitType' + resident.foreignerResidencePermitType) }}</span>
                  </v-col>
                  <v-col cols="4" class="py-0" v-if="resident.savedFiles">
                    <template v-for="(item, index) in resident.savedFiles">
                      <v-list-item :key="'file' + index">
                        <v-list-item-title><span class="subtitle-2 font-weight-bold color-card-text">{{ item }}</span></v-list-item-title>
                        <v-list-item-action>
                          <v-btn :key="'button' + index" icon ripple @click.stop="downloadAttachment(item)" :title="$t('common.buttons.download')">
                            <v-icon color="blue lighten-1">file_download</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col cols="12" class="py-0">
                    <span class="subtitle-2 font-weight-bold">{{ $t('renting.documents.swissCitizenship') }}:</span>
                    <v-icon color="green" class="mb-1">
                      checked
                    </v-icon>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-title>{{ $t('renting.documents.creditRatingConclusion') }}</v-card-title>
              <v-card-text>
                <v-radio-group dense class="mt-0" row v-model="resident.identityDocumentsConclusion" :rules="[rules.radioRequired]">
                  <v-radio :label="$t('renting.documents.conclusionGood')" :value="true" :disabled="formReadOnly"></v-radio>
                  <v-radio :label="$t('renting.documents.conclusionBad')" :value="false" :disabled="formReadOnly"></v-radio>
                </v-radio-group>
                <v-textarea
                  dense
                  class="mt-0 pt-0"
                  :label="$t('renting.publicForm.fields.personInfo.creditRating.comment')"
                  v-model="resident.identityDocumentsComment"
                  :disabled="formReadOnly"
                ></v-textarea>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ResidentInfo from './shared/ResidentInfo'
import ResidentCertificatePreview from '../ResidentCertificatePreview'
import DatePicker from '@/components/DatePicker'
import * as ticketingHelper from '@/features/ticketing/helpers/ticketingHelper'
import { getGraphQLVariable } from '@/helpers/graphQLHelper'
import { formatDate } from '@/helpers/formatterHelper'
import { mapActions } from 'vuex'
import { TICKETING_TICKET_EXECUTION } from '../../graphql-apollo/ticketingTicket.gql'

import countries from 'i18n-iso-countries'
countries.registerLocale(require('i18n-iso-countries/langs/en.json'))
countries.registerLocale(require('i18n-iso-countries/langs/de.json'))

import { useRentingForm } from '@/use/useRentingForm'
const { downloadResidentAttachment, loadRentingForm } = useRentingForm()

const PARKING = 'PARKING'

export default {
  name: 'RentingFormDocuments',
  components: {
    ResidentInfo,
    ResidentCertificatePreview,
    DatePicker
  },
  props: {
    ticketingTask: {
      type: Object,
      default: () => {}
    },
    processVariables: {
      type: Array,
      default: () => {
      }
    },
    formReadOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      rentingForm: {},
      oldResidentsData: null,
      rules: {
        required: (value) => !!value || this.$t('common.errors.required'),
        radioRequired: (value) => (value !== undefined && value !== '' && value !== null) || this.requiredMessage
      }
    }
  },
  apollo: {
    executionVariables: {
      query: TICKETING_TICKET_EXECUTION,
      skip () {
        return !this.ticketId || !this.executionId
      },
      error (error) {
        this.ticketLoadError = error.message
      },
      variables () {
        return {
          ticketId: this.ticketId,
          executionId: this.executionId
        }
      }
    }
  },
  watch: {
    'rentingFormId': {
      handler: 'getRentalFormData',
      immediate: true
    }
  },
  computed: {
    residents () {
      let residents = this.rentingForm?.content?.residents
      if (residents && this.token) {
        return residents.filter(resident => resident.landlordToken === this.token)
      } else if (residents) {
        return residents
      } else {
        return []
      }
    },
    rentingFormId () {
      return this.getVariableValue('System_RentingFormId_Long')
    },
    ticketId () {
      return this.ticketingTask?.ticket?.ticketId
    },
    executionId () {
      return this.ticketingTask?.executionId
    },
    token () {
      return getGraphQLVariable(this.executionVariables?.variables, 'landlordToken', 'stringValue')
    },
    requiredMessage () {
      return this.$t('common.errors.required')
    },
    parking () {
      return this.ticketingTask.objectType === PARKING
    }
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    formatDate: formatDate,
    async getRentalFormData () {
      this.rentingForm = await loadRentingForm(this.rentingFormId)
      this.loading = false
    },
    async saveThirdPartyData () {
      await this.$prestigeRenting.updateRentingForm(this.rentingFormId, this.rentingForm.content)
    },
    getVariableValue (variableName) {
      return ticketingHelper.getVariableValue(this.processVariables, variableName)
    },
    getResidentCountryName (resident) {
      return countries.getNames(this.$i18n.locale, {select: 'official'})[resident.country]
    },
    downloadAttachment (fileName) {
      downloadResidentAttachment(fileName)
    }
  }
}
</script>

<style scoped lang="postcss">
/deep/ .v-input--is-readonly {
  cursor: not-allowed;
  pointer-events: none;
}
/deep/ .datepicker-input {
  margin-top: 0px;
  padding-top: 0px;
}
.color-card-text {
  color: rgba(0,0,0,.6);
}
</style>
