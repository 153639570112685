<template>
  <v-container fluid>
    <v-slide-y-transition mode="out-in">
      <v-layout>
        <PrinterConfiguration />
      </v-layout>
    </v-slide-y-transition>
    <v-slide-y-transition mode="out-in">
      <v-card class="pl-2 pr-2 pb-2 mt-3 grey lighten-5">
        <v-card-title class="pl-1 pb-0">
          <v-icon class="pr-2">file_copy</v-icon>{{ $t('dossier.documentTypeConfiguration.title') }}
        </v-card-title>
        <DocumentTypeConfiguration ref="documentTypeConfiguration" />
        <v-card-actions>
          <v-btn color="success" @click="updateDocumentTypeConfiguration">
            <span>{{ $t('common.buttons.save') }}</span>
            <v-icon>send</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-slide-y-transition>
  </v-container>
</template>

<script>
import PrinterConfiguration from './PrinterConfiguration.vue'
import DocumentTypeConfiguration from './documentConfiguration/DocumentTypeConfiguration.vue'
import { mapActions } from 'vuex'

export default {
  name: 'DossierConfiguration',
  components: {
    PrinterConfiguration,
    DocumentTypeConfiguration
  },
  created () {
    this.resetSavedFilters()
  },
  methods: {
    ...mapActions('printer', ['resetSavedFilters']),
    updateDocumentTypeConfiguration () {
      this.$refs.documentTypeConfiguration.updateDocumentTypeConfiguration()
    }
  }
}
</script>
