<template>
  <v-timeline-item
    class="mb-4"
    :color="color + ' lighten-2'"
    icon="mdi-email"
    fill-dot
  >
    <v-card :color="color + '  lighten-4'">
      <v-card-title class="pa-3">
        <v-list-item dense class="pl-0 pr-0 mb-3">
          <v-list-item-content>
            <v-list-item-title class="subtitle-1 text-wrap">{{ email.subject }}</v-list-item-title>
            <v-list-item-subtitle>{{ formatTimeFunc(email.createdOn) }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-icon>
            <v-btn v-if="isRead === false" dark small color="amber darken-4" @click="setEmailIsRead" class="mr-1">
              {{ $t('ticketingSystem.messages.email.read') }}
            </v-btn>
            <v-btn dark small color="blue-grey" @click.stop="openReplyDialog">
              {{ $t('ticketingSystem.messages.email.reply') }}
            </v-btn>
          </v-list-item-icon>
        </v-list-item>
      </v-card-title>
      <MailClient :email="email" />
      <template v-if="hasAttachments">
        <v-divider></v-divider>
        <v-list dense class="white text--primary">
          <v-list-item v-for="attachment in orderedEmailAttachments" :key="'attachment-' + attachment.location + '/' + attachment.fileName">
            <v-list-item-content>
              <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on" class="truncate-one-line">{{ attachment.fileName }}</span>
              </template>
              <span>{{ attachment.fileName }}</span>
            </v-tooltip>
            </v-list-item-content>
            <v-list-item-action>
              <FileActions
                module-name="email"
                :deletable="false"
                :file="attachment"
                :file-name="attachment.fileName"
              />
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </template>
      <v-divider></v-divider>
      <v-card-text class="white text--primary overflow-auto email-html-body" v-if="email.messageHtml" v-html="email.messageHtml" />
      <v-card-text class="white text--primary overflow-auto text-pre-wrap" v-else>{{ email.messagePlain }}</v-card-text>
    </v-card>
  </v-timeline-item>
</template>

<script>
import FileActions from '@/features/ticketing/components/FileActions'
import MailClient from './MailClient'
import { formatTime } from '@/helpers/formatterHelper'
import { addAutocompleteClickListeners } from '@/features/ticketing/helpers/autocompleteHelper'
import { SET_EMAIL_IS_READ } from '@/features/abacus/graphql-apollo/abacusTicketing.gql.js'
import _cloneDeep from 'lodash.clonedeep'

export default {
  name: 'TicketingEmail',
  props: {
    email: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    MailClient,
    FileActions
  },
  data () {
    return {
      userName: '',
      emailReply: {},
      showBotEmailDialog: false,
      isRead: this.email.isRead
    }
  },
  updated () {
    addAutocompleteClickListeners()
  },
  computed: {
    color () {
      if (this.isRead === false) {
        return 'orange'
      } else if(this.email.from.endsWith('@prestige.ch')) {
        return 'blue-grey'
      } else {
        return 'blue'
      }
    },
    emailAttachments () {
      return this.email.attachments || this.email.azureFileAttachments
    },
    orderedEmailAttachments () {
      let emailAttachmens = _cloneDeep(this.emailAttachments)
      return emailAttachmens.sort((a, b) => (a.fileName.toLowerCase() > b.fileName.toLowerCase())
        ? 1 : (b.fileName.toLowerCase() > a.fileName.toLowerCase() ? -1 : 0))
    },
    hasAttachments () {
      return this.email?.attachments?.length > 0 ||
        this.email?.azureFileAttachments?.length > 0
    }
  },
  methods: {
    formatTimeFunc: formatTime,
    openReplyDialog () {
      this.$emit('openReplyDialog', this.email)
    },
    setEmailIsRead () {
      this.$apollo.mutate({
        mutation: SET_EMAIL_IS_READ,
        variables: {
          isRead: true,
          emailId: this.email.id
        }
      }).then((response) => {
        this.isRead = response.data.setEmailIsRead.isRead
      }).catch((error) => {
        this.message = 'failed to set data: ' + error
      })
    }
  }
}

</script>

<style scoped lang="postcss">
/deep/ .email-html-body {
  max-height: 500px;
}
/deep/ .email-html-body img {
  display: none;
}
/deep/ .email-html-body p {
  margin-bottom: 2px;
}
.truncate-one-line {
  display: -webkit-inline-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
