<template>
  <v-flex class="column" v-if="renderColumn">
    <v-card-title class="pa-3 column-title font-weight-bold">
      {{ title }}
    </v-card-title>
    <v-alert class="ma-2 mr-10" :value="true" color="error" icon="warning" v-if="ticketLoadError">
      {{ ticketLoadError }}
    </v-alert>
    <div class="column-content" v-if="totalItems > 0">
      <TicketingTaskEntry
        v-for="(item, index) in taskList"
        :task-entry="item"
        :key="index + '_' + item.taskId"
        :hide-assignee="hideAssignee"
        :column-id="columnId"
        @openTaskDetailsDialog="openTaskDetailsDialog"
      />
      <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler">
        <div slot="no-more"></div>
        <div slot="no-results"></div>
      </infinite-loading>
    </div>
  </v-flex>
</template>

<script>
import TicketingTaskEntry from './TicketingTaskEntry'
import InfiniteLoading from 'vue-infinite-loading'

import * as ticketingHelper from './../helpers/ticketingHelper'
import { mapActions } from 'vuex'
import { AuthenticationContext } from 'vue-adal'
import { TICKETING_TASKS } from '../graphql-apollo/ticketingTasks.gql'

export default {
  name: 'TicketingColumn',
  components: {
    TicketingTaskEntry,
    InfiniteLoading
  },
  apollo: {
    ticketingTasks: {
      query: TICKETING_TASKS,
      variables () {
        return {
          assignedUserId: this.assignedUserIdQuery,
          candidateGroup: this.candidateGroupQuery,
          includeAssigned: this.ticketingListFilters.includeAssigned,
          includeFollowUpInFuture: this.ticketingListFilters.includeFollowUpInFuture,
          propertyId: this.ticketingListFilters.property,
          tenantId: this.ticketingListFilters.tenant,
          caretakerId: this.ticketingListFilters.caretaker,
          userId: this.ticketingListFilters.user,
          processDefinitionKey: this.ticketingListFilters.processDefinitionKey,
          priorities: this.ticketingListFilters.priorities,
          page: 0,
          size: 5
        }
      },
      error (error) {
        this.ticketLoadError = error.message
      },
      manual: true,
      result ({ data }) {
        if (data?.ticketingTasks) {
          this.taskList = [...data.ticketingTasks.items]
          this.totalItems = data.ticketingTasks.totalItems
        }
      }
    }
  },
  watch: {
    infiniteIdChange () {
      this.changeType()
    }
  },
  data () {
    return {
      page: 0,
      taskList: [],
      totalItems: 0,
      ticketLoadError: '',
      infiniteId: 0
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    hideAssignee: {
      type: Boolean,
      default: false
    },
    columnId: {
      type: String,
      default: 'personal'
    },
    renderColumn: {
      type: Boolean,
      default: true
    },
    groupTasks: {
      type: Boolean,
      default: false
    },
    ticketingListFilters: {
      type: Object,
      default: () => {}
    },
    infiniteIdChange: {
      type: Number,
      default: 0
    },
    candidateGroups: {
      type: Array,
      default: () => []
    }
  },
  mounted () {
    this.windowResizeHandler()
  },
  computed: {
    assignedUserIdQuery () {
      if (this.columnId === 'personal') {
        let userId = AuthenticationContext && AuthenticationContext.user && AuthenticationContext.user.profile ? AuthenticationContext.user.profile.employeeid : null
        return userId
      } else {
        return null
      }
    },
    candidateGroupQuery () {
      if (this.columnId === 'personal') {
        return null
      } else if (this.columnId === 'general') {
        return ''
      } else {
        return this.columnId
      }
    }
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    windowResizeHandler () {
      ticketingHelper.setTicketingColumnHeight()
    },
    async infiniteHandler ($state) {
      this.page++
      await this.$apollo.queries.ticketingTasks.fetchMore({
        variables: {
          page: this.page
        },
        updateQuery: (previous, {fetchMoreResult}) => {
          this.taskList.push(...fetchMoreResult.ticketingTasks.items)
          this.totalItems = fetchMoreResult.ticketingTasks.totalItems
          return {
            ...previous
          }
        }
      })
      if (this.taskList.length < this.totalItems) {
        $state.loaded()
      } else {
        $state.complete()
      }
      ticketingHelper.setTicketingColumnHeight()
    },
    changeType () {
      this.page = 0
      this.taskList = []
      this.totalItems = 0
      this.infiniteId += 1
      this.$apollo.queries.ticketingTasks.refresh()
    },
    openTaskDetailsDialog (taskEntryId) {
      this.$emit('openTaskDetailsDialog', taskEntryId)
    }
  }
}
</script>

<style scoped lang="postcss">
.column {
  background-color: rgb(211, 211, 211, 0.2);
  min-width: 30vh;
  max-width: 100vh;
  width: 30vh;
}
.column-content {
  overflow-y: scroll;
}
.column-title {
  font-size: 1em;
  background-color: #fff;
  border-bottom: 2px solid lightgray;
}
.border-left {
  border-right: 2px solid lightgray;
}
::-webkit-scrollbar-button:single-button {
  background-color: lightgray;
  display: block;
  border-style: solid;
  height: 13px;
  width: 16px;
}
/* Up */
::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent #555555 transparent;
}
::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  border-color: transparent transparent #777777 transparent;
}
/* Down */
::-webkit-scrollbar-button:single-button:vertical:increment {
  border-width: 8px 8px 0 8px;
  border-color: #555555 transparent transparent transparent;
}
::-webkit-scrollbar-button:vertical:single-button:increment:hover {
  border-color: #777777 transparent transparent transparent;
}
/* Scrollbar */
::-webkit-scrollbar {
  width: 12px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(99, 99, 99, .7);
  background-color: #fff;
}
::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px lightgray;
  background-color: #F5F5F5;
}
</style>
