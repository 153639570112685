<template>
  <v-list-item v-if="ticketingTicket" class="pl-0 pr-0">
    <v-list-item-content class="pa-0">
      <v-card-text>
        <div class="pa-0 ma-0 ticket-link">
          <StateIcon :state="ticketingTicket.state" />
          [<a @click="onTicketClicked(ticketingTicket.ticketId)">{{ ticketingTicket.processDefinitionName }}</a>]
          <p class="mb-0 text">{{ ticketingTicket.subject }}</p>
        </div>
      </v-card-text>
    </v-list-item-content>
     <v-list-item-action class="ma-3">
       <ActionsMenu
         :item="getItem(ticketingTicket)"
         :actions="actions"
         :delete-action="deleteAction"
       />
     </v-list-item-action>
  </v-list-item>
</template>

<script>
import ActionsMenu from '@/components/ActionsMenu'
import StateIcon from '@/components/StateIcon'

import { TASK_TICKETING_TICKET } from '@/features/tasks/graphql-apollo/taskTicketingTicket.gql'

import { openTicket } from '@/helpers/routesHelper'

export default {
  name: 'ProcessTicket',
  components: {
    ActionsMenu,
    StateIcon
  },
  props: {
    deleteAction: {
      type: Object,
      default: () => {}
    },
    ticketId: {
      type: String,
      default: ''
    }
  },
  apollo: {
    ticketingTicket: {
      query: TASK_TICKETING_TICKET,
      variables () {
        return {
          ticketId: this.ticketId
        }
      },
      skip () {
        return !this.ticketId
      },
      fetchPolicy: 'cache-first'
    }
  },
  computed: {
    actions () {
      return [
        { title: this.$t('dashboard.actions.newTicket'), icon: 'mdi-ticket', action: this.onNewTicket }
      ]
    }
  },
  methods: {
    getItem (item) {
      return Object.assign(item, { linkName: 'ticket', linkValue: item.ticketId })
    },
    onTicketClicked (ticketId) {
      openTicket(ticketId)
    },
    onNewTicket (item) {
      this.$emit('openNewTicketDialog', item)
    }
  }
}
</script>