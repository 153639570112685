<template>
  <v-dialog v-model="mutableShowDialog" max-width="500px" @keydown.esc="closeDialog" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t('heatingOil.tankLevel.fillingLevelDialog.title') }}</span>
        <v-btn icon absolute right>
          <v-icon class="clear" color="primary" :size="28" @click.native="closeDialog"/>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-0 pb-0">
        <v-container grid-list-xs>
          <v-form ref="form" v-model="valid">
            <v-alert v-if="mutableItem.comment" type="warning">
              {{ mutableItem.comment }}
            </v-alert>
            <v-text-field
              name="input-1"
              autofocus
              required
              :rules="[rules.required, rules.fillingLevel]"
              :label="$t('heatingOil.tankLevel.fillingLevelDialog.remainingAmount')"
              :hint="$t('heatingOil.tankLevel.fillingLevelDialog.hint')"
              persistent-hint
              type="number"
              v-model="mutableItem.fillingLevel"
            ></v-text-field>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="closeDialog">{{ $t('common.buttons.cancel') }}</v-btn>
        <v-btn color="primary" :disabled="!valid" @click.native="saveFillingLevel">{{ $t('common.buttons.save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'FillingLevelDialog',
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      valid: false,
      rules: {
        required: (value) => !!value || this.$t('common.errors.required'),
        fillingLevel: (value) => {
          const valid = !isNaN(parseInt(value)) && value <= this.mutableItem.volume && value >= 0
          return valid || this.$t('heatingOil.tankLevel.fillingLevelDialog.validAmount')
        }
      }
    }
  },
  methods: {
    closeDialog () {
      this.$emit('closeDialog')
    },
    saveFillingLevel () {
      if (this.$refs.form.validate()) {
        this.$emit('saveFillingLevel')
      }
    }
  },
  computed: {
    mutableShowDialog () {
      return this.showDialog
    },
    mutableItem () {
      return this.item
    }
  }
}
</script>
