export const navigationItems = [
  {
    requireRoles: 'prestige-tools-reader',
    icon: 'build',
    title: 'constructionProjects',
    target: '/construction-projects',
    enabled: true
  },
  {
    requireRoles: 'prestige-tools-reader',
    icon: 'local_gas_station',
    title: 'heatingOil',
    enabled: true,
    items: [
      {
        icon: 'local_gas_station',
        title: 'tanks',
        target: '/heating-oil/tanks'
      },
      {
        icon: 'shopping_cart',
        title: 'basket',
        target: '/heating-oil/basket'
      },
      {
        icon: 'local_shipping',
        title: 'orders',
        target: '/heating-oil/orders'
      }
    ]
  },
  {
    requireRoles: 'prestige-tools-reader',
    icon: 'file_copy',
    title: 'dossier',
    enabled: true,
    items: [
      {
        icon: 'location_city',
        title: 'corporations',
        target: '/dossier/corporations'
      },
      {
        icon: 'person',
        title: 'recipients',
        target: '/dossier/recipients'
      },
      {
        icon: 'print',
        title: 'queue',
        target: '/dossier/queue'
      },
      {
        icon: 'settings',
        title: 'settings',
        target: '/dossier/configuration'
      }
    ]
  },
  {
    requireRoles: 'prestige-tools-reader',
    icon: 'chat_bubble_outline',
    title: 'feedback',
    target: '/feedback/list',
    enabled: true
  },
  {
    requireRoles: 'prestige-tools-reader',
    icon: 'house',
    title: 'rentingList',
    name: 'RentingList',
    target: '/renting/interested-persons/list',
    enabled: true
  },
  {
    requireRoles: 'prestige-tools-reader',
    icon: 'map',
    title: 'map',
    enabled: true,
    items: [
      {
        icon: 'mdi-home-search-outline',
        title: 'properties',
        target: '/map/properties'
      },
      {
        icon: 'mdi-home-remove-outline',
        title: 'terminations',
        target: '/map/terminations'
      }
    ]
  },
  {
    requireRoles: 'prestige-tools-admin',
    icon: 'trending_up',
    title: 'statistics',
    target: '/statistics',
    enabled: true
  },
  {
    requireRoles: 'prestige-tools-admin',
    icon: 'trending_up',
    title: 'ticketingStatistics',
    target: '/ticketing-statistics',
    enabled: true
  },
  {
    requireRoles: 'prestige-tools-admin',
    icon: 'settings',
    title: 'admin',
    enabled: true,
    items: [
      {
        icon: 'mdi-ticket',
        title: 'ticketing',
        target: '/admin/tickets'
      },
      {
        icon: 'ballot',
        title: 'abacusAdapter',
        target: '/admin/abacus-adapter'
      },
      {
        icon: 'search',
        title: 'search',
        target: '/admin/search'
      },
      {
        icon: 'mdi-database-import',
        title: 'dataImport',
        target: '/admin/data-import'
      },
      {
        icon: 'location_on',
        title: 'propertyLocations',
        target: '/admin/property-locations'
      },
      {
        icon: 'trending_up',
        title: 'charts',
        target: '/admin/charts'
      }
    ]
  },
  {
    requireRoles: 'prestige-tools-admin',
    icon: 'dashboard',
    title: 'abacusDemo',
    enabled: true,
    items: [
      {
        icon: 'mdi-plus-circle-outline',
        title: 'taskCreate',
        name: 'TaskCreate',
        target: '/abacus/tasks/create/:propertyId/:propertyObjectId?',
        propertyParams: true,
        newTab: true
      },
      {
        icon: 'list',
        title: 'tasksList',
        name: 'TasksList',
        target: '/abacus/tasks/list/:propertyId?/:propertyObjectId?',
        propertyParams: true,
        newTab: true
      },
      {
        icon: 'mdi-plus-circle-outline',
        title: 'interestedPersonCreate',
        name: 'AbacusInterestedPersonCreate',
        target: '/abacus/renting/interested-persons/create/:propertyId?/:propertyObjectId?',
        propertyParams: true,
        newTab: true
      },
      {
        icon: 'house',
        title: 'rentingList',
        name: 'AbacusRentingList',
        target: '/abacus/renting/interested-persons/list/:propertyId?/:propertyObjectId?',
        propertyParams: true,
        enabled: true,
        newTab: true
      },
      {
        icon: 'mdi-plus-circle-outline',
        title: 'abacusTicketingStart',
        name: 'AbacusTicketingStart',
        target: '/abacus/ticketing/:processDefinitionKey/:externalTicketId/create',
        ticketParams: true,
        enabled: true,
        newTab: true
      },
      {
        icon: 'mdi-play-circle-outline',
        title: 'abacusTicketingUpdate',
        name: 'AbacusTicketingUpdate',
        target: '/abacus/ticketing/:processDefinitionKey/:externalTicketId/:activityId/update',
        ticketParams: true,
        enabled: true,
        newTab: true
      },
      {
        icon: 'mdi-check-circle-outline',
        title: 'abacusTicketingComplete',
        name: 'AbacusTicketingComplete',
        target: '/abacus/ticketing/:processDefinitionKey/:externalTicketId/:activityId/complete',
        ticketParams: true,
        enabled: true,
        newTab: true
      }
    ]
  }
]
