<template>
  <v-card class="height mt-2">
    <v-expansion-panels>
      <v-expansion-panel class="pa-3 pr-4">
        <v-expansion-panel-header class="pa-0">
          <v-card-subtitle class="pa-0 font-weight-bold subtitle-1">
            {{ $t('ticketingSystem.processes.completedTasks') }}
          </v-card-subtitle>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-data-table
            v-if="completedTasks && completedTasks.length > 0"
            :headers="headers"
            :items="filteredCompletedTasks"
            :items-per-page="5"
            :footer-props="{ itemsPerPageOptions: itemsPerPageOptions, itemsPerPageText: itemsPerPageText }"
            @click:row="goToTaskDetails"
            class="elevation-1 mt-2"
            sort-by="finishedOn"
          >
            <template v-slot:[`item.startedOn`]="{ item }">
              {{ formatDateAndTimeFunc(item.startedOn) }}
            </template>
            <template v-slot:[`item.finishedOn`]="{ item }">
              {{ formatDateAndTimeFunc(item.finishedOn) }}
            </template>
            <template v-slot:[`item.assignee`]="{ item }">
              <UserName :user="item.assignee" />
            </template>
          </v-data-table>
          <v-card-text v-else class="mt-2 pa-0">
            {{ $t('table.noData') }}
          </v-card-text>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import { formatDateAndTime } from '@/helpers/formatterHelper'
import UserName from '@/components/UserName'
import { mapState } from 'vuex'

export default {
  name: 'ProcessCompletedTasks',
  components: {
    UserName
  },
  props: {
    completedTasks: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState('ticketingSystem', ['ticketingUsers']),
    headers () {
      return [
        {
          text: this.$t('ticketingSystem.task'),
          align: 'left',
          value: 'name'
        },
        {
          text: this.$t('ticketingSystem.entry.created'),
          align: 'left',
          value: 'startedOn'
        },
        {
          text: this.$t('ticketingSystem.entry.finished'),
          align: 'left',
          value: 'finishedOn'
        },
        {
          text: this.$t('ticketingSystem.entry.assignee'),
          align: 'left',
          value: 'assignee'
        }
      ]
    },
    filteredCompletedTasks () {
      return this.completedTasks.filter(t => t.parentTaskId == null)
    },
    itemsPerPageOptions () {
      return [
        5,
        10,
        15
      ]
    },
    itemsPerPageText () {
      return this.$t('common.itemsPerPageText')
    }
  },
  methods: {
    formatDateAndTimeFunc: formatDateAndTime,
    goToTaskDetails (item) {
      if (item.taskId) {
        let taskFinished = true
        this.$emit('openTaskDetailsDialog', item.taskId, taskFinished)
      }
    }
  }
}
</script>

<style scoped lang="postcss">
/deep/ tr {
  cursor: pointer;
}
@media (min-width: 960px) {
  .height {
    margin-right: 8px;
  }
}
/deep/ .theme--light.v-expansion-panels .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon {
  color: #1976d2;
}
/deep/ .v-expansion-panel-content__wrap {
  padding: 0;
}
</style>
