import gql from 'graphql-tag'

const RENTAL_CASE = gql`
  query rentingRentalCase ($rentalContractId: Int!) {
    rentingRentalCase(rentalContractId: $rentalContractId) {
      rentalCaseId
      rentalContractId
      token
      acceptSubmit
      propertyObject {
        houseStreet
        property {
          place
          zip
        }
        objectText
        floorText
      }
      rentalForms {
        id
        ticketId
        formContent {
          desiredMoveInDate
          residents {
            firstName
            lastName
          }
        }
        ticket {
          startedOn
          openTasks {
              name
          }
          variables(variableNames: ["System_InterestedPerson_Decision_Enum", "System_InterestedPerson_DecisionComment_String"]) {
            __typename
            ... on TicketingStringVariable {
              name
              stringValue: value
            }
          }
        }
      }
    }
  }
`

const RENTAL_CASE_ENABLE_TOKEN = gql`
  mutation ($rentalContractId: Int!) {
    rentingEnableTokenForRentalCase(rentalContractId: $rentalContractId) {
      rentalContractId
      propertyId
      objectId
      token
      acceptSubmit
      propertyObject {
        houseStreet
        property {
          zip
          place
        }
        floorText
        objectText
      }
    }
  }
`

const RENTAL_CASE_DISABLE_TOKEN = gql`
  mutation ($rentalContractId: Int!) {
    rentingDisableTokenForRentalCase(rentalContractId: $rentalContractId) {
      rentalContractId
      acceptSubmit
    }
  }
`

export { RENTAL_CASE, RENTAL_CASE_ENABLE_TOKEN, RENTAL_CASE_DISABLE_TOKEN }
