<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card class="pa-2" outlined tile v-if="$apollo.loading">
          <v-progress-linear indeterminate color="blue"></v-progress-linear>
        </v-card>
        <v-card class="pa-2" outlined tile v-if="message">
          <v-alert type="error">
            {{ message }}
          </v-alert>
        </v-card>

        <AbacusRentalCaseStatus v-if="rentingRentalCase" :rental-case="rentingRentalCase" />

        <v-card class="pa-2" outlined tile>
          <v-card-title>{{ $t('renting.abacus.stateCommitment') }} ({{commitmentForms.length}})</v-card-title>
          <v-progress-linear indeterminate color="blue" v-if="$apollo.loading"></v-progress-linear>
          <v-card-text v-else-if="commitmentForms.length > 0">
            <RentalFormList :rental-forms="commitmentForms" :loading="$apollo.loading"></RentalFormList>
          </v-card-text>

          <v-card-title>{{ $t('renting.abacus.statePriority1') }} ({{priority1Forms.length}})</v-card-title>
          <v-progress-linear indeterminate color="blue" v-if="$apollo.loading"></v-progress-linear>
          <v-card-text v-else-if="priority1Forms.length > 0">
            <RentalFormList
              :rental-forms="priority1Forms"
              :loading="$apollo.loading"
              multi-decline-option
              @loadRentalForms="reloadRentalForms"
            >
            </RentalFormList>
          </v-card-text>

          <v-card-title>{{ $t('renting.abacus.statePriority2') }} ({{priority2Forms.length}})</v-card-title>
          <v-progress-linear indeterminate color="blue" v-if="$apollo.loading"></v-progress-linear>
          <v-card-text v-else-if="priority2Forms.length > 0">
            <RentalFormList
              :rental-forms="priority2Forms"
              :loading="$apollo.loading"
              multi-decline-option
              @loadRentalForms="reloadRentalForms"
            >
            </RentalFormList>
          </v-card-text>

          <v-card-title>{{ $t('renting.abacus.statePending') }} ({{pendingForms.length}})</v-card-title>
          <v-progress-linear indeterminate color="blue" v-if="$apollo.loading"></v-progress-linear>
          <v-card-text v-else-if="pendingForms.length > 0">
            <RentalFormList
              :rental-forms="pendingForms"
              :loading="$apollo.loading"
              multi-decline-option
              @loadRentalForms="reloadRentalForms"
            >
            </RentalFormList>
          </v-card-text>

          <v-card-title>{{ $t('renting.abacus.stateDeclined') }} ({{declinedForms.length}})</v-card-title>
          <v-progress-linear indeterminate color="blue" v-if="$apollo.loading"></v-progress-linear>
          <v-card-text v-else-if="declinedForms.length > 0">
            <RentalFormList :rental-forms="declinedForms" :loading="$apollo.loading"></RentalFormList>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AbacusRentalCaseStatus from './AbacusRentalCaseStatus'
import RentalFormList from '../../renting/components/RentalFormList'
import { formatDateAndTime, formatDate } from '@/helpers/formatterHelper'
import { getGraphQLVariable } from '@/helpers/graphQLHelper'
import { RENTAL_CASE } from '../graphql-apollo/rentalCase.gql'

export default {
  name: 'AbacusRentalFormList',
  components: {
    AbacusRentalCaseStatus,
    RentalFormList
  },
  data () {
    return {
      rentalCase: null,
      message: ''
    }
  },
  apollo: {
    rentingRentalCase: {
      query: RENTAL_CASE,
      update (data) {
        if (data.rentingRentalCase) {
          if (data.rentingRentalCase.rentalForms) {
            data.rentingRentalCase.rentalForms = data.rentingRentalCase.rentalForms.map(form => {
              return Object.assign(form, {
                name: form.formContent.residents[0].firstName + ' ' + form.formContent.residents[0].lastName,
                openTasks: form.ticket.openTasks,
                processStartedOn: form.ticket.startedOn,
                processStartedOnFormatted: formatDateAndTime(form.ticket.startedOn),
                desiredMoveInDateFormatted: formatDate(form.formContent.desiredMoveInDate),
                processDecision: getGraphQLVariable(form.ticket.variables, 'System_InterestedPerson_Decision_Enum', 'stringValue', 'IN_PROGRESS'),
                processDecisionComment: getGraphQLVariable(form.ticket.variables, 'System_InterestedPerson_DecisionComment_String', 'stringValue', '')
              })
            })
          } else {
            data.rentingRentalCase.rentalForms = []
          }
        } else {
          data.rentingRentalCase = {
            rentalContractId: this.rentalContractId,
            rentalForms: []
          }
        }

        return data.rentingRentalCase
      },
      skip () {
        return !this.rentalContractId
      },
      error (error) {
        this.message = error.message
      },
      variables () {
        return {
          rentalContractId: this.rentalContractId
        }
      }
    }
  },
  computed: {
    rentalContractId () {
      return parseInt(this.$route.params.rentalContractId)
    },
    commitmentForms () {
      return this.rentingRentalCase?.rentalForms?.filter(form => form.processDecision === 'COMMITMENT') ?? []
    },
    priority1Forms () {
      return this.rentingRentalCase?.rentalForms?.filter(form => form.processDecision === 'PRIORITY_1') ?? []
    },
    priority2Forms () {
      return this.rentingRentalCase?.rentalForms?.filter(form => form.processDecision === 'PRIORITY_2') ?? []
    },
    pendingForms () {
      return this.rentingRentalCase?.rentalForms?.filter(form => form.processDecision === 'IN_PROGRESS') ?? []
    },
    declinedForms () {
      return this.rentingRentalCase?.rentalForms?.filter(form => form.processDecision !== 'COMMITMENT' && form.processDecision !== 'IN_PROGRESS' && form.processDecision !== 'PRIORITY_1' && form.processDecision !== 'PRIORITY_2') ?? []
    }
  },
  methods: {
    reloadRentalForms () {
      this.$apollo.queries.rentingRentalCase.refresh()
    }
  }
}
</script>

<style scoped lang="postcss">
</style>
