<template>
  <div class="hello">
    <v-container fluid class="grey lighten-5">
      <v-row cols="12">
        <v-col>
          <v-card class="mx-auto">
            <v-card-text>
              <v-list-item>
                <v-list-item-title>Email</v-list-item-title>
                <v-list-item-subtitle>{{userInfo.email}}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>Name</v-list-item-title>
                <v-list-item-subtitle>{{userInfo.name}}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>Family Name</v-list-item-title>
                <v-list-item-subtitle>{{userInfo.family_name}}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>Given Name</v-list-item-title>
                <v-list-item-subtitle>{{userInfo.given_name}}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>Employee ID</v-list-item-title>
                <v-list-item-subtitle>{{userInfo.employeeid}}</v-list-item-subtitle>
              </v-list-item>
              <div class="text--primary" v-text="userInfo">
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { AuthenticationContext } from 'vue-adal'
export default {
  data () {
    return {
    }
  },
  computed: {
    userInfo () {
      return AuthenticationContext.user.profile
    }
  }
}
</script>
