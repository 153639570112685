<template>
  <v-card class="pa-4 mb-4">
    <v-card-title class="pa-0 mb-1 text-uppercase">
      <span v-if="residential">
        {{ $t('renting.publicForm.fields.newApartment.additionalInformation.title') }}
      </span>
      <span v-else>
        {{ $t('renting.publicForm.fields.newApartment.title') }}
      </span>
    </v-card-title>

    <div v-if="residential">
      <v-row>
        <v-col cols="12" sm="12" md="2" lg="2" xl="2" class="pb-0">
          <v-select
            :label="$t('renting.publicForm.fields.newApartment.additionalInformation.numberOfAdults')"
            :items="numberOfAdultsItems"
            v-model="mutableForm.numberOfAdults"
            :readonly="readOnly"
          >
          </v-select>
          <v-select
            :label="$t('renting.publicForm.fields.newApartment.additionalInformation.numberOfChildren')"
            :items="numberOfChildrenItems"
            v-model="mutableForm.numberOfChildren"
            :readonly="readOnly"
          >
          </v-select>
        </v-col>
        <v-col cols="12" sm="12" md="10" lg="10" xl="10" class="pb-0">
          <v-textarea
            class="mt-0 pt-2"
            :label="$t('renting.publicForm.fields.newApartment.residenceChangeReason')"
            v-model="mutableForm.reasonForChange"
            required
            :rules="[rules.required]"
            :readonly="readOnly"
            :rows="4"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="4" class="d-md-flex">
          <v-card-title class="pa-0 mr-3 align-md-baseline">{{ $t('renting.publicForm.fields.newApartment.additionalInformation.pets.title') }}</v-card-title>
          <v-radio-group class="mt-0" row v-model="mutableForm.hasPets" :rules="[rules.radioRequired]" :readonly="readOnly" @change="mutableForm.pet = ''">
            <v-radio :label="$t('renting.publicForm.fields.answers.yes')" :value="true"></v-radio>
            <v-radio :label="$t('renting.publicForm.fields.answers.no')" :value="false"></v-radio>
          </v-radio-group>
        </v-col>
        <template v-if="mutableForm.hasPets">
          <v-col cols="12" xs="6" sm="4">
            <v-text-field
              class="mt-0 pt-md-0"
              v-model="mutableForm.pet"
              :label="$t('renting.publicForm.fields.newApartment.additionalInformation.pets.which')"
              :required="mutableForm.hasPets"
              :rules="mutableForm.hasPets ? [rules.required] : []"
              :disabled="!mutableForm.hasPets"
              @input="mutableForm.hasPets = true"
              :readonly="readOnly"
            ></v-text-field>
          </v-col>
          <v-col cols="12" xs="6" sm="4">
            <v-text-field
              class="mt-0 pt-md-0"
              :label="$t('renting.publicForm.fields.newApartment.additionalInformation.pets.breed')"
              v-model="mutableForm.dogBreed"
              :disabled="!mutableForm.hasPets"
              :readonly="readOnly"
            ></v-text-field>
          </v-col>
        </template>
      </v-row>
      <v-row v-if="!readOnly">
        <v-col cols="12">
          <p class="ma-0">{{ $t('renting.publicForm.fields.newApartment.additionalInformation.pets.info') }}</p>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6" class="d-md-flex">
          <v-card-title class="pa-0 align-md-baseline mr-3">{{ $t('renting.publicForm.fields.newApartment.additionalInformation.musicInstruments.title') }}</v-card-title>
          <v-radio-group class="mt-0" row v-model="mutableForm.hasMusicalInstruments" :rules="[rules.radioRequired]" :readonly="readOnly" @change="mutableForm.musicalInstrument = ''">
            <v-radio :label="$t('renting.publicForm.fields.answers.yes')" :value="true"></v-radio>
            <v-radio :label="$t('renting.publicForm.fields.answers.no')" :value="false"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col v-if="mutableForm.hasMusicalInstruments" cols="12" sm="6">
          <v-text-field
            class="mt-0 pt-0"
            v-model="mutableForm.musicalInstrument"
            :label="$t('renting.publicForm.fields.newApartment.additionalInformation.musicInstruments.which')"
            :required="mutableForm.hasMusicalInstruments"
            :rules="mutableForm.hasMusicalInstruments ? [rules.required] : []"
            :disabled="!mutableForm.hasMusicalInstruments"
            @input="mutableForm.hasMusicalInstruments = true"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card-title class="pa-0">{{ $t('renting.publicForm.fields.newApartment.sublet.title') }}</v-card-title>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" class="d-md-flex">
          <v-card-subtitle class="pa-0 mr-3 align-md-baseline">{{ $t('renting.publicForm.fields.newApartment.sublet.subTenants') }}</v-card-subtitle>
          <v-radio-group class="mt-0 pt-md-0" row v-model="mutableForm.takeSubtenantsLater" :rules="[rules.radioRequired]" :readonly="readOnly">
            <v-radio :label="$t('common.options.yes')" :value="true"></v-radio>
            <v-radio :label="$t('common.options.no')" :value="false"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card-title class="pa-0 mr-3">{{ $t('renting.publicForm.fields.newApartment.title') }}</v-card-title>
        </v-col>
      </v-row>
    </div>

    <v-row>
      <v-col cols="12">
        <v-textarea
          class="pa-0 ma-0"
          :label="$t('renting.publicForm.fields.newApartment.renovation')"
          v-model="mutableForm.renovationRequirement"
          :readonly="readOnly"
          :rows="3"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-md-flex">
        <v-card-subtitle class="pa-0 mr-3 align-md-baseline">{{ $t('renting.publicForm.fields.newApartment.concludeContract') }}</v-card-subtitle>
        <v-radio-group class="ma-0 pt-md-0" row v-model="mutableForm.conclusionOfContract" :rules="mutableForm.renovationRequirement ? [rules.radioRequired] : []" :readonly="readOnly">
          <v-radio :label="$t('common.options.yes')" :value="true"></v-radio>
          <v-radio :label="$t('common.options.no')" :value="false"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>

    <div v-if="residential">
      <v-row>
        <v-col cols="12">
          <v-card-title class="pa-0 mr-3">{{ $t('renting.publicForm.fields.newApartment.additionalObjects') }}</v-card-title>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="4">
          <v-checkbox
            v-model="mutableForm.additionalGarage"
            :label="$t('renting.publicForm.fields.newApartment.additionalGarage')"
            :value="true"
            hide-details
            :readonly="readOnly"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" sm="4">
          <v-checkbox
            v-model="mutableForm.additionalParkingSpot"
            :label="$t('renting.publicForm.fields.newApartment.additionalParkingSpot')"
            :value="true"
            hide-details
            :readonly="readOnly"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" sm="4">
          <v-checkbox
            v-model="mutableForm.additionalMotorcycleParkingSpot"
            :label="$t('renting.publicForm.fields.newApartment.additionalMotorcycleParkingSpot')"
            :value="true"
            hide-details
            :readonly="readOnly"
          ></v-checkbox>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'AdditionalInformationForm',
  props: {
    form: {
      type: Object,
      default: () => {}
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    residential: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      numberOfAdultsItems: [1, 2, 3, 4, 5, 6],
      numberOfChildrenItems: [0, 1, 2, 3, 4, 5, 6],
      rules: {
        required: (value) => (!!value && value !== undefined) || this.requiredMessage,
        radioRequired: (value) => (value !== undefined && value !== '' && value !== null) || this.requiredMessage
      }
    }
  },
  computed: {
    mutableForm () {
      return this.form
    },
    requiredMessage () {
      return this.$t('common.errors.required')
    }
  }
}
</script>
<style scoped lang="postcss">
.w-full {
  width: 100%;
}
/deep/ .v-input--is-readonly {
  cursor: not-allowed;
  pointer-events: none;
}
/deep/ .row+.row {
  margin-top: 0;
}
/deep/ .v-input--selection-controls {
  margin-top: 0;
}
</style>
