<template>
  <v-slide-y-transition mode="out-in">
    <v-card class="pl-2 pr-2 pb-2 mt-3 grey lighten-5">
      <v-card-title class="pl-1 pb-0">
        <v-icon class="pr-2">mdi-database-import</v-icon>{{ $t('dataImport.silentsoft.title') }}
      </v-card-title>
      <v-layout wrap class="row pa-2">
        <v-flex class="pl-2 pr-1 mt-2">
            <v-card height="100%">
              <v-container fluid>
                <div class="inline float-left mb-2">
                  <v-btn class="primary ma-2 silentsoft-btn" :loading="silentsoftTankLevelLoading" :disabled="silentsoftTankLevelLoading" @click="triggerSilentSoftImport()">
                    <v-icon class="pr-2">mdi-database-plus</v-icon> {{ $t('dataImport.silentsoft.tankLevel') }}
                  </v-btn>
                </div>
              </v-container>
            </v-card>
        </v-flex>
      </v-layout>
    </v-card>
  </v-slide-y-transition>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'SilentsoftImport',
  data () {
    return {
      silentsoftTankLevelLoading: false
    }
  },
  components: {
  },
  methods: {
    ...mapActions('notifier', ['setSuccessSnackbar', 'setErrorSnackbar']),
    triggerSilentSoftImport () {
      let that = this
      that.silentsoftTankLevelLoading = true
      this.$silentsoftImporter.triggerSilentSoftImport().then(response => {
        if (response.status === 204 || response.status === 200) {
          that.silentsoftTankLevelLoading = false
          that.triggerSuccessMessage()
        }
      }).catch(error => {
        that.silentsoftTankLevelLoading = false
        that.setErrorSnackbar(error)
      })
    },
    triggerSuccessMessage () {
      return this.setSuccessSnackbar(this.$t('dataImport.success'))
    }
  }
}
</script>

<style scoped>
</style>
