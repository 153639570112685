<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-12">
          <v-form ref="form" v-model="valid" class="write-feedback">
            <v-toolbar dark :color="appBarBackgroundColor" class="mbtm-30">
              <v-toolbar-title>
                <v-img height="70px" width="70px" class="mt-10" src="/Logo-Prestige.png"></v-img>
              </v-toolbar-title>
              <v-toolbar-title class="title-lng pl-4 font-weight-bold d-flex justify-space-between align-center">
                <span>{{ $t('feedback.title') }}</span>
                <v-btn-toggle
                  v-model="activeLng"
                  mandatory
                  class="lng-group"
                >
                  <v-btn 
                    v-for="(item, index) in languageList"
                    @click="updateLanguage(item.value)"
                    :value="item.value"
                    class="lng-btn"
                    :key="'language-' + index" 
                    :color="appBarBackgroundColor"
                  >
                    <img :src="require('../../assets/icons/' + item.value + '.svg')" />
                  </v-btn>
                </v-btn-toggle>
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text v-if="verificationLoading">
              <v-progress-linear :indeterminate="true"></v-progress-linear>
            </v-card-text>
            <template v-else-if="feedbackSubmitted">
              <v-card-text>
                <v-alert class="mb-5" :value="true" type="success">
                  {{ $t('feedback.alerts.success') }}
                </v-alert>
                  <p v-if="feedback.scorePrestige <= 2" class="body-2">
                    {{ $t('feedback.alerts.dissatisfied') }}
                  </p>
                  <template v-else-if="feedback.scorePrestige >=4">
                    <p>
                      {{ $t('feedback.alerts.enjoyed') }}
                    </p>
                  </template>
                  <p v-else>
                    {{ $t('feedback.alerts.dissatisfied') }}
                  </p>
              </v-card-text>
              <v-card-actions class="pl-4 pr-4">
                <a href="https://www.prestige.ch/impressum/" target="_blank">{{ $t('feedback.imprint') }}</a>
              </v-card-actions>
            </template>
            <template v-else-if="verified">
              <v-card-text>
                <v-alert v-if="errorMessage" :value="errorMessage" type="warning">
                  {{ errorMessage }}
                </v-alert>
                <template v-if="feedbackInfo.success">
                  <p v-html="feedbackInfo.success.feedbackIntroduction"></p>
                  <p>
                    <label class="subheading" v-bind:class="{ 'required': feedback.taskDone === undefined && submitClicked }">
                      {{ $t('feedback.questions.jobDone') }}
                    </label>
                    <v-radio-group v-model="feedback.taskDone" :rules="[v => feedback.taskDone !== undefined || $t('feedback.fillRating') ]" row>
                      <v-radio :label="$t('common.options.yes')" :value="true"></v-radio>
                      <v-radio :label="$t('common.options.no')" :value="false"></v-radio>
                    </v-radio-group>
                  </p>
                  <p>
                    <label class="subheading" v-bind:class="{ 'required': !validScoreExecution }">
                      {{ $t('feedback.questions.satisfied') }}
                    </label>
                    <v-rating v-model="feedback.scoreExecution"></v-rating>
                    <span class="caption red--text" v-if="!validScoreExecution">{{ $t('feedback.fillRating') }}</span>
                  </p>
                  <p>
                    <label class="subheading" v-bind:class="{ 'required': !validScoreCraftsman }">
                      {{ $t('feedback.questions.craftsman') }}
                    </label>
                    <v-rating v-model="feedback.scoreCraftsman"></v-rating>
                    <span class="caption red--text" v-if="!validScoreCraftsman">{{ $t('feedback.fillRating') }}</span>
                  </p>
                  <p>
                    <label class="subheading" v-bind:class="{ 'required': !validScorePrestige }">
                      {{ $t('feedback.questions.serviceProvider') }}
                    </label>
                    <v-rating v-model="feedback.scorePrestige"></v-rating>
                    <span class="caption red--text" v-if="!validScorePrestige">{{ $t('feedback.fillRating') }}</span>
                  </p>
                  <p>
                    <v-textarea
                      :label="$t('feedback.comment')"
                      v-model="feedback.comment"
                    ></v-textarea>
                  </p>
                </template>
              </v-card-text>
              <v-card-actions class="pl-4 pr-4">
                <a href="https://www.prestige.ch/impressum/" target="_blank">{{ $t('feedback.imprint') }}</a>
                <v-spacer></v-spacer>
                <v-btn color="#6e2c6b" class="white--text" :loading="submitLoading" @click.stop="submit">
                  {{ $t('common.buttons.send') }}
                </v-btn>
              </v-card-actions>
            </template>
            <template v-else>
              <v-card-text>
                <v-alert :value="true" type="warning">
                  <span v-if="feedbackInfo.invalid && feedbackInfo.invalid.reason">{{ $t('feedback.alerts.' + feedback.invalid.reason) }}</span>
                  <span v-else>{{ $t('feedback.alerts.invalidLink') }}</span>
                </v-alert>
              </v-card-text>
              <v-card-text>
                <p>
                  {{ $t('feedback.info.giveFeedback') }} <a href="https://www.prestige.ch/kontakt/">{{ $t('feedback.info.contactChannels') }}</a> {{ $t('feedback.info.notify') }}.
                </p>
              </v-card-text>
              <v-card-actions class="pl-4 pr-4">
                <a href="https://www.prestige.ch/impressum/" target="_blank">{{ $t('feedback.imprint') }}</a>
              </v-card-actions>
            </template>
          </v-form>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { appBarBackgroundColor } from '@/config/options/appBar'
import moment from 'moment'
import {languageList} from '@/config/options/languages'

export default {
  name: 'FeedbackWrite',
  data () {
    return {
      errorMessage: '',
      verified: false,
      verificationLoading: false,
      submitLoading: false,
      feedbackSubmitted: false,
      valid: false,
      validScore: true,
      validScoreExecution: true,
      validScoreCraftsman: true,
      validScorePrestige: true,
      feedbackInfo: {},
      feedback: {},
      submitClicked: false,
      appBarBackgroundColor: appBarBackgroundColor,
      languageList: languageList,
      activeLng: 'en'
    }
  },
  watch: {
    // call again the method if the route changes
    '$route': 'verifyToken',
    feedback: {
      handler () {
        if (this.submitClicked) {
          this.validate()
        }
      },
      deep: true
    }
  },
  mounted () {
    this.verifyToken()
  },
  methods: {
    verifyToken () {
      this.verified = false
      this.verificationLoading = true
      this.errorMessage = ''
      this.feedbackSubmitted = false
      let that = this
      this.$prestigeFeedback.getFeedbackIntroduction(this.$route.params.id, this.$route.params.token).then(function (response) {
        that.verified = response.status === 200
        if (that.verified) {
          that.feedbackInfo.success = response.data
        }
        that.verificationLoading = false
      }).catch(error => {
        let errorResponse = error.response
        if (errorResponse) {
          that.setErrorMessage(errorResponse)
        }
        that.verificationLoading = false
      })
    },
    validate () {
      let valid = true
      if (this.feedback) {
        this.validScorePrestige = this.isScoreValid(this.feedback.scorePrestige)
        this.validScoreExecution = this.isScoreValid(this.feedback.scoreExecution)
        this.validScoreCraftsman = this.isScoreValid(this.feedback.scoreCraftsman)
        if (!this.validScorePrestige || !this.validScoreExecution || !this.validScoreCraftsman) {
          valid = false
        }
      }
      if (!this.$refs.form.validate()) {
        valid = false
      }
      return valid
    },
    isScoreValid (score) {
      return score >= 1 && score <= 5
    },
    submit () {
      this.submitClicked = true
      if (this.validate()) {
        this.submitLoading = true
        this.errorMessage = ''
        let that = this
        this.feedback.token = this.$route.params.token
        this.feedback.comment = this.feedback.comment ? this.feedback.comment.replace(/↵/, '\n') : null
        this.$prestigeFeedback.provideFeedbackByCustomer(this.$route.params.id, this.feedback).then(response => {
          if (response.status === 200) {
            that.feedbackSubmitted = true
            that.submitLoading = false
          } else {
            that.errorMessage = this.$t('feedback.alerts.submitError')
          }
        }).catch(error => {
          let errorResponse = error.response
          if (errorResponse) {
            that.setErrorMessage(errorResponse)
          }
          that.submitLoading = false
        })
      }
    },
    setErrorMessage (errorResponse) {
      this.feedbackInfo.invalid = errorResponse.status === 400 ? errorResponse.data.reason : null
      this.errorMessage = errorResponse.data.reason ? this.$t('feedback.alerts.' + errorResponse.data.reason) : ''
    },
    updateLanguage (value) {
      this.$i18n.locale = value
      moment.locale(value)
      localStorage.setItem('language', value)
    },
    activeLanguage () {
      this.activeLng = localStorage.getItem('language')
    }
  }
}
</script>

<style scoped lang="postcss">
.required::after {
  content: '*';
  color: #ff5252;
  font-size: 16px !important;
}
.mbtm-30  {
  margin-bottom: 30px;
}
.write-feedback .bl {
  border-left: 1px solid rgba(0,0,0,.54);
}
.write-feedback .subheading {
  font-size: 14px !important;
}
.write-feedback .v-label {
  font-size: 14px !important;;
  color: rgba(0,0,0,.87) !important;;
}
.write-feedback .v-label--active {
  transform: translateY(-22px) scale(1) !important;
}
.lng-group {
  width: 100%;
  max-width: 110px;
  height: 40px;
  border-radius: 28px;
}
/deep/ .lng-group .v-btn.v-btn.v-size--default {
  width: 50%;
  height: 100%!important;
  border: 0;
  border-left-radius: 28px;
}
.lng-group .lng-btn:first-child img {
  border-top-left-radius: 28px;
  border-bottom-left-radius: 28px;
}

.lng-group .lng-btn:last-child img {
  border-top-right-radius: 28px;
  border-bottom-right-radius: 28px;
}
.title-lng {
  width: 100%;
}
</style>
