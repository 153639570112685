<template>
  <div>
    <v-menu
      :attach="attachSelector || false"
      v-model="menu"
      :nudge-right="40"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
     >
       <template v-slot:activator="{ on, attrs }">
         <v-text-field
           ref="inputDatePicker"
           :dense="dense"
           :label="label"
           prepend-icon="event"
           :required="required"
           :rules="required ? [rules.required, rules.date] : [rules.date]"
           :value="dateDisplay"
           :disabled="disabled"
           :readonly="readonly"
           class="datepicker-input"
           :placeholder="dateWithTime ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY'"
           v-mask="dateWithTime ? '##.##.#### ##:##' : '##.##.####'"
           :maxLength="dateWithTime ? 16 : 10"
           v-bind="attrs"
           v-on="on"
           :clearable="clearable"
           @click:clear="clearDate"
           @input="debounceInput"
         >
          <template #message="{ message }">
            <span v-if="message === 'required'">{{ requiredMessage }}</span>
            <span v-else-if="message === 'invalidDate'">{{ invalidDateMessage }}</span>
          </template>
         </v-text-field>
       </template>
       <v-date-picker
         ref="datePicker"
         v-model="date"
         no-title
         v-click-outside="cancelSetDate"
         :locale="$i18n.locale"
         :first-day-of-week="1"
         :min="minDate"
         :max="maxDate"
         :default-value="from || defaultValue"
         @input="updateFormValues"
       >
        <div class="time ml-4" v-if="dateWithTime">
          <vue-timepicker v-model="time" manual-input hide-dropdown aria-required="true">
            <template v-slot:dropdownButton>&#x02263;</template>
          </vue-timepicker>
          <v-btn medium class="ml-10 mr-2" @click="cancelSetDate">
            <v-icon small>close</v-icon>
          </v-btn>
          <v-btn medium color="primary" @click="saveDateWithTime" :disabled="disableButton">
            <v-icon small>check</v-icon>
          </v-btn>
        </div>
       </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import moment from 'moment'
import _debounce from 'lodash.debounce'
import VueTimepicker from 'vue2-timepicker'
import { validateDate } from '@/helpers/validationHelper'
import { formatDate } from '@/helpers/formatterHelper'

export default {
  name: 'DatePicker',
  props: {
    attachSelector: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    from: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    defaultValue: {
      type: Boolean,
      default: true
    },
    defaultValueDate: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => {}
    },
    dateWithTime: {
      type: Boolean,
      default: false
    },
    defaultTime: {
      type: String,
      default: ''
    },
    clearable: {
      type: Boolean,
      default: false
    },
    disablePastDates: {
      type: Boolean,
      default: false
    },
    disableFutureDates: {
      type: Boolean,
      default: false
    },
    menuOpen: {
      type: Boolean,
      default: false
    }
  },
  components: {
    VueTimepicker
  },
  data () {
    return {
      menu: false,
      defaultDate: this.from ? (this.defaultValue ? moment(moment()).subtract(1, 'months').startOf('month') : '') : (this.defaultValue ? (this.defaultValueDate ? moment(this.defaultValueDate) : moment(moment()).subtract(1, 'months').endOf('month')) : null),
      date: null,
      rules: {
        required: (value) => !!value || 'required',
        date: (value) => {
          if (!this.dateWithTime) {
            let valid = validateDate(value)
            return valid
          } else {
            return true
          }
        }
      },
      time: '',
      showCalendar: false,
      disableButton: false
    }
  },
  created () {
    this.updateFormValues()
    if (this.dateWithTime) {
      this.setDefaultDateWithTime()
    }
  },
  watch: {
    'dateDisplay': 'enableButton',
    'defaultValueDate': 'setDefaultDateWithTime'
  },
  computed: {
    dateDisplay () {
      if (this.dateWithTime) {
        if (this.date) {
          return this.formatDateFunc(this.date) + ' ' + this.time
        }
      } else {
        return this.date ? this.formatDateFunc(this.date) : ''
      }
      return ''
    },
    minDate () {
      return this.disablePastDates ? moment().toISOString().substr(0, 10) : ''
    },
    maxDate () {
      return this.disableFutureDates ? moment().toISOString().substr(0, 10) : ''
    },
    requiredMessage () {
      return this.$t('common.errors.required')
    },
    invalidDateMessage () {
      return this.$t('common.errors.invalidDate')
    }
  },
  methods: {
    formatDateFunc: formatDate,
    enableButton () {
      if (this.dateWithTime) {
        let dateAndTime = this.date + 'T' + this.time + ':00'
        if (this.defaultValueDate !== dateAndTime) {
          this.disableButton = false
        }
      }
    },
    updateFormValues () {
      if (!this.dateWithTime) {
        this.setDate()
        this.$emit('input', this.date)
        this.menu = false
      } else {
        if (!this.defaultValueDate) {
          if (!this.date) {
            this.time = this.defaultTime
          }
          this.date = this.date || moment().format('YYYY-MM-DD')
          this.$emit('input', this.date)
        }
      }
    },
    setDate () {
      this.date = this.date || (this.defaultDate ? this.defaultDate.format('YYYY-MM-DD') : (this.defaultValueDate || null))
    },
    saveDateWithTime () {
      let dateWithTime = this.date + 'T' + this.time + ':00'
      this.$emit('updateFormValues', dateWithTime)
    },
    setDefaultDateWithTime () {
      if (this.defaultValueDate) {
        let splittedDateTime = this.defaultValueDate.split('T')
        if (splittedDateTime) {
          this.date = splittedDateTime[0]
          this.time = splittedDateTime[1] || '00:00'
          this.disableButton = true
        }
      } else {
        this.date = moment().format('YYYY-MM-DD')
        this.time = this.defaultTime
      }
      this.menu = this.menuOpen
    },
    clearDate () {
      this.date = null
      this.$emit('input', this.date)
    },
    cancelSetDate () {
      this.$emit('cancelSetDate')
    },
    debounceInput: _debounce(function (e) {
      if (e && e.length === 10 && !this.dateWithTime && (this.$refs.inputDatePicker && this.$refs.inputDatePicker.valid) && this.date) {
        this.date = moment(e, 'DD.MM.YYYY').format('YYYY-MM-DD')
        this.$emit('input', this.date)
      } else if (e && e.length === 0) {
        this.$emit('input', '')
      }
    }, 500)
  }
}
</script>

<style scoped lang="postcss">
.time {
  display: contents;
}
/deep/ .time-picker .controls {
  display: none;
}
/deep/ .time-picker input {
  border: 2px solid #ccdbeb;
  border-radius: 5px;
  font-size: 16px;
  height: 40px;
  width: 70px;
  padding-right: 5px;
  text-align: center;
}
/deep/ .v-picker__actions {
  padding-left: 20px;
  padding-right: 20px;
}
/deep/ input:read-only {
  pointer-events: not-allowed;
  color: rgba(0,0,0,.87);
}
/* /deep/ .datepicker-input {
  position: relative;
}
/deep/ .v-menu__content {
  top: 50px!important;
} */
</style>
