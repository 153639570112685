<template>
  <span v-if="readOnly && selectedPriority">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon :color="selectedPriority.color" v-bind="attrs" v-on="on" @click.stop="setReadOnly">{{ selectedPriority.icon }}</v-icon>
      </template>
      <span>{{ selectedPriority.label }}</span>
    </v-tooltip>
  </span>
  <v-select
    v-else-if="!readOnly"
    :items="priorities"
    item-value="value"
    item-text="label"
    :label="$t('ticketingSystem.priority.title')"
    v-model="priority"
  >
    <template v-slot:item="{item}">
      <v-icon :color="item.color">{{ item.icon }}</v-icon>
      <span>{{ item.label }}</span>
    </template>
    <template v-slot:selection="{item}">
      <v-icon :color="item.color">{{ item.icon }}</v-icon>
      <span>{{ item.label }}</span>
    </template>
  </v-select>
</template>

<script>

export default {
  name: 'TicketPriority',
  data () {
    return {
      priorities: [
        {
          value: 1,
          icon: 'mdi-arrow-up-bold',
          color: 'red',
          label: this.$t('ticketingSystem.priority.priority1')
        },
        {
          value: 2,
          icon: 'mdi-arrow-right',
          color: '',
          label: this.$t('ticketingSystem.priority.priority2')
        }
      ]
    }
  },
  props: {
    value: {
      type: Number,
      default: 2
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    selectedPriority () {
      const result = this.priorities.find(p => p.value === this.priority)
      if (result) {
        return result
      } else {
        return {
          icon: 'mdi-help-circle-outline',
          color: 'gray',
          label: this.$t('ticketingSystem.priority.undefined')
        }
      }
    },
    priority: {
      get: function () {
        return this.value
      },
      set: function (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    setReadOnly () {
      this.$emit('readOnly', !this.readOnly)
    }
  }
}
</script>

<style scoped lang="postcss">
</style>
