import i18n from '@/i18n/i18n.js'

export function getCandidateGroupName (candidateGroupId) {
  if (candidateGroupId === '') {
    return i18n.t('common.candidateGroups.general')
  } else {
    let groupShortName = candidateGroupId.split('-').pop()
    if (groupShortName) {
      const key = 'common.candidateGroups.' + groupShortName
      const label = i18n.t('common.candidateGroups.' + groupShortName)
      if (label === key) {
        return groupShortName
      } else {
        return label
      }
    }
  }
  return ''
}
