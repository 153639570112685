<template>
  <v-container fluid class="grey lighten-5 pt-2 pl-5 pr-5 pb-15">
    <v-form ref="form" v-if="isLoaded" class="mb-3">
      <v-layout row wrap>
        <AbacusTaskInfo
          :property="mappedProperty"
          :property-object="mappedObject"
          :edit-disabled="ordersDisabled"
          @updateFormValues="updateForm"
          @propertyObjectChanged="onPropertyObjectChanged"
          @houseChanged="onHouseChange"
        />

        <v-flex v-if="ordersComment || ordersDisabled" class="mt-4" xs12>
          <v-alert v-if="ordersComment" type="info" transition="scale-transition" class="mb-1">
            {{ ordersComment }}
          </v-alert>
          <v-alert v-if="ordersDisabled" type="warning" transition="scale-transition" class="mb-1">
            {{ $t('tasks.warning.ordersDisabled') }}
          </v-alert>
        </v-flex>

        <template v-if="!ordersDisabled">
          <TaskOrder
            :subject-line1="form.subjectLine1"
            :subject-line2="form.subjectLine2"
            :job-description="form.jobDescription"
            :insurance-case="form.insuranceCase"
            :task-type="form.taskType"
            @setSubjectLine2="setSubjectLine2"
            @updateFormValues="updateForm"
          />
          <RecentTasks :property-id="form.propertyId" />
          <AbacusTenant
            :tenant="form.tenant"
            :tenant-objects="mappedObject"
            :new-tenant-contract-start="form.newTenantContractStart"
            :property-id="form.propertyId"
            @setSubjectLine2="setSubjectLine2"
            @updateFormValues="updateForm"
          />
          <AbacusCaretaker :caretaker="form.caretaker" @updateFormValues="updateForm"/>
          <AbacusCraftsmen :client-id="form.client.addressId" :property-id="form.propertyId" :craftsman="form.craftsman" :comment="form.craftsmenComment" @updateFormValues="updateForm" />
          <TicketComment :comment="form.comment" @updateFormValues="updateForm" />
          <FileAttachments :show-info-alert="true" module-name="task" />
        </template>
  
      </v-layout>
    </v-form>
    <v-footer v-if="!ordersDisabled" fixed height="auto">
      <v-btn color="success" @click="submitData" :disabled="!valid">
        <span>{{ $t('common.buttons.save') }}</span>
        <v-icon>send</v-icon>
      </v-btn>
    </v-footer>
  </v-container>
</template>

<script>
import AbacusTaskInfo from './components/AbacusTaskInfo'
import TaskOrder from './components/TaskOrder'
import RecentTasks from './components/RecentTasks'
import AbacusTenant from './components/AbacusTenant'
import AbacusCaretaker from './components/AbacusCaretaker'
import AbacusCraftsmen from './components/AbacusCraftsmen'
import TicketComment from './components/TicketComment'
import FileAttachments from '../shared/attachments/FileAttachments'
import * as taskHelper from './helpers/taskHelper'

import { ABACUS_PROPERTY } from './graphql-apollo/abacusProperty.gql'

import { mapActions, mapState } from 'vuex'

export default {
  name: 'CreateTask',
  data () {
    return {
      isLoaded: false,
      propertyId: this.$route.params.propertyId,
      objectId: this.$route.params.propertyObjectId,
      form: taskHelper.initForm(),
      valid: true,
      taskData: {},
      mappedProperty: {},
      mappedObject: {}
    }
  },
  components: {
    AbacusTaskInfo,
    TaskOrder,
    RecentTasks,
    AbacusTenant,
    AbacusCaretaker,
    AbacusCraftsmen,
    TicketComment,
    FileAttachments
  },
  apollo: {
    abacusProperty: {
      query: ABACUS_PROPERTY,
      update (data) {
        if (data) {
          return data.abacusProperty
        }
      },
      variables () {
        return {
          propertyId: parseInt(this.propertyId)
        }
      },
      skip () {
        return isNaN(this.propertyId)
      },
      fetchPolicy: 'cache-first'
    }
  },
  created () {
    this.$store.dispatch('abacus/loadRealEstateManagers')
    this.loadProperty()
  },
  computed: {
    ...mapState('abacus', ['realEstateManagers', 'realEstateManagerLoading']),
    ordersDisabled () {
      return this.abacusProperty?.ordersDisabled
    },
    ordersComment () {
      return (this.abacusProperty?.ordersComment?.charAt(0).toUpperCase() + this.abacusProperty?.ordersComment?.slice(1)) || ''
    }
  },
  methods: {
    ...mapActions('notifier', ['setSuccessSnackbar', 'setErrorSnackbar']),
    loadProperty () {
      let that = this
      this.$abacusProperty.getPropertyById(this.propertyId).then(function (response) {
        that.taskData = response.data
        that.mapInfo(that.taskData)
        return true
      }).catch(function (error) {
        that.$store.dispatch('notifier/setAlert', {
          text: error
        })
        that.taskData = []
      })
    },
    mapInfo (response) {
      this.mappedProperty = this.mapProperty(response)
      this.mappedObject = this.setTenantObjects(response)
      this.mappedProperty.tenant = taskHelper.getTenant(this.mappedObject)
      let params = Object.assign(this.mappedProperty, this.mappedObject)
      taskHelper.mapAddressId(params)
      this.updateForm(params)
      this.isLoaded = true
    },
    mapProperty (response) {
      return {
        propertyId: parseInt(response.propertyId),
        propertyStreet: response.street,
        propertyZip: response.zip,
        propertyPlace: response.place,
        client: response.client,
        caretaker: response.caretaker,
        realEstateManager: response.realEstateManager,
        administrativeAreaId: response.administrativeAreaId,
        administrativeAreaText: response.administrativeAreaText,
        competenceSum: response.competenceSum
      }
    },
    setTenantObjects (response) {
      if (this.objectId !== undefined && this.objectId !== null) {
        let propertyObject = response.objects.find(x => x.objectId.toString() === this.objectId.toString())
        propertyObject.tenant = taskHelper.getTenant(propertyObject)
        this.mappedObject = taskHelper.createHelperObject(propertyObject)
      }
      return this.mappedObject
    },
    setSubjectLine2 (propertyObject) {
      this.form.subjectLine2 = taskHelper.setSubjectLine2(propertyObject)
    },
    updateForm (params) {
      Object.assign(this.form, params)
    },
    submitData () {
      if (this.$refs.form.validate()) {
        this.removeProp(this.form, 'displayText')
        this.saveTask()
      }
    },
    saveTask () {
      let that = this
      let processInstanceId = this.$route.query.processInstanceId
      this.form.taskSource = 'TASK'
      this.form.abacusOrderComment = this.ordersComment
      if (processInstanceId) {
        this.form.processInstanceId = processInstanceId
      }
      this.$prestigeTask.saveTask(this.form).then(function (response) {
        // store link in process instance
        if (processInstanceId) {
          let data = { newLinks: [ {linkName: 'order', linkValues: [response.data]} ] }
          that.$prestigeTSProcess.saveProcessVariables(processInstanceId, data)
        }

        // change route to detail view
        that.$router.push({
          name: 'TaskDetailsFullscreen',
          params: { taskId: response.data }
        }).then(
          that.setSuccessSnackbar(that.$t('tasks.createTask.successfullyCreated'))
        )
        return response.data
      }).catch(function (error) {
        that.setErrorSnackbar(error)
      })
    },
    removeProp (obj, propToDelete) {
      let newObject = taskHelper.removeProp(obj, propToDelete)
      Object.assign(this.form, newObject)
    },
    onPropertyObjectChanged (object) {
      // keep original address ID
      if (object.currentTenant) {
        object.currentTenant.addressId = object.currentTenant.id
        object.currentTenant.id = null
      }
      if (object.newTenant) {
        object.newTenant.addressId = object.newTenant.id
        object.newTenant.id = null
      }

      Object.assign(this.form, object)

      this.form.tenant = taskHelper.getTenant(this.form)
      this.$set(this.mappedObject, 'objectId', this.form.objectId)
      this.$set(this.mappedObject, 'objectText', this.form.objectText)
      this.$set(this.mappedObject, 'houseId', this.form.houseId)
      this.$set(this.mappedObject, 'houseStreet', this.form.houseStreet)
      this.$set(this.mappedObject, 'currentTenant', this.form.currentTenant)
      this.$set(this.mappedObject, 'newTenant', this.form.newTenant)

      this.form.subjectLine1 = this.form.objectText + ' / ' + this.form.floorText
      this.form.subjectLine2 = taskHelper.setSubjectLine2(this.form)

      this.updateForm(this.form)
    },
    onHouseChange (house) {
      this.$set(this.form, 'houseId', house.houseId)
      this.$set(this.form, 'houseStreet', house.houseStreet)
      this.$set(this.form, 'objectId', null)
      this.$set(this.form, 'objectText', null)
      this.$set(this.form, 'currentTenant', null)
      this.$set(this.form, 'newTenant', null)
      this.$set(this.mappedObject, 'objectId', null)
      this.$set(this.mappedObject, 'objectText', null)
      this.$set(this.mappedObject, 'houseId', this.form.houseId)
      this.$set(this.mappedObject, 'houseStreet', this.form.houseStreet)
      this.$set(this.mappedObject, 'currentTenant', null)
      this.$set(this.mappedObject, 'newTenant', null)

      this.form.subjectLine1 = ''
      this.form.subjectLine2 = ''

      this.updateForm(this.form)
    }
  }
}
</script>
