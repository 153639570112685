import gql from 'graphql-tag'

const SEARCH_ADDRESSES = gql`

  query searchAddresses ($query: SearchAddressesQuery!) {
    searchAddresses(
      query: $query
    ) {
      items {
        addressId
        name
        line1
        line2
        line3
        zip
        place
        email
        phone1
        phone2
        mobile
        isTenant
        isClient
        isCraftsman
        isCaretaker
        processInstanceId
      }
      totalItems
    }
  }
`

export {
  SEARCH_ADDRESSES
}