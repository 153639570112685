<template>
  <v-card class="mb-2 pa-0" v-if="propertyObjectLinks.length > 0">
    <v-card-text class="pa-4">
      <v-icon color="primary" @click="toggle" class="float-right">{{ icon }}</v-icon>
      <v-card-subtitle class="pa-0 font-weight-bold subtitle-1">
        {{ $t('common.objects') }}
      </v-card-subtitle>
    </v-card-text>
    <v-divider></v-divider>
    <v-list v-if="isOpen" class="context overflow-auto">
      <template v-for="(property, index) in propertyObjectLinks">
        <ProcessPropertyObject :key="index" :propertyObjectId="getObjectId(property)" :propertyId="getPropertyId(property)" :deleteAction="deleteAction" @openNewTicketDialog="onNewObjectTicket" @openOfflineRentalApplication="onOpenOfflineRentalApplication" :processInstanceId="processInstanceId"/>
        <v-divider :key="'divider_' + index"></v-divider>
      </template>
    </v-list>
  </v-card>
</template>
<script>
import ProcessPropertyObject from './ProcessPropertyObject'
import { getNewTicketShortSubject } from '@/helpers/globalHelper'
import { TICKETING_CONSTANTS } from '@/config/options/ticketingConstants'
import { mapActions } from 'vuex'
import { useObjectActions } from '@/use/useObjectActions'

const { onNewObjectTicket, onOfflineRentalApplication } = useObjectActions()

export default {
  name: 'ProcessPropertyObjects',
  components: {
    ProcessPropertyObject
  },
  props: {
    processInstanceId: {
      type: String,
      default: ''
    },
    propertyObjectLinks: {
      type: Array,
      default: () => []
    },
    deleteAction: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      isOpen: true
    }
  },
  created () {
    this.loadProperties()
  },
  watch: {
    propertyObjectLinks () {
      this.loadProperties()
    }
  },
  computed: {
    icon () {
      return this.isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
    }
  },
  methods: {
    ...mapActions('abacus', ['loadPropertyDetails']),
    getPropertyId (link) {
      return link.split(':')[0]
    },
    getObjectId (link) {
      return link.split(':')[1]
    },
    loadProperties () {
      if (this.propertyObjectLinks.length > 0) {
        this.propertyObjectLinks.forEach(propertyObjectId => {
          this.loadPropertyDetails(this.getPropertyId(propertyObjectId))
        })
      }
    },
    toggle () {
      this.isOpen = !this.isOpen
    },
    onNewObjectTicket (object) {
      let links = onNewObjectTicket(object.property.propertyId, object.objectId)
      let subject = object?.houseStreet
      this.$emit('openNewTicketDialog', links, subject)
    },
    onOpenOfflineRentalApplication (object) {
      let data = onOfflineRentalApplication(object)
      let subject = getNewTicketShortSubject(object)
      this.$emit('openNewTicketDialog', data.links, subject, TICKETING_CONSTANTS.ANMELDUNG_OFFLINE, data.variables)
    }
  }
}
</script>

<style scoped lang="postcss">
.property-link a {
  text-decoration: none;
}
</style>
