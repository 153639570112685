<template>
  <v-card class="mb-2 pa-0">
    <v-card-subtitle class="pa-2 font-weight-bold subtitle-1">
      {{ $t('ticketingSystem.noteField.title') }}
    </v-card-subtitle>
    <v-card-text class="pa-2">
      <AutocompleteLinks
        :key="commentTicketFieldHash"
        ref="autocomplete"
        class="height-150"
        name="comment"
        auto-focus
        :placeholder="$t('ticketingSystem.messages.addComment')"
        :required="true"
        @input="checkValidity"
        :default-html="commentTicketField"
      />
    </v-card-text>
    <v-card-actions>
      <v-btn
        small
        @click="saveFileVariable"
        :disabled="!valid"
        :loading="processingRequest"
      >
        <v-icon color="primary" dark>done</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import AutocompleteLinks from '@/features/ticketing/components/AutocompleteLinks'
import * as ticketingHelper from '@/features/ticketing/helpers/ticketingHelper'
import { hashCode } from '@/helpers/algorithmHelper'
import { mapActions } from 'vuex'

export default {
  name: 'ProcessComment',
  props: {
    processInstanceId: {
      type: String
    },
    processVariables: {
      type: Array,
      default: () => []
    }
  },
  components: {
    AutocompleteLinks
  },
  data () {
    return {
      valid: false,
      processingRequest: false,
      commentTicketField: ''
    }
  },
  watch: {
    processVariables: {
      handler () {
        this.commentTicketField = this.getVariableValue('System_Note_String', '')
      },
      immediate: true
    }
  },
  computed: {
    commentTicketFieldHash () {
      return hashCode(this.commentTicketField)
    }
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar', 'setSuccessSnackbar']),
    getVariableValue (variableName, defaultValue = null) {
      return ticketingHelper.getVariableValue(this.processVariables, variableName, defaultValue)
    },
    saveFileVariable () {
      let commentBody = {
        newLinks: this.$refs.autocomplete.getLinks(),
        variables: [
          {
            variableName: 'System_Note_String',
            variableType: 'string',
            variableValue: this.$refs.autocomplete.getHtml()
          }
        ]
      }
      let that = this
      this.processingRequest = true
      this.$prestigeTSProcess.saveProcessVariables(this.processInstanceId, commentBody).then(() => {
        that.processingRequest = false
        that.onSuccessReloadData()
        return true
      }).catch(error => {
        that.processingRequest = false
        that.setErrorSnackbar(error)
      })
    },
    onSuccessReloadData () {
      this.setSuccessSnackbar(this.$t('ticketingSystem.messages.successfullyAddedComment'))
      this.$emit('loadProcessReferences')
      this.$emit('loadDetails')
    },
    checkValidity () {
      this.valid = !this.$refs.autocomplete.ariaInvalid
    }
  }
}
</script>
<style scoped lang="postcss">
/deep/ .height-150 .editor {
  height: 150px;
}
</style>
