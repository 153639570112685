<template>
  <v-dialog
    :value="true"
    max-width="1024px"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card height="100%" class="pa-4">
      <v-card-title class="pa-2">
        <span class="headline">{{ commentDialogTitle }}</span>
        <v-btn icon absolute right>
          <v-icon class="clear" color="primary" :size="28" @click.native="closeDialog"/>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-2">
        <v-form ref="form">
          <v-layout>
            <v-flex>
              <p><em>{{ $t('ticketingSystem.messages.comment.hint') }}</em></p>
              <AutocompleteLinks
                ref="autocomplete"
                class="height-150"
                name="comment"
                auto-focus
                :placeholder="$t('ticketingSystem.messages.addComment')"
                :required="true"
                :additional-links="additionalLinks"
                :default-html="defaultHtml"
                @input="checkValidity"
              />
            </v-flex>
          </v-layout>
        </v-form>
      </v-card-text>
      <v-card-actions class="mt-4">
        <v-spacer></v-spacer>
        <v-btn @click.native="closeDialog">{{ $t('common.buttons.cancel') }}</v-btn>
        <v-btn color="success" @click="saveComment" :disabled="!valid" :loading="processingRequest">
          <span>{{ $t('ticketingSystem.messages.comment.create') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AutocompleteLinks from '@/features/ticketing/components/AutocompleteLinks'

import { mapActions } from 'vuex'
import { AuthenticationContext } from 'vue-adal'
import { formatDateAndTime } from '@/helpers/formatterHelper'

export default {
  name: 'CommentDialog',
  props: {
    processInstanceId: {
      type: String
    },
    taskId: {
      type: String
    },
    additionalLinks: {
      type: Array,
      default: () => []
    },
    isUpdateComment: {
      type: Boolean,
      default: false
    },
    comment: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    AutocompleteLinks
  },
  created () {
    this.$nextTick(() => {
      if (this.$refs.autocomplete) {
        if (this.$refs.autocomplete.$refs.editor) {
          setTimeout(() => {
            this.$refs.autocomplete.$refs.editor.focus()
          }, 10)
        }
      }
    })
  },
  data () {
    return {
      valid: false,
      processingRequest: false
    }
  },
  computed: {
    userName () {
      return AuthenticationContext && AuthenticationContext.user && AuthenticationContext.user.profile ? AuthenticationContext.user.profile.name : ''
    },
    commentDialogTitle () {
      return this.isUpdateComment ? this.$t('ticketingSystem.messages.comment.updateDialogTitle') : this.$t('ticketingSystem.messages.comment.dialogTitle')
    },
    defaultHtml () {
      return this.comment?.message || ''
    }
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar', 'setSuccessSnackbar']),
    formatDateAndTimeFunc: formatDateAndTime,
    saveComment () {
      this.processingRequest = true
      if (this.valid) {
        let commentBody = {
          message: this.$refs.autocomplete.getHtml(),
          newLinks: this.$refs.autocomplete.getLinks()
        }
        if (this.taskId && this.taskId !== '') {
          // add comment based on task id if possible
          if (this.isUpdateComment) {
            this.updateTaskComment(commentBody)
          } else {
            this.addTaskRelatedComment(commentBody)
          }
        } else if (this.processInstanceId && this.processInstanceId !== '') {
          // add comment based on process id
          if (this.isUpdateComment) {
            this.updateProcessComment(commentBody)
          } else {
            this.addProcessRelatedComment(commentBody)
          }
        } else {
          // this should not happen
          this.setErrorSnackbar('Unable to add comment')
        }
      }
    },
    addProcessRelatedComment (commentBody) {
      let that = this
      this.$prestigeTSProcess.addProcessComment(this.processInstanceId, commentBody).then(function () {
        that.onSuccessAddedComment()
        return true
      }).catch(error => {
        that.processingRequest = false
        that.setErrorSnackbar(error)
      })
    },
    addTaskRelatedComment (commentBody) {
      let that = this
      this.$prestigeTSTask.addComment(this.taskId, commentBody).then(function () {
        that.onSuccessAddedComment()
        return true
      }).catch(error => {
        that.processingRequest = false
        that.setErrorSnackbar(error)
      })
    },
    updateProcessComment (commentBody) {
      let that = this
      this.$prestigeTSProcess.updateProcessComment(this.processInstanceId, this.comment.id, commentBody).then(function () {
        that.processingRequest = false
        that.onSuccessUpdatedComment()
        return true
      }).catch(error => {
        that.processingRequest = false
        that.setErrorSnackbar(error)
      })
    },
    updateTaskComment (commentBody) {
      let that = this
      this.$prestigeTSTask.updateTaskComment(this.taskId, this.comment.id, commentBody).then(function () {
        that.onSuccessUpdatedComment()
        return true
      }).catch(error => {
        that.processingRequest = false
        that.setErrorSnackbar(error)
      })
    },
    onSuccessAddedComment () {
      this.processingRequest = false
      this.setSuccessSnackbar(this.$t('ticketingSystem.messages.successfullyAddedComment'))
      this.closeDialog(true)
    },
    onSuccessUpdatedComment () {
      this.processingRequest = false
      this.setSuccessSnackbar(this.$t('ticketingSystem.messages.successfullyUpdatedComment'))
      this.closeDialog(true)
    },
    closeDialog (reload = false) {
      this.$emit('closeDialog', reload)
    },
    updateForm (params) {
      if (params.files && params.files.length > 0) {
        let azueFileAttachmentIds = params.files.map(x => x.fileId)
        Object.assign(this.form, { azureFileAttachments: azueFileAttachmentIds })
      }
    },
    checkValidity () {
      this.valid = !this.$refs.autocomplete.ariaInvalid
    }
  }
}
</script>

<style scoped lang="postcss">
/deep/ .height-150 .editor {
  height: 150px;
}
</style>
