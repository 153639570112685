<template>
  <v-container fluid class="grey lighten-5 pt-2 pl-5 pr-5 pb-2">
    <v-alert v-if="errorMessage" type="warning">
      {{ errorMessage }}
    </v-alert>
    <v-form ref="form" v-if="isLoaded" class="mb-3">
      <v-layout row wrap>
        <AbacusTaskInfo
          :property="mappedProperty"
          :task="taskData"
          :property-object="mappedObject"
          :details-view="true"
          :edit-disabled="ordersDisabled"
          @updateFormValues="updateForm"
          @propertyObjectChanged="onPropertyObjectChanged"
          @houseChanged="onHouseChange"
        />

        <v-flex v-if="orderCommentText" class="mt-3" xs12 sm12 md12 lg12 xl12>
          <v-alert type="info" transition="scale-transition" class="mb-0">
            {{ orderCommentText }}
          </v-alert>
        </v-flex>

        <TaskOrder
          :subject-line1="form.subjectLine1"
          :subject-line2="form.subjectLine2"
          :job-description="form.jobDescription"
          :insurance-case="form.insuranceCase"
          :task-type="form.taskType"
          :read-only="ordersDisabled"
          @updateFormValues="updateForm"
        />
        <RecentTasks :property-id="form.propertyId" />
        <AbacusTenant
          :tenant="form.tenant"
          :tenant-objects="mappedObject"
          :new-tenant-contract-start="form.newTenantContractStart"
          :property-id="form.propertyId"
          :read-only="ordersDisabled"
          @setSubjectLine2="setSubjectLine2"
          @updateFormValues="updateForm"
        />
        <AbacusCaretaker :caretaker="form.caretaker" :read-only="ordersDisabled" @updateFormValues="updateForm"/>
        <AbacusCraftsmen :client-id="form.client.addressId" :property-id="form.propertyId" :craftsman="form.craftsman" :comment="form.craftsmenComment" :read-only="ordersDisabled" @updateFormValues="updateForm" />
        <TicketComment :comment="form.comment" :read-only="ordersDisabled" @updateFormValues="updateForm" />
        <FileAttachments module-name="task" :files="form.files" :read-only="ordersDisabled" @addFile="onAddFile" @updateFile="onUpdateFile" @removeFile="onRemoveFile" />
        <SendTaskMail :mail-form="form" :mailHistory="mailHistory" :disabled="disabled" :read-only="ordersDisabled" @fetchMailHistory="fetchMailHistory" @updateFormValues="updateForm" />
        <MailHistory :mail-history="mailHistory" />
      </v-layout>

      <ConfirmationDialog :data="deleteData" :show-dialog="showDeleteDialog" @closeDialog="closeDeleteDialog" @submitDialog="deleteTask" />
      <ConfirmationDialog :data="cloneData" :show-dialog="showCloneDialog" @closeDialog="closeCloneDialog" @submitDialog="cloneTask" />
    </v-form>
    <v-footer inset app height="auto">
      <template v-if="!ordersDisabled">
        <v-btn :small="smAndDown" color="success" @click="updateTask" :disabled="!valid" class="mr-2 mt-1 mb-1">
          <span>{{ $t('common.buttons.save') }}</span>
          <v-icon :small="smAndDown">send</v-icon>
        </v-btn>
        <v-btn :small="smAndDown" @click="openCloneDialog" class="mr-2 mt-1 mb-1">
          <span>{{ $t('common.buttons.copy') }}</span>
          <v-icon :small="smAndDown">content_copy</v-icon>
        </v-btn>
        <v-btn :small="smAndDown" color="error" class="mr-2 mt-1 mb-1" @click="openDeleteDialog">
          <span>{{ $t('common.buttons.delete') }}</span>
          <v-icon :small="smAndDown">delete</v-icon>
        </v-btn>
      </template>
      <v-btn :small="smAndDown" @click="downloadTenantPdf" class="mr-2 mt-1 mb-1" :disabled="disabled">
        <span>{{ $t('tasks.tenant.title') }} </span>
        <v-icon :small="smAndDown">insert_drive_file</v-icon>
      </v-btn>
      <v-btn :small="smAndDown" @click="downloadCraftsmanPdf" class="mr-2 mt-1 mb-1" :disabled="disabled">
        <span>{{ $t('tasks.craftsmen.title')}}</span>
        <v-icon :small="smAndDown">insert_drive_file</v-icon>
      </v-btn>
      <v-btn :small="smAndDown" @click="downloadCaretakerPdf" class="mr-2 mt-1 mb-1" :disabled="disabled">
        <span>{{ $t('tasks.info.caretaker')}}</span>
        <v-icon :small="smAndDown">insert_drive_file</v-icon>
      </v-btn>
    </v-footer>
  </v-container>
</template>

<script>
import AbacusTaskInfo from './components/AbacusTaskInfo'
import TaskOrder from './components/TaskOrder'
import RecentTasks from './components/RecentTasks'
import AbacusTenant from './components/AbacusTenant'
import AbacusCaretaker from './components/AbacusCaretaker'
import AbacusCraftsmen from './components/AbacusCraftsmen'
import TicketComment from './components/TicketComment'
import FileAttachments from '../shared/attachments/FileAttachments'
import SendTaskMail from './components/mail/SendTaskMail'
import MailHistory from './components/mail/MailHistory'
import ConfirmationDialog from '@/components/ConfirmationDialog'

import * as taskHelper from './helpers/taskHelper'
import { ascSortFilesByParam } from '@/helpers/sortHelper'

import diff from 'deep-diff'
import _cloneDeep from 'lodash.clonedeep'
import Vue from 'vue'

import { mapActions, mapState } from 'vuex'

import { TASKS_HOME } from '@/config/options/routes/taskRoutes'
import { ABACUS_PROPERTY } from './graphql-apollo/abacusProperty.gql'

export default {
  name: 'TaskDetail',
  data () {
    return {
      isLoaded: false,
      showCloneDialog: false,
      showDeleteDialog: false,
      originalForm: {},
      form: taskHelper.initForm(),
      valid: true,
      taskData: {},
      mappedProperty: {},
      mappedPropertyObject: {},
      mailHistory: [],
      disabled: false,
      errorMessage: '',
      mappedObject: {}
    }
  },
  components: {
    AbacusTaskInfo,
    TaskOrder,
    RecentTasks,
    AbacusTenant,
    AbacusCaretaker,
    AbacusCraftsmen,
    TicketComment,
    FileAttachments,
    SendTaskMail,
    MailHistory,
    ConfirmationDialog
  },
  apollo: {
    abacusProperty: {
      query: ABACUS_PROPERTY,
      update (data) {
        if (data) {
          return data.abacusProperty
        }
      },
      variables () {
        return {
          propertyId: parseInt(this.mappedProperty.propertyId)
        }
      },
      skip () {
        return isNaN(this.mappedProperty.propertyId)
      },
      fetchPolicy: 'cache-first'
    }
  },
  created () {
    this.$store.dispatch('abacus/loadRealEstateManagers')
    this.loadTask()
    this.fetchMailHistory()
  },
  watch: {
    // call again the method if the route changes
    '$route': 'loadTask'
  },
  computed: {
    ...mapState('abacus', ['propertiesDetails']),
    smAndDown () {
      return this.$vuetify.breakpoint.smAndDown
    },
    cloneData () {
      return {
        title: this.$t('tasks.dialog.clone.title'),
        question: this.$t('tasks.dialog.clone.question'),
        confirmButton: this.$t('common.buttons.copy'),
        action: 'cloneTask'
      }
    },
    deleteData () {
      return {
        title: this.$t('tasks.dialog.delete.title'),
        question: this.$t('tasks.dialog.delete.question'),
        confirmButton: this.$t('common.buttons.delete'),
        action: 'deleteTask'
      }
    },
    orderCommentText () {
      return this.form.abacusOrdersComment || this.ordersComment
    },
    ordersComment () {
      return this.abacusProperty?.ordersComment
    },
    ordersDisabled () {
      return this.abacusProperty?.ordersDisabled
    }
  },
  methods: {
    ...mapActions('notifier', ['setSuccessSnackbar', 'setErrorSnackbar']),
    ...mapActions('abacus', ['loadPropertyDetails']),
    loadTask () {
      let that = this
      this.$prestigeTask.getTaskById(this.$route.params.taskId).then(response => {
        that.taskData = response.data
        Object.assign(that.form, that.taskData)
        if (that.taskData) {
          that.mapInfo(that.taskData)
        }
        if (that.form?.files?.length > 0) {
          that.form.files = ascSortFilesByParam(that.form.files, 'fileName')
        }
        that.originalForm = _cloneDeep(that.form)
        return true
      }).catch(function (error) {
        that.taskData = []
        that.errorMessage = that.$t('tasks.alert')
        that.setErrorSnackbar(error)
      })
    },
    fetchMailHistory () {
      let that = this
      this.$prestigeTaskEmail.getTaskEmailHistory(this.$route.params.taskId).then(response => {
        that.mailHistory = response.data
        return true
      }).catch(function (error) {
        that.mailHistory = []
        that.setErrorSnackbar(error)
      })
    },
    async mapInfo (response) {
      this.mappedProperty = this.mapProperty(response)
      this.mappedObject = taskHelper.createHelperObject(response, true)
      this.mappedProperty.tenant = response.tenant
      this.mapCompetenceSum()
      let params = Object.assign(this.mappedProperty, this.mappedObject)
      Object.assign(this.form, params)
      this.isLoaded = true
    },
    mapCompetenceSum () {
      if (this.propertiesDetails && this.propertiesDetails.length > 0) {
        let propertyData = this.propertiesDetails.find(x => x.propertyId.toString() === this.taskData.propertyId.toString())
        if (propertyData) {
          this.mappedProperty.competenceSum = propertyData.competenceSum
        } else {
          this.loadPropertyDetailsForCompetenceSum()
        }
      } else {
        this.loadPropertyDetailsForCompetenceSum()
      }
    },
    async loadPropertyDetailsForCompetenceSum () {
      let data = await this.loadPropertyDetails(this.mappedProperty.propertyId)
      if (data) {
        this.mappedProperty.competenceSum = data.competenceSum
      }
    },
    mapProperty (response) {
      return {
        propertyId: parseInt(response.propertyId),
        propertyStreet: response.propertyStreet,
        propertyZip: response.propertyZip,
        propertyPlace: response.propertyPlace,
        client: response.client,
        caretaker: response.caretaker,
        realEstateManager: response.realEstateManager,
        administrativeAreaId: response.administrativeAreaId,
        administrativeAreaText: response.administrativeAreaText,
        competenceSum: null
      }
    },
    setSubjectLine2 (propertyObject) {
      this.form.subjectLine2 = taskHelper.setSubjectLine2(propertyObject)
    },
    updateForm (params) {
      Object.assign(this.form, params)
      let differences = diff.diff(this.originalForm, this.form)
      this.disabled = typeof differences !== 'undefined'
    },
    onAddFile (file) {
      if (!this.form?.files) {
        this.updateForm({ files: [file] })
      } else {
        this.form.files.push(file)
      }
      this.disabled = true
     },
    onRemoveFile (index) {
      this.form.files.splice(index, 1)
      this.disabled = true
    },
    onPropertyObjectChanged (object) {
      // keep original address ID
      if (object.currentTenant && this.form.currentTenant) {
        object.currentTenant.addressId = object.currentTenant.id
        object.currentTenant.id = this.form.currentTenant.id
      }
      if (object.newTenant && this.form.newTenant) {
        object.newTenant.addressId = object.newTenant.id
        object.newTenant.id = this.form.newTenant.id
      }
      Object.assign(this.form, object)

      this.form.tenant = taskHelper.getTenant(this.form)

      this.$set(this.mappedObject, 'objectId', this.form.objectId)
      this.$set(this.mappedObject, 'objectText', this.form.objectText)
      this.$set(this.mappedObject, 'houseId', this.form.houseId)
      this.$set(this.mappedObject, 'houseStreet', this.form.houseStreet)
      this.$set(this.mappedObject, 'currentTenant', this.form.currentTenant)
      this.$set(this.mappedObject, 'newTenant', this.form.newTenant)

      this.form.subjectLine1 = this.form.objectText + ' / ' + this.form.floorText
      this.form.subjectLine2 = taskHelper.setSubjectLine2(this.form)

      this.updateForm(this.form)
    },
    onHouseChange (house) {
      this.$set(this.form, 'houseId', house.houseId)
      this.$set(this.form, 'houseStreet', house.houseStreet)
      this.$set(this.form, 'objectId', null)
      this.$set(this.form, 'objectText', null)
      this.$set(this.form, 'currentTenant', null)
      this.$set(this.form, 'newTenant', null)

      this.$set(this.mappedObject, 'objectId', null)
      this.$set(this.mappedObject, 'objectText', null)
      this.$set(this.mappedObject, 'houseId', this.form.houseId)
      this.$set(this.mappedObject, 'houseStreet', this.form.houseStreet)
      this.$set(this.mappedObject, 'currentTenant', null)
      this.$set(this.mappedObject, 'newTenant', null)

      this.form.subjectLine1 = ''
      this.form.subjectLine2 = ''

      this.updateForm(this.form)
    },
    removeProp (obj, propToDelete) {
      let newObject = taskHelper.removeProp(obj, propToDelete)
      Object.assign(this.form, newObject)
    },
    onUpdateFile (file, index) {
      Vue.set(this.form.files, index, file)
      this.disabled = true
    },
    updateTask () {
      if (this.$refs.form.validate()) {
        this.removeProp(this.form, 'displayText')
        this.updateAndLoadTask()
      }
    },
    updateAndLoadTask () {
      let that = this
      this.$prestigeTask.updateTask(this.$route.params.taskId, this.form).then(function (response) {
        if (response.status === 200) {
          that.setSuccessSnackbar(that.$t('common.messages.successfullyUpdated'))
          that.loadTask()
          that.disabled = false
        }
        return true
      }).catch(function (error) {
        that.setErrorSnackbar(error)
      })
    },
    deleteTask () {
      let that = this
      this.$prestigeTask.deleteTask(this.$route.params.taskId).then(function (response) {
        if (response.status === 200) {
          that.setSuccessSnackbar(that.$t('common.messages.successfullyDeleted'))
          if (that.$route.meta.abacus) {
            window.close()
          } else {
            that.$router.push({ name: TASKS_HOME })
          }
        }
        return true
      }).catch(function (error) {
        that.setErrorSnackbar(error)
      })
      that.closeDeleteDialog()
    },
    cloneTask () {
      if (this.$refs.form.validate()) {
        this.removeProp(this.form, 'displayText')
        this.removeProp(this.form, 'id')
        delete this.form.id
        let that = this
        this.$prestigeTask.saveTask(this.form).then(response => {
          let clonedTaskId = response.data
          that.closeCloneDialog()
          if (clonedTaskId) {
            that.$router.push({
              name: that.$route.meta.full ? 'TaskDetailsFullscreen' : 'TaskDetails',
              params: { taskId: clonedTaskId }
            }).then(
              that.setSuccessSnackbar(that.$t('common.messages.successfullyCopied'))
            )
          }
          that.disabled = false
          return response.data
        }).catch(function (error) {
          that.setErrorSnackbar(error)
        })
        that.closeCloneDialog()
      }
    },
    downloadFile (pdfData, fileName) {
      let fileDownload = require('js-file-download')
      fileDownload(pdfData, fileName)
    },
    downloadTenantPdf () {
      let that = this
      const config = { responseType: 'blob' }
      this.$prestigeTaskPdf.getTenantPdf(this.$route.params.taskId, config).then(function (response) {
        that.downloadFile(response.data, 'tenant.pdf')
        return true
      }).catch(function (error) {
        that.setErrorSnackbar(error)
      })
    },
    downloadCaretakerPdf () {
      let that = this
      const config = { responseType: 'blob' }
      this.$prestigeTaskPdf.getCaretakerPdf(this.$route.params.taskId, config).then(function (response) {
        that.downloadFile(response.data, 'caretaker.pdf')
        return true
      }).catch(function (error) {
        that.setErrorSnackbar(error)
      })
    },
    downloadCraftsmanPdf () {
      let that = this
      const config = { responseType: 'blob' }
      this.$prestigeTaskPdf.getCraftsmanPdf(this.$route.params.taskId, config).then(function (response) {
        that.downloadFile(response.data, 'craftsman.pdf')
        return true
      }).catch(function (error) {
        that.setErrorSnackbar(error)
      })
    },
    openCloneDialog () {
      this.showCloneDialog = true
    },
    closeCloneDialog () {
      this.showCloneDialog = false
    },
    openDeleteDialog () {
      this.showDeleteDialog = true
    },
    closeDeleteDialog () {
      this.showDeleteDialog = false
    }
  }
}
</script>
