import gql from "graphql-tag";

const RENTING_ADDRESSES_REINDEX = gql`
  mutation rentingFormAddressReindex {
    rentingFormAddressReindex
  }
`
export {
  RENTING_ADDRESSES_REINDEX
}
