<template>
  <v-card class="mb-4">
    <v-card-text v-if="propertyId">
      <v-icon color="primary" @click="toggle" class="float-right">{{ icon }}</v-icon>
      <v-card-title class="pa-0">{{ $t('navigation.processes') }}</v-card-title>
    </v-card-text>
    <v-divider></v-divider>
    <div v-if="isOpen" class="pa-2 ml-2 mb-2">
      <v-layout class="processes-list mt-1">
        <v-flex>
          <v-data-table
            :headers="headers"
            :items="processes"
            :loading="loading"
            :footer-props="{itemsPerPageOptions: itemsPerPageOptions, itemsPerPageText: itemsPerPageText}"
            :server-items-length="totalItems"
            :item-class="rowClass"
            :options.sync="options"
            @click:row="goToProcessDetails"
            class="elevation-1"
          >
            <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
            <template v-slot:[`item.processSubject`]="{ item }">
              <span class="truncate-two-lines">{{ item.processSubject }}</span>
            </template>
            <template v-slot:[`item.state`]="{ item }">
              <StateIcon :state="item.state" />
            </template>
            <template v-slot:[`item.startedOn`]="{ item }">
              {{ item.startedOnFormatted }}
            </template>
            <template v-slot:[`item.finishedOn`]="{ item }">
              {{ item.finishedOnFormatted }}
            </template>
            <template v-slot:[`item.startUserId`]="{ item }">
              {{ item.startUser }}
            </template>
            <template slot="no-data">
              <v-alert class="mt-4" :value="true" color="error" icon="warning">
                {{ $t('table.noData') }}
              </v-alert>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </div>
  </v-card>
</template>

<script>
import StateIcon from '@/components/StateIcon'
import { mapActions, mapState } from 'vuex'
import * as ticketingHelper from '@/features/ticketing/helpers/ticketingHelper'
import { setRowClassByDiff } from '@/helpers/momentHelper'
import TicketingProcessService from '@/services/TicketingProcessService'
import { TICKETING_PROCESS_DETAILS } from '@/config/options/routes/ticketingRoutes'

export default {
  name: 'TaskList',
  props: {
    propertyId: {
      type: String,
      default: ''
    }
  },
  components: {
    StateIcon
  },
  watch: {
    '$route': 'loadProcesses',
    'options.page': 'loadProcesses',
    'options.itemsPerPage': 'loadProcesses'
  },
  created () {
    this.loadProcesses()
  },
  data () {
    return {
      loading: true,
      isOpen: true,
      processes: [],
      options: { itemsPerPage: 10, page: 1 },
      itemsPerPageOptions: [ 10, 15, 25 ],
      totalItems: 0
    }
  },
  computed: {
    ...mapState('ticketingSystem', ['ticketingUsers']),
    icon () {
      return this.isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
    },
    headers () {
      return [
        {
          text: this.$t('ticketingSystem.processes.key'),
          align: 'left',
          value: 'processDefinitionKey',
          sortable: false
        },
        {
          text: this.$t('ticketingSystem.processes.process'),
          align: 'left',
          value: 'processDefinitionName',
          sortable: false
        },
        {
          text: this.$t('ticketingSystem.subject'),
          align: 'left',
          value: 'processSubject',
          sortable: false
        },
        {
          text: this.$t('ticketingSystem.taskDetails.state'),
          align: 'left',
          value: 'state',
          sortable: false
        },
        {
          text: this.$t('ticketingSystem.taskDetails.processReferences.startedOn'),
          align: 'left',
          value: 'startedOn',
          sortable: false
        },
        {
          text: this.$t('ticketingSystem.taskDetails.processReferences.finishedOn'),
          align: 'left',
          value: 'finishedOn',
          sortable: false
        },
        {
          text: this.$t('ticketingSystem.taskDetails.processReferences.startedBy'),
          align: 'left',
          value: 'startUser',
          sortable: false
        }
      ]
    },
    itemsPerPageText () {
      return this.$t('common.itemsPerPageText')
    }
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar']),
    toggle () {
      this.isOpen = !this.isOpen
    },
    async loadProcesses () {
      this.loading = true
      const { page, itemsPerPage } = this.options
      let paginationFilters = { page: page - 1, size: itemsPerPage }
      let propertyParam = { propertyIds: [this.propertyId] }
      let queryParams = { query: Object.assign(paginationFilters, propertyParam) }
      try {
        const response = await TicketingProcessService.getProcesses(queryParams)
        this.processes = response.data.items || []
        this.totalItems = response.data.totalItems
        ticketingHelper.mapProcesses(this.processes)
        this.loading = false
      } catch (error) {
        this.setErrorSnackbar(error)
        this.processes = []
        this.loading = false
      }
    },
    goToProcessDetails (process) {
      this.$router.push({ name: TICKETING_PROCESS_DETAILS, params: { processInstanceId: process.processInstanceId } })
    },
    rowClass (item) {
      return setRowClassByDiff(item.startedOn)
    }
  }
}
</script>
<style scoped lang="postcss">
/deep/ tr:nth-child(odd) {
  background: #f8f8f8;
}
/deep/ tr {
  cursor: pointer;
}
.truncate-two-lines {
  max-width: 200px;
  display: -webkit-inline-box;
  margin: 0 auto;
  line-height: 1.5;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
