<template>
  <v-container fluid class="white pt-2 pl-5 pr-5">
    <AbacusImport />
    <SilentsoftImport />
    <DossierImport />
    <ConstructionProjectsImport />
    <ImmoSparrowImport />
  </v-container>
</template>

<script>
import AbacusImport from './AbacusImport'
import SilentsoftImport from './SilentsoftImport'
import DossierImport from './DossierImport'
import ConstructionProjectsImport from './ConstructionProjectsImport'
import ImmoSparrowImport from './ImmoSparrowImport'

export default {
  name: 'DataImport',
  components: {
    AbacusImport,
    SilentsoftImport,
    DossierImport,
    ConstructionProjectsImport,
    ImmoSparrowImport
  }
}
</script>

<style scoped>
</style>
