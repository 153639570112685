<template>
  <v-card class="mb-2 pa-0">
    <v-card-text class="pa-2">
      <v-list-item class="pa-0">
        <v-list-item-content class="pl-1">
          <v-card-subtitle class="pa-0 font-weight-bold subtitle-1">
            {{ $t('ticketingSystem.processes.interestedPerson.title') }}
          </v-card-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn-toggle
            rounded
            dense
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click.stop="showRentingFormDialog" class="float-right">
                  <v-icon>more</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('ticketingSystem.processes.interestedPerson.rentalApplicationLabel') }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click.stop="downloadRentalFormPDF" class="float-right">
                  <v-icon>file_download</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('ticketingSystem.processes.interestedPerson.rentalApplicationPdfLabel') }}</span>
            </v-tooltip>
          </v-btn-toggle>
        </v-list-item-action>
      </v-list-item>
    </v-card-text>
    <template v-if="rentingFormData">
      <v-divider></v-divider>
      <v-list>
        <v-subheader class="flex-column align-start subheader">
          <div v-if="rentingFormData.desiredMoveInDate">
            {{ $t('renting.publicForm.desiredMoveInDate') }}: {{formatDateFunc(rentingFormData.desiredMoveInDate)}}
          </div>
          <div v-if="highlightEarliestMoveInDate(rentingFormEarliestDate, rentingFormData.desiredMoveInDate)" class="d-flex align-end">
            {{ $t('renting.publicForm.earliestStart') }}: {{formatDateFunc(rentingFormEarliestDate)}}
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" color="orange" class="pl-2">warning</v-icon>
              </template>
              <span>{{ $t('ticketingSystem.messages.desiredDateNoEqual') }}</span>
            </v-tooltip>
          </div>
          <div>Interessent(en)</div>
        </v-subheader>
        <template v-for="(resident, index) in rentingFormData.residents">
          <v-card :key="'residents-' + index" class="ma-2">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ resident.firstName }} {{ resident.lastName }}</v-list-item-title>
                <v-list-item-subtitle v-if="resident.employmentStatus">{{ $t('renting.publicForm.fields.personInfo.employment.title')}}:
                  <span class="font-weight-bold">
                    {{ $t('renting.publicForm.fields.personInfo.employment.status_' + resident.employmentStatus)}}
                  </span>
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="resident.currentApartmentStatus">{{ $t('renting.publicForm.fields.personInfo.apartment.title')}}:
                  <span class="font-weight-bold">
                    {{ $t('renting.publicForm.fields.personInfo.apartment.status_' + resident.currentApartmentStatus)}}
                  </span>
                </v-list-item-subtitle>
                <v-list-item-subtitle>{{ resident.mobile }} {{ resident.phone1 }} {{ resident.phone2 }} {{ resident.email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <ResidentCertificatePreview v-if="resident.verifyCertificateResponse" :resident="resident" />
          </v-card>
        </template>
      </v-list>
      <v-divider></v-divider>
      <RentalApplicationDialog :form="rentingFormData" :show-dialog="showDialog" @closeDialog="showDialog = false" />
    </template>
    <RentingViewings :renting-form-id="rentingFormId"></RentingViewings>
    <template v-if="rentingFormData">
      <SetRentalApplicationDecision
        :process-instance-id="processInstanceId"
        :decision="decision"
        :decision-comment="decisionComment"
        @loadDetails="loadDetails"
      />
    </template>
    <v-expansion-panels v-if="rentalContractId" v-model="panel" class="pa-2">
      <ProcessesRentalApplications :rentalContractId="rentalContractId" :isExpanded="isExpanded"/>
    </v-expansion-panels>
  </v-card>
</template>
<script>
import ResidentCertificatePreview from '../ResidentCertificatePreview'
import SetRentalApplicationDecision from './process-renting-form/SetRentalApplicationDecision'
import RentingViewings from './process-renting-form/RentingViewings'
import RentalApplicationDialog from './process-renting-form/RentalApplicationDialog'
import ProcessesRentalApplications from './process-reletting/ProcessesRentalApplications.vue'
import * as ticketingHelper from '@/features/ticketing/helpers/ticketingHelper'
import { formatDate } from '@/helpers/formatterHelper'
import { mapActions } from 'vuex'

import { useRentingForm } from '@/use/useRentingForm'
const { downloadRentalFormPDF, loadRentingForm } = useRentingForm()

export default {
  name: 'ProcessRentingForm',
  props: {
    title: {
      type: String,
      default: ''
    },
    processInstanceId: {
      type: String,
      default: ''
    },
    processVariables: {
      type: Array,
      default: () => {
      }
    }
  },
  components: {
    ResidentCertificatePreview,
    RentingViewings,
    SetRentalApplicationDecision,
    RentalApplicationDialog,
    ProcessesRentalApplications
  },
  data () {
    return {
      rentingForm: null,
      loading: false,
      showDialog: false,
      panel: [],
      isExpanded: false
    }
  },
  computed: {
    rentalContractId () {
      return this.getVariableValue('System_RentalContractId_Long')
    },
    rentingFormId () {
      return this.getVariableValue('System_RentingFormId_Long')
    },
    rentingFormData () {
      return this.rentingForm && this.rentingForm.content ? this.rentingForm.content : undefined
    },
    rentingFormEarliestDate () {
      return this.rentingForm?.rentalCase?.earliestStart
    },
    decision () {
      return this.getVariableValue('System_InterestedPerson_Decision_Enum')
    },
    decisionComment () {
      return this.getVariableValue('System_InterestedPerson_DecisionComment_String')
    }
  },
  watch: {
    'rentingFormId': {
      handler: 'loadData',
      immediate: true
    },
    'panel': 'expandedPanel'
  },
  methods: {
    formatDateFunc: formatDate,
    ...mapActions('notifier', ['setErrorSnackbar']),
    toggle () {
      this.isOpen = !this.isOpen
    },
    async loadData () {
      this.loading = true
      if (this.rentingFormId) {
        let rentingForm = await loadRentingForm(this.rentingFormId)
        this.loading = false
        if (rentingForm) {
          this.rentingForm = rentingForm
          if (this.rentingForm.rentalCase.propertyId) {
            this.getPropertyInfo(this.rentingForm.rentalCase.propertyId)
          }
        }
      }
    },
    getPropertyInfo (propertyId) {
      let that = this
      this.$abacusProperty.getPropertyById(propertyId).then(response => {
        if (response.data) {
          let data = response.data
          that.rentingForm.propertyInfo = data.street + ', ' + data.zip + ' ' + data.place
          if (that.rentingForm.rentalCase.propertyObjectId) {
            let objectData = data.objects.find(x => x.objectId === that.rentingForm.rentalCase.propertyObjectId)
            if (objectData) {
              that.rentingForm.objectFloorInfo = objectData.floorText + (objectData.objectText ? ' - ' + objectData.objectText : '')
            }
            that.loading = false
          } else {
            that.loading = false
          }
        }
      }).catch(error => {
        that.loading = false
        that.setErrorSnackbar(error)
      })
    },
    getVariableValue (variableName) {
      return ticketingHelper.getVariableValue(this.processVariables, variableName)
    },
    loadDetails () {
      this.$emit('loadDetails')
    },
    showRentingFormDialog () {
      this.showDialog = true
    },
    downloadRentalFormPDF () {
      downloadRentalFormPDF(this.rentingFormId)
    },
    expandedPanel (value) {
      if(value >= 0) {
        this.isExpanded = true
      }
    },
    highlightEarliestMoveInDate (earliestDate, desiredDate) {
      if (earliestDate !== desiredDate) {
        return this.formatDateFunc(earliestDate)
      }
      return null
    }
  }
}
</script>
<style scoped lang="postcss">
.no-info {
  color: rgba(0,0,0,.6)
}
/deep/ .editor {
  height: 75px;
}
.subheader {
  height: 100%;
}
</style>
