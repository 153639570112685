<template>
  <v-dialog v-model="mutableShowDialog" max-width="500px" @keydown.esc="closeDialog" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">{{ shoppingModalTitle }}</span>
        <v-btn icon absolute right>
          <v-icon class="clear" color="primary" :size="28" @click.native="closeDialog"/>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-0 pb-0">
          <v-form ref="form" v-model="valid">
            <v-alert v-if="mutableItem.orderedOn && lastOrderDifferenceInMonths" type="error">
              {{ lastOrderInfo }}
            </v-alert>
            <v-alert v-if="mutableItem.comment" type="warning">
              {{ mutableItem.comment }}
            </v-alert>
            <p>{{ $t('heatingOil.headers.stockVolume') }}:<br />{{ mutableItem.volumeInfo }}</p>
            <v-autocomplete
              :items="heatingOilSuppliers"
              :loading="heatingOilSuppliersLoading"
              :label="$t('heatingOil.tankLevel.shoppingCartDialog.supplier')"
              item-text="displayText"
              item-value="addressId"
              v-model="select"
              @change="changedValue"
              required
              :rules="[rules.required]"
              single-line
            >
            </v-autocomplete>
            <p v-if="defaultSupplier && mutableItem.supplierId.toString() !== defaultSupplier.addressId.toString()">
              <v-icon color="orange">warning</v-icon>
              <span>{{ defaultSupplierInfo }}</span>
            </p>
            <v-text-field
              name="input-1"
              autofocus
              :label="$t('heatingOil.tankLevel.shoppingCartDialog.amountLabel')"
              hint="Liter"
              persistent-hint
              required
              :rules="[rules.required, rules.fillingLevel]"
              type="number"
              v-model="mutableItem.basketAmount"
            ></v-text-field>
          </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="closeDialog">{{ $t('common.buttons.cancel') }}</v-btn>
        <v-btn color="primary" :disabled="!valid || disableBtn" @click.native="saveItemToBasket">{{ $t('common.buttons.save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment'
import { formatDateAndTime } from '@/helpers/formatterHelper'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'ShoppingCartDialog',
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => {}
    },
    defaultSupplierId: {
      type: String,
      default: ''
    },
    disableBtn: {
      type: Boolean,
      default: false
    },
    maxAmount: {
      type: Number,
      default: null
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      valid: false,
      rules: {
        required: (value) => !!value || this.$t('common.errors.required'),
        fillingLevel: (value) => {
          const valid = this.maxAmount ? (!isNaN(parseInt(value)) && value <= this.maxAmount && value > 0) : value > 0
          return valid || this.$t('heatingOil.tankLevel.fillingLevelDialog.validAmount')
        }
      }
    }
  },
  mounted () {
    this.$store.dispatch('abacus/loadHeatingOilSuppliers')
  },
  computed: {
    ...mapState('abacus', ['heatingOilSuppliers', 'heatingOilSuppliersLoading']),
    ...mapGetters('abacus', ['getHeatingOilSuppliers', 'getHeatingOilSuppliersLoading']),
    lastOrderInfo () {
      return this.$t('heatingOil.tankLevel.shoppingCartDialog.messages.lastOrder') + ' ' + this.formatDateAndTimeFunc(this.mutableItem.orderedOn) + '. ' + this.$t('heatingOil.tankLevel.shoppingCartDialog.messages.lastOrderQuestion')
    },
    lastOrderDifferenceInMonths () {
      return moment().diff(moment(this.mutableItem.orderedOn), 'months') < 2
    },
    defaultSupplierInfo () {
      return this.$t('heatingOil.tankLevel.shoppingCartDialog.defaultSupplier') + ': [' + this.defaultSupplier.addressId + '] ' + this.defaultSupplier.lastName
    },
    defaultSupplier () {
      if (this.defaultSupplierId && this.defaultSupplierId !== '0') {
        let standard = this.heatingOilSuppliers.filter(supplier => supplier.addressId.toString() === this.defaultSupplierId)
        if (standard && standard.length > 0) {
          standard[0].displayText = standard[0].lastName + ' ' + standard[0].firstName
          return standard[0]
        } else {
          return null
        }
      } else {
        return null
      }
    },
    select: {
      get: function () {
        return this.defaultSupplier
      },
      set: function (newValue) {
        return newValue
      }
    },
    shoppingModalTitle () {
      return this.edit ? this.$t('heatingOil.tankLevel.shoppingCartDialog.editTitle') : this.$t('heatingOil.tankLevel.shoppingCartDialog.addTitle')
    },
    mutableItem () {
      return this.item
    },
    mutableShowDialog () {
      return this.showDialog
    }
  },
  methods: {
    formatDateAndTimeFunc: formatDateAndTime,
    closeDialog () {
      this.$emit('closeDialog')
    },
    saveItemToBasket () {
      if (this.$refs.form.validate()) {
        this.$emit('saveItemToBasket')
      }
    },
    changedValue (value) {
      this.mutableItem.supplierId = parseInt(value)
    }
  }
}
</script>
