<template>
  <v-container fluid v-if="dataInitialized">
    <v-row dense>
      <v-col cols="12">
        <v-btn block color="primary" target="_blank" :href="'https://abacus.prestige.ch:40001/abastart?mandant=10&program=immo.11&ObjectContractNumber=' + rentalContractId">
          {{ $t('ticketingSystem.processes.termination.contract') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="6" v-if="signatureCheckLoop > 0">
        <VariableFileUpload
          ref="formFile"
          label="Neue Kündigung"
          required
          deletable
          :process-instance-id="ticketingTask.ticket.ticketId"
          :form-field-id="newTerminationFileVariableName"
          :variable-file="newTerminationFile"
          :validate-clicked="validateClicked"
          :task-finished="taskFinished"
          @addFileVariable="onAddFileVariable"
          @removeFileVariable="onRemoveFileVariable"
          @loadProcessVariables="onLoadProcessVariables"
        />
      </v-col>
      <v-col cols="6" v-else>
        <VariableFileUpload
          ref="formFile"
          label="Kündigung"
          disabled
          :process-instance-id="ticketingTask.ticket.ticketId"
          :form-field-id="this.existingTerminationFileVariableName"
          :variable-file="this.existingTerminationFileId"
          :validate-clicked="validateClicked"
          :task-finished="taskFinished"
          @onAddFileVariable="onAddFileVariable"
          @removeFileVariable="onRemoveFileVariable"
          @loadProcessVariables="onLoadProcessVariables"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-radio-group
          row
          v-model="signatureCorrect"
          required
          :rules="[rules.radioRequired]"
          @change="onUpdatedField"
        >
          <v-list-item-subtitle class="subtitle-1 mb-2">
            {{ $t('ticketingSystem.processes.termination.signaturesCorrect') }}
          </v-list-item-subtitle>
          <v-radio class="pr-5 mr-0" :label="$t('common.options.yes')" value="YES" :disabled="formReadOnly" />
          <v-radio class="pr-5 mr-0" :label="$t('common.options.no')" value="NO" :disabled="formReadOnly" />
          <template #message="{ message }">
            <span v-if="message === 'required'">{{ requiredMessage }}</span>
          </template>
        </v-radio-group>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VariableFileUpload from '@/features/ticketing/components/VariableFileUpload'
import * as ticketingHelper from '@/features/ticketing/helpers/ticketingHelper'
import _isEmpty from 'lodash.isempty'

export default {
  name: 'TerminationSignatureCheck',
  props: {
    ticketingTask: {
      type: Object,
      default: () => {}
    },
    processVariables: {
      type: Array,
      default: () => {}
    },
    formReadOnly: {
      type: Boolean,
      default: false
    },
    validateClicked: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dataInitialized: false,
      signatureCorrect: {},
      newTerminationFile: null,
      rules: {
        required: (value) => !!value || this.$t('common.errors.required'),
        checkboxRequired: (value) => (value !== undefined && value !== '' && value !== null && value) || this.requiredMessage,
        radioRequired: (value) => (value !== undefined && value !== '' && value !== null) || this.requiredMessage
      }
    }
  },
  components: {
    VariableFileUpload
  },
  watch: {
    'processVariables': {
      handler: 'initProcessVariables',
      immediate: true
    }
  },
  computed: {
    taskFinished () {
      return !_isEmpty(this.ticketingTask) && this.ticketingTask.finishedOn !== '' && this.ticketingTask.finishedOn !== null
    },
    requiredMessage () {
      return this.$t('common.errors.required')
    },
    existingTerminationFileId () {
      return this.getVariableValue(this.existingTerminationFileVariableName)
    },
    signatureCheckLoop () {
      return this.getVariableValue('System_TerminationSignatureCheckLoop_Long', '')
    },
    rentalContractId () {
      return this.getVariableValue('System_RentalContractId_Long')
    },
    existingTerminationFileVariableName () {
      if (this.signatureCheckLoop > 1) {
        return 'System_Termination' + (this.signatureCheckLoop - 1) + '_File'
      } else {
        return 'System_Termination_File'
      }
    },
    newTerminationFileVariableName () {
      return 'System_Termination' + this.signatureCheckLoop + '_File'
    }
  },
  methods: {
    initProcessVariables () {
      this.newTerminationFile = this.getVariableValue(this.newTerminationFileVariableName)
      this.signatureCorrect = this.getVariableValue('System_SignaturesCorrect_Enum')
      this.dataInitialized = true
    },
    onLoadProcessVariables (file) {
      this.onAddFileVariable(file)
    },
    onAddFileVariable (file) {
      this.newTerminationFile = file
      this.onUpdatedField()
    },
    onRemoveFileVariable () {
      this.newTerminationFile = null
      this.onUpdatedField()
    },
    onUpdatedField () {
      let formFields = [
        {
          formFieldId: 'System_SignaturesCorrect_Enum',
          formFieldType: 'enum',
          formFieldDefaultValue: this.signatureCorrect
        }
      ]

      if (this.signatureCheckLoop > 0) {
        formFields.push(
          {
            formFieldId: this.newTerminationFileVariableName,
            formFieldType: 'staticFile',
            formFieldDefaultValue: this.newTerminationFile ? this.newTerminationFile.fileId.toString() : null
          }
        )
      }

      this.$emit('updateFormValues', { formFields })
    },
    getVariableValue (variableName) {
      return ticketingHelper.getVariableValue(this.processVariables, variableName)
    }
  }
}
</script>

<style scoped lang="postcss">
</style>
