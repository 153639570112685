<template>
  <v-dialog v-model="mutableShowDialog" max-width="500px" @keydown.esc="closeDialog" persistent>
    <v-card>
      <v-card-title>{{ title }}
        <v-btn icon absolute right>
          <v-icon class="clear" color="primary" :size="28" @click.native="closeDialog"/>
        </v-btn>
      </v-card-title>
      <v-flex xs12 sm12 md12 lg12 xl12 class="ml-6" v-if="currentTenant">
        <v-card-text class="pa-0">{{ currentTenant.firstName + ' ' + currentTenant.lastName }}</v-card-text>
        <v-card-text class="pa-0">{{ currentTenant.phone1 }}</v-card-text>
        <v-card-text class="pa-0">{{ currentTenant.phone2 }}</v-card-text>
        <v-card-text class="pa-0">{{ currentTenant.mobile }}</v-card-text>
        <v-card-text class="pa-0">{{ currentTenant.email }}</v-card-text>
        <v-card-text class="pa-0">{{ currentTenant.comment }}</v-card-text>
      </v-flex>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="mt-2" @click="closeDialog">{{ $t('common.buttons.cancel') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ObjectDialog',
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    currentTenant: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: ''
    }
  },
  computed: {
    mutableShowDialog () {
      return this.showDialog
    }
  },
  methods: {
    closeDialog () {
      this.$emit('closeDialog')
    }
  }
}
</script>
