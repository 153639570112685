<template>
  <v-dialog :value="true" scrollable max-width="750px" @keydown.enter.prevent="updateFileName" @keydown.esc="closeDialog" persistent>
    <v-card>
      <v-card-title>
        {{ $t('ticketingSystem.fileActions.editFileName') }}
        <v-btn icon absolute right>
          <v-icon class="clear" color="primary" :size="28" @click.native="closeDialog"/>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-layout row wrap>
            <v-flex xs12 sm12 md12 lg12 xl12>
              <v-form ref="fileNameForm">
                <v-row dense>
                  <v-col
                    :class="fileExtension ? 'xs11 sm11 md11 lg11 xl11' : 'xs12 sm12 md12 lg12 xl12'"
                  >
                    <v-text-field
                      :label="$t('common.data.name')"
                      v-model="name"
                      :required="true"
                      :rules="[rules.required]"
                      autofocus
                    >
                      <template #message="{ message }">
                        <span v-if="message === 'required'">{{ requiredMessage }}</span>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col v-if="fileExtension" xs="1" sm="1" md="1" lg="1" xl="1">
                    <v-list-item-content class="pt-8 font-weight-bold">{{ `.${fileExtension}` }}</v-list-item-content>
                  </v-col>
                </v-row>
              </v-form>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="closeDialog" class="mt-2">{{ $t('common.buttons.cancel') }}</v-btn>
        <v-btn color="success" @click="updateFileName" class="mt-2">
          <span>{{ $t('common.buttons.save') }}</span>
          <v-icon class="ml-2">send</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'EditAzureFileName',
  props: {
    fileId: {
      type: String,
      default: ''
    },
    fileName: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      name: '',
      rules: {
        required: (value) => !!value || 'required'
      }
    }
  },
  computed: {
    fileExtension () {
      let fileNameSplitted = this.fileName.split('.')
      if (fileNameSplitted.length > 1) {
        return this.fileName.split('.').pop()
      }
      return ''
    },
    requiredMessage () {
      return this.$t('common.errors.required')
    }
  },
  created () {
    if (this.fileExtension) {
      this.name = this.fileName.substring(0, this.fileName.length - this.fileExtension.length - 1)
    } else {
      this.name = this.fileName
    }
  },
  methods: {
    ...mapActions('notifier', ['setErrorSnackbar', 'setSuccessSnackbar']),
    closeDialog () {
      this.$emit('closeDialog')
    },
    updateFileName () {
      if (this.$refs.fileNameForm.validate()) {
        let that = this
        let fileName = this.fileExtension ? `${this.name}.${this.fileExtension}` : this.name
        this.$prestigeTSFile.updateAzureFile(this.fileId, { fileName }).then(function (response) {
          if (response.status === 200) {
            that.setSuccessSnackbar(that.$t('ticketingSystem.messages.successfullyUpdatedTask'))
            that.closeDialog()
            that.$emit('reloadFileName', response.data)
          }
          return true
        }).catch(error => {
          that.setErrorSnackbar(error)
        })
      }
    }
  }
}
</script>

<style scoped lang="postcss">
</style>
